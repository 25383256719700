import React, { useState, useEffect } from "react";
import markImg from "../../Images/markImg.png";
import { Link, useParams } from "react-router-dom";
import InputImg from "../../Images/input_icon.png";
import EliteCardicon from "../../Images/elitecardicon.png";
import bi_calendarevent from "../../Images/bi_calendarevent.png";
import NewYear from "../../Images/new_year.png";
import table_icon from "../../Images/table_icon.png";
import world_day from "../../Images/world_day.png";
import earth_day from "../../Images/earth_day.png";
import eventimg1 from "../../Images/eventimg1.png";
import bgprfldiv from "../../Images/bgprfldiv.png";
import prfl from "../../Images/Ellipse _8.png";
// import eventimg2 from "../../Images/eventimg2.png";
import "../Engagementcalender/Mycalender.css";
import EngagementeventCard from "../../Component/EngagementeventCard";
import HighlightedEvents from "../../Component/HighlightedEvents";
import MysubscribedEvents from "../../Component/MysubscribedEvents";
import EngagementPlaceCard from "../../Component/EngagementPlacecard";
import ReactCalender from "../../Component/customComponent/ReactCalender";
import BigCalender from "../../Component/customComponent/BigCalender";
import Allapicall from "../../services/Allapicall";
import moment from "moment";
import { useAuthCtx } from "../../context/AuthCtx";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import goldIcon from "../../Images/Icons/PNG/Reward - Gold.png";
import eliteExp from "../../Images/Icons/PNG/Elite employee experience logo.png";
import SaraTylor from "../../Component/SaraTylor";
import ShareThoughts from "../../Component/ShareThoughts";
import { useDispatch, useSelector } from "react-redux";
import GrowWel from "../Hub/GrowWel";
import Advocayboarddetails from "../../Component/Advocayboarddetails";
import Statuscard from "../../Component/Statuscard";
import EventsModal from "../../SeprateModal/EventsModal";
import ListofEngagement from "./ListofEngagement";
import Addicon from "../../Images/headericon/Addicon.png"
import Chatbot from "../../Component/Chatbot/Chatbot";

function EngagementCalender() {
  const { modalF, setModalF } = useAuthCtx();
  const postDataReducer = useSelector((state) => state?.postGetSlice);
  const dispatch = useDispatch();
  const [postData, setPostData] = useState([]);
  const [modalB, setModalB] = useState(false);
  const [modalId, setModalId] = useState("");
  const [modalA, setModalA] = useState(false);
  const [changeShow, setChangeShow] = useState(false);
  const params = useParams();

  const [fetchcalenderdata, setfetchcalenderdata] = useState([]);
  const [fetchholidaycalenderdata, setfetchholidaycalenderdata] = useState([]);
  const [question, setQuestion] = useState("");
  const { userData, setLoading } = useAuthCtx();
  const [singleData, setSingleData] = useState({});
  const navigate = useNavigate();
  console.log("UserDATA", singleData?.id);
  const [goldCard, setGoldCard] = useState([]);

  const initialValues = {
    eventName: "",
    hostedBy: "",
    eventDate: "",
    eventstarttime: "",
    eventendtime: "",
    lattitude: "",
    longitude: "",
    addinvites: "",
    name: "",
    notes: "",
    image: "",
    editTrainingName: "",
    editTraininDate: "",
    isHighLighted: "",
  };

  //get gold card
  const getGoldCard = async () => {
    setLoading(true);

    const response = await ApiHelperFunction({
      urlPath: "/get-profile",
      method: "GET",
    });

    // console.log("responseeeee", response?.data);

    if (response.status === 200) {
      setGoldCard(response?.data?.data);
      // console.log("data get successfully");
    } else {
      // toast.error(response.message);
    }
    setLoading(false);
  };

  const handleOptionClick = (index) => {
    setChangeShow(index === modalId);
  };


  //view all affinity group post
  const getAffinityPosts = async () => {
    const data = {
      affinityGroupId: params?.id,
      postType: "private",
    };
    setLoading(true);
    const response = await ApiHelperFunction({
      urlPath: `/view-all-post`,
      method: "POST",
      data,
    });
    // console.log("responseddfftt", response);
    if (response.status === 200) {
      setPostData(response?.data?.data);
    } else {
    }
    setLoading(false);
  };

  useEffect(() => {
    getAffinityPosts();
  }, [params?.id]);


  useEffect(() => {
    setPostData(postDataReducer?.posts);
  }, [postDataReducer?.posts]);


  const HandleAgenda = () => {
    if (userData?.userType === "Admin" || userData?.userType === "Owner") {
      navigate("/agendaModal");
    } else {
      toast.error("Only Admin can access");
    }
  };
  // function formatDate(year, month, day) {
  //   const date = new Date(year, month - 1, day);

  //   const formattedYear = date.getFullYear();
  //   const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to month since it's 0-based.
  //   const formattedDay = date.getDate().toString().padStart(2, "0");

  //   const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

  //   return formattedDate;
  // }

  //to fetch all event data
  const fetchcalenderdatafunc = async () => {
    let res = await Allapicall?.Allcalenderdata();
    // console.log("res99", res);
    if (res && res?.status) {
      const temparr = res?.data?.map((ele, id) => {
        console.log("yyrt", ele);
        return {
          id: ele?._id,
          title: ele?.eventName,
          // image: (
          //   <img
          //     style={{ height: "50px", width: "120px" }}
          //     src={ele?.image}
          //     alt=""
          //   />
          // ),
          notes: ele?.notes,
          image: ele?.image,
          start: moment(ele?.eventDate),
          end: moment(ele?.eventDate),
          addedBy: ele?.addedBy
        };
      });

      if (temparr.length > 0) {
        setSingleData(temparr[0]);
      }
      setfetchcalenderdata(temparr);
    }
  };

  //fetch holiday calender data
  const fetchholidaycalenderdatafunc = async () => {
    let res = await Allapicall.Allholidaycalenderdata();
    // console.log("res99", res);
    if (res && res?.status) {
      const temparr = res?.data?.map((ele, id) => {
        console.log("yyrtqq", ele);
        return {
          id: ele?._id,
          title: ele?.holidayName,
          // image: (
          //   <img
          //     style={{ height: "50px", width: "120px" }}
          //     src={ele?.image}
          //     alt=""
          //   />
          // ),
          notes: ele?.notes,
          image: ele?.image,
          start: moment(ele?.holidayDate),
          end: moment(ele?.holidayDate),
        };
      });

      if (temparr.length > 0) {
        setSingleData(temparr[0]);
      }
      setfetchholidaycalenderdata(temparr);
    }
  };

  // console.log(fetchholidaycalenderdata,"fetchholidaycalenderdata")

  const handleQuestionClick = async () => {
    const data = {
      userQuestion: question,
    };
    setLoading(true);
    const response = await ApiHelperFunction({
      urlPath: `/add-hr-question`,
      method: "POST",
      data,
    });

    if (response.status === 200) {
      toast.success(response?.data?.data?.message);
      setQuestion("");
    } else {
      toast.error(response?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchcalenderdatafunc();
    fetchholidaycalenderdatafunc();
    // getGoldCard();
  }, []);


  const HandleCreateEvent = () => {
    // setModalF(true);
    navigate('/createevent')
  }

  return (
    <>
      <section className="engaghementmaindiv">
        <section id="usrclndr">
          <div className="headFirstPart">
            <GrowWel />
          </div>


          <div className="employeepagecontainer">
            <div className="employeecalederflx">
              {/* <div className="engageplacecardlistwhh">
                <div className="">
                 

                </div>
              </div> */}
              <div className="calenderwhhh">
                <div className="">


                  <BigCalender
                    fetchcalenderdatafunc={fetchcalenderdatafunc}
                    fetchcalenderdata={fetchcalenderdata}
                    fetchholidaycalenderdata={fetchholidaycalenderdata}
                  />
                </div>
              </div>

              <div className="highlightedsubsrwhh">
                <div className="user_right">
                  {/* <EngagementeventCard /> */}

                  {
                    userData?.userType === "Admin" || userData?.userType === "Manager" || userData?.userType === "Owner" ? (<div className="advocacycreatemain">
                      <div onClick={HandleCreateEvent} className="advocacycreatemaindiv" style={{ cursor: "pointer" }}>
                        <div className="addimgdiv">
                          <img src={Addicon} alt="..." />
                        </div>
                        <div className="">
                          <p className="crtadvocacytxtypp">Create Event</p>
                        </div>
                      </div>
                    </div>) : (<></>)
                  }
                  <EngagementPlaceCard />
                  {/* <ListofEngagement /> */}
                  {/* <EngagementPlaceCard /> */}
                  <div className="listdivnmaonimgbg">
                    <ListofEngagement />
                  </div>
                  <MysubscribedEvents />

                  <HighlightedEvents />


                  {/* <MysubscribedEvents /> */}
                </div>
              </div>
            </div>
          </div>

          <Chatbot />

          {modalF && (
            <EventsModal
              closemodal={setModalF}
              activity={"events"}
              initialValues={initialValues}
            />
          )}
        </section>
      </section>
    </>
  );
}

export default EngagementCalender;
