import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import HappyAnniversaryCardclean from "../../Images/HappyAnniversaryCardclean.png";
import personImg from "../../Images/personImg.png";
import companyelite from "../../Images/companyelite.png"
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const ForthCard = ({ initialValues, closemodal }) => {
    // console.log(initialValues, "initialValuespp")
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
    // const handleclosestop = (e) => {
    //     e.stopPropagation()
    // }

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const [empData, setempData] = useState([]);

    // console.log(initialValues,"initialValuespp")

    //for getting internal trainer name
    const getEmployeeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-company-employees`,
            method: "GET",
        });
        if (response && response.status) {
            // console.log("response", response)
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.email,
                image: item?.image
            }));
            setColourOptions(formattedOptions);
            setempData(response?.data?.data);

        } else {

        }
    };

    const handleSelectChange = (e) => {
        setSelectedOptions(e);
    };


    const submitHandler = async (e, selectedSubscription) => {
        e.preventDefault();
        setShouldValidateOnChange(true);
        setShouldValidateOnBlur(true);

        let data = {};
        if (values.cardName === "") {
            return toast.error("Card Name is required");
        }

        if (values.cardDesc === "") {
            return toast.error("Card Description is required");
        }

        if (!selectedOptions?.label) {
            return toast.error("Receiver Mail is required");
        }

        data = {
            cardName: values?.cardName,
            cardDesc: values?.cardDesc,
            receiverMail: selectedOptions?.label,
            receiverImage: selectedOptions?.image
        };
        // console.log("datadfh", data)
        // return false;
        const res = await ApiHelperFunction({
            urlPath: "/send-giftcard",
            method: "POST",
            data,
        });

        if (res?.status) {
            // console.log(res,"datadata")
            toast.success("Send Successfully");
            resetForm();
            closemodal();
        } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
            console.log("ERROR CREATING USER3", res);
        }

        // setLoading(false);
    };

    useEffect(() => {
        getEmployeeData();
    }, [])


    const { values, resetForm, handleChange } =
        useFormik({
            initialValues,
            // validationSchema: schemaCheck,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

    const handlestopClose = (e) => {
        e.stopPropagation();
    }
    return (
        <div className='allcardmodalonebgmain' onClick={() => closemodal()}>
            <div className='allcardonebigbg' onClick={(e) => handlestopClose(e)}>
                <div className="allcardsectionbgonecard" >
                    <div className=''>
                        <form>
                            <div className='toinpyrgpltpp'>
                                {/* <input type='text' placeholder='to...' /> */}
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    defaultValue={[]}
                                    // isMulti
                                    options={colourOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </form>
                    </div>
                    <div className='thankyoucrdbg' style={{ backgroundImage: `url(${HappyAnniversaryCardclean})` }}>
                        <div className='companylitlleimg'>
                            <img src={companyelite} alt="..." />
                        </div>
                        <div className='happyannyversytxtppflx'>
                            <div className='happyannyversyimgmain'>
                                <div className='happyannyversyimg'>
                                    {/* {
                                        values?.image ? (<img src={values?.image} alt="..." />) : (<img src={"https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png"} alt="..." />)
                                    } */}
                                    {
                                        selectedOptions?.image ? (<img src={selectedOptions?.image} alt="..." />) : (<img src={"https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png"} alt="..." />)
                                    }
                                    {/* <img src={personImg} alt="..." /> */}
                                    {/* <img src={selectedOptions?.image} alt="..." /> */}
                                </div>
                            </div>
                            <div className='txthappyanniversydiv'>
                                {/* <div className='yrscmpltdivmain'>
                                    <p className='fiveyrsrtxtpp'>5 Years</p>
                                    <p className='fiveyrsrtxtppcmplt'>Completion in the family</p>
                                </div> */}
                                <div className='happypritydivtxt'>
                                    <p className='happyannyvrtxtpp'>Happy Anniversary</p>
                                    <p className='thankyoutxtppcrd'>{values?.cardDesc}</p>
                                    {/* <p className='happyannyvrtxtppmanme'>Prity!</p> */}
                                </div>
                                {/* <div className=''>
                                    <p className='wishyutxtpgdpp'>Wish you a good future ahead</p>
                                    <p className='wishyutxtpgdppsrpp'>It's your day, dear.</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <form>
                            {/* <div className='toinpyrgpltpp'>
                                <input type='text' placeholder='for...' />
                            </div> */}
                            <div className='toinpyrgpltpp'>
                                <input
                                    type='text'
                                    name='cardDesc'
                                    value={values?.cardDesc}
                                    onChange={handleChange}
                                    placeholder='for...'
                                />

                            </div>
                        </form>
                    </div>
                    <div className='sndbtnmaibtnkk'>
                        <button
                            type='button'
                            onClick={submitHandler}
                            className='sndbtnmaibbg'
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForthCard