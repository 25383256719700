import React, { useEffect, useState } from 'react'
import { useAuthCtx } from '../../context/AuthCtx';
import { useFormik } from "formik";
import * as Yup from "yup";
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';
import Calender from "../../Images/Icons/PNG/Calender.png";
import Post from "../../Images/Icons/PNG/Post.png"

const Managerjobfirst = ({ activity }) => {
    const { setLoading } = useAuthCtx();
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
    const [managerData, setManagerData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);

    useEffect(() => {
        getManagerData();
        getDepartmentData()
    }, []);

    //for getting all manager data
    const getManagerData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-manager`,
            method: "GET",
        });
        if (response && response.status) {
            setManagerData(response?.data?.data);
        } 
    };

    //for fetching Department data
    const getDepartmentData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-department`,
            method: "GET",
        });
        if (response && response.status) {
            setDepartmentData(response?.data?.data);
        } 
    };


    //for submitting functionality
    const submitHandler = async (e) => {
        e.preventDefault();
        setShouldValidateOnChange(true);
        setShouldValidateOnBlur(true);
        console.log("handleSubmit", values);

        let data = {};
        setLoading(true);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the email is empty or not valid
        if (!values.interviewerEmail || !emailPattern.test(values.interviewerEmail)) {
            toast.error("Please enter a valid email address");
            return;

        }

        // if (!emailPattern.test(values.altEmail)) {
        //     toast.error("Please enter a valid alternative email address");
        //     return;
        // }


        if (values.jobTitle === "") {
            return toast.error("Job Title is required");
        }
        if (values.Department === "") {
            return toast.error("Department is required");
        }
        if (values.jobLocation?.length===0) {
            return toast.error("Location is required");
        }
        if (values.hiringManager === "") {
            return toast.error("HiringManager is required");
        }
        if (values.level === "") {
            return toast.error("level is required");
        }
        if (values.isNewPosition === "") {
            return toast.error("New Position is field required");
        }
        if (values.isTempPosition === "") {
            return toast.error("TempPosition is required");
        }
        if (values.candidateSourcing === "") {
            return toast.error("Candidate Sourcing is required");
        }
        if (values.hiringReason === "") {
            return toast.error("Hiring Reason is required");
        }
        if (values.priority === "") {
            return toast.error("priority is required");
        }
        // if (values.description === "") {
        //     return toast.error("Description is required");
        // }
        if (values.interviewerEmail?.length === 0) {
            return toast.error("InterviewerEmail is required");
        }
        if (values.openings === "") {
            return toast.error("Opening is required");
        }

        if (values.qualification === "") {
            return toast.error("qualification is required");
        }
        if (values.addQualification == "") {
            return toast.error("AddQualification is required");
        }
        if (values.experience_low === "") {
            return toast.error("Experience_low is required");
        }
        if (values.experience_high === "") {
            return toast.error("Experience_high is required");
        }
        if (values.Competencies === "") {
            return toast.error("Competencies is required");
        }
        if (values.inclusion === "") {
            return toast.error("Inclusion is required");
        }
        if (values.interviewProcess === "") {
            return toast.error("InterviewProcess is required");
        }
        if (values.criteria === "") {
            return toast.error("Criteria is required");
        }
        if (values.profileLink === "") {
            return toast.error("Profile Link is required");
        }
        if (values.salary === "") {
            return toast.error("Salary is required");
        }
        if (values.period === "") {
            return toast.error("Period is required");
        }
        if (values.keySkills === "") {
            return toast.error("Skill is required");
        }
        if (values.positionDescription === "") {
            return toast.error("Position Description is required");
        }
        if (values.responsibilities === "") {
            return toast.error("Responsibilities is required");
        }
        data = {
            // name: getNameFromUserId(values?.userId),
            // userId: values?.userId,
            // reason: values?.reason,

            jobTitle: values?.jobTitle,
            Department: values?.Department,
            jobLocation: values?.jobLocation,
            hiringManager: values?.hiringManager,
            level: values?.level,
            isNewPosition: values?.isNewPosition,
            isTempPosition: values?.isTempPosition,
            candidateSourcing: values?.candidateSourcing,
            hiringReason: values?.hiringReason,
            priority: values?.priority,
            interviewerEmail: values?.interviewerEmail,
            openings: values?.openings,
            qualification: values?.qualification,
            addQualification: values?.addQualification,
            experience_low: values?.experience_low,
            experience_high: values?.experience_high,
            Competencies: values?.Competencies,
            inclusion: values?.inclusion,
            interviewProcess: values?.interviewProcess,
            criteria: values?.criteria,
            profileLink: values?.profileLink,
            salary: values?.salary,
            salaryDate: values?.salaryDate,
            altEmail: values?.altEmail,
            responsibilities: values?.responsibilities,
            keySkills: values?.keySkills,
            period: values?.period,
            positionDescription: values?.positionDescription,
            isReffered: values?.isReffered
        };
        // console.log("click_training", data);
        const res = await ApiHelperFunction({
            urlPath: "/add-job-requirment",
            method: "POST",
            data,
        });
        if (res?.status) {
            toast.success("Manager Job Recruitment is added successfully");
            resetForm();
            // dispatch(getHallOfFame());
        } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
            console.log("ERROR CREATING USER3", res);
        }
        setLoading(false);
    };

    const schemaCheck = Yup.object(
        activity === "billBoard" ||
            activity === "events" ||
            activity === "kababMenu" ||
            activity === "editEvent"
            ? {
                eventName: Yup.string().required("Event Name is required"),
                hostedBy: Yup.string().required("Host name is required"),
                eventDate: Yup.string().required("Event date is required"),
                eventstarttime: Yup.string().required("start Time is required"),
                eventendtime: Yup.string().required(" End Time is required"),
                notes: Yup.string().required(" Notes is required"),
                addinvites: Yup.string().required(" Addinvites is required"),
                lattitude: Yup.string().required(" lattitude is required"),

                longitude: Yup.string().required(" longitude is required"),
            }
            : activity === "training" || activity === "editTraining"
                ? {
                    name: Yup.string().required("Event Name is required"),
                    trainingDate: Yup.string().required("Training date is required"),
                    link: Yup.string().required("Training link is required"),
                }
                : activity === "halloffame" || activity === "edithalloffame"
                    ? {
                        // name: Yup.string().required("name is required"),
                        reason: Yup.string().required("Reason is required"),
                    }
                    : activity === "jobReffered"
                        ? {
                            name: Yup.string().required("Job Name is required"),
                            location: Yup.string().required("Job location is required"),
                            salary: Yup.string().required("Salary range is required"),
                            description: Yup.string().required("Description range is required"),
                            opening: Yup.string().required("Opening is required"),
                        }
                        : activity === "managerJobPost"
                            ? {
                                jobTitle: Yup.string().required("Job Title is required"),
                                Department: Yup.string().required("Department is required"),
                                jobLocation: Yup.string().required("Job Location is required"),
                                level: Yup.string().required("Level is required"),
                                isNewPosition: Yup.string().required("Is New Position is required"),
                                interviewerEmail: Yup.array().of(Yup.string()),
                            }
                            : ""
    );

    const { values, handleBlur, resetForm, handleChange, setFieldValue } =
        useFormik({
            initialValues: {
                // isTempPosition: false,
                // isNewPosition: true,
                // priority: "",
                // experience_low: 0,
                // experience_high: 0,
                // interviewerEmail: [],
                jobTitle: "",
                Department: "",
                jobLocation: [],
                hiringManager: "",
                level: "",
                isNewPosition: "",
                isTempPosition: "",
                candidateSourcing: "",
                hiringReason: "",
                priority: "",
                description: "",
                interviewerEmail: [],
                openings: "",
                // keyResponsibilities: "",
                qualification: "",
                addQualification: "",
                experience_low: "",
                experience_high: "",
                Competencies: "",
                inclusion: "",
                interviewProcess: "",
                criteria: "",
                profileLink: "",
                salary: "",
                altEmail: "",
                period: "",
                keySkills: "",
                positionDescription: "",
                responsibilities: "",
                isReffered: false,
                salaryDate: ""
            },
            validationSchema: schemaCheck,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

 

    // Handler to add interviewer email by clicking on 'addimg'
    // const handleAddInterviewerEmail = () => {
    //     const newInterviewerEmails = [...values.interviewerEmail, '']; // Add an empty email field
    //     setFieldValue('interviewerEmail', newInterviewerEmails);
    // };

    // Handler to remove interviewer email
    // const handleRemoveInterviewerEmail = (index) => {
    //     const updatedInterviewerEmails = [...values.interviewerEmail];
    //     updatedInterviewerEmails.splice(index, 1);
    //     setFieldValue('interviewerEmail', updatedInterviewerEmails);
    // };


    // const handleClick = () => {
    //     if (document.querySelector('#DarkSwitch').checked) {
    //         document.body.classList.add("drakmode");
    //     }
    //     else {
    //         document.body.classList.remove("drakmode");
    //     }
    // }

    // const handleToggleChange = (e) => {
    //     console.log("togglee", e.target.checked)
    //     setCheck(e.target.checked)
    // }

    return (
        <>
            <div className='jobmaindtxlflx'>
                <div className='jobbrdrwhdt'>
                    <div className='jobdtksvbrdr'>
                        <div className=''>
                            <form>
                                <div className='fulljobdiv'>
                                    <div className='jobdtlsdiv'>
                                        <p className='jibtxt'>Job Title <span class="required">*</span></p>
                                        <input type='text'
                                            className='jobtxtinpt'
                                            name="jobTitle"
                                            value={values.jobTitle}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {/* <div className='jobdtlsdiv'>
                                        <p className='jibtxt'>Department/Team <span class="required">*</span></p>
                                        <input type='text'
                                            className='jobtxtinpt'
                                            name="Department"
                                            value={values.Department}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div> */}

                                    <div className='jobdtlsdiv'>
                                        <p className='jibtxt'>Department<span class="required">*</span></p>
                                        <select
                                            class="jobslctdiv"
                                            aria-label="Select Department name"
                                            name="Department"
                                            value={values?.Department}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            <option value={""} >
                                                Select Department
                                            </option>
                                            {departmentData?.map((item, i) => (
                                                <option key={i} value={item?._id}>
                                                    {item?.departmentName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='jobdtlsdiv'>
                                        <p className='jibtxt'>Hiring Manager<span class="required">*</span></p>
                                        {/* <input
                                        type='text'
                                        className='jobtxtinpt'
                                        name="hiringManager"
                                        value={values?.hiringManager}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    /> */}
                                        <select
                                            class="jobslctdiv"
                                            aria-label="Select Hiring Manager name"
                                            name="hiringManager"
                                            value={values?.hiringManager}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            <option value={""} >
                                                Select Manager
                                            </option>
                                            {managerData?.map((item, i) => (
                                                <option key={i} value={item?._id}>
                                                    {item?.firstName} {item?.lastName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='jobdtlsdiv'>
                                        <p className='jibtxt'>Location<span class="required">*</span></p>
                                        <input
                                            type='text'
                                            className='jobtxtinpt'
                                            name="jobLocation"
                                            value={values?.jobLocation}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className='jobdtlsdiv'>
                                        <p className='jibtxt'>Level<span class="required">*</span></p>
                                        <input
                                            type='number'
                                            className='jobtxtinpt'
                                            name="level"
                                            value={values?.level}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className='ysnopst'>
                                        <p className='jibtxt'>Is this a new Position? <span class="required">*</span></p>
                                        <div className='yxsbnflx'>
                                            <div className='mrtrdtys'>
                                                {/* <input type="radio" id="Yes" name="" defaultValue="yes" /> */}
                                                <input

                                                    type="radio"
                                                    id="true"
                                                    name="isNewPosition"
                                                    value="true"
                                                    checked={values?.isNewPosition === "true"}
                                                    // onChange={handleChange}
                                                    onChange={() => setFieldValue('isNewPosition', "true")}
                                                />
                                                <label htmlFor="html">Yes</label>
                                            </div>
                                            <div className='mrtrdtys'>
                                                {/* <input type="radio" id="No" name="" defaultValue="no" /> */}
                                                <input

                                                    type="radio"
                                                    id="false"
                                                    name="isNewPosition"
                                                    value="false"
                                                    checked={values?.isNewPosition === "false"}
                                                    // onChange={handleChange}
                                                    onChange={() => setFieldValue('isNewPosition', "false")}
                                                />
                                                <label htmlFor="html">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ysnopst'>
                                        <p className='jibtxt'>Is this a Temporary Position?<span class="required">*</span></p>
                                        <div className='yxsbnflx'>
                                            <div className='mrtrdtys'>
                                                {/* <input type="radio" id="Yes" name="" defaultValue="yes" /> */}
                                                <input

                                                    type="radio"
                                                    id="true"
                                                    name="isTempPosition"
                                                    value="true"
                                                    checked={values?.isTempPosition === "true"}
                                                    // onChange={handleChange}
                                                    onChange={() => setFieldValue('isTempPosition', "true")}
                                                />
                                                <label htmlFor="html">Yes</label>
                                            </div>
                                            <div className='mrtrdtys'>
                                                {/* <input type="radio" id="No" name="" defaultValue="No" /> */}
                                                <input
                                                    // defaultValue="no"
                                                    type="radio"
                                                    id="false"
                                                    name="isTempPosition"
                                                    value="false"
                                                    checked={values?.isTempPosition === "false"}
                                                    // onChange={handleChange}
                                                    onChange={() => setFieldValue('isTempPosition', "false")}
                                                />
                                                <label htmlFor="html">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ysnopst'>
                                        <p className='jibtxt'>Level Of Priority<span class="required">*</span></p>
                                        <div className='hgmdlwflx'>

                                            {/* <button
                                                className='gnjhbtn'
                                                type='button'
                                                onClick={() => handlePriorityClick("high")}
                                                onClick={() => setFieldValue(prev => ({ ...prev, priority: "High" }))}
                                            >
                                                High
                                            </button>
                                            <button
                                                className='gnjhbtn'
                                                type='button'
                                                onClick={() => handlePriorityClick("mid")}
                                                onClick={() => setFieldValue(prev => ({ ...prev, priority: "Mid" }))}
                                            >
                                                Medium
                                            </button>
                                            <button
                                                className='gnjhbtn'
                                                type='button'
                                                onClick={() => handlePriorityClick("low")}
                                                onClick={() => setFieldValue(prev => ({ ...prev, priority: "Low" }))}
                                            >
                                                Low
                                            </button> */}

                                            <button
                                                className={values?.priority === "High" ? "btnscactb" : "plnbtnact"}
                                                type='button'
                                                onClick={() => setFieldValue('priority', 'High')}
                                            >
                                                High
                                            </button>
                                            <button
                                                className={values?.priority === "Mid" ? "btnscactb" : "plnbtnact"}
                                                type='button'
                                                onClick={() => setFieldValue('priority', 'Mid')}
                                            >
                                                Medium
                                            </button>
                                            <button
                                                className={values?.priority === "Low" ? "btnscactb" : "plnbtnact"}
                                                type='button'
                                                onClick={() => setFieldValue('priority', 'Low')}
                                            >
                                                Low
                                            </button>
                                        </div>
                                    </div>
                                    <div className='ysnopst'>
                                        <p className='jibtxt'>Candidate Sourcing Preference<span class="required">*</span></p>
                                        <div className='hgmdlwflx'>
                                            {/* <button
                                                type='button'
                                                className='gnjhbtn'
                                                onClick={() => setFieldValue(prev => ({ ...prev, candidateSourcing: "External" }))}
                                            >
                                                External
                                            </button>
                                            <button
                                                type='button'
                                                className='gnjhbtn'
                                                onClick={() => setFieldValue(prev => ({ ...prev, candidateSourcing: "Internal" }))}
                                            >
                                                Internal
                                            </button>
                                            <button type='button'
                                                className='gnjhbtn'
                                                onClick={() => setFieldValue(prev => ({ ...prev, candidateSourcing: "Both" }))}
                                            >
                                                Both
                                            </button> */}

                                            <button
                                                className={values?.candidateSourcing === "External" ? "btnscactb" : "plnbtnact"}
                                                type='button'
                                                onClick={() => setFieldValue('candidateSourcing', 'External')}
                                            >
                                                External
                                            </button>
                                            <button
                                                className={values?.candidateSourcing === "Internal" ? "btnscactb" : "plnbtnact"}
                                                type='button'
                                                onClick={() => setFieldValue('candidateSourcing', 'Internal')}
                                            >
                                                Internal
                                            </button>
                                            <button
                                                className={values?.candidateSourcing === "Both" ? "btnscactb" : "plnbtnact"}
                                                type='button'
                                                onClick={() => setFieldValue('candidateSourcing', 'Both')}
                                            >
                                                Both
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>

                <div className='jobdtlswh'>
                    <div className='dtlskmflx'>
                        <h4 className='dtlstxtpphh'>Details</h4>
                        {/* <p className='jibtxt'><span class="required">*</span>Mandatory</p> */}
                        <div className='rfrlinpflx'>
                            <h6
                            // className='opnttxt'
                            ><span class="required"></span>Open to Refferal Hiring</h6>
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="DarkSwitch"
                                    // onClick={handleClick}
                                    // checked={isCheck}
                                    // onChange={(e) => handleToggleChange(e)}
                                    checked={values?.isReffered}
                                    onChange={(e) => setFieldValue('isReffered', e.target.checked)}
                                />
                                <label className="custom-control-label" htmlFor="DarkSwitch"></label>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <form>
                            <div className='alldtlsfrmdiv'>
                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Reason for Hire<span class="required">*</span></p>
                                    </div>
                                    <div className='rsntxrptxtdiv'>
                                        <textarea
                                            cols={50}
                                            rows={2}
                                            type="text"
                                            className='rsjobtxtdiv'
                                            name="hiringReason"
                                            value={values?.hiringReason}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                </div>

                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Position Description<span class="required">*</span></p>
                                    </div>
                                    <div className='rsntxrptxtdiv'>
                                        <textarea
                                            type="text"
                                            cols={50}
                                            rows={2}
                                            className='rsjobtxtdiv'
                                            name="positionDescription"
                                            value={values?.positionDescription}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                </div>


                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Interviewer Email Id
                                            <span class="required">*</span>
                                        </p>
                                    </div>
                                    <div className='hrdivtxtmain'>
                                        <input
                                            type="email"
                                            className='hrdivtxt'
                                            name="interviewerEmail"
                                            value={values?.interviewerEmail}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Alternative Email
                                            <span class="required"></span>
                                        </p>
                                    </div>
                                    <div className='hrdivtxtmain'>
                                        <input
                                            type="email"
                                            className='hrdivtxt'
                                            name="altEmail"
                                            value={values?.altEmail}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Nature of Engagement<span class="required">*</span></p>
                                    </div>
                                    <div className='btnflxmain'>
                                        <div className={values?.period === "FullTime" ? "btnscactb" : "plnbtnact"}
                                            // onClick={handlebtnClick}
                                            onClick={() => setFieldValue('period', 'FullTime')}
                                        >Full Time</div>
                                        <div className={values?.period === "PartTime" ? "btnscactb" : "plnbtnact"}
                                            // onClick={handleconClick}
                                            onClick={() => setFieldValue('period', 'PartTime')}
                                        >Part Time</div>

                                        <div className={values?.period === "Internship" ? "btnscactb" : "plnbtnact"}
                                            // onClick={handleinClick}
                                            onClick={() => setFieldValue('period', 'Internship')}
                                        >Internship</div>
                                        <div className={values?.period === "Contractual" ? "btnscactb" : "plnbtnact"}
                                            // onClick={handleconClick}
                                            onClick={() => setFieldValue('period', 'Contractual')}
                                        >Contractual</div>
                                    </div>
                                </div>
                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Skills<span class="required">*</span></p>
                                    </div>
                                    <div className='rsntxrptxtdiv'>
                                        <input type="text" className='rsjobtxtdiv'
                                            name="keySkills"
                                            value={values?.keySkills}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>No. of Hire<span class="required">*</span></p>
                                    </div>
                                    <div className='hrdivtxtmain'>
                                        <input
                                            type="number"
                                            className='hrdivtxt'
                                            name="openings"
                                            value={values?.openings}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Responsibilities<span class="required">*</span></p>
                                    </div>
                                    <div className='rsntxrptxtdiv'>
                                        <textarea
                                            cols={50}
                                            rows={2}
                                            type="text"
                                            className='rsjobtxtdiv'
                                            name="responsibilities"
                                            value={values?.responsibilities}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Minimum Qualification<span class="required">*</span></p>
                                    </div>
                                    <div className='rsntxrptxtdiv'>
                                        <textarea
                                            cols={50}
                                            rows={2}
                                            type="text"
                                            className='rsjobtxtdiv'
                                            name="qualification"
                                            value={values?.qualification}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Additional Qualification
                                            {/* <span class="required">*</span> */}
                                        </p>
                                    </div>
                                    <div className='rsntxrptxtdiv'>
                                        <textarea
                                            cols={50}
                                            rows={2}
                                            type="text"
                                            className='rsjobtxtdiv'
                                            name="addQualification"
                                            value={values?.addQualification}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                {/* <div className='qualiaddi'>
                                    <div className='minimdiv'>
                                        <div className="addqtmntxt">
                                            <p className="rsntxrp">Minimum Qualification<span className="required">*</span></p>
                                        </div>
                                        <div className="qulitxtar">
                                            <input
                                                type="text"
                                                className="qulitxtardiv"
                                                name="qualification"
                                                value={values?.qualification}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='addnidiv'>
                                        <div className="addqtmntxt">
                                            <p className="rsntxrp">Additional Qualification
                                               
                                            </p>
                                        </div>
                                        <div className="qulitxtar">
                                            <input
                                                type="text"
                                                className="qulitxtardiv"
                                                name="addQualification"
                                                value={values?.addQualification}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Experience(No. of Years)<span class="required">*</span></p>
                                    </div>
                                    <div className='frmtodivflx'>
                                        <div className='frmstrt'>
                                            <>
                                                <label htmlFor="">From</label>
                                                {/* <select name="" id="">
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select> */}
                                                <select
                                                    name="experience_low"
                                                    value={values?.experience_low}
                                                    onChange={handleChange}
                                                    className='experience-select'
                                                >
                                                    <option>Select</option>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </>

                                        </div>
                                        <div className='frmend'>
                                            <>
                                                <label htmlFor="">To</label>
                                                {/* <select name="" id="">
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select> */}
                                                <select
                                                    name="experience_high"
                                                    value={values?.experience_high}
                                                    onChange={handleChange}
                                                    className='experience-select'
                                                >
                                                    <option>Select</option>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </>

                                        </div>
                                    </div>
                                </div>
                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Competencies and Traits<span class="required">*</span></p>
                                    </div>
                                    <div className='rsntxrptxtdiv'>
                                        <textarea
                                            cols={50}
                                            rows={2}
                                            type="text"
                                            className='rsjobtxtdiv'
                                            name="Competencies"
                                            value={values?.Competencies}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Inclusion Considerations<span class="required">*</span></p>
                                    </div>
                                    <div className='rsntxrptxtdiv'>
                                        <textarea
                                            cols={50}
                                            rows={2}
                                            type="text"
                                            className='rsjobtxtdiv'
                                            name="inclusion"
                                            value={values?.inclusion}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='positionwhnflx'>
                                    <div className='avgdixflx'>
                                        <div className='avgsldiv'>
                                            <p className='rsntxrp'>Avg. Salary for the Position<span class="required">*</span></p>
                                        </div>
                                        <div className='avgdivmain'>
                                            <input
                                                type="number"
                                                className='avgdiv'
                                                name="salary"
                                                value={values?.salary}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='whendixflx'>
                                        <div className='addldiv'>
                                            <p className='rsntxrp'>By When<span class="required">*</span></p>
                                        </div>
                                        <div className='caledricontxtflsx'>
                                            <div className='clndrimg'>
                                                <img src={Calender} alt="..." />
                                            </div>
                                            <div className='avgdivmain'>
                                                <input
                                                    type="date"
                                                    className='avgdiv'
                                                    name="salaryDate"
                                                    value={values?.salaryDate}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Interview Process<span class="required">*</span></p>
                                    </div>
                                    <div className='rsntxrptxtdiv'>
                                        <input
                                            type="text"
                                            className='rsjobtxtdiv'
                                            name="interviewProcess"
                                            value={values?.interviewProcess}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Evaluation Criteria<span class="required">*</span></p>
                                    </div>
                                    <div className='rsntxrptxtdiv'>
                                        <textarea
                                            cols={50}
                                            rows={2}
                                            type="text"
                                            className='rsjobtxtdiv'
                                            name="criteria"
                                            value={values?.criteria}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='senddivrxr'>
                                    <div className='prvdlinkdiv'>
                                        <p className='rsntxrp'>Sample Profile From Job Board</p>
                                        <p className='rsntxrp'>Provided Link <span class="required">*</span></p>
                                    </div>
                                    <div className='rsntxrptxtdiv'>
                                        <input
                                            type="text"
                                            className='rsjobtxtdiv'
                                            name="profileLink"
                                            value={values?.profileLink}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className='sbmtdivmain'>
                                    <button className='sbmtdiv'
                                        type="button"
                                        onClick={submitHandler}>
                                        <div className='pstimg'>
                                            <img src={Post} alt="..." />
                                        </div>
                                        <div className='hrtxty'>
                                            Submit to HR
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div >
                </div >
            </div>
        </>
    )
}

export default Managerjobfirst