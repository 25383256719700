import React, { useState, useEffect } from "react";
import { useAuthCtx } from "../../context/AuthCtx";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import { useDispatch } from "react-redux";
import { getInitiative } from "../../redux/slices/initiativeSlice";
import moment from "moment";
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import { useNavigate } from "react-router-dom";

const animatedComponents = makeAnimated();


const InitiativeModal = () => {
  const initialValues = {
    initiativeTypeID: "",
    nameOfInitaitive: "",
    description: "",
    startDate: "",
    endDate: "",
    rewardPoints: "",
    assignedUsers: [],
    departmentId: [],
    initiativeView: ""
  }
  const { setLoading, userData } = useAuthCtx();
  const dispatch = useDispatch();
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
  const [filteredInitiativeTypes, setFilteredInitiativeTypes] = useState([]);
  const [DepartmentDropdown, setDepartmentDropdown] = useState([]);
  const [departmentId, setDepartmentId] = useState([]);
  const [UserDropdown, setUserDropdown] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [rewardP, setRewardP] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    getAllInitiativeType();
    getDepartmentData();
    // getUserData();
  }, [])


  useEffect(() => {
    if (departmentId.length > 0) {
      getUserData();
    }
  }, [departmentId]);



  //view all initiative type
  const getAllInitiativeType = async () => {
    setLoading(true);

    const response = await ApiHelperFunction({
      urlPath:"/view-userwise-initiative-type",
      // urlPath: "/view-initiative-type",
      method: "GET",
    });

    if (response.status === 200) {

      // Filter based on userType
      // const userType = userData?.userType;
      // if (userType === "Admin") {
      //   setFilteredInitiativeTypes(response?.data?.data?.filter(item => item.initiativeFor === "Admin"));
      // } else if (userType === "Manager") {
      //   setFilteredInitiativeTypes(response?.data?.data?.filter(item => item.initiativeFor === "Manager"));
      // }
      setFilteredInitiativeTypes(response?.data?.data);

    } else {
      // toast.error(response.message);
    }

    setLoading(false);
  };

  // get Department Data
  const getDepartmentData = async () => {
    const res = await ApiHelperFunction({
      urlPath: "/view-department",
      method: "GET"
    });
    let apiData = []
    if (res && res?.status) {
      apiData = res?.data?.data?.map((item, i) => ({
        label: item?.departmentName,
        value: item?._id,

      }));
    }
    setDepartmentDropdown(apiData);
  }


  // get User Data department-wise
  const getUserData = async () => {
    let data = {
      departmentArray: departmentId
    }
    const res = await ApiHelperFunction({
      urlPath: "/fetch-user-department-wise",
      method: "POST",
      data
    });

    // console.log("bnmkl",data)
    let apiData = []
    if (res && res?.status) {
      apiData = res?.data?.data?.map((item) => ({
        label: item?.userName,
        value: item?._id,

      }));
      apiData?.unshift({ label: "All", value: "all" });
    }
    setUserDropdown(apiData);
  }


  // onchange of department data 
  const handleSelectDepartment = e => {
    setDepartmentId(e ? e?.map(option => option.value) : []);
  }

  // onchange of User data
  const handleSelectUser = e => {
    const values = e ? e.map(option => option.value) : [];
    if (values.includes("all")) {
      setAssignedUsers(UserDropdown.filter(option => option.value !== "all").map(option => option.value));
    } else {
      setAssignedUsers(values);
    }
  }


  const submitHandler = async (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    setShouldValidateOnBlur(true);
    console.log("handleSubmit", values);

    let data = {};
    setLoading(true);
    if (
      values.nameOfInitaitive === "" ||
      values.description === "" ||
      values.startDate === "" ||
      values.endDate === "" ||
      values.rewardPoints === "" ||
      values?.initiativeView === "" ||
      departmentId?.length === 0 ||
      assignedUsers?.length === 0
    ) {
      return toast.error("All fields required");
    }

    if (parseInt(values.rewardPoints) > parseInt(rewardP)) {
      return toast.error(`Reward Points should be less than or equal to ${rewardP}`);
    }

    data = {
      initiativeTypeID: values?.initiativeTypeID,
      nameOfInitaitive: values?.nameOfInitaitive,
      description: values?.description,
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
      rewardPoints: values?.rewardPoints,
      initiativeView: values?.initiativeView,
      departmentId: departmentId,
      assignedUsers: assignedUsers
    };

    console.log("kkkkkkkop", data);
    // return false;
    const res = await ApiHelperFunction({
      urlPath: "/add-initiative",
      method: "POST",
      data,
    });
    if (res?.status) {
      console.log(res, "ressssssss");
      toast.success(res?.data?.message);
      resetForm();
      dispatch(getInitiative());
      navigate('/initiativeDetail')
    } else {
      toast.error(res?.message || "Something went wrong");
    }
    setLoading(false);
  };




  const { values, handleBlur, resetForm, handleChange, setFieldValue } =
    useFormik({
      initialValues,
      validateOnChange: shouldValidateOnChange,
      validateOnBlur: shouldValidateOnBlur,
      onSubmit: (val) => {
        console.log("val", val);
      },
    });


  // get Department Data
  const getRewardData = async () => {
    const res = await ApiHelperFunction({
      urlPath: `/view-reward-point/${values?.initiativeTypeID}`,
      method: "GET"
    });

    if (res && res?.status) {
      setRewardP(res?.data?.data?.rewardPoints);
    }
  }

  useEffect(() => {
    getRewardData();
  }, [values?.initiativeTypeID]);


  return (
    <div className="projectaddfrm">
      <div className="employeepagecontainer">


        <div className="advocacyfrmdivmain">

          <form>
            <div>

              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt">Project List</p>
                <select id=""
                  value={values?.initiativeTypeID}
                  name="initiativeTypeID"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="typstrningwhselctnew"
                >

                  <option value={""}>
                    Select Initiative Type
                  </option>
                  {filteredInitiativeTypes?.map((item, i) => (
                    <option key={i} value={item?._id}>
                      {item?.initiativetype}
                    </option>
                  ))}
                </select>
              </div>

              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt">Name of Project</p>
                <input
                  type="text"
                  placeholder="Enter Project name"
                  id="nameOfInitaitive"
                  aria-describedby="emailHelp"
                  value={values?.nameOfInitaitive}
                  name="nameOfInitaitive"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="typstrningwhselctnew"

                />
              </div>

              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt">Description</p>
                <input
                  type="text"
                  placeholder="Enter Description"
                  id="description"
                  aria-describedby="emailHelp"
                  value={values?.description}
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="typstrningwhselctnew"

                />
              </div>

              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt">Select One</p>
                <div className="isHighLightedRadiodivnewdiv">
                  <div className='isHighLightedRadiodivnew'>
                    <input
                      type="radio"
                      id=""
                      name="initiativeView"
                      value="department"
                      checked={values?.initiativeView === "department"}
                      onChange={() => setFieldValue('initiativeView', 'department')}

                    />
                    <label style={{ color: "black" }}>Department</label>
                  </div>

                  {
                    userData?.userType === "Admin" || userData?.userType === "Owner" ?
                      (
                        <div className='isHighLightedRadiodivnew'>
                          <input
                            type="radio"
                            id=""
                            name="initiativeView"
                            value="all"
                            checked={values?.initiativeView === "all"}
                            onChange={() => setFieldValue('initiativeView', 'all')}

                          />
                          <label style={{ color: "black" }}>All</label>
                        </div>
                      )
                      :
                      <></>
                  }

                </div>
              </div>


              {
                values?.initiativeView === "department"
                  ?
                  (
                    <>
                      <div className="trainingpageleftdivmain">
                        <p className="rsntxrpnewtxt">Department</p>

                        <Select
                          closeMenuOnSelect={false}
                          className="typstrningwhselctnew"
                          components={animatedComponents}
                          defaultValue={[]}
                          isMulti
                          options={DepartmentDropdown}
                          onChange={handleSelectDepartment}
                          value={DepartmentDropdown?.filter(option => departmentId?.includes(option.value))}
                        />
                      </div>

                      <div className="trainingpageleftdivmain">
                        <p className="rsntxrpnewtxt">Select People</p>
                        <Select
                          closeMenuOnSelect={false}
                          className="typstrningwhselctnew"
                          components={animatedComponents}
                          defaultValue={[]}
                          isMulti
                          options={UserDropdown}
                          onChange={handleSelectUser}
                          value={UserDropdown?.filter(option => assignedUsers?.includes(option.value))}
                        />
                      </div>

                    </>
                  )
                  :
                  (
                    <></>
                  )
              }

              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt">Start Date</p>
                <input
                  type="date"
                  placeholder="Enter Start Date"
                  // className="form-control"
                  className="typstrningwhselctnew"
                  id="exampleInputPassword1"
                  value={moment(values.startDate).format("YYYY-MM-DD")}
                  min={moment().format("YYYY-MM-DD")}
                  name="startDate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>

              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt">End Date</p>
                <input
                  type="date"
                  placeholder="Enter End Date"
                  // className="form-control"
                  className="typstrningwhselctnew"
                  id="exampleInputPassword1"
                  value={moment(values.endDate).format("YYYY-MM-DD")}
                  min={moment(values.startDate).add(1, "days").format("YYYY-MM-DD")}
                  name="endDate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>

              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt">Reward Points</p>
                <input
                  type="number"
                  placeholder={`Reward Points should be less than or equal to ${rewardP}`}
                  // className="form-control"
                  className="typstrningwhselctnew"
                  id="exampleInputPassword1"
                  value={values?.rewardPoints}
                  name="rewardPoints"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  min="0"
                />
              </div>

              <div className="bulletinsbmtbtndivv">
                <button
                  type="submit"
                  onClick={submitHandler}
                  className="bulletinsbmtbtn"
                  style={{ margin: "0 auto", display: "table" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* </div> */}


      </div>
    </div>
    // </div>
  );
};

export default InitiativeModal;
