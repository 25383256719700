import React, { useEffect, useState } from 'react'
import allarticlevwimg from "../../Images/allarticlevwimg.png";
import Createdocument from './Createdocument';
import Recentdoc from './Recentdoc';
import Answerquiz from "../../Images/Icons/PNG/Answerquiz.png"
import {useParams } from 'react-router-dom';
import download from "../../Images/Icons/PNG/download.png";
import moment from 'moment';
import { ApiHelperFunction } from '../../services/api/apiHelpers';

const FullArticleview = () => {
    const params=useParams();
    const [article,setArticle]=useState({});
    // const location = useLocation();
    // const article = location?.state;

    
    const SingleArticle = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-single-documents/${params?.id}`,
            method: "GET"
        });

        if (response.status === 200) {
            setArticle(response?.data?.data?.[0])
        } else {
        
        }
        
    };

    useEffect(() => {
        SingleArticle();
    }, [])


    console.log("opuitykl", article);
    return (
        <>
            <section className='fullarticleviewmain'>
                <div className='employeepagecontainer'>
                    <div className='fullarticleviewmainflx'>
                        <div className='fullarticleviewmainleft'>
                            <div className='fullarticleviewbg'>
                                {
                                    article?.docImage === "docImage" ? (<div className='allarticlevwimgdivmai'>
                                        <img src={allarticlevwimg} alt="..." />
                                    </div>) : (<div className='allarticlevwimgdivmai'>
                                        <img src={article?.docImage} alt="..." />
                                    </div>)
                                }


                                <div className='purchaseprfolileflx'>
                                    <div className='articleprofiletextflx'>
                                        <div className='profilearticlebrdr'>
                                            <div className='purcahseprofile'>
                                                <img src={article?.addedBy?.[0]?.image} alt="..." />
                                            </div>
                                        </div>
                                        <div className=''>
                                            <p className='purchseprflnametext'>{article?.addedBy?.[0]?.firstName} {article?.addedBy?.[0]?.lastName}</p>
                                        </div>
                                    </div>
                                    <div className='purchsedepartmentflx'>
                                        {/* <button className='purchebtn'>Purchse</button>
                                        <button className='departbtn'>Department</button> */}


                                        <div className='downladimgmainpl'>
                                            {
                                                article?.docUrl !== "" ? (<div className='dateshowlinkdiv'><img src={download} alt="..." /></div>) : (<div><img src={download} alt="..." /></div>)
                                            }

                                            <div className='downloaddivmaindiv'>

                                                {
                                                    article?.docUrl !== "" && (
                                                        <>
                                                            <div className='downloaddivmaindivsize' >

                                                                <a href={article?.docUrl?.startsWith("http") ? article?.docUrl : `http://${article?.docUrl}`} target="_blank" rel="noopener noreferrer">
                                                                    {article?.docUrl?.slice(0, 20)}
                                                                </a>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className=''>
                                    <p className='articleversntext'>Version : {article?.version}
                                    </p>
                                    <p className='articleversntext'>Version Approve Date : {moment(article?.createdOn)?.format("DD-MM-YYYY")}
                                    </p>
                                </div>

                                <div className='Sourcingtextppdivall'>
                                    <h4 className='Sourcingtextpp'>Defining “Sourcing”
                                    </h4>
                                    <p className='purchsesrcingtext'>{article?.details}
                                    </p>
                                    {/* <p className='purchsesrcingtext'>Employee satisfaction, meanwhile, is a measure of how happy someone is at work. Engaged employees are more likely to be satisfied employees. Employee Engagement is important for a great workplace because if an employee isn’t engaged, they’re less productive, and may provide a lower quality of work. They’re also more likely to experience burnout. Eventually, this will lead to them leaving, which will increase your employee turnover. All this will impact your customer satisfaction and workplace culture, and may cause decreased profits.
                                    </p>
                                    <p className='purchsesrcingtext'>On the flip side, engaged employees experience greater workplace satisfaction and are therefore more productive in their roles. They provide a greater quality of work and customer service, which translates to the business getting better reviews and making more money.
                                    </p> */}
                                </div>

                                <div className='purchasetextdivppcheckbx'>
                                    <input type="checkbox" id="" name="" defaultValue="" />
                                    <label htmlFor=""> I have read and understood the article.
                                    </label>
                                </div>

                                <div className='completionassenmntflx'>
                                    <button className='completeaseentbtn'>
                                        <div className='Answerquizdivimg'>
                                            <img src={Answerquiz} alt="..." />
                                        </div>
                                        Verify Completion
                                    </button>
                                    <button className='completeaseentbtn'>
                                        <div className='Answerquizdivimg'>
                                            <img src={Answerquiz} alt="..." />
                                        </div>
                                        Complete Your Assessment
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className='fullarticleviewmainright'>
                            <Createdocument />
                            {/* */}
                            <Recentdoc />
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default FullArticleview
