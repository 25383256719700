import React, { useEffect, useState } from "react";
import { useAuthCtx } from "../../context/AuthCtx";
import { useDispatch, useSelector } from "react-redux";
import { getRefferedJobs } from "../../redux/slices/jobRefferedSlice";
import { useNavigate } from "react-router-dom";
import JobRefferedModal from "../../SeprateModal/JobRefferedModal";
import { RWebShare } from "react-web-share";
import table_icon from "../../Images/Icons/PNG/share-icon-elite-web.svg"
import toast from "react-hot-toast";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import EditJobRefferedModal from "../../SeprateModal/EditJobRefferedModal";
import HiringDetailsModal from "../../SeprateModal/HiringDetailsModal";
import Hiringicon from "../../Images/Icons/PNG/Hiringicon.png"
import ReferModal from "../../Component/Modal/ReferModal";
import JobApplyModal from "../../Component/Modal/JobApplyModal";
import Initiative from "../../Component/Initiative";
import TrainingCard from "../../Component/TrainingCard";
import EventCard from "../../Component/EventCard";
import Onboardingcard from "./Onboardingcard";
import add_icon from "../../Images/Icons/PNG/Add icon.png"
import Chatbot from "../../Component/Chatbot/Chatbot";


const initialValues = {
  name: "",
  location: "",
  salary: "",
  opening: "",
  description: "",
};
export default function JobsReferDetail() {
  const [modalA, setModalA] = useState(false);
  const [modalB, setModalB] = useState(false);
  const [modalId, setModalId] = useState("");
  const [jobrefferedData, setJobrefferedData] = useState({});
  const jobs = useSelector((state) => state.jobSlice.jobs);
  const { userData, getUserDetails } = useAuthCtx();
  const [modala, setModala] = useState(false);
  const dispatch = useDispatch();
  const [hiringviewmodal, setHirringviewmodal] = useState(false)
  const [refer, setRefer] = useState(false);
  const [applyModal, setApplyModal] = useState(false);

  const navigate = useNavigate()
  // const createModal = () => {
  //   setModala(true);
  // };

  const handlejobsdetaisClick = (item) => {
    // console.log("itemmmmmfff", item)
    // setJobrefferedData(item);
    navigate(`/jobssingledetails/${item?.jobTitle?.replace(/\s+/g, '-')}/${item?._id}`);
  }

  console.log("jobrefferedDatatwo", jobrefferedData)
  // const handlehiringClick =()=>{
  //   setHirringviewmodal(!hiringviewmodal)
  // }

  const handlehiringClick = (ele) => {
    // console.log("nmcverio", ele)
    setJobrefferedData(ele);
    setHirringviewmodal(true);
    setRefer(false);
    setApplyModal(false);
  };



  const handleClick = (e, ele, index) => {
    setModalId(ele._id);
    // console.log("EVENT", e, index);
    setJobrefferedData(ele);
    // console.log("s", e, ele);
    // setTop(e.clientY);
    // setLeft(e.clientX);
    setModalA(!modalA);
  };

  console.log(modalId, "modalIdop")

  const handleDelete = async () => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-job-reffered/${modalId}`,
      method: "DELETE",
    });
    if (res?.status) {
      toast.success("Job is deleted successfully");

      dispatch(getRefferedJobs());
      setModalA(false);
    } else {
      toast.error(res?.message || "Something went wrong");
      console.log("ERROR CREATING USER3", res);
    }
  };

  useEffect(() => {
    dispatch(getRefferedJobs());
    getUserDetails();
  }, []);
  return (
    <section className="jobsrefrerdetail">
      <div className="">

        <div className="homepagecontainer">

          <div className="thought_area_mxh-left hirimngspclleft">

        
            <div className="iniSusTabDiv">
              <Initiative />
            </div>

            <TrainingCard />
          </div>
          <div className="thought_area_mxh-mid" style={{ paddingTop: "0px" }}>
            <section  className="jobstrfredisblck">
              <div className="top_area">
                <div className="head jntxtimgflx">
                  <div className="initaimg">
                    <img src={Hiringicon} alt="..." />
                  </div>
                  <h4>Hiring</h4>
                  <div className="">
                  {userData?.userType === "Admin" || userData?.userType === "Owner"
                  
                    ? (
                      
                      <div className="tyEvMainDiv" onClick={() => navigate(`/hrJobPost`)}>
                        {/* <span className="tyEvIcon"> */}
                        <figure className="bulleBoardFig">
                          <img src={add_icon} alt="..." />
                        </figure>
                   
                      </div>

                    ) :
                    userData?.userType === "Manager"
                    
                      ? (
                        
                        <div className="tyEvMainDiv" onClick={() => navigate(`/managerJobPost`)}>
                         
                          <figure className="bulleBoardFig">
                            <img src={add_icon} alt="..." />
                          </figure>
                       
                        </div>

                      )
                      : (<></>)}
                  <div>

                  </div>
                </div>
                </div>
                <div>
                 
                </div>
              </div>

              <div className="bottom_area_details">

                {jobs?.length > 0 ? (
                  jobs?.map((item, index) => {
                    return (
                      <div className="bottom_area_detailsdiv" >
                        <div className="bottom_area_detailsdivdivflx">
                          <div className="jobsredferdrttgdibvmanbhk" onClick={() => handlejobsdetaisClick(item)}>
                            <div className="head">
                              <h4 style={{ fontSize: "16px", fontWeight: 600, lineHeight: "18px", fontFamily: "Notosans",marginBottom:"5px" }}>{item?.jobTitle}</h4>
                            </div>
                            <div className="details">
                              <p className="lbndapplicdtgsiuci">
                                Location : <span>{item?.jobLocation}</span>
                              </p>
                              <p className="lbndapplicdtgsiuci">
                                Skill :  <span>{item?.keySkills}</span>
                              </p>
                              <p className="lbndapplicdtgsiuci">
                                Opening : <span>{item?.openings}</span>
                              </p>
                             
                                {/* <p className="ldestrcjobpojsk">
                                Description :
                                <span>{item?.description}</span>
                              </p> */}
                                {/* <div className="viewbtnnndivhajj" onClick={() => handlehiringClick(item)}>
                                  <button className="viewbtnnn">View</button>
                                </div> */}

                              
                            </div>

                          </div>

                          <div className="">
                            <button className="cmpltdrbtnkbhagn">Active</button>
                          </div>



                        </div>

                      </div>
                    );
                  })
                ) : (
                  <p>No jobs to show! </p>
                )}
              </div>
            </section>
          </div>
          <div className="thought_area_mxh_right">
            <EventCard />
            {/* <JobsReferred /> */}
            <Onboardingcard />
          </div>



        </div>




        {modala && (
          <JobRefferedModal
            closemodal={setModala}
            activity={"jobReffered"}
            initialValues={initialValues}
          />
        )}

      </div>
      <Chatbot />

      {
        modalB && (<EditJobRefferedModal
          closemodal={setModalB}
          activity={"editJob"}
          initialValues={jobrefferedData}
        />)
      }

      {hiringviewmodal && <HiringDetailsModal
        closemodal={setHirringviewmodal}
        jobrefferedData={jobrefferedData}
        setRefer={setRefer}
        setApplyModal={setApplyModal}
      />}

      {
        refer && (<ReferModal closemodal1={setRefer} id={jobrefferedData?._id} />)
      }

      {/* {
        console.log("cvaser", jobrefferedData?._id)
      } */}

      {
        applyModal && (<JobApplyModal closemodal1={setApplyModal} id={jobrefferedData?._id} />)
      }
    </section>
  );
}
