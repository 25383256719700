import React, { useEffect, useState } from 'react'
import Share from "../../src/Images/Icons/PNG/Share.png";
import Refer from "../../src/Images/Icons/PNG/Refer.png";
import Skills from "../../src/Images/Icons/PNG/Skills.png";
import Designation from "../../src/Images/Icons/PNG/Designation.png";
import time from "../../src/Images/Icons/PNG/time.png";
import LocationIcon from "../../src/Images/Icons/PNG/LocationIcon.png";
import { ApiHelperFunction } from '../services/api/apiHelpers';
import ApplicantsIcon from "../Images/headericon/Applicants Icon.png"
import Download from "../../src/Images/headericon/Download.png";
import Refergradient from "../../src/Images/headericon/Refergradient.png"
// import Share from "../..src/Images/Icons/PNG/share-icon-elite-web.svg"
import { RWebShare } from 'react-web-share';
import toast from 'react-hot-toast';
// import ReferModal from '../Component/Modal/ReferModal';
import { useAuthCtx } from '../context/AuthCtx';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import download from "../../src/Images/Icons/PNG/download.png"
const HiringDetailsModal = ({ setRefer, setApplyModal }) => {
  const domainName = window.location.hostname;
  const splitedDName = domainName?.split('.')[0];
  const params = useParams();
  const jobs = useSelector((state) => state?.jobSlice?.jobs);
  const { userData } = useAuthCtx();
  const [whyData, setWhyData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [jobrefferedData, setJobReferData] = useState([]);
  const [jobApplied, setJobApplied] = useState("");
  const [jobDld, setJobDld] = useState([]);
  const navigate = useNavigate();



  const handleapplicant = () => {
    navigate(`/applicantdetails/${params?._id}`)
  }


  //for fetching data of why join us
  const getWhyJoinData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-about-company`,
      method: "GET",
    });
    if (response && response.status) {
      setWhyData(response?.data?.data);
    } else {
      // toast.error(response.error);
    }
  };


  //for fetching Department data
  const getDepartmentData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-department`,
      method: "GET",
    });
    console.log("RESPONSEF", response?.data?.data);
    if (response && response.status) {
      setDepartmentData(response?.data?.data);
    } else {
      // toast.error(response.error);
    }
  };

  const getDepartmentName = (departmentId) => {
    const department = departmentData.find(department => department._id === departmentId);
    return department ? department.departmentName : '';
  };


  //for refer modal click
  const referClick = () => {
    // closemodal();
    setRefer(true);
  }

  //for Applying job
  const handleApplyClick = () => {
    // closemodal();
    setApplyModal(true);
  }


  //for viewing is applied
  const viewIsJobApplied = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/is-applied/${params?._id}`,
      method: "GET",
    });

    if (response && response.status) {
      setJobApplied(response?.data?.isApplied)
    } else {

    }
  };
  // console.log("jobapply", jobApplied);

  const handleJobDownload = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/download-job-pdf/${jobrefferedData?._id}`,
      method: "GET",
    });

    if (response && response.status) {
      // console.log("url",response)
      // setJobDld(response?.data?.url)
      downloadCertificate(response?.data?.url)
    }
  }

  const downloadCertificate = (job) => {
    const link = document.createElement('a');
    link.href = job;
    link.download = 'job.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    getWhyJoinData();
    getDepartmentData();
    handleJobDownload();
  }, [])

  useEffect(() => {
    viewIsJobApplied();
  }, [params?._id])

  useEffect(() => {
    const findData = jobs?.find(ele => ele?._id === params?._id)
    setJobReferData(findData)
  }, [jobs, params])
  // console.log("jobrefferedData", jobrefferedData)

  return (
    // <div className='viedetailsmain' >
    //   <div className='viewdetailscontenthiring'>
    //     <div className='closemarkdst' onClick={() => closemodal()}><i class="fa-solid fa-xmark"></i>
    //     </div>
    <div className='hiringdivbg'>

      <div className='bg-brdrdiv'>
        <div className='bg-grydiv' style={{ borderBottom: "1px solid #000" }}>
          <h2 className='psttxtppdiv' >{jobrefferedData?.jobTitle}</h2>

          {/* <div className='downloiaddibimgonedivmain'>
            <div className='downloiaddibimgone'>
              <img src={download} alt="..." onClick={handleJobDownload} />
            </div>
          </div> */}

          {/*for pdf download */}
          {/* <div className='downloiaddibimgonedivmain'>
            <div className='downloiaddibimgoneifdocmnt'>
              <img src={download} alt="..." />
            </div>
          </div> */}


        </div>
        <div className='dshimgtxt'>
          <div className='imhtnch'>
            <img src={Designation} alt="..." />
          </div>
          {
            jobrefferedData?.departmentName ? (<div className='bg-grydiv'>
              <p className='dstgytxy'>{jobrefferedData?.departmentName}</p>
            </div>) : (<div className='bg-grydiv'>
              <p className='dstgytxy'>{getDepartmentName(jobrefferedData?.Department)}</p>

            </div>)
          }

        </div>
        <div className='dshimgtxt'>
          <div className='imhtnch'>
            <img src={time} alt="..." />
          </div>
          <div className='bg-grydiv'>
            <p className='dstgytxy'>{jobrefferedData?.period}</p>
          </div>
        </div>
        <div className='dshimgtxt'>
          <div className='imhtnch'>
            <img src={Skills} alt="..." />
          </div>
          <div className='bg-grydiv'>
            <p className='dstgytxy'>
              {jobrefferedData?.keySkills?.slice(0, -1).join(", ")
                + (jobrefferedData?.keySkills?.length > 1 ? "," : "")
                + (jobrefferedData?.keySkills?.length > 1 ? " and " : "")
                + jobrefferedData?.keySkills?.slice(-1)}
            </p>
          </div>
        </div>
        <div className='dshimgtxt'>
          <div className='imhtnch'>
            <img src={LocationIcon} alt="..." />
          </div>
          <div className='bg-grydiv'>
            <p className='dstgytxy'>
              {jobrefferedData?.jobLocation?.slice(0, -1).join(", ")
                + (jobrefferedData?.jobLocation?.length > 1 ? "," : "")
                + (jobrefferedData?.jobLocation?.length > 1 ? " and " : "")
                + jobrefferedData?.jobLocation?.slice(-1)}
            </p>
          </div>
        </div>

        <div className='inpuytboxdyflx'>
          <p className='optxtvn'>Open to Referral Hiring</p>
          <input type="checkbox"
            id=""
            name=""
            value=""
            checked={jobrefferedData?.isReffered}
          />
        </div>
      </div>

      {
        whyData?.map((item, ind) => {
          return (
            <>
              <div className='jndivtxtwhy' key={ind} >
                <p className="jntxtdivhrin">Why Join US?</p>
                <div className='bg-grydiv'>
                  <p className='frcttxy'>
                    {item?.whyJoinUs}
                  </p>
                </div>
              </div>
            </>
          )
        })
      }



      <div className='jndivtxtwhy'>
        <p className="jntxtdivhrin">Position Description</p>
        <div className='bg-grydiv'>
          <p className='frcttxy'>{jobrefferedData?.positionDescription}</p>
          {/* <p className='frcttxy'>Fractals Global’s mission is to ‘empower every person and every organization on the
                  planet to achieve more’. At studio+91 at the India Development Center (MSIDC), we work
                  together across a multitude of disciplines: design, research and design engineering, to
                  empower people all over the planet. Our diverse teams are constantly iterating, solving
                  problems and collaborating with product managers, engineers to craft meaningful and
                  relevant product experiences.</p>
                */}
        </div>
      </div>
      <div className='jndivtxtwhy'>
        <p className="jntxtdivhrin">Responsibilities:</p>
        <div className='bg-grydiv'>
          <ul className='txtillsltall'>
            <li className='txtlixtli'>
              {jobrefferedData?.responsibilities}
            </li>
            {/* <li className='txtlixtli'>
                    You will be leading research projects independently, either alone or with a team
                    of researchers, working closely with a team of designers, product managers and
                    engineers in a highly collaborative and agile environment.
                  </li>
                  <li className='txtlixtli'>
                    Projects usually start with a fundamental inquiry, arrived collaboratively with
                    stakeholders across design product & engineering. This also entails identifying &
                    prioritizing research inquiries and defining the research strategy for your product
                    area
                  </li>
                   */}
          </ul>
        </div>
      </div>
      <div className='jndivtxtwhy'>
        <p className="jntxtdivhrin">Qualifications:</p>
        <div className='bg-grydiv'>
          <ul className='txtillsltall'>
            <li className='txtlixtli'>
              {jobrefferedData?.qualification}
            </li>
            {/* <li className='txtlixtli'>
                    {jobrefferedData?.addQualification}
                  </li> */}
            {/* <li className='txtlixtli'>
                    You will be leading research projects independently, either alone or with a team
                    of researchers, working closely with a team of designers, product managers and
                    engineers in a highly collaborative and agile environment.
                  </li>
                  <li className='txtlixtli'>
                    Projects usually start with a fundamental inquiry, arrived collaboratively with
                    stakeholders across design product & engineering. This also entails identifying &
                    prioritizing research inquiries and defining the research strategy for your product
                    area
                  </li>
                 */}
          </ul>
        </div>
      </div>
      <div className='jndivtxtwhy'>
        <p className="jntxtdivhrin">Good To Have</p>
        <div className='bg-grydiv'>
          <ul className='txtillsltall'>
            <li className='txtlixtli'>
              {jobrefferedData?.addQualification}
            </li>
            {/* <li className='txtlixtli'>
                    You will be leading research projects independently, either alone or with a team
                    of researchers, working closely with a team of designers, product managers and
                    engineers in a highly collaborative and agile environment.
                  </li>
                  <li className='txtlixtli'>
                    Projects usually start with a fundamental inquiry, arrived collaboratively with
                    stakeholders across design product & engineering. This also entails identifying &
                    prioritizing research inquiries and defining the research strategy for your product
                    area
                  </li>
                 */}
          </ul>
        </div>
      </div>
      <div className=''>
        <p className='sndstpp'>Sounds you know someone who can fit?</p>
      </div>
      <div className=''>
        {/* <p className='sndstpp'></p> */}
        <div className='refershrflx'>
          <button className='btnmainflxchnageupdate' onClick={referClick}>
            <div className='shrimg'>
              <img src={Refergradient} alt="..." />
            </div>
            <div className='dwnsnnjndpdgghstsxtppp'>
              Refer
            </div>
          </button>


          <RWebShare
            data={{
              text: "Elite Employee Experience Hub - Powered by Fractals Global",
              url: `http://${splitedDName}.fractalselite.com/jobssingledetails/${jobrefferedData?.jobTitle?.replace(/\s+/g, '-')}/${jobrefferedData?._id}`,
              title: "Share job",
            }}
            sites={[
              "linkedin",
              "facebook",
              "twitter",
              "whatsapp",
              "mail",
              "copy",
            ]}
            onClick={(platform) => {
              if (platform === "copy") {
                const textToCopy = `http://${splitedDName}.fractalselite.com/jobssingledetails/${jobrefferedData?.jobTitle?.replace(/\s+/g, '-')}/${jobrefferedData?._id}`;
                navigator.clipboard.writeText(textToCopy).then(() => {
                  toast.success("Link copied to clipboard!");
                });
              } else {

              }
            }}
          >
            <button className='btnmainflxchnageupdate'>
              {/* <div className="share"> */}
              <div className='shrimg'>
                <img src={Share} alt="Share Icon" className="shareIconImg" />
                {/* <span>Share</span> */}

              </div>
              <div className='dwnsnnjndpdgghstsxtppp'>
                Share
              </div>
            </button>
          </RWebShare>

          {
            userData?.userType === "Employee" ?
              (
                <>
                  {
                    jobApplied
                      ?
                      (<div className='btnmainflxchnageupdate'>
                        <div className='dwnsnnjndpdgghstsxtppp'>Job Applied</div>
                      </div>
                      )
                      :
                      (<button className='btnmainflxchnageupdate' onClick={() => handleApplyClick()}>
                        <div className='shrimg'>
                          <img src={Refer} alt="..." />
                        </div>
                        <div className='dwnsnnjndpdgghstsxtppp'>
                          Apply
                        </div>
                      </button>)
                  }
                </>


              )
              : (<></>)
          }

          {
            userData?.userType === "Admin" || userData?.userType === "Owner" ? (
              <button className='btnmainflxchnageupdate' onClick={handleapplicant}>
                <div className='shrimg'>
                  <img src={ApplicantsIcon} alt="..." />
                </div>
                <div className='dwnsnnjndpdgghstsxtppp'>
                  No. Of Applicant
                </div>
              </button>
            ) : (<></>)
          }

          <div className='btnmainflxchnageupdate' onClick={handleJobDownload}>
            <div className='shrimg'>
              <img src={Download} alt="..." />
            </div>
            <p className='dwnsnnjndpdgghstsxtppp'>Download Pdf</p>
          </div>



        </div>
      </div>
    </div>

    //   </div>

    // </div>
  )
}

export default HiringDetailsModal