import React from 'react'
import { useAuthCtx } from '../context/AuthCtx';

const Statuscard = () => {
  const { userData } = useAuthCtx();
  return (
    <div>
      {userData?.userType !== "Owner" &&
        <div className="bg-userdtwht" style={{ backgroundColor: `${userData?.rewardCard?.[0]?.cardColor}` }}>
          <div className="plthclbtx">
            <p className="bghhkpp">{userData?.rewardCard?.[0]?.cardName}</p>
            <p className="clbpp">Club</p>
          </div>
          <div className="" style={{ backgroundColor: "#fff", height: "70%", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px", borderTopRightRadius: "92px" }}>
            <div className="divpaddingghh">
              <div className="prflimgdiv">
                <div className="prflimgbrdr">
                  <div className="prflimgfh">
                    <img src={userData?.image} alt="..." />
                  </div>
                </div>
                <div className="divpatyn">
                  <p className="thounsabpp" style={{ color: `${userData?.rewardCard?.[0]?.cardColor}` }}>{userData?.rewardPoints}</p>
                  <p className="pointshttx">POINTS BALANCE</p>
                </div>
              </div>

              <div className="tstdfghmrl">
                <p className="txtppght">{`${userData?.firstName} ${userData.lastName}`}</p>
                {/* {
                userData?.userType !== "Admin" ? (<p className="dvjknjpp">{userData?.designation}</p>) : (<></>)
              } */}

              </div>
            </div>
          </div>
        </div>
      }

      {/* for company owner */}
      {
        userData?.userType === "Owner" &&
        <div className="bg-userdtwht" style={{ backgroundColor: `${userData?.rewardCard?.[0]?.cardColor}` }}>
          <div className="plthclbtx">
            {/* <p className="bghhkpp">{userData?.rewardCard?.[0]?.cardName}</p> */}
            {/* <p className="clbpp">Club</p> */}
          </div>
          <div className="" style={{ backgroundColor: "#fff", height: "70%", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px", borderTopRightRadius: "92px" }}>
            <div className="divpaddingghh">
              <div className="prflimgdiv">
                <div className="prflimgbrdr">
                  <div className="prflimgfh">
                    <img src={userData?.image} alt="..." />
                  </div>
                </div>
                <div className="divpatyn">
                  {/* <p className="thounsabpp" style={{ color: `${userData?.rewardCard?.[0]?.cardColor}` }}>{userData?.rewardPoints}</p> */}
                  {/* <p className="pointshttx">POINTS BALANCE</p> */}
                </div>
              </div>

              <div className="tstdfghmrl">
                <p className="txtppght">{`${userData?.firstName} ${userData.lastName}`}</p>


              </div>
            </div>
          </div>
        </div>
      }
    </div >
  )
}

export default Statuscard
