import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

const initialState = {
    status: 'idle',
    isLoading: false,
    isSuccess: false,
    isError: false,
    badge: [],
    appliedJob: [],
    following:[],
    follower:[]
}

export const getBadgeData = createAsyncThunk(
    'get_badge_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/get-badge", method: 'GET' })

        if (response?.status) {
            console.log(response?.data, "responsety")
            return response?.data;

        } else {
            // toast.error('Error fetching badge data')
        }
    }
);

export const getAppliedJobData = createAsyncThunk(
    'get_applied_job_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-applied-job", method: 'GET' })

        if (response?.status) {
            console.log(response?.data, "responr")
            return response?.data;

        } else {
            // toast.error('Error fetching badge data')
        }
    }
);


export const getFollowingData = createAsyncThunk(
    'get_following_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/get-following-friend-list", method: 'GET' })

        if (response?.status) {
            // console.log(response?.data, "responsefollowing")
            return response?.data;

        } else {
            // toast.error('Error fetching badge data')
        }
    }
);


export const getFollowerData = createAsyncThunk(
    'get_follower_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/get-follower-friend-list", method: 'GET' })

        if (response?.status) {
            // console.log(response?.data, "responr")
            return response?.data;

        } else {
            // toast.error('Error fetching badge data')
        }
    }
);

export const badgeSlice = createSlice({
    name: "badge_actions",
    initialState,
    reducers: {
        clearBadgeData: (state) => {
            state.status = "idle";
            state.badge = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
        clearAppliedJobData: (state) => {
            state.status = "idle";
            state.appliedJob = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBadgeData.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getBadgeData.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                // console.log('payload', payload);
                state.badge = payload?.data;
            })
            .addCase(getBadgeData.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })


            .addCase(getAppliedJobData.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getAppliedJobData.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                // console.log('payload', payload);
                state.appliedJob = payload?.data;
            })
            .addCase(getAppliedJobData.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })

            .addCase(getFollowingData.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getFollowingData.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                // console.log('payloadfollowing', payload);
                state.following = payload?.data;
            })
            .addCase(getFollowingData.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })


            .addCase(getFollowerData.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getFollowerData.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                // console.log('payload', payload);
                state.follower = payload?.data;
            })
            .addCase(getFollowerData.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })
    }

})
export const { clearBadgeData, clearAppliedJobData } = badgeSlice.actions;
export default badgeSlice.reducer;