import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Worklocationslide = ({ assignedEmp }) => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <>
            <section className='workdetailsdivmainslide'>
                <div className='workdetailsdivmainslidebg'>
                    <div className=''>
                        <p className='wrklcntnrxrtxtppwrlk'>Work Location</p>
                    </div>
                    <div className=''>
                        <Slider {...settings}>
                            {
                                assignedEmp?.map((emp) => (
                                    <div key={emp?._id}>
                                        <h3 className='txtppdatenmkkwrlklcth'>{emp?.userName}</h3>
                                        <table className='tableworklocatiomdedt'>
                                            <tr>
                                                <th className='tableworklocatiomthwrklctn'>
                                                    <p className="elutitxtppsmoplctnwrktxtp">Office(Default)</p>
                                                </th>
                                                <th className='tableworklocatiomthwrklctn'>
                                                    <p className="elutitxtppsmoplctnwrktxtp">Client Site</p>
                                                </th>
                                                <th className='tableworklocatiomthwrklctn'>
                                                    <p className="elutitxtppsmoplctnwrktxtp">Work From Home</p>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className='tableworklocatiomtdwrklctn'>
                                                    <div className='inputattendencedivworkdtls'>
                                                        <p className="timeworktxtpp">{emp?.workFromOffice}</p>
                                                    </div>
                                                </td>
                                                <td className='tableworklocatiomtdwrklctn'>
                                                    <div className='inputattendencedivworkdtls'>
                                                        <p className="timeworktxtpp">{emp?.clientSiteWork}</p>
                                                    </div>
                                                </td>
                                                <td className='tableworklocatiomtdwrklctn'>
                                                    <div className='inputattendencedivworkdtls'>
                                                        <p className="timeworktxtpp">{emp?.workFromHome}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                ))
                            }
                            {/* <div>
                                <h3 className='txtppdatenmkkwrlklcth'>Nilardri Roy</h3>
                                <table className='tableworklocatiomdedt'>
                            <tr>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Office(Default)</p>
                                </th>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Client Site</p>
                                </th>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Work From Home</p>
                                </th>
                            </tr>
                            <tr>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                            </div> */}


                            {/* <div>
                                <h3 className='txtppdatenmkkwrlklcth'>Samil Ismal</h3>
                                <table className='tableworklocatiomdedt'>
                            <tr>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Office(Default)</p>
                                </th>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Client Site</p>
                                </th>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Work From Home</p>
                                </th>
                            </tr>
                            <tr>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                            </div>
                            <div>
                                <h3 className='txtppdatenmkkwrlklcth'>Samil Ismal</h3>
                                <table className='tableworklocatiomdedt'>
                            <tr>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Office(Default)</p>
                                </th>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Client Site</p>
                                </th>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Work From Home</p>
                                </th>
                            </tr>
                            <tr>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                            </div>
                            <div>
                                <h3 className='txtppdatenmkkwrlklcth'>Samil Ismal</h3>
                                <table className='tableworklocatiomdedt'>
                            <tr>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Office(Default)</p>
                                </th>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Client Site</p>
                                </th>
                                <th className='tableworklocatiomthwrklctn'>
                                    <p class="elutitxtppsmoplctnwrktxtp">Work From Home</p>
                                </th>
                            </tr>
                            <tr>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                                <td className='tableworklocatiomtdwrklctn'>
                                    <div className='inputattendencedivworkdtls'>
                                    <p className="timeworktxtpp">5.0</p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                            </div> */}

                        </Slider>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Worklocationslide
