import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import sharethght from "../../Images/sharethght.jpg";
import { BsEmojiSmileFill } from "react-icons/bs";
import EmojiPicker from 'emoji-picker-react';
import post from "../../Images/headericon/post_new.png";
import { useDispatch } from 'react-redux';
import { ApiHelperFunction, fileUpload } from '../../services/api/apiHelpers';
import { getAllPosts, toggleDescription } from '../../redux/slices/postSlice';
import { useAuthCtx } from '../../context/AuthCtx';
import empUserImg from "../../Images/Icons/PNG/Profile (2).png"
import SilverIcon from "../../Images/Icons/PNG/Reward - Silver.png"
import Fancybox from '../FAncyBox';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactPlayer from 'react-player';

const EditRepostModal = ({ closemodal, repostId, repostData, imagesArray, showDescription, description, postId }) => {
    const { userData } = useAuthCtx();
    const [isEmoji, setIsEmoji] = useState(false);
    const [text, setText] = useState(repostData?.text);
    const dispatch = useDispatch();

    console.log("repostData", imagesArray?.postId?.[0])

    //for  repost in home page
    const handlePost = async () => {
        let data = {
            text: text
        }
        let response = await ApiHelperFunction({
            urlPath: `/edit-repost/${repostId}`,
            method: "POST",
            data: data,
        });
        console.log("databn", data);
        // return false;
        if (response?.status === 200) {
            setText("");
            closemodal();
            // console.log("responsenm", response?.data?.msg)
            toast.success(response?.data?.message);
            dispatch(getAllPosts());
        } else {
            toast.error(response?.response?.data?.message);
        }
    }

    useEffect(() => {
        dispatch(getAllPosts())
    }, [])

    return (
        <>
            <section className='repostmodal'>
                <div className='commenteditmodalmainbg'>
                    <div className='coomenteditbgmain'>
                        <div className='commentedittxtdeleteicontop'>
                            <div className=''>
                                <p className='surecommntedit'>Repost Edit</p>
                            </div>
                            <div className='crsicondelte' onClick={() => closemodal()}>
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                        </div>
                        <div className=''>
                            {/* <div className='profile_picimgmain'>
                            <img src={sharethght} alt="..." />
                        </div> */}
                            {/* <div className='wrapboxbg'>
                            <p>Nilardri Roy</p>
                           
                        </div> */}

                        </div>
                        <div className='commentprofiledeletesectionbtm'>
                            <div className='profile_picimgmain'>
                                {/* <img src={sharethght} alt="..." /> */}
                                <img src={userData?.image} alt="..." />
                            </div>
                            <div className="mycomment_push">
                                <form>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id=""
                                            aria-describedby="textHelp"
                                            placeholder="Write a text..."
                                            name='text'
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}

                                        />

                                        <BsEmojiSmileFill className="comment-emoji-icon"
                                            onClick={() => setIsEmoji(prev => !prev)}
                                        // onClick={() => setIsEmoji(true)}
                                        />
                                        {isEmoji &&
                                            // <EmojiPicker onEmojiClick={(emojiObject) => setCommentText(prev => prev + emojiObject.emoji)} />
                                            <EmojiPicker
                                                onEmojiClick={(emojiObject) => setText(prev => prev + emojiObject.emoji)}
                                            />
                                        }
                                        <button
                                            type='button'
                                            className="sendcommentbtn"
                                            onClick={handlePost}
                                        >
                                            <img src={post} alt="..." />
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div id="sara_tylor" >
                            <div className="top_area" style={{ position: "relative" }}>
                                <div className="left">
                                    {
                                        imagesArray?.postIdForHomePost?.length > 0 && (

                                            <div className="profile_img">
                                                {imagesArray?.postIdForHomePost?.[0]?.userData?.[0]?.image ? (
                                                    <img style={{ cursor: "pointer" }}
                                                        src={imagesArray?.postIdForHomePost?.[0]?.userData?.[0]?.image}
                                                        className="img-fluid"
                                                        alt="profile"
                                                    />
                                                ) : (
                                                    <img style={{ cursor: "pointer" }} 
                                                        src={empUserImg}
                                                        className="img-fluid"
                                                        alt="profile"
                                                    />
                                                )}
                                            </div>
                                        )
                                    }

                                    {
                                        imagesArray?.postIdForEvent?.length > 0 && (

                                            <div className="profile_img">
                                                {imagesArray?.postIdForEvent?.[0]?.userData?.[0]?.image ? (
                                                    <img style={{ cursor: "pointer" }} 
                                                        src={imagesArray?.postIdForEvent?.[0]?.userData?.[0]?.image}
                                                        className="img-fluid"
                                                        alt="profile"
                                                    />
                                                ) : (
                                                    <img style={{ cursor: "pointer" }} 
                                                        src={empUserImg}
                                                        className="img-fluid"
                                                        alt="profile"
                                                    />
                                                )}
                                            </div>
                                        )
                                    }

                                    <div
                                        className="details"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div className="name">
                                            {/* <h2 style={{ cursor: "pointer" }} >{imagesArray?.postId?.[0]?.userData?.[0]?.firstName} {imagesArray?.postId?.[0]?.userData?.[0]?.lastName}</h2> */}
                                            {
                                                imagesArray?.postIdForHomePost?.length > 0 && (
                                                    <h2 style={{ cursor: "pointer" }} >{imagesArray?.postIdForHomePost?.[0]?.userData?.[0]?.firstName} {imagesArray?.postIdForHomePost?.[0]?.userData?.[0]?.lastName}</h2>

                                                )
                                            }

                                            {
                                                imagesArray?.postIdForEvent?.length > 0 && (
                                                    <h2 style={{ cursor: "pointer" }} >{imagesArray?.postIdForEvent?.[0]?.userData?.[0]?.firstName} {imagesArray?.postIdForEvent?.[0]?.userData?.[0]?.lastName}</h2>

                                                )
                                            }
                                            <span>
                                                {
                                                    imagesArray?.userType !== "Owner" ? (<>  <img src={SilverIcon} className="img-fluid" alt="icon" />
                                                        {imagesArray?.postId?.[0]?.userData?.[0]?.rewardCard?.[0]?.cardName} Club</>) : (<></>)
                                                }
                                            </span>
                                        </div>
                                        {/* </Link> */}
                                        <div className="text">
                                            {
                                                imagesArray?.postIdForHomePost?.length > 0 && (
                                                    <p>
                                                        <span>{imagesArray?.postIdForHomePost?.[0]?.userData?.[0]?.userType + " "}, </span>
                                                        <span>Posted on {moment(imagesArray?.postIdForHomePost?.[0]?.createdOn)?.format('YYYY - MM - DD')}</span>
                                                    </p>
                                                )
                                            }

                                            {
                                                imagesArray?.postIdForEvent?.length > 0 && (
                                                    <p>
                                                        <span>{imagesArray?.postIdForEvent?.[0]?.userData?.[0]?.userType + " "}, </span>
                                                        <span>Posted on {moment(imagesArray?.postIdForEvent?.[0]?.createdOn)?.format('YYYY - MM - DD')}</span>
                                                    </p>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="">
                                <div className="content"
                                    style={{ cursor: "pointer" }
                                    }
                                >

                                    {imagesArray?.postIdForHomePost?.[0]?.description && (
                                        <>
                                            {showDescription || imagesArray?.postIdForHomePost?.[0]?.description?.length <= 850 ? (
                                                <p>{imagesArray?.postIdForHomePost?.[0]?.description}</p>
                                            ) : (
                                                <>{imagesArray?.postIdForHomePost?.[0]?.description?.slice(0, 850) + '...'}</>
                                            )}
                                            {description?.length > 850 && (
                                                <Link onClick={() => dispatch(toggleDescription(postId))}>
                                                    {showDescription ? '...Show Less' : 'Show More...'}
                                                </Link>
                                            )}
                                        </>
                                    )}


                                    {imagesArray?.postIdForEvent?.[0]?.description && (
                                        <>
                                            {showDescription || imagesArray?.postIdForEvent?.[0]?.description?.length <= 850 ? (
                                                <p>{imagesArray?.postIdForEvent?.[0]?.description}</p>
                                            ) : (
                                                <>{imagesArray?.postIdForEvent?.[0]?.description?.slice(0, 850) + '...'}</>
                                            )}
                                            {description?.length > 850 && (
                                                <Link onClick={() => dispatch(toggleDescription(postId))}>
                                                    {showDescription ? '...Show Less' : 'Show More...'}
                                                </Link>
                                            )}
                                        </>
                                    )}


                                </div>

                                <div className="gallery_area">

                                    <div>
                                        <Fancybox options={{ infinite: false }}>
                                            <p className="all_box">

                                                {

                                                    (imagesArray?.postIdForEvent?.[0]?.docType === "eventData") ?
                                                        (<>
                                                            {imagesArray?.postIdForEvent?.[0]?.image ? (<img src={imagesArray?.postIdForEvent?.[0]?.image}
                                                                style={{
                                                                    width: `${imagesArray?.postIdForEvent?.[0]?.image?.length === 1 ? "100%" : ""
                                                                        }`,
                                                                    height: `${imagesArray?.postIdForEvent?.[0]?.image?.length === 1 ? "auto" : ""
                                                                        }`,
                                                                }} alt="" />) : (<></>)}
                                                        </>) :
                                                        (<></>)
                                                }


                                            </p>
                                        </Fancybox>


                                        <Fancybox options={{ infinite: false }}>
                                            <p className="all_box">

                                                {
                                                    (imagesArray?.postIdForHomePost?.[0]?.docType === "homePost") ?
                                                        (<>
                                                            {imagesArray?.postIdForHomePost?.[0]?.image?.[0] ? (<img src={imagesArray?.postIdForHomePost?.[0]?.image[0]}
                                                                style={{
                                                                    width: `${imagesArray?.postIdForHomePost?.[0]?.image?.length === 1 ? "100%" : ""
                                                                        }`,
                                                                    height: `${imagesArray?.postIdForHomePost?.[0]?.image?.length === 1 ? "auto" : ""
                                                                        }`,
                                                                }} alt="" />) : (<></>)}
                                                            {
                                                                imagesArray?.postIdForHomePost?.[0]?.video?.[0] ? (<div className='trainingdivmainedt' >
                                                                    <ReactPlayer
                                                                        className='reactdivimgmainplrt'
                                                                        controls
                                                                        width='40rem'
                                                                        height='20rem'
                                                                        playing={false}
                                                                        url={imagesArray?.postIdForHomePost?.[0]?.video}
                                                                        // onBuffer={cousrevideoonClick}
                                                                        config={{
                                                                            youtube: {
                                                                                playerVars: { showinfo: 1 }
                                                                            },
                                                                            facebook: {
                                                                                appId: '12345'
                                                                            },
                                                                        }}

                                                                    />
                                                                </div>) : (<></>)
                                                            }
                                                        </>) :
                                                        (<></>)
                                                }


                                            </p>
                                        </Fancybox>
                                    </div>
                                    <div>



                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div >
            </section>
        </>
    )
}

export default EditRepostModal