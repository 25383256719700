import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Eventimg2 from "../../Images/No-Image-Placeholder.png";
import CreateModal from "../../Component/Modal/CreateModal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useAuthCtx } from "../../context/AuthCtx";
// Constants
import { toast } from "react-hot-toast";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import { getEventData } from "../../redux/slices/eventSlice";
import EditEventModal from "../../SeprateModal/EditEventModal";
import Event_View from "../../Images/Icons/PNG/Event_View.png";
import add_icon from "../../Images/Icons/PNG/Add icon.png";
import Edit from "../../Images/headericon/Edit.png";
import Delete from "../../Images/headericon/Delete.png";
import { getSubscriptionEventData } from "../../redux/slices/subscriptionEventSlice";
// import userplusicon from "../../Images/Icons/PNG/userplusicon.png"
import EmployeeModal from "../../SeprateModal/EmployeeModal";
import UserPlus from "../../Images/headericon/UserPlus.png"
import EventsModal from "../../SeprateModal/EventsModal";
const initialValues = {
  eventName: "",
  hostedBy: "",
  eventDate: "",
  name: "",
  trainingDate: "",
  editTrainingName: "",
  editTraininDate: "",
};

export default function EventsDeails() {
  const [modala, setModala] = useState(false);
  const [modalA, setModalA] = useState(false);
  const [modalB, setModalB] = useState(false);
  const [modalId, setModalId] = useState("");
  const [BulletinBoardSingleData, setBulletinBoardSingleData] = useState({});
  const [top, setTop] = useState(null);
  const [left, setLeft] = useState(null);
  const dispatch = useDispatch();
  const billBoardData = useSelector((state) => state?.eventSlice?.event);
  // const { employeeModal, setEmployeeModal } = useAuthCtx();

  const { userData } = useAuthCtx();
  const { modalF, setModalF } = useAuthCtx();
  const [viewDetails, setViewDetails] = useState(false);
  const navigate = useNavigate();

  const createModal = () => {
    // setModalF(true);
    navigate('/createevent')
  };

  //for clicking functionality for edit,delete
  const handleClick = (e, ele, index) => {
    setModalId(ele._id);
    // console.log("EVENT", e, index);
    setBulletinBoardSingleData(ele);
    // console.log("s", e, ele);
    setTop(e.clientY);
    setLeft(e.clientX);
    setModalA(!modalA);
  };

  useEffect(() => {
    dispatch(getEventData());
    dispatch(getSubscriptionEventData());
    // console.log("bill", billBoardData);
  }, []);

  const stopModal = (e) => {
    modalA && setModalA(false);
    // setModalId("");
    e.stopPropagation();
  };

  //for delete events
  const handleDelete = async () => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-event/${modalId}`,
      method: "DELETE",
    });
    if (res?.status) {
      toast.success("Event is deleted successfully");

      dispatch(getEventData());
      setModalA(false);
    } else {
      toast.error(res?.message || "Something went wrong");
      console.log("ERROR CREATING USER3", res);
    }
  };

  //for joinning event
  const handleJoinClick = async (id) => {
    const data = {
      eventId: id,
    };
    const response = await ApiHelperFunction({
      urlPath: `/join-event`,
      method: "POST",
      data,
    });

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      dispatch(getSubscriptionEventData());
    } else {
      toast.error(response?.response?.data?.message);
    }
  }

  //for navigate to event single page
  const handleviewbulletinClick = (item) => {
    const data = {
      item: item
    }
    // navigate("/BulletinView", { state: data })
    navigate(`/EventView/${item?.eventName?.replace(/\s+/g, '-')}/${item?._id}`, { state: data })
  }
  return (
    <section className="bulleDetail eventDetailsSection">
      <div className="container">
        <div className="row">
          <div className="col">
            <div
              className="bulletin_board"
              // style={{ overflow: modalA ? "hidden" : "" }}
              onClick={(e) => stopModal(e)}
              id="eventsBulle"
            >
              <div className="top">
                <div className="head bulleBoardHead">
                  <figure className="bulleBoardFig">
                    <img src={Event_View} alt="..." />
                  </figure>

                  <h4>Events</h4>
                  <div className="" style={{ marginLeft: "15px" }}>
                    {(userData?.userType === "Admin" || userData?.userType === "Manager" || userData?.userType === "Owner") ? (
                      <div className="tyEvMainDiv" onClick={createModal}>
                        {/* <span className="tyEvIcon">
                          <i class="fa-solid fa-plus"></i>
                        </span> */}
                        <figure className="bulleBoardFig">
                          <img src={add_icon} alt="..." />
                        </figure>
                        {/* <p className="tyEvText">Add Event</p> */}
                      </div>
                    ) : (<></>)}
                  </div>
                </div>
                <div>
                  <Link to="/" className="backHomeBtn">
                    <i class="fa-solid fa-arrow-left" style={{ color: "#000000" }}></i>
                    {/* Back */}
                  </Link>
                </div>
              </div>
              <div className="eventbottom">
                {/* {(userData?.userType === "Admin" || userData?.userType === "Manager")? (
                  <div className="tyEvMainDiv" onClick={createModal}>
                    <span className="tyEvIcon">
                      <i class="fa-solid fa-plus"></i>
                    </span>
                    <p className="tyEvText">Add Event</p>
                  </div>
                ):(<></>)} */}
                {billBoardData?.length > 0 ? (
                  billBoardData?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="card_area card_area_event"
                        style={{
                          // marginBottom: "15px",
                          // background: "rgb(242, 233, 220)",
                          // marginTop: "10px",
                          backgroundColor: "#fff",

                        }}
                      >
                        <div className="event_img">
                          {item?.image ? (
                            <img
                              src={item?.image}
                              className="img-fluid"
                              alt="event"
                            />
                          ) : (
                            <img
                              src={Eventimg2}
                              className="img-fluid"
                              alt="event"
                            />
                          )}


                          <div className="">
                            <div className="dotflexthree">
                              {
                                userData?.userType !== "Owner" && (
                                  <div style={{ color: "#fff", cursor: "pointer" }} onClick={() => handleJoinClick(item?._id)}>
                                    <div className="userplusicondivimg">
                                      <img src={UserPlus} alt="..." />
                                    </div>
                                  </div>
                                )
                              }

                              {userData?._id === item?.addedBy || userData?.userType==="Owner" ? (
                                <div className="threedotskll" onClick={(e) => handleClick(e, item, index)}>
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                  {modalId === item?._id && modalA && (
                                    <div className="editdeltedtdivbgwh">
                                      <div className="editimagemain" onClick={() => setModalB(true)}>
                                        {/* <i
                                          style={{
                                            fontSize: "14px",
                                            color: "#000"
                                          }}
                                          class="fas fa-edit"
                                          onClick={() => setModalB(true)}
                                        ></i> */}
                                        <img src={Edit} alt="..." />
                                      </div>
                                      <div className="editimagemain">
                                        {/* <i
                                          style={{
                                            fontSize: "14px",
                                            color: "red",
                                          }}
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                          onClick={handleDelete}
                                        ></i> */}
                                        <img src={Delete} alt="..." onClick={handleDelete} />
                                      </div>

                                    </div>
                                  )}
                                </div>
                              )
                              :
                              <></>
                              }

                              <div className="dotbtnplus" style={{ color: "#fff", cursor: "pointer", fontSize: "20px" }}>
                                {item?.isHighLighted === "yes" ? "+" : ""}
                              </div>

                            </div>
                          </div>
                        </div>


                        {/* <div style={{ cursor: "pointer" }} onClick={() => handleJoinClick(item?._id)}>
                          <i class="fa fa-user-plus" aria-hidden="true"></i>
                        </div> */}
                        <div className="event_details">
                          {/* <div onClick={() => handleJoinClick(item?._id)}>
                            <i class="fa fa-user-plus" aria-hidden="true"></i>
                          </div> */}

                          <div
                            className="d-flex justify-content-between"
                          // style={{ marginBottom: "9px" }}
                          >
                            <div className="head">
                              <h2>{item?.eventName}</h2>
                            </div>

                            <div className="">
                              <button
                                className="viewbtn"
                                style={{ cursor: "pointer" }}
                                key={index}
                                onClick={() =>
                                  // openDetails(item, item._id, index)
                                  handleviewbulletinClick(item)
                                }
                              >
                                View
                              </button>
                            </div>
                            {/* {userData?._id === item?.addedBy && (
                              <div
                                className="dot_btn"
                                onClick={(e) => handleClick(e, item, index)}
                                style={{
                                  position: "absolute",
                                  zIndex: "2",
                                  right: "-4px",
                                  top: "9px",
                                }}
                              >
                                <a to="/" className="btn">
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                </a>
                                {modalId === item?._id && modalA && (
                                  <div
                                    style={{
                                      position: "relative",
                                      // overflow: "hidden",
                                      backgroundColor: "#fff",
                                      padding: "3px",
                                      border: "none",
                                      // top: "123px",
                                      padding: "3px 8px",
                                      marginLeft: "-27px",
                                      borderRadius: "4px",
                                      cursor: "pointer",
                                      boxShadow: "0 0 0.1rem 0",
                                    }}
                                  >
                                    <i
                                      style={{
                                        marginRight: "7px",
                                        fontSize: "14px",
                                      }}
                                      class="fas fa-edit"
                                      onClick={() => setModalB(true)}
                                    ></i>
                                    <i
                                      style={{
                                        margin: "3px",
                                        fontSize: "14px",
                                        color: "red",
                                      }}
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                      onClick={handleDelete}
                                    ></i>
                                  </div>
                                )}
                              </div>
                            )} */}
                            {/* {item?.isHighLighted === "yes" ? "+" : ""} */}
                          </div>
                          {/* <div className="hosted">
                            <p>Hosted by {item.hostedBy}</p>
                          </div> */}

                          <div className="d-flex justify-content-between">
                            <div className="date">
                              <p className="dttxtppdatepp">
                                Date:{" "}
                                {moment(item.eventDate).format("YYYY-MM-DD")}
                              </p>
                            </div>
                            {/* <div className="time">
                      <p>Time: 5:30 PM </p>
                    </div> */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No data to show!</p>
                )}
              </div>
            </div>
            {/* 
            {modalB && (
              <CreateModal
                closemodal={setModalB}
                activity={"editEvent"}
                BulletinBoardSingleData={BulletinBoardSingleData}
                id={modalId}
                initialValues={BulletinBoardSingleData}
              />
            )} */}

            {modalB && (
              <EditEventModal
                closemodal={setModalB}
                activity={"editEvent"}
                BulletinBoardSingleData={BulletinBoardSingleData}
                id={modalId}
                initialValues={BulletinBoardSingleData}
              />
            )}

            {/* {modala && (
              <CreateModal
                closemodal={setModala}
                activity={"events"}
                initialValues={initialValues}
              />
              
            )} */}
            {modalF && (
              <EventsModal
                closemodal={setModalF}
                activity={"events"}
                initialValues={initialValues}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
