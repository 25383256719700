import React, { useEffect, useState } from 'react'
// import bokk_img from "../../Images/bokk_img.png";
import { useNavigate, useParams } from 'react-router-dom';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { useAuthCtx } from '../../context/AuthCtx';


const Orgmanagerchart = () => {
    const {userData}=useAuthCtx();
    const params = useParams();
    const navigate=useNavigate();
    const [managerData, setManagerData] = useState([]);

    //for view Single Manager tree
    const viewSingleManager = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/get-manageer-company-tree/${params?.hid}/${params?.id}`,
            method: "GET",
        });
        if (response.status) {
            setManagerData(response?.data?.data?.[0])
        }
    }
    useEffect(() => {
        viewSingleManager();
    }, [])

    const AnotherProfile = (id) => {
        if (id === userData?._id) {
            navigate(`/profile`);
        }
        else {
            navigate(`/Profile_rating/${id}`);
        }
    }

    return (
        <>
            <div className="tree">
                <div className='treecontainer'>
                    <ul>
                        <li>
                            <div className=''>
                                <div className='ordgfdtlsdivmain' onClick={()=>AnotherProfile(managerData?._id)}>
                                    <div className='pimfprlimg'>
                                        <img src={managerData?.image} alt="..." />
                                    </div>
                                    <div className=''>
                                        <p className='ordgdtklstxtp'>{managerData?.firstName+ " " + managerData?.lastName}</p>
                                        <p className='ordgdtklstxtphhdr'>Admin</p>

                                    </div>
                                </div>
                            </div>
                            <ul className="inn_linemaindiv">

                                {/* <li>
                                    <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                        <div className='ordgfdtlsdivmainflx'>
                                            <div className='pimfprlimg'>
                                                <img src={bokk_img} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                <p className='ordgdtklstxtphhdr'>Hr</p>

                                            </div>
                                        </div>
                                        <div className=''>
                                            <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                        </div>
                                    </div>
                                </li> */}


                                <li>
                                    <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                        <div className='ordgfdtlsdivmainflx' onClick={()=>AnotherProfile(managerData?.HRs?.[0]?._id)}>
                                            <div className='pimfprlimg'>
                                                <img src={managerData?.HRs?.[0]?.image} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='ordgdtklstxtp'>{managerData?.HRs?.[0]?.firstName+ " " + managerData?.HRs?.[0]?.lastName}</p>
                                                <p className='ordgdtklstxtphhdr'>Hr</p>

                                            </div>
                                        </div>
                                        {/* <div className=''>
                                            <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                        </div> */}
                                    </div>
                                    <ul className='inn_linemaindiv'>
                                        <li>
                                        <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                            <div className='ordgfdtlsdivmainflx' onClick={()=>AnotherProfile(managerData?.HRs?.[0]?.Managers?.[0]?._id)}>
                                                <div className='pimfprlimg'>
                                                    <img src={managerData?.HRs?.[0]?.Managers?.[0]?.image} alt="..." />
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtp'>{managerData?.HRs?.[0]?.Managers?.[0]?.firstName+ " " + managerData?.HRs?.[0]?.Managers?.[0]?.lastName}</p>
                                                    <p className='ordgdtklstxtphhdr'>Manager</p>

                                                </div>
                                            </div>
                                            {/* <div className=''>
                                                <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                            </div> */}
                                        </div>
                                        </li>
                                    </ul>

                                    <ul className='orgmanagerdivdifff'>
                                        {
                                            managerData?.HRs?.[0]?.Managers?.[0]?.Employees?.map((emp,i)=>{
                                                return(
                                                    <li className='orgmanagerdivdiffflistli' key={i}>
                                                    <div className='ordgfdtlsdivmaindiffmanng' onClick={()=>AnotherProfile(emp?._id)}>
                                                        <div className='pimfprlimg'>
                                                            <img src={emp?.image} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>{emp?.firstName+ " " + emp?.lastName}</p>
        
                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                )
                                            })
                                        }
                                       
                                        {/* <li className='orgmanagerdivdiffflistli'>
                                            <div className='ordgfdtlsdivmaindiffmanng'>
                                                <div className='pimfprlimg'>
                                                    <img src={bokk_img} alt="..." />
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='orgmanagerdivdiffflistli'>
                                            <div className='ordgfdtlsdivmaindiffmanng'>
                                                <div className='pimfprlimg'>
                                                    <img src={bokk_img} alt="..." />
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='orgmanagerdivdiffflistli'>
                                            <div className='ordgfdtlsdivmaindiffmanng'>
                                                <div className='pimfprlimg'>
                                                    <img src={bokk_img} alt="..." />
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                </div>
                                            </div>
                                        </li> */}
                                    </ul>

                                    </li>

                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

        </>
    )
}

export default Orgmanagerchart
