import React, { useState, useEffect } from "react";
import "./Modal2.css";
import { useAuthCtx } from "../../context/AuthCtx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const MyMentorModal = ({ closemodal, activity, initialValues }) => {
  const { setLoading } = useAuthCtx();
  // const [imageURL, setImageURL] = useState("");
  // const dispatch = useDispatch();
  // const [localerror, setlocalerror] = useState("");
  // const [image, setImage] = useState("");
  // const [uploading, setUploading] = useState(false);
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
  // const [empData, setempData] = useState([]);
  const [mentor, setMentor] = useState([]);
  // const [departmentData, setDepartmentData] = useState([]);
  const [DepartmentName, setDepartmentName] = useState("");
  const [getManagerList, setGetManagerList] = useState([]);
  const [options, setOptions] = useState([]);
  const [DepartmentDropdown, setDepartmentDropdown] = useState([]);

  // console.log(mentor, "mentor");


  // fetch mentor
  const fetchMentor = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/view-mentor",
      method: "GET",
    });
    if (response && response.status) {
      setMentor(response?.data?.data?.reverse());
    } else {
      // toast.error(response?.data?.message);
    }
  };

  //pick department name
  const handleSelectDepartName = selectedOption => {
    // Update the selected IDs array
    setDepartmentName(selectedOption ? selectedOption.value : "");
  }


  // Manager data view department wise
  const handleSelectChange = selectedOption => {
    // Update the selected IDs array
    setGetManagerList(selectedOption ? selectedOption.map(option => option.value) : []);
  }


  //for fetching Department data
  // const getDepartmentData = async () => {
  //   const response = await ApiHelperFunction({
  //     urlPath: `/view-department`,
  //     method: "GET",
  //   });
  //   // console.log("RESPONSEF", response?.data?.data);
  //   if (response && response.status) {
  //     setDepartmentData(response?.data?.data);
  //   } else {
  //     // toast.error(response.error);
  //   }
  // };

  // get Designation/Department Data
  const getDepartmentData = async () => {
    // setIsLoading(true);
    const res = await ApiHelperFunction({
      urlPath: "/view-department",
      method: "GET"
    });
    console.log("resGetCat", res)
    let apiData = []
    if (res && res?.status) {
      // setIsLoading(false);
      apiData = res?.data?.data?.map((item) => ({
        label: item?.departmentName,
        value: item?._id,

      }));
    } else {
      // setIsLoading(false);
    }
    setDepartmentDropdown(apiData);
  }



  // const getNameFromUserId = (selectedUserId) => {
  //   const selectedEmployee = empData.find(
  //     (employee) => employee._id === selectedUserId
  //   );
  //   return selectedEmployee ? selectedEmployee.userName : "";
  // };

  const submitHandler = async (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    setShouldValidateOnBlur(true);
    // console.log("handleSubmit", values);

    let data = {};
    setLoading(true);
    // if (values.userID == "") {
    //   return toast.error("All fields required");
    // }
    // if (values.description == "") {
    //   return toast.error("All fields required");
    // }

    data = {
      // name: getNameFromUserId(values?.userId),
      userID: getManagerList,
      description: DepartmentName,
    };
    // console.log("click_training123", data);
    const res = await ApiHelperFunction({
      urlPath: "/add-mentor",
      method: "POST",
      data,
    });
    if (res?.status) {
      toast.success("Mentor is added successfully");
      resetForm();
      fetchMentor();
      closemodal();
    } else {
      toast.error(res?.response?.data?.message || "Something went wrong");
      console.log("ERROR CREATING USER3", res);
    }
    setLoading(false);
  };

  const schemaCheck = Yup.object(
    activity === "billBoard" ||
      activity === "events" ||
      activity === "kababMenu" ||
      activity === "editEvent"
      ? {
        eventName: Yup.string().required("Event Name is required"),
        hostedBy: Yup.string().required("Host name is required"),
        eventDate: Yup.string().required("Event date is required"),
        eventstarttime: Yup.string().required("start Time is required"),
        eventendtime: Yup.string().required(" End Time is required"),
        notes: Yup.string().required(" Notes is required"),
        addinvites: Yup.string().required(" Addinvites is required"),
        lattitude: Yup.string().required(" lattitude is required"),

        longitude: Yup.string().required(" longitude is required"),
      }
      : activity === "training" || activity === "editTraining"
        ? {
          name: Yup.string().required("Event Name is required"),
          trainingDate: Yup.string().required("Training date is required"),
          link: Yup.string().required("Training link is required"),
        }
        : activity === "halloffame" || activity === "edithalloffame"
          ? {
            // name: Yup.string().required("name is required"),
            reason: Yup.string().required("Reason is required"),
          }
          : activity === "mymentor"
            ? {
              // name: Yup.string().required("name is required"),
              description: Yup.string().required("Description is required"),
            }
            : activity === "jobReffered"
              ? {
                name: Yup.string().required("Job Name is required"),
                location: Yup.string().required("Job location is required"),
                salary: Yup.string().required("Salary range is required"),
                description: Yup.string().required("Description range is required"),
                opening: Yup.string().required("Opening is required"),
              }
              : ""
  );



  //Fetch Manager Data
  const getManagerData = async () => {
    const data = {
      department: DepartmentName
    }
    // setIsLoading(true);
    const res = await ApiHelperFunction({
      urlPath: "/view-manager-department-wise",
      method: "POST",
      data
    });
    console.log("resGetCatman", res)
    let apiData = []
    if (res && res?.status) {
      // setIsLoading(false);
      apiData = res?.data?.data?.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item._id,

      }));
    } else {
      // setIsLoading(false);
    }
    setOptions(apiData);
  }

  const { handleSubmit, resetForm } =
    useFormik({
      initialValues,
      validationSchema: schemaCheck,
      validateOnChange: shouldValidateOnChange,
      validateOnBlur: shouldValidateOnBlur,
      onSubmit: (val) => {
        console.log("val", val);
      },
    });


  useEffect(() => {
    fetchMentor();
    getDepartmentData();
  }, []);


  useEffect(() => {
    getManagerData()
  }, [DepartmentName]);

  return (
    <section className="createmodalnewdiv">
      <div className="createmodalnewdivcontent">
        {/* <p className="newBulleBrodHead">Mentor</p>
        <button className="bulleBrodCloseBtn" onClick={() => closemodal()}>
          <i class="fa-solid fa-xmark"></i>
        </button> */}
        <div className="bulletintextclose">
          <div className="bulletinimageflxdivmodal">
            <div className="bulleBoardFig">
              {/* <img src={Bulletin_Board} alt="..." /> */}
            </div>
            <span className="newBulleCreHead">Mentor</span>
          </div>

          <div onClick={() => closemodal()}>
            <i
              class="fa-solid fa-xmark"
              style={{
                color: "red",
                fontSize: "22px",
                cursor: "pointer",
              }}
            ></i>
          </div>
        </div>
        <form
          onSubmit={(e) => {
            setShouldValidateOnBlur(true);
            setShouldValidateOnChange(true);
            handleSubmit(e);
          }}
        >

          {/* <div className="" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <p className="empListSubHead">Department Name</p>
            <select
              class=""
              aria-label="Select department name"
              name="description"
              value={values?.description}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option value={""} >
                Select department
              </option>
              {departmentData?.map((item, i) => (
                <option key={i} value={item?._id}>
                  {item?.departmentName}
                </option>
              ))}
            </select>
          </div>

          <div className="" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <p className="empListSubHead">Mentor to be added</p>
            <select
              class=""
              aria-label="Select Employee name"
              name="userID"
              value={values.userID}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option value={""} disabled>
                Select Mentor
              </option>
              {empData?.map((item, i) => (
                <option key={i} value={item?._id}>
                  {item?.firstName} {item?.lastName}
                </option>
              ))}
            </select>
          </div> */}



          <div className="homePgModInnerInpDivsmain">
            <p>Select Department Name</p>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}

              options={DepartmentDropdown}
              onChange={handleSelectDepartName}
              value={DepartmentDropdown?.find(option => option.value === DepartmentName)}
            />
          </div>


          <div className="homePgModInnerInpDivsmain">
            <p>Mentor to be added</p>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={options}
              onChange={handleSelectChange}
              value={options?.filter(option => getManagerList.includes(option.value))}
            />
          </div>




          {/* <div className="mb-3">
            <p className="empListSubHead">Department</p>
            <input
              id="description"
              className="empListTextArea"
              placeholder="Department name"
              value={values.description}
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div> */}


          <div className="homePgCreModSubmitDiv">
            <button
              className="homePgCreModSubmitBtn"
              type="button"
              onClick={submitHandler}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default MyMentorModal;
