import React, { useEffect } from 'react'
import Articleicon from "../../Images/Icons/PNG/Article icon.png"
import { useDispatch, useSelector } from 'react-redux'
import { getDocumentsRepo } from '../../redux/slices/jobRecruitmentSlice'
// import download from "../../Images/Icons/PNG/download.png";
import { useNavigate } from 'react-router-dom';

const Recentdoc = () => {
    const documents = useSelector((state) => state?.jobRecruitmentSlice?.documents);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getDocumentsRepo());
    }, [])

    const handlearticlefullClick = (item) => {
        // const data = {
        //     item: item
        // }
        // console.log("uiopgh",item)
        // setSingleData(item)
        navigate(`/fullarticleview`, { state: item })
    }

    const recentDoc = documents?.filter(item => new Date(item?.createdOn) <= new Date());
    // console.log("recentDoc", recentDoc)
    return (
        <>
            <div className='recentdocdiv'>
                <div className='top'>
                    <div className="recentdoccdiv">
                        <div className='articleiconimgdiv'>
                            <img src={Articleicon} alt="..." />
                        </div>
                        <div className=''>
                            <p className='dpocsttxtppp'>Your Recent Docs</p>
                        </div>
                    </div>
                </div>
                <div className='buttom'>
                    {
                        recentDoc?.slice(0, 4)?.map((item, i) => {
                            return (<>
                                <div className='docstxtpflxdaa' key={i} style={{cursor:"pointer"}} onClick={() => handlearticlefullClick(item)}>
                                    <div className='articleiconimgdiv'>
                                        {
                                            item?.docImage === "docImage" || item?.docImage === "" ? (<img src={Articleicon} alt="..." />) : (<img src={item?.docImage} alt="..." />)
                                        }

                                    </div>
                                    <div className=''>
                                        <p className='dcmnttxtpp'>{item?.title}
                                        </p>
                                    </div>

                                    {/* <div className=''>
                                        <div className='downladimgmainpl'>
                                            {
                                                item?.docUrl !== "" ? (<div className='dateshowlinkdiv'><img src={download} alt="..." /></div>) : (<div><img src={download} alt="..." /></div>)
                                            }

                                            <div className='downloaddivmaindiv'>

                                                {
                                                    item?.docUrl !== "" && (
                                                        <>
                                                            <div className='downloaddivmaindivsize' >

                                                                <a href={item?.docUrl?.startsWith("http") ? item?.docUrl : `http://${item?.docUrl}`} target="_blank" rel="noopener noreferrer">
                                                                    {item?.docUrl?.slice(0, 20)}
                                                                </a>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </>)
                        })
                    }

                    {/* <div className='docstxtpflxdaa'>
                        <div className='articleiconimgdiv'>
                            <img src={Articleicon} alt="..." />
                        </div>
                        <div className=''>
                            <p className='dcmnttxtpp'>Lorem ipsum dolor sans
                            </p>
                        </div>
                    </div>
                    <div className='docstxtpflxdaa'>
                        <div className='articleiconimgdiv'>
                            <img src={Articleicon} alt="..." />
                        </div>
                        <div className=''>
                            <p className='dcmnttxtpp'>Lorem ipsum dolor sans
                            </p>
                        </div>
                    </div>
                    <div className='docstxtpflxdaa'>
                        <div className='articleiconimgdiv'>
                            <img src={Articleicon} alt="..." />
                        </div>
                        <div className=''>
                            <p className='dcmnttxtpp'>Lorem ipsum dolor sans
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Recentdoc
