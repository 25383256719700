import React, { useEffect, useMemo, useState } from 'react'
import BulletinBoard from '../../Component/BulletinBoard'
import HallofFame from '../../Component/HallofFame'
import Employees from '../../Component/Employees'
import EventCard from '../../Component/EventCard'
import JobsReferred from '../../Component/JobsReferred'
import { TabPanel, Tabs } from 'react-tabs'
import TrainingCard from '../../Component/TrainingCard'
import Initiative from '../../Component/Initiative'
import HubAffinityGrp from '../Hub/HubAffinityGrp'
import { ApiHelperFunction, fileUpload } from '../../services/api/apiHelpers';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Fancybox from "../../Component/FAncyBox";
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAuthCtx } from '../../context/AuthCtx'
import moment from 'moment'
import { getAllPosts } from '../../redux/slices/postSlice'
import toast from 'react-hot-toast'
import { RWebShare } from 'react-web-share'
// import msgChat from "../../Images/Icons/PNG/Comment.png"
// import table_icon from "../../Images/Icons/PNG/share-icon-elite-web.svg"
import ProfileImg1 from "../../Images/No-Image-Placeholder.png";
import { BsEmojiSmileFill } from 'react-icons/bs'
import EmojiPicker from 'emoji-picker-react'
import Delete from "../../Images/headericon/Delete.png";
import share from "../../Images/headericon/share.png"
import ReactPlayer from 'react-player';
import post from "../../Images/headericon/post_new.png"
import Attach from "../../Images/headericon/Attach.png";
import Comment from "../../Images/headericon/Comment.png";
import Like from "../../Images/headericon/Like.png";
import ReactionLike from "../../Images/headericon/ReactionLike.png";
import ReactionCelebrate from "../../Images/headericon/ReactionCelebrate.png";
import ReactionLove from "../../Images/headericon/ReactionLove.png";
import ReactionSupport from "../../Images/headericon/ReactionSupport.png";
import ReationCurious from "../../Images/headericon/ReationCurious.png";
import ReationInsightful from "../../Images/headericon/ReationInsightful.png";
import Statuscard from '../../Component/Statuscard'
import Edit from "../../Images/headericon/Edit.png";
import reporticon from "../../Images/headericon/reporticon.png"
import Commenteditmodal from '../../Component/Modal/Commenteditmodal'
import Replyeditmodal from '../../Component/Modal/Replyeditmodal'
import repost from "../../Images/Repost Icon.png";
import RepostModal from '../../Component/Modal/RepostModal'
import empUserImg from "../../Images/Icons/PNG/Profile (2).png"
// import { BASE_URL } from '../../constants/config'

import ThankYoucard from "../../Images/ThankYoucard.png";
import HappyBirthdayCard from "../../Images/HappyBirthdayCard.png";
import Onboardingwelcomecardclean from "../../Images/Onboardingwelcomecardclean.png";
import HappyAnniversaryCardclean from "../../Images/HappyAnniversaryCardclean.png";
import companyelite from "../../Images/companyelite.png"


const SingleImage = () => {
    const domainName = window.location.hostname;
    const splitedDName = domainName?.split('.')[0];
    const { userData } = useAuthCtx();
    const [postData, setPostData] = useState([]);
    const [emojimodal, setEmojimodal] = useState(false);
    const params = useParams();
    const location = useLocation();
    const SingleimageId = location?.state?.Singleid;
    // const queryParams = new URLSearchParams(location.search);
    // const SingleimageId = queryParams.get("Singleid");
    // const imagesArray = JSON.parse(queryParams.get('imagesArray'));
    // const [selectedReaction, setSelectedReaction] = useState(null);
    // const reactions = ["alike", "thumbsup", "care", "haha", "surprised", "sadface", "angry"];
    // const [emojiHovered, setEmojiHovered] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [initiativeData, setInitiativeData] = useState({});
    // const [viewDetails, setViewDetails] = useState(false);
    // const [approvedAdvocacyData, setApprovedAdvocacyData] = useState([]);
    // const [advoLoading, setAdvoLoading] = useState(false);

    // const [comment, setComment] = useState("");
    // const [likeLoading, setLikeLoading] = useState(false);
    const [isEmoji, setIsEmoji] = useState(false);
    const [image, setImage] = useState("")
    const [uploading, setUploading] = useState(false);
    const [imageURL, setImageURL] = useState("");
    const [commentView, setCommentView] = useState(true);
    const [commentText, setCommentText] = useState("");
    const [commentData, setCommentData] = useState([]);
    const [indexShow, setIndex] = useState("");
    const [indexShowReply, setIndexReply] = useState("");
    const [replyText, setReplyText] = useState("");
    const [replyToggle, setReplyToggle] = useState(Array(commentData?.length).fill(false));

    const [replyLoading, setReplyLoading] = useState(false);
    const [isReplyEmoji, setIsReplyEmoji] = useState(false);
    const [imageReplyURL, setImageReplyURL] = useState("");

    // const [modalA, setModalA] = useState(false);
    // const [showModal, setShowModal] = useState(false);
    const [showComment, setShowComment] = useState(true);
    // const [commentIndex, setCommetIndex] = useState(3);
    const [singleView, setSingleView] = useState({});

    const [editdeletereport, setEditdeletereport] = useState(false);
    const [editnewthreemodal, setEditnewthreemodal] = useState(false);
    const [editComment, setEditComment] = useState({});
    const [editdeletereportReply, setEditdeletereportReply] = useState(false);
    const [editnewthreemodalReply, setEditnewthreemodalReply] = useState(false);
    const [editCommentReply, setEditCommentReply] = useState({});
    const [repostModal, setRepostModal] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [showReaction, setShowReaction] = useState("")


    //for view Single Page
    const viewSinglepost = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-single-post/${params?.doc}/${params?.postId}`,
            method: "GET",
        });
        if (response.status === 200) {
            setSingleView(response?.data?.data);
            // console.log("data get successfully");
        } else {
            // toast.error(response.message);
        }

    }

    useEffect(() => {
        viewSinglepost();
    }, [params?.id])


    //for view Single Advocacy share
    const viewShareAdvocacy = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/update-advocacy-sharing-count/${params?.id}`,
            method: "PUT",
        });
        if (response.status) {
            console.log("respovbsdiop", response)
        }
    }

    const handleRepostModal = () => {
        setRepostModal(!repostModal);
    }
    // console.log("singleViewqw", singleView)

    // const showComment = queryParams.get('showComment');s

    // const imagesArrayParam = queryParams.get('imagesArray');

    // Use try...catch to handle potential JSON parsing errors
    // let imagesArray;
    // try {
    //     imagesArray = JSON.parse(imagesArrayParam);
    // } catch (error) {
    //     console.error('Error parsing imagesArray JSON:', error);
    //     // Handle the error or provide a default value for imagesArray
    //     imagesArray = [];
    // }


    // console.log("uuuu", location?.state?.reactionIconMemo)

    //get all comments
    const getComment = async () => {
        let data = {
            postID: params?.postId,
        };

        let urlPath = "/view-comment";
        let res = await ApiHelperFunction({ urlPath, method: "POST", data });
        if (res?.status) {
            setCommentData(res?.data?.data);
        }
    };

    //for adding Comment
    const handleComment = async (e) => {
        e.preventDefault();
        if (commentText === "" && imageURL === "") {
            return;
        }
        let urlPath = "/add-comment";
        let data = {
            userId: userData?._id,
            Userimage: userData?.image,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            postID: params?.postId,
            comment: commentText,
            image: imageURL
        };

        if (commentText || imageURL) {
            let res = await ApiHelperFunction({ urlPath, method: "POST", data });
            if (res?.status) {
                setCommentData(prevCommentData => [data, ...prevCommentData]);
                setCommentText("");
                setImageURL("");
            } else {
                toast.error(res?.data?.message || "Error Fetching User Details");
            }
        } else {
            toast.error("No Comment is Added");
        }
    };

    //for delete comment
    const handleDeleteComment = async (id) => {
        const updatedCommentData = commentData?.filter(comment => comment?._id !== id);
        setCommentData(updatedCommentData);
        const res = await ApiHelperFunction({
            urlPath: `/delete-comment/${id}`,
            method: "DELETE",
        });
        if (res?.status) {
            const newTotalComment = updatedCommentData?.length;
            setSingleView(prevSingleView => ({
                ...prevSingleView,
                [0]: {
                    ...prevSingleView[0],
                    totalComment: newTotalComment
                }
            }));
            toast.success("Comment deleted successfully");

        } else {

        }
    };

    //image
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await fileUpload("/image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploading(false);
    };

    //reply-image
    const handleReplyImageChange = async (e) => {
        let image = e.target.files[0];
        setReplyLoading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await fileUpload("/image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageReplyURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setReplyLoading(false);
    };

    //for reply toggle
    const handleReplyToggle = (index) => {
        const newToggles = [...replyToggle];
        newToggles[index] = !newToggles?.[index];
        setReplyToggle(newToggles);
    };

    //for Reply of comment
    const handleReplyClick = async (id) => {
        if (replyText === "" && imageReplyURL === "") {
            return;
        }
        let urlPath = "/add-reply";
        let data = {
            commentID: id,
            reply: replyText,
            image: imageReplyURL
        };

        // console.log("imffggg", imageReplyURL);
        // return false;
        if (replyText || imageReplyURL) {
            let res = await ApiHelperFunction({ urlPath, method: "POST", data });
            if (res?.status) {
                toast.success("Reply is added successfully");
                getComment();
                dispatch(getAllPosts());
                setReplyText("");
                setReplyToggle(false);
                setImageReplyURL("");
                setIsReplyEmoji(false);
            } else {
                toast.error(res?.data?.message || "Error Fetching User Details");
            }
        } else {
            toast.error("No Reply is Added");
        }
    };

    // const handleEmojiMouseEnter = () => {
    //     setEmojiHovered(true);
    // };

    // const handleEmojiMouseLeave = () => {
    //     setEmojiHovered(false);
    // };



    useEffect(() => {
        const closeEmojiModal = (event) => {
            if (!event.target.closest('.emojimodalbg') && !event.target.closest('.reaction')) {
                setEmojimodal(false);
            }
        };

        document.addEventListener('click', closeEmojiModal);

        return () => {
            document.removeEventListener('click', closeEmojiModal);
        };
    }, []);

    const postDataReducer = useSelector((state) => state.postGetSlice);

    //for gold card
    // const getGoldCard = async () => {
    //     setLoading(true);

    //     const response = await ApiHelperFunction({
    //         urlPath: "/get-profile",
    //         method: "GET",
    //     });


    //     if (response.status === 200) {
    //         setGoldCard(response?.data?.data);
    //         // console.log("data get successfully");
    //     } else {
    //         // toast.error(response.message);
    //     }
    //     setLoading(false);
    // };

    //for affinity group posts
    const getAffinityPosts = async () => {
        const data = {
            affinityGroupId: params?.id,
            postType: "private",
        };
        // setLoading(true);
        const response = await ApiHelperFunction({
            urlPath: "/view-all-post",
            method: "POST",
            data,
        });
        if (response.status === 200) {
            // toast.success(response?.data?.data?.message);
            // console.log(response?.data, "response?.data");
            // setPostData(response?.data?.data);

            let filterData = postDataReducer?.posts.filter((item) => {
                // console.log("ddd", item._id, SingleimageId)
                return item._id === SingleimageId
            });
            // console.log("SingleimageIdeff",SingleimageId,filterData)
            setPostData(filterData)
        } else {
            // toast.error(response?.message);
        }
        // setLoading(false);
    };


    // const handleReactionSelect = async (index) => {
    //     const reactionType = reactions[index];
    //     setSelectedReaction(index);

    //     const response = await ApiHelperFunction({
    //         urlPath: "/add-update-reaction",
    //         method: "POST",
    //         data: {
    //             postID: SingleimageId,
    //             reactionData: reactionType,
    //         },
    //     });

    //     if (response.status === 200) {
    //         // Handle success
    //         console.log("Reaction added/updated successfully!");
    //     } else {
    //         // Handle error
    //         console.error("Failed to add/update reaction");
    //     }
    // };


    //add update reaction
    const handleReactionSelect = async (postId, reactionType) => {
        setShowReaction(reactionType)
        setEmojimodal(false)
        setIsHovered(true);
        const response = await ApiHelperFunction({
            urlPath: "/add-reaction",
            method: "POST",
            data: {
                postID: postId,
                reactionData: reactionType,
            },
        });

        if (response.status === 200) {
            viewSinglepost();
        }
        setTimeout(() => {
            setIsHovered(false);
        }, 100);
    };


    //Like-dislike functionality
    const handleLikeDislike = async () => {
        const currentReaction = singleView?.[0]?.reactionData?.find(item => item?.userId === userData?._id)?.reactionData;
        const remove = !!currentReaction;

        setShowReaction(remove ? "" : "Like");

        setIsHovered(true);
        let data = {}
        data = {
            postID: singleView?.[0]?._id,
            reactionData: showReaction,
            remove: remove
        }

        const response = await ApiHelperFunction({
            urlPath: "/add-reaction",
            method: "POST",
            data

        });

        if (response.status === 200) {
            viewSinglepost();
        }

        setTimeout(() => {
            setIsHovered(false);
        }, 100);
    };

    // const handleReactionSelect = async (postId, reactionType) => {
    //     // setSelectedReaction(reactionType);
    //     setIsHovered(true);
    //     const response = await ApiHelperFunction({
    //         urlPath: "/add-reaction",
    //         method: "POST",
    //         data: {
    //             postID: postId,
    //             reactionData: reactionType,
    //         },
    //     });

    //     if (response.status === 200) {
    //         viewSinglepost();
    //         // console.log("Reaction added/updated successfully!");
    //     } else {
    //         // console.error("Failed to add/update reaction");
    //     }
    //     setTimeout(() => {
    //         // setLikeLoading(false);
    //         setIsHovered(false);
    //     }, 100);
    // };


    //for view approved advocacy 
    // const viewApprovedAdvocacy = async () => {
    //     setAdvoLoading(true);
    //     let res = await ApiHelperFunction({
    //         urlPath: "/view-approved-advocacy",
    //         method: "GET",
    //     });

    //     if (res && res?.status) {
    //         setApprovedAdvocacyData(res?.data?.data);
    //         setAdvoLoading(false)
    //     } else {
    //         // toast.error(res?.message);
    //     }
    // };

    useEffect(() => {
        getAffinityPosts();
    }, [params?.id]);


    useEffect(() => {
        // getGoldCard();
        getComment();
        // viewApprovedAdvocacy();
    }, [])

    const handleemojiClick = () => {
        setEmojimodal(true)
        setIsHovered(true);
    }

    // const reactionIconMemo = useMemo(() => {
    //     return singleView?.[0]?.reactionData?.find(item => item?.userId === userData?._id)
    // }, [singleView, userData])



    const reactionIconMemo = useMemo(() => {
        if (showReaction) {
            return showReaction
        } else {
            return singleView?.[0]?.reactionData?.find(item => item?.userId === userData?._id)?.reactionData;
        }
    }, [singleView, userData, showReaction])



    const AnotherProfile = async (userID) => {
        if (userID === userData?._id) {
            navigate("/profile");
        } else {
            let response = await ApiHelperFunction({ urlPath: `/get-others-profile/${userID}`, method: "GET" })
            if (response && response?.status) {
                console.log("RESPONSE", response?.data?.data);
                let data = response?.data?.data;
                response && navigate(`/Profile_rating/${userID}`, {
                    state: {
                        data
                    }
                })
            } else {
                // toast.error('Error to fetching another profile data')
            }
        }
    }

    // const handleInitiativeClick = (ele) => {
    //     setInitiativeData(ele);
    //     setViewDetails(true);
    // };


    // console.log("reactionIconMemommm", singleView?.[0]?.userData)
    // console.log("reactionIconMemo", reactionIconMemo?.reactionData)


    const handledeleteeditreportClick = (ele, ind) => {
        setIndex((prev) => prev === ind ? "" : ind);
        setEditdeletereport(!editdeletereport);
        // console.log(ele?._id, index, "bmw", editdeletereport);
    }

    const handleeditClick = (ele) => {
        setEditnewthreemodal(!editnewthreemodal)
        setEditComment(ele);
    }


    //for reply open modal for edit delete report
    const handledeleteeditreportReplyClick = (ele, ind, i) => {
        setIndexReply((prev) => prev === `${ind}${i}` ? "" : `${ind}${i}`);
        // setIndexReply(ind?.[i]);
        setEditdeletereportReply(!editdeletereportReply);
        // console.log(ele?._id, ind?.[i], i, indexShow, "bmw", editdeletereportReply);
        // console.log("indexShowReply".indexShowReply, i)
    }

    //for reply handle edit modal
    const handleeditClickReply = (ele) => {
        setEditnewthreemodalReply(!editnewthreemodalReply)
        setEditCommentReply(ele);
    }

    //for delete reply
    const handleDeletereply = async (id) => {
        const res = await ApiHelperFunction({
            urlPath: `/deleted-reply/${id}`,
            method: "PUT",
        });
        if (res?.status) {
            toast.success("Reply is deleted successfully");
            getComment();
            dispatch(getAllPosts());
        } else {
            // toast.error(res?.message || "Something went wrong");
            // console.log("ERROR CREATING USER3", res);
        }
    }

    //mouse hover leave
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const textColor = isHovered ? 'red' : 'black';
    return (
        <>
            <section className="mainpagediv">
                <div className='homepagecontainer'>
                    <div className="thought_area_mxh-left">
                        <BulletinBoard />
                        <HallofFame />
                        <Employees />
                        <EventCard />
                        <JobsReferred />
                    </div>
                    <div className='thought_area_mxh-mid'>

                        <section id='sara_tylor'>
                            {/* {
                                postData?.map((item) => {
                                    return (
                                        <> */}
                            <div className='top_area' style={{ marginTop: "0px" }}>
                                <div className='left'>
                                    <div className='profile_img'
                                        onClick={() => AnotherProfile(singleView?.[0]?.userData?.[0]?.userID)}
                                    >
                                        {/* <img src={singleView?.[0]?.userData?.[0]?.image} alt="..." /> */}
                                        {
                                            singleView?.[0]?.docType === "giftcard"
                                                ?
                                                (
                                                    <>
                                                        {singleView?.[0]?.senderDetails?.[0]?.image ? (
                                                            <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                                                src={singleView?.[0]?.senderDetails?.[0]?.image}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        ) : (
                                                            <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                                                src={empUserImg}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        )}
                                                    </>
                                                )
                                                :
                                                (<>
                                                    {singleView?.[0]?.userData?.[0]?.image ? (
                                                        <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                                            src={singleView?.[0]?.userData?.[0]?.image}
                                                            className="img-fluid"
                                                            alt="profile"
                                                        />
                                                    ) : (
                                                        <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                                            src={empUserImg}
                                                            className="img-fluid"
                                                            alt="profile"
                                                        />
                                                    )}
                                                </>)
                                        }
                                    </div>

                                    <div className='details'>
                                        {/* <div
                                            className='name'
                                            onClick={() => AnotherProfile(singleView?.[0]?.userData?.[0]?.userID)}
                                        >
                                            <h2 >{singleView?.[0]?.userData?.[0]?.firstName} {singleView?.[0]?.userData?.[0]?.lastName}</h2>

                                            <div className='badgeimgdivimgsctfet'>
                                                {
                                                    singleView?.userType !== "Owner"
                                                        ?
                                                        (<>
                                                            <img src={singleView?.[0]?.userData?.[0]?.rewardCard?.[0]?.cardImage} className="img-fluid" alt="icon" />

                                                        </>)
                                                        :
                                                        (<></>)
                                                }
                                            </div>
                                        </div> */}

                                        <div className="name">
                                            {
                                                singleView?.[0]?.docType === "giftcard"
                                                    ?
                                                    (<h2 style={{ cursor: "pointer" }} onClick={AnotherProfile}>
                                                        {singleView?.[0]?.senderDetails?.[0]?.firstName + " " + singleView?.[0]?.senderDetails?.[0]?.lastName}
                                                    </h2>)
                                                    :
                                                    (
                                                        <h2 style={{ cursor: "pointer" }} onClick={AnotherProfile}>
                                                            {singleView?.[0]?.userData?.[0]?.firstName} {singleView?.[0]?.userData?.[0]?.lastName}
                                                        </h2>
                                                    )

                                            }

                                            {
                                                singleView?.[0]?.docType === "giftcard"
                                                    ?
                                                    (
                                                        <span>
                                                            {
                                                                singleView?.[0]?.userType !== "Owner"
                                                                    ?
                                                                    (<>  <img src={singleView?.[0]?.senderDetails?.[0]?.rewardCard?.[0]?.cardImage}
                                                                        className="img-fluid" alt="icon" />
                                                                        {/* {imagesArray?.userData?.[0]?.rewardCard?.[0]?.cardName} Club */}
                                                                    </>)
                                                                    : (<></>)
                                                            }

                                                        </span>
                                                    )
                                                    :
                                                    (
                                                        <span>
                                                            {
                                                                singleView?.[0]?.userType !== "Owner"
                                                                    ?
                                                                    (<>  <img src={singleView?.[0]?.userData?.[0]?.rewardCard?.[0]?.cardImage}
                                                                        className="img-fluid" alt="icon" />
                                                                        {/* {imagesArray?.userData?.[0]?.rewardCard?.[0]?.cardName} Club */}
                                                                    </>)
                                                                    : (<></>)
                                                            }

                                                        </span>
                                                    )
                                            }

                                        </div>
                                        <div className="text">
                                            <p>
                                                <span>{singleView?.userType} </span>
                                                <span>Posted on {moment(singleView?.[0]?.createdOn).format('YYYY - MM - DD')}</span>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="content" >
                                {singleView?.[0]?.link ? (
                                    <>
                                        <div>
                                            <p className='lrmtxtpptxtpp'>{singleView?.[0]?.prefix}</p>
                                            <Link to={singleView?.[0]?.link} target="_blank" rel="noopener noreferrer">
                                                {singleView?.[0]?.link}
                                            </Link>
                                            <p className='lrmtxtpptxtpp'>{singleView?.[0]?.suffix}</p>
                                        </div>
                                        <div className="reactplayerivmain" >
                                            <ReactPlayer url={singleView?.[0]?.link} controls width="100%"
                                                height="100%" />
                                        </div>
                                    </>
                                ) : (

                                    <p>{singleView?.[0]?.description || singleView?.[0]?.notes || singleView?.[0]?.desc}
                                    </p>
                                )}
                            </div>
                            {/* </>
                                    )
                                })
                            } */}

                            <div className="gallery_area">
                                {
                                    singleView?.[0]?.urlSiteName && (
                                        <>
                                            <div>
                                                <span>{singleView?.[0]?.urlSiteName}</span>
                                            </div>
                                            <div>
                                                <span>{singleView?.[0]?.urlTitle}</span>
                                            </div>
                                        </>
                                    )
                                }
                                {/* for post image */}
                                <Fancybox options={{ infinite: false }}>
                                    <p className="all_box">

                                        {
                                            (singleView?.[0]?.docType === "homePost") ?
                                                (<>
                                                    {/* {singleView?.[0]?.image[0] ? (<img src={singleView?.[0]?.image[0]}
                                                        style={{
                                                            width: `${singleView?.[0]?.image?.length === 1 ? "100%" : ""
                                                                }`,
                                                            height: `${singleView?.[0]?.image?.length === 1 ? "auto" : ""
                                                                }`,
                                                        }} alt="" />) : (<></>)} */}

                                                    {singleView?.[0]?.image[0] ? (
                                                        <>{
                                                            singleView?.[0]?.image?.map((ele) => {
                                                                return (
                                                                    <img src={ele}
                                                                        style={{
                                                                            width: `${singleView?.[0]?.image?.length === 1 ? "100%" : ""
                                                                                }`,
                                                                            height: `${singleView?.[0]?.image?.length === 1 ? "auto" : ""
                                                                                }`,
                                                                        }} alt="" />
                                                                )
                                                            })

                                                        }
                                                        </>
                                                    ) : (<></>)}

                                                    {
                                                        singleView?.[0]?.video?.[0] ? (<div className='trainingdivmainedt' >
                                                            <ReactPlayer
                                                                className='reactdivimgmainplrt'
                                                                controls
                                                                width='40rem'
                                                                height='20rem'
                                                                playing={false}
                                                                url={singleView?.[0]?.video}
                                                                // onBuffer={cousrevideoonClick}
                                                                config={{
                                                                    youtube: {
                                                                        playerVars: { showinfo: 1 }
                                                                    },
                                                                    facebook: {
                                                                        appId: '12345'
                                                                    },
                                                                }}

                                                            />
                                                        </div>) : (<></>)
                                                    }

                                                    {
                                                        singleView?.video?.[0] ? (<div className='trainingdivmainedt' >
                                                            <ReactPlayer
                                                                className='reactdivimgmainplrt'
                                                                controls
                                                                width='40rem'
                                                                height='20rem'
                                                                playing={false}
                                                                url={singleView?.video}
                                                                // onBuffer={cousrevideoonClick}
                                                                config={{
                                                                    youtube: {
                                                                        playerVars: { showinfo: 1 }
                                                                    },
                                                                    facebook: {
                                                                        appId: '12345'
                                                                    },
                                                                }}

                                                            />
                                                        </div>) : (<></>)
                                                    }
                                                </>) :
                                                (singleView?.[0]?.docType === "advocacyPost") ?
                                                    (<>
                                                        {singleView?.[0]?.image ? (<img src={singleView?.[0]?.image}
                                                            style={{
                                                                width: `${singleView?.[0]?.image ? "100%" : ""
                                                                    }`,
                                                                height: `${singleView?.[0]?.image ? "auto" : ""
                                                                    }`,
                                                            }} alt="" />) : (<></>)}
                                                    </>) :
                                                    (singleView?.[0]?.docType === "eventData") ?
                                                        (<>
                                                            {singleView?.[0]?.image ? (<img src={singleView?.[0]?.image}
                                                                style={{
                                                                    width: `${singleView?.[0]?.image ? "100%" : ""
                                                                        }`,
                                                                    height: `${singleView?.[0]?.image ? "auto" : ""
                                                                        }`,
                                                                }} alt="" />) : (<></>)}
                                                        </>) :
                                                        (<></>)
                                        }





                                        {/* {imagesArray && imagesArray?.image?.length > 0 &&
                                            imagesArray?.image?.slice(0, 4)?.map((item, index) => {
                                              return (
                                                <button
                                                  data-fancybox="gallery"
                                                  data-src="https://lipsum.app/id/1/800x600"
                                                  className="button button--secondary"
                                                  style={{
                                                    width: `${imagesArray?.image?.length === 1 ? "100%" : ""
                                                      }`,
                                                    height: `${imagesArray?.image?.length === 1 ? "auto" : ""
                                                      }`,
                                                  }}
                                                >
                                                  <div className="card-image">
                                                    <a
                                                      href={item}
                                                      data-fancybox="gallery"
                                                      data-caption="Caption Images 1"
                                                    >
                                                      <img
                                                        src={item}
                                                        className="img-fluid"
                                                        alt="Image Gallery"
                                                      />
                          
                                                      {imagesArray?.image.length > 4 && index === 3 && (
                                                        <div className="moreimgOverlay">
                                                          <div className="count_img">
                                                            <p>{imagesArray?.image.length - 4}+</p>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </a>
                                                  </div>
                                                </button>
                                              );
                                            })} */}
                                    </p>
                                </Fancybox>

                                {/* for card image */}
                                <div>
                                    {
                                        (singleView?.[0]?.docType === "giftcard") ?
                                            (<>

                                                <>
                                                    {
                                                        singleView?.[0]?.cardName === "ThankYou" && (<div className='cardgapbgdiv'>
                                                            <div className='thankyoucrdbg' style={{ backgroundImage: `url(${ThankYoucard})` }} >
                                                                <div className='profilebgthanky'>
                                                                    <div className='profilebgthankyimg'>

                                                                        {
                                                                            singleView?.[0]?.receiverImage ? (<img src={singleView?.[0]?.receiverImage} alt="..." />) : (<img src={"https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png"} alt="..." />)
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className=''>

                                                                    <p className='thankyoutxtppcrd'>{singleView?.[0]?.cardDesc}</p>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    }

                                                    {
                                                        singleView?.[0]?.cardName === "BirthDay" && (<div className='cardgapbgdiv'>
                                                            <div className='thankyoucrdbg' style={{ backgroundImage: `url(${HappyBirthdayCard})` }}>
                                                                <div className='companylitlleimg'>
                                                                    <img src={companyelite} alt="..." />
                                                                </div>
                                                                <div className='happybrthdivmain'>
                                                                    <p className='happytxtcrdpp'>Happy</p>
                                                                    <h4 className='birthtxtcrdpp'>Birthday</h4>
                                                                </div>
                                                                <div className='profilebgthankywh'>
                                                                    <div className='profilebgthankyimg'>

                                                                        {
                                                                            singleView?.[0]?.receiverImage ? (<img src={singleView?.[0]?.receiverImage} alt="..." />) : (<img src={"https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png"} alt="..." />)
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='happybrthdivmain'>

                                                                    <h4 className='birthtxtcrdpphead'>{singleView?.[0]?.cardDesc}</h4>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    }

                                                    {
                                                        singleView?.[0]?.cardName === "Onboarding" && (<div className='cardgapbgdiv'>
                                                            <div className='thankyoucrdbg' style={{ backgroundImage: `url(${Onboardingwelcomecardclean})` }}>
                                                                <div className='companylitlleimg'>
                                                                    <img src={companyelite} alt="..." />
                                                                </div>
                                                                <div className='textimagemainwelcmdivflx'>
                                                                    <div className='algnonbrddivmainwh'>
                                                                        <p className='wlcmcrdtxtpp'>Welcome Onboard </p>

                                                                        <h4 className='birthtxtcrdpphead'>{singleView?.[0]?.cardDesc}</h4>
                                                                    </div>
                                                                    <div className='algnonbrddivmainimg'>
                                                                        <div className='profilebgthankywh'>
                                                                            <div className='profilebgthankyimg'>

                                                                                {
                                                                                    singleView?.[0]?.receiverImage ? (<img src={singleView?.[0]?.receiverImage} alt="..." />) : (<img src={"https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png"} alt="..." />)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    }

                                                    {
                                                        singleView?.[0]?.cardName === "Anniversary" && (<div className='cardgapbgdiv'>
                                                            <div className='thankyoucrdbg' style={{ backgroundImage: `url(${HappyAnniversaryCardclean})` }}>
                                                                <div className='companylitlleimg'>
                                                                    <img src={companyelite} alt="..." />
                                                                </div>
                                                                <div className='happyannyversytxtppflx'>
                                                                    <div className='happyannyversyimgmain'>
                                                                        <div className='happyannyversyimg'>

                                                                            {
                                                                                singleView?.[0]?.receiverImage ? (<img src={singleView?.[0]?.receiverImage} alt="..." />) : (<img src={"https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png"} alt="..." />)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='txthappyanniversydiv'>

                                                                        <div className='happypritydivtxt'>

                                                                            <p className='thankyoutxtppcrd'>{singleView?.[0]?.cardDesc}</p>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )
                                                    }

                                                </>

                                            </>)
                                            :
                                            (<></>)
                                    }
                                </div>

                                {/* for badge */}

                                <div>
                        {
                          (singleView?.[0]?.docType === "sendBadge") ?
                            (
                              <>
                                <div className='cardgapbgdiv'>
                                  <div className='thankyoucrdbg' style={{ backgroundImage: `url(${ThankYoucard})` }} >
                                    <div className='profilebgthanky'>
                                      <div className='profilebgthankyimg'>

                                        {
                                          singleView?.[0]?.badgeDetails?.image && singleView?.[0]?.badgeDetails?.image !== ""
                                            ?
                                            <>
                                              {
                                                <img src={singleView?.[0]?.badgeDetails?.image} alt="" />
                                              }
                                            </>
                                            :
                                            <>
                                              <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="..." />
                                            </>
                                        }

                                        {/* {
                                          imagesArray?.receiverImage
                                            ? imagesArray?.receiverImage?.trim() !== ""
                                              ? (
                                                <img src={`${imagesArray.receiverImage}`} alt="..." />
                                              ) : (
                                                <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="..." />
                                              )
                                            : (
                                              <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="..." />
                                            )
                                        } */}

                                      </div>
                                    </div>
                                    <div className='thankyubrdrdiv'>
                                      Congratulations
                                    </div>

                                    <div className=''>
                                      <p className='thankyoutxtppcrd'>{singleView?.[0]?.badgeDetails?.badgename} for {singleView?.[0]?.receiverData?.firstName+" " +singleView?.[0]?.receiverData?.lastName}</p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            :
                            (<></>)
                        }
                      </div>


                            </div>

                            <div className='like_comment_area'>
                                {/* {
                                    postData?.map((item) => {
                                        return ( */}
                                <div class="left">
                                    {/* <i class="fa-solid fa-thumbs-up"></i> */}
                                    {/* {reactionIconMemo
                                        ?
                                        reactionIconMemo === "like" ? (<img src={ReactionLike} alt="..." className="likeIconImgg" />) :

                                            reactionIconMemo === "love" ? (<img src={ReactionLove} alt="..." className="likeIconImgg" />) :
                                                reactionIconMemo === "support" ? (<img src={ReactionSupport} alt="..." className="likeIconImgg" />) :
                                                    reactionIconMemo === "celebrate" ? (<img src={ReactionCelebrate} alt="..." className="likeIconImgg" />) :
                                                        reactionIconMemo === "curious" ? (<img src={ReationCurious} alt="..." className="likeIconImgg" />) :
                                                            reactionIconMemo === "insightful" ? (<img src={ReationInsightful} alt="..." className="likeIconImgg" />)
                                                                :
                                                                (<img src={Like} alt="..." className="likeIconImg" />)
                                        :
                                        (<img src={Like} alt="..." className="likeIconImg" />)

                                    } */}
                                    <p>{singleView?.[0]?.totalReaction} Reaction</p>
                                </div>
                                {/* //         )

                                //     })
                                // } */}

                                {/* <div class="left">
                            <i class="fa-solid fa-thumbs-up"></i>
                            <p>1 People Reacted</p>
                        </div> */}

                                {
                                    singleView?.[0]?.totalComment !== 0 && (
                                        <div className="right">
                                            <h6>
                                                <span>{singleView?.[0]?.totalComment} </span>
                                                Comment
                                            </h6>
                                            <p>
                                                <span>1</span> Share
                                            </p>
                                        </div>
                                    )
                                }
                            </div>
                            <div className='reaction_area'>
                                <button
                                    className="reaction"
                                    onClick={handleLikeDislike}
                                    onMouseEnter={handleemojiClick}
                                    // onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    style={{ color: textColor }}
                                // onMouseLeave={() => setEmojimodal(false)}
                                >
                                    {/* <img src={alike} alt="..." className="likeIconImg" /> */}
                                    {/* <span style={{ color: "blue" }}>Reaction</span> */}
                                    {/* <img src={selectedReaction ? reactions[selectedReaction] : alike} alt="..." className="likeIconImg" /> */}
                                    {reactionIconMemo
                                        ?
                                        reactionIconMemo === "like" ? (<img src={ReactionLike} alt="..." className="likeIconImgg" />) :

                                            reactionIconMemo === "love" ? (<img src={ReactionLove} alt="..." className="likeIconImgg" />) :
                                                reactionIconMemo === "support" ? (<img src={ReactionSupport} alt="..." className="likeIconImgg" />) :
                                                    reactionIconMemo === "celebrate" ? (<img src={ReactionCelebrate} alt="..." className="likeIconImgg" />) :
                                                        reactionIconMemo === "curious" ? (<img src={ReationCurious} alt="..." className="likeIconImgg" />) :
                                                            reactionIconMemo === "insightful" ? (<img src={ReationInsightful} alt="..." className="likeIconImgg" />)
                                                                :
                                                                // selectedReaction === "angry" ? (<img src={angry} alt="..." className="likeIconImgg" />) :
                                                                (<img src={Like} alt="..." className="likeIconImg" />)
                                        :
                                        (<img src={Like} alt="..." className="likeIconImg" />)

                                    }
                                    <span style={{ color: "black" }}>Reaction</span>

                                </button>

                                {emojimodal &&
                                    <div className='emojimodalbg' style={{ left: "379px", marginTop: "-80px" }}>
                                        <button className="reaction">
                                            <img src={ReactionLike} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(params?.postId, "like")} />
                                        </button>
                                        <button className="reaction">
                                            <img src={ReactionLove} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(params?.postId, "love")} />
                                        </button>
                                        <button className="reaction">
                                            <img src={ReactionSupport} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(params?.postId, "support")} />
                                        </button>
                                        <button className="reaction">
                                            <img src={ReactionCelebrate} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(params?.postId, "celebrate")} />
                                        </button>
                                        <button className="reaction">
                                            <img src={ReationCurious} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(params?.postId, "curious")} />
                                        </button>
                                        <button className="reaction">
                                            <img src={ReationInsightful} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(params?.postId, "insightful")} />
                                        </button>
                                        {/* <button className="reaction">
                                        <img src={angry} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(SingleimageId, "angry")} />
                                    </button> */}
                                    </div>
                                }

                                <button
                                    className="comments"
                                    onClick={() => {
                                        // setModalId(SingleimageId);
                                        setShowComment(!showComment);
                                        setCommentView(!commentView);
                                    }}
                                >
                                    <img src={Comment} alt="..." className="chatIconImg" />
                                    <span>Comment</span>
                                </button>


                                {/* {
                                    imagesArray?.userType === "Admin" ? (<RWebShare
                                        disableNative={true}
                                        data={{
                                            text: `Elite Employee Experience Hub - Powered by Fractals Global`,
                                            url: "https://fractalselite.com/",
                                            title: "Elite",
                                        }}
                                        sites={[
                                            "linkedin",
                                            "facebook",
                                            "twitter",
                                            "whatsapp",
                                            "mail",
                                            "copy",
                                        ]}
                                        // onClick={() => console.log("shared successfully!")}
                                        onClick={(platform) => {

                                            if (platform === "copy") {
                                                const textToCopy = `${BASE_URL}`;
                                                navigator.clipboard.writeText(textToCopy).then(() => {
                                                    toast.success("Link copied to clipboard!");
                                                });
                                            } else {
                                                // Handle other platforms
                                                // console.log("Shared successfully on", platform);
                                            }
                                        }}

                                    >
                                        <button className="share">

                                            <img src={share} alt="..." className="shareIconImg" />
                                            <span>Share</span>
                                        </button>

                                    </RWebShare>) : ( */}

                                {
                                    singleView?.[0]?.docType === "advocacyPost"
                                        ?
                                        (<RWebShare
                                            disableNative={true}
                                            data={{
                                                text: `Elite Employee Experience Hub - Powered by Fractals Global`,
                                                url: `http://${splitedDName}.fractalselite.com/singleadvocacy/${singleView?.[0]?.AdvocacyName?.replace(/\s+/g, '-')}/${params?.postId}`,
                                                title: "Elite",
                                            }}
                                            sites={[
                                                "linkedin",
                                                "facebook",
                                                "twitter",
                                                "whatsapp",
                                                "mail",
                                                "copy",
                                            ]}
                                            // onClick={() => console.log("shared successfully!")}
                                            onClick={(platform) => {
                                                viewShareAdvocacy();
                                                if (platform === "copy") {
                                                    const textToCopy = `http://${splitedDName}.fractalselite.com/singleadvocacy/${singleView?.[0]?.AdvocacyName?.replace(/\s+/g, '-')}/${params?.postId}`;
                                                    navigator.clipboard.writeText(textToCopy).then(() => {
                                                        toast.success("Link copied to clipboard!");
                                                    });
                                                } else {
                                                    // Handle other platforms
                                                    // console.log("Shared successfully on", platform);
                                                }
                                            }}

                                        >
                                            <button className="share">

                                                <img src={share} alt="..." className="shareIconImg" />
                                                <span style={{ paddingLeft: "5px" }}>Share</span>
                                            </button>

                                        </RWebShare>)
                                        :
                                        singleView?.[0]?.docType === "giftcard"
                                            ?
                                            (<></>)
                                            :
                                            (
                                                <button className="share" onClick={handleRepostModal}>
                                                    <img src={repost} alt="..." className="shareIconImg"
                                                    />
                                                    <span style={{ paddingLeft: "5px" }}>Repost</span>
                                                </button>
                                            )

                                }


                                {/* {
                                    singleView?.[0]?.docType === "giftcard"
                                        ?
                                        (<></>)
                                        :
                                        (
                                            <button className="share" onClick={handleRepostModal}>
                                                <img src={repost} alt="..." className="shareIconImg"
                                                />
                                                <span style={{ paddingLeft: "5px" }}>Repost</span>
                                            </button>
                                        )
                                } */}
                                {/* 
                                <button className="share" onClick={handleRepostModal}>
                                    <img src={repost} alt="..." className="shareIconImg"
                                    />
                                    <span style={{ paddingLeft: "5px" }}>Repost</span>
                                </button> */}
                                {/* )
                                } */}
                            </div>


                            <div className="profilepicimagecommnetfflx">
                                <div className="profile_picimgmain">
                                    {userData?.image ? (
                                        <img
                                            src={userData?.image}
                                            className="img-fluid"
                                            alt="profile"
                                        />
                                    ) : (
                                        <img
                                            src={ProfileImg1}
                                            className="img-fluid"
                                            alt="profile"
                                        />
                                    )}
                                </div>
                                <div className="textinptwodth">
                                    <form>
                                        <div className="form-group rplytxtdivrply">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id=""
                                                aria-describedby="textHelp"
                                                placeholder="Write a comment..."
                                                value={commentText}
                                                onChange={(e) => setCommentText(e.target.value)}
                                            />

                                            <div className="inpCmntAttach">
                                                <img src={Attach} alt="..." />
                                                <input
                                                    type="file"
                                                    class="form-control"
                                                    id="hostedBy"
                                                    placeholder="Choose a photo"
                                                    value={image}
                                                    name="hostedBy"

                                                    onChange={handleImageChange}
                                                />
                                            </div>

                                            {uploading ? <p style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: '-84%'
                                            }}>image uploading......</p> : null}


                                            <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsEmoji(prev => !prev)} />
                                            {isEmoji &&
                                                <EmojiPicker onEmojiClick={(emojiObject) => setCommentText(prev => prev + emojiObject.emoji)} />
                                            }
                                            <div className="postimgdivmn" onClick={handleComment}>
                                                <img src={post} alt="..." />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            {showComment && commentView && (
                                <div className="commentshow_area">
                                    {commentData?.length > 0 ? (
                                        commentData?.map((item, index) => {
                                            console.log("COMMDATA", item);
                                            return (
                                                <>
                                                    <div className="profile_picimgmaindiv" key={index}>
                                                        <div className="profile_picimgmain">
                                                            {item?.Userimage ? (
                                                                <img
                                                                    src={item?.Userimage}
                                                                    className="img-fluid"
                                                                    alt="profile"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={ProfileImg1}
                                                                    className="img-fluid"
                                                                    alt="profile"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="wrapboxdivbgwh">
                                                            <div className="man_details">
                                                                <div className="wrap_box">
                                                                    <div className="editnamedeletereportflx">
                                                                        <div className="name">
                                                                            <h4>{item?.firstName + " " + item?.lastName}</h4>
                                                                        </div>
                                                                        {
                                                                            (userData?._id === item?.userId) && (<div className="threedotsdktreportedt" onClick={() => handledeleteeditreportClick(item, index)}>
                                                                                <i className="fa-solid fa-ellipsis"></i>

                                                                                {
                                                                                    indexShow === index &&
                                                                                    // editdeletereport &&
                                                                                    <div
                                                                                        className="editdeleteupdatereportmodal"
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                        }}
                                                                                    >

                                                                                        <div className={item?.userId === userData?._id ? "editdivimgthreediv" : "editdivimgthree"} onClick={() => handleeditClick(item)}>
                                                                                            <img src={Edit} alt="..." />
                                                                                        </div>
                                                                                        <div className={item?.userId === userData?._id ? "editdivimgthreedeletediv" : "editdivimgthreedelete"} onClick={() => handleDeleteComment(item?._id)}>
                                                                                            <img src={Delete} alt="..." />
                                                                                        </div>
                                                                                        <div className="editdivimgthreereport">
                                                                                            <img src={reporticon} alt="..." />
                                                                                        </div>
                                                                                    </div>}

                                                                            </div>)
                                                                        }
                                                                    </div>

                                                                    {/* <div className='imagesinglepost'> */}


                                                                    {item?.image ? (
                                                                        <div className='imagesinglepost'>
                                                                            <img
                                                                                src={item?.image}
                                                                                className="img-fluid"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        // <div className='NoImageComment'>

                                                                        // </div>
                                                                        <></>
                                                                    )}
                                                                    {/* </div> */}


                                                                    <div className="text">
                                                                        <p>{item?.comment}</p>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="rplydkltflxmainrply">
                                                                <div className="rplydltbtndiv">
                                                                    <div className="dltimgmain">
                                                                        <img src={share} alt="..." onClick={() => handleReplyToggle(index)} />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="rplydltbtndiv">
                                                              
                                                                    <div className="dltimgmain" onClick={() => handleDeleteComment(item?._id)}>
                                                                        <img src={Delete} alt="..." />
                                                                    </div>
                                                                </div> */}
                                                            </div>


                                                            {
                                                                replyToggle?.[index] && (
                                                                    <>
                                                                        <div className="profilepicimagecommnetfflxdivmm">
                                                                            <div className="profile_picimgmain">
                                                                                {userData?.image ? (
                                                                                    <img
                                                                                        src={userData?.image}
                                                                                        className="img-fluid"
                                                                                        alt="profile"
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src={ProfileImg1}
                                                                                        className="img-fluid"
                                                                                        alt="profile"
                                                                                    />
                                                                                )}
                                                                            </div>

                                                                            <div className="textinptwodth">
                                                                                <form>
                                                                                    <div className=" form-group rplytxtdivrply">
                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder="Reply"
                                                                                            class="form-control"
                                                                                            name="replyText"
                                                                                            value={replyText}
                                                                                            onChange={(e) => setReplyText(e.target.value)}
                                                                                        />

                                                                                        <div className="inpCmntAttach">
                                                                                            <img src={Attach} alt="..." />
                                                                                            <input
                                                                                                type="file"
                                                                                                class="form-control"
                                                                                                id="images"
                                                                                                placeholder="Choose a photo"
                                                                                                name="hostedBy"

                                                                                                onChange={handleReplyImageChange}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsReplyEmoji(prev => !prev)} />
                                                                                            {isReplyEmoji &&
                                                                                                <EmojiPicker onEmojiClick={(emojiObject) => setReplyText(prev => prev + emojiObject.emoji)} />
                                                                                            }
                                                                                        </div>
                                                                                        <div className="postimgdivmn">
                                                                                            <img src={post} alt="..." onClick={() => handleReplyClick(item?._id)} />
                                                                                        </div>

                                                                                    </div>


                                                                                    {replyLoading ? <p style={{
                                                                                        position: 'absolute',
                                                                                        right: 0,
                                                                                        top: '-84%'
                                                                                    }}>Reply uploading......</p> : null}


                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }





                                                        </div>
                                                    </div>


                                                    {
                                                        item?.replyData?.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <div className='profilepicimgmaintxtflx'>
                                                                        <div className="profile_picimgmain" key={i}>
                                                                            {item?.UserImage ? (
                                                                                <img
                                                                                    src={item?.UserImage}
                                                                                    className="img-fluid"
                                                                                    alt="profile"
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={ProfileImg1}
                                                                                    className="img-fluid"
                                                                                    alt="profile"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className="wrapboxdivbgwh">
                                                                            <div className="man_details">
                                                                                <div className="wrap_box">
                                                                                    <div className="editnamedeletereportflx">
                                                                                        <div className="name">
                                                                                            <h4>{item?.firstName + " " + item?.lastName}</h4>
                                                                                        </div>
                                                                                        {
                                                                                            (userData?._id === item?.userID) &&
                                                                                            (<div
                                                                                                className="threedotsdktreportedt"
                                                                                                onClick={() => handledeleteeditreportReplyClick(item, index, i)}
                                                                                            >
                                                                                                <i className="fa-solid fa-ellipsis"></i>

                                                                                                {
                                                                                                    indexShowReply === `${index}${i}` &&
                                                                                                    // editdeletereport &&
                                                                                                    <div
                                                                                                        className="editdeleteupdatereportmodal"
                                                                                                        onClick={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                        }}
                                                                                                    >

                                                                                                        <div className="editdivimgthreediv" onClick={() => handleeditClickReply(item)}>
                                                                                                            <img src={Edit} alt="..." />
                                                                                                        </div>
                                                                                                        <div className="editdivimgthreedeletediv" onClick={() => handleDeletereply(item?._id)}>
                                                                                                            <img src={Delete} alt="..." />
                                                                                                        </div>
                                                                                                        <div className="editdivimgthreereport">
                                                                                                            <img src={reporticon} alt="..." />
                                                                                                        </div>
                                                                                                    </div>}

                                                                                            </div>)
                                                                                        }
                                                                                    </div>
                                                                                    {item?.image ? (
                                                                                        <img
                                                                                            src={item?.image}
                                                                                            className="img-fluid"
                                                                                            alt=""
                                                                                        />
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}

                                                                                    <div className="text">
                                                                                        <p>{item?.reply}</p>
                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                    {/* {
                                                        replyToggle?.[index] && (
                                                            <>
                                                                <div className="profilepicimagecommnetfflxdivmm">
                                                                    <div className="profile_picimgmain">
                                                                        {userData?.image ? (
                                                                            <img
                                                                                src={userData?.image}
                                                                                className="img-fluid"
                                                                                alt="profile"
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={ProfileImg1}
                                                                                className="img-fluid"
                                                                                alt="profile"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <div className="textinptwodth">
                                                                        <form>
                                                                            <div className="form-group rplytxtdivrply">
                                                                                <input
                                                                                    type="text"
                                                                                    placeholder="Reply"
                                                                                    name="replyText"
                                                                                    class="form-control"
                                                                                    value={replyText}
                                                                                    onChange={(e) => setReplyText(e.target.value)}
                                                                                />

                                                                                <div className="inpCmntAttach">
                                                                                    <img src={Attach} alt="..." />
                                                                                    <input
                                                                                        type="file"
                                                                                        class="form-control"
                                                                                        id="images"
                                                                                        placeholder="Choose a photo"
                                                                                        name="hostedBy"

                                                                                        onChange={handleReplyImageChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="">
                                                                                    <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsReplyEmoji(prev => !prev)} />
                                                                                    {isReplyEmoji &&
                                                                                        <EmojiPicker onEmojiClick={(emojiObject) => setReplyText(prev => prev + emojiObject.emoji)} />
                                                                                    }
                                                                                </div>
                                                                                <div className="postimgdivmn">
                                                                                    <img src={post} alt="..." onClick={() => handleReplyClick(item?._id)} />
                                                                                </div>

                                                                            </div>


                                                                            {replyLoading ? <p style={{
                                                                                position: 'absolute',
                                                                                right: 0,
                                                                                top: '-84%'
                                                                            }}>Reply uploading......</p> : null}


                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    } */}

                                                    {/* {item?.replyData?.length > 1 && item?.replyData?.length != replyIndex && (
                                                        <div className="ShowMoreDialogue_btn">
                                                            <butsetEmojimodalton
                                                                className="btn"
                                                                onClick={() => setReplyIndex(item?.replyData?.length)}
                                                                
                                                            >
                                                                Show More Replies
                                                            </butsetEmojimodalton>
                                                        </div>
                                                    )} */}

                                                    {/* off */}

                                                    {/* {
                                                        replyToggle[index] && (
                                                            <div className="">
                                                                <form>
                                                                    <div className="rplytxtdivrply">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Reply"
                                                                            name="replyText"
                                                                            value={replyText}
                                                                            onChange={(e) => setReplyText(e.target.value)}
                                                                        />
                                                                        <div className="postimgdivmn">
                                                                            <img src={post} alt="..." onClick={() => handleReplyClick(item?._id)} />
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        )
                                                    } */}

                                                </>
                                            );
                                        })
                                    ) : (
                                        <p>No comments to show!</p>
                                    )}

                                    {/* {commentData?.length > 3 && commentData?.length != commentIndex && (
                                        <div className="ShowMoreDialogue_btn">
                                            <butsetEmojimodalton
                                                className="btn"
                                                onClick={() => setCommetIndex(commentData?.length)}
                                            >
                                                Show More Comments
                                            </butsetEmojimodalton>
                                        </div>
                                    )} */}

                                    {imageURL !== "" && (

                                        <div style={{
                                            display: "flex", position: "relative",
                                            bottom: "0", left: '0', paddingTop: "10px"
                                        }}>
                                            <img
                                                style={{ marginTop: "1rem" }}
                                                src={imageURL}
                                                alt=""
                                                height="100rem"
                                                width="100rem"
                                            />
                                            <div
                                                onClick={() => {
                                                    setImageURL("");
                                                    setImage('')
                                                }}
                                            >
                                                <i
                                                    class="fa-solid fa-xmark"
                                                    style={{
                                                        color: "red",
                                                        fontSize: "2rem",
                                                        cursor: "pointer",
                                                    }}
                                                ></i>
                                            </div>
                                        </div>

                                    )}


                                    {/* <div className="mycomment_push">

                                        <form>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby="textHelp"
                                                    placeholder="Write a comment..."
                                                    value={commentText}
                                                    onChange={(e) => setCommentText(e.target.value)}
                                                />

                                                <div className="inpCmntAttach">
                                                    <i class="fa-solid fa-paperclip"></i>
                                                    <input
                                                        type="file"
                                                        class="form-control"
                                                        id="hostedBy"
                                                        placeholder="Choose a photo"
                                                        value={image}
                                                        name="hostedBy"

                                                        onChange={handleImageChange}
                                                    />
                                                </div>

                                                {uploading ? <p style={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: '-84%'
                                                }}>image uploading......</p> : null}


                                                <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsEmoji(prev => !prev)} />
                                                {isEmoji &&
                                                    <EmojiPicker onEmojiClick={(emojiObject) => setCommentText(prev => prev + emojiObject.emoji)} />
                                                }
                                                <button className="sendcommentbtn" onClick={handleComment}>
                                                    <i className="fa-solid fa-paper-plane"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </div> */}
                                </div>
                            )}

                        </section >
                    </div >



                    <div className="thought_area_mxh_right">
                        {/* {viewDetails && <AdvocacyDetailsModal closemodal={setViewDetails} initiativeData={initiativeData} />} */}
                        {/* <div className="bulletin_board">
                            <div className="top">
                                <div class="head bulleBoardHead">
                                    <figure class="bulleBoardFig">
                                        <img src={Advocacy} alt="..." />
                                    </figure>
                                    <h4>Advocacy</h4>
                                </div>
                                <div>
                                    <Link className="seeAllAn" to="/advocacy">
                                        See All
                                    </Link>
                                </div>
                            </div>
                            <div className="advcyimgdiv">
                      
                                <img src={approvedAdvocacyData?.[0]?.image} style={{
                                    width: `${approvedAdvocacyData?.[0]?.image === 1 ? "100%" : ""
                                        }`,
                                    height: `${approvedAdvocacyData?.[0]?.image === 0 ? "100%" : ""
                                        }`,
                                }} alt="..." />
                            </div>
                            <div className="advcytxtflx">
                                <div className="advcytxtpp">
                                    <p>{approvedAdvocacyData?.[0]?.description}</p>
                                </div>
                                <div className="">
                                    <Link className="viewbtnnn" onClick={() => handleInitiativeClick(approvedAdvocacyData?.[0])}>View</Link>
                                </div>
                            </div>

                        </div> */}
                        <div className="satuscarddivpading">
                            <Statuscard />
                        </div>
                        <div className="iniSusTabDiv">
                            <Tabs>
                                {/* <TabList>
                  <Tab>Initiative</Tab> 
                  <Tab>Sustainable</Tab>
                </TabList> */}

                                <TabPanel>
                                    <Initiative />
                                </TabPanel>
                                <TabPanel>
                                    {/* <GoalAchiver /> */}
                                </TabPanel>
                            </Tabs>
                        </div>
                        <TrainingCard />
                        <div className="myAffitiDiv">
                            <HubAffinityGrp />
                        </div>
                    </div>
                </div>
            </section>
            {editnewthreemodal && <Commenteditmodal
                closemodal={setEditnewthreemodal}
                activity={"comment"}
                postId={params?.postId}
                initialValues={editComment}
                getComment={getComment}
            />}

            {editnewthreemodalReply && <Replyeditmodal
                closemodal={setEditnewthreemodalReply}
                activity={"reply"}
                // postId={postId}
                initialValues={editCommentReply}
                getComment={getComment}
            />}

            {
                repostModal && (<RepostModal
                    closemodal={setRepostModal}
                    imagesArray={singleView?.[0]}
                    postId={params?.postId} />)
            }
        </>
    )
}

export default SingleImage