import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Eventimg2 from "../../Images/No-Image-Placeholder.png";
import CreateModal from "../../Component/Modal/CreateModal";
import { useDispatch, useSelector } from "react-redux";
import { getBillBoardData } from "../../redux/slices/billBoardSlice";
import moment from "moment";
import { useAuthCtx } from "../../context/AuthCtx";
// Constants
import { BASE_URL, API_ENDPOINT_PATH } from "../../constants/config";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import "./Details.css";
import KababMenu from "../../SeprateModal/KababMenu";
import bulletinImg from "../../Images/Icons/PNG/Bulletin_Board.png";
import BulletinView from "../../SeprateModal/BulletinView";
import add_icon from "../../Images/Icons/PNG/Add icon.png";
import BillBoardModal from "../../SeprateModal/BillBoardModal";
import Edit from "../../Images/headericon/Edit.png";
import Delete from "../../Images/headericon/Delete.png"
const initialValues = {
  eventName: "",
  hostedBy: "",
  eventDate: "",
  eventstarttime: "",
  eventendtime: "",
  lattitude: "",
  longitude: "",
  addinvites: "",
  name: "",
  notes: "",
  image: "",
  editTrainingName: "",
  editTraininDate: "",
};

export default function BulletinDetails() {
  const [indx, setindx] = useState("");
  const [modala, setModala] = useState(false);
  const [modalA, setModalA] = useState(false);
  const [modalB, setModalB] = useState(false);
  const [modalId, setModalId] = useState("");
  const [BulletinBoardSingleData, setBulletinBoardSingleData] = useState({});
  const [viewDetails, setViewDetails] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null); // New state
  const [top, setTop] = useState(null);
  const [left, setLeft] = useState(null);
  const dispatch = useDispatch();
  const billBoardData = useSelector(
    (state) => state?.billBoardData?.billBoardData
  );
  const createModal = () => {
    setModala(true);
  };
  const navigate = useNavigate()

  // const openDetails = (item) => {
  //   setViewDetails(!viewDetails);
  //   // alert(item.addNotes);
  // };

  const { userData } = useAuthCtx();

  const handleClick = (e, ele, index) => {
    setModalId(ele._id);
    // console.log("EVENT", e, index);
    setBulletinBoardSingleData(ele);
    // console.log("s", e, ele);
    setTop(e.clientY);
    setLeft(e.clientX);
    setModalA(!modalA);

    // console.log(modalId, ele?._id, modalA, "bmww")
  };

  const openDetails = (item, id, index) => {
    console.log("index", index);
    setBulletinBoardSingleData(item);
    setindx(index);
    setViewDetails(!viewDetails);
    //  alert("working")
    setSelectedDescription(item.addNotes || "");
  };

  // const handleIdClick = (id) => {
  //   // Fetch the description based on the ID
  //   const selectedItem = billBoardData.find((item) => item._id === id);
  //   if (selectedItem) {
  //     setModalId(id);
  //     setSelectedDescription(selectedItem.addNotes || "");
  //     setSelectedItemId(id); // Set the selected item ID
  //     setModalA(true);
  //   }
  // };

  useEffect(() => {
    dispatch(getBillBoardData());
    console.log("bill", billBoardData);
  }, []);

  const stopModal = (e) => {
    modalA && setModalA(false);
    // setModalId("");
    e.stopPropagation();
  };

  const handleDelete = async () => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-bulletin-board/${modalId}`,
      method: "PUT",
    });
    if (res?.status) {
      toast.success("Bulletin deleted successfully");

      dispatch(getBillBoardData());
      setModalA(false);
    } else {
      toast.error(res?.message || "Something went wrong");
      console.log("ERROR CREATING USER3", res);
    }
  };
  const handleviewbulletinClick = (item) => {
    const data = {
      item: item
    }
    // navigate("/BulletinView", { state: data })
    navigate(`/BulletinView/${item?.eventName?.replace(/\s+/g, '-')}/${item?._id}`, { state: data })
  }
  return (
    <section className="bulleDetail">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="bulletin_board" id="bullettBoad">
              <div className="top">
                <div className="head bulleBoardHead">
                  <figure className="bulleBoardFig">
                    <img src={bulletinImg} alt="..." />
                  </figure>
                  <h4>Bulletin</h4>
                  <div className="" style={{ marginLeft: "15px" }}>
                    {userData?.userType === "Admin" ||
                      userData?.userType === "Manager" ||
                      userData?.userType === "Owner" ? (
                      <div className="tyEvMainDiv" onClick={createModal}>
                        {/* <span className="tyEvIcon"> */}
                        <figure className="bulleBoardFig">
                          <img src={add_icon} alt="..." />
                        </figure>
                        {/* </span> */}
                        {/* <p className="tyEvText">Add Bulletin Board</p> */}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div>
                  <Link to="/" className="backHomeBtn">
                    <i
                      class="fa-solid fa-arrow-left"
                      style={{ color: "#000000" }}
                    ></i>
                    {/* Back */}
                  </Link>
                </div>
              </div>
              <div className="bottom bullentindetailflx">
                {billBoardData?.length > 0 ? (
                  billBoardData?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="card_area_details card_area_bullentindetails"
                        style={{
                          // marginBottom: "17px",
                          background: "#fff",
                          boxShadow: "0px 0px 5px 0px #ccc",
                          position: "relative",
                          // marginTop: "10px",
                        }}
                      >
                        <div className="event_img">
                          {item?.image ? (
                            <img
                              src={item?.image}
                              className="img-fluid"
                              alt="event"
                            />
                          ) : (
                            <img
                              src={Eventimg2}
                              className="img-fluid"
                              alt="event"
                            />
                          )}
                          <div className="">
                            {userData?._id === item?.addedBy || userData?.userType==="Owner" ? (
                              <div
                                className="dot_btn"
                                onClick={(e) => handleClick(e, item, index)}
                                style={{
                                  position: "relative",
                                  zIndex: "2",
                                  right: "-92%",
                                  top: "-151px",
                                }}
                              >
                                <Link
                                  to="/bulletinDetails"
                                  className="btn"
                                  style={{ color: "#fff" }}
                                >
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                </Link>
                                {modalId === item?._id && modalA && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      // overflow: "hidden",
                                      backgroundColor: "#fff",
                                      padding: "3px",
                                      border: "none",
                                      // top: "123px",
                                      padding: "3px 8px",
                                      marginLeft: "-46px",
                                      borderRadius: "4px",
                                      cursor: "pointer",
                                      boxShadow: "0 0 0.1rem 0",
                                      display: "flex",
                                      columnGap: "10px"
                                    }}
                                  >
                                    {/* <i
                                      style={{
                                        marginRight: "7px",
                                        fontSize: "14px",
                                      }}
                                      class="fas fa-edit"
                                      onClick={() => setModalB(true)}
                                    ></i>
                                    <i
                                      style={{
                                        margin: "3px",
                                        fontSize: "14px",
                                        color: "red",
                                      }}
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                      onClick={handleDelete}
                                    ></i> */}


                                    <div className="editimagemain" onClick={() => setModalB(true)}>
                                      <img src={Edit} alt="..." />
                                    </div>
                                    <div className="editimagemain" onClick={handleDelete}>
                                      <img src={Delete} alt="..." />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )
                            :
                            (<></>)
                          }
                            {/* <div className="vwdnbtn">
                              <button
                                className="viewbtn" style={{ cursor: "pointer" }}
                                key={index}
                                onClick={() => openDetails(item, item._id, index)}
                              >
                                View

                              </button>
                            </div> */}
                          </div>
                        </div>

                        <div className="detialshederdatepdng">
                          <div className="headrtbtndivflx">
                            <div className="head">
                              <h2>{item?.eventName}</h2>
                            </div>
                            <div className="">
                              <button
                                className="viewbtn"
                                style={{ cursor: "pointer" }}
                                key={index}
                                onClick={() =>
                                  // openDetails(item, item._id, index)
                                  handleviewbulletinClick(item)
                                }
                              >
                                View
                              </button>
                            </div>
                          </div>
                          <div className="date">
                            <p>
                              Date:{" "}
                              {moment(item?.eventDate)?.format("YYYY-MM-DD")}
                            </p>
                          </div>
                        </div>

                        <div className="event_details">
                          {/* <div className="hosted">
                    <p>Hosted by {item.hostedBy}</p>
                  </div> */}

                          {/* <div className="d-flex justify-content-between"> */}
                          {/* <div className="date">
                              <p>
                                Date:{" "}
                                {moment(item.eventDate).format("YYYY-MM-DD")}
                              </p>
                            </div> */}
                          {/* <button
                              className="viewbtn"
                              key={index}
                              onClick={() => openDetails(item, item._id, index)}
                            >
                              View
                              {viewDetails && index === indx && (
                                <div key={index} className="modaldescript">
                                  {item.addNotes}
                                </div>
                              )} */}

                          {/* {viewDetails && (
                    <div className="modaldescript">
                      {selectedDescription}
                    </div>
                  )} */}
                          {/* </button> */}

                          {/* <div className="time">
                      <p>Time: 5:30 PM</p>
                    </div> */}
                          {/* </div> */}
                          {/* <button
                            className="viewbtn"
                            onClick={() => openDetails(item)}
                          >
                            View
                          </button> */}
                          {/* <div className="d-flex justify-content-between">
                            <div className="date">
                              <p>Description : {item?.addNotes}</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>Nothing to show!</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {modalB && (
        <CreateModal
          closemodal={setModalB}
          activity={"kababMenu"}
          BulletinBoardSingleData={BulletinBoardSingleData}
          id={modalId}
          initialValues={BulletinBoardSingleData}
        />
      )} */}

      {modalB && (
        <KababMenu
          closemodal={setModalB}
          activity={"kababMenu"}
          BulletinBoardSingleData={BulletinBoardSingleData}
          id={modalId}
          initialValues={BulletinBoardSingleData}
        />
      )}

      {modala && (
        <BillBoardModal
          closemodal={setModala}
          activity={"billBoard"}
          initialValues={initialValues}
        />
      )}

      {/* {viewDetails && (
        <BulletinView
          selectedDescription={selectedDescription}
          closemodal={setViewDetails}
        />
      )} */}
    </section>
  );
}
