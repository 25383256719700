import React from 'react'

const SalaryTemplatecompthree = () => {
    return (
        <>
            <section className='salarytemplacomdibvmain'>
                <div className=''>
                    <p className='crtnetmpletxtppp'>
                        Salary Details
                    </p>
                </div>

                <div className='salrytemplatebgmain_bg'>
                    <div className='salarttemplatedivflsx'>
                        <div className='salarttemplatedivfrst'>
                            <p className='salrtytxtppytrtxtp'>
                                Annual CTC
                            </p>
                            <p className='brdsrcsalrybrdryrp'>
                                ₹1,20,000.00 per Year
                            </p>
                        </div>
                        <div className='salarttemplatedivscnd'>
                            <p className='salrtytxtppytrtxtp'>
                                Salary Per Month's
                            </p>
                            <p className='brdsrcsalrybrdryrp'>
                                ₹1,20,000.00 per Monthly
                            </p>
                        </div>
                    </div>
                </div>

                <div className='salrttxtppdtlstxtppinbgmain'>

                    <p className='salrttxtppdtlstxtppincltxtp'>
                        Salary Details : Including CTC
                    </p>

                    <div className='tablesaralydetlksdivtbldiv'>
                        <table className='tablesaralydetlksdivtbldmaon'>
                            <thead className='tablesaralydetlksdivtbldmaonthed'>
                                <tr className='tablesaralydetlksdivtmaintr'>
                                    <th className='tablesaralydetlksdivtmainthone'>
                                        <p className='templatenametetxpppcomptwotxtpp'>
                                            Salary Component
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainthtwo'>
                                        <p className='templatenametetxpppcomptwotxtpp' style={{ textAlign: "right" }}>
                                            Monthly Component
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainththre'>
                                        <p className='templatenametetxpppcomptwotxtpp' style={{ textAlign: "right" }}>
                                            Annual Component
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className=''>
                                <tr className='tablesaralydetlksdivtmaintr'>
                                    <th className='tablesaralydetlksdivtmainth' colSpan="12">
                                        <p className='erningtxtpptxtpp'>Earnings</p>
                                    </th>
                                </tr>

                                <tr className='tablesaralydetlksdivtmaintr'>
                                    <th className='tablesaralydetlksdivtmainth'>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr'>
                                            Basic
                                        </p>
                                        <p className='enarrsaddwritetetxtetheadthccfrt'>
                                            (50% of CTC)
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 5,000.00
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 6,000.00
                                        </p>
                                    </th>
                                </tr>

                                <tr className='tablesaralydetlksdivtmaintr'>
                                    <th className='tablesaralydetlksdivtmainth'>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr'>
                                            House Rent Allowance
                                        </p>
                                        <p className='enarrsaddwritetetxtetheadthccfrt'>
                                            (50% of Basic)
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 2,500.00
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 1,000.00
                                        </p>
                                    </th>
                                </tr>

                                <tr className='tablesaralydetlksdivtmaintr'>
                                    <th className='tablesaralydetlksdivtmainth'>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr'>
                                            Conveyance Allowance
                                        </p>
                                        <p className='enarrsaddwritetetxtetheadthccfrt'>
                                            (50% of Basic)
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 1000.00
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 12,000.00
                                        </p>
                                    </th>
                                </tr>

                                <tr className='tablesaralydetlksdivtmaintr'>
                                    <th className='tablesaralydetlksdivtmainth'>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr mb-3' >
                                            Children Education Allowance
                                        </p>

                                    </th>
                                    <th className='tablesaralydetlksdivtmainth ' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr  mb-3' style={{ textAlign: "right" }}>
                                            ₹ 500.00
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr  mb-3' style={{ textAlign: "right" }}>
                                            ₹ 12,000.00
                                        </p>
                                    </th>
                                </tr>

                                <tr className='tablesaralydetlksdivtmaintr'>
                                    <th className='tablesaralydetlksdivtmainth'>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr'>
                                            Transport Allowance
                                        </p>
                                        <p className='enarrsaddwritetetxtetheadthccfrt'>
                                            (20% of Basic)
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 1000.00
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 12,000.00
                                        </p>
                                    </th>
                                </tr>

                                <tr className='tablesaralydetlksdivtmaintr'>
                                    <th className='tablesaralydetlksdivtmainth' colSpan="12">
                                        <p className='erningtxtpptxtpp'>Deduction</p>
                                    </th>
                                </tr>

                                <tr className='tablesaralydetlksdivtmaintr'>
                                    <th className='tablesaralydetlksdivtmainth'>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr'>
                                            Absent
                                        </p>
                                        <p className='enarrsaddwritetetxtetheadthccfrt'>
                                            (50% of CTC)
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 5,000.00
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 6,000.00
                                        </p>
                                    </th>
                                </tr>

                                <tr className='tablesaralydetlksdivtmaintr'>
                                    <th className='tablesaralydetlksdivtmainth'>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr'>
                                            Medical Allowance
                                        </p>
                                        <p className='enarrsaddwritetetxtetheadthccfrt'>
                                            (50% of Basic)
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 2,500.00
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 1,000.00
                                        </p>
                                    </th>
                                </tr>

                                <tr className='tablesaralydetlksdivtmaintr'>
                                    <th className='tablesaralydetlksdivtmainth'>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr'>
                                            PF
                                        </p>
                                        <p className='enarrsaddwritetetxtetheadthccfrt'>
                                            (50% of Basic)
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 1000.00
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth' style={{ verticalAlign: "top" }}>
                                        <p className='enarrsaddwritetetxtetheadthppcompfr' style={{ textAlign: "right" }}>
                                            ₹ 12,000.00
                                        </p>
                                    </th>
                                </tr>

                                <tr className='tablesaralydetlksdivtmaintr' style={{ borderTop: "1px solid #ccc" }}>
                                    <th className='tablesaralydetlksdivtmainth'>
                                        <p className='cstcmpnytxtpptxtpp'>
                                            Salary Details (Including CTC)
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth'>
                                        <p className='cstcmpnytxtpptxtpp' style={{textAlign:"right"}}>
                                            ₹ 10,000.00
                                        </p>
                                    </th>
                                    <th className='tablesaralydetlksdivtmainth'>
                                        <p className='cstcmpnytxtpptxtpp'  style={{textAlign:"right"}}>
                                            ₹ 1,20,000.00
                                        </p>
                                    </th>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
            </section>
        </>
    )
}

export default SalaryTemplatecompthree
