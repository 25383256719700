import React, { useEffect, useState } from 'react'
import post from "../../Images/headericon/post_new.png"
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';


const initial1 = {
    complaint: "",
    tag: "",
    managerId: "",
    hrId: "",
    commiteeId: "",
    priority: ""
}
const GrivanceModal = ({ closeModal }) => {
    const [GrivanceData, setGrivanceData] = useState(initial1);
    const [managerData, setManagerData] = useState([]);
    const [hrData, setHrData] = useState([]);

    //for fetching all manager
    const getManagerData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-manager`,
            method: "GET",
        });
        if (response && response.status) {
            setManagerData(response?.data?.data);
        }
    };

    //for fetching all hr data
    const getHrData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-hr`,
            method: "GET",
        });
        if (response && response.status) {
            setHrData(response?.data?.data);
        }
    };


    //onChange functionality for radio button
    const handleRadioChange = (e) => {
        const { value } = e.target;
        setGrivanceData((prev) => ({
            ...prev,
            tag: value,
            managerId: "",
            hrId: "",
            commiteeId: ""
        }));
    };


    //Submit functionality for grivance
    const handleGrivance = async () => {
        if (GrivanceData?.complaint == "") {
            toast.error("All field is required")
            return false;
        }
        let data = {
            complaint: GrivanceData?.complaint,
            tag: GrivanceData?.tag,
            tagId: GrivanceData.managerId ? GrivanceData.managerId : GrivanceData.hrId ? GrivanceData?.hrId : GrivanceData.commiteeId ? GrivanceData?.commiteeId : "",
            priority: GrivanceData?.priority
        };
        // console.log("grivanceData", data)
        // return false
        let response = await ApiHelperFunction({
            urlPath: `/add-complaint`,
            method: "POST",
            data,
        });
        if (response && response.status) {
            toast.success(response?.data?.message);
            setGrivanceData(initial1)
        } else {
            toast.error(response?.response?.data?.message);
        }
    };

    //grivance box  onchange
    const handleChangeGrivance = (e) => {
        const { name, value } = e.target;
        setGrivanceData(prev => ({ ...prev, [name]: value }))

        if (name === "managerId") {
            setGrivanceData((prev) => ({
                ...prev,
                hrId: "",
                commiteeId: "",
            }))

        }
        if (name === "hrId") {
            setGrivanceData((prev) => ({
                ...prev, managerId: "",
                commiteeId: ""
            }))

        }

    }

    useEffect(() => {
        getHrData();
        getManagerData();
    }, [])
    return (
        <>
            <div className='mainsurveysuggestion'>
                <div className='surveysuggestioncontent'>
                    <div className='crsiconopentxdivflx'>
                        <div className='opentxtdivmainflxncm'>
                            <h4 className='opentxtdiv'>
                                Whistle Blower
                            </h4>
                            <p className='sgstyytxt'>

                                (Grievance Cell)
                            </p>
                        </div>
                        <div className='crsicon' onClick={() => closeModal()}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>

                    <div className='Specifydivmaindibscvnbp'>

                        <div className='Specifydivmain'>
                            <p className='Specifydivmaintxtgfhpp'>Please Specify Your Grievance</p>
                            <textarea rows="2" cols="30" placeholder='Write Maximum 200 Words'
                                name="complaint"
                                value={GrivanceData?.complaint}
                                onChange={(e) => handleChangeGrivance(e)}
                            >
                            </textarea>
                        </div>
                        <div className="">
                            <p className="prttxtdiv">Priority</p>
                            <div className="pstyflxgrvnc">
                                <button className={GrivanceData?.priority === "high" ?"hlbutton" : "hgbtn"} onClick={() => setGrivanceData(prev => ({ ...prev, priority: "high" }))}>High</button>
                                <button className={GrivanceData?.priority === "medium" ?"hlbutton" : "hgbtn"} onClick={() => setGrivanceData(prev => ({ ...prev, priority: "medium" }))}>Medium</button>
                                <button className={GrivanceData?.priority === "low" ?"hlbutton" : "hgbtn"} onClick={() => setGrivanceData(prev => ({ ...prev, priority: "low" }))}>Low</button>
                            </div>
                            <div className="adresdiv">
                                <p className="prttxtdiv">Address to</p>
                                <>

                                    <div className='radiobtntxtpp'>
                                        <input
                                            type="radio"
                                            name="tag"
                                            value="Manager"
                                            checked={GrivanceData.tag === "Manager"}
                                            onChange={handleRadioChange}
                                        />
                                        <label>Manager</label>
                                    </div>

                                    <div className='radiobtntxtpp'>
                                        <input
                                            type="radio"
                                            name="tag"
                                            value="Hr"
                                            checked={GrivanceData.tag === "Hr"}
                                            onChange={handleRadioChange}

                                        />
                                        <label>HR</label>
                                    </div>

                                    <div className='radiobtntxtpp'>
                                        <input
                                            type="radio"
                                            name="tag"
                                            value="GrievanceCell"
                                            checked={GrivanceData.tag === "GrievanceCell"}
                                            onChange={handleRadioChange}
                                        />
                                        <label>Suggestion Commitee</label>
                                    </div>

                                </>


                                <div className="pstyflxgrvnc">
                                    {
                                        GrivanceData.tag === "Manager" &&
                                        <div className="emplListDiv">
                                            <div>
                                                <select
                                                    class="empListSelect"
                                                    aria-label="Select Manager name"
                                                    name="managerId"
                                                    value={GrivanceData?.managerId}
                                                    onChange={(e) => handleChangeGrivance(e)}
                                                >
                                                    <option value={""} disabled>
                                                        Manager
                                                    </option>
                                                    {managerData?.map((item, i) => (
                                                        <option value={item?._id}>
                                                            {item?.firstName} {item?.lastName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    }



                                    {
                                        GrivanceData.tag === "Hr" &&
                                        <div className="emplListDiv">
                                            <div>
                                                <select
                                                    class="empListSelect"
                                                    aria-label="Select HR name"
                                                    name="hrId"
                                                    value={GrivanceData?.hrId}
                                                    onChange={(e) => handleChangeGrivance(e)}
                                                >
                                                    <option value={""} disabled>
                                                        HR
                                                    </option>
                                                    {hrData?.map((item, i) => (
                                                        <option value={item?._id}>
                                                            {item?.firstName} {item?.lastName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    {GrivanceData.tag === "GrievanceCell" && <button className="hgbtnnmgnsuggenstdiv"
                                        name="commiteeId"
                                        value={GrivanceData?.commiteeId
                                        }
                                        onClick={() => {
                                            setGrivanceData((prev) => ({
                                                ...prev,
                                                commiteeId: "",
                                                hrId: "",
                                                managerId: "",

                                            }))
                                        }}
                                    >
                                        Suggestion Committee
                                    </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="grvncdivmain" onClick={handleGrivance}>
                            send
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default GrivanceModal
