import React, { useState, useEffect } from 'react'
import { useAuthCtx } from '../context/AuthCtx';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-hot-toast';
import { ApiHelperFunction, fileUpload } from '../services/api/apiHelpers';
import { useDispatch } from 'react-redux';
import { getEventData } from '../redux/slices/eventSlice';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Event_View from "../../src/Images/Icons/PNG/Event_View.png"
import moment from 'moment';

const animatedComponents = makeAnimated();

const EditEventModal = ({ closemodal, activity, initialValues }) => {
  const { setLoading, loading, getUserDetails, userData } = useAuthCtx();
  const [imageURL, setImageURL] = useState(initialValues?.image);
  const dispatch = useDispatch();
  const [localerror, setlocalerror] = useState("");
  const [image, setImage] = useState();
  const [uploading, setUploading] = useState(false);
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([initialValues?.addEmploee]);
  const [colourOptions, setColourOptions] = useState([]);
  const [empData, setempData] = useState([]);
  const [eventTypeId, seteventTypeId] = useState([]);
  const [colourOptionseventTypeId, setColourOptionseventTypeId] = useState([]);
  const [selectedOptionseventTypeId, setSelectedOptionseventTypeId] = useState(initialValues?.eventTypeId);
  const [groupData, setGroupData] = useState([]);
  const [colourOptionsGroup, setColourOptionsGroup] = useState([]);
  const [selectedOptionsGroup, setSelectedOptionsGroup] = useState(initialValues?.affinityGroupId);
  const [isPublic, setIsPublic] = useState(false);
  const [postType, setPostType] = useState("");
  const [selectedOptionsPostType, setSelectedOptionsPostType] = useState(initialValues?.postType);

  const [formData, setFormData] = useState({
    eventType: {}
  })

  useEffect(() => {
    getEmployeeData();
    getEventTypeData();
    getMyGroupData();
    setFieldValue("selectedOptionsPostType", initialValues?.postType);
    setFieldValue("selectedOptionsGroup", initialValues?.affinityGroupId);
    setFieldValue("selectedOptionseventTypeId", initialValues?.eventTypeId);
    setFieldValue("selectedOptions", initialValues?.addEmploee);
  }, [])

  useEffect(() => {
    const data = initialValues?.addEmploee
    const mentorData = data?.map((item) => {
      const optData = colourOptions?.find(ele => ele?.value === item)
      return optData ? optData : {}
    })
    setSelectedOptions(mentorData)
  }, [colourOptions])




  console.log("initialValues", initialValues, colourOptionseventTypeId);
  //options for postType
  const options = [
    { value: 'public', label: 'Public' },
    { value: 'private', label: 'Affinity Group' }
  ]


  useEffect(() => {
    const data = initialValues?.postType
    const optData = options?.find(ele => ele?.value === data)
    setSelectedOptionsPostType(optData)
  }
    , []);


  //for selecting invitees
  const handleSelectChange = (e) => {
    console.log("Selected Options:", e);

    // Update the colourOptions state with the selected options
    setSelectedOptions(e);
  };

  //for selecting event type
  const handleSelectChangeEventType = (e) => {
    // console.log("esssdd", e)
    setSelectedOptionseventTypeId(e);
    // setFormData(prev => ({
    //   ...prev,
    //   eventType: e
    // }))
  };

  //for selecting group 
  const handleSelectChangeGroup = (e) => {
    setSelectedOptionsGroup(e);
  };

  //for selecting Post Type
  const handleSelectChangePostType = (e) => {
    console.log("publicio", e)
    // setPostType(selectedOptionsPostType);

    setSelectedOptionsPostType(e);
  };

  //get all invitees
  const getEmployeeData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-all-employees`,
      method: "GET",
    });

    console.log("RESPONSEF", response?.data?.data);

    if (response && response.status) {
      const formattedOptions = response?.data?.data?.map((item) => ({
        value: item?._id,
        label: item?.userName,
      }));

      setColourOptions(formattedOptions);
      setSelectedOptions(formattedOptions?.filter(e => e.value === initialValues.addEmploee))
      setempData(response?.data?.data);
    } else {
      // toast.error(response.message);
    }
  };

  //get event types
  const getEventTypeData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/fetch-event-type`,
      method: "GET",
    });

    console.log("RESPONSEWF", response?.data?.data);

    if (response && response.status) {
      const formattedOptions = response?.data?.data?.map((item) => ({
        value: item?._id,
        label: item?.typeName,
      }));

      setColourOptionseventTypeId(formattedOptions);
      // setFormData(prev => ({
      //   ...prev,
      //   eventType: formattedOptions.find(e => e.value === initialValues.eventTypeId)
      // }))
      setSelectedOptionseventTypeId(formattedOptions?.find(e => e.value === initialValues?.eventTypeId))
      seteventTypeId(response?.data?.data);
    } else {
      // toast.error(response.message);
    }
  };

  console.log("eventTypeId", eventTypeId)

  //get my affinity groups
  const getMyGroupData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-my-affinity-group`,
      method: "GET",
    });

    console.log("RESPONSEWTF", response?.data?.data);

    if (response && response.status) {
      const formattedOptions = response?.data?.data?.map((item) => ({
        value: item?._id,
        label: item?.groupName,
      }));

      setColourOptionsGroup(formattedOptions);
      setSelectedOptionsGroup(formattedOptions?.find(e => e.value === initialValues?.affinityGroupId))
      setGroupData(response?.data?.data);
    } else {
      // toast.error(response.message);
    }
  };


  const submitHandler = async (e, selectedSubscription) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    setShouldValidateOnBlur(true);

    let data = {};
    setLoading(true);
    if (
      values.eventName === "" ||
      values.eventDate === "" ||
      values.eventTypeId === "" ||
      imageURL === ""
    ) {
      return toast.error("All fields required");

    }

    data = {
      eventName: values?.eventName,
      eventDate: values?.eventDate,
      image: imageURL,
      notes: values?.notes,
      addEmploee: selectedOptions?.map((item) => item?.value),
      isHighLighted: values?.isHighLighted,
      eventTypeId: selectedOptionseventTypeId?.value,
      postType: selectedOptionsPostType?.value,
      affinityGroupId: selectedOptionsGroup?.value,
      eventTime: values?.eventTime
    };

    console.log("qwervbnncfg", data)
    // return false;
    const res = await ApiHelperFunction({
      urlPath: `/update-event/${initialValues?._id}`,
      method: "PUT",
      data,
    });
    if (res?.status) {
      toast.success("Event is updated successfully");
      resetForm();
      dispatch(getEventData());
      closemodal();
    } else {
      toast.error(res?.response?.data?.message || "Something went wrong");
      console.log("ERROR CREATING USER3", res);
    }

    setLoading(false);
  };

  const schemaCheck = Yup.object(
    activity === "billBoard" ||
      activity === "events" ||
      activity === "kababMenu" ||
      activity === "editEvent"
      ? {
        eventName: Yup.string().required("Event Name is required"),
        hostedBy: Yup.string().required("Host name is required"),
        eventDate: Yup.string().required("Event date is required"),
        eventstarttime: Yup.string().required("start Time is required"),
        eventendtime: Yup.string().required(" End Time is required"),
        notes: Yup.string().required(" Notes is required"),
        addinvites: Yup.string().required(" Addinvites is required"),
        lattitude: Yup.string().required(" lattitude is required"),

        longitude: Yup.string().required(" longitude is required"),
      }
      : activity === "training" || activity === "editTraining"
        ? {
          name: Yup.string().required("Event Name is required"),
          trainingDate: Yup.string().required("Training date is required"),
        }
        : activity === "jobReffered"
          ? {
            name: Yup.string().required("Job Name is required"),
            location: Yup.string().required("Job location is required"),
            salary: Yup.string().required("Salary range is required"),
            description: Yup.string().required("Description range is required"),
            opening: Yup.string().required("Opening is required"),
          }
          : ""
  );


  const handleImageChange = async (e) => {
    let image = e.target.files[0];
    setUploading(true);

    const form = new FormData();
    form.append("image", image);

    let res = await fileUpload("/image-upload", "POST", form);

    if (res.status) {
      toast.success("Image uploaded successfully");
      setImageURL(res?.image);
    } else {
      toast.error("Error uploading image");
    }
    setUploading(false);
  };

  const { values, errors, handleBlur, handleSubmit, resetForm, handleChange, setFieldValue } =
    useFormik({
      initialValues,

      validationSchema: schemaCheck,
      validateOnChange: shouldValidateOnChange,
      validateOnBlur: shouldValidateOnBlur,
      onSubmit: (val) => {
        console.log("val", val);
      },
    });

  return (
    <div className="createmodal_main">
      <div className="createmodalnewdiv">
        <div className="createmodalnewdivcontent">
          <div
            className=""
          // style={{ width: "100%", height: "100%", padding: "20px 0" }}
          >
            {/* <div className="newBulleCloseModDiv">
              <span className="newBulleCreHead">Add Event</span>
              <div onClick={() => closemodal()}>
                <i
                  class="fa-solid fa-xmark"
                  style={{
                    color: "red",
                    fontSize: "24px",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </div> */}
            <div className="bulletintextclose">
              <div className="bulletinimageflxdivmodal">
                <div className="bulleBoardFig">
                  <img src={Event_View} alt="..." />
                </div>
                <span className="newBulleCreHead">Update Event</span>
              </div>

              <div onClick={() => closemodal()}>
                <i
                  class="fa-solid fa-xmark"
                  style={{
                    color: "red",
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </div>

            {/* <Formik initialValues={initialValues} onSubmit={onSubmit}> */}
            <div className='formhgfthdivmain'>
              <form
                // onSubmit={(e) => {
                //   setShouldValidateOnBlur(true);
                //   setShouldValidateOnChange(true);
                //   handleSubmit(e);
                //   // submitHandler(e);
                // }}
                className=""
              >
                {/* <div className=""> */}
                {/* <button className="addCurLocaBtn">
                <i class="fa-solid fa-location-crosshairs"></i>
                Add location
              </button> */}
                {/* <input
                type="text"
                placeholder="Add location"
                className="modAddLocaInp"
              /> */}
                {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.6rem",
                }}
              >
                <p>lattitude:</p>
                <input
                  style={{ backgroundColor: "white" }}
                  type="text"
                  id="addlocation"
                  value={values.lattitude}
                  name="eventstarttime"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className="homePgMoInps"
                />
                <p>longitude:</p>
                <input
                  style={{ backgroundColor: "white" }}
                  type="text"
                  id="eventDate"
                  value={values.longitude}
                  name="eventendtime"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                />
              </div> */}
                {/* </div> */}

                <div className="homePgModInnerInpDivsmain">
                  <p>Event Name <span className='starmandatort'>*</span></p>
                  <input
                    type="text"
                    id="eventName"
                    aria-describedby="emailHelp"
                    placeholder=""
                    value={values?.eventName}
                    name="eventName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className=""
                  />
                  <small id="emailHelp" style={{ color: "red" }}>
                    {errors?.eventName}
                  </small>
                </div>

                {/* <div className="homePgModInnerInpDivsmain">
                <p>Hosted by</p>
                <input
                  type="text"
                  id="hostedBy"
                  placeholder="Hosted by"
                  value={values?.hostedBy}
                  name="hostedBy"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                />
                <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.hostedBy}
                </small>
              </div> */}

                <div className="homePgModInnerInpDivsmain">
                  <p>Event Date  <span className='starmandatort'>*</span></p>
                  <input
                    type="date"
                    id="eventDate"
                    placeholder=""
                    value={moment(values?.eventDate).format("YYYY-MM-DD")}
                    name="eventDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="homePgMoInps"
                    min={new Date()?.toISOString()?.split("T")[0]}
                  />
                  <small id="emailHelp" style={{ color: "red" }}>
                    {errors?.eventDate}
                  </small>
                  {/* <div
                style={{
                  paddingTop: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label>Pick Time</label>
                <small id="emailHelp" style={{ color: "red" }}>
                  {errors.eventstarttime}
                </small>
                <small id="emailHelp" style={{ color: "red" }}>
                  {localerror}
                  {errors.eventendtime}
                </small>
              </div> */}

                  {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.6rem",
                }}
              >
                <input
                  style={{}}
                  type="time"
                  id="eventDate"
                  placeholder="from"
                  value={values.eventstarttime}
                  name="eventstarttime"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFeventstarttime(e.target.value);
                  }}
                  className="homePgMoInps"
                />
                <p>to</p>
                <input
                  style={{}}
                  type="time"
                  id="eventDate"
                  placeholder="upto"
                  value={values.eventendtime}
                  name="eventendtime"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (compareTimes(Feventstarttime, e.target.value)) {
                      handleChange(e);
                      setFeventendtime(handleTimeChange(e));
                      setlocalerror("");
                    } else {
                      setlocalerror("Please select time correctly");
                    }
                  }}
                  className="homePgMoInps"
                />
              </div> */}
                </div>

                <div className="homePgModInnerInpDivsmain">
                  <p>Start Time <span className='starmandatort'>*</span></p>
                  <input
                    type="time"
                    id="eventTime"
                    aria-describedby="emailHelp"
                    placeholder="Enter Time"
                    value={values?.eventTime}
                    name="eventTime"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className=""
                  />
                  <small id="emailHelp" style={{ color: "red" }}>
                    {errors?.eventTime}
                  </small>
                </div>
                <div className="homePgModInnerInpDivsmain">
                  <p> Types Of Event <span className='starmandatort'>*</span></p>
                  {/* <input
                  type="text"
                  id="hostedBy"
                  placeholder="Add Invites"
                  value={values?.addinvites}
                  name="addinvites"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                /> */}
                  <Select
                    placeholder={"Select Event Type"}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    value={selectedOptionseventTypeId}

                    // defaultValue={colourOptionseventTypeId?.filter(e => e.value === initialValues.eventTypeId) && null}
                    options={colourOptionseventTypeId}
                    onChange={handleSelectChangeEventType}
                  />
                  {/* <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.addinvites}
                </small> */}
                </div>

                <div className="homePgModInnerInpDivsmain">
                  <p> Share Your Event <span className='starmandatort'>*</span></p>
                  {/* <input
                  type="text"
                  id="hostedBy"
                  placeholder="Add Invites"
                  value={values?.addinvites}
                  name="addinvites"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                /> */}


                  <Select
                    placeholder={"Select Post Type"}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    // defaultValue={[]}
                    value={selectedOptionsPostType}
                    options={options}
                    onChange={handleSelectChangePostType}
                  />


                  {/* <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.addinvites}
                </small> */}
                </div>

                {
                  (selectedOptionsPostType === 'private' ||
                    selectedOptionsPostType?.value === 'private') &&
                  (<div className="homePgModInnerInpDivsmain">
                    <p> Affinity Groups <span className='starmandatort'>*</span></p>
                    {/* <input
                  type="text"
                  id="hostedBy"
                  placeholder="Add Invites"
                  value={values?.addinvites}
                  name="addinvites"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                /> */}


                    <Select
                      placeholder={"Select Affinity Groups"}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      // defaultValue={[]}
                      value={selectedOptionsGroup}
                      options={colourOptionsGroup}
                      onChange={handleSelectChangeGroup}
                    />


                    {/* <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.addinvites}
                </small> */}
                  </div>)
                }


                {
                  (selectedOptionsPostType === 'public' ||
                    selectedOptionsPostType?.value === 'public') && (
                    <div className="homePgModInnerInpDivsmain">
                      <p>Invitation</p>
                      {/* <input
                  type="text"
                  id="hostedBy"
                  placeholder="Add Invites"
                  value={values?.addinvites}
                  name="addinvites"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                /> */}
                      <Select
                        placeholder={"Select Invitees"}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        value={selectedOptions}
                        // defaultValue={[]}
                        isMulti
                        options={colourOptions}
                        onChange={handleSelectChange}
                      />
                      {/* <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.addinvites}
                </small> */}
                    </div>)
                }




                <div className="eventmodalyesornodivflx">
                  <p>Is this event highlighted?</p>
                  <div className='isHighLightedRadiodiv'>
                    <input
                      type="radio"
                      id="isHighLightedYes"
                      name="isHighLighted"
                      value="yes"
                      checked={values.isHighLighted === "yes"}
                      onChange={handleChange}
                      className=""
                    />
                    <label htmlFor="isHighLightedYes" className="radioLabel" >
                      Yes
                    </label>
                  </div>
                  <div className='isHighLightedRadiodiv'>
                    <input
                      type="radio"
                      id="isHighLightedNo"
                      name="isHighLighted"
                      value="no"
                      checked={values.isHighLighted === "no"}
                      onChange={handleChange}
                      className=""
                    />
                    <label htmlFor="isHighLightedNo" className="radioLabel" >
                      No
                    </label>
                  </div>
                  <small id="isHighLightedHelp" style={{ color: "red" }}>
                    {errors.isHighLighted}
                  </small>
                </div>

                <div className="homePgModInnerInpDivsmain">
                  <p>Event Details</p>
                  <textarea
                    value={values?.notes}
                    name="notes"
                    id=""
                    cols=""
                    rows="4"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="addNoteCreModTextArea"
                  ></textarea>
                  <small id="emailHelp" style={{ color: "red" }}>
                    {localerror}
                    {errors?.notes}
                  </small>
                </div>

                {/* <div className="homePgModInnerInpDivs">
                <input
                  type="file"
                  id="hostedBy"
                  placeholder="Choose a photo"
                  value={image}
                  accept="image/*"
                  name="hostedBy"
                  onBlur={handleBlur}
                  onChange={handleImageChange}
                />
                <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.image}
                </small>
                {uploading ? <p>image uploading......</p> : null}
                {imageURL !== "" && (
                  <div style={{ display: "flex" }}>
                    <img
                      style={{ marginTop: "1rem" }}
                      src={imageURL}
                      alt=""
                      height="100rem"
                      width="100rem"
                    />
                    <div
                      onClick={() => {
                        setImageURL("");
                        setImage("");
                      }}
                    >
                      <i
                        class="fa-solid fa-xmark"
                        style={{
                          color: "red",
                          fontSize: "2rem",
                          cursor: "pointer",
                        }}
                      ></i>
                    </div>
                  </div>
                )}
              </div> */}


                <div className="homePgModInnerInpDivsupldflx">
                  <p> Image Upload <span className='starmandatort'>*</span></p>
                  <div className="bguploadbulletinmdbrdr">
                    <div className="bgcrdbulletin">
                      <div className="bgcontainerupldfilediv">
                        <div className="logoupldbulletin">
                          <img src="/static/media/Add icon.ed5715eb88ab4afb9efc.png" alt="..." />
                          <div className="">
                            <input
                              type="file"
                              id="image"
                              placeholder="Choose a photo"
                              // value={image}
                              accept="image/*"
                              name="image"
                              onBlur={handleBlur}
                              onChange={handleImageChange}
                              className="upldimagediv"
                            />

                          </div>
                        </div>
                        <div className="bgcontainerupldfiledivabslt">

                          {imageURL !== "" && (
                            <div className="upldimgdivbulletin" >
                              <img
                                src={imageURL}
                                alt="" />
                              <div className="clsoedivmainrd"
                                onClick={() => {
                                  setImageURL("");
                                  setImage("");
                                }}>
                                <i className="fa-solid fa-xmark"></i>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {uploading ? <p>image uploading......</p> : null}
                </div>
                <div className="bulletinsbmtbtndivv">
                  <button
                    type="button"
                    className="bulletinsbmtbtn"
                    onClick={submitHandler}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
            {/* </Formik> */}

          </div>
        </div>
      </div >
    </div >
  )
}

export default EditEventModal