import { Route, Routes } from "react-router-dom";
import CheckRoutes from "./CheckRoutes";
import Registration from "../Athentication/Registration";
import React, { useEffect } from "react";
import Index from "../View/Home/Index";
import Main from "../Component/Main/Main";
import Login from "../Foldable Login & Registration form/Login";
import Onboarding from "../View/Employee/Onboarding";
import EmployeePage from "../View/Employees/EmployeePage";
import Training from "../View/TrainingPage/Training"
import HubPage from "../View/Hub/HubPage";
import NetWorking from "../View/Hub/NetWorking";
import HubDevelopment from "../View/Hub/HubDevelopment";
import Profile from "../View/Profile/Profile";
import EngagementCalender from "../View/Engagementcalender/EngagementCalender";
import Advocacy from "../View/Advocacy/Advocacy";
import Learning from "../View/Learning/Learning";
import BulletinDetails from "../View/DetailsPages/BulletinDetails";
import HallOfDetails from "../View/DetailsPages/HallOfDetails";
import EmployeeDetail from "../View/DetailsPages/EmployeeDetail";
import InitiativeDetail from "../View/DetailsPages/InitiativeDetail";
import SustInitiativeDetail from "../View/DetailsPages/SustInitiativeDetail";
import EventsDeails from "../View/DetailsPages/EventsDeails";
import TrainingDetails from "../View/DetailsPages/TrainingDetails";
import JobsReferDetail from "../View/DetailsPages/JobsReferDetail";
import MyAffinityDetails from "../View/DetailsPages/MyAffinityDetails";
import Profilerating from "../View/ProfileRating/Profilerating";
import AgendaModal from "../Component/Modal/AgendaModal";
import NotiHome from "../View/Notifications/NotiHome";
import Surveys from "../View/Hub/Surveys";
import SingleAffinityGroup from "../View/AffinityGroup/SingleAffinityGroup";
import MyAffinityMyGroupsDetails from "../View/DetailsPages/MyAffinityMyGroupsDetails";
import SingleImage from "../View/Home/SingleImage";
import Landingpage from "../View/Landingpage/Landingpage";
import HrJobPost from "../View/Jobs/HrJobPost";
import ManagerJobPost from "../View/Jobs/ManagerJobPost";
import JobNotificationSingle from "../View/Notifications/JobNotificationSingle";
import Trainingmain from "../View/TrainingPage/Trainingmain";
import SingleMandatoryCourse from "../View/TrainingPage/SingleMandatoryCourse";
import SingleSkillCourse from "../View/TrainingPage/SingleSkillCourse";
import SingleMicroCourse from "../View/TrainingPage/SingleMicroCourse";
import TrainingCreatePage from "../View/TrainingPage/TrainingCreatePage";
import Singleongoing from "../View/TrainingPage/Singleongoing";
import CoursePreview from "../View/TrainingPage/CoursePreview";
import EnlargedNewEnrollment from "../View/TrainingPage/EnlargedNewEnrollment";
import TrainingNotification from "../View/Notifications/TrainingNotification";
import CourseVideoPlayer from "../View/TrainingPage/CourseVideoPlayer";
import Feedbackquize from "../View/TrainingPage/Feedbackquize";
import Documentrepository from "../View/TrainingPage/Documentrepository";
import Articlepage from "../View/TrainingPage/Articlepage";
import FullArticleview from "../View/TrainingPage/FullArticleview";
import Createdocumentform from "../View/TrainingPage/Createdocumentform";
import Singlerequestedtraining from "../View/TrainingPage/Singlerequestedtraining";
import TrainingCreateManager from "../View/TrainingPage/TrainingCreateManager";
import TrainingCreateEmployee from "../View/TrainingPage/TrainingCreateEmployee";
import TrainingCreateManagerNotification from "../View/Notifications/TrainingCreateManagerNotification";
import Singleadvocacy from "../View/Advocacy/Singleadvocacy";
import Advocacyaddform from "../View/Advocacy/Advocacyaddform";
import CoursefirstVideoPlayer from "../View/TrainingPage/CoursefirstVideoPlayer";
import Jobssingledetails from "../View/DetailsPages/Jobssingledetails";
import SingleNontechnicalCourse from "../View/TrainingPage/SingleNontechnicalCourse";
import Certificate from "../View/AllCertificatesMain/Certificate";
import Firstadvocacy from "../View/Advocacy/Firstadvocacy";
import Secondadvocacy from "../View/Advocacy/Secondadvocacy";
import BulletinView from "../SeprateModal/BulletinView";
import Hranalyticspage from "../View/HrAnalytics/Hranalyticspage";
import EliteWorkplaceCertification from "../View/Landingpage/EliteWorkplaceCertification";
import EliteExperienceHub from "../View/Landingpage/EliteExperienceHub";
import InitiativeSinglePage from "../View/DetailsPages/InitiativeSinglePage";
import Follower from "../View/Profile/Follower";
import EventView from "../SeprateModal/EventView";
import Message from "../View/Message/Message";
import Registrationpage from "../Athentication/Registrationpage";
import EventsModal from "../SeprateModal/EventsModal";
import SaveAllTraining from "../View/Profile/SaveAllTraining";
import Hranalyticssub from "../View/HrAnalytics/Hranalyticssub";
import Subscriptionplan from "../View/Landingpage/Subscriptionplan";
// import LandingLayout from "../View/Landingpage/LandingLayout";
import Hiringdetials from "../../src/View/Profile/Hiringdetails"
import SingleReqTrainingMH from "../View/TrainingPage/SingleReqTrainingMH";
import ForgotPassWord from "../Athentication/ForgotPassWord";
import Orgchart from "../View/OrgChart/Orgchart"
import Orgchartdetails from "../View/OrgChart/Orgchartdetails";
import Orgmanagerchart from "../View/OrgChart/Orgmanagerchart";
import Applicantdetails from "../View/DetailsPages/Applicantdetails";
import Surveydetails from "../View/Hub/Surveydetails";
import SinglePhysicalCourse from "../View/TrainingPage/SinglePhysicalCourse";
import SuggestionList from "../View/GrivenceSuggestion/SuggestionList";
import GrivenceList from "../View/GrivenceSuggestion/GrivenceList"
import Attendence from "../View/Attendenceall/Attendence";
import ManagerviewProject from "../View/Attendenceall/ManagerviewProject";
import ProjectViewdetails from "../View/Attendenceall/ProjectViewdetails"
import MyLeavesmain from "../View/Attendenceall/MyLeavesmain";
import Certificatesmain from "../View/AllCertificatesMain/Certificatesmain";
import LeaveReqList from "../View/Attendenceall/LeaveReqList";
import InitiativeModal from "../Component/Modal/InitiativeModal";
import EditInitiativeModal from "../SeprateModal/EditInitiativeModal";
import MyViewProject from "../View/Attendenceall/MyViewProject";
import Salarytemplatemain from "../View/Salary Template/Salarytemplatemain";
import Contact from "../View/Home/Contact";
import About from "../View/Home/About";
// import GrivenceList from "../Component/Modal/GrivenceList";
// import SuggestionList from "../Component/Modal/SuggestionList";
const AppRoutes = () => {
  
  //for setting socket 
  const userValid = localStorage?.getItem("Authorization")
  useEffect(() => {
    // if (userValid) {
    //   SocketConnect(userValid)
    // } else {
    //   console.log("userValid")
    // }
  }, [userValid])

  return (
    <Routes>
      <Route element={<CheckRoutes />}>
        <Route element={<Main />}>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/affinity/:id" element={<SingleAffinityGroup />} />
          <Route path="onboarding" element={<Onboarding />} />
          <Route path="employees" element={<EmployeePage />} />
          <Route path="hubpage" element={<HubPage />} />
          <Route path="registration" element={<Registration />} />
          <Route path="netWorking" element={<NetWorking />} />
          <Route path="surveys" element={<Surveys />} />
          <Route path="surveydetails/:id" element={<Surveydetails />} />
          <Route path="grivenceList" element={<GrivenceList />} />
          <Route path="project" element={<InitiativeModal />} />
          <Route path="editProject" element={<EditInitiativeModal/>}/>
          <Route path="suggestionList" element={<SuggestionList />} />
          <Route path="hubDevelopment" element={<HubDevelopment />} />
          <Route path="profile" element={<Profile />} />
          <Route path="saveTraining" element={<SaveAllTraining />} />
          <Route path="engagementcalender" element={<EngagementCalender />} />
          <Route path="createevent" element={<EventsModal />} />
          <Route path="advocacy" element={<Advocacy />} />
          <Route path="hiringdetials/:id" element={<Hiringdetials />} />
          <Route path="bulletinDetails" element={<BulletinDetails />} />
          <Route path="hallOfDetails" element={<HallOfDetails />} />
          <Route path="employeeDetail" element={<EmployeeDetail />} />
          <Route path="initiativeDetail" element={<InitiativeDetail />} />
          <Route path="/:nameOfInitaitive/:id" element={<InitiativeSinglePage />} />
          <Route path="singleimage/:doc/:postId" element={<SingleImage />} />
          <Route path="singleReqTrainingmh/:id" element={<SingleReqTrainingMH/>}/>
          <Route path="sustInitiativeDetail" element={<SustInitiativeDetail />} />
          <Route path="leaveReqList" element={<LeaveReqList />} />
          <Route path="eventsDeails" element={<EventsDeails />} />
          <Route path="trainingDetails" element={<TrainingDetails />} />
          <Route path="singleTrainingNotification" element={<TrainingNotification />} />
          <Route path="jobsReferDetail" element={<JobsReferDetail />} />
          <Route path="applicantdetails/:id" element={<Applicantdetails />} />
          <Route path="myAffinityDetails" element={<MyAffinityDetails />} />
          <Route path="msg" element={<Message />} />
          <Route
            path="myaffinitymygroups"
            element={<MyAffinityMyGroupsDetails />}
          />
          <Route path="hrJobPost" element={<HrJobPost />} />
          <Route path="managerJobPost" element={<ManagerJobPost />} />
          <Route path="jobNotificationSingle" element={<JobNotificationSingle />} />
          <Route path="notiHome" element={<NotiHome />} />
          {/* <Route path="/bulletin-board" element={<BulletinBoard />} /> */}
          <Route path="Learning" element={<Learning />} />
          <Route path="training" element={<Training />} />
          <Route path="trainingmain" element={<Trainingmain />} />
          <Route path="singleMandatoryCourse" element={<SingleMandatoryCourse />} />
          <Route path="singleSkillCourse" element={<SingleSkillCourse />} />
          <Route path="singleMicroCourse" element={<SingleMicroCourse />} />
          <Route path="singleongoing" element={<Singleongoing />} />
          <Route path="Singlerequestedtraining" element={<Singlerequestedtraining />} />
          <Route path="trainingCreate" element={<TrainingCreatePage />} />
          <Route path="trainingCreateManager" element={<TrainingCreateManager />} />
          <Route path="trainingCreateEmployee" element={<TrainingCreateEmployee />} />
          <Route path="singleManagerTrainingNotification" element={<TrainingCreateManagerNotification />} />
          <Route path="singleNontechnicalCourse" element={<SingleNontechnicalCourse />} />
          <Route path="singlePhysicalCourse" element={<SinglePhysicalCourse />} />
          <Route path="Profile_rating/:userID" element={<Profilerating />} />
          <Route path="coursepreview" element={<CoursePreview />} />
          <Route path="courseVideoPlayer/:title/:tId/:id" element={<CourseVideoPlayer />} />
          <Route path="coursefirstVideoPlayer/:title/:tId/:id" element={<CoursefirstVideoPlayer />} />
          <Route path="feedbackquize/:id" element={<Feedbackquize />} />
          <Route path="enlargedNewEnrollment/:title/:id" element={<EnlargedNewEnrollment />} />
          <Route path="documentrepository" element={<Documentrepository />} />
          <Route path="articlepage" element={<Articlepage />} />
          <Route path="fullarticleview/:id" element={<FullArticleview />} />
          <Route path="createdocumentform" element={<Createdocumentform />} />
          <Route path="singleadvocacy/:AdvocacyName/:_id" element={<Singleadvocacy />} />
          <Route path="firstadvocacy/:AdvocacyName/:_id" element={<Firstadvocacy />} />
          <Route path="secondadvocacy/:AdvocacyName/:_id" element={<Secondadvocacy />} />
          <Route path="advocacyaddform" element={<Advocacyaddform />} />
          <Route path="followers" element={<Follower />} />
          <Route path="jobssingledetails/:jobTitle/:_id" element={<Jobssingledetails />} />
          <Route path="/BulletinView/:eventName/:id" element={<BulletinView />} />
          <Route path="/EventView/:eventName/:id" element={<EventView />} />
          <Route path="/hranalyticspage" element={<Hranalyticspage />} />
          <Route path="/hranalyticssub" element={<Hranalyticssub/>}/>
          <Route path="/orgchart" element={<Orgchart/>}/>
          <Route path="/orgchartdetails/:id" element={<Orgchartdetails/>}/>
          <Route path="/orgmanagerchart/:hid/:id" element={<Orgmanagerchart/>}/>
          <Route path="/attendence" element={<Attendence/>}/>
          <Route path="/managerviewproject" element={<ManagerviewProject/>}/>
          <Route path="/myviewproject" element={<MyViewProject/>}/>
          <Route path="/projectviewdetails/:id" element={<ProjectViewdetails/>}/>
          <Route path="/myleaves" element={<MyLeavesmain/>}/>
          <Route path="/salarytemplatemain" element={<Salarytemplatemain/>}/>
          <Route path="/certificatemain" element={<Certificatesmain/>}/>
          {/* <Route path="/projects" element={<Managerview/>}/> */}
        </Route>
        <Route path="agendaModal" element={<AgendaModal />} />
        <Route path="/certificateofeducation" element={<Certificate />} />
      </Route>

      {/* <Route path="/" element={<Landingpage />} /> */}
      <Route path="/login" element={<Login />} />
      <Route path="/forgotPass" element={<ForgotPassWord/>}/>
      <Route path="/registrationpage" element={<Registrationpage />} />
      <Route path="/subscriptionplan" element={<Subscriptionplan />} />
      {/* <Route path="" element={<Landingpage />} /> */}
      <Route path="/EliteExperienceHub" element={<EliteExperienceHub />} />
      <Route path="/EliteWorkplaceCertification" element={<EliteWorkplaceCertification />} />

    </Routes>
  );
};

export default AppRoutes;
