import React, { useEffect, useState } from 'react'
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';
// import "./Grivencesuggestion.css";
// import GrivenceCell from "../../Images/headericon/GrivenceCell.png";
// import search from "../../Images/headericon/search.png";
// import filter from "../../Images/headericon/filter.png"
// import GrivenceCell from "../../Images/headericon/GrivenceCell.png";
import moment from 'moment';


const LeaveReqList = () => {
    const [leaveData, setLeaveData] = useState([]);

    //for view Leave data
    const viewLeaveData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-leave-req`,
            method: "GET"
        });
        if (response.status) {
            setLeaveData(response?.data?.data)
        }
    };


    //for action functionality
    const handleAction = async (id, approvalStatus) => {
        let data = {
            leaveStatus: approvalStatus,
        };
        const response = await ApiHelperFunction({
            urlPath: `/leave-req-handle/${id}`,
            method: "POST",
            data
        })

        if (response.status) {
            toast.success(response?.data?.message);
        }
        else {
            toast.error(response?.message);
        }

    }

    useEffect(() => {
        viewLeaveData();
    }, [])

    return (
        <section className='Grivencesuggestiondivmain'>
            <div className='employeepagecontainer'>


                <div className='Grivencesuggsbdivbghj'>

                    <div className="grovencedivmabdivpading">

                        <div className='GrivenceCelldivimh'>
                            {/* <img
                                src={GrivenceCell}

                                alt="icon"
                            /> */}

                        </div>
                        <p className="newBulleBrodHead">Leave Management</p>

                    </div>


                    <div className='grovencedivmabdivpadingbtm'>

                        {
                            leaveData?.map((item, i) => {
                                return (

                                    <div className="notiPersonImggrovendivfmainboc" key={i}>
                                        <div className='notiPersonImggrovendivflx'>

                                            <div className='fggdjjhanotiPersonIm'>
                                                <div className="notiPersonImggroven">
                                                    <img src={item?.leaveApplicantImage} alt="..." />
                                                </div>
                                                <div className="fggdjjhafcsghlop">

                                                    <div className='grivenceprorotyttxdivmain'>
                                                        <p className="grivenceprorotyttx">
                                                            {item?.leaveApplicant}
                                                        </p>
                                                        <p className="grivenceprorotyttx">
                                                            Created On :
                                                            <span className='grivenceprorotyttxspn'>{moment(item?.createdOn)?.format('YYYY-MM-DD')}</span>
                                                        </p>
                                                        <p className="grivenceprorotyttx">
                                                            Leave Type :
                                                            <span>{item?.leaveTypeName}</span>
                                                        </p>
                                                    </div>
                                                    <div className='grivenceprorotyttxdivmain'>
                                                        <p className="grivenceprorotyttx">
                                                            Leave Start Date :
                                                            <span className='grivenceprorotyttxspn' >{moment(item?.startDate)?.format('YYYY-MM-DD')}</span>
                                                        </p>
                                                        {
                                                            item?.unit === "hour"
                                                                ?
                                                                (
                                                                    <p className="grivenceprorotyttx">
                                                                        Time Duration :
                                                                        <span className='grivenceprorotyttxspn' >{item?.startTime} - {item?.EndTime}</span>
                                                                    </p>
                                                                )
                                                                :
                                                                (
                                                                    <p className="grivenceprorotyttx">
                                                                        Leave End Date :
                                                                        <span className='grivenceprorotyttxspn' >{moment(item?.endDate)?.format('YYYY-MM-DD')}</span>
                                                                    </p>
                                                                )
                                                        }
                                                    </div>
                                                    {/* <div className='grivenceprorotyttxdivmain'>
                                                        <p className="grivenceprorotyttx">
                                                            Priority
                                                            <span className='grivenceprorotyttxspn' >{item?.priority} </span>
                                                        </p>
                                                    </div> */}

                                                    <div className=''>
                                                        <p className="grivenceprorotyttx">
                                                            Reason :
                                                            <span className='grivenceprorotyttxspn'> {item?.reason}</span>
                                                        </p>
                                                    </div>


                                                </div>
                                            </div>


                                            <div className='manndgfacctdibn'>
                                                {
                                                    item?.leaveStatus === "pending"
                                                        ?
                                                        <>
                                                            <button className='btn btn-success' onClick={() => handleAction(item?._id, "accept")}>
                                                                Accept
                                                            </button>
                                                            <button className='btn btn-danger' onClick={() => handleAction(item?._id, "reject")}>
                                                                Reject
                                                            </button>
                                                        </>
                                                        :
                                                        item?.leaveStatus === "accept" ?
                                                            <button className='btn btn-success'>
                                                                Accepted
                                                            </button>
                                                            :
                                                            <button className='btn btn-danger'>
                                                                Rejected
                                                            </button>


                                                }
                                            </div>
                                        </div>


                                    </div>
                                )
                            })
                        }

                    </div>
                </div>

            </div>
        </section>
    )
}

export default LeaveReqList