import React from 'react'

const Salarytemplatecomp = () => {
    return (
        <>

        <section className='salarytemplacomdibvmain'>
            <div className=''>
                <p className='crtnetmpletxtppp'>
                    Create New Template
                </p>
            </div>

            <div className='templatenamedesriptiondiv'>
                <div className='templatenamedesriptiondivdivflx'>
                    <div className='templatedivright'>
                        <div className='tmepletxtpppnamefrmmaindiv'>
                            <p className='tmepletxtpppnamefrm'>
                                Template Name
                            </p>
                            <input type="text" />
                        </div>
                    </div>
                    <div className='templatedivleft'>
                        <div className='tmepletxtpppnamefrmmaindiv'>
                            <p className='tmepletxtpppnamefrm'>
                                Description

                            </p>
                            <input type="text" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='templatedivsalarydetaildiv'>
                <div className='annualysycttdivmaindivflx'>
                    <div className='annualtctttsttxctppdivmain'>
                        <p className='annualtctttsttxctpp'>Annual CTC</p>
                    </div>

                    <div className='annualysycttdivmain'>
                        <div className='riiuppwttstdiv'>
                            <p className='ruppeerstrrsymoboltxt'>₹</p>
                        </div>
                        <div className=''>
                            <p className='ruppeeincondomettxpp'>120000</p>
                        </div>
                    </div>
                    <span className='pweryyersttrxtppp'>Per Year</span>
                </div>

                <div className=''>
                    <table className='tabledivslipmiantable'>
                        <thead>
                            <tr>
                                <th className='basicdivfrst'>
                                    <p className='slrttxtpppcmpltxp'>
                                        Salary Component
                                    </p>
                                </th>
                                <th className='basicdivscnd'>
                                    <p className='slrttxtpppcmpltxp'>
                                        Calculation Type
                                    </p>
                                </th>
                                <th className='basicdivfrth'>
                                    <p className='slrttxtpppcmpltxpcenter'>
                                        Monthly Amount
                                    </p>
                                </th>
                                <th className='basicdivffth'>
                                    <p className='slrttxtpppcmpltxpnnt'>
                                        Annual Amount
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    <p className='erningtxtpp'>Earnings</p>
                                </th>
                            </tr>

                            <tr>
                                <th className='teysvvdrowscnd'>
                                    <p className='babsttgsyyxttxppp'>
                                        Basic
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <div className='brdrprnstctrtcbrdr'>
                                        <div className='inpusttdivtstrexttsoo'>
                                            <input type='text' />
                                            <span className='perggsggstxrtxpctstpp'>
                                                %of CTC
                                            </span>
                                        </div>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd' >
                                    <div className='badisbrdrdivmain'>
                                        <p className='fivethsdindtxtpp'>
                                            5000
                                        </p>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <p className='fivethsdindtxtppspclnewoine'>
                                        6000
                                    </p>
                                </th>

                            </tr>

                            <tr>
                                <th className='teysvvdrowscnd'>
                                    <p className='babsttgsyyxttxppp'>
                                        Fixed Allowance
                                    </p>
                                    <p className='babsttgsyyxttxppp'>
                                        Monthly CTC-Sum of all other Components
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd'>

                                    <p className='fivethsdindtxtppfixed'>
                                        Fixed Amount
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd' >
                                    <div className='badisbrdrdivmainwitho'>
                                        <p className='fivethsdindtxtpptettxnewpp'>
                                            0
                                        </p>
                                    </div>

                                </th>

                                <th className='teysvvdrowscnd'>
                                    <p className='fivethsdindtxtppspclnewoine'>
                                        0
                                    </p>
                                </th>

                            </tr>


                            <tr>
                                <th className='teysvvdrowscnd'>
                                    <p className='babsttgsyyxttxppp'>
                                        House Rent Allowance
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <div className='brdrprnstctrtcbrdr'>
                                        <div className='inpusttdivtstrexttsoo'>
                                            <input type='text' />
                                            <span className='perggsggstxrtxpctstpp'>
                                                %of CTC
                                            </span>
                                        </div>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd' >
                                    <div className='badisbrdrdivmain'>
                                        <p className='fivethsdindtxtpp'>
                                            5000
                                        </p>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <p className='fivethsdindtxtpp'>
                                        6000

                                        <span className='arrowcrrrsitttpp'>
                                            <i className="fa-solid fa-xmark"></i>
                                        </span>
                                    </p>
                                </th>

                            </tr>

                            <tr>
                                <th className='teysvvdrowscnd'>
                                    <p className='babsttgsyyxttxppp'>
                                        Conveyance Allowance
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <div className='brdrprnstctrtcbrdr'>
                                        <div className='inpusttdivtstrexttsoo'>
                                            <input type='text' />
                                            <span className='perggsggstxrtxpctstpp'>
                                                %of CTC
                                            </span>
                                        </div>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd' >
                                    <div className='badisbrdrdivmain'>
                                        <p className='fivethsdindtxtpp'>
                                            5000
                                        </p>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <p className='fivethsdindtxtpp'>
                                        6000
                                        <span className='arrowcrrrsitttpp'>
                                            <i className="fa-solid fa-xmark"></i>
                                        </span>
                                    </p>


                                </th>

                            </tr>

                            <tr>
                                <th className='teysvvdrowscnd'>
                                    <p className='babsttgsyyxttxppp'>
                                        Children Education Allowance
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <p className='fivethsdindtxtppfixed'>
                                        Fixed Amount
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd' >
                                    <div className='badisbrdrdivmain'>
                                        <p className='fivethsdindtxtpp'>
                                            5000
                                        </p>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <p className='fivethsdindtxtpp'>
                                        6000
                                        <span className='arrowcrrrsitttpp'>
                                            <i className="fa-solid fa-xmark"></i>
                                        </span>
                                    </p>


                                </th>

                            </tr>


                            <tr>
                                <th className='teysvvdrowscnd'>
                                    <p className='babsttgsyyxttxppp'>
                                        Transposrt Allowance
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <div className='brdrprnstctrtcbrdr'>
                                        <div className='inpusttdivtstrexttsoo'>
                                            <input type='text' />
                                            <span className='perggsggstxrtxpctstpp'>
                                                %of CTC
                                            </span>
                                        </div>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd' >
                                    <div className='badisbrdrdivmain'>
                                        <p className='fivethsdindtxtpp'>
                                            5000
                                        </p>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <p className='fivethsdindtxtpp'>
                                        6000
                                        <span className='arrowcrrrsitttpp'>
                                            <i className="fa-solid fa-xmark"></i>
                                        </span>
                                    </p>
                                </th>

                            </tr>

                            <tr>
                                <th>
                                    <p className='erningtxtpp'>Deduction</p>
                                </th>
                            </tr>

                            <tr>
                                <th className='teysvvdrowscnd'>
                                    <p className='babsttgsyyxttxppp'>
                                        Provident Fund
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <div className='brdrprnstctrtcbrdr'>
                                        <div className='inpusttdivtstrexttsoo'>
                                            <input type='text' />
                                            <span className='perggsggstxrtxpctstpp'>
                                                %of CTC
                                            </span>
                                        </div>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd' >
                                    <div className='badisbrdrdivmain'>
                                        <p className='fivethsdindtxtpp'>
                                            5000
                                        </p>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <p className='fivethsdindtxtpp'>
                                        6000
                                        <span className='arrowcrrrsitttpp'>
                                            <i className="fa-solid fa-xmark"></i>
                                        </span>
                                    </p>
                                </th>

                            </tr>

                            <tr>
                                <th className='teysvvdrowscnd'>
                                    <p className='babsttgsyyxttxppp'>
                                        Professional Tax
                                    </p>

                                </th>

                                <th className='teysvvdrowscnd'>

                                    <p className='fivethsdindtxtppfixed'>
                                        Fixed Amount
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd' >

                                    <div className='badisbrdrdivmain'>
                                        <p className='fivethsdindtxtpp'>
                                            5000
                                        </p>
                                    </div>

                                </th>

                                <th className='teysvvdrowscnd'>
                                    <p className='fivethsdindtxtpp'>
                                        9000
                                        <span className='arrowcrrrsitttpp'>
                                            <i className="fa-solid fa-xmark"></i>
                                        </span>
                                    </p>
                                </th>

                            </tr>


                            <tr>
                                <th className='teysvvdrowscnd'>
                                    <p className='babsttgsyyxttxppp'>
                                        Income Tax
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <p className='fivethsdindtxtppfixed'>
                                        Fixed Amount
                                    </p>
                                </th>

                                <th className='teysvvdrowscnd' >
                                    <div className='badisbrdrdivmain'>
                                        <p className='fivethsdindtxtpp'>
                                            5000
                                        </p>
                                    </div>
                                </th>

                                <th className='teysvvdrowscnd'>
                                    <p className='fivethsdindtxtpp'>
                                        6000
                                        <span className='arrowcrrrsitttpp'>
                                            <i className="fa-solid fa-xmark"></i>
                                        </span>
                                    </p>
                                </th>

                            </tr>

                            <tr className='tfootetdivvsgfccfdbg'>
                                <th>
                                    <p className='erningtxtpprighttnew'>Cost to Company</p>
                                </th>
                                <th>

                                </th>
                                <th>
                                    <p className='erningtxtpprightt'>₹10000</p>
                                </th>


                                <th>
                                    <p className='erningtxtpprightt'>₹120000</p>
                                </th>



                            </tr>

                        </tbody>

                    </table>
                </div>
            </div>
            </section>
        </>
    )
}

export default Salarytemplatecomp
