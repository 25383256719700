import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { useAuthCtx } from '../../context/AuthCtx';
import toast from 'react-hot-toast';
import moment from 'moment';

const InitiativeSinglePage = () => {
    const { userData } = useAuthCtx();
    const params = useParams();
    const [singleView, setSingleView] = useState([])

    //for view Single Page
    const viewSinglepost = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-single-initiative/${params?.id}`,
            method: "GET",
        });
        if (response?.status === 200) {
            console.log("singleView", response?.data?.data)
            setSingleView(response?.data?.data?.[0]);
        } else {

        }
    }

    //for join initiative
    const handleJoinClick = async (id) => {
        const data = {
            initiativesID: id
        };
        const response = await ApiHelperFunction({
            urlPath: `/join-initiative`,
            method: "POST",
            data,
        });

        if (response.status) {
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.response?.data?.message);
        }
    };

    useEffect(() => {
        viewSinglepost();
    }, [])

    return (
        <>
            <section className='initialsingle'>
                <div className='initialsinglecontainer'>
                    <div className="initialdetails">
                        <div className='inititaiveheadingmodaltxtmaindiv'>
                            <p className="inititaiveheadingmodaltxt">
                                Name Of Project : <span className='initiativetextdtlsmodaltxt'>
                                    {singleView?.nameOfInitaitive}
                                </span>
                            </p>
                        </div>
                        <div className='inititaiveheadingmodaltxtmaindiv'>
                            <p className="inititaiveheadingmodaltxt">
                                Description : <span className='initiativetextdtlsmodaltxt'>
                                    {singleView?.description}
                                </span>
                            </p>
                        </div>
                        <div className='inititaiveheadingmodaltxtmaindiv'>
                            <p className="inititaiveheadingmodaltxt">
                                Reward Points : <span className='initiativetextdtlsmodaltxt'>
                                    {singleView?.rewardPoints}
                                </span>
                            </p>
                        </div>
                        <div className='inititaiveheadingmodaltxtmaindiv'>
                            <p className="inititaiveheadingmodaltxt">
                                Start Date : <span className='initiativetextdtlsmodaltxt'>
                                    {moment(singleView?.startDate)?.format('YYYY-MM-DD')}
                                </span>
                            </p>
                        </div>
                        <div className='inititaiveheadingmodaltxtmaindiv'>
                            <p className="inititaiveheadingmodaltxt">
                                End Date : <span className='initiativetextdtlsmodaltxt'>
                                    {moment(singleView?.endDate)?.format('YYYY-MM-DD')}
                                </span>
                            </p>
                        </div>

                        {
                            userData?._id === singleView?.addedBy ?
                                (<></>) :
                                (<div className="jnbtndivinititvemarginmainduiv">
                                    <button className="jnbtndivinititveinitiatv" onClick={() => handleJoinClick(singleView?._id)}>Join</button>
                                </div>)
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default InitiativeSinglePage