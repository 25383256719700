import React, { useEffect, useState } from "react";
import "./Notifications.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import BulletinBoard from "../../Component/BulletinBoard";
import HallofFame from "../../Component/HallofFame";
import Employees from "../../Component/Employees";
import Initiative from "../../Component/Initiative";
import EventCard from "../../Component/EventCard";
import TrainingCard from "../../Component/TrainingCard";
import JobsReferred from "../../Component/JobsReferred";
import HubAffinityGrp from "../Hub/HubAffinityGrp";
import BillBoardModal from "../../SeprateModal/BillBoardModal";
import { useAuthCtx } from "../../context/AuthCtx";
import GoalAchiverModal from "../../SeprateModal/GoalAchiverModal";
import EventsModal from "../../SeprateModal/EventsModal";
import TrainingModal from "../../SeprateModal/TrainingModal";
import JobRefferedModal from "../../SeprateModal/JobRefferedModal";
import { useDispatch, useSelector } from "react-redux";
import NotificationActual from "./NotificationActual";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import JobNotificationActual from "./JobNotificationActual";
import { getClaimPoints, getOnboardingMicroTraining, getRecruitmentJobs } from "../../redux/slices/jobRecruitmentSlice";
import { getRefferJobs} from "../../redux/slices/jobRefferedSlice";
import Chatbot from "../../Component/Chatbot/Chatbot";


function NotiHome() {
  const {
    modala,
    modalD,
    setModalD,
    modalF,
    setModalF,
    modalH,
    setModalH,
    modalJ,
    setModalJ,
    setModala
  } = useAuthCtx();
  // const [modalId, setModalId] = useState("");
  const dispatch = useDispatch();

  const initialValues = {
    eventName: "",
    hostedBy: "",
    eventDate: "",
    eventstarttime: "",
    eventendtime: "",
    lattitude: "",
    longitude: "",
    addinvites: "",
    name: "",
    notes: "",
    image: "",
    editTrainingName: "",
    editTraininDate: "",
  };

  const initialValues1 = {
    eventName: "",
    hostedBy: "",
    eventDate: "",
    name: "",
    trainingDate: "",
    editTrainingName: "",
    editTraininDate: "",
  };

  const initialValues2 = {
    eventName: "",
    hostedBy: "",
    eventDate: "",
    name: "",
    trainingDate: "",
    editTrainingName: "",
    editTraininDate: "",
  };

  const initialValues3 = {
    name: "",
    location: "",
    salary: "",
    opening: "",
    description: "",
  };

  // const handleOptionClick = (index) => {
  //   setChangeShow(index === modalId);
  // };

  // const [changeShow, setChangeShow] = useState(false);
  const [notiData, setNotiData] = useState([]);
  const [unreadArr, setUnreadArr] = useState([]);
  const [jobNoti, setNoti] = useState([]);


  //for fetching all notification
  const viewAllNotification = async () => {
    let res = await ApiHelperFunction({
      urlPath: "/view-notification",
      method: "GET",
    });

    if (res && res?.status) {
      setNotiData(res?.data?.data);
    } else {
      // toast.error(res?.response?.data?.message);
    }
  };

  //for all unread notification 
  const viewUnreadNotification = async () => {
    let res = await ApiHelperFunction({
      urlPath: "/view-unread-notification",
      method: "GET",
    });

    if (res && res?.status) {
      setUnreadArr(res?.data?.data);
    } else {
      // toast.error(res?.response?.data?.message);
    }
  };

  const jobs = useSelector((state) => state?.jobRecruitmentSlice?.jobsrec);
  const refferedjobs = useSelector((state) => state?.jobSlice?.refferjobs);
  const microTraining = useSelector((state) => state?.jobRecruitmentSlice?.allMicroTraining);
  const cliamPoints = useSelector((state) => state?.jobRecruitmentSlice?.cliamPoints);

  // Function to fetch job details based on jobRequirmentId
  const getJobDetails = (jobRequirmentId) => {
    return jobs?.find(job => job?._id === jobRequirmentId);
  };

  // Function to fetch microTraining details based on microTrainingId
  const getMicroTrainingDetails = (microTrainingId) => {
    return microTraining?.find(microTraining => microTraining?._id === microTrainingId);
  };


  // Function to fetch managerTraining details based on onboardingTrainingId
  const getManagerTrainingDetails = (onboardingTrainingId) => {
    return microTraining?.find(onboardingTraining => onboardingTraining?._id === onboardingTrainingId);
  };

  // Function to fetch claim points based on claimPointId
  const getClaimPoint = (claimPointId) => {
    return cliamPoints?.find(claimPoint => claimPoint?._id === claimPointId);
  };


  // Function to fetch reffered job based on jobId
  const getrefferedjob = (refferlId) => {
    console.log("firstop", refferedjobs?.find(refferedjob => refferedjob?._id === refferlId))
    return refferedjobs?.find(refferedjob => refferedjob?._id === refferlId);
  };
  // console.log("optionrtyu", refferedjobs)

  //for fetching all notification
  const viewAllJobNotification = async () => {
    let res = await ApiHelperFunction({
      urlPath: "/view-job-notification",
      method: "GET",
    });

    if (res && res?.status) {
      setNoti(res?.data?.data);
    } else {
      // toast.error(res?.response?.data?.message);
    }
  };

  useEffect(() => {
    viewAllNotification();
    viewUnreadNotification();
    viewAllJobNotification();
    dispatch(getRecruitmentJobs());
    dispatch(getOnboardingMicroTraining());
    dispatch(getClaimPoints());
    dispatch(getRefferJobs());
    getMicroTrainingDetails();
    getManagerTrainingDetails();
    getrefferedjob();
    getClaimPoint();
    getJobDetails();
  }, []);


  // const unreadArr = [
  //   {
  //     icon: <i class="fa-regular fa-comment-dots"></i>,
  //     bgColor: true,
  //   },
  //   {
  //     icon: <i class="fa-regular fa-comment-dots"></i>,
  //     bgColor: true,
  //   },
  //   {
  //     icon: <i class="fa-regular fa-comment-dots"></i>,
  //     bgColor: true,
  //   },
  //   {
  //     icon: <i class="fa-regular fa-bell"></i>,
  //     bgColor: true,
  //   },
  //   {
  //     icon: <i class="fa-regular fa-bell"></i>,
  //     bgColor: true,
  //   },
  //   {
  //     icon: <i class="fa-regular fa-bell"></i>,
  //     bgColor: true,
  //   },
  //   {
  //     icon: <i class="fa-regular fa-comment-dots"></i>,
  //     bgColor: true,
  //   },
  //   {
  //     icon: <i class="fa-regular fa-bell"></i>,
  //     bgColor: true,
  //   },
  // ];
  // console.log("payloadreqqq", microTraining)
  return (
    <>
      <section className="mainpagediv">
        <div className="">
          <div className="thought_area_mxh-left">
            <BulletinBoard />
            <HallofFame />
            <Employees />
            <EventCard />
            <JobsReferred />
          </div>
          <div className="thought_area_mxh-mid notiMidThgtDiv">
            <div className="notifiInnerDiv">
              <p className="notifiHead">Notifications</p>
              <div className="allNotiTabDiv">
                <Tabs>
                  <TabList>
                    <Tab>All</Tab>
                    <Tab>Unread</Tab>
                    <Tab>Job Notification</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="newNotifiDiv">
                      <p className="allNotiSubHeads">New</p>
                      <div>
                        {notiData?.map((value) => (
                          <NotificationActual
                            // activeY={value.bgColor}
                            // icon={value.icon}
                            id={value?._id}
                            advocacyId={value?.advocacyId}
                            title={value?.title}
                            description={value?.description}
                            image={value?.image}
                            advocacyPostedBy={value?.advocacyPostedBy}
                            userFirstName={value?.userFirstName}
                            userLastName={value?.userLastName}
                            senderName={value?.senderName}
                            isAdminApproved={value?.isAdminApproved}
                            isManagerApproved={value?.isManagerApproved}
                            groupId={value?.groupId}
                            requestId={value?.requestId}
                            onboardingTrainingId={value?.onboardingTrainingId}
                            microTrainingId={value?.microTrainingId}
                            jobRequirmentId={value?.jobRequirmentId}
                            userID={value?.userID}
                            createdOn={value?.createdOn}
                            value={getJobDetails(value?.jobRequirmentId)}
                            trainingvalue={getMicroTrainingDetails(value?.microTrainingId)}
                            managerTrainingValue={getManagerTrainingDetails(value?.onboardingTrainingId)}
                            claimPointId={value?.claimPointId}
                            claimPointValue={getClaimPoint(value?.claimPointId)}
                            friendRequestId={value?.friendRequestId}
                            isFriendReqAccept={value?.isFriendReqAccept}
                            senderImage={value?.senderImage}
                            refferlId={value?.refferlId}
                            refferedValue={getrefferedjob(value?.refferlId)}
                            viewAllNotification={viewAllNotification}
                          />
                        ))}
                      </div>
                    </div>
                    {/* <div>
                  <p className="allNotiSubHeads">Today</p>
                  {todayNotiArr.map((value) => (
                    <NotificationActual
                      activeY={value.bgColor}
                      icon={value.icon}
                    />
                  ))}
                </div> */}
                  </TabPanel>
                  <TabPanel>
                    <div className="newNotifiDiv">
                      <p className="allNotiSubHeads">New</p>
                      <div>
                        {unreadArr?.map((value) => (
                          <NotificationActual
                            // activeY={value.bgColor}
                            // icon={value.icon}
                            id={value?._id}
                            // advocacyId={value?.advocacyId}
                            title={value?.title}
                            description={value?.description}
                            // image={value?.image}
                            // advocacyPostedBy={value?.advocacyPostedBy}
                            // userFirstName={value?.userFirstName}
                            // userLastName={value?.userLastName}
                            isAdminApproved={value?.isAdminApproved}
                            senderId={value?.senderId}
                            receiverId={value?.receiverId}
                            suggestionId={value?.suggestionId}
                            createdOn={value?.createdOn}
                            value={value}
                            isRead={value?.isRead}
                          />
                        ))}
                      </div>
                    </div>
                    {/* <div>
                      <p className="allNotiSubHeads">Today</p>
                      {unreadArr.map((value) => (
                        <NotificationActual
                          activeY={value.bgColor}
                          icon={value.icon}
                        />
                      ))}
                    </div> */}
                  </TabPanel>

                  <TabPanel>
                    <div className="newNotifiDiv">
                      {/* <p className="allNotiSubHeads">New</p> */}
                      <div>
                        {jobNoti?.map((value) => (
                          <JobNotificationActual
                            values={getJobDetails(value?.jobRequirmentId)}
                            addedBy={value?.addedBy}
                            // values={value}
                            _id={value?._id}
                            title={value?.title}
                            type={value?.type}
                            receiverId={value?.receiverId}
                            senderId={value?.senderId}
                            jobRequirmentId={value?.jobRequirmentId}
                            isAdminApproved={value?.isAdminApproved}
                            isCAdminApproved={value?.isCAdminApproved}
                            isRead={value?.isRead}
                            createdOn={value?.createdOn}

                          />
                        ))}
                      </div>
                    </div>
                    {/* <div>
                      <p className="allNotiSubHeads">Today</p>
                      {unreadArr.map((value) => (
                        <NotificationActual
                          activeY={value.bgColor}
                          icon={value.icon}
                        />
                      ))}
                    </div> */}
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="thought_area_mxh_right">
            {/* <div className="iniSusTabDiv">
              <Tabs>
                <TabList>
                  <Tab>Initiative</Tab>
                  <Tab>Sustainable</Tab>
                </TabList>

                <TabPanel>
                  <Initiative />
                </TabPanel>
                <TabPanel>
                  <GoalAchiver />
                </TabPanel>
              </Tabs>
            </div> */}
            <div className="iniSusTabDiv">
              <Tabs>
                {/* <TabList>
                  <Tab>Initiative</Tab> 
                  <Tab>Sustainable</Tab>
                </TabList> */}

                <TabPanel>
                  <Initiative />
                </TabPanel>
                <TabPanel>
                  {/* <GoalAchiver /> */}
                </TabPanel>
              </Tabs>
            </div>
            <TrainingCard />
            <div className="myAffitiDiv">
              <HubAffinityGrp />
            </div>
          </div>
        </div>
      </section>
      <Chatbot />
      {/* *************************************** */}
      {modala && (
        <BillBoardModal
          closemodal={setModala}
          activity={"billBoard"}
          initialValues={initialValues}
        />
      )}

      {modalD && (
        <GoalAchiverModal
          closemodal={setModalD}
          activity={"goalAchiver"}
          initialValues={initialValues1}
        />
      )}

      {modalF && (
        <EventsModal
          closemodal={setModalF}
          activity={"events"}
          initialValues={initialValues}
        />
      )}

      {modalH && (
        <TrainingModal
          closemodal={setModalH}
          activity={"training"}
          initialValues={initialValues2}
        />
      )}

      {modalJ && (
        <JobRefferedModal
          closemodal={setModalJ}
          activity={"jobReffered"}
          initialValues={initialValues3}
        />
      )}
    </>
  );
}

export default NotiHome;
