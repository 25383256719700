import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./custom.css";
import "../src/View/Employee/Onboarding.css";
import AppRoutes from "./routes/AppRoutes";
import { Toaster } from "react-hot-toast";
import "../src/Component/Modal/Modal.css";
function App() {
  // const { loading } = useAuthCtx();
  return (
    <React.Fragment>
      <BrowserRouter>
        {/* <div className='loader-header' style={loading ? { pointerEvents: "none" } : { pointerEvents: "" }}>
          <div className="loader" style={{ filter: "brightness(2)" }}>
            <RingLoader color="#36d7b7" loading={loading} size={150} />
          </div>
          <div style={loading ? { filter: 'brightness(30%)' } : { filter: 'brightness(100%)' }}><AppRoutes /></div>

        </div> */}
        <AppRoutes />
      </BrowserRouter>
      <Toaster />
    </React.Fragment>
  );
}

export default App;
