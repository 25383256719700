import React, { useEffect, useState } from 'react'
// import trainingmain2 from "../../Images/trainingmain2.png";
import { useDispatch, useSelector } from 'react-redux';
import { getRequestTraining } from '../../redux/slices/jobRecruitmentSlice';
import { useNavigate } from 'react-router-dom';
import { ApiHelperFunction } from '../../services/api/apiHelpers';

const RequestedTrainingcard = () => {
    const [mentorData,setMentorData]=useState([]);
    const navigate = useNavigate();
    const requestedtraining = useSelector((state) => state?.jobRecruitmentSlice?.trainingreq)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getRequestTraining())
    }, [])



    const handletrainingClick = (id) => {
        navigate(`/singleReqTrainingmh/${id}`)
    }

    const getMentorData = async () => {
        const response = await ApiHelperFunction({
          urlPath: `/view-company-employees`,
          method: "GET",
        });
        console.log("RESPONSEF", response?.data?.data);
        if (response && response.status) {
          setMentorData(response?.data?.data);
        } else {
          // toast.error(response.error);
        }
      };

    const getMentorName = (mentorId) => {
        const mentor = mentorData?.find(mentor => mentor._id === mentorId);
        return mentor ? mentor?.firstName + " " + mentor?.lastName : '';
      };

      useEffect(()=>{
        getMentorData();
      },[])

    return (
        <>
            {requestedtraining?.map((item, index) => {
                return (
                    <div className='videoongoinddiv' key={index}
                        onClick={() => handletrainingClick(item?._id)}
                        style={{ cursor: "pointer" }}>
                        <div className=''>
                            <div className='video_onflxdiv'>
                                <div className='trainingmainimgdiv'>
                                    <img src={item?.image} alt="..." />
                                </div>
                                <div className=''>
                                    <p className='lrmtxtpp'>{item?.title}</p>
                                    {/* <div className=''>
                                        <p className="dotstxtpp"><span className="dotsdiv"></span>{item.notification}</p>
                                    </div> */}

                                    <div className=''>
                                        <p className='dotstxtpp'>Language : {item?.language}</p>
                                    </div>
                                    {
                                        item?.mentor?.length > 0 ?
                                            (
                                                <div className=''>
                                                    <p className='dotstxtpp'>Mentor : {getMentorName(item?.mentor?.[0])}</p>
                                                </div>
                                            )
                                            :
                                            (
                                                <></>
                                            )
                                    }
                                    {
                                        item?.trainerName ?
                                            (
                                                <div className=''>
                                                    <p className='dotstxtpp'>Trainer Name : {item?.trainerName}</p>
                                                </div>
                                            )
                                            :
                                            (
                                                <></>
                                            )
                                    }

                                    <div className=''>
                                        <p className='dotstxtpp'>status : {item?.isApproved === "approved" ? <>Accepted</> : item?.isApproved === "pending" ? <>Pending</> : <>Rejected</>}</p>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                )
            })}

        </>
    )
}

export default RequestedTrainingcard
