import React, { useState } from 'react'
import Earningaddition from './Earningaddition'
import Deduction from './Deduction'
import Leavetempcomp from './Leavetempcomp'

const SalaryTemplatecompone = () => {
    const [diffonnertab, setDiffonnertab] = useState(1)
    const diffinnertabclick = (tabnumber) => {
        setDiffonnertab(tabnumber)
    }
    return (
        <>
            <section className='salarytemplacomdibvmain'>
                <div className=''>
                    <p className='crtnetmpletxtppp'>
                        Salary Components
                    </p>
                </div>

                <div className='salartcomponembtnmaindiv'>
                    <button className={diffonnertab === 1 ? 'salartcomponembtn actv' : 'salartcomponembtn'} onClick={() => diffinnertabclick(1)}>
                        Earning/addition
                    </button>
                    <button className={diffonnertab === 2 ? 'salartcomponembtn actv' : 'salartcomponembtn'} onClick={() => diffinnertabclick(2)}>
                        Deduction
                    </button>
                    <button className={diffonnertab === 3 ? 'salartcomponembtn actv' : 'salartcomponembtn'} onClick={() => diffinnertabclick(3)}>
                        Leave
                    </button>
                </div>

                <div className='slarycomponentdivbg'>
                    {diffonnertab === 1 && (
                        <>
                            <Earningaddition />
                        </>
                    )}

                    {diffonnertab === 2 && (
                        <>
                            <Deduction />
                        </>
                    )}

                    {diffonnertab === 3 && (
                        <>
                            <Leavetempcomp />
                        </>
                    )}
                </div>
            </section>
        </>
    )
}

export default SalaryTemplatecompone
