import React, { useEffect, useState } from "react";
// import myMentPic1 from "../../Images/No-Image-Placeholder.png";
// import myMentPic2 from "../../Images/myMentPic2.png";
// import myMentPic3 from "../../Images/myMentPic3.png";
// import Mentor_new from "../../Images/Icons/PNG/Mentor_new.png";
import { Link, useNavigate } from "react-router-dom";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import { useAuthCtx } from "../../context/AuthCtx";
import MyMentorModal from "../../Component/Modal/MyMentorModal";
import add_icon from "../../Images/Icons/PNG/Add icon.png"
import toast from "react-hot-toast";
import Swal from "sweetalert2";


const initialValues = {
  userID: [""],
  description: ""
}

function MyMentor() {
  const { userData } = useAuthCtx();
  const [mentor, setMentor] = useState([]);
  const [modalMentor, seModalMentor] = useState(false);
  const navigate = useNavigate();


  // fetch mentor
  const fetchMentor = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/view-mentor",
      method: "GET",
    });
    if (response && response.status === 200) {
      setMentor(response?.data?.data.reverse());
    } else {
      // toast.error(response?.data?.message);
    }
  };

  const AnotherProfile = async (userID) => {

    if (userID === userData?._id) {
      // console.log(userID,userData,"uikodc")
      navigate("/profile");
    } else {
      let response = await ApiHelperFunction({ urlPath: `/get-others-profile/${userID}`, method: "GET" })
      if (response && response?.status) {
        console.log("RESPONSE", response?.data?.data);
        let data = response?.data?.data;
        response && navigate(`/Profile_rating/${userID}`, {
          state: {
            data
          }
        })
      } else {
        // toast.error('Error to fetching another profile data')
      }
    }
  }

  const handleDelete = async (id) => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-mentor/${id}`,
      method: "PUT",
    })
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {

      if (result.isConfirmed) {
        toast?.success("Deleted Successfully");
        fetchMentor()

      }
    });
  }

  useEffect(() => {
    fetchMentor();
  }, []);

  return (
    <>
      <div className="myMentorDiv">
        <div className="myMentBtnDiv">
          <div className="bulleBoardHead">
            {/* <figure className="bulleBoardFig">
              <img src={Mentor_new} alt="..." />
            </figure> */}
            {
              userData?.userType === "Admin" || userData?.userType === "Owner" ? (<h4 className='opentxtdiv'>Mentor</h4>) : (<h4 className='opentxtdiv'>My Mentor</h4>)
            }

          </div>
          {
            userData?.userType === "Admin" || userData?.userType === "Owner" ? (<button className="mentAddBtn" onClick={() => seModalMentor(true)}><figure className="bulleBoardFig">
              <img src={add_icon} alt="..." />
            </figure></button>) : (<></>)
          }

        </div>
        <ul className="myMentorUl">
          {mentor?.map((item) => {
            return (
              <li className="myMentorLi">
                <Link className="myMentorLiAn" >
                  <figure className="myMentFig" onClick={() => AnotherProfile(item?.userID)}>
                    <img src={item?.userImage} alt="..."  />
                  </figure>

                  {/* <figure className="mrkHubFig">
                    {userData?.image === "image" ? (
                      <img src={myMentPic1} alt="..." />
                    ) : (
                      <img src={userData?.image} alt="..." />
                    )}
                  </figure> */}
                  <div>
                    <div className="d-flex">
                    <div className="mentorName" onClick={() => AnotherProfile(item?.userID)}>
                      {item?.userfirstName + " " + item?.userlastNAme}
                    </div>
                    <div className="deleticondivbgmentor">
                      <i class="fa-solid fa-trash" style={{ cursor: "pointer",padding:"0px 0px 15px 30px" }} onClick={() => handleDelete(item?._id)}></i>
                    </div>
                    </div>
                    <p className="mentorDesignation">{item?.designation}</p>
                  </div>
                </Link>
              </li>
            );
          })}
          {/* <li className="myMentorLi">
          <Link className="myMentorLiAn">
            <figure className="myMentFig">
              <img src={myMentPic2} alt="..." />
            </figure>
            <div>
              <p className="mentorName">Lorem Ipsum</p>
              <p className="mentorDesignation">Ux /Ui design</p>
            </div>
          </Link>
        </li>
        <li className="myMentorLi">
          <Link className="myMentorLiAn">
            <figure className="myMentFig">
              <img src={myMentPic3} alt="..." />
            </figure>
            <div>
              <p className="mentorName">Lorem Ipsum</p>
              <p className="mentorDesignation">Ux /Ui design</p>
            </div>
          </Link>
        </li> */}
        </ul>
      </div>
      {
        modalMentor && (
          <MyMentorModal
            closemodal={seModalMentor}
            activity={"mymentor"}
            initialValues={initialValues}
          />
        )
      }
    </>

  );
}

export default MyMentor;
