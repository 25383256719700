import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import moment from 'moment';


const ProjectWorktable = ({ selectedDate, assignedEmp, id }) => {
    const [timeaddcomplete, serTimeaddcomplete] = useState();
    const [attendanceShow, setAttendanceShow] = useState([]);


    //Get all attendance project
    const getAttendanceShowData = async () => {
        let data = {
            date: moment(selectedDate)?.format("YYYY-MM-DD")
        }
        const response = await ApiHelperFunction({
            urlPath: `/fetch-project-wise-weekly-data/${id}`,
            method: "POST",
            data
        });
        if (response && response.status) {
            setAttendanceShow(response?.data?.data);
        }
    };

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    //for getting whole week's date
    const getWeekDates = (date) => {
        const startOfWeek = moment(date)?.startOf('isoWeek'); // Start of the week is Monday
        return Array.from({ length: 7 }, (_, i) => startOfWeek?.clone()?.add(i, 'days'));
    };
    const weekDates = moment(selectedDate).isValid() ? getWeekDates(selectedDate) : [];


    useEffect(() => {
        // getProjectData();
        getAttendanceShowData();
    }, [selectedDate])

    // Calculate total work hours for each day across all projects
    const totalHoursPerDay = weekDates.map(date => {
        return assignedEmp?.reduce((total, item) => {
            const attendanceForDate = attendanceShow?.find(attendance =>
                attendance?.nameOfInitaitive === item?.projectName
                &&
                attendance?.attendenceData?.some(att =>
                    att._id.userId === item.userId &&
                    moment(att.details[0]?.createdOn).isSame(date, 'day')
                )
            );

            const totalWorkHour = attendanceForDate
                ? attendanceForDate?.attendenceData?.find(att =>
                    att._id.userId === item.userId &&
                    moment(att.details[0]?.createdOn).isSame(date, 'day')
                )?.details[0]?.totalWorkHour
                : 0;

            return total + (totalWorkHour ? parseFloat(totalWorkHour) : 0);
        }, 0);
    });

    const totalWeeklyHours = totalHoursPerDay.reduce((total, hours) => total + hours, 0);

    // console.log("attendanceShow", attendanceShow)
    return (
        <>
            <div className=''>

                <div className='attrendencedivdatedivaladapading'>
                    <div className='attrendencedivdatediv'>
                        <Slider {...settings}>
                            {/* <div>
                                <h3 className='txtppdatenmkk'>Saturday,21 nov</h3>
                            </div>
                            <div>
                                <h3 className='txtppdatenmkk'>Saturday,21 nov</h3>
                            </div> */}

                            {weekDates?.map((date, index) => (
                                <div key={index}>
                                    <h3 className='txtppdatenmkk'>{date?.format("dddd, DD MMM")}</h3>
                                </div>
                            ))}

                        </Slider>
                    </div>
                </div>



                <div className='tabledivattendivtablediv'>
                    <table className='tabledivattendivmain'>
                        <tr>
                            <th>Employees</th>

                            {weekDates?.map((date, index) => (
                                <th className='attendencedaydiv' key={index}>
                                    <p className="elutitxtppsmop">{date?.format("dddd")}</p>
                                    <p className="elutitxtppsmop">{date?.format("DD/MM")}</p>
                                </th>
                            ))}

                            {/* <th className='attendencedaydiv'>
                                <p class="elutitxtppsmop">Monady</p>
                                <p class="elutitxtppsmop">10/06</p>
                            </th>
                            <th className='attendencedaydiv'>
                                <p class="elutitxtppsmop">Tuesday
                                </p>
                                <p class="elutitxtppsmop">11/06</p>

                            </th>
                            <th className='attendencedaydiv'>
                                <p class="elutitxtppsmop">Wednesday</p>
                                <p class="elutitxtppsmop">11/06</p>
                            </th>
                            <th className='attendencedaydiv'><p class="elutitxtppsmop">Thursday</p>
                                <p class="elutitxtppsmop">12/06</p></th>
                            <th className='attendencedaydiv'>
                                <p class="elutitxtppsmop">Friday
                                </p>
                                <p class="elutitxtppsmop">13/06</p></th>
                            <th className='attendencedaydiv'>
                                <p class="elutitxtppsmop">Saturday
                                </p>
                                <p class="elutitxtppsmop">14/06</p>
                            </th>
                            <th className='attendencedaydiv'>
                                <p class="elutitxtppsmop">Sunday
                                </p>
                                <p class="elutitxtppsmop">15/06</p></th> */}
                            <th className='attendencedaydivnewalada'>Total</th>
                        </tr>

                        {
                            assignedEmp?.map((item) => {
                                let projectTotalWork = 0;
                                return (
                                    <>
                                        <tr>
                                            <td className='inputattendencetdm'>
                                                <p className='elutitxtppsmop'>{item?.userName}</p>
                                            </td>

                                            {weekDates?.map((date, dateIndex) => {
                                                const attendanceForDate = attendanceShow?.find(attendance =>
                                                    attendance?.nameOfInitaitive === item?.projectName
                                                    &&
                                                    attendance?.attendenceData?.some(att =>
                                                        att._id.userId === item.userId &&
                                                        moment(att.details[0]?.createdOn).isSame(date, 'day')
                                                    )
                                                );

                                                const totalWorkHour = attendanceForDate
                                                    ? attendanceForDate?.attendenceData?.find(att =>
                                                        att._id.userId === item.userId &&
                                                        moment(att.details[0]?.createdOn).isSame(date, 'day')
                                                    )?.details[0]?.totalWorkHour
                                                    : null;

                                                if (totalWorkHour) {
                                                    projectTotalWork += parseFloat(totalWorkHour);
                                                }


                                                return (
                                                    <td className='inputattendencetdm' key={dateIndex}>
                                                        <div className={timeaddcomplete ? "inputattendencediv actv" : 'inputattendencediv'}
                                                        >
                                                            <p className="timeworktxtpp">{totalWorkHour !== null ? totalWorkHour : '-'}</p>
                                                        </div>
                                                    </td>
                                                );
                                            })}


                                            <td className='inputattendencetdmnewalada'>
                                                <div className="totalrightyaxisattendencediv">
                                                    {/* <p className="timeworktxtpp">35.0</p> */}
                                                    <p className="timeworktxtpp">{Math.round(projectTotalWork)}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                        }



                        <tr style={{ borderBottom: "1px solid #000" }}></tr>

                        <tr>
                            <td className='inputattendencetdm'>
                                <p className='elutitxtppsmoptptal'>Total</p>
                            </td>
                            {totalHoursPerDay?.map((totalHours, index) => (
                                <td className='inputattendencetdm' key={index}>
                                    <div className={timeaddcomplete ? "totalattendencediv actv" : 'totalattendencediv'}>
                                        <p className='timeworktxtpp'>{Math.round(totalHours)}</p>
                                    </div>
                                </td>
                            ))}
                            <td className='inputattendencetdmnewalada'>
                                <div className='totalrightyaxisattendencediv'>
                                    <p className='timeworktxtpp'>{Math.round(totalWeeklyHours)}</p>
                                </div>
                            </td>


                            {/* <td className='inputattendencetdm' >
                                <div className={timeaddcomplete ? "totalattendencediv actv" : 'totalattendencediv'}>
                                    <p className='timeworktxtpp'>5.0</p>
                                </div>
                            </td>
                            <td className='inputattendencetdm'>
                                <div className={timeaddcomplete ? "totalattendencediv actv" : 'totalattendencediv'}>
                                    <p className='timeworktxtpp'>5.0</p>
                                </div>
                            </td>
                            <td className='inputattendencetdm'>
                                <div className={timeaddcomplete ? "totalattendencediv actv" : 'totalattendencediv'}>
                                    <p className='timeworktxtpp'>5.0</p>
                                </div>
                            </td>
                            <td className='inputattendencetdm'>
                                <div className={timeaddcomplete ? "totalattendencediv actv" : 'totalattendencediv'}>
                                    <p className='timeworktxtpp'>5.0</p>
                                </div>
                            </td>
                            <td className='inputattendencetdm'>
                                <div className={timeaddcomplete ? "totalattendencediv actv" : 'totalattendencediv'}>
                                    <p className='timeworktxtpp'>5.0</p>
                                </div>
                            </td>
                            <td className='inputattendencetdm'>
                                <div className={timeaddcomplete ? "totalattendencediv actv" : 'totalattendencediv'}>
                                    <p className='timeworktxtpp'>5.0</p>
                                </div>
                            </td>
                            <td className='inputattendencetdm'>
                                <div className={timeaddcomplete ? "totalattendencediv actv" : 'totalattendencediv'}>
                                    <p className='timeworktxtpp'>5.0</p>
                                </div>
                            </td>
                            <td className='inputattendencetdmnewalada'>
                                <div className='totalrightyaxisattendencediv'>
                                    <p className='timeworktxtpp'>35.0</p>
                                </div>
                            </td> */}
                        </tr>

                    </table>
                </div>
            </div>
        </>
    )
}

export default ProjectWorktable
