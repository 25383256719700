import React from 'react'
import companyname from "../../Images/companyname.jpeg"
const Salarydetails = () => {
    return (
        <>
            <section className='saralydetailsmainsection'>
                <div className='organizatindtls'>
                    <p className='organizationtzt'>Organization Details</p>
                    <p className='oraganitionidtxtpp'>
                        Organization ID : 80030378547
                    </p>
                </div>

                <div className='organizationdetailsmainportin'>
                    <div className='organizaiongrppadng'>
                        <p className='organizationdtlsheading'>Organization Logo
                            {/* <sup className='strtimpotatnt'>
                                <i class="fa-solid fa-star"></i>
                            </sup> */}
                        </p>
                        <div className='organizationlogoimgbrdr'>
                            <div className='organizationlogoimg'>
                                <img src={companyname} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className='organizaiongrppadng'>
                        <p className='organizationdtlsheading'>Organization Name
                            {/* <sup className='strtimpotatnt'>
                                <i class="fa-solid fa-star"></i>
                            </sup> */}
                        </p>
                        <div className='organinamebrdr'>
                            <p className='organizationdtlstxtpp'>Save World Green World</p>
                        </div>
                    </div>

                    <div className='organizaiongrppadng'>
                        <p className='organizationdtlsheading'>Types of Industry
                            {/* <sup className='strtimpotatnt'>
                                <i class="fa-solid fa-star"></i>
                            </sup> */}
                        </p>
                        <div className='organinamebrdr'>
                            <p className='organizationdtlstxtpp'>Agriculture</p>
                        </div>
                    </div>

                    <div className='organizaiongrppadng'>
                        <p className='organizationdtlsheading'>Organization Address
                      
                        </p>
                       
                                <p className='adrestext_dtls'> Kolkata, West Bengal 700091</p>
                                <p className='adrestext_dtls'> India </p>
                           
                        </div>
                    

              


                </div>
            </section>
        </>
    )
}

export default Salarydetails
