import moment from "moment";
import React, { useEffect, useState, Link } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAuthCtx } from "../context/AuthCtx";
import { getBillBoardData } from "../redux/slices/billBoardSlice";
import { ApiHelperFunction } from "../services/api/apiHelpers";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const BulletinView = () => {
  const params = useParams();
  const location = useLocation();
  const singleData = location?.state?.item;
  // console.log("itemqwe", singleData);
  const { userData } = useAuthCtx();
  const [indx, setindx] = useState("");
  const [modala, setModala] = useState(false);
  const [modalA, setModalA] = useState(false);
  const [modalB, setModalB] = useState(false);
  const [modalId, setModalId] = useState("");
  const [BulletinBoardSingleData, setBulletinBoardSingleData] = useState({});
  const [viewDetails, setViewDetails] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null); // New state
  const [top, setTop] = useState(null);
  const [left, setLeft] = useState(null);
  const billBoardData = useSelector(
    (state) => state?.billBoardData?.billBoardData
  );
  const [empData, setempData] = useState([]);
  const dispatch = useDispatch();
  const [singleView, setSingleView] = useState({});

  //for view Single Page
  const viewSinglepost = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-single-bulletin-board/${params?.id}`,
      method: "GET",
    });
    if (response.status === 200) {
      console.log("respovbsd", response?.data)
      setSingleView(response?.data?.data);
      // console.log("data get successfully");
    } else {
      // toast.error(response.message);
    }
  }

  // console.log("singleView", singleView)

  useEffect(() => {
    getEmployeeData();
    viewSinglepost();
  }, [])

  const handleClick = (e, ele, index) => {
    setModalId(ele._id);
    // console.log("EVENT", e, index);
    setBulletinBoardSingleData(ele);
    // console.log("s", e, ele);
    setTop(e.clientY);
    setLeft(e.clientX);
    setModalA(!modalA);
  };


  //get all invitees
  const getEmployeeData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-all-employees`,
      method: "GET",
    });

    console.log("RESPONSEF", response?.data?.data);

    if (response && response.status) {
      const formattedOptions = response?.data?.data?.map((item) => ({
        value: item?._id,
        label: item?.userName,
      }));
      const employeeLabel = formattedOptions?.find(
        (e) =>
          e.value == singleData?.addEmploee
      )?.label || ""
      setempData(employeeLabel);
      // console.log(employeeLabel, "eeeee")
      // setempData(response?.data?.data);
    } else {
      // toast.error(response.message);
    }
  };

  // console.log("empData", empData)

  // const openDetails = (item, id, index) => {
  //   console.log("index", index);
  //   setBulletinBoardSingleData(item);
  //   setindx(index);
  //   setViewDetails(!viewDetails);
  //   //  alert("working")
  //   setSelectedDescription(item.addNotes || "");
  // };

  // const handleIdClick = (id) => {
  //   // Fetch the description based on the ID
  //   const selectedItem = billBoardData.find((item) => item._id === id);
  //   if (selectedItem) {
  //     setModalId(id);
  //     setSelectedDescription(selectedItem.addNotes || "");
  //     setSelectedItemId(id); // Set the selected item ID
  //     setModalA(true);
  //   }
  // };

  useEffect(() => {
    dispatch(getBillBoardData());
  }, []);

  // const stopModal = (e) => {
  //   modalA && setModalA(false);
  //   // setModalId("");
  //   e.stopPropagation();
  // };

  const handleDelete = async () => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-bulletin-board/${modalId}`,
      method: "PUT",
    });
    if (res?.status) {
      toast.success("Bulletin deleted successfully");

      dispatch(getBillBoardData());
      setModalA(false);
    } else {
      toast.error(res?.message || "Something went wrong");
      console.log("ERROR CREATING USER3", res);
    }
  };

  return (
    <>
      <section className="bulletindetailsview">
        <div className="employeepagecontainer">
          <div className="">
            <div className="singleimagemaindivmain">
              <div className="singleimagemaindiv">
                <img src={singleView?.[0]?.image} alt="..." />
              </div>
            </div>
            <div className="bulletindetilasbg">
              <div className=" bulllwetindetlsrty">
                <p className="singledatappp">
                  {" "}
                  Event Name: {singleView?.[0]?.eventName}{" "}
                </p>
              </div>

              <div className=" bulllwetindetlsrty">
                <p className="singledatappp"> Note {singleView?.[0]?.addNotes} </p>
              </div>

              <div className=" bulllwetindetlsrty">
                <p className="singledatappp">
                  {" "}
                  Date: {moment(singleView?.[0]?.eventDate)?.format(
                    "YYYY-MM-DD"
                  )}{" "}
                </p>
              </div>

              {/* <div className=" bulllwetindetlsrty">
                <p className="singledatappp">Invite : {empData} </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BulletinView;
