import React, { useState, useEffect } from 'react'
import { useAuthCtx } from '../context/AuthCtx';
// import { useFormik } from 'formik';
// import * as Yup from "yup";
import { toast } from 'react-hot-toast';
import { ApiHelperFunction, fileUpload } from '../services/api/apiHelpers';
import { useDispatch } from 'react-redux';
// import { getEventData } from '../redux/slices/eventSlice';
import Select from "react-select";
// import { getEmployeeData } from '../redux/slices/employeeSlice';
import Ask_HR from "../../src/Images/Icons/PNG/Ask_HR.png"
import moment from 'moment';


const EmployeeModal = ({ closemodal, searchEmployee }) => {
  const { userData } = useAuthCtx();

  const INITIAL = {
    managedBy: [],
    firstName: "",
    lastName: "",
    companyCode: userData?.companyCode, userType: "",
    email: "",
    password: "",
    contact: "",
    image: "",
    designation: "",
    employeeid: "",
    address: "",
    gender: "",
    dob: "",
    education: "",
    userDesignation: ""
  }

  const [imageURL, setImageURL] = useState("");
  const dispatch = useDispatch();
  // const [localerror, setlocalerror] = useState("");
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [AllManagerData, setAllManagerData] = useState([]);
  const [managerSeperateData, setManagerSepreatedData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [department, setDepartment] = useState([]);
  const [EmployeeData, setEmployeeData] = useState(INITIAL);
  const [hideManager, setHIdeManager] = useState(true);

  const [designation, setDesignation] = useState([]);
  const [education, setEducation] = useState([]);


  useEffect(() => {
    ViewAllDepartment();
    searchEmployee();
    ViewAllDesignation();
    ViewAllEducation();
    // dispatch(getEmployeeData());
  }, [])

  useEffect(() => {
    ViewAllManager();
  }, [EmployeeData.designation])

  useEffect(() => {
    AllManagerDataFormat();
  }, [AllManagerData])


  const AllManagerDataFormat = () => {
    let arr = [];
    AllManagerData && AllManagerData?.map((item) => {
      arr.push({ label: `${item?.firstName} ${item?.lastName}`, value: item?._id },)
    });
    setManagerSepreatedData(arr);
  }

  // const ViewAllManager = async () => {
  //   // let arr = [];
  //   const res = await ApiHelperFunction({
  //     urlPath: "/view-all-manager",
  //     method: "GET",

  //   });

  //   if (res && res?.status) {
  //     // arr.push({ label: "L", value: "L" },)
  //     setAllManagerData(res?.data?.data)

  //   } else {
  //     // toast.error(res?.message || "Something went wrong");
  //   }
  // }


  //for view all designation
  const ViewAllDesignation = async () => {
    const res = await ApiHelperFunction({
      urlPath: '/view-designation',
      method: 'GET',
    });

    if (res && res?.status) {
      const formattedDepartments = res?.data?.data?.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setDesignation(formattedDepartments);
    } 
  };

  
  //for view all education
  const ViewAllEducation = async () => {
    const res = await ApiHelperFunction({
      urlPath: '/view-education',
      method: 'GET',
    });

    if (res && res?.status) {
      const formattedDepartments = res?.data?.data?.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setEducation(formattedDepartments);
    } 
  };


  //for view all department
  const ViewAllDepartment = async () => {
    // let arr = [];
    const res = await ApiHelperFunction({
      urlPath: '/view-department',
      method: 'GET',
    });

    if (res && res?.status) {
      const formattedDepartments = res?.data?.data?.map((item) => ({
        label: item.departmentName,
        value: item._id,
      }));
      setDepartment(formattedDepartments);
    } else {
      // toast.error(res?.message || 'Something went wrong');
    }
  };

  //for viewing departmentwise manager
  const ViewAllManager = async () => {
    const data = {
      department: EmployeeData.designation
    }
    const res = await ApiHelperFunction({
      urlPath: "/view-manager-department-wise",
      method: "POST",
      data
    });
    // console.log("resGetCatman", res)
    let apiData = []
    if (res && res?.status) {
      apiData = res?.data?.data?.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item._id,

      }));
    } else {
      // setIsLoading(false);
    }
    setAllManagerData(apiData);
  }

  const handleChange = (e) => {
    setEmployeeData({
      ...EmployeeData, [e.target.name]: e.target.value,
    })
  }

  const HandleUserType = (e) => {
    if (e.target.value === "Manager") {
      setHIdeManager(false);
    } else {
      setHIdeManager(true);
    }
    setEmployeeData({
      ...EmployeeData,
      userType: e.target.value,
    });
  }

  const HandleEducation = (e) => {
    setEmployeeData({
      ...EmployeeData,
      education: e.target.value
    });
  }

  const HandleGender = (e) => {
    setEmployeeData({
      ...EmployeeData,
      gender: e.target.value
    });
  }

  const HandleManager = (e) => {

    setSelectedOptions(e);
    let arr = [];
    e?.map((item) => {
      return arr.push(item?.value);
    });
    setEmployeeData({
      ...EmployeeData,
      managedBy: arr,
    });
  }



  // const submitHandler = async (e) => {
  //     e.preventDefault();
  //     // setShouldValidateOnChange(true);
  //     // setShouldValidateOnBlur(true);

  //     // let data = {};
  //     setLoading(true);
  //     if (
  //         values.eventName == "" ||
  //         values.hostedBy == "" ||
  //         values.eventDate == ""
  //       ) {
  //         return toast.error("All fields required");

  //       }

  //       data = {
  //         eventName: values?.eventName,
  //         hostedBy: values?.hostedBy,
  //         eventDate: values?.eventDate,
  //         image: imageURL,
  //       };

  // const res = await ApiHelperFunction({
  //   urlPath: "/add-event",
  //   method: "POST",
  //   data,
  // });

  // if (res?.status) {
  //   toast.success("Event added successfully");
  //   resetForm();
  //   dispatch(getEventData());
  //   closemodal();
  // } else {
  //   toast.error(res?.message || "Something went wrong");
  //   console.log("ERROR CREATING USER3", res);
  // }

  //     setLoading(false);
  //   };


  const submitHandler = async () => {

    if (EmployeeData?.firstName && EmployeeData?.lastName && EmployeeData?.email &&
      EmployeeData?.password && EmployeeData?.contact && EmployeeData?.userType && EmployeeData?.designation
      && EmployeeData?.image && EmployeeData?.employeeid && EmployeeData?.address && EmployeeData?.gender && EmployeeData?.dob && EmployeeData?.education && EmployeeData?.userDesignation) {
      // dispatch(getTrainingData());
      let data = EmployeeData;
      const res = await ApiHelperFunction({
        urlPath: "/user-type-admin-add-epm",
        method: "POST",
        data,
      });

      if (res && res?.status) {
        toast.success("Employee added successfully");
        setEmployeeData(INITIAL);
        closemodal();
        // dispatch(getEmployeeData());
        searchEmployee();

      } else {
        toast.error(res?.message || "Something went wrong");
        console.log("ERROR CREATING USER3", res);
      }
    } else {
      toast.error("All fields are required");
    }
    // console.log("EmployeeDATA", EmployeeData);
  }

  const handleImageChange = async (e) => {
    let image = e.target.files[0];
    setUploading(true);

    const form = new FormData();
    form.append("image", image);

    let res = await fileUpload("/image-upload", "POST", form);

    if (res.status) {
      toast.success("Image uploaded successfully");
      setImageURL(res?.image);
      setEmployeeData({
        ...EmployeeData,
        image: res?.image,
      });
    } else {
      toast.error("Error uploading image");
    }
    setUploading(false);
  };

  return (
    <div className="createmodal_main">
      <div className="createmodalnewdiv">
        <div className="createmodalnewdivcontent">
          <div
            className=""
          // style={{ width: "100%", height: "100%", padding: "20px 0" }}
          >

            <div className="bulletintextclose">
              <div className="bulletinimageflxdivmodal">
                <div className="bulleBoardFig">
                  <img src={Ask_HR} alt="..." />
                </div>
                <span className="newBulleCreHead">Add Employee</span>
              </div>

              <div onClick={() => closemodal()}>
                <i
                  class="fa-solid fa-xmark"
                  style={{
                    color: "red",
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </div>

            <div className='formhgfthdivmain'>
              <form className="">
                <div className="homePgModInnerInpDivsmain">
                  <p>First Name</p>
                  <input
                    type="text"
                    id="firstName"
                    aria-describedby="emailHelp"
                    placeholder="First Name"
                    value={EmployeeData.firstName}
                    name="firstName"
                    onChange={handleChange}
                  // className="homePgMoInps"
                  />
                </div>
                <div className="homePgModInnerInpDivsmain">
                  <p>Last Name</p>
                  <input
                    type="text"
                    id="lastName"
                    aria-describedby="emailHelp"
                    placeholder="Last Name"
                    value={EmployeeData.lastName}
                    name="lastName"
                    // onBlur={handleBlur}
                    onChange={handleChange}
                    className="homePgMoInps"
                  />

                </div>

                <div className="homePgModInnerInpDivsmain">
                  <p>Employee Id</p>
                  <input
                    type="text"
                    id="employeeid"
                    aria-describedby="emailHelp"
                    placeholder="Employee Id"
                    value={EmployeeData.employeeid}
                    name="employeeid"
                    // onBlur={handleBlur}
                    onChange={handleChange}
                    className="homePgMoInps"
                  />
                </div>


                <div className="homePgModInnerInpDivsmain">
                  <p>Gender</p>
                  <select
                    className="form-select slct_frm"
                    aria-label="Default select example"
                    name="gender"
                    value={EmployeeData?.gender}
                    onChange={HandleGender}
                    style={{
                      width: "-webkit-fill-available",
                      height: "36px",
                      borderRadius: "4px"
                    }}
                  >
                    <option value={""}>Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>

                <div className="homePgModInnerInpDivsmain">
                  <p>Date of Birth</p>
                  <input
                    type="date"
                    id="dob"
                    aria-describedby="emailHelp"
                    placeholder="Date of birth"
                    value={moment(EmployeeData?.dob)?.format("YYYY-MM-DD")}
                    name="dob"
                    onChange={handleChange}
                    className="homePgMoInps"
                  />
                </div>

                {/* <div className="homePgModInnerInpDivsmain">
                  <p>Education</p>
                  <select
                    className="form-select slct_frm"
                    aria-label="Default select example"
                    name="education"
                    value={EmployeeData?.education}
                    onChange={HandleEducation}
                    style={{
                      width: "-webkit-fill-available",
                      height: "36px",
                      borderRadius: "4px"
                    }}
                  >
                    <option value={""}>Education</option>
                    <option value="HS">Higher Secondary</option>
                    <option value="BACHELORS">Bachelors</option>
                    <option value="MASTERS">Masters</option>
                  </select>
                  
                </div> */}

                <div className="homePgModInnerInpDivsmain">
                  <p>Education</p>

                  <Select
                    className="select"
                    options={education}
                    value={education?.find((d) => d.value === EmployeeData.education)}
                    onChange={(selectedOption) => {
                      setEmployeeData({
                        ...EmployeeData,
                        education: selectedOption?.value || '',
                      });
                    }}
                  />
                </div>

                <div className="homePgModInnerInpDivsmain">
                  <p>User Type</p>
                  <select
                    className="form-select slct_frm"
                    aria-label="Default select example"
                    name="userType"
                    value={EmployeeData?.userType}
                    onChange={HandleUserType}
                    style={{
                      width: "-webkit-fill-available",
                      height: "36px",
                      borderRadius: "4px"
                    }}
                  >
                    <option value={""}>User Type...</option>
                    <option value="Employee">Employee</option>
                    <option value="Manager">Manager</option>
                  </select>
                </div>

                <div className="homePgModInnerInpDivsmain">
                  <p>Designation</p>

                  <Select
                    className="select"
                    options={designation}
                    value={designation?.find((d) => d.value === EmployeeData.userDesignation)}
                    onChange={(selectedOption) => {
                      setEmployeeData({
                        ...EmployeeData,
                        userDesignation: selectedOption?.value || '',
                      });
                    }}
                  />
                </div>

                <div className="homePgModInnerInpDivsmain">
                  <p>Department</p>

                  <Select
                    className="select"
                    options={department}
                    value={department.find((d) => d.value === EmployeeData.designation)}
                    onChange={(selectedOption) => {
                      setEmployeeData({
                        ...EmployeeData,
                        designation: selectedOption?.value || '',
                      });
                    }}
                  />
                </div>

                {
                  hideManager ? <>

                    <div className="homePgModInnerInpDivsmain">
                      <p>Select Manager</p>
                      <Select
                        className="select"
                        options={AllManagerData}
                        // options={managerSeperateData}
                        value={selectedOptions}
                        name="size"
                        isMulti
                        onChange={HandleManager}
                      />
                    </div></> :
                    null
                }




                <div className="homePgModInnerInpDivsmain">
                  <p>Email</p>
                  <input
                    type="text"
                    id="email"
                    placeholder=""
                    value={EmployeeData.email}
                    name="email"
                    onChange={handleChange}
                    className=""
                  />
                </div>
                <div className="homePgModInnerInpDivsmain">
                  <p>Password</p>
                  <input
                    type="password"
                    id="password"
                    placeholder=""
                    min={5}
                    value={EmployeeData.password}
                    name="password"
                    onChange={handleChange}
                    className="homePgMoInps"
                  />
                </div>
                <div className="homePgModInnerInpDivsmain">
                  <p>Phone No.</p>
                  <input
                    type="text"
                    id="contact"
                    placeholder="Phone No"
                    maxlength="10"
                    value={EmployeeData.contact}
                    name="contact"
                    // onBlur={handleBlur}
                    onChange={handleChange}
                    className="homePgMoInps"
                  />
                </div>

                <div className="homePgModInnerInpDivsmain">
                  <p>Address</p>
                  <input
                    type="text"
                    id="address"
                    placeholder="Address"
                    value={EmployeeData.address}
                    name="address"
                    // onBlur={handleBlur}
                    onChange={handleChange}
                    className="homePgMoInps"
                  />
                </div>

                <div className="homePgModInnerInpDivsupldflx">
                  <p>Upload Image</p>
                  <div className="bguploadbulletinmdbrdr">
                    <div className="bgcrdbulletin">
                      <div className="bgcontainerupldfilediv">
                        <div className="logoupldbulletin">
                          <img src="/static/media/Add icon.ed5715eb88ab4afb9efc.png" alt="..." />
                          <div className="">
                            <input
                              type="file"
                              id="hostedBy"
                              placeholder="Choose a photo"
                              value={image}
                              accept="image/*"
                              name="hostedBy"
                              onChange={handleImageChange}
                              className="upldimagediv"
                            />
                            {/* <small id="emailHelp" style={{ color: "red" }}>
                            {errors.image}
                          </small> */}
                          </div>
                        </div>
                        <div className="bgcontainerupldfiledivabslt">
                          {/* {imageURL !== "" && (
                          <div className="upldimgdivbulletin" >
                            <img
                              src={imageURL}
                              alt=""
                         
                            />
                            <div className="clsoedivmainrd"
                              onClick={() => {
                                setImageURL("");
                                setImage("");
                              }}
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </div>
                          </div>
                        )} */}
                          {imageURL !== "" && (
                            <div className="upldimgdivbulletin" >
                              <img
                                src={imageURL}
                                alt="" />
                              <div className="clsoedivmainrd"
                                onClick={() => {
                                  setImageURL("");
                                  setImage("");
                                }}>
                                <i className="fa-solid fa-xmark"></i>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {uploading ? <p>image uploading......</p> : null}
                </div>
                <div className="bulletinsbmtbtndivv">
                  <button
                    type="button"
                    className="bulletinsbmtbtn"
                    onClick={submitHandler}
                  >
                    Submit
                  </button>
                </div>

              </form>
            </div>
            {/* </Formik> */}
          </div>
        </div>
      </div>
    </div >
  )
}

export default EmployeeModal