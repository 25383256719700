import React, { useEffect, useState } from "react";
import "./Advocacy.css";

import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import "swiper/swiper-bundle.css";
import { useAuthCtx } from "../../context/AuthCtx";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import { toast } from "react-hot-toast";
import profileImg from "../../Images/Icons/PNG/Profile (2).png";
import { RWebShare } from "react-web-share";
import GrowWel from "../Hub/GrowWel";
import bgprfldiv from "../../Images/bgprfldiv.png";
import Advocayboarddetails from "../../Component/Advocayboarddetails";
import Statuscard from "../../Component/Statuscard";
import halloof1 from "../../Images/halloof1.png"
import moment from "moment";
import Addicon from "../../Images/headericon/Addicon.png"
import ThoughtModal from "../../Component/Modal/ThoughtModal";
import Chatbot from "../../Component/Chatbot/Chatbot";

function Advocacy() {
  const { userData } = useAuthCtx();
  const [advocacyCategory, setAdvocacyCategory] = useState([]);
  const [advocacyData, setAdvocacyData] = useState([]);
  const [advocacyDatas, setAdvocacyDatas] = useState([]);
  const [approvedAdvocacyData, setApprovedAdvocacyData] = useState([]);

  const [postData, setPostData] = useState([]);
  const [modalB, setModalB] = useState(false);
  const [modalId, setModalId] = useState("");
  const [modalA, setModalA] = useState(false);
  const [changeShow, setChangeShow] = useState(false);
  const [singleAdvocacyData, setSingleAdvocacyData] = useState("");
  const [catAdvocacyData, setcatAdvocacyData] = useState([]);
  const [activeTab, setActiveTab] = React.useState(0);
  const [modalthght, setModalthght] = useState(false);

  const navigate = useNavigate()
  const location = useLocation()
  console.log("activeTabff", activeTab);

  const onTabClick = (e, index) => {
    console.log("indexss", index);
    setActiveTab(index);

  };

  useEffect(() => {
    viewAllCategory();
    // viewAdvocacy();
    viewApprovedAdvocacy();
    // viewCatwiseAdvocacy()
  }, []);


  //for view all advocacy category
  const viewAllCategory = async () => {
    let res = await ApiHelperFunction({
      urlPath: "/view-advocacy-category",
      method: "GET",
    });
    if (res && res?.status) {
      setAdvocacyCategory(res?.data?.data);

      if (location?.state?.item) {
        const obj = res?.data?.data?.find(ele => ele?._id === location?.state?.item?._id)
        if (obj) {
          const ind = res?.data?.data?.indexOf(obj)
          console.log("indxxss", ind)
          if (ind !== -1) {
            setActiveTab(ind + 1)
          } else {
            setActiveTab(0)
          }
        }
      }
    } else {
      // toast.error(res?.message);
    }
  };

  //for view categorywise advocacy data
  const viewCatwiseAdvocacy = async (id) => {
    console.log("idopui", id)
    let res = await ApiHelperFunction({
      urlPath: `/view-approved-advocacy-by-category/${id}`,
      method: "GET",
    });
    if (res && res?.status) {
      setcatAdvocacyData(res?.data?.data);
    } else {
      // toast.error(res?.message);
    }
  }



  //for view only advocacy
  const viewAdvocacy = async (id) => {
    let res = await ApiHelperFunction({
      urlPath: `/view-advocacy/${id}`,
      method: "GET",
    });
    console.log("resfftyuihf", res);
    if (res && res?.status) {
      setAdvocacyData(res?.data?.data);

      setAdvocacyDatas(res?.data?.data.sort((a, b) => a.priority - b.priority));
    } else {
      // toast.error(res?.message);
    }
  };


  //for view approved all advocacy
  const viewApprovedAdvocacy = async () => {
    let res = await ApiHelperFunction({
      urlPath: "/view-approved-advocacy",
      method: "GET",
    });

    if (res && res?.status) {
      setApprovedAdvocacyData(res?.data?.data);
    } else {
      // toast.error(res?.message);
    }
  };

  // console.log(approvedAdvocacyData, "approvedAdvocacyData");
  console.log("catAdvocacyData", catAdvocacyData)

  const handleOptionClick = (index) => {
    setChangeShow(index === modalId);
  };
  const handleadvocacysingleClick = (item) => {
    // console.log("locationmncv", item)
    const data = {
      item: item
    }
    // navigate("/singleadvocacy", { state: data })
    navigate(`/singleadvocacy/${item?.AdvocacyName?.replace(/\s+/g, '-')}/${item?._id}`, { state: data });
  }

  const handleadvocacyfirstClick = (item) => {
    console.log("locationmncv", item)
    const data = {
      item: item
    }
    // navigate(`/firstadvocacy/${item?.AdvocacyName?.slice(0, 10)}/${item?._id}`, { state: data })
    navigate(`/singleadvocacy/${item?.AdvocacyName?.replace(/\s+/g, '-')}/${item?._id}`, { state: data });

  }

  const handleadvocacysecondClick = (item) => {
    // console.log("locationmncv", item)
    const data = {
      item: item
    }
    // navigate("/secondadvocacy", { state: data })
    navigate(`/singleadvocacy/${item?.AdvocacyName?.replace(/\s+/g, '-')}/${item?._id}`, { state: data });
  }



  useEffect(() => {
    // console.log("advocacyCategoryindd", advocacyCategory)
    if (advocacyCategory?.length !== 0) {
      console.log("activeTabdd", activeTab - 1)
      viewCatwiseAdvocacy(advocacyCategory[activeTab - 1]?._id)
    }
  }, [activeTab, advocacyCategory])

  const handleadvocacyClick = () => {
    navigate('/advocacyaddform')
  }
  return (
    <>
      <section className="advocacySection">
        <div className="">
          {/* <div className="custContain"> */}
          <GrowWel />
          {/* </div> */}
        </div>
        <div className="employeepagecontainer">


          <div className="">
            <div className="advocacytabpadnhg">
              <Tabs activeTab={activeTab} onTabClick={onTabClick}>
                <Tab>All</Tab>

                {advocacyCategory &&
                  advocacyCategory?.map((ele, index) => (

                    <Tab key={index}      >
                      <span
                      // onClick={() => viewCatwiseAdvocacy(ele?._id)}
                      >
                        {ele?.categoryName}
                      </span>
                    </Tab>
                  ))}
              </Tabs>

                <TabScreen
                // key={ele}
                activeTab={activeTab}
                index={0}
                className="some-animation-class"
              >
                <div className="advocacymainsmalldivflx">
                  {approvedAdvocacyData?.length !== 0 &&
                    <div className="advoSlides" onClick={() => handleadvocacyfirstClick(approvedAdvocacyData?.[0])}>
                      <figure className="advoSliFig">
                        <img src={approvedAdvocacyData?.[0]?.image} alt="..." />
                        <div className="flxdivadvosildesdivabs">
                          <div className="prflimgtxtflxadvo">
                            <div className="imgnmnwhdivadvo">
                              <div className="prflsadvctimg">
                                <img
                                  src={approvedAdvocacyData?.[0]?.userImage}
                                  alt="..."
                                />
                              </div>
                            </div>
                            <div className="prfdtlsadvc">
                              <p className="prfladtxtavdocacy">{approvedAdvocacyData?.[0]?.userName}</p>
                              <p className="prfladtxtavdocacy">{moment(approvedAdvocacyData?.[0]?.createdOn).format("DD-MMM-YYYY")}</p>
                            </div>
                          </div>
                        </div>
                      </figure>
                      <div className="advoSlideText">
                        <p className="advSliLoHead">
                          {approvedAdvocacyData?.[0]?.desc}
                        </p>

                      </div>
                    </div>
                  }

                  <div className="advocacycreatemainwidhgdiv">
                    <div className="advocacycreatemain">
                      <div className="advocacycreatemaindiv" onClick={handleadvocacyClick}>
                        <div className="addimgdiv">
                          <img src={Addicon} alt="..." />
                        </div>
                        <div className="">
                          <p className="crtadvocacytxtypp">Create Advocacy</p>
                        </div>
                      </div>
                    </div>
                    {approvedAdvocacyData?.length > 1 &&
                      <div className="advoSlidesmanydivsingle" onClick={() => handleadvocacysecondClick(approvedAdvocacyData?.[1])}>
                        <figure className="advcydiv">
                          <img src={approvedAdvocacyData?.[1]?.image} alt="..." />
                        </figure>
                        <div className="">
                          <p className="advSliLoHeadtxt">
                            {approvedAdvocacyData?.[1]?.desc}
                          </p>
                        </div>
                        <div className="prflimgtxtflx">
                          <div className="imgnmnwhdiv">
                            <div className="prflsadvctimg">
                              <img src={approvedAdvocacyData?.[1]?.userImage} alt="..." />
                            </div>
                          </div>
                          <div className="prfdtlsadvc">
                            <p className="prfladtxt">{approvedAdvocacyData?.[1]?.userName}</p>
                            <p className="prfldttxt">{moment(approvedAdvocacyData?.[1]?.createdOn).format("DD-MMM-YYYY")}</p>
                          </div>
                        </div>
                      </div>
                    }


                  </div>
                </div>

                <div className="advoSlidesmanydivmainflx">

                  {
                    approvedAdvocacyData.length === 0 ? (<h4>No data is found</h4>) : (<>
                      {
                        approvedAdvocacyData?.slice(2)?.map((item, i) => {
                          return (
                            < div className="advoSlidesmanydiv" key={i}
                              onClick={() => handleadvocacysingleClick(item)}
                            >
                              <figure className="advcydiv">
                                <img src={item?.image} alt="..." />
                              </figure>
                              <div className="">
                                <p className="advSliLoHeadtxt">
                                  {item?.desc}
                                </p>
                              </div>
                              <div className="prflimgtxtflx">
                                <div className="imgnmnwhdiv">
                                  <div className="prflsadvctimg">
                                    <img src={item?.userImage} alt="..." />
                                  </div>
                                </div>
                                <div className="prfdtlsadvc">
                                  <p className="prfladtxt">{item?.userName}</p>
                                  <p className="prfldttxt">{moment(item?.createdOn).format("DD-MMM-YYYY")}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </>)

                  }


                </div>

              </TabScreen>


              {advocacyCategory &&
                advocacyCategory?.map((ele, i) => {
                  return (
                    <TabScreen
                      key={ele}
                      activeTab={activeTab}
                      index={i + 1}
                      className="some-animation-class"
                    >
                      <div className="advocacymainsmalldivflx">
                        {catAdvocacyData?.length !== 0 &&
                          <div className="advoSlides" onClick={() => handleadvocacyfirstClick(catAdvocacyData?.[0])}>
                            <figure className="advoSliFig">
                              <img src={catAdvocacyData?.[0]?.image} alt="..." />
                              <div className="flxdivadvosildesdivabs">
                                <div className="prflimgtxtflxadvo">
                                  <div className="imgnmnwhdivadvo">
                                    <div className="prflsadvctimg">
                                      <img
                                        src={catAdvocacyData?.[0]?.userImage}
                                        alt="..."
                                      />
                                    </div>
                                  </div>
                                  <div className="prfdtlsadvc">
                                    <p className="prfladtxtavdocacy">{catAdvocacyData?.[0]?.userName}</p>
                                    <p className="prfladtxtavdocacy">{moment(catAdvocacyData?.[0]?.createdOn).format("DD-MMM-YYYY")}</p>
                                  </div>
                                </div>
                              </div>
                            </figure>
                            <div className="advoSlideText">
                              <p className="advSliLoHead">
                                {catAdvocacyData?.[0]?.desc}
                              </p>

                            </div>
                          </div>
                        }

                        {catAdvocacyData?.length > 1 &&
                          <div className="advoSlidesmanydiv" onClick={() => handleadvocacysecondClick(catAdvocacyData?.[1])}>
                            <figure className="advcydiv">
                              <img src={catAdvocacyData?.[1]?.image} alt="..." />
                            </figure>
                            <div className="">
                              <p className="advSliLoHeadtxt">
                                {catAdvocacyData?.[1]?.desc}
                              </p>
                            </div>
                            <div className="prflimgtxtflx">
                              <div className="imgnmnwhdiv">
                                <div className="prflsadvctimg">
                                  <img src={catAdvocacyData?.[1]?.userImage} alt="..." />
                                </div>
                              </div>
                              <div className="prfdtlsadvc">
                                <p className="prfladtxt">{catAdvocacyData?.[1]?.userName}</p>
                                <p className="prfldttxt">{moment(catAdvocacyData?.[1]?.createdOn).format("DD-MMM-YYYY")}</p>
                              </div>
                            </div>
                          </div>
                        }
                      </div>

                      <div className="advoSlidesmanydivmainflx">

                        {
                          catAdvocacyData.length === 0 ? (<h4>No data is found</h4>) : (<>
                            {
                              catAdvocacyData?.slice(2)?.map((item, i) => {
                                return (
                                  < div className="advoSlidesmanydiv" key={i}
                                    onClick={() => handleadvocacysingleClick(item)}
                                  >
                                    <figure className="advcydiv">
                                      <img src={item?.image} alt="..." />
                                    </figure>
                                    <div className="">
                                      <p className="advSliLoHeadtxt">
                                        {item?.desc}
                                      </p>
                                    </div>
                                    <div className="prflimgtxtflx">
                                      <div className="imgnmnwhdiv">
                                        <div className="prflsadvctimg">
                                          <img src={item?.userImage} alt="..." />
                                        </div>
                                      </div>
                                      <div className="prfdtlsadvc">
                                        <p className="prfladtxt">{item?.userName}</p>
                                        <p className="prfldttxt">{moment(item?.createdOn)?.format("DD-MMM-YYYY")}</p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </>)



                        }
                       
                      </div>
                    </TabScreen>
                  )

                })
              }
            </div>


          </div>
        </div>

      </section>

      <Chatbot />

    </>

  )
}

export default Advocacy;
