import React, { useEffect, useState } from "react";
import ProMentConnetion from "../Profile/ProMentConnetion";
// import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Img11 from "../../Images/comment_person_1.png";
// import Img12 from "../../Images/table_icon.png";
import Img12 from "../../Images/Icons/PNG/Share.png";
import Img13 from "../../Images/comment_person_2.png";
import Img14 from "../../Images/table_icon.png";
import camera from "../../Images/camera.png";
import AspiringHobby from "../Profile/AspiringHobby";
import HubAffinityGrp from "../Hub/HubAffinityGrp";
import ProSocialCommit from "../Profile/ProSocialCommit";
import RateSkills from "../Profile/RateSkills";
import chmpMsgPic from "../../Images/chmpMsgPic.png";
import user_image_2 from "../../Images/user_image_2.png";
import empMnthPic from "../../Images/empMnthPic.png";
import Image4 from "../../Images/companylogo.png";
import eliteCard from "../../Images/eliteCard.png";
import battery from "../../Images/battery.png";
import trophy from "../../Images/trophy.png";
import { ApiHelperFunction, fileUpload } from "../../services/api/apiHelpers";
import { toast } from "react-hot-toast";
import { useAuthCtx } from "../../context/AuthCtx";
import { useLocation, useParams } from "react-router-dom";
// import AddSkill from "../../Component/Modal/AddSkill";
import AddSkillcomment from "../../Component/Modal/AddSkillcomment";
// import eliteExp from "../../Images/Icons/PNG/Elite employee experience logo.png";
// import goldIcon from "../../Images/Icons/PNG/Reward - Gold.png";
import AspireHobby from "../../View/Profile/AspireHobby";
// import { RWebShare } from "react-web-share";
// import { getAllPosts } from "../../redux/slices/postSlice";
import { useDispatch, useSelector } from "react-redux";
// import Post from "../../Images/Icons/PNG/Post.png";
// import share from "../../Images/Icons/PNG/share-icon-elite-web.svg";
// import Event_View from "../../Images/Icons/PNG/Event_View.png"
import RewardOther from "../../Component/Modal/RewardOther";
import BioDetailsModal from "../../SeprateModal/BioDetailsModal";
import { getBio } from "../../redux/slices/bioSlice";
import MyAffinityGroupModal from "../../Component/Modal/MyAffinityGroupModal";
// import CardsOther from "./CardsOther";
import Addicon from "../../Images/headericon/Addicon.png"
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import View from "../../Images/headericon/View.png";
import moment from "moment";
import SaraTylorProfile from "../Profile/SaraTylorProfile";
import ComDetailsModal from "../../SeprateModal/ComDetailsModal";
import Chatbot from "../../Component/Chatbot/Chatbot";

function Profilerating() {
  const cdetails = useSelector((state) => state?.bioSlice?.cdetails)
  const { modalL, setModalL, modalK, setModalK } = useAuthCtx();
  const [skillmodalrating, setSkillmodalrating] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [particularSkillData, setParticularSkillData] = useState([]);
  const [skillId, setSkillId] = useState("");
  const [AvgRating, setAvgRating] = useState("");
  // const [goldCard, setGoldCard] = useState([]);
  const [skillData, setSkillData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [modalA, setModalA] = useState(false);
  const [reward, setReward] = useState(false)
  const [modalB, setModalB] = useState(false);
  // const [modalR, setModalR] = useState(false);
  const [modalId, setModalId] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  // const [BulletinBoardSingleData, setBulletinBoardSingleData] = useState({});
  // const [top, setTop] = useState(null);
  // const [left, setLeft] = useState(null);
  const [initiativeData, setInitiativeData] = useState({});
  // const [bio, setBio] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  // const badgeData = useSelector((state) => state?.badgeSlice?.badge);
  const dispatch = useDispatch()
  // const biodata = useSelector((state) => state?.bioSlice?.data);
  const [singleView, setSingleView] = useState({});
  const [otherPost, setOtherPost] = useState([]);
  const [ratingData, setRatingData] = useState([]);

  const [CompanyData, setCompanyData] = useState({});
  const [viewComDetails, setViewComDetails] = useState(false);
  const params = useParams();

  // console.log("nmvb", otherPost)
  // const [emojimodal, setEmojimodal] = useState(false);
  // const [repostModal, setRepostModal] = useState(false);
  // const [isHovered, setIsHovered] = useState(false);
  // const [showComment, setShowComment] = useState(true);
  // const [commentView, setCommentView] = useState(true);
  // const [showModal, setShowModal] = useState(false);
  const [commentId, setCommentId] = useState("")
  const [commentData, setCommentData] = useState([]);
  const [commentLoading, setCommentLoading] = useState(false);
  const [modalCom, setmodalCom] = useState(false);

  const initialValues7 = {
    groupName: "",
    grouptype: "",
    charter: "",
    purpose: "",
    // addEmp: []
  };
  const onTabClick = (e, index) => {
    setActiveTab(index);

  };


  // console.log("PRODATA", profileData);
  useEffect(() => {
    window.scroll(0, 0);
    if (location?.pathname == `/Profile_rating/${params?.userID}`) {
      setProfileData(location?.state?.data[0]);
      // setSkillData(location?.state?.data[0]?.skills);
      // HandleSkill(location?.state?.data[0]?.skills[0]?._id);
      setSkillId(location?.state?.data[0]?.skills[0]?._id);
    }
    dispatch(getBio());
    viewSinglepost();
    ViewAllOtherPost();
  }, []);

  useEffect(() => {
    setSkillData(singleView?.[0]?.skills)
  }, [singleView])

  // console.log("skillDatajk", skillData)

  useEffect(() => {
    // console.log("dfgghgl", skillData);
    HandleSkill(skillData?.[activeTab]?._id)
  }, [activeTab, skillData])


  //for view Single Page
  const viewSinglepost = async () => {
    // console.log("kkkksdfd", params?.AdvocacyName?.replace(/-/g, " "))
    const response = await ApiHelperFunction({
      urlPath: `/get-others-profile/${params?.userID}`,
      method: "GET",
    });
    if (response.status === 200) {
      console.log("respovbsdio", response?.data?.data)
      setSingleView(response?.data?.data);
      // setSkillData(response?.data?.data?.[0]?.skills);
      // console.log("data get successfully");
    } else {
      // toast.error(response.message);
    }
  }

  //for get comment
  const getComment = async () => {
    let data = {
      postID: commentId,
    };
    setCommentLoading(true);
    let urlPath = "/view-comment";
    let res = await ApiHelperFunction({ urlPath, method: "POST", data });
    // console.log("ResponseDATA", res?.data?.data?.replyData);
    if (res?.status) {
      setCommentData(res?.data?.data);
      // setReplyData(res?.data?.data?.replyData);
      setCommentLoading(false);
    } else {
      setCommentLoading(false)
      // toast.error(res?.data?.message || "Error Fetching User Details");
    }
  };


  // console.log("commentId", commentId)
  useEffect(() => {
    if (commentId) {
      getComment();
      // getReply();
    }
  }, [commentId])

  //for viewing own post
  const ViewAllOtherPost = async () => {
    let response = await ApiHelperFunction({
      urlPath: `/view-other-user-post/${params?.userID}`,
      method: "GET",
    });
    if (response?.status) {
      // console.log("RESDSDSDiop", response);
      const allposts = [
        ...(response?.data?.data || []),
        ...(response?.data?.advocacyPost || []),
        ...(response?.data?.eventData || []),
        ...(response?.data?.giftCards || []),
        ...(response?.data?.repostData || []),
        ...(response?.data?.receivedBadge || [])
      ];
      const sortedData = allposts?.sort((a, b) => new Date(b?.createdOn) - new Date(a?.createdOn))
      setOtherPost(sortedData);
    } else {
      // toast.error("Error fetching skill data");
    }
  };


  const handleClick = () => {
    setSkillmodalrating(!skillmodalrating);
  };


  // function openModalOne() {
  //   setModalA(true);
  // }

  // for progess bar
  const rating = Math?.round(AvgRating?.avgRating);
  const renderProgressBars = () => {
    const progressBars = [];

    for (let i = 5; i >= 1; i--) {
      progressBars?.push(
        <div className="progress_wrap" key={i}>
          <span className="number">{i}</span>
          <span className="icon">
            <i className="fa-solid fa-star" />
          </span>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${(i <= rating) ? (i * 20) + '%' : '0%'}` }}
              aria-valuenow={25}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
          <span className="amount">{(i <= rating) ? 1 : 0}</span>
        </div>
      );
    }

    return progressBars;
  };


  // const handleChange2 = async (e) => {
  //   let file = e.target.files[0];
  //   const data = new FormData();
  //   data.append("image", file);

  //   let res = await fileUpload("/image-upload", "POST", data);

  //   if (res.status) {
  //     // setImageUrl(res?.image);
  //     updateCoverImage(res?.image);
  //   } else {
  //     toast.error("Error uploading image");
  //   }
  //   // setUploading(false);
  // };


  //for update cover image
  // const updateCoverImage = async (img) => {
  //   let data = {
  //     coverImage: img,
  //   };
  //   let response2 = await ApiHelperFunction({
  //     urlPath: "/update-cover-image",
  //     method: "PUT",
  //     data,
  //   });

  //   console.log(data, response2, "responkdhjli");

  //   if (response2?.status) {
  //     toast.success("Cover picture updated successfully");
  //     getUserDetails();
  //   } else {
  //     // toast.error("Error fetching data");
  //   }
  // };

  // const handleChange = async (e) => {
  //   let file = e.target.files[0];
  //   const data = new FormData();
  //   data.append("image", file);

  //   let res = await fileUpload("/image-upload", "POST", data);

  //   if (res.status) {
  //     // setImageUrl(res?.image);
  //     ProfileUpdate(res?.image);
  //   } else {
  //     toast.error("Error uploading image");
  //   }
  //   // setUploading(false);
  // };

  // const ProfileUpdate = async (image) => {
  //   let data = {
  //     coverImage: image,
  //   };
  //   let response = await ApiHelperFunction({
  //     urlPath: "/update-profile",
  //     method: "PUT",
  //     data,
  //   });
  //   if (response?.status) {
  //     toast.success("Cover picture updated successfully");
  //     getUserDetails();
  //   } else {
  //     toast.error("Error fetching data");
  //   }
  // };

  //for badge repost functionality
  // const handlePost = async (e, item) => {
  //   let data = {
  //     description: `${item?.employeeFName} has got ${item?.employeeLName} ${item?.badgeName}`,
  //     image: [item.image],
  //     postType: "public",
  //   };

  //   // return;
  //   let response = await ApiHelperFunction({
  //     urlPath: "/user-add-post",
  //     method: "POST",
  //     data: data,
  //   });

  //   if (response?.status === 200) {
  //     // setPost({
  //     //   description: "",
  //     //   image: "",
  //     // });
  //     toast.success(response?.data?.message);
  //     dispatch(getAllPosts());
  //   } else {
  //     toast.error(response?.response?.data?.message);
  //   }

  // };

  const Rating = (val) => {
    switch (val) {
      case 1:
        return <i className="fa-solid fa-star" />;
      case 2:
        return (
          <>
            <i className="fa-solid fa-star" />
            <i className="fa-solid fa-star" />
          </>
        );

      case 3:
        return (
          <>
            <i className="fa-solid fa-star" />
            <i className="fa-solid fa-star" />
            <i className="fa-solid fa-star" />
          </>
        );
      case 4:
        return (
          <>
            <i className="fa-solid fa-star" />
            <i className="fa-solid fa-star" />
            <i className="fa-solid fa-star" />
            <i className="fa-solid fa-star" />
          </>
        );
      case 5:
        return (
          <>
            <i className="fa-solid fa-star" />
            <i className="fa-solid fa-star" />
            <i className="fa-solid fa-star" />
            <i className="fa-solid fa-star" />
            <i className="fa-solid fa-star" />
          </>
        );

      default:
        return null;
    }
    // for(let i=0 ; i< val ; i++){
    // return(
    // <i className="fa-solid fa-star" />
    // )
    // }
  };

  const HandleSkill = async (id) => {
    let data = {
      id: id,
    };
    setSkillId(id);
    let response = await ApiHelperFunction({
      urlPath: "/view-skill-rating",
      method: "POST",
      data,
    });
    if (response?.status) {
      setParticularSkillData(response?.data?.data[0]?.reviewData);
      setAvgRating(response?.data?.data[0]);
      setRatingData(response?.data?.data[0]?.reviewCountData);
      // console.log("DATADFF", response?.data);
    } else {
      toast.error("Error fetching skill data");
    }
  };

  //for load more functionality
  const numberOfInitialRecords = 1
  const numberOfRecordsPage = 1
  const [totalRecordsToDisplay, setTotalRecordsToDisplay] = useState(numberOfInitialRecords)
  const loadMoreItems = () => {
    setTotalRecordsToDisplay(totalRecordsToDisplay + numberOfRecordsPage)
  }

  // const rewardClick = () => {
  //   setReward(!reward)
  // }


  //for Clicking Bio
  // const handleClickBio = (e, ele, index) => {
  //   setModalId(ele._id);
  //   // console.log("EVENT", e, index);
  //   setInitiativeData(ele);
  //   // console.log("s", e, ele);
  //   setTop(e.clientY);
  //   setLeft(e.clientX);
  //   setModalR(!modalR);
  // };


  const handleInitiativeClick = (ele) => {
    setInitiativeData(ele);
    setViewDetails(true);
  };


  //for delete functionality
  // const handleDelete = async () => {
  //   const res = await ApiHelperFunction({
  //     urlPath: `/delete-bio/${modalId}`,
  //     method: "PUT",
  //   });
  //   if (res?.status) {
  //     toast.success("Deleted Successfully");

  //     dispatch(getBio());
  //     setModalR(false);
  //   } else {
  //     toast.error(res?.response?.data?.message || "Something went wrong");
  //     console.log("ERROR CREATING USER3", res);
  //   }
  // };

  //for follow functionality
  const handleFollowClick = async () => {
    let data = {
      receiverId: singleView?.[0]?._id,
    };

    let response = await ApiHelperFunction({
      urlPath: "/send-friend-request",
      method: "POST",
      data: data,
    });

    if (response?.status === 200) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.response?.data?.message);
    }
  }

  // console.log("cvermn", singleView?.[0])

  const [activeTabown, setActiveTabown] = useState(0);
  const [viewbrndd, setViewbrndd] = useState(false)

  const handleTabClick = (index) => {
    setActiveTabown(index);
  };
  const handleviewclick = () => {
    setViewbrndd(!viewbrndd)
  }

  //for clicking Company
  const handleCompanyClick = (ele) => {
    setCompanyData(ele);
    setViewComDetails(true);
  };

  return (
    <>
      <section className="profilePageSection">
        <div className="profileCvrPicDiv">
          <figure className="profileBackFigure">
            {singleView?.[0]?.coverImage && (
              <img src={singleView?.[0]?.coverImage} alt="..." />
            )}
          </figure>
          <div>
            {/* <p className="proCvrTagLine">Your Tag Line</p> */}
            {/* {userData?.userType === "Admin" ? (
              <div className="proEditFigText">
                <input
                  type="file"
                  className="proEditFigInp"
                  onChange={handleChange2}
                />
                <div className="proEditFigInnerText">
                  <figure className="proEditCamFig">
                    <img src={camera} alt="..." />
                  </figure>
                  <span className="proEditText">Edit your cover picture</span>
                </div>
              </div>
            ) : (
              <></>
            )} */}
          </div>
        </div>
        <div className="employeepagecontainer">
          <div className="employeecalederflx">
            <div className="engageplacecardlistwhh profilerightscrngh">
              <div className="hobyagfondiv">

                {
                  singleView?.[0]?.userType === "Owner"
                    ?
                    (<div className="user_aboutnbet">
                      <div className="bg-userdtwht" style={{ backgroundColor: `${singleView?.[0]?.rewardCard?.[0]?.cardColor}` }}>
                        <div className="plthclbtx">
                          {/* <p className="bghhkpp">{singleView?.[0]?.rewardCard?.[0]?.cardName}</p> */}
                          {/* <p className="clbpp">Club</p> */}
                        </div>
                        <div className="" style={{ backgroundColor: "#fff", height: "71%", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", borderTopRightRadius: "80px" }}>
                          <div className="divpaddingghh">
                            <div className="prflimgdiv">
                              <div className="prflimgbrdr">
                                <div className="prflimgfh">
                                  <img src={singleView?.[0]?.image} alt="..." />
                                </div>
                              </div>
                              <div className="divpatyn">
                                {/* <p className="thounsabpp" style={{ color: `${singleView?.[0]?.rewardCard?.[0]?.cardColor}` }}>{singleView?.[0]?.rewardPoints}</p> */}
                                {/* <p className="pointshttx">POINTS BALANCE</p> */}
                              </div>
                            </div>

                            <div className="tstdfghmrl">
                              <p className="txtppght">{`${singleView?.[0]?.firstName} ${singleView?.[0]?.lastName}`}</p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)
                    :
                    (<div className="user_aboutnbet">
                      <div className="bg-userdtwht" style={{ backgroundColor: `${singleView?.[0]?.rewardCard?.[0]?.cardColor}` }}>
                        <div className="plthclbtx">
                          <p className="bghhkpp">{singleView?.[0]?.rewardCard?.[0]?.cardName}</p>
                          <p className="clbpp">Club</p>
                        </div>
                        <div className="" style={{ backgroundColor: "#fff", height: "71%", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", borderTopRightRadius: "80px" }}>
                          <div className="divpaddingghh">
                            <div className="prflimgdiv">
                              <div className="prflimgbrdr">
                                <div className="prflimgfh">
                                  <img src={singleView?.[0]?.image} alt="..." />
                                </div>
                              </div>
                              <div className="divpatyn">
                                <p className="thounsabpp" style={{ color: `${singleView?.[0]?.rewardCard?.[0]?.cardColor}` }}>{singleView?.[0]?.rewardPoints}</p>
                                <p className="pointshttx">POINTS BALANCE</p>
                              </div>
                            </div>

                            <div className="tstdfghmrl">
                              <p className="txtppght">{`${singleView?.[0]?.firstName} ${singleView?.[0]?.lastName}`}</p>
                              {/* {
                            profileData?.userType !== "Admin" ? (<p className="dvjknjpp">{profileData?.designation}</p>) : (<></>)
                          } */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)
                }


                <div className="">
                  <button className="followerbtnrating" onClick={handleFollowClick}>
                    <div className="addfolowersicomn">
                      <img src={Addicon} alt="..." />
                    </div>
                    <p className="folowtxtppphj">Follow</p>
                  </button>
                </div>

                <div className="hobbyPersoDiv">
                  <AspireHobby hobbyDatas={singleView?.[0]?.hobbies} />
                </div>


                <div className="rewardspoint">
                  <div className="rewarddiv">
                    {singleView?.[0]?.userType === "Owner"
                      ?
                      (
                        <div className="rewarddivflx">
                          <p className="hobbyAspHead">Company Details</p>
                        </div>
                      )
                      :
                      (
                        <div className="rewarddivflx">
                          <p className="hobbyAspHead">Bio</p>
                        </div>
                      )
                    }



                    <div className="bottom_ara  ">
                      {
                        singleView?.[0]?.userType === "Owner" &&
                        singleView?.[0]?.companydetails != {} && (
                          <div className="card_area">

                            <div className="top">
                              {/* <div className="name introdivprfltxt ">
                                <h4>{singleView?.[0]?.companydetails?.introduction}</h4>
                              </div> */}
                              <div className="thredothjdjfvnk" onClick={() => setmodalCom(!modalCom)}>
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                {modalCom && <div className="viwebdbfnmmdibn">
                                  <div className="dltimgdiv" onClick={() => handleCompanyClick(singleView?.[0]?.companydetails)}>
                                    <img src={View} alt="..." />
                                  </div>
                                </div>
                                }
                              </div>
                            </div>

                            <div className="content_area">
                              <p className="cntntxtbioplll"> Company Name :
                                <span>
                                  {cdetails?.companyName}
                                </span>
                              </p>
                              <p className="cntntxtbioplll"> CEO :
                                <span>
                                  {singleView?.[0]?.companydetails?.nameOfCeo}
                                </span>
                              </p>
                              <p className="cntntxtbioplll"> Address :
                                <span>
                                  {singleView?.[0]?.companydetails?.city},{singleView?.[0]?.companydetails?.state}
                                </span>
                              </p>

                              <div className="indstrtviewflxa">
                                <p className="cntntxtbioplll"> Industry Type:
                                  <span>
                                    {singleView?.[0]?.companydetails?.typeOfIndustry}
                                  </span>
                                </p>

                              </div>
                            </div>

                          </div>
                        )
                      }

                      {
                        singleView?.[0]?.bio?.length > 0 && (
                          // profileData?.map((item, index) => {
                          //   return (
                          <div className="card_area">
                            <div className="top">
                              <div className="name introdivprfltxt ">
                                <h4>{singleView?.[0]?.bio?.[0]?.introduction}</h4>
                              </div>
                              {/* <div className="thredothjdjfvnk" onClick={handleviewclick}>
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                {viewbrndd && <div className="viwebdbfnmmdibn">
                                  <div className="dltimgdiv" onClick={() => handleInitiativeClick(singleView?.[0]?.bio?.[0])}>
                                    <img src={View} alt="..." />
                                  </div>
                                </div>
                                }
                              </div> */}
                            </div>

                            <div className="content_area">

                              <p className="cntntxtbioplll"> Education :
                                <span>
                                  {singleView?.[0]?.bio?.[0]?.education}
                                </span>

                              </p>
                              <p className="cntntxtbioplll"> Address :
                                <span>
                                  {singleView?.[0]?.bio?.[0]?.city},{singleView?.[0]?.bio?.[0]?.state}
                                </span>
                              </p>
                              <p className="cntntxtbioplll">
                                Industry :
                                <span>

                                  {singleView?.[0]?.bio?.[0]?.industry}
                                </span>
                              </p>
                              <button className="viewbtndivlk" onClick={() => handleInitiativeClick(singleView?.[0]?.bio?.[0])}>View</button>

                            </div>
                            <div
                              className="all_profile d-flex align-items-center"
                            >

                            </div>

                          </div>
                          //   )
                          // })
                        )
                      }

                    </div>
                  </div>
                </div>

                {/* <div className="rewardspoint">
                  <div className="rewarddiv">
                    <div className="rewarddivflx">
                      <p className="hobbyAspHead">Rewards Point Details</p>
                      <figure className="bulleBoardFig" onClick={rewardClick}>
                        <img src={Event_View} alt="..." />
                      </figure>
                    </div>
                  </div>
                </div> */}


              </div>
            </div>
            {/* <div className="aspiringProDiv">
                            <AspiringHobby />
                        </div> */}


            {
              singleView?.[0]?.userType === "Owner"
                ?
                (

                  <div className="calenderwhhhperfl ">

                    <div className="profilemidscrngh">
                      <div className="tab-buttons">
                        {['Activitity Log']?.map((tab, index) => (
                          <button
                            key={index}
                            className={index === activeTabown ? 'activeprofilebtnh' : 'notactivebtnprfl'}
                            onClick={() => handleTabClick(index)}
                          >
                            {tab}
                          </button>
                        ))}
                      </div>
                      <div className="tab-content">
                        {/*  */}
                        {activeTabown === 0 &&
                          <div className="ownpostdivhjhkjhkjl">
                            {/* {
                              singleView?.[0]?.giftCards ? (<div className="">
                                <CardsOther profileData={singleView?.[0]} />
                              </div>) : (<></>)
                            } */}

                            {otherPost?.length > 0 ? (
                              otherPost?.map((item, index) => {
                                // console.log("ertioy", item);
                                return (
                                  <SaraTylorProfile
                                    index={index}
                                    userID={item?.singleView?.[0]?.adminID}
                                    key={item?._id}
                                    // handleOptionClick={handleOptionClick}
                                    setModalA={setModalA}
                                    modalA={modalA}
                                    modalB={modalB}
                                    modalId={modalId}
                                    isUserReacted={item?.isUserReacted}
                                    postId={item?._id}
                                    name={item?.userData?.[0]?.firstName + " " + item?.userData?.[0]?.lastName}
                                    imagesArray={item}
                                    createdOn={moment(item?.createdOn)?.format("YYYY-MM-DD")}
                                    reaction={item?.totalReaction}
                                    description={item?.description || item?.notes || item?.desc}
                                    showDescription={item?.showDescription}
                                    // optionShow={showEditDeleIconMemo(item)}
                                    setModalB={setModalB}
                                    setModalId={setModalId}
                                    modalK={modalK}
                                    setModalK={setModalK}
                                    // changeShow={modalId === item?._id}
                                    showComment={modalId === item?._id}
                                    // showComment={handleShowCommentClick}
                                    commentId={commentId}
                                    setCommentId={setCommentId}
                                    commentData={commentData}
                                    getComment={getComment}
                                    commentLoading={commentLoading}
                                    singleData={item}
                                  />
                                );
                              })
                            ) : (
                              <p>No post Found!</p>
                            )}
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                )
                :
                (<div className="calenderwhhhperfl ">

                  <div className="profilemidscrngh">
                    <div className="tab-buttons">
                      {['Activitity Log', 'Skill Validation']?.map((tab, index) => (
                        <button
                          key={index}
                          className={index === activeTabown ? 'activeprofilebtnh' : 'notactivebtnprfl'}
                          onClick={() => handleTabClick(index)}
                        >
                          {tab}
                        </button>
                      ))}
                    </div>
                    <div className="tab-content">
                      {activeTabown === 1 &&
                        <div className="testdivbfkd">
                          <div className="myTestRatingDiv">
                            <div className="myVegsDiv">
                              {/* <figure className="myVetFig">
                    <img src={Skills} alt="..." />
                  </figure> */}
                              <p className="hobbyAspHeadslklvtxt">
                                My Vet Skills and Write Testimonials
                              </p>
                              <div className="addSkillsTabBtnmain">
                                <button className="addSkillsTabBtn" onClick={handleClick}>
                                  <span>Add Ratings</span>
                                </button>
                              </div>
                            </div>
                            <div>
                              <div className="scrollbarandaddiconflx">
                                {/* <p className="myVetTestHead">
        My Vet Skills and Write Testimonials
      </p> */}
                                <Tabs activeTab={activeTab} onTabClick={onTabClick}>


                                  {singleView?.[0]?.skills &&
                                    singleView?.[0]?.skills?.map((ele, ind) => {
                                      return (
                                        <Tab
                                          key={ind}
                                          onClick={() => HandleSkill(ele?._id)}
                                        >
                                          {ele?.skill}
                                        </Tab>
                                      );
                                    })}

                                </Tabs>
                              </div>

                              {/* <button className="addSkillsTabBtn" onClick={handleClick}>
        <span>Add Ratings</span>
      </button> */}


                              {singleView?.[0]?.skills &&
                                singleView?.[0]?.skills?.map((ele, i) => {
                                  return (
                                    <TabScreen key={i}
                                      activeTab={activeTab}
                                      index={i}
                                      className="some-animation-class somdclassdkko">
                                      {particularSkillData && (
                                        <div id="rating">
                                          <div className="rateJavaHeadDiv">
                                            <p className="rateJavaHead">
                                              {AvgRating?.skillname} Skill Vetted Score
                                            </p>

                                          </div>
                                          <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                                              <div className="rating_text">
                                                <h2>
                                                  {/* {Math?.round(AvgRating?.avgRating)} */}
                                                  {AvgRating?.avgRating}
                                                </h2>
                                                <p>out of</p>
                                                <h3>5.00</h3>
                                              </div>
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-8 col-12">
                                              {/* {particularSkillData ? ( */}
                                                <p className="totalFiveText">
                                                  Total reviews
                                                </p>
                                              {/* // ) : (
                                              //   <p className="totalFiveText">
                                              //     Total 0 reviews given
                                              //   </p>
                                              // )} */}

                                              <div className="progress_wrap">
                                                <span className="number">5</span>
                                                <span className="icon">
                                                  <i className="fa-solid fa-star" />
                                                </span>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                  />
                                                </div>
                                                <span className="amount">
                                                  {Math.round(ratingData?.[0]?._id) == 5
                                                    ? `${ratingData?.[0]?.totalUser}`
                                                    : 0}
                                                </span>
                                              </div>
                                              <div className="progress_wrap">
                                                <span className="number">4</span>
                                                <span className="icon">
                                                  <i className="fa-solid fa-star" />
                                                </span>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "80%" }}
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                  />
                                                </div>
                                                <span className="amount">
                                                  {Math.round(ratingData?.[1]?._id) == 4
                                                    ? `${ratingData?.[1]?.totalUser}`
                                                    : 0}
                                                </span>
                                              </div>
                                              <div className="progress_wrap">
                                                <span className="number">3</span>
                                                <span className="icon">
                                                  <i className="fa-solid fa-star" />
                                                </span>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "75%" }}
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                  />
                                                </div>
                                                <span className="amount">
                                                  {Math.round(ratingData?.[2]?._id) == 3
                                                    ? `${ratingData?.[2]?.totalUser}`
                                                    : 0}
                                                </span>
                                              </div>
                                              <div className="progress_wrap">
                                                <span className="number">2</span>
                                                <span className="icon">
                                                  <i className="fa-solid fa-star" />
                                                </span>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "30%" }}
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                  />
                                                </div>
                                                <span className="amount">
                                                  {Math.round(ratingData?.[3]?._id) == 2
                                                    ? `${ratingData?.[3]?.totalUser}`
                                                    : 0}
                                                </span>
                                              </div>
                                              <div className="progress_wrap">
                                                <span className="number">1</span>
                                                <span className="icon">
                                                  <i className="fa-solid fa-star" />
                                                </span>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "10%" }}
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                  />
                                                </div>
                                                <span className="amount">
                                                  {Math.round(ratingData?.[4]?._id) == 1
                                                    ? `${ratingData?.[4]?.totalUser}`
                                                    : 0}
                                                </span>
                                              </div>


                                              {/* {renderProgressBars()} */}
                                            </div>
                                            {particularSkillData?.slice(0, totalRecordsToDisplay)?.map((ele) => {
                                              return (
                                                <div className="comment_section">
                                                  <div className="comment_left">
                                                    <div className="comment_person">
                                                      {ele?.image ? (
                                                        <img
                                                          className="img-fluid"
                                                          src={ele?.image}
                                                          alt="pic"
                                                        />
                                                      ) : (
                                                        <img
                                                          className="img-fluid"
                                                          src="http://52.66.120.170:4049/uploads/imageUploads/530060.com-4811117"
                                                          alt="pic"
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="comment_right">
                                                    <div className="comment_top">
                                                      <div className="comment_top_left">
                                                        <h5>{ele?.reviewedBy}</h5>
                                                        <div className="star">
                                                          {Rating(
                                                            Math.round(ele?.rating)
                                                          )}
                                                        </div>
                                                      </div>
                                                      {/* <div className="comment_top_right">
                                    <p>5 months ago</p>
                                  </div> */}
                                                    </div>
                                                    <div className="person_description">
                                                      <p>{ele?.review}</p>
                                                    </div>
                                                    {/* <div className="comment_icon">
                                  <button className="cmntLinkBtn">
                                    <i class="fa-brands fa-linkedin"></i>
                                  </button>
                                  <img
                                    className="img-fluid"
                                    src={Img12}
                                    alt="icon"
                                  />
                                </div> */}
                                                  </div>
                                                </div>
                                              );
                                            })}

                                            {/* <div className="rating_btn">
                          <button className="review_btn">
                            Show More Reviews
                          </button>
                        </div> */}

                                            {
                                              totalRecordsToDisplay < particularSkillData?.length && (
                                                <>
                                                  {/* <div class="blog-pagination text-center">
                                <button class="btn btn-success" onClick={loadMoreItems}>Load more</button>
                              </div> */}
                                                  <div className="rating_btn">
                                                    <button className="review_btn" onClick={loadMoreItems}>
                                                      Show More Reviews
                                                    </button>
                                                  </div>
                                                </>
                                              )
                                            }
                                          </div>
                                        </div>
                                      )}
                                    </TabScreen>
                                  );
                                })}


                            </div>

                          </div>
                        </div>
                      }
                      {activeTabown === 0 &&
                        <div className="ownpostdivhjhkjhkjl">
                          {/* {
                            singleView?.[0]?.giftCards ? (<div className="">
                              <CardsOther profileData={singleView?.[0]} />
                            </div>) : (<></>)
                          } */}

                          {otherPost?.length > 0 ? (
                            otherPost?.map((item, index) => {
                              // console.log("ertioy", item);
                              return (
                                <SaraTylorProfile
                                  index={index}
                                  userID={item?.singleView?.[0]?.adminID}
                                  key={item?._id}
                                  // handleOptionClick={handleOptionClick}
                                  setModalA={setModalA}
                                  modalA={modalA}
                                  modalB={modalB}
                                  modalId={modalId}
                                  isUserReacted={item?.isUserReacted}
                                  postId={item?._id}
                                  name={item?.userData?.[0]?.firstName + " " + item?.userData?.[0]?.lastName}
                                  imagesArray={item}
                                  createdOn={moment(item?.createdOn).format("YYYY-MM-DD")}
                                  reaction={item?.totalReaction}
                                  description={item?.description || item?.notes}
                                  showDescription={item?.showDescription}
                                  // optionShow={showEditDeleIconMemo(item)}
                                  setModalB={setModalB}
                                  setModalId={setModalId}
                                  modalK={modalK}
                                  setModalK={setModalK}
                                  // changeShow={modalId === item?._id}
                                  showComment={modalId === item?._id}
                                  // showComment={handleShowCommentClick}
                                  commentId={commentId}
                                  setCommentId={setCommentId}
                                  commentData={commentData}
                                  getComment={getComment}
                                  commentLoading={commentLoading}
                                  singleData={item}
                                />
                              );
                            })
                          ) : (
                            <p>No post Found!</p>
                          )}
                        </div>
                      }
                    </div>
                  </div>
                </div>)
            }

            {/* <div className="leftMrkDiv">
                <div className="mrkHubFigDiv">
                  <figure className="mrkHubFig">
                    {profileData?.image ? (
                      <img src={profileData?.image} alt="..." />
                    ) : (
                      <img
                        src="http://52.66.120.170:4049/uploads/imageUploads/530060.com-4811117"
                        alt="..."
                      />
                    )}
                  </figure>
                  <div className="mrkInpFigDiv">
                    <figure className="mrkCamFig">
                    </figure>
                   
                  </div>
                </div>
                <div className="empDegId">
                  {profileData && (
                    <p className="mrkHbHead">{`${profileData?.firstName} ${profileData.lastName}`}</p>
                  )}
                  <div className="mrkNmBtnDiv">
                    <span className="mrkHbName">
                      {profileData?.designation}
                    </span>
                
                  </div>
                </div>
              </div> */}



            {/* <div className="proSocialCmtDiv">
                <ProSocialCommit />
              </div> */}

            {/* <div className="col-xl-6 mt-3 mt-md-4 mt-xl-0"> */}
            {/* <p className="proAboutSelf">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
                            eligendi mollitia nisi, suscipit vitae repellat optio quasi
                            perspiciatis, eius esse excepturi odit ipsam vero maiores impedit
                            debitis eum soluta laudantium possimus officiis a incidunt
                            ducimus! Dolores temporibus cum consequuntur, earum vero doloribus
                            excepturi accusamus quo omnis consequatur deleniti obcaecati in
                            eaque expedita fugit labore odit ipsam? Explicabo illo quisquam
                            perspiciatis.
                        </p> */}
            {/* <RateSkills /> */}
            {/* <div className="rateMyInnerDivParent">
                <div className="rateMyInnerDiv">
                  <p className="rateMyComText">Critical Pending Task</p>
                  <div className="rateMyNumCirDiv">
                    <p className="rateMyNumHead">40</p>
                    <figure className="rateMybatteFig">
                      <img src={battery} alt="..." />
                    </figure>
                  </div>
                </div>
                <div className="rateMyNtrkInnerDiv">
                  <p className="rateMyComText">Total points Gained</p>
                  <div className="rateMyNumCirDiv">
                    <p className="rateMyNumHead">08</p>
                    <figure className="rateMyTropFig">
                      <img src={trophy} alt="..." />
                    </figure>
                  </div>
                </div>
              </div> */}
            {/* 
            </div> */}


            <div className="highlightedsubsrwhh profileleftscrngh">
              <div className="myAffitiDiv">
                <HubAffinityGrp />
              </div>
              <div className="jourChampDiv">
                {/* <figure className="jourEliteCardFig">
                  <img src={eliteCard} alt="..." />
                </figure> */}

                {/* <figure className="eliteFig">
            <img src={eliteCard} alt="..." />
          </figure>
          <span className="goldCardText">GOLD CLUB</span> */}
                {/* <div className="goldClubDiv" style={{ backgroundColor: `${goldCard?.rewardCard?.[0]?.cardColor}` }}>
                    <div className="goldInnerDiv">
                      <div>
                        <p className="eliteSubHead">Status</p>
                        <p className="goldClHead">{goldCard?.rewardCard?.[0]?.cardName}</p>
                      </div>
                      <figure className="goldIconFig">
                        <img src={eliteExp} alt="..." />
                      </figure>
                    </div>
                    <div className="cardNameDiv">
                      <div>
                        <p className="cardNumbText">
                          {profileData?.goldClubNo}
                        </p>
                        <p className="sanjNameText">
                          {profileData?.firstName} {profileData?.lastName}
                        </p>
                      </div>
                      <p className="goldSmClub">Gold Club</p>
                      <figure className="goldIcoFigDiv">
                        <img src={goldIcon} alt="..." />
                      </figure>
                    </div>
                  </div> */}


                {/* <div className="mycarddiv"> */}
                {/* {userData?.userType === "Admin" ? (
                    <div className="tyEvMainDiv" onClick={openModalOne}>
                      <span className="tyEvIcon">
                        <i class="fa-solid fa-plus"></i>
                      </span>
                      <p className="tyEvText">Badge Name</p>
                    </div>
                  ) : (
                    ""
                  )} */}
                {/* For badge */}
                {/* <p className="tyEvText">Badge Name</p>  */}
                {/* <div className="champMainDivParent">
                    {singleView?.[0]?.badge?.length > 0 ? (
                      singleView?.[0]?.badge?.map((item, ind) => {
                        return (
                          <div
                            className="champMainDiv"
                            key={ind}
                          >
                            <div className="champFigTextDiv">
                              <figure className="champFig">
                                <img src={item?.userImage} alt="..." />
                              </figure>
                              <div>
                                <span className="champHead">{item?.userName}</span>
                                
                              </div>
                            </div>

                            <div className="champFigTextDiv">
                          
                              <p className="chmpThisText">Badge : {item?.badgeName}</p>
                            </div>
                            <div style={{ paddingBottom: "10px" }}> <p className="chmpThisText">Title : {item?.desc}</p></div>
                            <div className="svCosMainDiv">
                              <div className="svCoShBtnDiv">
                                <figure className="svMsgFig">
                  
                                  <i className="fa-solid fa-floppy-disk"></i>
                                </figure>
                      
                              </div>
                              <div className="svCoShBtnDiv">
                                <figure className="svMsgFig" onClick={(e) => handlePost(e, item)}>
                                  <img src={Post} alt="..." />
                                </figure>
                               
                              </div>
                          
                              <RWebShare
                                data={{
                                  text: `${item?.badgeName}`,
                                  // url: `${item?.link}`,
                                  title: "Share badge",
                                }}
                           
                                sites={[
                                  "linkedin",
                                  "facebook",
                                  "twitter",
                                  "whatsapp",
                                  "mail",
                                  "copy",
                                ]}

                              
                                onClick={(platform) => {
                                  if (platform === "copy") {
                                    
                                    const textToCopy = `Elite Employee Experience Hub - Powered by Fractals Global- http://52.66.120.170:3004/Profile_rating`;
                                    navigator.clipboard.writeText(textToCopy).then(() => {
                                      console.log("Text copied to clipboard:", textToCopy);
                                  
                                      toast.success("Link copied to clipboard!");
                                    });
                                  } else {
                                 
                                    console.log("Shared successfully on", platform);
                                  }
                                }}
                              >
                                <div className="svCoShBtnDiv">
                              
                                  <figure className="svMsgFig">
                                    <img src={share} alt="..." />
                                  </figure>

                                 
                                </div>
                              </RWebShare>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p>No badge is added</p>
                    )}

                  </div> */}
                {/* </div> */}




                {/* <div className="champMainDivParent">
                  <div
                    className="champMainDiv"
                    style={{ backgroundColor: "#EDF0FF" }}
                  >
                    <div className="champFigTextDiv">
                      <figure className="champFig">
                        <img src={user_image_2} alt="..." />
                      </figure>
                      <div>
                        <span className="champHead">
                          Champion Learner Badge
                        </span>
                        <span className="champDate">17 Jan</span>
                      </div>
                    </div>
                    <div className="champFigTextDiv">
                      <figure className="champFig2">
                        <img src={empMnthPic} alt="..." />
                      </figure>
                      <p className="chmpThisText">
                        This is to certify that Mark Anderson has achieved
                        Champion Learner Badge for SAP HANA Certification by
                        Fractalz Learning Centre dated 17 Jan 2023
                      </p>
                    </div>
                    <div className="svCosMainDiv">
                      <div className="svCoShBtnDiv">
                        <figure className="svMsgFig">
                          <img src={chmpMsgPic} alt="..." />
                        </figure>
                        <span className="chmpMsgSpan">Save</span>
                      </div>
                      <div className="svCoShBtnDiv">
                        <figure className="svMsgFig">
                          <img src={chmpMsgPic} alt="..." />
                        </figure>
                        <span className="chmpMsgSpan">Copy</span>
                      </div>
                      <div className="svCoShBtnDiv">
                        <figure className="svMsgFig">
                          <img src={chmpMsgPic} alt="..." />
                        </figure>
                        <span className="chmpMsgSpan">Share</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="champMainDiv"
                    style={{ backgroundColor: "#EEEAE1" }}
                  >
                    <div className="champFigTextDiv">
                      <figure className="champFig">
                        <img src={user_image_2} alt="..." />
                      </figure>
                      <div>
                        <span className="champHead">
                          Champion Learner Badge
                        </span>
                        <span className="champDate">17 Jan</span>
                      </div>
                    </div>
                    <div className="champFigTextDiv">
                      <figure className="champFig2">
                        <img src={empMnthPic} alt="..." />
                      </figure>
                      <p className="chmpThisText">
                        This is to certify that Mark Anderson has achieved
                        Champion Learner Badge for SAP HANA Certification by
                        Fractalz Learning Centre dated 17 Jan 2023
                      </p>
                    </div>
                    <div className="svCosMainDiv">
                      <div className="svCoShBtnDiv">
                        <figure className="svMsgFig">
                          <img src={chmpMsgPic} alt="..." />
                        </figure>
                        <span className="chmpMsgSpan">Save</span>
                      </div>
                      <div className="svCoShBtnDiv">
                        <figure className="svMsgFig">
                          <img src={chmpMsgPic} alt="..." />
                        </figure>
                        <span className="chmpMsgSpan">Copy</span>
                      </div>
                      <div className="svCoShBtnDiv">
                        <figure className="svMsgFig">
                          <img src={chmpMsgPic} alt="..." />
                        </figure>
                        <span className="chmpMsgSpan">Share</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="champMainDiv"
                    style={{ backgroundColor: "#DCDAD5" }}
                  >
                    <div className="champFigTextDiv">
                      <figure className="champFig">
                        <img src={user_image_2} alt="..." />
                      </figure>
                      <div>
                        <span className="champHead">
                          Champion Learner Badge
                        </span>
                        <span className="champDate">17 Jan</span>
                      </div>
                    </div>
                    <div className="champFigTextDiv">
                      <figure className="champFig2">
                        <img src={empMnthPic} alt="..." />
                      </figure>
                      <p className="chmpThisText">
                        This is to certify that Mark Anderson has achieved
                        Champion Learner Badge for SAP HANA Certification by
                        Fractalz Learning Centre dated 17 Jan 2023
                      </p>
                    </div>
                    <div className="svCosMainDiv">
                      <div className="svCoShBtnDiv">
                        <figure className="svMsgFig">
                          <img src={chmpMsgPic} alt="..." />
                        </figure>
                        <span className="chmpMsgSpan">Save</span>
                      </div>
                      <div className="svCoShBtnDiv">
                        <figure className="svMsgFig">
                          <img src={chmpMsgPic} alt="..." />
                        </figure>
                        <span className="chmpMsgSpan">Copy</span>
                      </div>
                      <div className="svCoShBtnDiv">
                        <figure className="svMsgFig">
                          <img src={chmpMsgPic} alt="..." />
                        </figure>
                        <span className="chmpMsgSpan">Share</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mentConnecDivWrap">
                  <ProMentConnetion />
                </div> */}
              </div>
            </div>

          </div>
        </div>
      </section>
      <Chatbot />
      {viewComDetails && <ComDetailsModal closemodal={setViewComDetails} CompanyData={CompanyData} />}


      {modalL && (
        <MyAffinityGroupModal
          closemodal={setModalL}
          activity={"affinity"}
          initialValues={initialValues7}
        />
      )}

      {viewDetails && <BioDetailsModal closemodal={setViewDetails} initiativeData={initiativeData} />}

      {skillmodalrating && (
        <AddSkillcomment
          HandleSkill={HandleSkill}
          SkillId={skillId}
          closemodal={setSkillmodalrating}
        />
      )}

      {reward && <RewardOther closemodal={setReward} userData={singleView?.[0]} />}
    </>
  );
}

export default Profilerating;
