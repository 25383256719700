import React, { useState, useEffect } from "react";
import { useAuthCtx } from "../context/AuthCtx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { ApiHelperFunction, fileUpload } from "../services/api/apiHelpers";
import { useDispatch } from "react-redux";
import { getBillBoardData } from "../redux/slices/billBoardSlice";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Bulletin_Board from "../../src/Images/Icons/PNG/Bulletin_Board.png";
import Addicon from "../../src/Images/headericon/Addicon.png"
const animatedComponents = makeAnimated();

const BillBoardModal = ({ closemodal, activity, initialValues }) => {
  const { setLoading, loading, getUserDetails, userData } = useAuthCtx();
  const [imageURL, setImageURL] = useState("");
  const dispatch = useDispatch();
  const [localerror, setlocalerror] = useState("");
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [colourOptions, setColourOptions] = useState([]);
  const [empData, setempData] = useState([]);


  const handleSelectChange = (e) => {
    console.log("Selected Options:", e);

    // Update the colourOptions state with the selected options
    setSelectedOptions(e);
  };

  const getEmployeeData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-all-employees`,
      method: "GET",
    });

    console.log("RESPONSEF", response?.data?.data);

    if (response && response.status) {
      const formattedOptions = response?.data?.data?.map((item) => ({
        value: item?._id,
        label: item?.userName,
      }));

      setColourOptions(formattedOptions);
      setempData(response?.data?.data);
    } else {
      // toast.error(response.message);
    }
  };


  const submitHandler = async (e, selectedSubscription) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    setShouldValidateOnBlur(true);
    console.log("handleSubmit", values);

    let data = {};
    setLoading(true);
    if (
      values.eventName === "" ||
      // values.hostedBy == "" ||
      values.eventDate === "" ||
      // values.eventstarttime == "" ||
      // values.eventendtime == "" ||
      values.addNotes === ""
    ) {
      return toast.error("All fields required");
    }
    data = {
      // lattitude: values?.lattitude,
      // longitude: values?.longitude,
      eventName: values?.eventName,
      hostedBy: values?.hostedBy,
      eventDate: values?.eventDate,
      eventstarttime: values?.eventstarttime,
      eventendtime: values?.eventendtime,
      addNotes: values?.addNotes,
      image: imageURL,
      // invite: values.addinvites,
      addEmploee: selectedOptions?.value,
      // invite: selectedOptions?.value,
    };

    // console.log("kkkkkkk", data);
    const res = await ApiHelperFunction({
      urlPath: "/add-bulletin-board",
      method: "POST",
      data,
    });
    if (res?.status) {
      toast.success("Bulletin is added successfully");
      resetForm();
      dispatch(getBillBoardData());
      closemodal();
    } else {
      toast.error(res?.response?.data?.message || "Something went wrong");
      console.log("ERROR CREATING USER3", res);
    }
    setLoading(false);
  };

  const schemaCheck = Yup.object(
    activity === "billBoard" ||
      activity === "events" ||
      activity === "kababMenu" ||
      activity === "editEvent"
      ? {
        eventName: Yup.string().required("Event Name is required"),
        hostedBy: Yup.string().required("Host name is required"),
        eventDate: Yup.string().required("Event date is required"),
        eventstarttime: Yup.string().required("start Time is required"),
        eventendtime: Yup.string().required(" End Time is required"),
        notes: Yup.string().required(" Notes is required"),
        addinvites: Yup.string().required(" Addinvites is required"),
        lattitude: Yup.string().required(" lattitude is required"),

        longitude: Yup.string().required(" longitude is required"),
      }
      : activity === "training" || activity === "editTraining"
        ? {
          name: Yup.string().required("Event Name is required"),
          trainingDate: Yup.string().required("Training date is required"),
        }
        : activity === "jobReffered"
          ? {
            name: Yup.string().required("Job Name is required"),
            location: Yup.string().required("Job location is required"),
            salary: Yup.string().required("Salary range is required"),
            description: Yup.string().required("Description range is required"),
            opening: Yup.string().required("Opening is required"),
          }
          : ""
  );

  const handleImageChange = async (e) => {
    let image = e.target.files[0];
    setUploading(true);

    const form = new FormData();
    form.append("image", image);

    let res = await fileUpload("/image-upload", "POST", form);

    if (res.status) {
      toast.success("Image uploaded successfully");
      setImageURL(res?.image);
    } else {
      toast.error("Error uploading image");
    }
    setUploading(false);
  };

  const { values, errors, handleBlur, handleSubmit, resetForm, handleChange } =
    useFormik({
      initialValues,
      validationSchema: schemaCheck,
      validateOnChange: shouldValidateOnChange,
      validateOnBlur: shouldValidateOnBlur,
      onSubmit: (val) => {
        console.log("val", val);
      },
    });

  useEffect(() => {
    getEmployeeData()
  }, [])

  return (
    <div className="createmodal_main">
      <div className="create_modal">

        <div className="createmodalnewdivcontent">
          <div
            className=""
          // style={{ width: "100%", height: "100%", padding: "20px 0" }}
          >
            <div className="bulletintextclose">
              <div className="bulletinimageflxdivmodal">
                <div className="bulleBoardFig">
                  <img src={Bulletin_Board} alt="..." />
                </div>
                <span className="newBulleCreHead">New Bulletin</span>
              </div>

              <div onClick={() => closemodal()}>
                <i
                  class="fa-solid fa-xmark"
                  style={{
                    color: "red",
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </div>

            <form className="">
              <div>
                <div className="homePgModInnerInpDivsmain">
                  <p>Bulletin Name</p>
                  <input
                    type="text"
                    id="eventName"
                    aria-describedby="emailHelp"
                    // placeholder="Event Name"
                    value={values.eventName}
                    name="eventName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  // className="homePgMoInps"
                  />

                  <small id="emailHelp" style={{ color: "red" }}>
                    {errors.eventName}
                  </small>
                </div>
                <div className="homePgModInnerInpDivsmain">
                  <p>Event Date</p>
                  <input
                    type="date"
                    id="eventDate"
                    placeholder="Wednesday 2:45 to 4:45 PM"
                    value={values.eventDate}
                    name="eventDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    min={new Date()?.toISOString()?.split("T")[0]}
                    className="homePgMoInps"
                  />
                  <small id="emailHelp" style={{ color: "red" }}>
                    {errors.eventDate}
                  </small>
                </div>
                <div className="homePgModInnerInpDivsmain">
                  <p>Select Invitees</p>
                  <Select
                    placeholder={"Select Invitees"}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={[]}
                    options={colourOptions}
                    onChange={handleSelectChange}
                  />
                </div>
                <div className="homePgModInnerInpDivsmain">
                  <p>Add Notes</p>
                  <textarea
                    value={values?.addNotes}
                    name="addNotes"
                    id=""
                    cols=""
                    rows="2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  // className="addNoteCreModTextArea"
                  ></textarea>
                  <small id="emailHelp" style={{ color: "red" }}>
                    {localerror}
                    {errors.addNotes}
                  </small>
                </div>
                <div className="homePgModInnerInpDivsupldflx">
                  <p>Upload File</p>
                  <div className="bguploadbulletinmdbrdr">
                    <div className="bgcrdbulletin">
                      <div className="bgcontainerupldfilediv">
                        <div className="logoupldbulletin">
                          <img src={Addicon} alt="..." />
                          <div className="">
                            <input
                              type="file"
                              id="hostedBy"
                              placeholder="Choose a photo"
                              value={image}
                              accept="image/*"
                              name="hostedBy"
                              onBlur={handleBlur}
                              onChange={handleImageChange}
                              className="upldimagediv"
                            />
                            <small id="emailHelp" style={{ color: "red" }}>
                              {errors.image}
                            </small>
                          </div>
                        </div>
                        <div className="bgcontainerupldfiledivabslt">
                          {imageURL !== "" && (
                            <div className="upldimgdivbulletin" >
                              <img
                                src={imageURL}
                                alt=""
                              
                              />
                              <div className="clsoedivmainrd"
                                onClick={() => {
                                  setImageURL("");
                                  setImage("");
                                }}
                              >
                                <i
                                  class="fa-solid fa-xmark"
                                  style={{
                                    // color: "red",
                                    // fontSize: "2rem",
                                    // cursor: "pointer",
                                  }}
                                ></i>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                     
                    </div>
                  </div>


                  {uploading ? <p>image uploading......</p> : null}

                </div>
                <div className="bulletinsbmtbtndivv">
                  <button
                    type="button"
                    className="bulletinsbmtbtn"
                    onClick={submitHandler}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>

          </div>

        </div>
      </div>
    </div>
  );
};

export default BillBoardModal;
