import React from 'react'
import ThankYoucard from "../../Images/ThankYoucard.png";
import HappyBirthdayCard from "../../Images/HappyBirthdayCard.png";
import Onboardingwelcomecardclean from "../../Images/Onboardingwelcomecardclean.png"
import HappyAnniversaryCardclean from "../../Images/HappyAnniversaryCardclean.png";
import companyelite from "../../Images/companyelite.png"
import { useAuthCtx } from '../../context/AuthCtx';


const Allcard = ({
    closemodala,
    handleonecardClick,
    handlesecondcardClick,
    handlethirdcardClick,
    handlfourthcardClick,
}) => {
const {userData}=useAuthCtx();
    const handleclosecardClick = (e) => {

        e.stopPropagation()
    }

    // console.log("handleonecard", handleonecard)
    return (
        <>
            <div className='allcardmodalbgmain' onClick={() => closemodala()} >
                <div className='allcardbigbg' onClick={(e) => handleclosecardClick(e)}>
                    <div className='allcardsectionflx'>

                        <div className="allcardsectionbg" style={{ cursor: "pointer" }} onClick={handleonecardClick}>
                            <div className='thankyoucrdbg' style={{ backgroundImage: `url(${ThankYoucard})` }}>

                                <div className='companylitlleimg'>
                                    <img src={userData?.companyLogo} alt="..." />
                                </div>

                                <div className='profilebgthanky'>
                                    <div className='profilebgthankyimg'>
                                        {/* <img src={user_image} alt="..." /> */}
                                        <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt='...' />
                                    </div>
                                </div>
                                <div className='thankyubrdrdiv'>
                                    Thank You
                                </div>
                                {/* <div className=''>
                                    <p className='thankyoutxtppcrd'>Thank You for your supports and believe. I look forward to networking and exploring potential opportunities together</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="allcardsectionbg" onClick={handlesecondcardClick} style={{ cursor: "pointer" }}>
                            <div className='thankyoucrdbg' style={{ backgroundImage: `url(${HappyBirthdayCard})` }}>
                                <div className='companylitlleimg'>
                                    <img src={userData?.companyLogo} alt="..." />
                                </div>
                                <div className='happybrthdivmain'>
                                    <p className='happytxtcrdpp'>Happy</p>
                                    <h4 className='birthtxtcrdpp'>Birthday</h4>
                                </div>
                                <div className='profilebgthankywh'>
                                    <div className='profilebgthankyimg'>
                                        {/* <img src={user_image} alt="..." /> */}
                                        <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt='...' />
                                    </div>
                                </div>
                                <div className='happybrthdivmain'>
                                    {/* <p className='happytxtcrdppname'>Prity Sinha</p> */}
                                    {/* <h4 className='birthtxtcrdpphead'>Purchase Head</h4> */}
                                </div>
                            </div>
                        </div>
                        <div className="allcardsectionbg" onClick={handlethirdcardClick} style={{ cursor: "pointer" }}>
                            <div className='thankyoucrdbg' style={{ backgroundImage: `url(${Onboardingwelcomecardclean})` }}>
                                <div className='companylitlleimg'>
                                    <img src={userData?.companyLogo} alt="..." />
                                </div>
                                <div className='textimagemainwelcmdivflx'>
                                    <div className='algnonbrddivmainwh'>
                                        <p className='wlcmcrdtxtpp'>Welcome Onboard !</p>
                                        {/* <p className='wlcmcrdname'>Raju</p> */}
                                        {/* <h4 className='wlcmcrdtitle'>Acharya</h4> */}
                                        {/* <h4 className='birthtxtcrdpphead'>Purchase Head</h4> */}
                                    </div>
                                    <div className='algnonbrddivmainimg'>
                                        <div className='profilebgthankywh'>
                                            <div className='profilebgthankyimg'>
                                                {/* <img src={user_image} alt="..." /> */}
                                                <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt='...' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="allcardsectionbg" onClick={handlfourthcardClick} style={{ cursor: "pointer" }}>
                            <div div className='thankyoucrdbg' style={{ backgroundImage: `url(${HappyAnniversaryCardclean})` }}>
                                <div className='companylitlleimg'>
                                    <img src={userData?.companyLogo} alt="..." />
                                </div>
                                <div className='happyannyversytxtppflx'>
                                    <div className='happyannyversyimgmain'>
                                        <div className='happyannyversyimg'>
                                            {/* <img src={personImg} alt="..." /> */}
                                            <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt='...' />
                                        </div>
                                    </div>
                                    <div className='txthappyanniversydiv'>
                                        {/* <div className='yrscmpltdivmain'>
                                            <p className='fiveyrsrtxtpp'>5 Years</p>
                                            <p className='fiveyrsrtxtppcmplt'>Completion in the family</p>
                                        </div> */}
                                        <div className='happypritydivtxt'>
                                            <p className='happyannyvrtxtpp'>Happy Anniversary</p>
                                            {/* <p className='happyannyvrtxtppmanme'>Prity!</p> */}
                                        </div>
                                        {/* <div className=''>
                                            <p className='wishyutxtpgdpp'>Wish you a good future ahead</p>
                                            <p className='wishyutxtpgdppsrpp'>It's your day, dear.</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* {handleonecard && <OneCard closemodal={setHandleonecard} />} */}

        </>
    )
}

export default Allcard