import React from 'react'
import trophymodak from "../../Images/trophymodak.png"

const Congratulationmodal = ({ closemodal, certificateUrl,percentData }) => {
  
    const downloadCertificate = () => {
        const link = document.createElement('a');
        link.href = certificateUrl;
        link.download = 'certificate.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <div className='allcardmodalbgmain ' onClick={() => closemodal()}>
            <div className='congratsdivcard'>
                {/* <div className="close_icon" onClick={() => closemodal()}>
                    <i
                        class="fa-solid fa-xmark"
                        style={{ color: "red", fontSize: "16px", cursor: "pointer" }}
                    ></i>
                </div> */}

                <div className='trphyimhgmaindivmnain'>
                    <div className='trphyimhgmain'>
                        <img src={trophymodak} alt="..." />
                    </div>
                    <div className='congarttscyyppdiv'>
                        <h4 className='congarttscyypp'>CONGRATULATIONS!</h4>
                        <p className='ablernytxtpp'>
                            {/* On your Achivement. You Did It. */}
                            You have got {percentData}% 
                        </p>
                    </div>

                    <div className=''>
                        <button className="sbmtdbtbhmaiondiv" onClick={downloadCertificate} >View & Download Certificate</button>
                       {/* <div className=''>
                            <iframe src={certificateUrl}></iframe>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Congratulationmodal
