import React, { useState } from "react";
import "./Notifications.css";
import NotificationSingle from "./NotificationSingle";
import notiImg from "../../Images/notiImg.png";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import ApproveClaimPointModal from "../../Component/Modal/ApproveClaimPointModal";

function NotificationActual({
  id,
  advocacyId,
  title,
  description,
  image,
  advocacyPostedBy,
  userFirstName,
  userLastName,
  senderName,
  isAdminApproved,
  groupId,
  requestId,
  userID,
  createdOn,
  value,
  onboardingTrainingId,
  microTrainingId,
  jobRequirmentId,
  trainingvalue,
  managerTrainingValue,
  isManagerApproved,
  claimPointId,
  claimPointValue,
  friendRequestId,
  isFriendReqAccept,
  senderImage,
  refferlId,
  refferedValue,
  viewAllNotification
}) {

  // console.log(claimPointValue, "trainingvalue");
  const [modalA, setModalA] = useState(false);

  const navigate = useNavigate();
  const [modalB, setModalB] = useState(false);

  const openNotiModal = () => {
    setModalA(true);
  };

  const openClaimModal = () => {
    setModalB(!modalB);
  };

  // console.log("valuejk", refferedValue)

  // const viewAllNotification = async () => {
  //   let res = await ApiHelperFunction({
  //     urlPath: "/view-notification",
  //     method: "GET",
  //   });

  //   if (res && res?.status) {
  //     setNotiData(res?.data?.data);
  //   } else {
  //     toast.error(res?.message);
  //   }
  // };

  // useEffect(() => {
  //   viewAllNotification();
  // }, []);

  const openTrainingNotification = () => {
    let data = {
      values: trainingvalue,
      isAdminApproved: isAdminApproved,
      isManagerApproved: isManagerApproved
    }
    navigate(`/singleTrainingNotification`, { state: { data } })
  }

  const openManagerNotification = () => {
    let data = {
      values: managerTrainingValue,
      isAdminApproved: isAdminApproved,
      isManagerApproved: isManagerApproved
    }
    navigate(`/singleManagerTrainingNotification`, { state: { data } })
  }

  const openJobNotification = () => {
    let data = {
      values: value,
      isAdminApproved: isAdminApproved
    }
    navigate(`/jobNotificationSingle`, { state: { data } })
  }

  const openJobReffer = () => {
    navigate(`/jobssingledetails/${refferedValue?.jobTitle?.replace(/\s+/g, '-')}/${refferedValue?.jobId}`);
  }


  return (
    <>

      {
        groupId
          ?
          (<div
            className="notiActualImgDiv"
            // style={
            //   activeY
            //     ? { backgroundColor: "#EDF0FF" }
            //     : { backgroundColor: "white" }
            // }
            onClick={openNotiModal}
          >
            <figure className="notiPersonImg">
              <img src={notiImg} alt="..." style={{ height: "43px", width: "43px" }} />
              {/* <span className="notiBellSpan">{icon}</span> */}
            </figure>
            <div>
              <div>

                <Link className="notiInnerMainHead" style={{ fontSize: "15px" }}>{title}</Link>
                <p>{description}</p>

              </div>
              <div>
                {/* <span className="hourTimeNoti">2h ago</span> */}
                <span className="hourTimeNoti">
                  {moment(new Date(createdOn))?.fromNow()}
                </span>
                {/* <span className="planNoti">Social Media Plan</span> */}
              </div>
            </div>
          </div>)
          :
          advocacyId
            ?
            (<div
              className="notiActualImgDiv"
              // style={
              //   activeY
              //     ? { backgroundColor: "#EDF0FF" }
              //     : { backgroundColor: "white" }
              // }
              onClick={openNotiModal}
            >
              <figure className="notiPersonImg">
                <img src={notiImg} alt="..." style={{ height: "43px", width: "43px" }} />
                {/* <span className="notiBellSpan">{icon}</span> */}
              </figure>
              <div>
                <div>

                  <Link className="notiInnerMainHead" style={{ fontSize: "15px" }}>{title}</Link>
                  <p>{description}</p>

                </div>
                <div>
                  {/* <span className="hourTimeNoti">2h ago</span> */}
                  <span className="hourTimeNoti">
                    {moment(new Date(createdOn))?.fromNow()}
                  </span>
                  {/* <span className="planNoti">Social Media Plan</span> */}
                </div>
              </div>
            </div>)
            :
            microTrainingId
              ?
              (<div
                className="notiActualImgDiv"
                // style={
                //   activeY
                //     ? { backgroundColor: "#EDF0FF" }
                //     : { backgroundColor: "white" }
                // }
                onClick={openTrainingNotification}
              >
                <figure className="notiPersonImg">
                  <img src={notiImg} alt="..." style={{ height: "43px", width: "43px" }} />
                  {/* <span className="notiBellSpan">{icon}</span> */}
                </figure>
                <div>
                  <div>

                    <Link className="notiInnerMainHead" style={{ fontSize: "15px" }}>{title}</Link>
                    <p>{description}</p>

                  </div>
                  <div>
                    {/* <span className="hourTimeNoti">2h ago</span> */}
                    <span className="hourTimeNoti">
                      {moment(new Date(createdOn))?.fromNow()}
                    </span>
                    {/* <span className="planNoti">Social Media Plan</span> */}
                  </div>
                </div>
              </div>)
              :
              jobRequirmentId
                ?
                (<div
                  className="notiActualImgDiv"
                  // style={
                  //   activeY
                  //     ? { backgroundColor: "#EDF0FF" }
                  //     : { backgroundColor: "white" }
                  // }
                  onClick={openJobNotification}
                >
                  <figure className="notiPersonImg">
                    <img src={notiImg} alt="..." style={{ height: "43px", width: "43px" }} />
                    {/* <span className="notiBellSpan">{icon}</span> */}
                  </figure>
                  <div>
                    <div>

                      <Link className="notiInnerMainHead" style={{ fontSize: "15px" }}>{title}</Link>
                      <p>{description}</p>

                    </div>
                    <div>
                      {/* <span className="hourTimeNoti">2h ago</span> */}
                      <span className="hourTimeNoti">
                        {moment(new Date(createdOn))?.fromNow()}
                      </span>
                      {/* <span className="planNoti">Social Media Plan</span> */}
                    </div>
                  </div>
                </div>)
                :

                refferlId
                  ?
                  (<div
                    className="notiActualImgDiv"
                    // style={
                    //   activeY
                    //     ? { backgroundColor: "#EDF0FF" }
                    //     : { backgroundColor: "white" }
                    // }
                    onClick={openJobReffer}
                  >
                    <figure className="notiPersonImg">
                      <img src={notiImg} alt="..." style={{ height: "43px", width: "43px" }} />
                      {/* <span className="notiBellSpan">{icon}</span> */}
                    </figure>
                    <div>
                      <div>

                        <Link className="notiInnerMainHead" style={{ fontSize: "15px" }}>{title}</Link>
                        <p>{description}</p>

                      </div>
                      <div>
                        {/* <span className="hourTimeNoti">2h ago</span> */}
                        <span className="hourTimeNoti">
                          {moment(new Date(createdOn))?.fromNow()}
                        </span>
                        {/* <span className="planNoti">Social Media Plan</span> */}
                      </div>
                    </div>
                  </div>)
                  :
                  claimPointId
                    ?
                    (<div
                      className="notiActualImgDiv"
                      // style={
                      //   activeY
                      //     ? { backgroundColor: "#EDF0FF" }
                      //     : { backgroundColor: "white" }
                      // }
                      onClick={openClaimModal}
                    >
                      <figure className="notiPersonImg">
                        <img src={notiImg} alt="..." style={{ height: "43px", width: "43px" }} />
                        {/* <span className="notiBellSpan">{icon}</span> */}
                      </figure>
                      <div>
                        <div>

                          <Link className="notiInnerMainHead" style={{ fontSize: "15px" }}>{title}</Link>
                          <p>{description}</p>

                        </div>
                        <div>
                          {/* <span className="hourTimeNoti">2h ago</span> */}
                          <span className="hourTimeNoti">
                            {moment(new Date(createdOn))?.fromNow()}
                          </span>
                          {/* <span className="planNoti">Social Media Plan</span> */}
                        </div>
                      </div>
                    </div>)
                    :
                    onboardingTrainingId
                      ?
                      (<><div
                        className="notiActualImgDiv"

                        onClick={openManagerNotification}
                      >
                        <figure className="notiPersonImg">
                          <img src={notiImg} alt="..." style={{ height: "43px", width: "43px" }} />

                        </figure>
                        <div>
                          <div>

                            <Link className="notiInnerMainHead" style={{ fontSize: "15px" }}>{title}</Link>
                            <p>{description}</p>

                          </div>
                          <div>

                            <span className="hourTimeNoti">
                              {moment(new Date(createdOn))?.fromNow()}
                            </span>

                          </div>
                        </div>
                      </div></>)
                      :
                      friendRequestId
                        ?
                        (<div
                          className="notiActualImgDiv"
                          onClick={openNotiModal}
                        >
                          <figure className="notiPersonImg">
                            <img src={notiImg} alt="..." style={{ height: "43px", width: "43px" }} />
                          </figure>
                          <div>
                            <div>
                              <Link className="notiInnerMainHead" style={{ fontSize: "15px" }}>{title}</Link>
                            </div>
                            <div>
                              {/* <span className="hourTimeNoti">2h ago</span> */}
                              <span className="hourTimeNoti">
                                {moment(new Date(createdOn))?.fromNow()}
                              </span>
                              {/* <span className="planNoti">Social Media Plan</span> */}
                            </div>
                          </div>
                        </div>)
                        :
                        (<div
                          className="notiActualImgDiv"
                          // style={
                          //   activeY
                          //     ? { backgroundColor: "#EDF0FF" }
                          //     : { backgroundColor: "white" }
                          // }
                          onClick={openNotiModal}
                        >
                          <figure className="notiPersonImg">
                            <img src={notiImg} alt="..." style={{ height: "43px", width: "43px" }} />
                            {/* <span className="notiBellSpan">{icon}</span> */}
                          </figure>
                          <div>
                            <div>

                              <Link className="notiInnerMainHead" style={{ fontSize: "15px" }}>{title}</Link>
                              <p>{description}</p>

                            </div>
                            <div>
                              {/* <span className="hourTimeNoti">2h ago</span> */}
                              <span className="hourTimeNoti">
                                {moment(new Date(createdOn))?.fromNow()}
                              </span>
                              {/* <span className="planNoti">Social Media Plan</span> */}
                            </div>
                          </div>
                        </div>)
      }

      {modalA && (
        <NotificationSingle
          senderName={senderName}
          closeSingleModal={setModalA}
          id={id}
          advocacyId={advocacyId}
          title={title}
          description={description}
          image={image}
          advocacyPostedBy={advocacyPostedBy}
          userFirstName={userFirstName}
          userLastName={userLastName}
          isAdminApproved={isAdminApproved}
          groupId={groupId}
          requestId={requestId}
          userID={userID}
          createdOn={createdOn}
          Value={value}
          onboardingTrainingId={onboardingTrainingId}
          isManagerApproved={isManagerApproved}
          friendRequestId={friendRequestId}
          isFriendReqAccept={isFriendReqAccept}
          senderImage={senderImage}
          viewAllNotification={viewAllNotification}
        />
      )}

      {
        modalB && (<ApproveClaimPointModal id={id} closemodal={setModalB} activity={"claim point"} initialValues={claimPointValue} isManagerApproved={isManagerApproved} />)
      }
    </>
  );
}

export default NotificationActual;
