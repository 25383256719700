import React from 'react'
import EducateOngoing from "../../View/TrainingPage/EducateOngoing";
import ExpandGradient from "../../Images/headericon/ExpandGradient.png";
import video from "../../Images/Icons/PNG/video.png";
import { useNavigate } from 'react-router-dom';

const Ongoing = () => {

    const navigate = useNavigate();
    const handleongoingClick = () => {
        navigate('/singleongoing')
    }

    return (
        <>
            <div className='videoongoing'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        {/* <figure className="bulleBoardFig" >
                            <img
                                src={video}
                                alt="..."

                            />
                        </figure> */}
                        <h4>On Going</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv">
                            <figure className="bulleBoardFig" >
                                <img src={ExpandGradient} alt="..." onClick={handleongoingClick} />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='bottom bottomdivongong'>
                    <EducateOngoing />
                </div>
            </div>
        </>
    )
}

export default Ongoing
