import React, { useCallback, useEffect, useState } from "react";
// import AffinityGroups from "../../Component/AffinityGroups";
import BulletinBoard from "../../Component/BulletinBoard";
import HallofFame from "../../Component/HallofFame";
import Initiative from "../../Component/Initiative";
import JobsReferred from "../../Component/JobsReferred";
import EventCard from "../../Component/EventCard";
import ShareThoughts from "../../Component/ShareThoughts";
import SaraTylor from "../../Component/SaraTylor";
// import TrainingCard from "../../Component/TrainingCard";
// import MyConnections from "../../Component/MyConnections";
// import MyGalary from "../../Component/MyGalary";
import Employees from "../../Component/Employees";
// import GoalAchiver from "../../Component/GoalAchiver";
import { useAuthCtx } from "../../context/AuthCtx";
import { useDispatch, useSelector } from "react-redux";
import { getAllPosts } from "../../redux/slices/postSlice";
import moment from "moment";
import HubAffinityGrp from "../Hub/HubAffinityGrp";
import BillBoardModal from "../../SeprateModal/BillBoardModal";
import KababMenu from "../../SeprateModal/KababMenu";
import GoalAchiverModal from "../../SeprateModal/GoalAchiverModal";
import CreateModal from "../../Component/Modal/CreateModal";
import EventsModal from "../../SeprateModal/EventsModal";
import EditEventModal from "../../SeprateModal/EditEventModal";
import TrainingModal from "../../SeprateModal/TrainingModal";
import EditTrainingModal from "../../SeprateModal/EditTrainingModal";
import JobRefferedModal from "../../SeprateModal/JobRefferedModal";
import EmployeeModal from "../../SeprateModal/EmployeeModal";
// import eliteCard from "../../Images/eliteCard.png";
import HallOfFrameModal from "../../Component/Modal/HallOfFrameModal";
import MyAffinityGroupModal from "../../Component/Modal/MyAffinityGroupModal";
import InitiativeModal from "../../Component/Modal/InitiativeModal";
// import goldIcon from "../../Images/Icons/PNG/Reward - Gold.png";
// import eliteExp from "../../Images/Icons/PNG/Elite employee experience logo.png";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
// import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import EditHallOfFame from "../../SeprateModal/EditHallOfFame";
// import charbot from "../../Images/charbot.png"
import Chatbot from "../../Component/Chatbot/Chatbot";
// import Advocacy from "../../Images/Icons/PNG/Advocacy 1.png";
// import advytimg from "../../Images/advytimg.jpg"
// import Advocayboarddetails from "../../Component/Advocayboarddetails";
// import AdvocacyDetailsModal from "./AdvocacyDetailsModal";
// import Loader from "../../Component/loader/Loader";
// import see_all from "../../Images/Icons/PNG/See all icon.png"
import Statuscard from "../../Component/Statuscard";
import { getFollowerData, getFollowingData } from "../../redux/slices/badgeSlice";
import Follower from "../Profile/Follower";

export default function Index() {
  const { setLoading } = useAuthCtx();
  const postDataReducer = useSelector((state) => state?.postGetSlice);
  const dispatch = useDispatch();
  const [postData, setPostData] = useState([]);
  const [commentId, setCommentId] = useState("")
  const [commentData, setCommentData] = useState([]);
  const [commentLoading, setCommentLoading] = useState(false)
  const [leftcontainmain, setleftcontainmain] = useState(false);

  // console.log("postDataReducer", postDataReducer)
  const {
    userData,
    BulletinBoardSingleData1,
    HallfameSingleData1,
    modala,
    modalD,
    setModalD,
    modalE,
    setModalE,
    modalK,
    setModalK,
    modalL,
    setModalL,
    modalM,
    setModalM,
    modalF,
    setModalF,
    modalG,
    setModalG,
    modalH,
    setModalH,
    modalI,
    employeeModal,
    setEmployeeModal,
    setModalI,
    modalJ,
    setModalJ,
    setModala,
    trainingData,
    // setTrainingData,
    modalC,
    setModalC,
    modalO,
    setModalO,
    BulletinBoardSingleData,
  } = useAuthCtx();
  const [modalB, setModalB] = useState(false);
  const [modalId, setModalId] = useState("");
  const [modalA, setModalA] = useState(false);
  // const [goldCard, setGoldCard] = useState([]);
  // const [showComment, setShowComment] = useState(false);
  const navigate = useNavigate();
  const [changeShow, setChangeShow] = useState(false);
  const params = useParams();
  // const [approvedAdvocacyData, setApprovedAdvocacyData] = useState([]);
  // const [advoLoading, setAdvoLoading] = useState(false);
  // const [initiativeData, setInitiativeData] = useState({});
  // const [viewDetails, setViewDetails] = useState(false);
  // const [friendList, setfriendList] = useState([]);
  // const [followingList, setfollowingList] = useState([]);
  // const followingList = useSelector((state) => state?.badgeSlice?.following);
  // const friendList = useSelector((state) => state?.badgeSlice?.follower);

  //reload posts on addition

  // const getPosts = () => {
  //   dispatch(getAllPosts());
  //
  //   console.log("posts123",postData);

  // }

  // console.log("postData", postData);

  const initialValues = {
    eventName: "",
    hostedBy: "",
    eventDate: "",
    eventstarttime: "",
    eventendtime: "",
    lattitude: "",
    longitude: "",
    addinvites: "",
    name: "",
    notes: "",
    image: "",
    editTrainingName: "",
    editTraininDate: "",
    isHighLighted: "",
    eventTypes: "",
    postType: "",
    affinityGroupId: "",
    eventTime: "",
    addEmploee: []

    // nameofInitiative: "",
    // duration: "",
    // desc: "",
    // contact: "",
    // rewardPoints: ""
  };
  // const initialValues1 = {
  //   eventName: "",
  //   hostedBy: "",
  //   eventDate: "",
  //   name: "",
  //   trainingDate: "",
  //   editTrainingName: "",
  //   editTraininDate: "",

  //   // nameofInitiative: "",
  //   // duration: "",
  //   // desc: "",
  //   // contact: "",
  //   // rewardPoints: ""
  // };
  const initialValues2 = {
    eventName: "",
    hostedBy: "",
    eventDate: "",
    trainingJustification: "",
    trainingCost: "",
    name: "",
    trainingDate: "",
    link: "",
    editTrainingName: "",
    editTraininDate: "",
  };

  const initialValues3 = {
    // name: "",
    // location: "",
    // salary: "",
    // opening: "",
    // description: "",

    jobTitle: "",
    description: "",
    keySkills: [],
    qualification: "",
    jobLocation: [],
    experience: "",
    salary: "",
    openings: ""
  };

  const initialValues4 = {
    nameofInitiative: "",
    duration: "",
    desc: "",
    contact: "",
    rewardPoints: "",
  };

  const initialValues5 = {
    name: "",
    userId: "",
    reason: "",
  };

  const initialValues6 = {
    initiativeTypeID: "",
    nameOfInitaitive: "",
    description: "",
    startDate: "",
    endDate: "",
    rewardPoints: "",
  };

  const initialValues7 = {
    groupName: "",
    grouptype: "",
    charter: "",
    purpose: "",
    // addEmp: []
  };


  // //for viewing friendList
  // const ViewAllfriendList = async () => {
  //   let response = await ApiHelperFunction({
  //     urlPath: "/get-follower-friend-list",
  //     method: "GET",
  //   });
  //   if (response?.status) {
  //     // console.log("RESDSDSDiop", response);
  //     setfriendList(response?.data?.data);
  //   } else {
  //     // toast.error("Error fetching skill data");
  //   }
  // };

  // //for viewing followingList
  // const ViewAllfollowingList = async () => {
  //   let response = await ApiHelperFunction({
  //     urlPath: "/get-following-friend-list",
  //     method: "GET",
  //   });
  //   if (response?.status) {
  //     console.log("RESDSDSDiop", response);
  //     setfollowingList(response?.data?.data);
  //   } else {
  //     // toast.error("Error fetching skill data");
  //   }
  // };


  //for navigate follower page
  const handleFollowerClick = () => {
    navigate("/followers")
  }

  //for view approved advocacy 
  // const viewApprovedAdvocacy = async () => {
  //   setAdvoLoading(true);
  //   let res = await ApiHelperFunction({
  //     urlPath: "/view-approved-advocacy",
  //     method: "GET",
  //   });

  //   if (res && res?.status) {
  //     setApprovedAdvocacyData(res?.data?.data);
  //     setAdvoLoading(false)
  //   } else {
  //     // toast.error(res?.message);
  //   }
  // };

  //view advocacy details
  // const handleInitiativeClick = (ele) => {
  //   setInitiativeData(ele);
  //   setViewDetails(true);
  // };

  const handleOptionClick = (index) => {
    setChangeShow(index === modalId);
  };

  //for affinity group post
  const getAffinityPosts = async () => {
    const data = {
      affinityGroupId: params?.id,
      postType: "private",
    };

    // const data = {
    //     description: "",
    //     image: "image",
    //     "postType": "private",
    //     "affinityGroupId": "657c580aeda16942bbccb104"
    // }
    setLoading(true);
    const response = await ApiHelperFunction({
      urlPath: `/view-all-post`,
      method: "POST",
      data,
    });
    // console.log("responseddfftt", response);
    if (response.status === 200) {
      // toast.success(response?.data?.data?.message);
      // console.log(response?.data, "response?.data");
      setPostData(response?.data?.data);
    } else {
      // toast.error(response?.message);
    }
    setLoading(false);
  };

  //gold card status
  // const getGoldCard = async () => {
  //   setLoading(true);

  //   const response = await ApiHelperFunction({
  //     urlPath: "/get-profile",
  //     method: "GET",
  //   });

  //   console.log("responseeeee", response?.data);

  //   if (response.status === 200) {
  //     setGoldCard(response?.data?.data);
  //     // console.log("data get successfully");
  //   } else {
  //     // toast.error(response.message);
  //   }
  //   setLoading(false);
  // };

  // console.log("userDataasd", userData)

  // to show delete edit icon
  const showEditDeleIconMemo = useCallback((item) => {
    if (userData?.userType === "Admin" && item?.userType !== "Owner") {
      return true
    } else if (userData?._id === item?.userID) {
      return true
    } else if (userData?.userType ==="Owner") {
      return true
    }
    else {
      return false
    }

  }, [userData])

  // console.log(showEditDeleIconMemo, "showEditDeleIconMemo");

  useEffect(() => {
    dispatch(getAllPosts());
    // getGoldCard();
    // viewApprovedAdvocacy();
    dispatch(getFollowingData());
    dispatch(getFollowerData());
    // ViewAllfriendList();
    // ViewAllfollowingList();
  }, []);

  useEffect(() => {
    getAffinityPosts();
    // dispatch(getAllPosts());
    // dispatch(getAllAffinityPosts());
  }, [params?.id]);


  //for get comment
  const getComment = async () => {
    let data = {
      postID: commentId,
    };
    setCommentLoading(true);
    let urlPath = "/view-comment";
    let res = await ApiHelperFunction({ urlPath, method: "POST", data });
    // console.log("ResponseDATA", res?.data?.data?.replyData);
    if (res?.status) {
      setCommentData(res?.data?.data);
      // setReplyData(res?.data?.data?.replyData);
      setCommentLoading(false);
    } else {
      setCommentLoading(false)
      // toast.error(res?.data?.message || "Error Fetching User Details");
    }
  };


  // console.log("commentId", commentId)
  useEffect(() => {
    if (commentId) {
      getComment();
      // getReply();
    }
  }, [commentId])

  // useEffect(() => {
  //   setPostData(postDataReducer?.posts);
  // }, [postDataReducer?.posts]);
  // console.log("approvedAdvocacyData", approvedAdvocacyData)

  function Scrollany() {
    if (window.scrollY > 200) {
      setleftcontainmain(true)
    }
    else {
      setleftcontainmain(false)
    }
  }

  window.addEventListener("scroll", Scrollany)
  // const handleShowCommentClick=()=>{
  //   setShowComment(!showComment)
  // }

  const userValid = localStorage?.getItem("Authorization")
  useEffect(() => {
    if (userValid) {
      // console.log("fgklj754658yjkn35", userValid)
      // SocketConnect(userValid)
    }
  }, [userValid])

  return (
    <>
      {/* <section id="homepage_area">
        
      </section> */}
      {/* <div className="thought_area_mxh_main">
        
      </div> */}
      <section className="mainpagediv">
        <div className="homepagecontainer">

          {/* <div className="thought_area_mxh-left"> */}
          <div className={leftcontainmain ? "thought_area_mxh-left thought_area_mxh-leftpostionchng" : "thought_area_mxh-left"}>
            <BulletinBoard />
            <HallofFame />
            {
              userData?.userType === "Admin" || userData?.userType === "Owner"
                ?
                <Employees />
                :
                <div>
                  <button className="followerbtn" onClick={handleFollowerClick}>
                    Connections
                  </button>
                </div>
                // <Follower/>
            }

            <EventCard />
            <JobsReferred />
          </div>

          <div className="thought_area_mxh-mid">
            <ShareThoughts getAffinityPosts={getAffinityPosts} />
            <div className="postthoughts_area">
              {postDataReducer?.posts?.length > 0 ? (
                postDataReducer?.posts?.map((item, index) => {
                  // console.log("erty", item);
                  return (
                    <SaraTylor
                      index={index}
                      userID={item?.userID}
                      key={item?._id}
                      handleOptionClick={handleOptionClick}
                      setModalA={setModalA}
                      modalA={modalA}
                      modalB={modalB}
                      modalId={modalId}
                      isUserReacted={item?.isUserReacted}
                      postId={item?._id}
                      name={item?.firstName + " " + item?.lastName}
                      imagesArray={item}
                      createdOn={moment(item?.createdOn).format("YYYY-MM-DD")}
                      reaction={item?.totalReaction}
                      description={item?.description || item?.notes || item?.desc}
                      showDescription={item?.showDescription}
                      optionShow={showEditDeleIconMemo(item)}
                      setModalB={setModalB}
                      setModalId={setModalId}
                      modalK={modalK}
                      setModalK={setModalK}
                      changeShow={modalId === item?._id}
                      showComment={modalId === item?._id}
                      // showComment={handleShowCommentClick}
                      commentId={commentId}
                      setCommentId={setCommentId}
                      commentData={commentData}
                      setCommentData={setCommentData}
                      getComment={getComment}
                      commentLoading={commentLoading}
                      singleData={item}
                    />
                  );
                })
              ) : (
                <p>No post Found!</p>
              )}
            </div>
          </div>

          {/* {viewDetails && <AdvocacyDetailsModal closemodal={setViewDetails} initiativeData={initiativeData} />} */}

          <div className={leftcontainmain ? "thought_area_mxh_right thought_area_mxh-rightpostionchng" : "thought_area_mxh_right"}>
            <div className="eliteFigDiv" style={{ marginBottom: "1px" }}>
              {/* <figure className="eliteFig">
            <img src={eliteCard} alt="..." />
          </figure>
          <span className="goldCardText">GOLD CLUB</span> */}
              {/* <div className="goldClubDiv" style={{ backgroundColor: `${goldCard?.rewardCard?.[0]?.cardColor}` }}>
                <div className="goldInnerDiv">
                  <div>
                    <p className="eliteSubHead">Status</p>
                    <p className="goldClHead">{goldCard?.rewardCard?.[0]?.cardName}</p>
                  </div>
                  <figure className="goldIconFig">
                    <img src={eliteExp} alt="..." />
                  </figure>
                </div>
                <div className="cardNameDiv">
                  <div>
                    <p className="cardNumbText">{goldCard?.goldClubNo}</p>
                    <p className="sanjNameText">
                      {goldCard?.firstName} {goldCard?.lastName}
                    </p>
                  </div>
                  <p className="goldSmClub">Gold Club</p>
                  <figure className="goldIcoFigDiv">
                    <img src={goldIcon} alt="..." />
                  </figure>
                </div>
              </div> */}

              {/* {
                approvedAdvocacyData &&
                approvedAdvocacyData?.slice(0, 1)?.map((ele) => {
                  <div className="bulletin_board">
                    <div className="top">
                      <div class="head bulleBoardHead">
                        <figure class="bulleBoardFig">
                          <img src={Advocacy} alt="..." />
                        </figure>
                        <h4>Advocacy</h4>
                      </div>
                      <div>
                        <Link className="seeAllAn" to="/advocacy">
                          See All
                        </Link>
                      </div>
                    </div>
                    <div className="advcyimgdiv">
                      {
                        (ele?.image == "" || ele?.image == "image" || ele?.image == undefined) ? (<></>) : (<img src={ele?.image} alt="..." />)
                      }

                    </div>
                    <div className="advcytxtflx">
                      <div className="advcytxtpp">
                        <p>{ele?.description}</p>
                      </div>
                      <div className="">
                        <Link className="viewbtnnn" to="/advocacy">View</Link>
                      </div>
                    </div>

                  </div>
                })
              } */}
              {/* {
                advoLoading ? (<p>...</p>) : ( */}
              {/* <div className="bulletin_board">
                <div className="top">
                  <div class="head bulleBoardHead">
                    <figure class="bulleBoardFig">
                      <img src={Advocacy} alt="..." />
                    </figure>
                    <h4>Advocacy</h4>
                  </div>
                  <div>
                    <Link className="seeAllAn" to="/advocacy">
                      <figure className="bulleBoardFig">
                        <img src={see_all} alt="..." />
                      </figure>
                    </Link>
                  </div>
                </div>
                <div className="advcyimgdiv">

                  <img src={approvedAdvocacyData?.[0]?.image} style={{
                    width: `${approvedAdvocacyData?.[0]?.image === 1 ? "100%" : ""
                      }`,
                    height: `${approvedAdvocacyData?.[0]?.image === 0 ? "100%" : ""
                      }`,
                  }} alt="..." />
                </div>
                <div className="advcytxtflx">
                  <div className="advcytxtpp">
                    <p>{approvedAdvocacyData?.[0]?.description}</p>
                  </div>
                  <div className="">
                    <Link className="viewbtnnn" onClick={() => handleInitiativeClick(approvedAdvocacyData?.[0])}>View</Link>
                  </div>
                </div>

              </div> */}
              {/* )
              } */}
              {/* // <div className="bulletin_board">
              //   <div className="top">
              //     <div class="head bulleBoardHead">
              //       <figure class="bulleBoardFig">
              //         <img src={Advocacy} alt="..." />
              //       </figure>
              //       <h4>Advocacy</h4>
              //     </div>
              //     <div>
              //       <Link className="seeAllAn" to="/advocacy">
              //         See All
              //       </Link>
              //     </div>
              //   </div>
              //   <div className="advcyimgdiv">
              //     <img src={approvedAdvocacyData?.[0]?.image} alt="..." />
              //   </div>
              //   <div className="advcytxtflx">
              //     <div className="advcytxtpp">
              //       <p>{approvedAdvocacyData?.[0]?.description}</p>
              //     </div>
              //     <div className="">
              //       <Link className="viewbtnnn" to="/advocacy">View</Link>
              //     </div>
              //   </div>

              // </div> */}

            </div>
            <div className="">

              {/* <TabList>
                  <Tab>Initiative</Tab> 
                  <Tab>Sustainable</Tab>
                </TabList> */}

              {/* <TabPanel> */}
              {/* <Initiative /> */}
              {/* </TabPanel> */}
              <div className="satuscarddivpading">
                <Statuscard />
              </div>
              <div className="iniSusTabDiv">
                <Initiative />
              </div>

              {/* <GoalAchiver /> */}

            </div>
            {/* <TrainingCard /> */}
            <div className="myAffitiDiv">
              <HubAffinityGrp />
            </div>
          </div>

        </div>


      </section>
      <Chatbot />

      {/*  ************************ All MODALS * ********************/}
      {modalK && (
        <HallOfFrameModal
          closemodal={setModalK}
          activity={"halloffame"}
          initialValues={initialValues5}
        />
      )}

      {
        modalO && <EditHallOfFame closemodal={setModalO}
          activity={"editHallFame"}
          initialValues={HallfameSingleData1} />
      }

      {modalL && (
        <MyAffinityGroupModal
          closemodal={setModalL}
          activity={"affinity"}
          initialValues={initialValues7}
        />
      )}

      {modalM && (
        <InitiativeModal
          closemodal={setModalM}
          activity={"initiative"}
          initialValues={initialValues6}
        />
      )}

      {modala && (
        <BillBoardModal
          closemodal={setModala}
          activity={"billBoard"}
          initialValues={initialValues}
        />
      )}

      {modalC && (
        <KababMenu
          closemodal={setModalC}
          activity={"kababMenu"}
          BulletinBoardSingleData={BulletinBoardSingleData}
          id={modalId}
          initialValues={BulletinBoardSingleData}
        />
      )}

      {modalD && (
        <GoalAchiverModal
          closemodal={setModalD}
          activity={"goalAchiver"}
          initialValues={initialValues4}
        />
      )}

      {modalE && (
        <CreateModal
          closemodal={setModalE}
          activity={"editGoalAchiver"}
          BulletinBoardSingleData={BulletinBoardSingleData}
          id={modalId}
          initialValues={BulletinBoardSingleData}
        />
      )}

      {modalF && (
        <EventsModal
          closemodal={setModalF}
          activity={"events"}
          initialValues={initialValues}
        />
      )}

      {modalG && (
        <EditEventModal
          closemodal={setModalG}
          activity={"editEvent"}
          initialValues={BulletinBoardSingleData1}
        />
      )}

      {modalH && (
        <TrainingModal
          closemodal={setModalH}
          activity={"training"}
          initialValues={initialValues2}
        />
      )}

      {modalI && (
        <EditTrainingModal
          closemodal={setModalI}
          activity={"editTraining"}
          initialValues={trainingData}
        />
      )}

      {modalJ && (
        <JobRefferedModal
          closemodal={setModalJ}
          activity={"jobReffered"}
          initialValues={initialValues3}
        />
      )}

      {employeeModal && (
        <EmployeeModal
          closemodal={setEmployeeModal}
          activity={"events"}
          initialValues={initialValues}
        />
      )}
    </>
  );
}
