import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import notiImg from "../../Images/notiImg.png";
import moment from 'moment';
import JobNotificationSingle from './JobNotificationSingle';

const JobNotificationActual = ({ values, title, isAdminApproved }) => {
    // console.log("valueee", isAdminApproved)
    const navigate = useNavigate();
    const [modalA, setModalA] = useState(false);

    const openNotiModal = () => {
        setModalA(true);
    };
    
    return (
        <>
            {/* {
                value?.map((item, i) => {
                    return (
                        <> */}
            <div
                className="notiActualImgDiv"
                // style={
                //   activeY
                //     ? { backgroundColor: "#EDF0FF" }
                //     : { backgroundColor: "white" }
                // }
                // onClick={openNotiModal}
                onClick={() => {
                    let data = {
                        values: values,
                        isAdminApproved: isAdminApproved
                    }
                    navigate(`/jobNotificationSingle`, { state: { data } })
                }}
            >
                <figure className="notiPersonImg">
                    <img src={notiImg} alt="..." style={{ width: "45px", height: "45px" }} />

                </figure>
                <div>
                    <div>

                        <Link className="notiInnerMainHead" style={{ fontSize: "15px" }}>{title}</Link>
                        {/* <p>{item?.jobTitle}</p> */}

                    </div>
                    <div>

                        <span className="hourTimeNoti">
                            {moment(new Date(values?.createdOn))?.fromNow()}
                        </span>

                    </div>
                </div>
            </div>
            {/* </>
                    )
                })
            } */}

            {/* {modalA && (
                <JobNotificationSingle
                    closeSingleModal={setModalA}
                    //   id={id}
                    //   advocacyPostId={advocacyPostId}
                    //   title={title}
                    //   description={description}
                    //   image={image}
                    //   advocacyPostedBy={advocacyPostedBy}
                    //   userFirstName={userFirstName}
                    //   userLastName={userLastName}
                    //   isAdminApproved={isAdminApproved}
                    //   groupId={groupId}
                    //   requestId={requestId}
                    //   userID={userID}
                    //   createdOn={createdOn}
                    values={values}
                />
            )} */}

        </>
        // <div style={{ cursor: "pointer" }} onClick={() => navigate(`/jobNotificationSingle/${value?._id}`)}>JobNotificationActual</div>
    )
}

export default JobNotificationActual