import React from 'react';
import "./Training.css"
import Educatetrainingard from "../../View/TrainingPage/Educatetrainingard";
import Mandatory from "../../View/TrainingPage/Mandatory";
import Skilldevelopment from "../../View/TrainingPage/Skilldevelopment";
import Microtrainig from "../../View/TrainingPage/Microtrainig";
import Ongoing from './Ongoing';
import Requestedtraining from './Requestedtraining';
import DocumentRepositorymain from './DocumentRepositorymain';
import { useNavigate } from 'react-router-dom';
import Nontechnicaltrainig from './Nontechnicaltrainig';
import Statuscard from '../../Component/Statuscard';
import { useAuthCtx } from '../../context/AuthCtx';
import Addicon from "../../Images/headericon/Addicon.png"
import Physicaltrainig from './Physicaltraining';
import Chatbot from '../../Component/Chatbot/Chatbot';

const Trainingmain = () => {
    const { userData } = useAuthCtx();
    const navigate = useNavigate()
    const documentrepoformClick = () => {
        navigate("/createdocumentform")
    }
    const handleDetailsClick = () => {
        if (userData?.userType === "Admin" || userData?.userType === "Owner") {
            navigate('/trainingCreate')
        } else if (userData?.userType === "Manager") {
            navigate('/trainingCreateManager')
        } else {
            navigate('/trainingCreateEmployee')
        }

    }

    return (
        <>

            <section className='educatemain'>
                <div className='employeepagecontainer'>
                    <div className='educatemainflx'>
                        <div className='educatemainleft'>

                            <div className='educatemainthreedivs'>
                                <div className=''>
                                    <p class="mandatorttxtnewlihgjtxtpp">Newly Added</p>
                                </div>
                                <Educatetrainingard />
                            </div>
                            <div className='mandatorycoursemaindiv'>
                                <Mandatory />
                            </div>
                            {/* <div className='mandatorycoursemaindiv'>
                                <Skilldevelopment />
                            </div>
                            <div className='mandatorycoursemaindiv'>
                                <Microtrainig />
                            </div>
                            <div className='mandatorycoursemaindiv'>
                                <Nontechnicaltrainig />
                            </div> */}
                            <div className='mandatorycoursemaindiv'>
                                <Physicaltrainig />
                            </div>
                        </div>
                        <div className='educatemainright'>
                            {/* <EducateStatusCard /> */}
                            <div className='statusdivbgdivnm'>
                                <Statuscard />
                            </div>

                            <div className=''>
                                <div className=''>
                                    {
                                        (userData?.userType === "Admin" || userData?.userType === "Manager" || userData?.userType === "Owner")
                                            ?
                                            (<button className='advocacycreatemaindiv' onClick={handleDetailsClick}>
                                                <div className='addimgdiv'>
                                                    <img src={Addicon} alt="..." />
                                                </div>
                                                <p className='crtadvocacytxtypp'>Create New Training</p>
                                            </button>)
                                            :
                                            (<button className='advocacycreatemaindiv' onClick={handleDetailsClick}>
                                                <div className='addimgdiv'>
                                                    <img src={Addicon} alt="..." />
                                                </div>
                                                <p className='crtadvocacytxtypp'>Create New Training</p>
                                            </button>)
                                    }

                                </div>
                            </div>

                            {
                                userData?.userType !== "Owner" &&
                                <div className=''>
                                    <Ongoing />
                                </div>
                            }
                            {/* <div className=''>
                                <button className='createbtnnewdiv' >Request New Training</button>
                            </div> */}

                            {/* {
                                userData?.userType !== "Employee" ? (<></>) : (<div className=''> */}
                            <Requestedtraining />
                            {/* </div>)
                            } */}

                            {
                                userData?.userType === "Admin" || userData?.userType === "Manager"
                                    ?
                                    (
                                        <div className=''>
                                            {/* <button className='createbtnnewdiv' onClick={documentrepoformClick}>Create Documents Repository </button> */}
                                            <button className='advocacycreatemaindiv' onClick={documentrepoformClick}>
                                                <div className='addimgdiv'>
                                                    <img src={Addicon} alt="..." />
                                                </div>
                                                <p className='crtadvocacytxtypp'>
                                                    Create  Documents Repository
                                                </p>
                                            </button>
                                        </div>
                                    )
                                    :
                                    (<></>)
                            }


                            <div className=''>

                                <DocumentRepositorymain />
                            </div>


                        </div>
                    </div>
                </div>
            </section>
            <Chatbot />
        </>
    )
}

export default Trainingmain
