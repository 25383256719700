import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Hub/Hub.css";
import { useDispatch, useSelector } from "react-redux";
import { getAffinityGroups, getMyAffinityGroups } from "../../redux/slices/affinitySlice";
import profilePic from "../../Images/profile_img1.png";
import { useAuthCtx } from "../../context/AuthCtx";
import { toast } from "react-hot-toast";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import MyAffinityGroupModal from "../../Component/Modal/MyAffinityGroupModal";
import AffinityGroupIcon from "../../Images/Icons/PNG/AffinityGroupIcon.png"
import add_icon from "../../Images/Icons/PNG/Add icon.png"
import Edit from "../../Images/headericon/Edit.png";
import Delete from "../../Images/headericon/Delete.png";
import EditAffinityModal from "../../SeprateModal/EditAffinityModal";

function MyAffinityMyGroupsDetails() {
  const {
    userData,
    modalL,
    setModalL,
    setLoading,
  } = useAuthCtx();
  const [modalB, setModalB] = useState(false);
  const [modalId, setModalId] = useState("");
  const [modalA, setModalA] = useState(false);
  const navigate = useNavigate();
  const [myaffinityData, setMyAffinityData] = useState({});
  const [top, setTop] = useState(null);
  const [left, setLeft] = useState(null);
  const [goldCard, setGoldCard] = useState([]);
  const dispatch = useDispatch();
  const affinityData = useSelector((state) => state?.affinitySlice?.myGroups);

  const initialValues7 = {
    groupName: "",
    grouptype: "",
    charter: "",
    purpose: "",
  };

  //for navigate single page
  const handleClick = (item) => {
    navigate(`/affinity/${item?._id}`);
  };

  //get details for gold card 
  const getGoldCard = async () => {
    setLoading(true);

    const response = await ApiHelperFunction({
      urlPath: "/get-profile",
      method: "GET",
    });

    console.log("responseeeee", response?.data);

    if (response.status === 200) {
      setGoldCard(response?.data?.data);
      // console.log("data get successfully");
    } else {
      // toast.error(response?.message);
    }
    setLoading(false);
  };

  //for delete functionality
  const handleDelete = async () => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-affinity-group/${modalId}`,
      method: "PUT",
    });
    if (res?.status) {
      toast.success(res?.data?.message);
      dispatch(getMyAffinityGroups());
      setModalA(false);
    } else {
      toast.error(res?.message || "Something went wrong");
      // console.log("ERROR CREATING USER3", res);
    }
  }

  //for handle edit delete
  const handleEditDeleteClick = (e, ele) => {
    setModalId(ele._id);
    setMyAffinityData(ele);
    setTop(e.clientY);
    setLeft(e.clientX);
    setModalA(!modalA);
  }

  //   const handleJoinClick = async (item) => {
  //     const data = {
  //       // name: groupName,
  //       groupId: item?._id,
  //     };
  //     const response = await ApiHelperFunction({
  //       urlPath: `/join-group`,
  //       method: "POST",
  //       data,
  //     });

  //     if (response.status === 200) {
  //       toast.success(response?.data?.message);
  //       // dispatch(getAffinityGroups());
  //       // setGroupName("");
  //     } else {
  //       toast.error(response?.response?.data?.message);
  //     }
  //   };

  const createModal = () => {
    setModalL(true);
  };

  useEffect(() => {
    // dispatch(getAffinityGroups());
    dispatch(getMyAffinityGroups());
    getGoldCard();
  }, []);
  return (
    <>
      {/* Sidebar */}

      {/* body */}
      <section className="bulleDetail">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="myafinitydtls">
                <div className="hubAffityDiv">
                  <div className="myAffSeeAllBtnDiv">
                    <div className="AffinityGroupIcondivimgtxtflx">
                      <div className="AffinityGroupIcondivimg">
                        <img src={AffinityGroupIcon} alt="..." />
                      </div>
                      <p className="myAffityHead">My Affinity Groups</p>
                      {userData?.userType === "Admin" || userData?.userType === "Manager" || userData?.userType === "Owner" ? (
                        <div className="tyEvMainDiv" onClick={createModal}>
                          <figure className="bulleBoardFig">
                            <img src={add_icon} alt="..." />
                          </figure>
                          {/* <p className="tyEvText">Add Group</p> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <Link className="backHomeBtn" onClick={() => navigate(-1)}>
                        <i class="fa-solid fa-arrow-left" style={{ color: "#000000" }}></i>
                        {/* Back */}
                      </Link>
                    </div>
                  </div>
                  <div
                    className=" myevetCresParentDiv"
                  // style={{ overflow: "auto", height: "647px" }}
                  >
                    {affinityData?.length > 0 ? (
                      affinityData?.map((item, index) => {
                        // console.log("itemcvdf", item)
                        return (
                          <div
                            className="evetCreMainDivdtls"
                          // style={{
                          //   backgroundColor: "#F1E5DD",
                          //   cursor: "pointer",
                          // }}

                          >
                            <div className="myallgrpdtlsv">
                              <div className="myimgdiv">
                                {item?.image === "image" ? (
                                  <img src={profilePic} alt="" />
                                ) : (
                                  <img
                                    className="img-fluid"
                                    src={item?.image}
                                    alt="..."
                                  // style={{
                                  //   width: "100px",
                                  //   height: "100px",
                                  //   borderRadius: "50%",
                                  // }}
                                  />
                                )}
                              </div>
                              {/* <img
                              className="img-fluid"
                              src={item?.image}
                              alt="..."
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%",
                              }}
                            /> */}

                              {
                                userData?._id === item?.gpp?.[0]?.addedBy || userData?.userType==="Owner" ? 
                                (<div className="threedotskll" onClick={(e) => handleEditDeleteClick(e, item, index)}>
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                  {modalId === item?._id && modalA && (
                                    <div className="deleteeditdivbg">
                                      <div className="editdivimgmaini" onClick={() => setModalB(true)}>

                                        <img src={Edit} alt="..." />
                                      </div>
                                      <div className="editdivimgmaini">

                                        <img src={Delete} alt="..." onClick={handleDelete} />
                                      </div>

                                    </div>
                                  )}
                                </div>)
                                :
                                <></>
                              }


                              <p className="hubEvCrePara" style={{ cursor: "pointer" }} onClick={() => handleClick(item)}>{item?.groupName}</p>
                              {/* <div className="hubMyAfFigsDiv">
                              <figure className="hubMyAfFig hubMyAfFig1">
                                <img src={afiPic1} alt="..." />
                              </figure>
                              <figure className="hubMyAfFig hubMyAfFig2">
                                <img src={afiPic2} alt="..." />
                              </figure>
                              <figure className="hubMyAfFig hubMyAfFig3">
                                <img src={afiPic3} alt="..." />
                              </figure>
                              <figure className="hubMyAfFig hubMyAfFig4">
                                <img src={afiPic4} alt="..." />
                              </figure>
                            </div> */}

                              <div className="nameGrpMemDiv">
                                {/* <p className="nameGrpMemText">
                                  Name of Group Members:
                                </p>
                                <select className="nameGrpSelect">
                                  {item?.groupMember?.map((member, index) => (
                                    <option>{`${member?.name}`}</option>
                                  ))}
                                </select> */}
                              </div>
                            </div>
                            <div className="divctrtparadiv">
                              <div className="divparacrema">
                                {/* <div className="eveCreAfisDiv"> */}
                                {/* <img src={item?.image} /> */}
                                <p className="myAffCrePara">
                                  Created by {item?.userType}
                                </p>
                                {/* </div> */}

                                {/* <button className="myAffCreChatBtn">
                              <i class="fa-regular fa-comment-dots"></i>
                            </button> */}

                                {/* <figure className="chatIconFig">
                              <img src={chatIcon} alt="..." />
                            </figure> */}

                                {/* {userData?.userType === "Admin" ||
                            userData?.userType === "Manager" ? (
                              <RWebShare
                                data={{
                                  text: `${item?.groupName}`,
                                  // url: "",
                                  title: "Share group",
                                }}
                                onClick={() =>
                                  console.log("shared successfully!")
                                }
                              >
                                <div className="share">
                                  <img
                                    src={table_icon}
                                    alt="..."
                                    className="shareIconImg"
                                  />
                                  <span></span>
                                </div>
                              </RWebShare>
                            ) : (
                              <button
                                className="jnbtn d-none"
                                onClick={() => handleJoinClick(item)}
                              >
                                Join
                              </button>
                            )} */}
                              </div>

                              <div className="divparacrema">
                                <p className="myAffCrePara">
                                  Type : {item?.grouptype}
                                </p>
                              </div>
                              <div className="divparacrema">
                                <p className="myAffCrePara">
                                  Charter : {item?.charter}
                                </p>
                              </div>
                              <div className="divparacrema">
                                <p className="myAffCrePara">
                                  Purpose : {item?.purpose}
                                </p>
                              </div>
                            </div>
                            {/* <div className="myAffTypeBtnInpDiv">
                            <input
                              type="text"
                              placeholder="Type your message"
                              className="myAffChatTypeInp"
                            />
                            <div className="myAffChatTypeBtn">
                              <img src={chatSend} alt="..." />
                            </div>
                          </div> */}

                          </div>
                        );
                      })
                    ) : (
                      <p>No Groups to show!</p>
                    )}

                    {/* <div
                    className="evetCreMainDiv"
                    style={{ backgroundColor: "#F1E5DD" }}
                  >
                    <div className="eveCreAfisDiv">
                      <p className="hubEvCrePara">Event Creator</p>
                      <div className="hubMyAfFigsDiv">
                        <figure className="hubMyAfFig hubMyAfFig1">
                          <img src={afiPic1} alt="..." />
                        </figure>
                        <figure className="hubMyAfFig hubMyAfFig2">
                          <img src={afiPic2} alt="..." />
                        </figure>
                        <figure className="hubMyAfFig hubMyAfFig3">
                          <img src={afiPic3} alt="..." />
                        </figure>
                        <figure className="hubMyAfFig hubMyAfFig4">
                          <img src={afiPic4} alt="..." />
                        </figure>
                      </div>
                    </div>
                    <div className="myAffCreBtnsDiv">
                      <p className="myAffCrePara">Created by Admin</p>
                      <button className="myAffCreChatBtn">
                        <i class="fa-regular fa-comment-dots"></i>
                      </button>
                    </div>
                    <div className="myAffTypeBtnInpDiv">
                      <input
                        type="text"
                        placeholder="Type your message"
                        className="myAffChatTypeInp"
                      />
                      <button className="myAffChatTypeBtn">
                        <i class="fa-solid fa-paper-plane"></i>
                      </button>
                    </div>
                  </div> */}
                    {/* <div
                    className="evetCreMainDiv"
                    style={{ backgroundColor: "#F1F1D4" }}
                  >
                    <div className="eveCreAfisDiv">
                      <p className="hubEvCrePara">Marketing & Sales</p>
                      <div className="hubMyAfFigsDiv">
                        <figure className="hubMyAfFig hubMyAfFig1">
                          <img src={afiPic1} alt="..." />
                        </figure>
                        <figure className="hubMyAfFig hubMyAfFig2">
                          <img src={afiPic2} alt="..." />
                        </figure>
                        <figure className="hubMyAfFig hubMyAfFig3">
                          <img src={afiPic3} alt="..." />
                        </figure>
                        <figure className="hubMyAfFig hubMyAfFig4">
                          <img src={afiPic4} alt="..." />
                        </figure>
                      </div>
                    </div>
                    <div className="myAffCreBtnsDiv">
                      <p className="myAffCrePara">Created by Admin</p>
                      <button className="myAffCreChatBtn">
                        <i class="fa-regular fa-comment-dots"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    className="evetCreMainDiv"
                    style={{ backgroundColor: "#E8E9FF" }}
                  >
                    <div className="eveCreAfisDiv">
                      <p className="hubEvCrePara">Leaders Group</p>
                      <div className="hubMyAfFigsDiv">
                        <figure className="hubMyAfFig hubMyAfFig1">
                          <img src={afiPic1} alt="..." />
                        </figure>
                        <figure className="hubMyAfFig hubMyAfFig2">
                          <img src={afiPic2} alt="..." />
                        </figure>
                        <figure className="hubMyAfFig hubMyAfFig3">
                          <img src={afiPic3} alt="..." />
                        </figure>
                        <figure className="hubMyAfFig hubMyAfFig4">
                          <img src={afiPic4} alt="..." />
                        </figure>
                      </div>
                    </div>
                    <div className="myAffCreBtnsDiv">
                      <p className="myAffCrePara">Created by Admin</p>
                      <button className="myAffCreChatBtn">
                        <i class="fa-regular fa-comment-dots"></i>
                      </button>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {modalL && (
        <MyAffinityGroupModal
          closemodal={setModalL}
          activity={"affinity"}
          initialValues={initialValues7}
        />
      )}

      {modalB && (
        <EditAffinityModal
          closemodal={setModalB}
          activity={"editAffinity"}
          // myaffinityData={myaffinityData}
          id={modalId}
          initialValues={myaffinityData}
        />
      )}
    </>
  );
}

export default MyAffinityMyGroupsDetails;
