import React from 'react'
import seeall from "../../Images/Icons/PNG/See all icon.png";
import Onboarding from "../../Images/Icons/PNG/Onboarding.png"
import { Link } from 'react-router-dom';
import ExpandGradient from "../../Images/headericon/ExpandGradient.png"
const Onboardingcard = () => {
    return (
        <>
            <div className="bulletin_board" id="eventsBulle">
                <div className="top">
                    <div className="head bulleBoardHead">
                        {/* <figure className="bulleBoardFig">
                            <img
                                src={Onboarding}
                                alt="..."
                            />
                        </figure> */}
                        <h4>Onboarding</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv">
                            <figure className="bulleBoardFig">
                                {/* <img
                                    src="/static/media/Add icon.ed5715eb88ab4afb9efc.png"
                                    alt="..."
                                /> */}
                            </figure>
                        </div>
                        <div>
                            <Link className="seeAllAn" to="/netWorking">
                                <figure className="bulleBoardFig">
                                    <img
                                        src={ExpandGradient}
                                        alt="..."
                                    />
                                </figure>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className=''>
                        <div className='bottomdivbgonbrdtx'>
                            New Hire Survey - 30 Days
                        </div>
                        <div className='bottomdivbgonbrdtx'>
                            New Hire Survey - 60 Days
                        </div>
                        <div className='bottomdivbgonbrdtx'>
                            New Hire Survey - 90 Days
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Onboardingcard
