import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { TabPanel, Tabs } from 'react-tabs';
import see_all from "../../Images/Icons/PNG/See all icon.png"
import { useDispatch, useSelector } from 'react-redux';
import { getSaveTraining } from '../../redux/slices/jobRecruitmentSlice';
import { useAuthCtx } from '../../context/AuthCtx';
import moment from 'moment';
import ExpandGradient from "../../Images/headericon/ExpandGradient.png"
const Savetrainningjob = () => {
    const navigate = useNavigate();
    const { userData } = useAuthCtx();
    const savetraining = useSelector((state) => state?.jobRecruitmentSlice?.savetraining)
    const dispatch = useDispatch();
    // console.log("getSaveTraining", savetraining);
    useEffect(() => {
        dispatch(getSaveTraining())
    }, [])
    return (
        <>
            <section className="hubAffityDivmainsection">
                <div className="hubAffityDiv">
                    <div className="myAffSeeAllBtnDiv">
                        <div className="jntxtimgflx">
                            {/* <div className="initaimg">
                                <img src={AffinityGroupIcon} alt="..." />
                            </div> */}
                            <p className="hobbyAspHead">Save Items</p>
                        </div>
                        <div className="add_seeallflx">
                            {/* 
                            <figure className="bulleBoardFig">
                                <img src={add_icon} alt="..." />
                            </figure> */}
                            <div className="myAffSeeAllBtnInnerDiv" style={{ cursor: "pointer" }} onClick={() => navigate('/saveTraining')}>
                                <div
                                    className="myAffSeeAllBtn">
                                    <figure className="bulleBoardFig" >
                                        <img src={ExpandGradient} alt="..." />
                                    </figure>
                                </div>
                            </div>

                        </div>


                    </div>


                </div>


                <div className="bottom">
                    <div className="myAffinityGrpsDiv">
                        <Tabs>
                            {/* <TabList>
                                <Tab> Hiring</Tab>
                                <Tab> Training</Tab>
                            </TabList> */}

                            {/* <TabPanel>
                                <div className='mainafinitydivbtm'>
                                    <div className="mainafinitydiv">
                                        <div
                                            style={{
                                                padding: "6px 10px 9px",
                                                borderBottom: "1px solid rgb(181, 255, 210)"
                                            }}
                                        >
                                            <div className="top">
                                                <div
                                                    className="head"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        width: "100%"
                                                    }}
                                                >
                                                    <h4 className='jobtstgyuiopp'>job2</h4>
                                                    <div>
                                                        <div className="share">
                                                            <img
                                                                src={Share}
                                                                alt="..."
                                                                className="shareIconImg"
                                                            />
                                                            <span />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="details detailsjobsvaetxtt">
                                                <p />
                                                <p />
                                                <p>Opening: 50</p>
                                                <p>Skill Required:javaScriptsql</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mainafinitydiv">
                                        <div
                                            style={{
                                                padding: "6px 10px 9px",
                                                borderBottom: "1px solid rgb(181, 255, 210)"
                                            }}
                                        >
                                            <div className="top">
                                                <div
                                                    className="head"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        width: "100%"
                                                    }}
                                                >
                                                    <h4 className='jobtstgyuiopp'>job2</h4>
                                                    <div>
                                                        <div className="share">
                                                            <img
                                                                src={Share}
                                                                alt="..."
                                                                className="shareIconImg"
                                                            />
                                                            <span />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="details">
                                                <p />
                                                <p />
                                                <p>Opening: 50</p>
                                                <p>Skill Required:javaScriptsql</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mainafinitydiv">
                                        <div
                                            style={{
                                                padding: "6px 10px 9px",
                                                borderBottom: "1px solid rgb(181, 255, 210)"
                                            }}
                                        >
                                            <div className="top">
                                                <div
                                                    className="head"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        width: "100%"
                                                    }}
                                                >
                                                    <h4 className='jobtstgyuiopp'>job2</h4>
                                                    <div>
                                                        <div className="share">
                                                            <img
                                                                src={Share}
                                                                alt="..."
                                                                className="shareIconImg"
                                                            />
                                                            <span />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="details">
                                                <p />
                                                <p />
                                                <p>Opening: 50</p>
                                                <p>Skill Required:javaScriptsql</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </TabPanel> */}
                            <TabPanel>
                                <div className='mainafinitydivbtm'>
                                    {
                                        savetraining?.slice(0,3)?.map((item, index) => {
                                            return (
                                                <>
                                                    {
                                                        userData?._id === item?.addedBy && (
                                                            <div
                                                                className="card_areatraining"
                                                                style={{ position: "relative", }} key={index}
                                                            >
                                                                <div className="image_areatraingkhj">
                                                                    <img
                                                                        src={item?.savedData?.[0]?.image}
                                                                        className="img-fluid"
                                                                        alt="event"
                                                                    />
                                                                </div>
                                                                <div className="details hjhjdshduikn">
                                                                    <div className="up">
                                                                        <div className="name">
                                                                            <p className='hhghihxjggimghsvh'>{item?.savedData?.[0]?.title || item?.savedData?.[0]?.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="date">
                                                                        <p className='datetrainghtxt'>Date: {moment(item?.savedData?.[0]?.createdOn)?.format('YYYY-MM-DD')}</p>
                                                                    </div>
                                                                    {/* <div className="time">
                                                                <p className='datetrainghtxt'>Training Cost : Rs.800</p>
                                                            </div> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        })
                                    }



                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>

            </section>


        </>
    )
}

export default Savetrainningjob
