import React from "react";
import { RingLoader } from "react-spinners";

const MainLoader = ({ isLoading }) => {
  return (
    <div className="loader-header">
      
    </div>
  );
};

export default MainLoader;
