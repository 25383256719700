import React, { useEffect, useState } from 'react'
import Ongoing from './Ongoing'
import EducateStatusCard from './EducateStatusCard'
import trainingmain from "../../Images/trainingmain.png"
import StandardOperatingProsedure from "./StandardOperatingProsedure"
import star from "../../Images/Icons/PNG/star.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward.png";
import MandatoryCousremodal from '../../Component/Modal/MandatoryCousremodal'
import { getOnboardingTraining } from '../../redux/slices/jobRecruitmentSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'


const SingleMandatoryCourse = () => {
    const location = useLocation();
    const mandatory = location?.state?.trainingDetails;
    const rewardPoint= location?.state?.rewardPoint
    // const mandatory = useSelector((state) => state?.jobRecruitmentSlice?.training)
    const [singleMandatory, setSingleMandatory] = useState({});
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(getOnboardingTraining())
    // }, [])
    const [videotraing, setVideotraing] = useState(false)

    const handlevideoClick = (item) => {
        setSingleMandatory(item)
        setVideotraing(!videotraing)
    }
    console.log("mandatory",mandatory)
    return (
        <>
            <section className='singlemandatorysection'>
                <div className='employeepagecontainer'>
                    <div className='singleeducatemainflx'>
                        <div className='singlemandatoryright'>
                            <div className='singlemandatorydivbg'>
                                <div className='mandatorydivbgflxdiv'>
                                    {mandatory?.map((item, index) => {
                                        return (
                                            <div className='mandatorydivbg mandatorywidthdiv' key={index} onClick={() => handlevideoClick(item)}>
                                                {
                                                    (item?.image === "images" || item?.image === "" || item?.image === "image") ? (<div className='traingmaindivmain'>
                                                        <img src={trainingmain} alt="..." />
                                                    </div>) : (<div className='traingmaindivmain'>
                                                        <img src={item?.image} alt="..." />
                                                    </div>)
                                                }
                                                <div className=''>
                                                    <p className='mandttxtpp'>
                                                        {item?.description}
                                                    </p>
                                                    <p className='writertxtpp'>{item?.userName}</p>
                                                </div>
                                                <div className="imgtxtdivmaindddflx">
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgdiv">
                                                            <img
                                                                src={star}
                                                                alt="..."
                                                            />
                                                        </div>
                                                        <p className="tsgyutppp">{item.rating ? (<>{item.rating}</>) : ("0")}</p>
                                                    </div>
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgdiv">
                                                            <img
                                                                src={clock}
                                                                alt="..."
                                                            />
                                                        </div>
                                                       
                                                        {item?.totalDurationInMinute} min
                                                    </div>
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgfrmt">
                                                            <img
                                                                src={reward}
                                                                alt="..."
                                                            />
                                                        </div>
                                                        <p className="tsgyutppp">{rewardPoint}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>

                            </div>
                        </div>
                        <div className='singlemandatoryleft'>
                            <div className=''>
                                <EducateStatusCard />
                            </div>
                            <div className=''>
                                <Ongoing />
                            </div>
                            <div className=''>
                                <StandardOperatingProsedure />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {videotraing && <MandatoryCousremodal closemodal={setVideotraing} singleMandatory={singleMandatory} rewardPoint={rewardPoint}/>}
        </>
    )
}

export default SingleMandatoryCourse

