import React from 'react'
import { useSelector } from 'react-redux'

const ComDetailsModal = ({ closemodal, CompanyData }) => {
  const cdetails = useSelector((state) => state?.bioSlice?.cdetails)

    return (
        <div className='viedetailsmain' >
            <div className='viewdetailscontent'>
                <div className='bulletintextclose'>
                    <p className='newBulleBrodHead'>Company details</p>
                    <div className='closedivred' onClick={() => closemodal()}>
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>
                {/* <div className='closemarkdst'><i class="fa-solid fa-xmark"></i></div> */}
                <div className=''>
                    <div className="details">
                        <p className="biodetailstxtopnop">
                            name of the company : <span>{cdetails?.companyName}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            Numbers of employees : <span>{cdetails?.employeeStrenght}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            local address  : <span>{cdetails?.address}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            country  : <span>{cdetails?.country}</span>
                        </p>
                        {/* <p className="biodetailstxtopnop">
                            Education : <span>{CompanyData?.education}</span>
                        </p> */}
                        <p className="biodetailstxtopnop">
                            City : <span>{CompanyData?.city}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            State : <span>{CompanyData?.state}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            Type of Industry : <span>{CompanyData?.typeOfIndustry}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            About : <span>{CompanyData?.about}</span>
                        </p>

                        <p className="biodetailstxtopnop">
                            Phone No.: <span>{CompanyData?.phoneNoOne}</span> {CompanyData?.phoneNoTwo !== null && <span>/{CompanyData?.phoneNoTwo}</span>}{CompanyData?.phoneNoThree !== null && <span>/{CompanyData?.phoneNoThree}</span>}
                        </p>

                        <p className="biodetailstxtopnop">
                            E-mail: <span>{CompanyData?.emailIdOne}</span> {CompanyData?.emailIdTwo !== "" && <span>/{CompanyData?.emailIdTwo}</span>}{CompanyData?.emailIdThree !== "" && <span>/{CompanyData?.emailIdThree}</span>}
                        </p>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default ComDetailsModal