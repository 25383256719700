import React, { useState } from 'react'
import {useNavigate } from 'react-router-dom';
import Eventimg2 from "../../Images/No-Image-Placeholder.png";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Fllowmodal from './Fllowmodal';
import { useAuthCtx } from '../../context/AuthCtx';
import { useSelector } from 'react-redux';

const Follower = () => {
    const following = useSelector((state) => state?.badgeSlice?.following);
    const followerList = useSelector((state) => state?.badgeSlice?.follower);
    const { userData } = useAuthCtx();
    const [followe, setFollowe] = useState(false)
    const [followin, setFollowin] = useState({})
    const navigate = useNavigate();
    const followonclick = (item) => {
        setFollowe(!followe);
        setFollowin(item)
    }

    // const followeronclick = (item) => {
    //     setFollowe(!followe);
    //     setFollower(item);
    // }


    //for following message
    const handleMessageClick = (item) => {
        const data = {
            fId: item?.fId,
            name: item?.followingName,
            image: item?.followingImage,
            ftype: item?.followingType,
            fdept: item?.followingDept
        }
        navigate('/msg', { state: data })
    }

    //for follower message
    const handleFollowerMessageClick = (item) => {
        const data = {
            fId: item?.fId,
            name: item?.followerName,
            image: item?.followedByImage,
            ftype: item?.followerType,
            fdept: item?.followerDept
        }
        navigate('/msg', { state: data })
    }
    return (
        <>
            {/* <section className="bulleDetail">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <section id="halloof_fame">
                                <div className="top_area">
                                    <div className="head bulleBoardHead">

                                        <h4>Follower</h4>

                                    </div>
                                    <div>
                                        <Link to="/profile" className="backHomeBtn">
                                            <i class="fa-solid fa-arrow-left" style={{ color: "#000000" }}></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="bottom_area hallofdllsdiv">


                                    {item?.map((item, index) => {
                                        return (
                                            <div className="hallofmain" key={index}>

                                                <div className="prflicondot">
                                                    <div className="profile_img"

                                                    >
                                                        {item?.followedByImage ? (
                                                            <img
                                                                src={item?.followedByImage}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        ) : (
                                                            <img
                                                                src={Eventimg2}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        )}
                                                    </div>

                                                </div>


                                                <div className="details_area">
                                                    <div className="name">
                                                        <div className="d-flex justify-content-between"

                                                        >
                                                            <h4>{item?.followerName}</h4>



                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </section>



                        </div>
                    </div>
                </div>
            </section> */}

            <section className='followwerssectionmain'>
                <div className='msgcontainer'>
                    <div className='followerssectiondivflx'>
                        <div className='followerleftdiv'>

                        </div>
                        <div className='followerrightdiv'>
                            <div className='followerrightdivbg'>
                                <div className='followerrighttopup'>
                                    <p className='followtoppppxyu'>
                                        {userData?.firstName + " " + userData?.lastName}'s Network
                                    </p>
                                </div>
                                <div className='followerrighttopbtm'>
                                    <Tabs>
                                        <div className='followerrighttopbtmden'>
                                            <TabList>
                                                <Tab>Following</Tab>
                                                <Tab>Followers</Tab>
                                            </TabList>
                                        </div>
                                        <div className='followerrighttopbtmdwnl'>
                                            <TabPanel>
                                                {
                                                    following?.map((item, index) => {
                                                        // console.log("bnklio", item)
                                                        return (
                                                            <>
                                                                <div className='prflimgdtlsfllowdivflxflwbtn' key={index}>
                                                                    <div className='prflimgdtlsfllowdivflx'>
                                                                        <div className='profilefollowimg'>
                                                                            {/* <img src={advytimg} alt="..." /> */}
                                                                            {item?.followingImage ? (
                                                                                <img
                                                                                    src={item?.followingImage}
                                                                                    className="img-fluid"
                                                                                    alt="profile"
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={Eventimg2}
                                                                                    className="img-fluid"
                                                                                    alt="profile"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className=''>
                                                                            <p className='followtxtpppnmjl'>{item?.followingName}</p>
                                                                            <p className='followtxtpppnmjldsgnty'>{item?.followingDept}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='messageflowwingdivflx'>
                                                                        <button className='followingbtndivnm' onClick={() => handleMessageClick(item)}>
                                                                            Message
                                                                        </button>
                                                                        <button className='followingbtndivnm' onClick={() => followonclick(item)}>
                                                                            Unfollow
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }

                                            </TabPanel>

                                            <TabPanel>

                                                {
                                                    followerList?.map((item, index) => {
                                                        // console.log("bnklioio", item)
                                                        return (
                                                            <>
                                                                <div className='prflimgdtlsfllowdivflxflwbtn' key={index}>
                                                                    <div className='prflimgdtlsfllowdivflx'>
                                                                        <div className='profilefollowimg'>
                                                                            {/* <img src={advytimg} alt="..." /> */}

                                                                            {item?.followedByImage ? (
                                                                                <img
                                                                                    src={item?.followedByImage}
                                                                                    className="img-fluid"
                                                                                    alt="profile"
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={Eventimg2}
                                                                                    className="img-fluid"
                                                                                    alt="profile"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className=''>
                                                                            <p className='followtxtpppnmjl'>{item?.followerName}</p>
                                                                            <p className='followtxtpppnmjldsgnty'>{item?.followerDept}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='messageflowwingdivflx'>
                                                                        <button className='followingbtndivnm' onClick={() => handleFollowerMessageClick(item)}>
                                                                            Message
                                                                        </button>
                                                                        <button className='followingbtndivnm' onClick={() => followonclick(item)}>
                                                                            Follow Back
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }

                                            </TabPanel>
                                        </div>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {followe &&
                <Fllowmodal
                    closemodal={setFollowe}
                    followin={followin}
                    // follower={follower}
                />}
        </>
    )
}

export default Follower