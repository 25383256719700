import { Calendar, momentLocalizer, Views } from "react-big-calendar";
// import { Agenda, components as calendarComponents } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useAuthCtx } from "../../context/AuthCtx";
import React, { useEffect } from "react";
// import Modal from "react-modal";
import { useState } from "react";
// import { ApiHelperFunction } from "../../services/api/apiHelpers";
import EventsBigCalenderModal from "../../SeprateModal/EventsBigCalenderModal";
import './MyCalendar.css';
// import EventsBigCalenderMoreModal from "../../SeprateModal/EventsBigCalenderMoreModal";
// import { RWebShare } from "react-web-share";
// import table_icon from "../../Images/Icons/PNG/share-icon-elite-web.svg"


// const localizer = momentLocalizer(moment); 

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
// };


const BigCalender = ({ fetchcalenderdata,
  fetchcalenderdatafunc
  // fetchholidaycalenderdata 
}) => {
  const { setCalenderMonth, setCalenderYear } =
    useAuthCtx();
  const [eventsForSelectedDate, setEventsForSelectedDate] = useState([]);
  // const [eventsForSelectedDateMore, setEventsForSelectedDateMore] = useState([]);
  const [modalIsOpen, setModalIsOpen] =useState(false);
  // const [modalIsOpenMore, setIsOpenMore] = React.useState(false);
  // const [value, onChange] = useState(new Date());
  const [isListViewActive, setIsListViewActive] = useState(false); // New state
  const [viewType, setViewType] = useState(Views.MONTH);
  //   const defaultDate = new Date(2023, 10, 15); // Year, Month (0-based), Day

  const localizer = momentLocalizer(moment);
  // const views = [Views.MONTH, Views.AGENDA];


  //for navigating functionality
  const handleNavigate = (date, view, action) => {
    let NewDate;

    if (!date) {
      NewDate = new Date();
    } else {
      NewDate = new Date(date);
    }

    if (action === 'TODAY') {
      setViewType(Views.MONTH);
    } else if (action === 'PREV') {
      NewDate.setMonth(NewDate.getMonth() - 1);
    } else if (action === 'NEXT') {
      NewDate.setMonth(NewDate.getMonth() + 1);
    }

    const getmonth = NewDate.getMonth() + 1;
    const getYear = NewDate.getFullYear();

    setCalenderYear(getYear);
    setCalenderMonth(getmonth);

    // Check if the agenda view is active
    if (action === 'agenda') {
      setIsListViewActive(true);
    } else {
      setIsListViewActive(false);
    }

    if (action === 'ANNUALLY') {
      setViewType(Views.AGENDA);
    }
  };

  // const { Agenda } = calendarComponents;
  const renderListView = () => {
    if (isListViewActive) {
      return (
        <div>
          <button onClick={() => setIsListViewActive(false)}>back</button>

          <div>
            {
              convertedEvents?.map((item) =>
                <p>
                  <span className="mr-5">{moment(item?.start).format("LL")}</span>
                  {
                    item?.image ? (<img src={item?.image} style={{ height: "50px", width: "50px" }} onClick={() => handleDateChange(item)} />) : (<span onClick={() => handleDateChange(item)}> {item?.title}</span>)
                  }

                </p>
              )
            }
          </div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    handleNavigate();
  }, []);


  //event details in modal
  const convertedEvents = fetchcalenderdata?.reduce((prev, current) => {
    let data = {
      id: current.id,
      addedBy:current.addedBy,
      title: current.title,
      image: current.image,
      notes: current.notes,
      start: current.start.toDate(),
      end: current.end.toDate(),
      count: 0,
      additionalEvents: [],
    };
    let index = prev.findIndex(
      (it) =>
        moment(it?.start).format("YYYY-MM-DD") ===
        moment(current?.start).format("YYYY-MM-DD")
    );
    if (index >= 0) {
      prev[index].count += 1;
      prev[index].additionalEvents.push(data);
      return [...prev];
    } else {
      return [...prev, data];
    }
  }, []);
  // console.log("convertedEvents", convertedEvents)


  //event image or title show in calender
  const CustomEvent = ({ event, onClickEvent, onClickMore }) => (
    <>

      {event?.image === "" ? (
        <>
          <div onClick={() => onClickEvent(event)}>{event?.title}</div>
        </>
      ) : (
        <div className="eventimagebgoverlay">
          <img
            src={event?.image}
            alt="Event Image"
            onClick={() => onClickEvent(event)}
          />
        </div>
      )}
      {
        event.count > 0 ?
          <h5 className="hhmoretxtevent"
            onClick={() => onClickEvent(event)}
          >

            +{event?.count} more
          </h5>
          :
          null
      }

    </>
  );

  const MyAgendaTime = () => {
    <></>
  }
  const handleDateChange = (e) => {
    setModalIsOpen(true);
    setEventsForSelectedDate(e);
  };

  const CustomToolbar = (toolbar) => {
    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={() => toolbar.onNavigate('PREV')}>
            {"<"}
          </button>
          <button type="button" onClick={() => toolbar.onNavigate('TODAY')}>
            Month
          </button>
          <button type="button" onClick={() => toolbar.onNavigate('NEXT')}>
            {">"}
          </button>
        </span>
        <span className="rbc-toolbar-label">{toolbar.label}</span>
        <span className="rbc-btn-group">
          <button type="button" onClick={() => handleNavigate(null, null, 'ANNUALLY')}>
            List of events
          </button>
        </span>
      </div>
    );
  };

  //for close Eventbigcalendermodal
  const closemodal = () => {
    setModalIsOpen(false)
  }

  return (
    <div style={{ height: "100%" }} className="calenderdivmain">
      {
        !isListViewActive &&
        <Calendar
          localizer={localizer}
          events={convertedEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ width: "100%" }}
          onNavigate={(date, view, action) => handleNavigate(date, view, action)}

          selectable={() => {

          }}
          // components={components}
          // onSelectEvent={(e)=>{console.log("convertedEventsllkk",e)}}
          onSelectEvent={(e) => handleDateChange(e)}

          // value={value}
          eventPropGetter={(event, start, end, isSelected) => ({
            style: {
              // backgroundColor: "transparent",
              border: "none",
              outline: 0,
              // color: "red",
              // fontSize: "25px",
              // fontWeight: "500",
            },
            showMore: false,
          })}
          // components={{
          //   event: CustomEvent,
          //   time: MyAgendaTime,
          // }}
          components={{
            toolbar: CustomToolbar,
            event: (props) => (
              <CustomEvent {...props} onClickEvent={handleDateChange} 
              // onClickMore={handleMoreEvents} 
              />
            ),
            time: MyAgendaTime,
          }}
         
          views={[Views.MONTH, Views.AGENDA]} // Specify the available views
          onView={(view) => setViewType(view)} // Update the view type when it changes
          view={viewType}
          messages={{ agenda: "List Of Events", previous: "<", next: ">", time: "" }}
    
        />
      }
{/* 
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        // style={customStyles}
        contentLabel="Example Modal"
      > */}
        {/* <button
          className="btn btn-danger"
          style={{ marginLeft: "80%", height: "30px", width: "30px" }}
          onClick={() => setIsOpen(false)}
        >
          X
        </button> */}
        {modalIsOpen && <EventsBigCalenderModal
        fetchcalenderdatafunc={fetchcalenderdatafunc}
          closemodal={closemodal}
          eventsForSelectedDate={eventsForSelectedDate}
          // eventsForSelectedDateMore={eventsForSelectedDateMore}
        />}
      {/* </Modal> */}


      {/* Display the list of events */}
      {renderListView()}


  
    </div>
  );
};

export default BigCalender;
