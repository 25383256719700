import React, { useEffect, useState } from "react";
import "./style.css";
// import frontImage from "./Images/frontImg.jpg";
// import backImage from "./Images/backImg.jpg";
import { useAuthCtx } from "../context/AuthCtx";
import { useFormik } from "formik";
// import companyname from "../../src/Images/companyname.jpeg"
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { clearLogin, loginSuccess } from "../redux/slices/loginSlice";
// import employeeexperiencehub from "../Images/employeeexperiencehub.png";
// import logipageimage from "../Images/logipageimage.png"
import logimg from "../Images/LogNewPic.png"
import logogo from "../Images/logcompany.png"
import btmlogo from "../Images/bottomlogo.png"
import Hide from "../Images/headericon/Hide.png"
import Show from "../Images/headericon/View.png"
import { ApiHelperFunction } from "../services/api/apiHelpers";


const Login = () => {
  const domainName = window.location.hostname;
  const splitedDName = domainName?.split('.')[0];
  // console.log("domainName", splitedDName);

  const navigate = useNavigate();
  const { loginUser, loading, setLoading, setLogIn } = useAuthCtx();
  const dispatch = useDispatch();
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [comCode, setComCode] = useState(null);
  // const [forgetPassword,setForgetPassword]=useState(null);

  //for getting company code
  const getCompanyCode = async () => {
    let data = {
      userName: splitedDName
    };
    let response = await ApiHelperFunction({
      urlPath: "/get-company-code",
      method: "POST",
      data,
    });
    if (response?.status) {
      setComCode(response?.data?.data?.[0]?.companyCode);
      // console.log("DATADFF", response?.data?.data?.[0]?.companyCode);
    } else {
      navigate(window.location.href = `http://${splitedDName}.fractalselite.com/login`)
    }
  }



  useEffect(() => {
    getCompanyCode();
  }, [])

  const initialValues = {
    password: "",
    email: "",
    // companyCode: comCode
  };

  const { values, handleBlur, handleSubmit, handleChange } = useFormik({

    initialValues,
    onSubmit: (e) => {
      handleFormSubmit(e);
    },
  });
  // console.log("companyCode", initialValues);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  //for Submit Functionality
  const handleFormSubmit = async (e) => {
    if (loading) return;

    if (!agreeTerms) {
      toast?.error("Please agree to the terms and conditions.");
      return;
    }
    let data = values;
    data.companyCode = comCode;
    setLoading(true);
    const res = await loginUser(data);
    console.log("res", res)
    if (res?.status) {
      setLogIn(true);
      sessionStorage.setItem("login", true);

      localStorage.setItem("Authorization", res?.data?.data?.token);

      toast?.success("Login Successful");
      dispatch(loginSuccess());
      navigate("/");
    } else {
      localStorage.clear();
      dispatch(clearLogin());
      toast?.error(res?.response?.data?.error?.companyCode?.message || "Something went wrong");
      // console.log("Login_error", res?.response?.data?.error?.companyCode?.message);
    }

    setLoading(false);
  };

  // const [inputType, setInputType] = useState('password');
  // const [showHidebtnText, setShowHideBtnText] = useState(true);

  // const toggleInput = () => {
  //   setInputType(inputType == 'password' ? 'text' : 'password');
  //   //  setShowHideBtnText(showHidebtnText ? <AiFillEyeInvisible /> : <AiFillEye />);

  // };

  const handleForgetPass = () => {
    navigate('/forgotPass')
  }


  //for handle forget password
  // const handleForgetPass = async () => {
  //   let data={
  //     email: values?.email
  //   }
  //   const response = await ApiHelperFunction({
  //     urlPath: "/forget-password",
  //     method: "PUT",
  //     data
  //   });

  //   if (response.status === 200) {
  //     toast?.success(response?.data?.message)
  //     setForgetPassword(response?.data?.data?.otp)

  //     // navigate('/forgotPass')
  //   } else {

  //   }
  // }
  // useEffect(()=>{
  //   handleForgetPass();
  // },[])

  // console.log("opui",forgetPassword)
  return (
    <div className="container-parent">

      {/* <input type="checkbox" id="flip" /> */}
      {/* <div className="cover">
          <div className="front">
            <img src={frontImage} alt="" />
            <div className="text">
              <span className="text-1">
                Every new friend is a <br /> new adventure
              </span>
              <span className="text-2">Let's get connected</span>
            </div>
          </div>
        </div> */}

      {/****Udayan start***/}
      {/* <div className="lgnadivld">
        <div className="loginalaimg">
          <img src={logipageimage} alt="..." />
          <div className="divfrmabs">
            <div className="forms">
              <div className="form-content">
                <div className="login-form">
                  <div className="title">
                    <h4>WELCOME</h4>
                    <div className="companynameimgwh">
                      <img src={companyname} alt="/" />
                    </div>
                    <p>Login To Stay Connected</p>
                  </div>
                  <form action="#" onSubmit={handleSubmit}>
                    <div className="input-boxes">
                      <div className="input-box">

                        <i className="fa-regular fa-user"></i>
                        <input
                          name="email"
                          onBlur={handleBlur}
                          value={values.email}
                          onChange={handleChange}
                          type="email"
                          placeholder="User ID"
                          required
                        />
                      </div>
                      <div className="input-box inputboxkey">

                        <i className="fa-solid fa-key"></i>
                        <input
                          type="password"
                          onBlur={handleBlur}
                          value={values.password}
                          onChange={handleChange}
                          name="password"
                          placeholder="Password"
                          required
                        />
                      </div>

                      <div className="rmdirfgdiv">
                        <div className="checkdivbox">
                          <input type="checkbox" id="" name="" value="" />
                          <label for=""> Remember Me</label>
                        </div>
                        <div className="text">
                          <a href="#">Forgot password?</a>
                        </div>

                      </div>
                      <div className="button input-box">
                        <input type="submit" value="Submit" />
                      </div>
                      <div className="dljkflsxsosgn">
                        <div className="">
                          <p className="sccghdnttxt">Don't have an Account Please</p>
                        </div>
                        <div className="">
                          <Link to="/" className="sgnuptct">Sign Up</Link>
                        </div>
                      </div>
                      <div className="exptfflx">
                        <div className="ecxpkkhubimg">
                          <img src={employeeexperiencehub} alt="..." />
                        </div>
                        <div className="">
                          <p className="eperitxt">Elite Employee Experience Hub</p>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/****Udayan end***/}



      {/****shiltu start***/}
      <div className="loginsection_wrap">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="loginimg_wrap">
              <img src={logimg} className="img-fluid" alt="image" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="logcontent_wrap">

              <div className="responsivelogogo_design">
                <div className="logogo_icon">
                  <img src={logogo} className="img-fluid" alt="..." />
                </div>
              </div>

              <div className="rightcontentbox">
                <h4 className="heading">Log in to your account</h4>

                <form
                  action="#"
                  onSubmit={handleSubmit}
                >


                  <div className="form-group">
                    <label htmlFor="InputEmail1">Email Id</label>
                    <input
                      name="email"
                      onBlur={handleBlur}
                      value={values?.email}
                      onChange={handleChange}
                      type="email"
                      className="form-control"
                      id="InputEmail1"
                      aria-describedby="emailHelp"
                      placeholder=""
                      required
                    />

                  </div>

                  {/* <div className="form-group"> */}
                  <div className="form-group">
                    <label htmlFor="Inputpassword">Password</label>
                    <div className="inputcompanydtls">
                      <input
                        // type="password"
                        type={showPassword ? "text" : "password"}
                        onBlur={handleBlur}
                        value={values.password}
                        onChange={handleChange}
                        name="password"
                        className="form-control"
                        id="Inputpassword"
                        placeholder=""
                        required
                      />
                      <div className='Hideimgdivicn' onClick={togglePasswordVisibility}>
                        <img src={showPassword ? Show : Hide} alt="..." />
                      </div>
                    </div>
                    {/* <div className="icon" type="button" style={{ color: 'black' }}>
                      {showHidebtnText ? (
                        <AiFillEyeInvisible
                          onClick={() => {
                            setShowHideBtnText(false), toggleInput();
                          }}
                        />
                      ) : (
                        <AiFillEye
                          onClick={() => {
                            setShowHideBtnText(true), toggleInput();
                          }}
                        />
                      )}
                    </div> */}
                  </div>

                  <div className="checkbox_area">

                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="termsandcondition"
                        checked={agreeTerms}
                        onChange={() => setAgreeTerms(!agreeTerms)}
                      />
                      <label htmlFor="termsandcondition">I agree with the <Link>terms and conditions</Link></label>
                    </div>

                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="rememberPasswoard"
                        checked={rememberPassword}
                        onChange={() => setRememberPassword(!rememberPassword)}
                      />
                      <label htmlFor="rememberPasswoard">Remember Password</label>
                    </div>

                  </div>

                  <div className="submitarea_wrap">
                    <button to="/" className="login_btn" type="submit"
                    // onClick={handleFormSubmit} 
                    > Login </button>
                    <button onClick={handleForgetPass} className="forgot_btn">Forgot Password? *</button>
                  </div>

                </form>

                <div className="advicetext">
                  <p>* still can't log in? <br /> <span>Contact Your Company HP.</span></p>
                </div>

                <div className="bottomlogo">
                  <div className="imgwrap">
                    <img src={btmlogo} className="img-fluid" alt=".." />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/****shiltu end***/}


    </div >
  );
};

export default Login;
