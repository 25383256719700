import React from 'react'
import DocumentRepository from "../../Images/Icons/PNG/DocumentRepository.png";
import Expand from "../../Images/Icons/PNG/Expand.png";
import { useNavigate } from 'react-router-dom';
import RequestedTrainingcard from "./RequestedTrainingcard"

const Requestedtraining = () => {
    const navigate = useNavigate();
    const handleonrequestedClick = () => {
        navigate("/Singlerequestedtraining")
    }
    return (
        <>
            <div className='videoongoing'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        {/* <figure className="bulleBoardFig">
                            <img
                                src={DocumentRepository}
                                alt=".."
                            />
                        </figure> */}
                        <h4>Requested Training</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv">
                            <figure className="bulleBoardFig">
                                <img src={Expand} alt="..." onClick={handleonrequestedClick} />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='bottom bottomdivongong'>
                    <RequestedTrainingcard />

                </div>
            </div>
        </>
    )
}

export default Requestedtraining
