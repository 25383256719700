import React, { useEffect, useState } from "react"
import EducateStatusCard from "./EducateStatusCard"
import Ongoing from "./Ongoing"
import StandardOperatingProsedure from "./StandardOperatingProsedure";
import star from "../../Images/Icons/PNG/star.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward.png";
import trainingmain from "../../Images/trainingmain.png"
import { useDispatch, useSelector } from "react-redux";
import { getOnboardingSkillTraining } from "../../redux/slices/jobRecruitmentSlice";
import moment from "moment";
import SkillCousremodal from "../../Component/Modal/SkillCousremodal";

const SingleSkillCourse = () => {
    const trainingSkill = useSelector((state) => state?.jobRecruitmentSlice?.skillTraining);
    const [singleMandatory, setSingleMandatory] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOnboardingSkillTraining())
    }, [])


    const [videotraing, setVideotraing] = useState(false)
    const handlevideoClick = (item) => {
        setSingleMandatory(item)
        setVideotraing(!videotraing)
    }
  
    return (
        <>
            <section className='singlemandatorysection'>
                <div className='employeepagecontainer'>
                    <div className='singleeducatemainflx'>
                        <div className='singlemandatoryright'>
                            <div className='singlemandatorydivbg'>
                                <div className='mandatorydivbgflxdiv'>
                                    {trainingSkill?.map((item, index) => {
                                        return (
                                            <div className='mandatorydivbg mandatorywidthdiv' key={index} onClick={() => handlevideoClick(item)}>
                                                {/* <div className='traingmaindivmain'>
                                                    <img src={item.image} alt="..." />
                                                </div> */}
                                                {
                                                    (item?.image === "images" || item?.image === "" || item?.image === "image") ? (<div className='traingmaindivmain'>
                                                        <img src={trainingmain} alt="..." />
                                                    </div>) : (<div className='traingmaindivmain'>
                                                        <img src={item?.image} alt="..." />
                                                    </div>)
                                                }

                                                <div className=''>
                                                    <p className='mandttxtpp'>
                                                        {item?.description}
                                                    </p>
                                                    {/* <p className='writertxtpp'>{item.writter}</p> */}
                                                </div>
                                                <div className="imgtxtdivmaindddflx">
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgdiv">
                                                            <img
                                                                src={star}
                                                                alt="..."
                                                            />
                                                        </div>
                                                        <p className="tsgyutppp">{item.rating ? (<>{item.rating}</>) : ("0")}</p>
                                                    </div>
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgdiv">
                                                            <img
                                                                src={clock}
                                                                alt="..."
                                                            />
                                                        </div>
                                                        {/* <p className="tsgyutppp">{item.hrs}</p> */}
                                                        <p className="tsgyutppp">{moment(item.trainingStartDate).format("DD/MM/YY")} </p>
                                                    </div>
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgfrmt">
                                                            <img
                                                                src={reward}
                                                                alt="..."
                                                            />
                                                        </div>
                                                        <p className="tsgyutppp">{item.trainingReward}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>

                            </div>
                        </div>
                        <div className='singlemandatoryleft'>
                            <div className=''>
                                <EducateStatusCard />
                            </div>
                            <div className=''>
                                <Ongoing />
                            </div>
                            <div className=''>
                                <StandardOperatingProsedure />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {videotraing && <SkillCousremodal closemodal={setVideotraing} singleMandatory={singleMandatory} />}
        </>
    )
}

export default SingleSkillCourse