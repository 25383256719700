import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { ApiHelperFunction, fileUpload } from "../../services/api/apiHelpers";
import { useAuthCtx } from "../../context/AuthCtx";
import { useDispatch } from "react-redux";
import { getAllPosts } from "../../redux/slices/postSlice";
import { useLocation } from "react-router-dom";
import Loader from "../loader/Loader";
import profileImg from "../../Images/Icons/PNG/Profile (2).png";
import { BsEmojiSmileFill } from "react-icons/bs";
import EmojiPicker from "emoji-picker-react";
import Createnewpost from "../../Images/Icons/PNG/CreateNewPost.png";
import Attach from "../../Images/headericon/Attach.png";
import Post from "../../Images/Icons/PNG/Post.png";


export default function ThoughtModalHome({
  closemodal,
  text,
  getAffinityPosts,
}) {
  const [image, setImage] = useState("");
  const [hide, setHide] = useState(false);
  const location = useLocation();
  const [uploading, setUploading] = useState(false);
  const [sharePublicly, setSharePublicly] = useState("publicly");
  const [showAffinityDropdown, setShowAffinityDropdown] = useState(false);
  const [groupid, setGroupId] = useState("");
  const [grpData, setGrpData] = useState([]);
  const [isEmoji, setIsEmoji] = useState(false);
  const navigate = useNavigate();
  const [isChecked, setChecked] = useState(false);
  const [url, setUrl] = useState({});

  const [post, setPost] = useState({
    description: "",
    image: [],
    video: [],
    descType: "text",
    pre: "",
    link: "",
    suffix: "",
    // affinityGroupId: "",
  });


  const handleemojioffclick = () => {
    setIsEmoji(false)
  }


  // Function to check if text contains a URL
  const containsURL = (text) => {
    const urlPattern = new RegExp('https?://[^\s/$.?#].[^\s]*', 'i');
    return urlPattern.test(text);
  };


  // Function to extract URL and surrounding text
  const extractUrlParts = (description) => {
    const urlPattern = /(https?:\/\/[^\s/$.?#].[^\s]*)/i;
    const match = description?.match(urlPattern);

    if (match) {
      const url = match?.[0];
      const preText = description?.substring(0, match?.index);
      const suffixText = description?.substring(match?.index + url?.length);
      return { pre: preText, link: url, suffix: suffixText };
    }

    return { pre: description, link: '', suffix: '' };
  };

  // Update descType based on the description content
  useEffect(() => {
    const { pre, link, suffix } = extractUrlParts(post?.description);

    if (link) {
      setPost((prev) => ({ ...prev, descType: "link", pre, link, suffix }));
    } else {
      setPost((prev) => ({ ...prev, descType: "text", pre, link: '', suffix: '' }));
    }
    getUrl();
  }, [post.description]);


  // Update descType based on the description content
  // useEffect(() => {
  //   if (containsURL(post.description)) {
  //     setPost((prev) => ({ ...prev, descType: "link" }));
  //   } else {
  //     setPost((prev) => ({ ...prev, descType: "text" }));
  //   }
  //   getUrl();
  // }, [post.description]);



  //for viewing my affinity group
  const getGroupData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-my-affinity-group`,
      method: "GET",
    });
    // console.log("RESPONSEF", response?.data?.data);
    if (response && response.status) {
      setGrpData(response?.data?.data);
    } else {
      // toast.error(response.message);
    }
  };

  //for getting url details 
  const getUrl = async () => {
    let data = {
      url: post.link
    };
    let response = await ApiHelperFunction({
      urlPath: "/get-url-data",
      method: "POST",
      data,
    });
    if (response?.status) {
      setUrl(response?.data?.data);
    }
  }

  const handleset = (e) => {
    e.stopPropagation()
  }

  useEffect(() => {
    getGroupData();

  }, []);


  useEffect(() => {
    if (location?.pathname === "/advocacy") {
      setHide(true);
    }
  }, []);
  const { userData } = useAuthCtx();
  const dispatch = useDispatch();


  //for images upload
  const handleImageChange = async (e) => {
    let files = Array.from(e.target.files);
    console.log("files", files)
    let imageArray = [];
    let videoArray = [];
    setUploading(true);

    for (let i = 0; i < files.length; i++) {
      const form = new FormData();
      form.append("image", files[i]);

      // Check if the file is an image
      if (files[i].type.startsWith('image/')) {
        let res = await fileUpload("/image-upload", "POST", form);
        console.log("resnm", res)
        if (res?.status) {
          toast.success("Image uploaded successfully");
          imageArray?.push(res?.image);
        } else {
          toast.error("Error uploading image");
        }
      }
      // Check if the file is a video
      else if (files[i]?.type?.startsWith('video/')) {
        let res = await fileUpload("/image-upload", "POST", form);

        if (res?.status) {
          toast.success("Video uploaded successfully");
          videoArray.push(res?.image);
        } else {
          toast.error("Error uploading video");
        }
      }
      // Handle other file types if needed
      else {
        toast.error("Unsupported file type");
      }
    }

    // Update state with uploaded images and videos
    setPost({ ...post, image: imageArray, video: videoArray });
    setUploading(false);
  };


  //for remove image or video
  const removeImage = (index, type) => {
    if (type === 'image') {
      let images = [...post.image];
      images.splice(index, 1);
      setPost({ ...post, image: images });
    } else if (type === 'video') {
      let videos = [...post.video];
      videos.splice(index, 1);
      setPost({ ...post, video: videos });
    }
  };



  //for onChange functionality
  const handleChange = (e) => {
    e.preventDefault();
    setPost(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };


  // Handle emoji click
  const handleEmojiClick = (emojiObject) => {
    const emoji = emojiObject.emoji;
    setPost((prevPost) => ({
      ...prevPost,
      description: prevPost.description + emoji,
    }));
  };


  //for post functionality
  const handlePost = async (e) => {
    if (post.description == "" && post.image == "" && post.video == "") {
      toast.error("Please enter description or image or video!");
      return;
    }
    let concatenatedDescription = post.pre + post.link + post.suffix;
    let data = {
      description: concatenatedDescription,
      image: post?.image,
      video: post.video,
      postType: "public",
      descType: post.descType,
      pre: post.descType === "link" ? post.pre : undefined,
      link: post.descType === "link" ? post.link : undefined,
      suffix: post.descType === "link" ? post.suffix : undefined,
    };
    // console.log("vdoUrl",vdoUrl);
    // return false;
    if (sharePublicly === "publicly") {
      setUploading(true);
      let response = await ApiHelperFunction({
        urlPath: "/user-add-post",
        method: "POST",
        data: data,
      });

      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setPost({
          description: "",
          image: "",
          video: ""
        });
        // setVdoUrl("");
        setUploading(false);
        closemodal();
        dispatch(getAllPosts());
      } else {
        toast.error("Can't get data. Something went wrong");
        setUploading(false);
      }
    } else if (sharePublicly === "affinity") {
      // console.log("affinity api");
      let concatenatedDescription = post.pre + post.link + post.suffix;
      let data2 = {
        description: concatenatedDescription,
        image: post.image,
        affinityGroupId: groupid,
        postType: "private",
        descType: post.descType,
        pre: post.descType === "link" ? post.pre : undefined,
        link: post.descType === "link" ? post.link : undefined,
        suffix: post.descType === "link" ? post.suffix : undefined,
      };

      setUploading(true);
      let response = await ApiHelperFunction({
        urlPath: "/user-add-post",
        method: "POST",
        data: data2,
      });
      console.log(data2, "responseerty");

      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setPost({
          description: "",
          image: "",
        });
        setUploading(false);
        closemodal();
        navigate(`/affinity/${groupid}`);
        getAffinityPosts();
        // dispatch(getAllPosts());
      } else {
        // console.log(response?.response?.data?.message, "responseerty");
        toast.error(response?.response?.data?.message);
        setUploading(false);
      }
    } else {
      return;
    }
  };


  //toggle part
  const handleclicktggle = () => {
    setChecked(!isChecked);
    if (!isChecked) {
      setSharePublicly("affinity");
      setShowAffinityDropdown(true);
    } else {
      setSharePublicly("publicly");
      setShowAffinityDropdown(false);
    }
  };

  const handlestopClose = (e) => {
    e.stopPropagation();
  }

  return (
    <>
      <div
        className="thought_moadal_main"
        onClick={() => {
          closemodal()
          setIsEmoji(false)
        }}
      >
        <div className="thought_moadal">
          {/* <div className="Create_overlay"></div> */}
          <div className="thought_modal_content" onClick={(e) => handleset(e)}>
            <div className="close_icon" onClick={() => closemodal()}>
              <i
                class="fa-solid fa-xmark"
                style={{ color: "red", fontSize: "16px", cursor: "pointer" }}
              ></i>
            </div>
            <div className="thoghts_txt_top_btm">
              <div className="top">
                <div className="crtimg">
                  <img src={Createnewpost} alt="..." />
                </div>
                <p>Create New Post</p>
              </div>

              <div className="bottom">
                <div className="img_thght">
                  <div
                    className="profile_img"
                    style={{ borderRadius: "50%", overflow: "hidden" }}
                  >
                    {userData?.image === "image" ? (
                      // <img
                      //   src={userData?.image}
                      //   className="img-fluid"
                      //   alt="profile"
                      // />
                      <img src={profileImg} alt="..." />
                    ) : (


                      <img
                        src={userData?.image}
                        className="img-fluid"
                        alt="profile"
                      />
                    )}
                  </div>
                  {/* <div className="prfl_mnm">
                    <p>{`${userData?.firstName} ${userData?.lastName}`}</p>
                  </div> */}
                </div>


                <div className="dbdyptrtddiv" onClick={handleemojioffclick} >
                  <form>
                    <textarea
                      id=""
                      name="description"
                      value={post?.description}
                      rows="4"
                      cols="50"
                      className="txtar"
                      placeholder={!hide ? text : "Testimonials"}
                      onChange={(e) => handleChange(e)}
                    ></textarea>
                  </form>
                  {
                    url?.urlSiteName && (
                      <>
                        <div className='yutubeyrlsdivimgmaindiv'>
                          <div className='yutubeyrlsdivimg'>
                            <img src={url?.urlImage} alt="" />
                          </div>
                        </div>
                        <div className='frmdivmainyuoutuber'>
                          <p className='fromnsyiuybusnm'> From : {url?.urlSiteName}</p>
                          <p className='fromnsyiuybusnm'>{url?.urlTitle}</p>
                        </div>
                        <div>

                        </div>
                      </>
                    )
                  }


                  {uploading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Loader />
                    </div>
                  ) : (
                    <div className="prt_img_flx">
                      {post?.image &&
                        post?.image?.map((item, index) => {
                          return (
                            <div className="prt_img" key={index}>
                              <img src={item} alt="" />

                              <div className="close_icon_img">
                                <i
                                  class="fa-solid fa-xmark"
                                  style={{
                                    color: "red",
                                    fontSize: "24px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeImage(index, 'image')}
                                ></i>
                              </div>
                            </div>
                          );
                        })}


                    </div>
                  )}



                  <div className="prt_img_flx">
                    {post?.video &&
                      post?.video?.map((item, index) => {
                        return (
                          <div className="prt_img" key={index}>
                            <video src={item} style={{ width: "90px", height: "90px" }} alt="" />

                            <div className="close_icon_img">
                              <i
                                class="fa-solid fa-xmark"
                                style={{
                                  color: "red",
                                  fontSize: "24px",
                                  cursor: "pointer",
                                }}
                                onClick={() => removeImage(index, 'video')}
                              ></i>
                            </div>
                          </div>
                        );
                      })}


                  </div>

                </div>


                <div className="shrtatchps">

                  {/* toggle part */}
                  <div className="shrepubafinitydiv">
                    <div className="shredivmaindk">
                      <div className="brdttgglebox">

                        <div
                          className={isChecked ? "boxyellow actv" : "boxyellow"}
                          onClick={handleclicktggle}
                        />

                      </div>
                      {/* Display the current state */}
                      <p className="afntytxtpp">{isChecked ? ' Share Affinity Group' : 'Share publicly'}</p>
                      <div>
                        {showAffinityDropdown && (
                          <div className="emplListDiv">
                            {/* <p className="empListSubHead">Groups Name</p> */}
                            <select
                              class="empListSelectdiv"
                              aria-label="Select Employee name"
                              name="groupid"
                              value={groupid}

                              onChange={(e) => setGroupId(e.target.value)}
                            >
                              <option value={""} disabled>
                                Select Group
                              </option>
                              {grpData?.map((item, i) => (
                                <option key={i} value={item?._id}>
                                  {item?.groupName}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>



                  {/* emoji section */}
                  <div className="attachpostflxdiv" >
                    <div className="bsimgicon" onClick={(e) => handlestopClose(e)} >
                      <BsEmojiSmileFill
                        className=""
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsEmoji((prev) => !prev)

                        }}
                      />
                      {isEmoji && (
                        <EmojiPicker width={20} fontSize={20}
                          onEmojiClick={handleEmojiClick}
                        // onEmojiClick={(emojiObject) => {
                        //   const emoji = emojiObject.emoji;
                        //   setPost((prev) => ({
                        //     ...prev,
                        //     description: prev.description + emoji,
                        //   }));
                        // }}
                        />

                      )}
                      {/* <div className="emokjipickerdivimgmain" >
                        <div className="emokjipickerdivimg">
                          <img src={Emoji} alt="..." />
                        </div>
                      </div> */}
                    </div>
                    <div
                      className="attach_btn"
                      style={
                        {
                          // marginRight: "20px",
                          // display: "flex",
                          // alignItems: "center",
                        }
                      }
                    >
                      <div style={{ marginRight: "5px" }}>
                        <div className="file_upload">
                          <div className="input_img" style={{ cursor: "pointer" }}>
                            <img src={Attach} className="img-fluid" alt="icon" />
                          </div>
                          <input
                            id=""
                            type="file"
                            value={image}
                            onChange={handleImageChange}
                            // accept="image/*"
                            multiple
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>


                    </div>
                    <div className="postimgicon" style={{ cursor: "pointer" }} onClick={(e) => handlePost(e)}>
                      <div className="pstimg">
                        <img src={Post} alt="..." />
                      </div>

                    </div>
                  </div>

                </div>

              </div>
            </div>

            {/* dynamically photo add design */}
            {/* <div className="prt_img">
              <img src={connection4} alt="" />
              <div className="prt_overlay"></div>
              <div className="prt_img_edt">
                <Link to="/" className="edt_btnn_icon">
                  <i className="fa-solid fa-pen"></i>
                  Edit
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div >
    </>
  );
}
