import React from 'react'
import { ApiHelperFunction } from '../services/api/apiHelpers';
import toast from 'react-hot-toast';
import { useAuthCtx } from '../context/AuthCtx';

const InitiativeDetailsModal = ({ closemodal, initiativeData }) => {
    const { userData } = useAuthCtx();

    //for join initiative
    const handleJoinClick = async (id) => {
        const data = {
            // name: groupName,
            initiativesID: id
        };
        const response = await ApiHelperFunction({
            urlPath: `/join-initiative`,
            method: "POST",
            data,
        });

        if (response.status) {
            toast.success(response?.data?.message);
            // dispatch(getAffinityGroups());
            // setGroupName("");
        } else {
            toast.error(response?.response?.data?.message);
        }
    };

    // console.log(initiativeData, "ppppp")
    return (
        <div className='viedetailsmain' onClick={() => closemodal()}>
            <div className='viewdetailscontent'>
                <div className='initiativetctcrsth'>
                    <p className='inititaiveheadingmodaltxtmainhhh'>Project Details</p>
                    <div className='closetmodalinitive'>
                        <i class="fa-solid fa-xmark"></i>
                    </div>

                </div>

                <div className=''>
                    <div className="details">
                        <div className='inititaiveheadingmodaltxtmaindiv'>
                            <p className="inititaiveheadingmodaltxt">
                                Name Of Project : <span className='initiativetextdtlsmodaltxt'> {initiativeData.nameOfInitaitive} </span>
                            </p>
                        </div>
                        <div className='inititaiveheadingmodaltxtmaindiv'>
                            <p className="inititaiveheadingmodaltxt">
                                Type Of Project : <span className='initiativetextdtlsmodaltxt'> {initiativeData?.initiativeTypeName} </span>
                            </p>
                        </div>
                        <div className='inititaiveheadingmodaltxtmaindiv'>
                            <p className="inititaiveheadingmodaltxt">
                                Description : <span className='initiativetextdtlsmodaltxt'> {initiativeData.description} </span>
                            </p>
                        </div>
                        <div className='inititaiveheadingmodaltxtmaindiv'>
                            <p className="inititaiveheadingmodaltxt">
                                Reward Points : <span className='initiativetextdtlsmodaltxt'> {initiativeData.rewardPoints} </span>
                            </p>
                        </div>
                        <div className='inititaiveheadingmodaltxtmaindiv'>
                            <p className="inititaiveheadingmodaltxt">
                                Start Date : <span className='initiativetextdtlsmodaltxt'> {initiativeData.startDate} </span>
                            </p>
                        </div>
                        <div className='inititaiveheadingmodaltxtmaindiv'>
                            <p className="inititaiveheadingmodaltxt">
                                End Date : <span className='initiativetextdtlsmodaltxt'> {initiativeData.endDate} </span>
                            </p>
                        </div>
                        {/* <div className='jnbtndivinititvemarginmainduiv'>
                            <button className='jnbtndivinititve'>Join</button>
                        </div> */}

                        {/* {
                            userData?._id === initiativeData?.addedBy ? (<></>) : (<div className="jnbtndivinititvemarginmainduiv">
                                <button className="jnbtndivinititve" onClick={() => handleJoinClick(initiativeData?._id)}>Join</button>
                            </div>)
                        } */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InitiativeDetailsModal