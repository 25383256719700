import React from 'react'
import certificate from "../../Images/certificate.png"
import { useNavigate } from 'react-router-dom'
const DocumentRepositorycategory = ({ deptData }) => {
    const navigate = useNavigate()

    const handlecategoryClick = (id) => {
        navigate("/articlepage", { state: id })
    }
    
    return (
        <>
            {deptData?.map((item, index) => {
                return (
                    <div className='documentRepositorycategorybg' key={index} onClick={() => handlecategoryClick(item?._id)}>
                        <div className=''>
                            <div className='certificateimgdivbg'>
                                <img src={certificate} alt="..." />
                                <div className='itemcategppp'>
                                    <p className='itemcategppptext'>{item?.catName}</p>
                                </div>
                            </div>
                        </div>
                        <div className='topicviweflx'>
                            <div className=''>
                                <p className='texyttpxvwpp'>{item?.totaDocumentCount}</p>
                                <p className='texyttpxvwpp'>Topics</p>
                            </div>
                            <div className=''>
                                <p className='texyttpxvwpp'>{item?.totalUserViews}</p>
                                <p className='texyttpxvwpp'>Views</p>
                            </div>
                        </div>
                    </div>
                )
            })}


        </>
    )
}

export default DocumentRepositorycategory
