import React, { useEffect, useState } from "react";
import "./Hub.css";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
// import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import implink1 from "../../Images/impLink1.png";
import implink2 from "../../Images/impLink2.jpg";
import linkimg from "../../Images/Icons/PNG/Network.png";
import prflcircle from "../../Images/prflcircle.jpeg"
function ImportantLinks({ linkChange }) {
  const [links, setLinks] = useState([]);

  //fetch links
  const fetchLinks = async () => {
    let response = await ApiHelperFunction({
      urlPath: `/view-section`,
      method: "GET",
    });
    console.log("click", response);
    if (response && response.status === 200) {
      setLinks(response?.data?.data);
    } else {
      // toast.error(response?.data?.data?.message);
    }
  };

  useEffect(() => {
    fetchLinks();
  }, []);


  // const loop = [{ id: 1, img: implink1, }, { id: 2, img: implink2, }, { id: 3, img: implink1, },];

  return (
    <div className="impLinkDiv">
      <div className="impLiSpanDiv">
        <figure className="impLiSpanFig">
          <img src={linkimg} alt="" />
        </figure>
        <span className="impLiSpan">Important Links</span>
      </div>
      <div className="impSectionsDiv">


        {links?.map((item, index) => {
          return (
            <Link to={item?.link} target="_blank" className="impSecLink" key={index}>
              <div className="impSecDiv">
                <figure className="impSecFig">
                  {
                    item?.image ? (<img src={item?.image} alt="img" />) : (<img src={prflcircle} alt="" />)
                  }
                </figure>
                <div className="impSecTxt">
                  <a className="impSecTxtlnk" href={item?.link.startsWith("http") ? item?.link : `http://${item?.link}`} target="_blank" rel="noopener noreferrer">
                    {item?.link}
                  </a>

                  <p className="impSecDesc">{item?.description}</p>
                </div>
              </div>
            </Link>
          )
        })}


      </div>
    </div>
  );
}

export default ImportantLinks;
