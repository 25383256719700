import React, { useEffect, useState } from 'react'
import getFifLoyalPic from "../../Images/getFifLoyalPic.png";
import search from "../../Images/headericon/search.png"
import { useNavigate } from 'react-router-dom';
import { ApiHelperFunction } from '../../services/api/apiHelpers';


const Chatbotmain = ({ closemodal }) => {
   const [searchData, setSearchData] = useState("");
   const [searchAll, setSearchAll] = useState([]);
   const navigate = useNavigate();


   //for searching functionality
   const getsearchData = async () => {
      let response = await ApiHelperFunction({
         urlPath: "/multiple-search",
         method: "POST",
         data: {
            searchName: searchData,
         },
      });

      if (response?.status) {
         // console.log("searchDataddss", response?.data?.data)
         const newSearchAll = [...(response?.data?.data?.jobSearch || []),
         ...(response?.data?.data?.trainingSearch || []),
         ...(response?.data?.data?.affinityGroupSearch || []),
         ...(response?.data?.data?.initiativesearch || []),
         ...(response?.data?.data?.userSearch || []),
         ...(response?.data?.data?.BulletInBoardSearch || []),
         ...(response?.data?.data?.hallFameSearch || []),
         ...(response?.data?.data?.eventSearch || []),
         // ...(response?.data?.data?.rewardCardSearch || []),
         // ...(response?.data?.data?.giftCardSearch || []),
         ...(response?.data?.data?.postSearch || []),
         // ...(response?.data?.data?.calendarThemeSearch || []),
         ...(response?.data?.data?.advocacySearch || []),
         // ...(response?.data?.data?.advCatSearch || []),
         ...(response?.data?.data?.surveyNameSearch || []),
         ...(response?.data?.data?.surveySearch || []),
            // ...(response?.data?.data?.ChecklistDaySearch || []),
            // ...(response?.data?.data?.checklistSearch || [])

         ]
         setSearchAll(newSearchAll);
      }
   };


   //for navigate job
   const handleJobClick = (item) => {
      navigate(`/jobssingledetails/${item?.jobTitle?.replace(/\s+/g, '-')}/${item?._id}`);
      closemodal();
   }

   //for navigate affinity group
   const handleAffinityClick = (item) => {
      navigate(`/affinity/${item?._id}`);
      closemodal();
   }

   //for navigate training 
   const handleTrainingClick = (item) => {
      // navigate(`/trainingmain`);
      navigate(`/enlargedNewEnrollment/${item?.title?.replace(/\s+/g, '-')}/${item?._id}`)
      closemodal();
   }

   //for navigate initiative 
   const handleInitiativeClick = (item) => {
      navigate(`/${item?.nameOfInitaitive?.replace(/\s+/g, '-')}/${item?._id}`)
      closemodal();
   }

   //for navigate employee
   const handleEmployeeClick = (item) => {
      navigate(`/Profile_rating/${item?._id}`)
      closemodal();
   }

   //for navigate advocacy
   const handleAdvocacyClick = (item) => {
      navigate(`/singleadvocacy/${item?.AdvocacyName?.replace(/\s+/g, '-')}/${item?._id}`);
      closemodal();
   }


   //for navigate post
   const handlePostClick = (item) => {
      navigate(`/singleimage/${item?.docType}/${item?._id}`);
      closemodal();
   }

   //for navigate survey
   const handleSurveyClick = (item) => {
      navigate(`/surveydetails/${item?._id}`);
      closemodal();
   }
   // const handleSurveyClick = (item) => {
   //    navigate("/surveys")
   //    closemodal();
   // }

   //for navigate checklist
   const handleChecklistClick = (item) => {
      navigate("/netWorking")
      closemodal();
   }

   //for navigate bulletin
   const handleBulletinClick = (item) => {
      navigate(`/BulletinView/${item?.eventName?.replace(/\s+/g, '-')}/${item?._id}`)
      closemodal();
   }

   //for navigate event
   const handleEventClick = (item) => {
      navigate(`/EventView/${item?.eventName?.replace(/\s+/g, '-')}/${item?._id}`)
      closemodal();
   }

   //for navigate giftcard
   const handleGiftClick = (item) => {
      navigate(`/profile`);
      closemodal();
   }

   //for navigate top performer
   const handleHallFameClick = (item) => {
      navigate(`/hallOfDetails`)
      closemodal();
   }

   //for navigate top rewardcard
   const handleRewardClick = (item) => {
      navigate(`/profile`)
      closemodal();
   }

   const data = searchAll?.map((item) => {
      // console.log("itemqwer", item)
      return {
         name: (<div style={{ cursor: "pointer" }} >
            {
               item?.docType === "jobs"
                  ?
                  (<div style={{ cursor: "pointer" }} onClick={() => handleJobClick(item)}>{item?.jobTitle}</div>)
                  :
                  item?.docType === "training"
                     ?
                     (<div style={{ cursor: "pointer" }} onClick={() => handleTrainingClick(item)}>{item?.title}</div>)
                     :
                     item?.docType === "affinitygp"
                        ?
                        (<div style={{ cursor: "pointer" }} onClick={() => handleAffinityClick(item)}>{item?.groupName}</div>)
                        :
                        item?.docType === "initiative"
                           ?
                           (<div style={{ cursor: "pointer" }} onClick={() => handleInitiativeClick(item)}>{item?.nameOfInitaitive}</div>)
                           :
                           item?.docType === "users"
                              ?
                              (<div style={{ cursor: "pointer" }} onClick={() => handleEmployeeClick(item)}>{item?.firstName + " " + item?.lastName}</div>)
                              :
                              item?.docType === "advocacyPost"
                                 ?
                                 (<div style={{ cursor: "pointer" }} onClick={() => handleAdvocacyClick(item)}>{item?.AdvocacyName}</div>)
                                 :
                                 item?.docType === "homePost"
                                    ?
                                    (<div style={{ cursor: "pointer" }} onClick={() => handlePostClick(item)}>{item?.description?.slice(0, 20)}</div>)
                                    :
                                    item?.docType === "survey"
                                       ?
                                       (<div style={{ cursor: "pointer" }} onClick={() => handleSurveyClick(item)}>{item?.survey}</div>)
                                       :
                                       // item?.docType === "checklist"
                                       //    ?
                                       //    (<div style={{ cursor: "pointer" }} onClick={() => handleChecklistClick(item)}>{item?.checklist}</div>)
                                       //    :
                                       item?.docType === "surveyname"
                                          ?
                                          (<div style={{ cursor: "pointer" }} onClick={() => handleSurveyClick(item)}>{item?.name}</div>)
                                          :
                                          // item?.docType === "checklistday"
                                          //    ?
                                          //    (<div style={{ cursor: "pointer" }} onClick={() => handleChecklistClick(item)}>{item?.name}</div>)
                                          //    :
                                          item?.docType === "bulletin"
                                             ?
                                             (<div style={{ cursor: "pointer" }} onClick={() => handleBulletinClick(item)}>{item?.eventName}</div>)
                                             :
                                             item?.docType === "eventData"
                                                ?
                                                (<div style={{ cursor: "pointer" }} onClick={() => handleEventClick(item)}>{item?.eventName}</div>)
                                                :
                                                // item?.docType === "giftcard"
                                                //    ?
                                                //    (<div style={{ cursor: "pointer" }} onClick={() => handleGiftClick(item)}>{item?.cardName}</div>)
                                                //    :
                                                item?.docType === "hallfame"
                                                   ?
                                                   (<div style={{ cursor: "pointer" }} onClick={() => handleHallFameClick(item)}>{item?.name}</div>)
                                                   :
                                                   // item?.docType === "rewardcard"
                                                   //    ?
                                                   //    (<div style={{ cursor: "pointer" }} onClick={() => handleRewardClick(item)}>{item?.cardName}</div>)
                                                   //    :
                                                   (<p>There is no data to search</p>)

            }
         </div >),

         desc: (<div style={{ cursor: "pointer" }} >
            {
               item?.docType === "jobs"
                  ?
                  (<div style={{ cursor: "pointer" }} onClick={() => handleJobClick(item)}>{item?.description}</div>)
                  :
                  item?.docType === "training"
                     ?
                     (<div style={{ cursor: "pointer" }} onClick={() => handleTrainingClick(item)}>{item?.description}</div>)
                     :
                     item?.docType === "affinitygp"
                        ?
                        (<div style={{ cursor: "pointer" }} onClick={() => handleAffinityClick(item)}>{item?.purpose}</div>)
                        :
                        item?.docType === "initiative"
                           ?
                           (<div style={{ cursor: "pointer" }} onClick={() => handleInitiativeClick(item)}>{item?.description}</div>)
                           :
                           item?.docType === "users"
                              ?
                              (<div style={{ cursor: "pointer" }} onClick={() => handleEmployeeClick(item)}>{item?.firstName + " " + item?.lastName}</div>)
                              :
                              item?.docType === "advocacyPost"
                                 ?
                                 (<div style={{ cursor: "pointer" }} onClick={() => handleAdvocacyClick(item)}>{item?.desc}</div>)
                                 :
                                 item?.docType === "homePost"
                                    ?
                                    (<div style={{ cursor: "pointer" }} onClick={() => handlePostClick(item)}>{item?.description?.slice(0, 20)}</div>)
                                    :
                                    item?.docType === "survey"
                                       ?
                                       (<div style={{ cursor: "pointer" }} onClick={() => handleSurveyClick(item)}>{item?.survey}</div>)
                                       :

                                       item?.docType === "surveyname"
                                          ?
                                          (<div style={{ cursor: "pointer" }} onClick={() => handleSurveyClick(item)}>{item?.name}</div>)
                                          :

                                          item?.docType === "bulletin"
                                             ?
                                             (<div style={{ cursor: "pointer" }} onClick={() => handleBulletinClick(item)}>{item?.addNotes}</div>)
                                             :
                                             item?.docType === "eventData"
                                                ?
                                                (<div style={{ cursor: "pointer" }} onClick={() => handleEventClick(item)}>{item?.notes}</div>)
                                                :

                                                item?.docType === "hallfame"
                                                   ?
                                                   (<div style={{ cursor: "pointer" }} onClick={() => handleHallFameClick(item)}>{item?.reason}</div>)
                                                   :

                                                   (<p>There is no data to search</p>)

            }
         </div >),

      };
   });

   useEffect(() => {
      let debounce = setTimeout(() => {
         getsearchData();
      }, 100);

      return () => clearTimeout(debounce);
   }, [searchData]);

   return (
      <>

         <div className="aligeneratedivbgmainactv" onClick={() => closemodal()}>
            <div className='aligeneratedivbgmain' onClick={(e) => e.stopPropagation()}>
               <div className='aigeneratedimgdiv'>
                  <img src={getFifLoyalPic} alt="" />
                  <div className='aigeneratedtextdiv'>
                     <h4 className='welaigebenttettxt'>Welcome To</h4>
                     <p className='welaigsertxctnttettxt'>Elite Search</p>
                  </div>

                  <div className='inputaingenertsteddiv'>
                     <input
                        type='text'
                        name="searchData"
                        id=""
                        placeholder="Search here"
                        value={searchData}
                        onChange={(e) => setSearchData(e.target.value)}
                     />
                     <div className='serchimngsdiv'>
                        <img src={search} alt='...' />
                     </div>
                  </div>
               </div>
               <div className='aigeneratedimgdivbottom'>
                  <p className='aigenertatabchatetxt'>Elite has generated the results
                  </p>
                  {
                     data?.slice(0, 1)?.map((item) => {
                        return (
                           <p className='aigenertatabchatetxthtrtstpp'>
                              {item?.desc ? item?.desc : "No generated results"}
                           </p>
                        )
                     })
                  }


                  <div>
                     <p className='aigenertatabchatetxt'>Sugested Result</p>
                     <ul className='sertcstresulutul'>
                        {
                           data?.map((item, ind) => {
                              return (
                                 <li className='sertcstresulutli' key={ind}>
                                    <p className='sertcstresulutultettxtpp'>{item?.name ? item?.name : "No suggested result"}</p>
                                 </li>
                              )
                           })
                        }


                        {/* <li className='sertcstresulutli'>
                               <p className='sertcstresulutultettxtpp'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque, temporibus!</p> 
                            </li>
                            <li className='sertcstresulutli'>
                               <p className='sertcstresulutultettxtpp'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque, temporibus!</p> 
                            </li>
                            <li className='sertcstresulutli'>
                               <p className='sertcstresulutultettxtpp'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque, temporibus!</p> 
                            </li>
                            <li className='sertcstresulutli'>
                               <p className='sertcstresulutultettxtpp'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque, temporibus!</p> 
                            </li> */}
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Chatbotmain
