import React, { useEffect } from 'react'
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { getFollowerData, getFollowingData } from '../../redux/slices/badgeSlice';

const Fllowmodal = ({ 
    closemodal,
    followin
}) => {
const dispatch=useDispatch();

useEffect(()=>{
    dispatch(getFollowingData());
    dispatch(getFollowerData());
},[])

//for remove follower functionality
const handleRemoveClick=async ()=>{
    let response = await ApiHelperFunction({
        urlPath: `/remove-follower/${followin?.fId}`,
        method: "GET",
    });
    if (response?.status) {
        toast?.success(response?.data?.message)
        closemodal();
        dispatch(getFollowerData());
        
    } else {

    }
}

//for unfollow functionality
const handleUnfollowClick=async ()=>{
    let response = await ApiHelperFunction({
        urlPath: `/unfollow/${followin?.fId}`,
        method: "GET",
    });
    if (response?.status) {
        toast?.success(response?.data?.message)
        closemodal();
        dispatch(getFollowingData());
    } else {
    }
}


 //for follow functionality
 const handleFollowClick = async () => {
    let data = {
      receiverId: followin?.fId,
    };

    let response = await ApiHelperFunction({
      urlPath: "/send-friend-request",
      method: "POST",
      data: data,
    });

    if (response?.status === 200) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.response?.data?.message);
    }
  }

    return (
        <>
        {
            followin?.followingName && ( <section className='followmodalmain'>
            <div className='followmodalmainbg'>
                <div className='unfollowcrsdivfkx'>
                    <p className='unfollowdfpppbk'>Unfollow</p>
                    <div className='crossflwax' onClick={() => closemodal()}>
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div className='unfoolowttxyupdiv'>
                    <div className='unfoolowttxyupdivdicflx'>

                        <div className='profilefollowimg'>
                            <img src={followin?.followingImage} alt="..." />
                        </div>

                        <p className='unfoolowttxyupllktxt'>Are you unfollow {followin?.followingName}?</p>
                    </div>
                    <div className='canclebtbnunfolowbtndivflx'>
                        <button className='canclebtbndiv' onClick={() => closemodal()}>
                            Cancel
                        </button>
                        <button className='unfolowbtndiv' onClick={handleUnfollowClick}>
                            Unfollow
                        </button>
                    </div>
                </div>
            </div>
        </section>)
        }

        {
            followin?.followerName && (
                <section className='followmodalmain'>
                <div className='followmodalmainbg'>
                    <div className='unfollowcrsdivfkx'>
                        <p className='unfollowdfpppbk'>Remove</p>
                        <div className='crossflwax' onClick={() => closemodal()}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div className='unfoolowttxyupdiv'>
                        <div className='unfoolowttxyupdivdicflx'>
    
                            <div className='profilefollowimg'>
                                <img src={followin?.followedByImage} alt="..." />
                            </div>
    
                            <p className='unfoolowttxyupllktxt'>Are you remove {followin?.followerName}?</p>
                        </div>
                        <div className='canclebtbnunfolowbtndivflx'>
                            <button className='canclebtbndiv' onClick={() => closemodal()}>
                                Cancel
                            </button>
                            <button className='unfolowbtndiv' onClick={handleFollowClick}>
                                Follow Back
                            </button>
                            <button className='unfolowbtndiv' onClick={handleRemoveClick}>
                                Remove
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            )
        }
           
        </>
    )
}

export default Fllowmodal