import React, { useEffect, useMemo, useState } from "react";
import ProfileImg1 from "../../Images/No-Image-Placeholder.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllPosts, toggleDescription } from "../../redux/slices/postSlice";
import { toast } from "react-hot-toast";
import { useAuthCtx } from "../../context/AuthCtx";
import Delete from "../../Images/headericon/Delete.png";
import EmojiPicker from "emoji-picker-react";
import { BsEmojiSmileFill } from "react-icons/bs";
import post from "../../Images/headericon/post_new.png"
import empUserImg from "../../Images/Icons/PNG/Profile (2).png"
import ReactPlayer from "react-player";
import Attach from "../../Images/headericon/Attach.png";
import Comment from "../../Images/headericon/Comment.png";
import Like from "../../Images/headericon/Like.png";
import ReactionLike from "../../Images/headericon/ReactionLike.png";
import ReactionCelebrate from "../../Images/headericon/ReactionCelebrate.png";
import ReactionLove from "../../Images/headericon/ReactionLove.png";
import ReactionSupport from "../../Images/headericon/ReactionSupport.png";
import ReationCurious from "../../Images/headericon/ReationCurious.png";
import ReationInsightful from "../../Images/headericon/ReationInsightful.png";
import Edit from "../../Images/headericon/Edit.png";
import reporticon from "../../Images/headericon/reporticon.png"


import repost from "../../Images/Repost Icon.png";
import moment from "moment";
import RepostModal from "../../Component/Modal/RepostModal";
import Replyeditmodal from "../../Component/Modal/Replyeditmodal";
import Commenteditmodal from "../../Component/Modal/Commenteditmodal";
import { ApiHelperFunction, fileUpload } from "../../services/api/apiHelpers";
import EditPostModal from "../../SeprateModal/EditPostModal";
import CommentModal from "../../SeprateModal/CommentModal";
import Fancybox from "../../Component/FAncyBox";
import SaraTylorRepost from "../../Component/SaraTylorRepost";
import share from "../../Images/headericon/share.png";
import { RWebShare } from "react-web-share";

import ThankYoucard from "../../Images/ThankYoucard.png";
import HappyBirthdayCard from "../../Images/HappyBirthdayCard.png";
import Onboardingwelcomecardclean from "../../Images/Onboardingwelcomecardclean.png";
import HappyAnniversaryCardclean from "../../Images/HappyAnniversaryCardclean.png";
import companyelite from "../../Images/companyelite.png"

export default function SaraTylorProfile({
  userID,
  name,
  description,
  showDescription,
  createdOn,
  postId,
  modalA,
  setModalA,
  setModalB,
  changeShow,
  setModalId,
  imagesArray,
  showComment,
  setCommentId,
  commentData,
  getComment,
  index
}) {
  const domainName = window.location.hostname;
  const splitedDName = domainName?.split('.')[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [emojimodalll, setEmojimodalll] = useState(false);
  const { userData } = useAuthCtx();
  const [commentView, setCommentView] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [modalC, setModalC] = useState(false);
  const [replyLoading, setReplyLoading] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [replyToggle, setReplyToggle] = useState(Array(commentData?.length)?.fill(false));
  // const [replyIndex, setReplyIndex] = useState(1);
  const replyIndex = 1;
  const [indexShow, setIndex] = useState("");
  const [indexShowReply, setIndexReply] = useState(Array(commentData?.replyData?.length)?.fill(false));
  // const [commentData, setCommentData] = useState([]);
  // const [modalA, setModalA] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const[showComment,setShowComment]=useState(false);
  // const [commentIndex, setCommetIndex] = useState(3);
  const commentIndex = 3;
  const [postData, setPostData] = useState({});

  const [comment, setComment] = useState("");
  // const [likeLoading, setLikeLoading] = useState(false);
  const [isEmoji, setIsEmoji] = useState(false);
  const [image, setImage] = useState("")
  const [uploading, setUploading] = useState(false);
  const [isReplyEmoji, setIsReplyEmoji] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [imageReplyURL, setImageReplyURL] = useState("");
  const doc = imagesArray?.docType;
  const docRepost = imagesArray?.postId?.[0]?.docType;
  const [editdeletereport, setEditdeletereport] = useState(false);
  const [editnewthreemodal, setEditnewthreemodal] = useState(false);
  const [editComment, setEditComment] = useState({})
  const [editdeletereportReply, setEditdeletereportReply] = useState(false);
  const [editnewthreemodalReply, setEditnewthreemodalReply] = useState(false);
  const [editCommentReply, setEditCommentReply] = useState({});
  const [repostModal, setRepostModal] = useState(false);
  // const [metaDescription, setMetaDescription] = useState("Good workforce management can enhance greater")

  const [showReaction, setShowReaction] = useState("")

  // console.log("showReactionvv", imagesArray)

  // const [readmoreaaa, setReadmoreaaa] = useState(true)
  // console.log("singleDatass", imagesArray)

  // const [hide, setHide] = useState(false);
  // const [isImageEmoji, setIsImageEmoji] = useState(false)
  // const location = useLocation();


  //add reaction
  // console.log("imagesArray22", imagesArray?.docType);

  //for emojimodal closing functionality by clicking anywhere

  useEffect(() => {
    // setTimeout(() => {
    //   setMetaDescription("www")
    // }, 2000);

    const closeEmojiModalll = (event) => {
      if (!event.target.closest('.emojimodalbg') && !event.target.closest('.reaction')) {
        setEmojimodalll(false);
      }
    };

    document.addEventListener('click', closeEmojiModalll);

    return () => {
      document.removeEventListener('click', closeEmojiModalll);
    };
  }, []);


  //for view Single Advocacy share
  const viewShareAdvocacy = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/update-advocacy-sharing-count/${imagesArray?._id}`,
      method: "PUT",
    });
    if (response.status) {
      console.log("respovbsdiop", response)
    }
  }

  //for linking profle
  const AnotherProfile = async () => {
    if (userID === userData?._id) {
      navigate("/profile");
    } else {
      let response = await ApiHelperFunction({ urlPath: `/get-others-profile/${userID}`, method: "GET" })
      if (response && response?.status) {
        console.log("RESPONSE", response?.data?.data);
        let data = response?.data?.data;
        response && navigate(`/Profile_rating/${userID}`, {
          state: {
            data
          }
        })
      } else {
        // toast.error('Error to fetching another profile data')
      }
    }
  }

  //for doing comment
  const handleComment = async (e) => {
    e.preventDefault();
    if (commentText === "" && imageURL === "") {
      return;
    }
    let urlPath = "/add-comment";
    let data = {
      postID: postId,
      comment: commentText,
      image: imageURL
    };

    console.log("imffggg")
    if (commentText || imageURL) {
      let res = await ApiHelperFunction({ urlPath, method: "POST", data });
      if (res?.status) {
        // toast.success("Comment added successfully");
        getComment();
        dispatch(getAllPosts());
        setCommentText("");
        setImageURL("");
      } else {
        toast.error(res?.data?.message || "Error Fetching User Details");
      }
    } else {
      toast.error("No Comment is Added");
    }
  };


  //image
  const handleImageChange = async (e) => {
    let image = e.target.files[0];
    setUploading(true);

    const form = new FormData();
    form.append("image", image);

    let res = await fileUpload("/image-upload", "POST", form);

    if (res.status) {
      toast.success("Image uploaded successfully");
      setImageURL(res?.image);
    } else {
      toast.error("Error uploading image");
    }
    setUploading(false);
  };

  //reply-image
  const handleReplyImageChange = async (e) => {
    let image = e.target.files[0];
    setReplyLoading(true);

    const form = new FormData();
    form.append("image", image);

    let res = await fileUpload("/image-upload", "POST", form);

    if (res.status) {
      toast.success("Image uploaded successfully");
      setImageReplyURL(res?.image);
    } else {
      toast.error("Error uploading image");
    }
    setReplyLoading(false);
  };

  //delete post
  const handleDelete = async () => {
    if (imagesArray?.docType === "homePost") {
      const res = await ApiHelperFunction({
        urlPath: `/delete-post/${postId}`,
        method: "PUT",
      });
      if (res?.status) {
        toast.success("Deleted successfully");

        dispatch(getAllPosts());
        setModalA(false);
      } else {
        toast.error(res?.message || "Something went wrong");
      }
    }

    if (imagesArray?.docType === "advocacyPost") {
      const res = await ApiHelperFunction({
        urlPath: `/delete-advocacy/${postId}`,
        method: "DELETE",
      });
      if (res?.status) {
        toast.success("Deleted successfully");

        dispatch(getAllPosts());
        setModalA(false);
      } else {
        toast.error(res?.message || "Something went wrong");
      }
    }

    if (imagesArray?.docType === "eventData") {
      const res = await ApiHelperFunction({
        urlPath: `/delete-event/${postId}`,
        method: "DELETE",
      });
      if (res?.status) {
        toast.success("Deleted successfully");

        dispatch(getAllPosts());
        setModalA(false);
      } else {
        toast.error(res?.message || "Something went wrong");
        console.log("ERROR CREATING USER3", res);
      }
    }
    // const res = await ApiHelperFunction({
    //   urlPath: `/delete-post/${postId}`,
    //   method: "PUT",
    // });
    // if (res?.status) {
    //   toast.success("Deleted successfully");

    //   dispatch(getAllPosts());
    //   setModalA(false);
    // } else {
    //   toast.error(res?.message || "Something went wrong");
    //   console.log("ERROR CREATING USER3", res);
    // }
  };

  //add reaction
  // const handleReaction = async () => {
  //   setLikeLoading(true);

  //   const response = await ApiHelperFunction({
  //     urlPath: "/add-reaction",
  //     method: "POST",
  //     data: { postID: postId },
  //   });

  //   if (response.status === 200) {
  //     // toast.success(response?.data?.data?.message);
  //     dispatch(getAllPosts());
  //   } else {
  //     toast.error(response.error);
  //   }
  //   setTimeout(() => {
  //     setLikeLoading(false);
  //   }, 700);
  // };

  //  console.log("images1230",selectedReaction)


  //for update reaction
  const handleReactionSelect = async (postId, reactionType) => {
    setShowReaction(reactionType)
    setEmojimodalll(false)

    // setLikeLoading(true);
    setIsHovered(true);
    // setSelectedReaction(reactionType);

    const response = await ApiHelperFunction({
      urlPath: "/add-reaction",
      method: "POST",
      data: {
        postID: postId,
        reactionData: reactionType,
      },
    });

    if (response.status === 200) {
      // dispatch(getAllPosts());
      // console.log("Reaction added/updated successfully!");
    } else {
      // console.log("Failed to add/update reaction");
    }
    setTimeout(() => {
      // setLikeLoading(false);
      setIsHovered(false);
    }, 100);
  };

  //Like-dislike functionality
  const handleLikeDislike = async () => {
    const currentReaction = imagesArray?.reactionData?.find(item => item?.userId === userData?._id)?.reactionData;
    const remove = !!currentReaction;

    setShowReaction(remove ? "" : "Like");

    // setLikeLoading(true);
    setIsHovered(true);
    let data = {}
    data = {
      postID: postId,
      reactionData: "Like",
      remove: remove
    }

    const response = await ApiHelperFunction({
      urlPath: "/add-reaction",
      method: "POST",
      data

    });

    setTimeout(() => {
      // setLikeLoading(false);
      setIsHovered(false);
    }, 100);
  };

  //for emoji clicking
  const handleemojiClick = () => {
    // setEmojimodalll(!emojimodalll);
    setIsHovered(true);
    setEmojimodalll(true);
  }

  //reaction for only post
  const reactionIconMemo = useMemo(() => {
    if (showReaction) {
      return showReaction
    } else {
      return imagesArray?.reactionData?.find(item => item?.userId === userData?._id)?.reactionData;
    }
  }, [imagesArray, userData, showReaction])

  console.log("ghjklsdcd", imagesArray)

  //reaction for repost
  const reactionRepostMemo = useMemo(() => {
    if (showReaction) {
      return showReaction
    } else {
      return imagesArray?.reactionData?.find(item => item?.userId === userData?._id)?.reactionData;
    }
  }, [imagesArray, userData, showReaction])


  //for delete comment
  const handleDeleteComment = async (id) => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-comment/${id}`,
      method: "DELETE",
    });
    if (res?.status) {
      toast.success("Comment is deleted successfully");
      getComment();
      dispatch(getAllPosts());
    } else {
      // toast.error(res?.message || "Something went wrong");
      // console.log("ERROR CREATING USER3", res);
    }
  };


  //for reply toggle
  const handleReplyToggle = (index) => {
    const newToggles = [...replyToggle];
    newToggles[index] = !newToggles?.[index];
    setReplyToggle(newToggles);
  };


  //for Reply of comment
  const handleReplyClick = async (id) => {
    if (replyText === "" && imageReplyURL === "") {
      return;
    }
    let urlPath = "/add-reply";
    let data = {
      commentID: id,
      reply: replyText,
      image: imageReplyURL
    };

    // console.log("imffggg", imageReplyURL);
    // return false;
    if (replyText || imageReplyURL) {
      let res = await ApiHelperFunction({ urlPath, method: "POST", data });
      if (res?.status) {
        toast.success("Reply is added successfully");
        getComment();
        dispatch(getAllPosts());
        setReplyText("");
        // setReplyToggle(false);
        setImageReplyURL("");
        setIsReplyEmoji(false);
        setReplyToggle(false)
      } else {
        toast.error(res?.data?.message || "Error Fetching User Details");
      }
    } else {
      toast.error("No Reply is Added");
    }
  };

  //for Navigating Single Page
  const handleSinglePageClick = () => {
    navigate(`/singleimage/${doc}/${postId}`)
  }

  const handleRepostSinglePageClick = () => {
    navigate(`/singleimage/${docRepost}/${imagesArray?.postId?.[0]?._id}`)
  }

  //for open modal for edit delete report
  const handledeleteeditreportClick = (ele, ind) => {
    setIndex((prev) => prev === ind ? "" : ind);
    setEditdeletereport(!editdeletereport);
    // console.log(ele?._id, index, "bmw", editdeletereport);
  }

  //for handle edit modal
  const handleeditClick = (ele) => {
    setEditnewthreemodal(!editnewthreemodal)
    setEditComment(ele);
  }


  //for reply open modal for edit delete report
  const handledeleteeditreportReplyClick = (ele, index, i) => {
    setIndexReply((prev) => prev === `${index}${i}` ? "" : `${index}${i}`);
    setEditdeletereportReply(!editdeletereportReply);
    // console.log(ele?._id, index, i, indexShowReply, "bmw", editdeletereportReply);
  }

  //for reply handle edit modal
  const handleeditClickReply = (ele) => {
    setEditnewthreemodalReply(!editnewthreemodalReply)
    setEditCommentReply(ele);
  }


  useEffect(() => {

    const closeEmojiModalll = (event) => {
      if (!event.target.closest('.emojimodalbg') && !event.target.closest('.reaction')) {
        setEmojimodalll(false);
      }
    };

    document.addEventListener('click', closeEmojiModalll);

    return () => {
      document.removeEventListener('click', closeEmojiModalll);
    };
  }, []);


  //for delete reply
  const handleDeletereply = async (id) => {
    // console.log("nmvbrt", id)
    const res = await ApiHelperFunction({
      urlPath: `/deleted-reply/${id}`,
      method: "PUT",
    });
    if (res?.status) {
      toast.success("Reply is deleted successfully");
      getComment();
      dispatch(getAllPosts());
    } else {
      // toast.error(res?.message || "Something went wrong");
      // console.log("ERROR CREATING USER3", res);
    }
  }

  // const handleRepostModal = () => {
  //   setRepostModal(!repostModal);
  // }

  //for  repost in home page
  // const handlePost = async () => {
  //   let data = {
  //     postId: postId,
  //     text: text
  //   }
  //   let response = await ApiHelperFunction({
  //     urlPath: "/repost",
  //     method: "POST",
  //     data: data,
  //   });

  //   if (response?.status === 200) {
  //     console.log("responsenm", response?.data?.msg)
  //     toast.success(response?.data?.msg);
  //     dispatch(getAllPosts());
  //   } else {
  //     toast?.error(response?.response?.data?.msg);
  //   }
  // }



  //for comment modal
  const handleStop = (e) => {
    e.stopPropagation()
  }

  //for reply modal
  const handleCloseReply = (e) => {
    e.stopPropagation();
  }

  //mouse hover leave
  const handleMouseLeave = () => {
    setIsHovered(false);
    // setEmojimodalll(false)

  };

  const textColor = isHovered ? 'red' : 'black';

  // console.log("qwxcnmyu", imagesArray?.repostedBy?.[0]?.rewardCard?.[0]?.cardImage)
  return (
    <div key={index}>

      {
        imagesArray?.repostedBy
          ?
          (
            <>
              <section
                id="sara_tylor"
                // style={{ boxShadow: "1px 1px 5px rgba(0,0,0,0.5)" }}
                onClick={() => setShowModal(false)}
              >
                <SaraTylorRepost
                  imagesArray={imagesArray}
                  AnotherProfile={AnotherProfile}
                  setShowModal={setShowModal}
                  name={name}
                  createdOn={createdOn}
                  showDescription={showDescription}
                  postId={postId}
                />

                <div
                // onClick={() => setIsEmoji(!isEmoji)}
                >
                  <div className="top_area" style={{ position: "relative" }} onClick={() => handleRepostSinglePageClick()}>
                    <div className="left">
                      <div className="profile_img">
                        {imagesArray?.postId?.[0]?.userData?.[0]?.image ? (
                          <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                            src={imagesArray?.postId?.[0]?.userData?.[0]?.image}
                            className="img-fluid"
                            alt="profile"
                          />
                        ) : (
                          <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                            src={empUserImg}
                            className="img-fluid"
                            alt="profile"
                          />
                        )}
                      </div>

                      <div
                        className="details"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="name">
                          <h2 style={{ cursor: "pointer" }} onClick={AnotherProfile}>{imagesArray?.postId?.[0]?.userData?.[0]?.firstName} {imagesArray?.postId?.[0]?.userData?.[0]?.lastName}</h2>
                          <span>
                            {
                              imagesArray?.userType !== "Owner"
                                ?
                                (<>
                                  <img src={imagesArray?.repostedBy?.[0]?.rewardCard?.[0]?.cardImage} className="img-fluid" alt="icon" />

                                </>)
                                :
                                (<></>)
                            }

                          </span>
                        </div>
                        {/* </Link> */}
                        <div className="text">
                          <p>
                            <span>{imagesArray?.postId?.[0]?.userData?.[0]?.userType + " "}, </span>
                            <span>Posted on {moment(imagesArray?.postId?.[0]?.createdOn).format('YYYY - MM - DD')}</span>
                          </p>
                        </div>
                      </div>
                    </div>



                  </div>

                  <div className="" onClick={handleRepostSinglePageClick}>
                    <div className="content"

                      style={{ cursor: "pointer" }
                      }
                    >


                      {imagesArray?.postId?.[0]?.description && (
                        <>
                          {showDescription || imagesArray?.postId?.[0]?.description?.length <= 850 ? (
                            <p>{imagesArray?.postId?.[0]?.description}</p>
                          ) : (
                            <>{imagesArray?.postId?.[0]?.description?.slice(0, 850) + '...'}</>
                          )}
                          {description?.length > 850 && (
                            <Link onClick={() => dispatch(toggleDescription(postId))}>
                              {showDescription ? '...Show Less' : 'Show More...'}
                            </Link>
                          )}
                        </>
                      )}


                    </div>


                    <div className="gallery_area">

                      <div>
                        <Fancybox options={{ infinite: false }}>
                          <p className="all_box">

                            {
                              (imagesArray?.postId?.[0]?.docType === "homePost") ?
                                (<>
                                  {/* {imagesArray?.postId?.[0]?.image?.[0] ? (<img src={imagesArray?.postId?.[0]?.image[0]}
                                    style={{
                                      width: `${imagesArray?.postId?.[0]?.image?.length === 1 ? "100%" : ""
                                        }`,
                                      height: `${imagesArray?.postId?.[0]?.image?.length === 1 ? "auto" : ""
                                        }`,
                                    }} alt="" />) : (<></>)} */}

                                  {imagesArray?.postIdForHomePost?.[0]?.image?.[0] ? (
                                    <>{
                                      imagesArray?.postIdForHomePost?.[0]?.image?.map((ele) => {
                                        return (
                                          <img src={ele}
                                            style={{
                                              width: `${imagesArray?.image?.length === 1 ? "100%" : ""
                                                }`,
                                              height: `${imagesArray?.image?.length === 1 ? "auto" : ""
                                                }`,
                                            }} alt="" />
                                        )
                                      })

                                    }
                                    </>
                                  ) : (<></>)}
                                  {
                                    imagesArray?.postId?.[0]?.video?.[0] ? (<div className='trainingdivmainedt' >
                                      <ReactPlayer
                                        className='reactdivimgmainplrt'
                                        controls
                                        width='40rem'
                                        height='20rem'
                                        playing={false}
                                        url={imagesArray?.postId?.[0]?.video}
                                        // onBuffer={cousrevideoonClick}
                                        config={{
                                          youtube: {
                                            playerVars: { showinfo: 1 }
                                          },
                                          facebook: {
                                            appId: '12345'
                                          },
                                        }}

                                      />
                                    </div>) : (<></>)
                                  }
                                </>) :
                                (imagesArray?.postId?.[0]?.docType === "advocacyPost") ?
                                  (<>
                                    {imagesArray?.postId?.[0]?.image ? (<img src={imagesArray?.postId?.[0]?.image}
                                      style={{
                                        width: `${imagesArray?.postId?.[0]?.image ? "100%" : ""
                                          }`,
                                        height: `${imagesArray?.postId?.[0]?.image ? "auto" : ""
                                          }`,
                                      }} alt="" />) : (<></>)}
                                  </>) :
                                  (imagesArray?.postId?.[0]?.docType === "eventData") ?
                                    (<>
                                      {imagesArray?.postId?.[0]?.image ? (<img src={imagesArray?.postId?.[0]?.image}
                                        style={{
                                          width: `${imagesArray?.postId?.[0]?.image ? "100%" : ""
                                            }`,
                                          height: `${imagesArray?.postId?.[0]?.image ? "auto" : ""
                                            }`,
                                        }} alt="" />) : (<></>)}
                                    </>) :
                                    (<></>)
                            }


                          </p>
                        </Fancybox>
                      </div>
                      <div>



                      </div>

                    </div>

                  </div>
                </div>

                <div className="like_comment_area">

                  <div class="left">

                    <p>{imagesArray?.totalReaction} Reaction</p>
                  </div>

                  {imagesArray?.totalComment !== 0 && (
                    <div className="right">
                      <h6>
                        <p>{imagesArray?.totalComment} Comment</p>

                      </h6>
                    </div>
                  )}
                </div>

                <div className="reaction_area">

                  <>
                    <button
                      className="reaction"
                      onClick={handleLikeDislike}
                      onMouseEnter={handleemojiClick}
                      onMouseLeave={handleMouseLeave}
                      style={{ color: textColor }}
                    >

                      {reactionRepostMemo
                        ?
                        reactionRepostMemo === "like" ? (<img src={ReactionLike} alt="..." className="likeIconImgg" />) :
                          reactionRepostMemo === "love" ? (<img src={ReactionLove} alt="..." className="likeIconImgg" />) :
                            reactionRepostMemo === "support" ? (<img src={ReactionSupport} alt="..." className="likeIconImgg" />) :
                              reactionRepostMemo === "celebrate" ? (<img src={ReactionCelebrate} alt="..." className="likeIconImgg" />) :
                                reactionRepostMemo === "curious" ? (<img src={ReationCurious} alt="..." className="likeIconImgg" />) :
                                  reactionRepostMemo === "insightful" ? (<img src={ReationInsightful} alt="..." className="likeIconImgg" />)
                                    :
                                    (<img src={Like} alt="..." className="likeIconImg" />)
                        :
                        (<img src={Like} alt="..." className="likeIconImg" />)

                      }

                      <span style={{ color: "black", marginLeft: "2px" }}>Reaction</span>


                    </button>
                  </>


                  {/* emoji modal */}
                  {emojimodalll &&
                    <div className='emojimodalbg'>
                      <button className="reaction">
                        <img src={ReactionLike} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "like")} />
                      </button>
                      <button className="reaction">
                        <img src={ReactionLove} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "love")} />
                      </button>
                      <button className="reaction">
                        <img src={ReactionSupport} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "support")} />
                      </button>
                      <button className="reaction">
                        <img src={ReactionCelebrate} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "celebrate")} />
                      </button>
                      <button className="reaction">
                        <img src={ReationCurious} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "curious")} />
                      </button>
                      <button className="reaction">
                        <img src={ReationInsightful} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "insightful")} />
                      </button>
                      {/* <button className="reaction">
                  <img src={angry} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "angry")} />
                </button> */}
                    </div>
                  }
                  {/* //   )

                  // } */}


                  <button
                    className="comments"
                    onClick={() => {
                      setModalId(postId);
                      setCommentView(!commentView);
                      setCommentId(postId)
                    }}
                  >
                    <img src={Comment} alt="..." className="chatIconImg" />

                    <span>Comment</span>
                  </button>

                  {/* {
                    imagesArray?.userType === "Admin" ? (<RWebShare
                      disableNative={true}
                      data={{
                        text: `Elite Employee Experience Hub - Powered by Fractals Global`,
                        url: "https://fractalselite.com/",
                        title: "Elite",
                      }}
                      sites={[
                        "linkedin",
                        "facebook",
                        "twitter",
                        "whatsapp",
                        "mail",
                        "copy",
                      ]}
                      // onClick={() => console.log("shared successfully!")}
                      onClick={(platform) => {

                        if (platform === "copy") {
                          const textToCopy = `${BASE_URL}`;
                          navigator.clipboard.writeText(textToCopy).then(() => {
                            toast.success("Link copied to clipboard!");
                          });
                        } else {
                          // Handle other platforms
                          // console.log("Shared successfully on", platform);
                        }
                      }}

                    >
                      <button className="share">

                        <img src={share} alt="..." className="shareIconImg" />
                        <span style={{ paddingLeft: "5px" }}>Share</span>
                      </button>

                    </RWebShare>) : ( */}

                  {
                    imagesArray?.docType === "giftcard"
                      ?
                      (<></>)
                      :
                      (
                        <button className="share" onClick={handleRepostSinglePageClick}>
                          <img src={repost} alt="..." className="shareIconImg"
                          //  onClick={handlePost} 
                          />
                          <span style={{ paddingLeft: "5px" }}>Repost</span>
                        </button>
                      )
                  }

                  {/* //     )
                  // } */}


                </div>


                {/****comment show start*****/}
                <div

                // onClick={handleCloseClick}
                >
                  {showComment && commentView && (
                    <>

                      <div className="profilepicimagecommnetfflx" onClick={handleCloseReply}>
                        <div className="profile_picimgmain">
                          {userData?.image ? (
                            <img
                              src={userData?.image}
                              className="img-fluid"
                              alt="profile"
                            />
                          ) : (
                            <img
                              src={ProfileImg1}
                              className="img-fluid"
                              alt="profile"
                            />
                          )}
                        </div>
                        <div className="textinptwodth">
                          <form>
                            <div className="form-group rplytxtdivrply">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                aria-describedby="textHelp"
                                placeholder="Write a comment..."
                                value={commentText}
                                onChange={(e) => setCommentText(e.target.value)}
                              />

                              <div className="inpCmntAttach">
                                <img src={Attach} alt="..." />
                                <input
                                  type="file"
                                  class="form-control"
                                  id="hostedBy"
                                  placeholder="Choose a photo"
                                  value={image}
                                  name="hostedBy"

                                  onChange={handleImageChange}
                                />
                              </div>

                              {uploading ? <p style={{
                                position: 'absolute',
                                right: 0,
                                top: '-84%'
                              }}>image uploading......</p> : null}

                              <div onClick={(e) => handleStop(e)}>
                                <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsEmoji(prev => !prev)} />
                                {isEmoji &&
                                  <EmojiPicker onEmojiClick={(emojiObject) => setCommentText(prev => prev + emojiObject.emoji)} />
                                }
                              </div>
                              <div className="postimgdivmn" onClick={handleComment}>
                                <img src={post} alt="..." />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="commentshow_area">
                        {/* {
                commentLoading
                  ?
                  <p className="d-flex justify-content-center"><Loader /></p>
                  : */}
                        <div >

                          {commentData?.length > 0 ? (
                            commentData?.slice(0, commentIndex)?.map((item, index) => {
                              console.log("COMMDATA", item);
                              return (
                                <>
                                  <div className="profile_picimgmaindiv" key={index}>
                                    <div className="profile_picimgmain">
                                      {item?.Userimage ? (
                                        <img
                                          src={item?.Userimage}
                                          className="img-fluid"
                                          alt="profile"
                                        />
                                      ) : (
                                        <img
                                          src={ProfileImg1}
                                          className="img-fluid"
                                          alt="profile"
                                        />
                                      )}
                                    </div>

                                    <div className="wrapboxdivbgwh">
                                      <div className="man_details">
                                        <div className="wrap_box">
                                          <div className="editnamedeletereportflx">
                                            <div className="name">
                                              <h4>{item?.firstName + " " + item?.lastName}</h4>
                                            </div>

                                            {
                                              (userData?._id === item?.userId) && (<div className="threedotsdktreportedt" onClick={() => handledeleteeditreportClick(item, index)}>
                                                <i className="fa-solid fa-ellipsis"></i>

                                                {
                                                  indexShow === index &&
                                                  // editdeletereport &&
                                                  <div
                                                    className="editdeleteupdatereportmodal"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                    }}
                                                  >

                                                    <div className={item?.Userimage === userData?.image ? "editdivimgthreediv" : "editdivimgthree"} onClick={() => handleeditClick(item)}>
                                                      <img src={Edit} alt="..." />
                                                    </div>
                                                    <div className={item?.Userimage === userData?.image ? "editdivimgthreedeletediv" : "editdivimgthreedelete"} onClick={() => handleDeleteComment(item?._id)}>
                                                      <img src={Delete} alt="..." />
                                                    </div>
                                                    <div className="editdivimgthreereport">
                                                      <img src={reporticon} alt="..." />
                                                    </div>
                                                  </div>}

                                              </div>)
                                            }


                                          </div>

                                          {item?.image ? (
                                            <div className='imagesinglepost'>
                                              <img
                                                src={item?.image}
                                                className="img-fluid"
                                                alt=""
                                              />
                                            </div>
                                          ) : (
                                            <></>
                                          )}


                                          <div className="text">
                                            <p>{item?.comment}</p>
                                          </div>
                                        </div>

                                      </div>

                                      <div className="rplydkltflxmainrply">
                                        <div className="">
                                          <div className="dltimgmain">
                                            <p className="rplyrxrpppkjk" onClick={() => handleReplyToggle(index)} >Reply</p>
                                          </div>
                                        </div>
                                        {/* <div className="rplydltbtndiv"> */}
                                        {/* <p className="rplydltbtndivpp">Delete</p> */}
                                        {/* <div className="dltimgmain" >
                                      <img src={Delete} alt="..." />
                                    </div> */}
                                        {/* </div> */}
                                      </div>


                                      {
                                        replyToggle?.[index] && (
                                          <>
                                            <div className="profilepicimagecommnetfflxdivmm">
                                              <div className="profile_picimgmain">
                                                {userData?.image ? (
                                                  <img
                                                    src={userData?.image}
                                                    className="img-fluid"
                                                    alt="profile"
                                                  />
                                                ) : (
                                                  <img
                                                    src={ProfileImg1}
                                                    className="img-fluid"
                                                    alt="profile"
                                                  />
                                                )}
                                              </div>

                                              <div className="textinptwodth">
                                                <form>
                                                  <div className=" form-group rplytxtdivrply">
                                                    <input
                                                      type="text"
                                                      placeholder="Reply"
                                                      class="form-control"
                                                      name="replyText"
                                                      value={replyText}
                                                      onChange={(e) => setReplyText(e.target.value)}
                                                    />

                                                    <div className="inpCmntAttach">
                                                      <img src={Attach} alt="..." />
                                                      <input
                                                        type="file"
                                                        class="form-control"
                                                        id="images"
                                                        placeholder="Choose a photo"
                                                        name="hostedBy"

                                                        onChange={handleReplyImageChange}
                                                      />
                                                    </div>
                                                    <div className="" onClick={(e) => handleCloseReply(e)}>
                                                      <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsReplyEmoji(prev => !prev)} />
                                                      {isReplyEmoji &&
                                                        <EmojiPicker onEmojiClick={(emojiObject) => setReplyText(prev => prev + emojiObject.emoji)} />
                                                      }
                                                    </div>
                                                    <div className="postimgdivmn">
                                                      <img src={post} alt="..." onClick={() => handleReplyClick(item?._id)} />
                                                    </div>

                                                  </div>


                                                  {replyLoading ? <p style={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: '-84%'
                                                  }}>Reply uploading......</p> : null}


                                                </form>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }




                                    </div>
                                  </div >
                                  {
                                    item?.replyData?.slice(0, 1)?.map((item, i) => {
                                      return (
                                        <>
                                          <div className="profilepicimgmaintxtflx">
                                            <div className="profile_picimgmain" key={i}>
                                              {item?.UserImage ? (
                                                <img
                                                  src={item?.UserImage}
                                                  className="img-fluid"
                                                  alt="profile"
                                                />
                                              ) : (
                                                <img
                                                  src={ProfileImg1}
                                                  className="img-fluid"
                                                  alt="profile"
                                                />
                                              )}
                                            </div>
                                            <div className="wrapboxdivbgwh">
                                              <div className="man_details">
                                                <div className="wrap_box">
                                                  <div className="editnamedeletereportflx">
                                                    <div className="name">
                                                      <h4>{item?.firstName + " " + item?.lastName}</h4>
                                                    </div>
                                                    {
                                                      (userData?._id === item?.userID) && (<div className="threedotsdktreportedt" onClick={() => handledeleteeditreportReplyClick(item, index, i)}>
                                                        <i className="fa-solid fa-ellipsis"></i>

                                                        {
                                                          indexShowReply === `${index}${i}` &&
                                                          // editdeletereportReply &&
                                                          <div
                                                            className="editdeleteupdatereportmodal"
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                            }}
                                                          >

                                                            <div className="editdivimgthreediv" onClick={() => handleeditClickReply(item)}>
                                                              <img src={Edit} alt="..." />
                                                            </div>
                                                            <div className="editdivimgthreedeletediv" onClick={() => handleDeletereply(item?._id)}>
                                                              <img src={Delete} alt="..." />
                                                            </div>
                                                            <div className="editdivimgthreereport">
                                                              <img src={reporticon} alt="..." />
                                                            </div>
                                                          </div>}

                                                      </div>)
                                                    }
                                                  </div>

                                                  {item?.image ? (
                                                    <img
                                                      src={item?.image}
                                                      className="img-fluid"
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <></>
                                                  )}

                                                  <div className="text">
                                                    <p>{item?.reply}</p>
                                                  </div>

                                                </div>

                                              </div>

                                            </div>
                                          </div>

                                          {/* <div
                                  className="editdivimgthreedeletediv"
                                  onClick={() => handleDeletereply(item?._id)}>
                                  <img src={Delete} alt="..." />
                                </div> */}



                                        </>
                                      )
                                    })

                                  }

                                  {/* {
                          replyToggle[index] && (
                            <>
                              <div className="profilepicimagecommnetfflxdivmm">
                                <div className="profile_picimgmain">
                                  {userData?.image ? (
                                    <img
                                      src={userData?.image}
                                      className="img-fluid"
                                      alt="profile"
                                    />
                                  ) : (
                                    <img
                                      src={ProfileImg1}
                                      className="img-fluid"
                                      alt="profile"
                                    />
                                  )}
                                </div>

                                <div className="textinptwodth">
                                  <form>
                                    <div className=" form-group rplytxtdivrply">
                                      <input
                                        type="text"
                                        placeholder="Reply"
                                        class="form-control"
                                        name="replyText"
                                        value={replyText}
                                        onChange={(e) => setReplyText(e.target.value)}
                                      />

                                      <div className="inpCmntAttach">
                                        <img src={Attach} alt="..." />
                                        <input
                                          type="file"
                                          class="form-control"
                                          id="images"
                                          placeholder="Choose a photo"
                                          name="hostedBy"

                                          onChange={handleReplyImageChange}
                                        />
                                      </div>
                                      <div className="">
                                        <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsReplyEmoji(prev => !prev)} />
                                        {isReplyEmoji &&
                                          <EmojiPicker onEmojiClick={(emojiObject) => setReplyText(prev => prev + emojiObject.emoji)} />
                                        }
                                      </div>
                                      <div className="postimgdivmn">
                                        <img src={post} alt="..." onClick={() => handleReplyClick(item?._id)} />
                                      </div>

                                    </div>


                                    {replyLoading ? <p style={{
                                      position: 'absolute',
                                      right: 0,
                                      top: '-84%'
                                    }}>Reply uploading......</p> : null}


                                  </form>
                                </div>
                              </div>
                            </>
                          )
                        } */}
                                  {/* 1st comment show then reply is hide but click the reply btn then show the input field */}



                                  {item?.replyData?.length > 1 && item?.replyData?.length !== replyIndex && (
                                    <div className="ShowMoreDialogue_btn">
                                      <butsetEmojimodalton
                                        className="btn"
                                        // onClick={() => setReplyIndex(item?.replyData?.length)}
                                        onClick={handleSinglePageClick}
                                      >
                                        Show More Replies
                                      </butsetEmojimodalton>
                                    </div>
                                  )}

                                  {/* off */}

                                  {/* {
                            replyToggle[index] && (
                              <div className="">
                                <form>
                                  <div className="rplytxtdivrply">
                                    <input
                                      type="text"
                                      placeholder="Reply"
                                      name="replyText"
                                      value={replyText}
                                      onChange={(e) => setReplyText(e.target.value)}
                                    />

                                    <div className="inpCmntAttach">
                                      <i class="fa-solid fa-paperclip"></i>
                                      <input
                                        type="file"
                                        class="form-control"
                                        id="images"
                                        placeholder="Choose a photo"
                                        name="hostedBy"

                                        onChange={handleReplyImageChange}
                                      />
                                    </div>
                                    <div className="">
                                      <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsReplyEmoji(prev => !prev)} />
                                      {isReplyEmoji &&
                                        <EmojiPicker onEmojiClick={(emojiObject) => setReplyText(prev => prev + emojiObject.emoji)} />
                                      }
                                    </div>
                                    <div className="postimgdivmn">
                                      <img src={post} alt="..." onClick={() => handleReplyClick(item?._id)} />
                                    </div>

                                  </div>


                                  {replyLoading ? <p style={{
                                    position: 'absolute',
                                    right: 0,
                                    top: '-84%'
                                  }}>Reply uploading......</p> : null}


                                </form>
                              </div>
                            )
                          } */}
                                </>
                              );
                            })
                          ) : (
                            <p>No comments to show!</p>
                          )}
                        </div>
                        {/* } */}

                        {commentData?.length > 3 && commentData?.length !== commentIndex && (
                          <div className="ShowMoreDialogue_btn">
                            <butsetEmojimodalton
                              className="btn"
                              // onClick={() => setCommetIndex(commentData?.length)}
                              onClick={handleSinglePageClick}
                            >
                              Show More Comments
                            </butsetEmojimodalton>
                          </div>
                        )}


                        {imageReplyURL !== "" && (

                          <div style={{
                            display: "flex", position: "relative",
                            bottom: "0", left: '0', paddingTop: "10px"
                          }}>
                            <img
                              style={{ marginTop: "1rem" }}
                              src={imageReplyURL}
                              alt=""
                              height="100rem"
                              width="100rem"
                            />
                            <div
                              onClick={() => {
                                setImageReplyURL("");
                                setImage('');
                              }}
                            >
                              <i
                                class="fa-solid fa-xmark"
                                style={{
                                  color: "red",
                                  fontSize: "2rem",
                                  cursor: "pointer",
                                }}
                              ></i>
                            </div>
                          </div>

                        )}



                        {imageURL !== "" && (

                          <div style={{
                            display: "flex", position: "relative",
                            bottom: "0", left: '0', paddingTop: "10px"
                          }}>
                            <img
                              style={{ marginTop: "1rem" }}
                              src={imageURL}
                              alt=""
                              height="100rem"
                              width="100rem"
                            />
                            <div
                              onClick={() => {
                                setImageURL("");
                                setImage('')
                              }}
                            >
                              <i
                                class="fa-solid fa-xmark"
                                style={{
                                  color: "red",
                                  fontSize: "2rem",
                                  cursor: "pointer",
                                }}
                              ></i>
                            </div>
                          </div>

                        )}



                        {/* <div style={{height:'100px',width:'100%'}}></div> */}
                        {/* <div className="mycomment_push">

                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      aria-describedby="textHelp"
                      placeholder="Write a comment..."
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                    />

                    <div className="inpCmntAttach">
                      <i class="fa-solid fa-paperclip"></i>
                      <input
                        type="file"
                        class="form-control"
                        id="hostedBy"
                        placeholder="Choose a photo"
                        value={image}
                        name="hostedBy"

                        onChange={handleImageChange}
                      />
                    </div>

                    {uploading ? <p style={{
                      position: 'absolute',
                      right: 0,
                      top: '-84%'
                    }}>image uploading......</p> : null}


                    <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsEmoji(prev => !prev)} />
                    {isEmoji &&
                      <EmojiPicker onEmojiClick={(emojiObject) => setCommentText(prev => prev + emojiObject.emoji)} />
                    }
                    <button className="sendcommentbtn" onClick={handleComment}>
                      <i className="fa-solid fa-paper-plane"></i>
                    </button>
                  </div>
                </form>
              </div> */}
                      </div>
                    </>
                  )
                  }
                </div>
                {/****comment show End*****/}
              </section >
            </>
          )
          :
          (<>
            <section
              id="sara_tylor"
              // style={{ boxShadow: "1px 1px 5px rgba(0,0,0,0.5)" }}
              onClick={() => setShowModal(false)}
            >

              <div >
                <div className="top_area" style={{ position: "relative" }}>
                  <div className="left">
                    <div className="profile_img">
                      {
                        imagesArray?.docType === "giftcard"
                          ?
                          (
                            <>
                              {imagesArray?.senderDetails?.[0]?.image ? (
                                <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                  src={imagesArray?.senderDetails?.[0]?.image}
                                  className="img-fluid"
                                  alt="profile"
                                />
                              ) : (
                                <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                  src={empUserImg}
                                  className="img-fluid"
                                  alt="profile"
                                />
                              )}
                            </>
                          )
                          :
                          imagesArray?.docType === "sendBadge"
                            ?
                            (
                              <>
                                {imagesArray?.senderImage ? (
                                  <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                    src={imagesArray?.senderData?.image}
                                    className="img-fluid"
                                    alt="profile"
                                  />
                                ) : (
                                  <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                    src={empUserImg}
                                    className="img-fluid"
                                    alt="profile"
                                  />
                                )}
                              </>
                            )
                            :
                            (<>
                              {imagesArray?.userData?.[0]?.image ? (
                                <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                  src={imagesArray?.userData?.[0]?.image}
                                  className="img-fluid"
                                  alt="profile"
                                />
                              ) : (
                                <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                  src={empUserImg}
                                  className="img-fluid"
                                  alt="profile"
                                />
                              )}
                            </>)
                      }

                    </div>

                    <div
                      className="details"
                      // onClick={() => navigate(`/singleimage?Singleid=${postId}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <Link to="/Profile_rating"> */}
                      <div className="name">
                        {
                          imagesArray?.docType === "giftcard"
                            ?
                            (<h2 style={{ cursor: "pointer" }} onClick={AnotherProfile}>
                              {imagesArray?.senderDetails?.[0]?.firstName + " " + imagesArray?.senderDetails?.[0]?.lastName}
                            </h2>)
                            :
                            imagesArray?.docType === "sendBadge"
                              ?
                              (<h2 style={{ cursor: "pointer" }} onClick={AnotherProfile}>
                                {imagesArray?.senderData?.firstName + " " + imagesArray?.senderData?.lastName}
                              </h2>)
                              :
                              (
                                <h2 style={{ cursor: "pointer" }} onClick={AnotherProfile}>
                                  {name}
                                </h2>
                              )

                        }
                        {/* <h2 style={{ cursor: "pointer" }} onClick={AnotherProfile}>
                        item?.senderDetails?.[0]?.firstName + " " +item?.senderDetails?.[0]?.lastName
                          {name}
                          </h2> */}

                        {
                          imagesArray?.docType === "giftcard"
                            ?
                            (
                              <span>
                                {
                                  imagesArray?.userType !== "Owner"
                                    ?
                                    (<>  <img src={imagesArray?.senderDetails?.[0]?.rewardCard?.[0]?.cardImage}
                                      className="img-fluid" alt="icon" />
                                      {/* {imagesArray?.userData?.[0]?.rewardCard?.[0]?.cardName} Club */}
                                    </>)
                                    : (<></>)
                                }

                              </span>
                            )
                            :
                            imagesArray?.docType === "sendBadge"
                              ?
                              (
                                <span>
                                  {
                                    imagesArray?.userType !== "Owner"
                                      ?
                                      (<>  <img src={imagesArray?.senderData?.rewardCard?.[0]?.cardImage}
                                        className="img-fluid" alt="icon" />
                                        {/* {imagesArray?.userData?.[0]?.rewardCard?.[0]?.cardName} Club */}
                                      </>)
                                      : (<></>)
                                  }

                                </span>
                              )
                              :
                              (
                                <span>
                                  {
                                    imagesArray?.userType !== "Owner"
                                      ?
                                      (<>  <img src={imagesArray?.userData?.[0]?.rewardCard?.[0]?.cardImage}
                                        className="img-fluid" alt="icon" />
                                        {/* {imagesArray?.userData?.[0]?.rewardCard?.[0]?.cardName} Club */}
                                      </>)
                                      : (<></>)
                                  }

                                </span>
                              )
                        }

                      </div>
                      {/* </Link> */}
                      <div className="text">
                        <p>
                          {/* <span>{userData?.userType + " "}, </span> */}
                          <span>Posted on {createdOn}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {
                    // optionShow 
                    userData?._id === imagesArray?.userData?.[0]?._id || userData?.userType === "Owner"
                      ?
                      (
                        <div
                          className="dot_btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowModal(!showModal);
                            setModalId(postId);
                          }}

                        >
                          <Link to="/profile" className="btn">
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                          </Link>
                          {changeShow && showModal && (
                            <div
                              style={{
                                position: "relative",
                                // overflow: "hidden",
                                backgroundColor: "#fff",
                                // padding: "3px",
                                border: "none",
                                // top: "123px",
                                padding: "3px 8px",
                                marginLeft: "-27px",
                                borderRadius: "4px",
                                cursor: "pointer",
                                boxShadow: "0 0 0.1rem 0",
                                display: "flex"
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >

                              <div className="editimagemain" onClick={(e) => {
                                e.stopPropagation();
                                setModalA(false);
                                setModalB(true);
                                setModalC(true);
                                setPostData(imagesArray);
                              }}>
                                <img src={Edit} alt="..." />
                              </div>
                              <div className="editimagemain" onClick={handleDelete}>
                                <img src={Delete} alt="..." />
                              </div>
                            </div>
                          )}
                        </div>
                      )
                      :
                      (<></>)
                  }

                  {/* <div className="right">
            <div className="add_advocacy">
              <Link to="/" className="btn">
                Add to Advocacy
              </Link>
            </div>
            <div className="in_btn">
              <Link to="/" className="btn">
                <img src={InImg} className="img-fluid" alt="in" />
              </Link>
            </div>
            <div className="dots_btn">
              <Link to="/" className="btn">
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </Link>
            </div>
          </div> */}
                </div>

                {/* <div
            className="content"
            onClick={() => {

              navigate(`/singleimage?Singleid=${postId}`)
            }}
            style={{ cursor: "pointer" }}
          > */}
                <div className="" onClick={handleSinglePageClick}>
                  <div className="content"

                    // onClick={()=>viewSinglepost()}
                    style={{ cursor: "pointer" }
                    }
                  >
                    {/* <p style={{ paddingLeft: "27px" }}>
                {description}
              </p> */}

                    {/* {description && (
                      <>
                        {showDescription || description?.length <= 850 ? (
                          <p>{description}</p>
                        ) : (
                          <>{description?.slice(0, 850) + '...'}</>
                        )}
                        {description?.length > 850 && (
                          <Link onClick={() => dispatch(toggleDescription(postId))}>
                            {showDescription ? '...Show Less' : 'Show More...'}
                          </Link>
                        )}
                      </>
                    )} */}
                    {imagesArray?.link ? (
                      <>
                        <div>
                          <p className='lrmtxtpptxtpp'>{imagesArray?.prefix}</p>
                          <Link to={imagesArray?.link} target="_blank" rel="noopener noreferrer">
                            {imagesArray?.link}
                          </Link>
                          <p className='lrmtxtpptxtpp'>{imagesArray?.suffix}</p>
                        </div>
                        <div className="reactplayerivmain" >
                          <ReactPlayer url={imagesArray?.link} controls width="100%"
                            height="100%" />
                        </div>
                      </>
                    ) : (
                      // <p className='lrmtxtpptxtpp'>{post?.description}</p>
                      <>
                        {description && (
                          <>
                            {showDescription || description?.length <= 850 ? (
                              <p>{description}</p>
                            ) : (
                              <>{description?.slice(0, 850) + '...'}</>
                            )}
                            {description?.length > 850 && (
                              <Link onClick={() => dispatch(toggleDescription(postId))}>
                                {showDescription ? '...Show Less' : 'Show More...'}
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {/* {description && (
                <>
                  {showDescription ? (
                    <p>{description}</p>
                  ) : (
                    <>{description?.slice(0, 350) + '...'}</>
                  )}
                  {description && (
                    <Link onClick={() => dispatch(toggleDescription(postId))}>
                      {showDescription ? '...Show Less' : 'Show More...'}
                    </Link>
                  )}
                </>
              )} */}



                    {/* {readmoreaaa && <p className="ReadMore" style={{ color: "rgb(255 66 66)", fontSize: "16px", lineHeight: "20px", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "7px" }}>
                Read More
              </p>} */}
                  </div>

                  {/* <div className="content" onClick={() => navigate(`/singleimage`, {
            state: {
              Singleid: postId,
              setModalId: setModalId,
              showComment: showComment,
              modalA: modalA,
              setModalA: setModalA,
            }
          })} style={{ cursor: "pointer" }} >
          
            <p>{description}</p>
          </div> */}


                  <div className="gallery_area">

                    <div>
                      <Fancybox options={{ infinite: false }}>
                        <p className="all_box">

                          {
                            (imagesArray?.docType === "homePost") ?
                              (<>
                                {/* {imagesArray?.image?.[0] ? (<img src={imagesArray?.image[0]}
                                  style={{
                                    width: `${imagesArray?.image?.length === 1 ? "100%" : ""
                                      }`,
                                    height: `${imagesArray?.image?.length === 1 ? "auto" : ""
                                      }`,
                                  }} alt="" />) : (<></>)} */}


                                {imagesArray?.image?.[0] ? (
                                  <>{
                                    imagesArray?.image?.map((ele) => {
                                      return (
                                        <img src={ele}
                                          style={{
                                            width: `${imagesArray?.image?.length === 1 ? "100%" : ""
                                              }`,
                                            height: `${imagesArray?.image?.length === 1 ? "auto" : ""
                                              }`,
                                          }} alt="" />
                                      )
                                    })

                                  }
                                  </>
                                ) : (<></>)}

                                {
                                  imagesArray?.video?.[0] ? (<div className='trainingdivmainedt' >
                                    <ReactPlayer
                                      className='reactdivimgmainplrt'
                                      controls
                                      width='40rem'
                                      height='20rem'
                                      playing={false}
                                      url={imagesArray?.video?.[0]}
                                      // onBuffer={cousrevideoonClick}
                                      config={{
                                        youtube: {
                                          playerVars: { showinfo: 1 }
                                        },
                                        facebook: {
                                          appId: '12345'
                                        },
                                      }}

                                    />
                                  </div>) : (<></>)
                                }
                              </>) :
                              (imagesArray?.docType === "advocacyPost") ?
                                (<>
                                  {imagesArray?.image ? (<img src={imagesArray?.image}
                                    style={{
                                      width: `${imagesArray?.image ? "100%" : ""
                                        }`,
                                      height: `${imagesArray?.image ? "auto" : ""
                                        }`,
                                    }} alt="" />) : (<></>)}
                                </>) :
                                (imagesArray?.docType === "eventData") ?
                                  (<>
                                    {imagesArray?.image ? (<img src={imagesArray?.image}
                                      style={{
                                        width: `${imagesArray?.image ? "100%" : ""
                                          }`,
                                        height: `${imagesArray?.image ? "auto" : ""
                                          }`,
                                      }} alt="" />) : (<></>)}
                                  </>) :


                                  (<></>)
                          }

                          {/* {imagesArray && imagesArray?.image?.length > 0 &&
                  imagesArray?.image?.slice(0, 4)?.map((item, index) => {
                    return (
                      <button
                        data-fancybox="gallery"
                        data-src="https://lipsum.app/id/1/800x600"
                        className="button button--secondary"
                        style={{
                          width: `${imagesArray?.image?.length === 1 ? "100%" : ""
                            }`,
                          height: `${imagesArray?.image?.length === 1 ? "auto" : ""
                            }`,
                        }}
                      >
                        <div className="card-image">
                          <a
                            href={item}
                            data-fancybox="gallery"
                            data-caption="Caption Images 1"
                          >
                            <img
                              src={item}
                              className="img-fluid"
                              alt="Image Gallery"
                            />

                            {imagesArray?.image.length > 4 && index === 3 && (
                              <div className="moreimgOverlay">
                                <div className="count_img">
                                  <p>{imagesArray?.image.length - 4}+</p>
                                </div>
                              </div>
                            )}
                          </a>
                        </div>
                      </button>
                    );
                  })} */}
                        </p>
                      </Fancybox>
                    </div>
                    <div>
                      {
                        (imagesArray?.docType === "giftcard") ?
                          (
                            <>
                              {
                                imagesArray?.cardName === "ThankYou" && (<div className='cardgapbgdiv'>
                                  <div className='thankyoucrdbg' style={{ backgroundImage: `url(${ThankYoucard})` }} >
                                    <div className='profilebgthanky'>
                                      <div className='profilebgthankyimg'>

                                        {
                                          // console.log("kjhgfvbn",imagesArray?.receiverImage)
                                          // console.log("kjhgfvbn",imagesArray?.receiverImage!=="",imagesArray?.receiverImage)


                                          imagesArray?.receiverImage && imagesArray?.receiverImage !== ""
                                            ?
                                            <>
                                              {
                                                <img src={imagesArray?.receiverImage} alt="" />
                                              }
                                            </>
                                            :
                                            <>
                                              <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="..." />
                                            </>
                                        }

                                        {/* {
                                          imagesArray?.receiverImage
                                            ? imagesArray?.receiverImage?.trim() !== ""
                                              ? (
                                                <img src={`${imagesArray.receiverImage}`} alt="..." />
                                              ) : (
                                                <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="..." />
                                              )
                                            : (
                                              <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="..." />
                                            )
                                        } */}

                                      </div>
                                    </div>
                                    <div className='thankyubrdrdiv'>
                                      Thank You
                                    </div>

                                    <div className=''>

                                      <p className='thankyoutxtppcrd'>{imagesArray?.cardDesc}</p>
                                    </div>
                                  </div>
                                </div>)
                              }

                              {
                                imagesArray?.cardName === "BirthDay" && (<div className='cardgapbgdiv'>
                                  <div className='thankyoucrdbg' style={{ backgroundImage: `url(${HappyBirthdayCard})` }}>
                                    {/* <div className='companylitlleimg'>
                                      <img src={companyelite} alt="..." />
                                    </div> */}
                                    <div className='happybrthdivmain'>
                                      <p className='happytxtcrdpp'>Happy</p>
                                      <h4 className='birthtxtcrdpp'>Birthday</h4>
                                    </div>
                                    <div className='profilebgthankywh'>
                                      <div className='profilebgthankyimg'>

                                        {
                                          imagesArray?.receiverImage ? (<img src={imagesArray?.receiverImage} alt="..." />) : (<img src={"https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png"} alt="..." />)
                                        }
                                      </div>
                                    </div>
                                    <div className='happybrthdivmain'>

                                      <h4 className='birthtxtcrdpphead'>{imagesArray?.cardDesc}</h4>
                                    </div>
                                  </div>
                                </div>)
                              }

                              {
                                imagesArray?.cardName === "Onboarding" && (<div className='cardgapbgdiv'>
                                  <div className='thankyoucrdbg' style={{ backgroundImage: `url(${Onboardingwelcomecardclean})` }}>
                                    <div className='companylitlleimg'>
                                      <img src={companyelite} alt="..." />
                                    </div>
                                    <div className='textimagemainwelcmdivflx'>
                                      <div className='algnonbrddivmainwh'>
                                        <p className='wlcmcrdtxtpp'>Welcome Onboard </p>

                                        <h4 className='birthtxtcrdpphead'>{imagesArray?.cardDesc}</h4>
                                      </div>
                                      <div className='algnonbrddivmainimg'>
                                        <div className='profilebgthankywh'>
                                          <div className='profilebgthankyimg'>

                                            {
                                              imagesArray?.receiverImage ? (<img src={imagesArray?.receiverImage} alt="..." />) : (<img src={"https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png"} alt="..." />)
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>)
                              }

                              {
                                imagesArray?.cardName === "Anniversary" && (<div className='cardgapbgdiv'>
                                  <div className='thankyoucrdbg' style={{ backgroundImage: `url(${HappyAnniversaryCardclean})` }}>
                                    <div className='companylitlleimg'>
                                      <img src={companyelite} alt="..." />
                                    </div>
                                    <div className='happyannyversytxtppflx'>
                                      <div className='happyannyversyimgmain'>
                                        <div className='happyannyversyimg'>

                                          {
                                            imagesArray?.receiverImage ? (<img src={imagesArray?.receiverImage} alt="..." />) : (<img src={"https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png"} alt="..." />)
                                          }
                                        </div>
                                      </div>
                                      <div className='txthappyanniversydiv'>

                                        <div className='happypritydivtxt'>
                                          <p className='happyannyvrtxtpp'>Happy Anniversary</p>
                                          <p className='thankyoutxtppcrd'>{imagesArray?.cardDesc}</p>

                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                )
                              }

                            </>
                          )
                          :
                          (<></>)
                      }
                    </div>

                    <div>
                      {
                        (imagesArray?.docType === "sendBadge") ?
                          (
                            <>
                              <div className='cardgapbgdiv'>
                                <div className='thankyoucrdbg' style={{ backgroundImage: `url(${ThankYoucard})` }} >
                                  <div className='profilebgthanky'>
                                    <div className='profilebgthankyimg'>

                                      {
                                        imagesArray?.badgeImage && imagesArray?.badgeImage !== ""
                                          ?
                                          <>
                                            {
                                              <img src={imagesArray?.badgeImage} alt="" />
                                            }
                                          </>
                                          :
                                          <>
                                            <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="..." />
                                          </>
                                      }

                                      {/* {
                                          imagesArray?.receiverImage
                                            ? imagesArray?.receiverImage?.trim() !== ""
                                              ? (
                                                <img src={`${imagesArray.receiverImage}`} alt="..." />
                                              ) : (
                                                <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="..." />
                                              )
                                            : (
                                              <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="..." />
                                            )
                                        } */}

                                    </div>
                                  </div>
                                  <div className='thankyubrdrdiv'>
                                    Congratulations
                                  </div>

                                  <div className=''>

                                    <p className='thankyoutxtppcrd'>{imagesArray?.badgeName} for {imagesArray?.receiverName}</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                          :
                          (<></>)
                      }
                    </div>



                    <div>


                      {/* {
                  (imagesArray?.video !== "") ? (<div className='trainingdivmainedt' >
                    <ReactPlayer
                      className='reactdivimgmainplrt'
                      controls
                      width='40rem'
                      height='20rem'
                      playing={false}
                      url={imagesArray?.video}
                      // onBuffer={cousrevideoonClick}
                      config={{
                        youtube: {
                          playerVars: { showinfo: 1 }
                        },
                        facebook: {
                          appId: '12345'
                        },
                      }}

                    />
                  </div>) : (<></>)
                } */}
                    </div>

                  </div>

                </div>
              </div>

              {/* reaction */}
              <div className="like_comment_area"
              // onClick={() => navigate(`/singleimage?Singleid=${postId}`)}
              >
                {/* {reaction > 0 ? (
            <div className="left">
              <i className="fa-solid fa-thumbs-up"></i>
              <p>{reaction} People Reacted</p>
            </div>
          ) : null} */}


                <div class="left">

                  <p>{imagesArray?.totalReaction} Reaction</p>
                </div>


                {imagesArray?.totalComment !== 0 && (
                  <div className="right">
                    <h6>
                      <span>{imagesArray?.totalComment} Comment</span>

                    </h6>
                    {/* <p>
                <span>1</span> Share
              </p> */}
                  </div>
                )}
              </div>



              <div className="reaction_area">
                {/* {
                  likeLoading ? (
                    <>
                      <p className="d-flex justify-content-center"><Loader /></p>
                    </>
                  ) : ( */}
                <>
                  <button
                    className="reaction"
                    onClick={handleLikeDislike}
                    // onMouseLeave={() => setEmojimodal(false)}
                    onMouseEnter={handleemojiClick}
                    // onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ color: textColor }}
                  >

                    {reactionIconMemo
                      ?
                      reactionIconMemo === "like" ? (<img src={ReactionLike} alt="..." className="likeIconImgg" />) :
                        reactionIconMemo === "love" ? (<img src={ReactionLove} alt="..." className="likeIconImgg" />) :
                          reactionIconMemo === "support" ? (<img src={ReactionSupport} alt="..." className="likeIconImgg" />) :
                            reactionIconMemo === "celebrate" ? (<img src={ReactionCelebrate} alt="..." className="likeIconImgg" />) :
                              reactionIconMemo === "curious" ? (<img src={ReationCurious} alt="..." className="likeIconImgg" />) :
                                reactionIconMemo === "insightful" ? (<img src={ReationInsightful} alt="..." className="likeIconImgg" />)
                                  :
                                  // selectedReaction === "angry" ? (<img src={angry} alt="..." className="likeIconImgg" />) :
                                  (<img src={Like} alt="..." className="likeIconImg" />)
                      :
                      (<img src={Like} alt="..." className="likeIconImg" />)

                    }

                    <span style={{ color: "black", marginLeft: "2px" }}>Reaction</span>



                  </button>

                </>
                {/* )

                } */}

                {/* emoji modal */}
                {emojimodalll &&
                  <div className='emojimodalbg' style={{left:"375px"}}>
                    <button className="reaction">
                      <img src={ReactionLike} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "like")} />
                    </button>
                    <button className="reaction">
                      <img src={ReactionLove} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "love")} />
                    </button>
                    <button className="reaction">
                      <img src={ReactionSupport} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "support")} />
                    </button>
                    <button className="reaction">
                      <img src={ReactionCelebrate} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "celebrate")} />
                    </button>
                    <button className="reaction">
                      <img src={ReationCurious} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "curious")} />
                    </button>
                    <button className="reaction">
                      <img src={ReationInsightful} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "insightful")} />
                    </button>
                    {/* <button className="reaction">
                  <img src={angry} alt="..." className="likeIconImgg" onClick={() => handleReactionSelect(postId, "angry")} />
                </button> */}
                  </div>
                }


                <button
                  className="comments"
                  onClick={() => {
                    setModalId(postId);
                    setCommentView(!commentView);
                    setCommentId(postId)
                  }}
                >
                  <img src={Comment} alt="..." className="chatIconImg" />

                  <span>Comment</span>
                </button>

                {/* {
                  imagesArray?.userType == "Admin" ? (<RWebShare
                    disableNative={true}
                    data={{
                      text: `Elite Employee Experience Hub - Powered by Fractals Global`,
                      url: "https://fractalselite.com/",
                      title: "Elite",
                    }}
                    sites={[
                      "linkedin",
                      "facebook",
                      "twitter",
                      "whatsapp",
                      "mail",
                      "copy",
                    ]}
                    // onClick={() => console.log("shared successfully!")}
                    onClick={(platform) => {

                      if (platform === "copy") {
                        const textToCopy = `${BASE_URL}`;
                        navigator.clipboard.writeText(textToCopy).then(() => {
                          toast.success("Link copied to clipboard!");
                        });
                      } else {
                        // Handle other platforms
                        // console.log("Shared successfully on", platform);
                      }
                    }}

                  >
                    <button className="share">

                      <img src={share} alt="..." className="shareIconImg" />
                      <span style={{ paddingLeft: "5px" }}>Share</span>
                    </button>

                  </RWebShare>) : ( */}

                {
                  imagesArray?.docType === "advocacyPost"
                    ?
                    (<RWebShare
                      disableNative={true}
                      data={{
                        text: `${description}`,
                        url: `http://${splitedDName}.fractalselite.com/singleadvocacy/${imagesArray?.AdvocacyName?.replace(/\s+/g, '-')}/${postId}`,
                        title: "Elite",
                      }}
                      sites={[
                        "linkedin",
                        "facebook",
                        "twitter",
                        "whatsapp",
                        "mail",
                        "copy",
                      ]}
                      // onClick={() => console.log("shared successfully!")}
                      onClick={(platform) => {
                        viewShareAdvocacy();
                        if (platform === "copy") {
                          const textToCopy = `http://${splitedDName}.fractalselite.com/singleadvocacy/${imagesArray?.AdvocacyName?.replace(/\s+/g, '-')}/${postId}`;
                          navigator.clipboard.writeText(textToCopy).then(() => {
                            toast.success("Link copied to clipboard!");
                          });
                        } else {
                          // Handle other platforms
                          // console.log("Shared successfully on", platform);
                        }
                      }}

                    >
                      <button className="share">

                        <img src={share} alt="..." className="shareIconImg" />
                        <span style={{ paddingLeft: "5px" }}>Share</span>
                      </button>

                    </RWebShare>)
                    :
                    imagesArray?.docType === "giftcard"
                      ?
                      (<></>)
                      :
                      imagesArray?.docType === "sendBadge"
                      ?
                      (<></>)
                      :
                      (
                        <button className="share" onClick={handleSinglePageClick}>
                          <img src={repost} alt="..." className="shareIconImg"
                          />
                          <span style={{ paddingLeft: "5px" }}>Repost</span>
                        </button>
                      )

                }


                {/* {
                  imagesArray?.docType === "giftcard"
                    ?
                    (<></>)
                    :
                    (
                      <button className="share" onClick={handleSinglePageClick}>
                        <img src={repost} alt="..." className="shareIconImg"
                        //  onClick={handlePost} 
                        />
                        <span style={{ paddingLeft: "5px" }}>Repost</span>
                      </button>
                    )
                } */}

                {/* <button className="share" onClick={handleRepostModal}>
                  <img src={repost} alt="..." className="shareIconImg" 
                  />
                  <span style={{ paddingLeft: "5px" }}>Repost</span>
                </button> */}
                {/* )
                } */}


              </div>


              {/****comment show start*****/}
              {showComment && commentView && (
                <>

                  <div className="profilepicimagecommnetfflx">
                    <div className="profile_picimgmain">
                      {userData?.image ? (
                        <img
                          src={userData?.image}
                          className="img-fluid"
                          alt="profile"
                        />
                      ) : (
                        <img
                          src={ProfileImg1}
                          className="img-fluid"
                          alt="profile"
                        />
                      )}
                    </div>
                    <div className="textinptwodth">
                      <form>
                        <div className="form-group rplytxtdivrply">
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            aria-describedby="textHelp"
                            placeholder="Write a comment..."
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                          />

                          <div className="inpCmntAttach">
                            <img src={Attach} alt="..." />
                            <input
                              type="file"
                              class="form-control"
                              id="hostedBy"
                              placeholder="Choose a photo"
                              value={image}
                              name="hostedBy"

                              onChange={handleImageChange}
                            />
                          </div>

                          {uploading ? <p style={{
                            position: 'absolute',
                            right: 0,
                            top: '-84%'
                          }}>image uploading......</p> : null}


                          <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsEmoji(prev => !prev)} />
                          {isEmoji &&
                            <EmojiPicker onEmojiClick={(emojiObject) => setCommentText(prev => prev + emojiObject.emoji)} />
                          }
                          <div className="postimgdivmn" onClick={handleComment}>
                            <img src={post} alt="..." />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="commentshow_area">
                    {/* {
                commentLoading
                  ?
                  <p className="d-flex justify-content-center"><Loader /></p>
                  : */}
                    <div>

                      {commentData?.length > 0 ? (
                        commentData?.slice(0, commentIndex)?.map((item, index) => {
                          console.log("COMMDATA", item);
                          return (
                            <>
                              <div className="profile_picimgmaindiv" key={index}>
                                <div className="profile_picimgmain">
                                  {item?.Userimage ? (
                                    <img
                                      src={item?.Userimage}
                                      className="img-fluid"
                                      alt="profile"
                                    />
                                  ) : (
                                    <img
                                      src={ProfileImg1}
                                      className="img-fluid"
                                      alt="profile"
                                    />
                                  )}
                                </div>

                                <div className="wrapboxdivbgwh">
                                  <div className="man_details">
                                    <div className="wrap_box">
                                      <div className="editnamedeletereportflx">
                                        <div className="name">
                                          <h4>{item?.firstName + " " + item?.lastName}</h4>
                                        </div>

                                        {
                                          (userData?._id === item?.userId) && (<div className="threedotsdktreportedt" onClick={() => handledeleteeditreportClick(item, index)}>
                                            <i className="fa-solid fa-ellipsis"></i>

                                            {
                                              indexShow === index &&
                                              // editdeletereport &&
                                              <div
                                                className="editdeleteupdatereportmodal"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              >

                                                <div className={item?.Userimage === userData?.image ? "editdivimgthreediv" : "editdivimgthree"} onClick={() => handleeditClick(item)}>
                                                  <img src={Edit} alt="..." />
                                                </div>
                                                <div className={item?.Userimage === userData?.image ? "editdivimgthreedeletediv" : "editdivimgthreedelete"} onClick={() => handleDeleteComment(item?._id)}>
                                                  <img src={Delete} alt="..." />
                                                </div>
                                                <div className="editdivimgthreereport">
                                                  <img src={reporticon} alt="..." />
                                                </div>
                                              </div>}

                                          </div>)
                                        }


                                      </div>

                                      {item?.image ? (
                                        <div className='imagesinglepost'>
                                          <img
                                            src={item?.image}
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <></>
                                      )}


                                      <div className="text">
                                        <p>{item?.comment}</p>
                                      </div>
                                    </div>

                                  </div>

                                  <div className="rplydkltflxmainrply">
                                    <div className="">
                                      <div className="dltimgmain">
                                        < p className="rplyrxrpppkjk" alt="..." onClick={() => handleReplyToggle(index)}>Reply</p>
                                      </div>
                                    </div>
                                    {/* <div className="rplydltbtndiv"> */}
                                    {/* <p className="rplydltbtndivpp">Delete</p> */}
                                    {/* <div className="dltimgmain" >
                                      <img src={Delete} alt="..." />
                                    </div> */}
                                    {/* </div> */}
                                  </div>


                                  {
                                    replyToggle[index] && (
                                      <>
                                        <div className="profilepicimagecommnetfflxdivmm">
                                          <div className="profile_picimgmain">
                                            {userData?.image ? (
                                              <img
                                                src={userData?.image}
                                                className="img-fluid"
                                                alt="profile"
                                              />
                                            ) : (
                                              <img
                                                src={ProfileImg1}
                                                className="img-fluid"
                                                alt="profile"
                                              />
                                            )}
                                          </div>

                                          <div className="textinptwodth">
                                            <form>
                                              <div className=" form-group rplytxtdivrply">
                                                <input
                                                  type="text"
                                                  placeholder="Reply"
                                                  class="form-control"
                                                  name="replyText"
                                                  value={replyText}
                                                  onChange={(e) => setReplyText(e.target.value)}
                                                />

                                                <div className="inpCmntAttach">
                                                  <img src={Attach} alt="..." />
                                                  <input
                                                    type="file"
                                                    class="form-control"
                                                    id="images"
                                                    placeholder="Choose a photo"
                                                    name="hostedBy"

                                                    onChange={handleReplyImageChange}
                                                  />
                                                </div>
                                                <div className="">
                                                  <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsReplyEmoji(prev => !prev)} />
                                                  {isReplyEmoji &&
                                                    <EmojiPicker onEmojiClick={(emojiObject) => setReplyText(prev => prev + emojiObject.emoji)} />
                                                  }
                                                </div>
                                                <div className="postimgdivmn">
                                                  <img src={post} alt="..." onClick={() => handleReplyClick(item?._id)} />
                                                </div>

                                              </div>


                                              {replyLoading ? <p style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: '-84%'
                                              }}>Reply uploading......</p> : null}


                                            </form>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  }




                                </div>
                              </div >
                              {
                                item?.replyData?.slice(0, 1)?.map((item, i) => {
                                  return (
                                    <>
                                      <div className="profilepicimgmaintxtflx">
                                        <div className="profile_picimgmain" key={i}>
                                          {item?.UserImage ? (
                                            <img
                                              src={item?.UserImage}
                                              className="img-fluid"
                                              alt="profile"
                                            />
                                          ) : (
                                            <img
                                              src={ProfileImg1}
                                              className="img-fluid"
                                              alt="profile"
                                            />
                                          )}
                                        </div>
                                        <div className="wrapboxdivbgwh">
                                          <div className="man_details">
                                            <div className="wrap_box">
                                              <div className="editnamedeletereportflx">
                                                <div className="name">
                                                  <h4>{item?.firstName + " " + item?.lastName}</h4>
                                                </div>
                                                {
                                                  (userData?._id === item?.userID) && (<div className="threedotsdktreportedt" onClick={() => handledeleteeditreportReplyClick(item, index, i)}>
                                                    <i className="fa-solid fa-ellipsis"></i>

                                                    {
                                                      indexShowReply === `${index}${i}` &&
                                                      // editdeletereportReply &&
                                                      <div
                                                        className="editdeleteupdatereportmodal"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                        }}
                                                      >

                                                        <div className="editdivimgthreediv" onClick={() => handleeditClickReply(item)}>
                                                          <img src={Edit} alt="..." />
                                                        </div>
                                                        <div className="editdivimgthreedeletediv" onClick={() => handleDeletereply(item?._id)}>
                                                          <img src={Delete} alt="..." />
                                                        </div>
                                                        <div className="editdivimgthreereport">
                                                          <img src={reporticon} alt="..." />
                                                        </div>
                                                      </div>}

                                                  </div>)
                                                }
                                              </div>

                                              {item?.image ? (
                                                <img
                                                  src={item?.image}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              ) : (
                                                <></>
                                              )}

                                              <div className="text">
                                                <p>{item?.reply}</p>
                                              </div>

                                            </div>

                                          </div>

                                        </div>
                                      </div>

                                      {/* <div
                                  className="editdivimgthreedeletediv"
                                  onClick={() => handleDeletereply(item?._id)}>
                                  <img src={Delete} alt="..." />
                                </div> */}



                                    </>
                                  )
                                })

                              }

                              {/* {
                          replyToggle[index] && (
                            <>
                              <div className="profilepicimagecommnetfflxdivmm">
                                <div className="profile_picimgmain">
                                  {userData?.image ? (
                                    <img
                                      src={userData?.image}
                                      className="img-fluid"
                                      alt="profile"
                                    />
                                  ) : (
                                    <img
                                      src={ProfileImg1}
                                      className="img-fluid"
                                      alt="profile"
                                    />
                                  )}
                                </div>

                                <div className="textinptwodth">
                                  <form>
                                    <div className=" form-group rplytxtdivrply">
                                      <input
                                        type="text"
                                        placeholder="Reply"
                                        class="form-control"
                                        name="replyText"
                                        value={replyText}
                                        onChange={(e) => setReplyText(e.target.value)}
                                      />

                                      <div className="inpCmntAttach">
                                        <img src={Attach} alt="..." />
                                        <input
                                          type="file"
                                          class="form-control"
                                          id="images"
                                          placeholder="Choose a photo"
                                          name="hostedBy"

                                          onChange={handleReplyImageChange}
                                        />
                                      </div>
                                      <div className="">
                                        <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsReplyEmoji(prev => !prev)} />
                                        {isReplyEmoji &&
                                          <EmojiPicker onEmojiClick={(emojiObject) => setReplyText(prev => prev + emojiObject.emoji)} />
                                        }
                                      </div>
                                      <div className="postimgdivmn">
                                        <img src={post} alt="..." onClick={() => handleReplyClick(item?._id)} />
                                      </div>

                                    </div>


                                    {replyLoading ? <p style={{
                                      position: 'absolute',
                                      right: 0,
                                      top: '-84%'
                                    }}>Reply uploading......</p> : null}


                                  </form>
                                </div>
                              </div>
                            </>
                          )
                        } */}
                              {/* 1st comment show then reply is hide but click the reply btn then show the input field */}



                              {item?.replyData?.length > 1 && item?.replyData?.length !== replyIndex && (
                                <div className="ShowMoreDialogue_btn">
                                  <butsetEmojimodalton
                                    className="btn"
                                    // onClick={() => setReplyIndex(item?.replyData?.length)}
                                    onClick={handleSinglePageClick}
                                  >
                                    Show More Replies
                                  </butsetEmojimodalton>
                                </div>
                              )}

                              {/* off */}

                              {/* {
                            replyToggle[index] && (
                              <div className="">
                                <form>
                                  <div className="rplytxtdivrply">
                                    <input
                                      type="text"
                                      placeholder="Reply"
                                      name="replyText"
                                      value={replyText}
                                      onChange={(e) => setReplyText(e.target.value)}
                                    />

                                    <div className="inpCmntAttach">
                                      <i class="fa-solid fa-paperclip"></i>
                                      <input
                                        type="file"
                                        class="form-control"
                                        id="images"
                                        placeholder="Choose a photo"
                                        name="hostedBy"

                                        onChange={handleReplyImageChange}
                                      />
                                    </div>
                                    <div className="">
                                      <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsReplyEmoji(prev => !prev)} />
                                      {isReplyEmoji &&
                                        <EmojiPicker onEmojiClick={(emojiObject) => setReplyText(prev => prev + emojiObject.emoji)} />
                                      }
                                    </div>
                                    <div className="postimgdivmn">
                                      <img src={post} alt="..." onClick={() => handleReplyClick(item?._id)} />
                                    </div>

                                  </div>


                                  {replyLoading ? <p style={{
                                    position: 'absolute',
                                    right: 0,
                                    top: '-84%'
                                  }}>Reply uploading......</p> : null}


                                </form>
                              </div>
                            )
                          } */}
                            </>
                          );
                        })
                      ) : (
                        <p>No comments to show!</p>
                      )}
                    </div>
                    {/* } */}

                    {commentData?.length > 3 && commentData?.length !== commentIndex && (
                      <div className="ShowMoreDialogue_btn">
                        <butsetEmojimodalton
                          className="btn"
                          // onClick={() => setCommetIndex(commentData?.length)}
                          onClick={handleSinglePageClick}
                        >
                          Show More Comments
                        </butsetEmojimodalton>
                      </div>
                    )}


                    {imageReplyURL !== "" && (

                      <div style={{
                        display: "flex", position: "relative",
                        bottom: "0", left: '0', paddingTop: "10px"
                      }}>
                        <img
                          style={{ marginTop: "1rem" }}
                          src={imageReplyURL}
                          alt=""
                          height="100rem"
                          width="100rem"
                        />
                        <div
                          onClick={() => {
                            setImageReplyURL("");
                            setImage('');
                          }}
                        >
                          <i
                            class="fa-solid fa-xmark"
                            style={{
                              color: "red",
                              fontSize: "2rem",
                              cursor: "pointer",
                            }}
                          ></i>
                        </div>
                      </div>

                    )}



                    {imageURL !== "" && (

                      <div style={{
                        display: "flex", position: "relative",
                        bottom: "0", left: '0', paddingTop: "10px"
                      }}>
                        <img
                          style={{ marginTop: "1rem" }}
                          src={imageURL}
                          alt=""
                          height="100rem"
                          width="100rem"
                        />
                        <div
                          onClick={() => {
                            setImageURL("");
                            setImage('')
                          }}
                        >
                          <i
                            class="fa-solid fa-xmark"
                            style={{
                              color: "red",
                              fontSize: "2rem",
                              cursor: "pointer",
                            }}
                          ></i>
                        </div>
                      </div>

                    )}



                    {/* <div style={{height:'100px',width:'100%'}}></div> */}
                    {/* <div className="mycomment_push">

                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      aria-describedby="textHelp"
                      placeholder="Write a comment..."
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                    />

                    <div className="inpCmntAttach">
                      <i class="fa-solid fa-paperclip"></i>
                      <input
                        type="file"
                        class="form-control"
                        id="hostedBy"
                        placeholder="Choose a photo"
                        value={image}
                        name="hostedBy"

                        onChange={handleImageChange}
                      />
                    </div>

                    {uploading ? <p style={{
                      position: 'absolute',
                      right: 0,
                      top: '-84%'
                    }}>image uploading......</p> : null}


                    <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsEmoji(prev => !prev)} />
                    {isEmoji &&
                      <EmojiPicker onEmojiClick={(emojiObject) => setCommentText(prev => prev + emojiObject.emoji)} />
                    }
                    <button className="sendcommentbtn" onClick={handleComment}>
                      <i className="fa-solid fa-paper-plane"></i>
                    </button>
                  </div>
                </form>
              </div> */}
                  </div>
                </>
              )
              }
              {/****comment show End*****/}
            </section >
          </>)
      }


      {/* {modalA && (
        <CreateModal
          closemodal={setModalA}
          activity={"comment"}
          handleComment={handleComment}
          comment={comment}
          setComment={setComment}
          commentData={commentData}
        />
      )} */}
      {
        modalA && (
          <CommentModal
            closemodal={setModalA}
            activity={"comment"}
            handleComment={handleComment}
            comment={comment}
            setComment={setComment}
            commentData={commentData}
          />
        )
      }
      {/* {modalC && (
        <CreateModal
          closemodal={setModalC}
          activity={"editPost"}
          initialValues={postData}
        />
      )} */}

      {
        modalC && (
          <EditPostModal
            description={description}
            imagesArray={imagesArray}
            closemodal={setModalC}
            activity={"editPost"}
            initialValues={postData}
          />
        )
      }
      {editnewthreemodal && <Commenteditmodal
        closemodal={setEditnewthreemodal}
        activity={"comment"}
        postId={postId}
        initialValues={editComment}
        getComment={getComment}
      />}

      {editnewthreemodalReply && <Replyeditmodal
        closemodal={setEditnewthreemodalReply}
        activity={"reply"}
        // postId={postId}
        initialValues={editCommentReply}
        getComment={getComment}
      />}

      {
        repostModal && (<RepostModal
          closemodal={setRepostModal}
          postId={postId}
          imagesArray={imagesArray}
          showDescription={showDescription}
          description={description}
        />)
      }
    </div>
  );
}
