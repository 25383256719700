import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import homeActiveIcon from "../Images/Icons/PNG/Home.png";
// import Homeicon from "../Images/homeicon.png";
import Homeicon from "../Images/Icons/PNG/Home.png";
import onboardIcon from "../Images/Icons/PNG/Onboarding.png";
import engageMent from "../Images/Icons/PNG/Engagement.png";
import Hiringicon from "../../src/Images/Icons/PNG/Hiringicon.png"
import Trainingicon from "../../src/Images/Icons/PNG/Trainingicon.png";
import Survey from "../../src/Images/Icons/PNG/Survey.png";
import Onboarding from "../../src/Images/headericon/Onboarding.png";
import documentReposiy from "../../src/Images/headericon/documentReposiy.png";
import BulletinBoard from "../../src/Images/headericon/BulletinBoard.png";
import TopPerformers from "../../src/Images/headericon/TopPerformers.png";
import Chatbot from "../../src/Images/headericon/Chatbot.png";
import Live from "../../src/Images/headericon/Live.png";
import Openintervuecom from "../../src/Images/headericon/Openintervuecom.png";
import recenttrainingicon from "../../src/Images/headericon/recenttrainingicon.png";
import requestedtrainingicon from "../../src/Images/headericon/requestedtrainingicon.png";
import GrivenceCell from "../../src/Images/headericon/GrivenceCell.png";
import SuggestionBox from "../../src/Images/headericon/SuggestionBox.png";
import Dailypoll from "../../src/Images/headericon/Dailypoll.png";
import Exitmanagement from "../../src/Images/headericon/Exitmanagement.png";
import Initiative from "../../src/Images/headericon/Initiative.png";
import Ideation from "../../src/Images/headericon/Ideation.png";
import SkillRating from "../../src/Images/headericon/SkillRating.png";
import TaskManagement from "../../src/Images/headericon/TaskManagement.png";
import Attendance from "../../src/Images/headericon/Attendance.png";
import Leaverequest from "../../src/Images/headericon/Leaverequest.png";
import CompanyOrgChart from "../../src/Images/headericon/CompanyOrgChart.png";
import Affinity from "../../src/Images/headericon/Affinity.png";
import Mentor from "../../src/Images/headericon/Mentor.png";
import Coach from "../../src/Images/headericon/Coach.png";
import More from "../../src/Images/headericon/More.png";
import BenefitManagement from "../../src/Images/headericon/BenefitManagement.png";
import CompetencyFramework from "../../src/Images/headericon/CompetencyFramework.png";
import CareerFramework from "../../src/Images/headericon/CareerFramework.png";
import GlobalMarketplace from "../../src/Images/headericon/GlobalMarketplace.png";
import ThankyouCard from "../../src/Images/headericon/ThankyouCard.png";
import Redeempoints from "../../src/Images/headericon/Redeempoints.png";
import ClaimPoints from "../../src/Images/headericon/ClaimPoints.png";
import Employeeiconn from "../../src/Images/headericon/Employeeiconn.png";
import Dashboard from "../../src/Images/headericon/Dashboard.png";
import Messages from "../../src/Images/headericon/Messages.png"
import Hiring from "../../src/Images/headericon/Hiring.png";
import advocacy from "../../src/Images/headericon/advocacy.png";
import training from "../../src/Images/headericon/training.png";
import events from "../../src/Images/headericon/events.png";
import rewardpoints from "../../src/Images/headericon/rewardpoints.png"
import { useAuthCtx } from "../context/AuthCtx";
import "../Layout/footer.css"
export default function Footer() {
  const [moreicon, setMoreicon] = useState(false);
  const { getUserDetails, userData } = useAuthCtx();
  return (
    <section className='ftr_section_main'>
      <div className="container-fluid">
        <div className='lstnv_mn'>
          <ul className="navbar-nav mr-auto">
          <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    <div className="icon">
                      <img
                        src={Homeicon}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={homeActiveIcon}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="name">Home</div>
                  </NavLink>
                </li>


                <li className="nav-item">
                  <NavLink className="nav-link" to="/engagementcalender">
                    <div className="icon">
                      <img
                        src={engageMent}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={engageMent}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="name">Events</div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/advocacy">
                    <div className="icon">
                      <img
                        src={onboardIcon}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={onboardIcon}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="name">Advocacy</div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/surveys">
                    <div className="icon">
                      <img
                        src={Survey}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={Survey}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="name">Survey</div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/trainingmain">
                    <div className="icon">
                      <img
                        src={Trainingicon}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={Trainingicon}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="name">Learning</div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/jobsReferDetail">
                    <div className="icon">
                      <img
                        src={Hiringicon}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={Hiringicon}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="name">Hiring</div>
                  </NavLink>
                </li>

                {/* <li className="nav-item">
                  <NavLink className="nav-link" to="/msg">
                    <div className="icon">
                      <img
                        src={Messages}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={Messages}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="name">Message</div>
                  </NavLink>
                </li> */}
                  <li className="nav-item" onClick={() => setMoreicon(!moreicon)}>
                  <div className="nav-link">
                    <div
                      className="iconheader handlemoreclickicon"
                    // onClick={handlemoreClick}
                    >
                      <img src={More} className="normalHeadIcon" alt="icon" />
                      <img src={More} className="activeHeadIcon" alt="icon" />
                      {moreicon && (
                        <div className="moreclickdivmain">
                          <div className="">

                            <div className="">
                              <p className="moreicontxtppp">
                                Employee Experience

                              </p>
                              <div className="hiringdivflxmain">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/bulletinDetails">
                                    <div className="iconheader">
                                      <img
                                        src={BulletinBoard}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={BulletinBoard}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Bulletin Board
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/hallOfDetails">
                                    <div className="iconheader">
                                      <img
                                        src={TopPerformers}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={TopPerformers}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Top Performer

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/engagementcalender">
                                    <div className="iconheader">
                                      <img
                                        src={events}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={events}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">Events</div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={Chatbot}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Chatbot}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">AI Chatbot</div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={Live}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Live}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">Live</div>
                                  </NavLink>
                                </li>
                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Hiring Experience
                              </p>
                              <div className="hiringdivflxmain">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/jobsReferDetail">
                                    <div className="iconheader">
                                      <img
                                        src={Hiring}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Hiring}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Hiring
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/netWorking">
                                    <div className="iconheader">
                                      <img
                                        src={Onboarding}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Onboarding}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Onboarding
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={Openintervuecom}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Openintervuecom}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">AI Hiring</div>
                                  </NavLink>
                                </li>
                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Learning Experience

                              </p>
                              <div className="hiringdivflxmain">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/trainingmain">
                                    <div className="iconheader">
                                      <img
                                        src={training}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={training}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Learning
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={recenttrainingicon}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={recenttrainingicon}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Recent Learnings

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/Singlerequestedtraining">
                                    <div className="iconheader">
                                      <img
                                        src={requestedtrainingicon}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={requestedtrainingicon}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Request
                                      Learnings

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/documentrepository">
                                    <div className="iconheader">
                                      <img
                                        src={documentReposiy}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={documentReposiy}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Document Repository
                                    </div>
                                  </NavLink>
                                </li>
                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Employee Voice

                              </p>
                              <div className="hiringdivflxmainnavflx">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/surveys">
                                    <div className="iconheader">
                                      <img
                                        src={Survey}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Survey}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      survey
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/surveys">
                                    <div className="iconheader">
                                      <img
                                        src={GrivenceCell}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={GrivenceCell}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Grivence Cell
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/surveys">
                                    <div className="iconheader">
                                      <img
                                        src={SuggestionBox}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={SuggestionBox}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Suggestion Box
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/advocacy">
                                    <div className="iconheader">
                                      <img
                                        src={advocacy}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Survey}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Advocacy
                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/bulletinDetails">
                                    <div className="iconheader">
                                      <img
                                        src={Dailypoll}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Dailypoll}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Daily poll
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/engagementcalender">
                                    <div className="iconheader">
                                      <img
                                        src={Exitmanagement}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Exitmanagement}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">Exit Management</div>
                                  </NavLink>
                                </li>
                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Performance Management
                              </p>
                              <div className="hiringdivflxmainnavflx">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/initiativeDetail">
                                    <div className="iconheader">
                                      <img
                                        src={Initiative}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Initiative}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Projects
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={Ideation}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Ideation}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Ideation

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={SkillRating}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={SkillRating}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Skill
                                      Rating

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={TaskManagement}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={TaskManagement}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Task
                                      Management

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={Attendance}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Attendance}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Attendance
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={Leaverequest}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Leaverequest}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Leave
                                      Request


                                    </div>
                                  </NavLink>
                                </li>

                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Networking
                              </p>
                              <div className="hiringdivflxmainnavflx">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/orgchart">
                                    <div className="iconheader">
                                      <img
                                        src={CompanyOrgChart}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={CompanyOrgChart}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Org
                                      Chart

                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/myAffinityDetails">
                                    <div className="iconheader">
                                      <img
                                        src={Affinity}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Affinity}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Affinity Groups


                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/netWorking">
                                    <div className="iconheader">
                                      <img
                                        src={Mentor}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Mentor}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Mentor

                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={Coach}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Coach}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Career
                                      Coach


                                    </div>
                                  </NavLink>
                                </li>


                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Career and Growth

                              </p>
                              <div className="hiringdivflxmainnavflx">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/initiativeDetail">
                                    <div className="iconheader">
                                      <img
                                        src={CareerFramework}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={CareerFramework}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Career
                                      Framework
                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={CompetencyFramework}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={CompetencyFramework}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Competency
                                      Framework
                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={BenefitManagement}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={BenefitManagement}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Benefit
                                      Management


                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={Coach}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Coach}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Career
                                      Coach


                                    </div>
                                  </NavLink>
                                </li>


                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Rewards
                              </p>
                              <div className="hiringdivflxmainnavflx">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={rewardpoints}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={rewardpoints}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Points
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={GlobalMarketplace}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={GlobalMarketplace}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Global Marketplace

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={ThankyouCard}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={ThankyouCard}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Gratitude
                                      Card


                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={Redeempoints}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Redeempoints}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Redeem
                                      Points
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={ClaimPoints}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={ClaimPoints}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Claim
                                      Points

                                    </div>
                                  </NavLink>
                                </li>


                              </div>
                            </div>


                            <div className="">
                              <p className="moreicontxtppp">
                                Analytics
                              </p>
                              <div className="hiringdivflxmain">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/employeeDetail">
                                    <div className="iconheader">
                                      <img
                                        src={Employeeiconn}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Employeeiconn}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Employees
                                    </div>
                                  </NavLink>
                                </li>
                                {
                                  userData?.userType === "Admin" && (<li className="nav-item navwidthmaindiv">
                                    <NavLink className="nav-link" to="/hranalyticspage">
                                      <div className="iconheader">
                                        <img
                                          src={Dashboard}
                                          className="normalHeadIcon"
                                          alt="icon"
                                        />
                                        <img
                                          src={Dashboard}
                                          className="activeHeadIcon"
                                          alt="icon"
                                        />
                                      </div>
                                      <div className="namelnktxtppl">
                                        Analytics
                                        Dashboard

                                      </div>
                                    </NavLink>
                                  </li>)
                                }


                              </div>
                            </div>


                          </div>
                        </div>
                      )}
                    </div>
                    <div className="name">More</div>
                  </div>
                </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
