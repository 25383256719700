import React from 'react'
import Skills from "../../Images/Icons/PNG/Skills.png";
import Expand from "../../Images/Icons/PNG/Expand.png"
import Singlerecomendedcard from './Singlerecomendedcard';
const Coursepreviewupnext = () => {
    return (
        <>

<section className='coursepreviewrecomended'>
                <div className='top'>
                  <div className='toprecomendedimg'>
                    <div className='skillimgrecomended'>
                       <img src={Skills} alt="..." />
                    </div>
                    <div className=''>
                        <p className='recomendedcrstxt'>Recomended Course</p>
                    </div>
                  </div>
                  <div className='expamdimgdivrecomnd'>
                    <img src={Expand} alt="..." />
                  </div>
                </div>
                <div className='bottom'>
                   <div className='bottomsinglediv'>
                    <Singlerecomendedcard/>
                   </div>
                </div>
            </section>
        </>
    )
}

export default Coursepreviewupnext
