import React, { useEffect, useState } from 'react'
import { ApiHelperFunction } from '../services/api/apiHelpers';
import { toast } from 'react-hot-toast';

function EngagementPlacecard() {
  const [themeforMonthData, setThemeforMonthData] = useState([])
  console.log("themeforMonthData", themeforMonthData);
  useEffect(() => {
    fetchCurrentCalTheme();
  }, [])

  const fetchCurrentCalTheme = async () => {

    let response = await ApiHelperFunction({
      urlPath: '/view-calendar-theme',
      method: "POST",

    });

    if (response && response.status) {
      setThemeforMonthData(response?.data?.data);
    } else {
      toast.error("Backend Error");
    }
  }
  return (
    <section  >
      {
        themeforMonthData && themeforMonthData?.map((ele) => {
          return (
            // <h4>{ele?.}</h4>
            <div className='engagementPlacecard' style={{ backgroundColor: `${ele?.themeColor}` }}>
              <p>Theme for the month</p>
              <h3>{ele?.themeName} comes in the month of {ele?.monthName}.</h3>
            </div>
          )
        })
      }

    </section>
  )
}

export default EngagementPlacecard
