import React from 'react'
import "./Managerjob.css"
import Managerjobfirst from '../../Component/TalentForm/Managerjobfirst'

const ManagerJobPost = () => {
    const initialValues = {
        jobTitle: "",
        Department: "",
        jobLocation: [],
        hiringManager: "",
        level: null,
        isNewPosition: true,
        isTempPosition: false,
        candidateSourcing: "",
        hiringReason: "",
        priority: "",
        description: "",
        interviewerEmail: [],
        openings: null,
        keyResponsibilities: "",
        qualification: "",
        addQualification: "",
        experience_low: null,
        experience_high: null,
        Competencies: "",
        inclusion: "",
        interviewProcess: "",
        criteria: "",
        profileLink: "",
        salary: null
    }
    return (
        <>
            <section className='bg-talentmain'>
                <div className='bg-talent'>
                    <div className='employeepagecontainer'>
                        <div className=''>
                            <h4 className='tlnttxtpp'>Talent Intake Form</h4>
                        </div>
                    </div>
                </div>
                <section className='jobdtlsmainsection'>
                    <div className='employeepagecontainer'>
                        <div className='jobmaindtxlflx'>

                            <Managerjobfirst initialValues={initialValues} activity={"managerJobPost"} />
                            {/* <Managerjobsecond /> */}
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default ManagerJobPost




// import React, { useEffect, useState } from 'react'
// import { useAuthCtx } from '../../context/AuthCtx';
// import { useFormik, setFieldValue, Field } from "formik";
// import * as Yup from "yup";
// import { ApiHelperFunction } from '../../services/api/apiHelpers';
// import toast from 'react-hot-toast';
// import a from "../../Images/Icons/PNG/Add icon.png"
// import Calender from "../../Images/Icons/PNG/Calender.png";
// import Post from "../../Images/Icons/PNG/Post.png"
// import "./Managerjob.css"


// const ManagerJobPost = () => {
//     const [fullclick, setFullclick] = useState(false);
//     const [interclick, setinternclick] = useState(false);
//     const [contracclick, setcontracclick] = useState(false);
//     const { setLoading, loading, getUserDetails, userData } = useAuthCtx();
//     const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
//     const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
//     const [managerData, setManagerData] = useState([]);
//     const [whyJoinData, setWhyJoinData] = useState([]);
//     const [departmentData, setDepartmentData] = useState([]);

//     useEffect(() => {
//         getManagerData();
//         getWhyJoinData();
//         getDepartmentData();
//     }, []);


//     //for fetching manager data
//     const getManagerData = async () => {
//         const response = await ApiHelperFunction({
//             urlPath: `/view-all-manager`,
//             method: "GET",
//         });
//         // console.log("RESPONSEF", response?.data?.data);
//         if (response && response.status) {
//             setManagerData(response?.data?.data);
//         } else {
//             // toast.error(response.error);
//         }
//     };


//     //for fetching Department data
//     const getDepartmentData = async () => {
//         const response = await ApiHelperFunction({
//             urlPath: `/view-department`,
//             method: "GET",
//         });
//         // console.log("RESPONSEF", response?.data?.data);
//         if (response && response.status) {
//             setDepartmentData(response?.data?.data);
//         } else {
//             // toast.error(response.error);
//         }
//     };


//     //for fetching data of why join us
//     const getWhyJoinData = async () => {
//         const response = await ApiHelperFunction({
//             urlPath: `/view-about-company`,
//             method: "GET",
//         });
//         // console.log("RESPONSEF", response?.data?.data);
//         if (response && response.status) {
//             setWhyJoinData(response?.data?.data);
//         } else {
//             // toast.error(response.error);
//         }
//     };


//     //for submitting form
//     const submitHandler = async (e, selectedSubscription) => {
//         e.preventDefault();
//         setShouldValidateOnChange(true);
//         setShouldValidateOnBlur(true);
//         console.log("handleSubmit", values);

//         let data = {};
//         setLoading(true);
//         if (values.jobTitle == "") {
//             return toast.error("Job Title is required");
//         }
//         if (values.Department == "") {
//             return toast.error("Department/Team is required");
//         }
//         if (values.jobLocation == [""]) {
//             return toast.error("Location is required");
//         }
//         if (values.hiringManager == "") {
//             return toast.error("Hiring Manager is required");
//         }
//         if (values.level == "") {
//             return toast.error("Level is required");
//         }
//         if (values.isNewPosition == "") {
//             return toast.error(" Is New Position is required");
//         }
//         if (values.isTempPosition == "") {
//             return toast.error("Temporary Position is required");
//         }
//         if (values.candidateSourcing == "") {
//             return toast.error("Candidate Sourcing Preference is required");
//         }
//         if (values.hiringReason == "") {
//             return toast.error("Reason For Hire is required");
//         }
//         if (values.priority == "") {
//             return toast.error("Level Of Priority is required");
//         }
//         if (values.description == "") {
//             return toast.error("Position Summery is required");
//         }
//         if (values.interviewerEmail == [""]) {
//             return toast.error("Interviewer Email Id is required");
//         }
//         if (values.openings == "") {
//             return toast.error("No. of Hire is required");
//         }
//         if (values.keyResponsibilities == "") {
//             return toast.error("Key Responsibilities is required");
//         }

//         if (values.qualification == "") {
//             return toast.error("Minimum Qualification is required");
//         }
//         // if (values.addQualification == "") {
//         //     return toast.error("Additional Qualification is required");
//         // }

//         if (values.experience_low == "") {
//             return toast.error("Experience from is required");
//         }
//         if (values.experience_high == "") {
//             return toast.error("Experience to is required");
//         }
//         if (values.Competencies == "") {
//             return toast.error("Competencies and Traits required");
//         }
//         if (values.inclusion == "") {
//             return toast.error("Inclusion Consideration is required");
//         }
//         if (values.interviewProcess == "") {
//             return toast.error("Interview Process is required");
//         }
//         if (values.criteria == "") {
//             return toast.error("Evaluation Criteria is required");
//         }
//         if (values.profileLink == "") {
//             return toast.error("Sample Profile JobBoard Link is  required");
//         }
//         if (values.salary == "") {
//             return toast.error("Avg. Salary for the Position is required");
//         }
//         if (values.salaryDate == "") {
//             return toast.error("By When is required");
//         }
//         if (values.period == "") {
//             return toast.error("Period is required");
//         }
//         if (values.keySkills == [""]) {
//             return toast.error("Skills is required");
//         }

//         if (values.goodToHave == "") {
//             return toast.error("Good To Have is required");
//         }


//         if (values.positionDescription == "") {
//             return toast.error("Position Description is required");
//         }

//         // if (values.positionName == "") {
//         //     return toast.error("Position Name is required");
//         // }

//         if (values.responsibilities == "") {
//             return toast.error("Responsibilities is required");
//         }

//         if (values.qualifications == "") {
//             return toast.error("Qualifications is required");
//         }



//         data = {
//             // name: getNameFromUserId(values?.userId),
//             // userId: values?.userId,
//             // reason: values?.reason,

//             jobTitle: values?.jobTitle,
//             Department: values?.Department,
//             jobLocation: values?.jobLocation,
//             hiringManager: values?.hiringManager,
//             level: values?.level,
//             isNewPosition: values?.isNewPosition,
//             isTempPosition: values?.isTempPosition,
//             candidateSourcing: values?.candidateSourcing,
//             hiringReason: values?.hiringReason,
//             priority: values?.priority,
//             description: values?.description,
//             interviewerEmail: values?.interviewerEmail,
//             openings: values?.openings,
//             keyResponsibilities: values?.keyResponsibilities,
//             qualification: values?.qualification,
//             addQualification: values?.addQualification,
//             experience_low: values?.experience_low,
//             experience_high: values?.experience_high,
//             Competencies: values?.Competencies,
//             inclusion: values?.inclusion,
//             interviewProcess: values?.interviewProcess,
//             criteria: values?.criteria,
//             profileLink: values?.profileLink,
//             salary: values?.salary,
//             salaryDate: values?.salaryDate,
//             // designation: values?.designation,
//             period: values?.period,
//             keySkills: values?.keySkills,
//             positionDescription: values?.positionDescription,
//             positionName: values?.positionName,
//             responsibilities: values?.responsibilities,
//             goodToHave: values?.goodToHave,
//             // positionName: values?.positionName,
//             qualifications: values?.qualifications,
//             altEmail: values?.altEmail
//         };
//         // console.log("click_training", data);
//         const res = await ApiHelperFunction({
//             urlPath: "/add-job-requirment",
//             method: "POST",
//             data,
//         });
//         if (res?.status) {
//             toast.success("Manager Job Recruitment is added successfully");
//             resetForm();
//             // dispatch(getHallOfFame());
//         } else {
//             toast.error(res?.response?.data?.message || "Something went wrong");
//             console.log("ERROR CREATING USER3", res);
//         }
//         setLoading(false);
//     };


//     const { values, errors, handleBlur, handleSubmit, resetForm, handleChange, setFieldValue } =
//         useFormik({
//             initialValues: {
//                 // isTempPosition: false,
//                 // isNewPosition: true,
//                 // priority: "",
//                 // experience_low: 0,
//                 // experience_high: 0,
//                 // interviewerEmail: [],

//                 jobTitle: "",
//                 Department: "",
//                 jobLocation: [""],
//                 hiringManager: "",
//                 level: "",
//                 isNewPosition: "",
//                 isTempPosition: "",
//                 candidateSourcing: "",
//                 hiringReason: "",
//                 priority: "",
//                 description: "",
//                 interviewerEmail: [""],
//                 openings: "",
//                 keyResponsibilities: "",
//                 qualification: "",
//                 addQualification: "",
//                 experience_low: "",
//                 experience_high: "",
//                 Competencies: "",
//                 inclusion: "",
//                 interviewProcess: "",
//                 criteria: "",
//                 profileLink: "",
//                 salary: "",
//                 salaryDate: "",
//                 // designation: "",
//                 period: "",
//                 keySkills: [""],
//                 // workMode: [""],
//                 // role: ""
//                 positionDescription: "",
//                 // positionName: "",
//                 responsibilities: "",
//                 goodToHave: "",
//                 positionName: "",
//                 qualifications: "",
//                 altEmail: ""
//             },
//             // validationSchema: schemaCheck,
//             validateOnChange: shouldValidateOnChange,
//             validateOnBlur: shouldValidateOnBlur,
//             onSubmit: (val) => {
//                 console.log("val", val);
//             },
//         });


//     // Handler to add interviewer email by clicking on 'addimg'
//     const handleAddInterviewerEmail = () => {
//         const newInterviewerEmails = [...values.interviewerEmail, '']; // Add an empty email field
//         setFieldValue('interviewerEmail', newInterviewerEmails);
//     };

//     // Handler to remove interviewer email
//     const handleRemoveInterviewerEmail = (index) => {
//         const updatedInterviewerEmails = [...values.interviewerEmail];
//         updatedInterviewerEmails.splice(index, 1);
//         setFieldValue('interviewerEmail', updatedInterviewerEmails);
//     };


//     const handlebtnClick = () => {
//         setFullclick(!fullclick)
//         setinternclick(false);
//         setcontracclick(false)
//     }
//     const handleinClick = () => {
//         setinternclick(!interclick);
//         setFullclick(false);
//         setcontracclick(false)
//     }
//     const handleconClick = () => {
//         setcontracclick(!contracclick)
//         setFullclick(false);
//         setinternclick(false)
//     }
//     const handleClick = () => {
//         if (document.querySelector('#DarkSwitch').checked) {
//             document.body.classList.add("drakmode");
//         }
//         else {
//             document.body.classList.remove("drakmode");
//         }
//     }

//     // console.log("whyJoinData", departmentData)
//     return (
//         <>
//             <section className='bg-talentmain'>
//                 <div className='bg-talent'>
//                     <div className='employeepagecontainer'>
//                         <div className=''>
//                             {/* <h4 className='tlnttxtpp'>Talent Intake Form</h4> */}
//                             <h2 className='hrtxtp'>Talent Intake Form</h2>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='jobdtlsmainsection'>
//                     <div className='employeepagecontainer'>
//                         <div className='jobmaindtxlflx'>
//                             <div className='jobmaindtxlflx'>
//                                 <div className='jobbrdrwhdt'>
//                                     <div className='jobdtksvbrdr'>
//                                         <div className=''>
//                                             <form>
//                                                 <div className='fulljobdiv'>
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Job Title <span class="required">*</span></p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="jobTitle"
//                                                             value={values.jobTitle}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     {/* <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Department/Team <span class="required">*</span></p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="Department"
//                                                             value={values.Department}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div> */}
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Department<span class="required">*</span></p>
//                                                         <select
//                                                             class="jobslctdiv"
//                                                             aria-label="Select Department name"
//                                                             name="Department"
//                                                             value={values?.Department}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         >
//                                                             <option value={""} >
//                                                                 Select Department
//                                                             </option>
//                                                             {departmentData?.map((item, i) => (
//                                                                 <option key={i} value={item?._id}>
//                                                                     {item?.departmentName}
//                                                                 </option>
//                                                             ))}
//                                                         </select>
//                                                     </div>

//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Hiring Manager<span class="required">*</span></p>
//                                                         <select
//                                                             class="jobslctdiv"
//                                                             aria-label="Select Hiring Manager name"
//                                                             name="hiringManager"
//                                                             value={values?.hiringManager}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         >
//                                                             <option value={""} >
//                                                                 Select Hiring Manager
//                                                             </option>
//                                                             {managerData?.map((item, i) => (
//                                                                 <option key={i} value={item?._id}>
//                                                                     {item?.firstName} {item?.lastName}
//                                                                 </option>
//                                                             ))}
//                                                         </select>
//                                                     </div>


//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Location<span class="required">*</span></p>
//                                                         <input
//                                                             type='text'
//                                                             className='jobtxtinpt'
//                                                             name="jobLocation"
//                                                             value={values?.jobLocation}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Level<span class="required">*</span></p>
//                                                         <input
//                                                             type='number'
//                                                             className='jobtxtinpt'
//                                                             name="level"
//                                                             value={values?.level}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     <div className='ysnopst'>
//                                                         <p className='jibtxt'>Is this a new Position<span class="required">*</span></p>
//                                                         <div className='yxsbnflx'>
//                                                             <div className='mrtrdtys'>
//                                                                 {/* <input type="radio" id="Yes" name="" defaultValue="yes" /> */}
//                                                                 <input

//                                                                     type="radio"
//                                                                     id="true"
//                                                                     name="isNewPosition"
//                                                                     value="true"
//                                                                     checked={values?.isNewPosition == "true"}
//                                                                     // onChange={handleChange}
//                                                                     onChange={() => setFieldValue('isNewPosition', "true")}
//                                                                 />
//                                                                 <label htmlFor="html">Yes</label>
//                                                             </div>
//                                                             <div className='mrtrdtys'>
//                                                                 {/* <input type="radio" id="No" name="" defaultValue="no" /> */}
//                                                                 <input

//                                                                     type="radio"
//                                                                     id="false"
//                                                                     name="isNewPosition"
//                                                                     value="false"
//                                                                     checked={values?.isNewPosition == "false"}
//                                                                     // onChange={handleChange}
//                                                                     onChange={() => setFieldValue('isNewPosition', "false")}
//                                                                 />
//                                                                 <label htmlFor="html">No</label>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className='ysnopst'>
//                                                         <p className='jibtxt'>Is this a Temporary Position<span class="required">*</span></p>
//                                                         <div className='yxsbnflx'>
//                                                             <div className='mrtrdtys'>
//                                                                 {/* <input type="radio" id="Yes" name="" defaultValue="yes" /> */}
//                                                                 <input

//                                                                     type="radio"
//                                                                     id="true"
//                                                                     name="isTempPosition"
//                                                                     value="true"
//                                                                     checked={values?.isTempPosition == "true"}
//                                                                     // onChange={handleChange}
//                                                                     onChange={() => setFieldValue('isTempPosition', "true")}
//                                                                 />
//                                                                 <label htmlFor="html">Yes</label>
//                                                             </div>
//                                                             <div className='mrtrdtys'>
//                                                                 {/* <input type="radio" id="No" name="" defaultValue="No" /> */}
//                                                                 <input
//                                                                     // defaultValue="no"
//                                                                     type="radio"
//                                                                     id="false"
//                                                                     name="isTempPosition"
//                                                                     value="false"
//                                                                     checked={values?.isTempPosition == "false"}
//                                                                     // onChange={handleChange}
//                                                                     onChange={() => setFieldValue('isTempPosition', "false")}
//                                                                 />
//                                                                 <label htmlFor="html">No</label>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className='ysnopst'>
//                                                         <p className='jibtxt'>Level Of Priority<span class="required">*</span></p>
//                                                         <div className='hgmdlwflx'>
//                                                             {/* <button className='gnjhbtn'>
//                                             High
//                                         </button>
//                                         <button className='gnjhbtn'>
//                                             Medium
//                                         </button>
//                                         <button className='gnjhbtn'>
//                                             Low
//                                         </button> */}

//                                                             {/* <button
//                                                                 className='gnjhbtn'
//                                                                 type='button'
//                                                             // onClick={() => handlePriorityClick("high")}
//                                                             // onClick={() => setFieldValue(prev => ({ ...prev, priority: "High" }))}
//                                                             >
//                                                                 High
//                                                             </button>
//                                                             <button
//                                                                 className='gnjhbtn'
//                                                                 type='button'
//                                                             // onClick={() => handlePriorityClick("mid")}
//                                                             // onClick={() => setFieldValue(prev => ({ ...prev, priority: "Mid" }))}
//                                                             >
//                                                                 Medium
//                                                             </button>
//                                                             <button
//                                                                 className='gnjhbtn'
//                                                                 type='button'
//                                                             // onClick={() => handlePriorityClick("low")}
//                                                             // onClick={() => setFieldValue(prev => ({ ...prev, priority: "Low" }))}
//                                                             >
//                                                                 Low
//                                                             </button> */}

//                                                             <button
//                                                                 className='gnjhbtn'
//                                                                 type='button'
//                                                                 onClick={() => setFieldValue('priority', 'High')}
//                                                             >
//                                                                 High
//                                                             </button>
//                                                             <button
//                                                                 className='gnjhbtn'
//                                                                 type='button'
//                                                                 onClick={() => setFieldValue('priority', 'Mid')}
//                                                             >
//                                                                 Medium
//                                                             </button>
//                                                             <button
//                                                                 className='gnjhbtn'
//                                                                 type='button'
//                                                                 onClick={() => setFieldValue('priority', 'Low')}
//                                                             >
//                                                                 Low
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                     <div className='ysnopst'>
//                                                         <p className='jibtxt'>Candidate Sourcing Preference<span class="required">*</span></p>
//                                                         <div className='hgmdlwflx'>
//                                                             {/* <button
//                                                                 type='button'
//                                                                 className='gnjhbtn'
//                                                             // onClick={() => setFieldValue(prev => ({ ...prev, candidateSourcing: "External" }))}
//                                                             >
//                                                                 External
//                                                             </button>
//                                                             <button
//                                                                 type='button'
//                                                                 className='gnjhbtn'
//                                                             // onClick={() => setFieldValue(prev => ({ ...prev, candidateSourcing: "Internal" }))}
//                                                             >
//                                                                 Internal
//                                                             </button>
//                                                             <button type='button'
//                                                                 className='gnjhbtn'
//                                                             // onClick={() => setFieldValue(prev => ({ ...prev, candidateSourcing: "Both" }))}
//                                                             >
//                                                                 Both
//                                                             </button> */}
//                                                             <button
//                                                                 className='gnjhbtn'
//                                                                 type='button'
//                                                                 onClick={() => setFieldValue('candidateSourcing', 'External')}
//                                                             >
//                                                                 External
//                                                             </button>
//                                                             <button
//                                                                 className='gnjhbtn'
//                                                                 type='button'
//                                                                 onClick={() => setFieldValue('candidateSourcing', 'Internal')}
//                                                             >
//                                                                 Internal
//                                                             </button>
//                                                             <button
//                                                                 className='gnjhbtn'
//                                                                 type='button'
//                                                                 onClick={() => setFieldValue('candidateSourcing', 'Both')}
//                                                             >
//                                                                 Both
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                     <div className='txtarfdiv'>
//                                                         <p className='jibtxt'>Reason For Hire<span class="required">*</span></p>
//                                                         <textarea id="" rows="4" cols="10" name="hiringReason"
//                                                             value={values?.hiringReason}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}>

//                                                         </textarea>
//                                                     </div>
//                                                     <div className='txtarfdiv'>
//                                                         <p className='jibtxt'>Position Summery<span class="required">*</span></p>
//                                                         <textarea id="" rows="4" cols="10" name="description"
//                                                             value={values?.description}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}>

//                                                         </textarea>
//                                                     </div>
//                                                     {/* <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Interviewer Email Id<span class="required">*</span></p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="jobTitle"
//                                                         // value={values.jobTitle}
//                                                         // onBlur={handleBlur}
//                                                         // onChange={handleChange}
//                                                         />
//                                                     </div> */}

//                                                     <div className=''>
//                                                         <div className='imgtxtdivmainflx'>
//                                                             <div className=''>
//                                                                 <p className='rsntxrp'>Interviewer Email Id<span class="required">*</span></p>
//                                                             </div>
//                                                             <div className='addimg' onClick={handleAddInterviewerEmail}> {/* Trigger addition of email */}
//                                                                 <img src={a} alt="..." />
//                                                             </div>
//                                                         </div>
//                                                         <div className='addimginptdivflx'>

//                                                             {/* Display added interviewer emails */}
//                                                             {values?.interviewerEmail?.map((email, index) => (
//                                                                 <div key={index} className="added-txtrmvadd">
//                                                                     <input
//                                                                         type='text'
//                                                                         className='inptadddivtxtwh'
//                                                                         value={email}
//                                                                         onChange={(e) => {
//                                                                             const updatedInterviewerEmails = [...values.interviewerEmail];
//                                                                             updatedInterviewerEmails[index] = e.target.value;
//                                                                             setFieldValue('interviewerEmail', updatedInterviewerEmails);
//                                                                         }}
//                                                                     />
//                                                                     {/* Display remove button only for additional email fields */}
//                                                                     {index > 0 && <button type="button" className='btnrmvnbmain' onClick={() => handleRemoveInterviewerEmail(index)}>Remove</button>}
//                                                                 </div>
//                                                             ))}
//                                                         </div>
//                                                     </div>


//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Alternative Email
//                                                             {/* <span class="required">*</span> */}
//                                                         </p>
//                                                         <input type='email'
//                                                             className='jobtxtinpt'
//                                                             name="altEmail"
//                                                             value={values?.altEmail}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>

//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>No. of Hire<span class="required">*</span></p>
//                                                         <input type='number'
//                                                             className='jobtxtinpt'
//                                                             name="openings"
//                                                             value={values?.openings}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Key Responsibilities<span class="required">*</span></p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="keyResponsibilities"
//                                                             value={values?.keyResponsibilities}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Minimum Qualification<span class="required">*</span></p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="qualification"
//                                                             value={values?.qualification}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>

//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Additional Qualification(If any)
//                                                             {/* <span class="required">*</span> */}
//                                                         </p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="addQualification"
//                                                             value={values?.addQualification}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Experience(No. of Years)<span class="required">*</span></p>
//                                                         <div className='frmtodivmainflx'>
//                                                             <div className='frmjobtxtdiv'>
//                                                                 {/* <input type='text'
//                                                                     className='jobtxtinpt'
//                                                                     name="jobTitle"
//                                                                 // value={values.jobTitle}
//                                                                 // onBlur={handleBlur}
//                                                                 // onChange={handleChange}
//                                                                 /> */}
//                                                                 <>
//                                                                     <label htmlFor="">From</label>

//                                                                     <select
//                                                                         name="experience_low"
//                                                                         value={values?.experience_low}
//                                                                         onChange={handleChange}
//                                                                         className='experience-select'
//                                                                     >
//                                                                         <option value="0">0</option>
//                                                                         <option value="1">1</option>
//                                                                         <option value="2">2</option>
//                                                                         <option value="3">3</option>
//                                                                     </select>
//                                                                 </>
//                                                             </div>
//                                                             <div className='frmjobtxtdiv'>
//                                                                 {/* <input type='text'
//                                                                     className='jobtxtinpt'
//                                                                     name="jobTitle"
//                                                                 // value={values.jobTitle}
//                                                                 // onBlur={handleBlur}
//                                                                 // onChange={handleChange}
//                                                                 /> */}
//                                                                 <>
//                                                                     <label htmlFor="">To</label>
//                                                                     <select
//                                                                         name="experience_high"
//                                                                         value={values?.experience_high}
//                                                                         onChange={handleChange}
//                                                                         className='experience-select'
//                                                                     >
//                                                                         <option value="0">0</option>
//                                                                         <option value="1">1</option>
//                                                                         <option value="2">2</option>
//                                                                         <option value="3">3</option>
//                                                                     </select>
//                                                                 </>
//                                                             </div>
//                                                         </div>

//                                                     </div>
//                                                     {/* <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Experience(No. of Years)<span class="required">*</span></p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="jobTitle"
//                                                         // value={values.jobTitle}
//                                                         // onBlur={handleBlur}
//                                                         // onChange={handleChange}
//                                                         />
//                                                     </div> */}
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Competencies and Traits<span class="required">*</span></p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="Competencies"
//                                                             value={values?.Competencies}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Inclusion Considerations<span class="required">*</span></p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="inclusion"
//                                                             value={values?.inclusion}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Avg. Salary for the Position<span class="required">*</span></p>
//                                                         <input type='number'
//                                                             className='jobtxtinpt'
//                                                             name="salary"
//                                                             value={values?.salary}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Interview Process<span class="required">*</span></p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="interviewProcess"
//                                                             value={values?.interviewProcess}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>By When<span class="required">*</span></p>
//                                                         <input type='date'
//                                                             className='jobtxtinpt'
//                                                             name="salaryDate"
//                                                             value={values?.salaryDate}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Evaluation Criteria<span class="required">*</span></p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="criteria"
//                                                             value={values?.criteria}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                     <div className='jobdtlsdiv'>
//                                                         <p className='jibtxt'>Sample Profile Job Board Link<span class="required">*</span></p>
//                                                         <input type='text'
//                                                             className='jobtxtinpt'
//                                                             name="profileLink"
//                                                             value={values?.profileLink}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                             </form>
//                                         </div>

//                                     </div>
//                                 </div>

//                                 <div className='jobdtlswh'>
//                                     <div className='dtlskmflx'>
//                                         <h4 className='dtlstxtpphh'>Job Description</h4>
//                                         <div className='rfrlinpflx'>
//                                             <p className='opnttxt'><span class="required">*</span>Open to Refferal Hiring</p>
//                                             <div className="custom-control custom-switch">
//                                                 <input type="checkbox" className="custom-control-input" id="DarkSwitch" onClick={handleClick} />
//                                                 <label className="custom-control-label" htmlFor="DarkSwitch"></label>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className=''>
//                                         <form>
//                                             <div className='alldtlsfrmdiv'>
//                                                 {/* <div className='senddivrxr'>
//                                                     <div className='rsntxrpdiv'>
//                                                         <p className='rsntxrp'>Position Name<span class="required">*</span></p>
//                                                     </div>
//                                                     <div className='rsntxrptxtdiv'>
//                                                         <input type="text" className='rsjobtxtdiv'
//                                                             name="positionName"
//                                                             value={values.positionName}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange} />
//                                                     </div>
//                                                 </div> */}
//                                                 <div className='senddivrxr'>
//                                                     <div className='rsntxrpdiv'>
//                                                         <p className='rsntxrp'>Period<span class="required">*</span></p>
//                                                     </div>
//                                                     <div className='btnflxmain'>
//                                                         <div className={fullclick ? "btnscactb" : "plnbtnact"}
//                                                             // onClick={handlebtnClick}
//                                                             onClick={() => setFieldValue('period', 'FullTime')}
//                                                         >Full Time</div>
//                                                         <div className={interclick ? "btnscactb" : "plnbtnact"}
//                                                             // onClick={handleinClick}
//                                                             onClick={() => setFieldValue('period', 'Internship')}
//                                                         >Internship</div>
//                                                         <div className={contracclick ? "btnscactb" : "plnbtnact"}
//                                                             // onClick={handleconClick}
//                                                             onClick={() => setFieldValue('period', 'Contractual')}
//                                                         >Contractual</div>
//                                                     </div>
//                                                 </div>
//                                                 <div className='senddivrxr'>
//                                                     <div className='rsntxrpdiv'>
//                                                         <p className='rsntxrp'>Skills<span class="required">*</span></p>
//                                                     </div>
//                                                     <div className='rsntxrptxtdiv'>
//                                                         <input type="text" className='rsjobtxtdiv'
//                                                             name="keySkills"
//                                                             value={values?.keySkills}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                                 <div className='senddivrxr'>
//                                                     <div className='rsntxrpdiv'>
//                                                         <p className='rsntxrp'>Location<span class="required">*</span></p>
//                                                     </div>
//                                                     <div className='rsntxrptxtdiv'>
//                                                         <input type="text"
//                                                             className='rsjobtxtdiv'
//                                                             name="jobLocation"
//                                                             value={values?.jobLocation}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                                 {/* <div className='whyjdiv'>
//                                                     <div className='rsntxrpdiv'>
//                                                         <p className='rsntxrp'>Why Join Us<span class="required">*</span></p>
//                                                     </div>
//                                                     <div className='txtwhydiv'>
//                                                         <textarea rows="2" cols="20" ></textarea>
//                                                     </div>
//                                                 </div> */}
//                                                 <div className='whyjdiv'>
//                                                     <div className='rsntxrpdiv'>
//                                                         <p className='rsntxrp'>Position Description<span class="required">*</span></p>
//                                                     </div>
//                                                     <div className='txtwhydiv'>
//                                                         <textarea rows="2" cols="20"
//                                                             name="positionDescription"
//                                                             value={values?.positionDescription}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         ></textarea>
//                                                     </div>
//                                                 </div>

//                                                 <div className='whyjdiv'>
//                                                     <div className='rsntxrpdiv'>
//                                                         <p className='rsntxrp'>Responsibilities:<span class="required">*</span></p>
//                                                     </div>
//                                                     <div className='txtwhydiv'>
//                                                         <textarea rows="2" cols="20"
//                                                             name="responsibilities"
//                                                             value={values?.responsibilities}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         ></textarea>
//                                                     </div>
//                                                 </div>

//                                                 <div className='whyjdiv'>
//                                                     <div className='rsntxrpdiv'>
//                                                         <p className='rsntxrp'>Qualifications:<span class="required">*</span></p>
//                                                     </div>
//                                                     <div className='txtwhydiv'>
//                                                         <textarea rows="2" cols="20"
//                                                             name="qualifications"
//                                                             value={values?.qualifications}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         ></textarea>
//                                                     </div>
//                                                 </div>

//                                                 <div className='whyjdiv'>
//                                                     <div className='rsntxrpdiv'>
//                                                         <p className='rsntxrp'>Good To Have<span class="required">*</span></p>
//                                                     </div>
//                                                     <div className='txtwhydiv'>
//                                                         <textarea rows="2" cols="20"
//                                                             name="goodToHave"
//                                                             value={values?.goodToHave}
//                                                             onBlur={handleBlur}
//                                                             onChange={handleChange}
//                                                         ></textarea>
//                                                     </div>
//                                                 </div>

//                                                 <div className=''>
//                                                     {
//                                                         whyJoinData?.map((item, ind) => {
//                                                             return (<>
//                                                                 <div className='jndivtxtwhy' key={ind}>
//                                                                     <p className="jntxt">Why Join US</p>
//                                                                     <div className='bg-grydiv'>
//                                                                         <p className='frcttxy'>{item?.whyJoinUs}</p>
//                                                                     </div>
//                                                                 </div>
//                                                             </>)
//                                                         })
//                                                     }

//                                                     {/* <div className='jndivtxtwhy'>
//                                                         <p className="jntxt">Position Description</p>
//                                                         <div className='bg-grydiv'>
//                                                             <p className='frcttxy'>Fractals Global’s mission is to ‘empower every person and every organization on the
//                                                                 planet to achieve more’. At studio+91 at the India Development Center (MSIDC), we work
//                                                                 together across a multitude of disciplines: design, research and design engineering, to
//                                                                 empower people all over the planet. Our diverse teams are constantly iterating, solving
//                                                                 problems and collaborating with product managers, engineers to craft meaningful and
//                                                                 relevant product experiences.</p>
//                                                             <p className='frcttxy'>
//                                                                 We are looking for a talented researcher who exhibits deep user empathy & analytical
//                                                                 ability; has excellent command of research methodologies & communication skills, to
//                                                                 work collaboratively with designers, product managers and engineers to uncover &
//                                                                 drive insights into experiences.
//                                                             </p>
//                                                         </div>
//                                                     </div>
//                                                     <div className='jndivtxtwhy'>
//                                                         <p className="jntxt">Responsibilities:</p>
//                                                         <div className='bg-grydiv'>
//                                                             <ul className='txtillsltall'>
//                                                                 <li className='txtlixtli'>
//                                                                     You will be leading research projects independently, either alone or with a team
//                                                                     of researchers, working closely with a team of designers, product managers and
//                                                                     engineers in a highly collaborative and agile environment.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     Projects usually start with a fundamental inquiry, arrived collaboratively with
//                                                                     stakeholders across design product & engineering. This also entails identifying &
//                                                                     prioritizing research inquiries and defining the research strategy for your product
//                                                                     area
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     You should be able to plan and execute research studies and accurately estimate
//                                                                     project time without oversight.
//                                                                 </li>
//                                                                 <li className='txtlixtli'> This also means thinking strategically, creating research plans and applying
//                                                                     relevant research methods to reveal unmet-unarticulated needs, motivations & behaviors of our users and customers. Additionally, you would have to understand
//                                                                     and incorporate business and technical requirements into your studies.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     A command of a wide range of research methods along with the ability to articulate
//                                                                     and communicate research insights at all stages of product development is
//                                                                     essential.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     You should be able to make connections across different research areas, making
//                                                                     connections and identifying patterns to enable larger product goals
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     You are expected to communicate results and opportunities in clear, concise, and
//                                                                     compelling ways to all levels of the product team, translate data into insights &
//                                                                     research findings into actionable recommendations.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     Most importantly, you’ll need to be a passionate advocate for our users throughout
//                                                                     the development process and demonstrate how this leads to business value.
//                                                                 </li>
//                                                             </ul>
//                                                         </div>
//                                                     </div>
//                                                     <div className='jndivtxtwhy'>
//                                                         <p className="jntxt">Qualifications:</p>
//                                                         <div className='bg-grydiv'>
//                                                             <ul className='txtillsltall'>
//                                                                 <li className='txtlixtli'>
//                                                                     You will be leading research projects independently, either alone or with a team
//                                                                     of researchers, working closely with a team of designers, product managers and
//                                                                     engineers in a highly collaborative and agile environment.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     Projects usually start with a fundamental inquiry, arrived collaboratively with
//                                                                     stakeholders across design product & engineering. This also entails identifying &
//                                                                     prioritizing research inquiries and defining the research strategy for your product
//                                                                     area
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     You should be able to plan and execute research studies and accurately estimate
//                                                                     project time without oversight.
//                                                                 </li>
//                                                                 <li className='txtlixtli'> This also means thinking strategically, creating research plans and applying
//                                                                     relevant research methods to reveal unmet-unarticulated needs, motivations & behaviors of our users and customers. Additionally, you would have to understand
//                                                                     and incorporate business and technical requirements into your studies.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     A command of a wide range of research methods along with the ability to articulate
//                                                                     and communicate research insights at all stages of product development is
//                                                                     essential.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     You should be able to make connections across different research areas, making
//                                                                     connections and identifying patterns to enable larger product goals
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     You are expected to communicate results and opportunities in clear, concise, and
//                                                                     compelling ways to all levels of the product team, translate data into insights &
//                                                                     research findings into actionable recommendations.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     Most importantly, you’ll need to be a passionate advocate for our users throughout
//                                                                     the development process and demonstrate how this leads to business value.
//                                                                 </li>
//                                                             </ul>
//                                                         </div>
//                                                     </div>
//                                                     <div className='jndivtxtwhy'>
//                                                         <p className="jntxt">Good To Have</p>
//                                                         <div className='bg-grydiv'>
//                                                             <ul className='txtillsltall'>
//                                                                 <li className='txtlixtli'>
//                                                                     You will be leading research projects independently, either alone or with a team
//                                                                     of researchers, working closely with a team of designers, product managers and
//                                                                     engineers in a highly collaborative and agile environment.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     Projects usually start with a fundamental inquiry, arrived collaboratively with
//                                                                     stakeholders across design product & engineering. This also entails identifying &
//                                                                     prioritizing research inquiries and defining the research strategy for your product
//                                                                     area
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     You should be able to plan and execute research studies and accurately estimate
//                                                                     project time without oversight.
//                                                                 </li>
//                                                                 <li className='txtlixtli'> This also means thinking strategically, creating research plans and applying
//                                                                     relevant research methods to reveal unmet-unarticulated needs, motivations & behaviors of our users and customers. Additionally, you would have to understand
//                                                                     and incorporate business and technical requirements into your studies.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     A command of a wide range of research methods along with the ability to articulate
//                                                                     and communicate research insights at all stages of product development is
//                                                                     essential.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     You should be able to make connections across different research areas, making
//                                                                     connections and identifying patterns to enable larger product goals
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     You are expected to communicate results and opportunities in clear, concise, and
//                                                                     compelling ways to all levels of the product team, translate data into insights &
//                                                                     research findings into actionable recommendations.
//                                                                 </li>
//                                                                 <li className='txtlixtli'>
//                                                                     Most importantly, you’ll need to be a passionate advocate for our users throughout
//                                                                     the development process and demonstrate how this leads to business value.
//                                                                 </li>
//                                                             </ul>
//                                                         </div>
//                                                     </div> */}
//                                                     <div className=''>
//                                                         <p className='sndstpp'>Sounds you know someone who can fit?</p>
//                                                     </div>
//                                                     <div className=''>
//                                                         <p className='sndstpp'>Refer Now !</p>
//                                                         <div className='refershrflx'>
//                                                             <button className='btnmainflx'>
//                                                                 <div className='shrimg'>
//                                                                     <img src={a} alt="..." />
//                                                                 </div>
//                                                                 <div className='rfshr'>
//                                                                     Refer
//                                                                 </div>
//                                                             </button>
//                                                             <button className='btnmainflx'>
//                                                                 <div className='shrimg'>
//                                                                     <img src={a} alt="..." />
//                                                                 </div>
//                                                                 <div className='rfshr'>
//                                                                     Share
//                                                                 </div>
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                 </div>

//                                                 {/* <div className=''>
//                                 <div className=''>

//                                 </div>
//                                 <div className=''>

//                                 </div>
//                             </div> */} <div className='sbmtdivmain'>
//                                                     <button className='sbmtdiv'
//                                                         type="button"
//                                                         onClick={submitHandler}
//                                                     >
//                                                         <div className='pstimg'>
//                                                             <img src={Post} alt="..." />
//                                                         </div>
//                                                         <div className='hrtxty'>
//                                                             Submit to Hr
//                                                         </div>
//                                                     </button>
//                                                 </div>

//                                             </div>
//                                         </form>
//                                     </div >
//                                 </div >
//                             </div>

//                             {/* <Managerjobsecond /> */}
//                         </div>
//                     </div>
//                 </div>
//             </section>

//         </>
//     )
// }

// export default ManagerJobPost