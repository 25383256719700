
import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import Chart from 'react-apexcharts';
import SpeedometerDeepGreen from "../../Images/SpeedometerDeepGreen.png";
import SpeedometerDeepRed from "../../Images/SpeedometerDeepRed.png";
import SpeedometerLightRed from "../../Images/SpeedometerLightRed.png";
import Loudspeakericon from "../../Images/Loudspeakericon.png"
const Employeevoiceanalytics = () => {
    const options = {
        chart: {
            type: 'bar',
            height: 200,
            stacked: true,
        },

        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900,
                        },
                    },
                },
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff'],
        },

        xaxis: {
            categories: ["apr 20", "mar 20", "feb 20", "jan 20", "oct 20", "nov 20"],
            labels: {
                show: true,
            }
        },
        yaxis: {
            title: {
                text: undefined,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
            }
        },

        fill: {
            opacity: 1,
            colors: ['#cc051c', '#142553']
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40,
        },
    };

    const series = [
        {
            name: 'Response',
            data: [53, 32, 33, 52, 13, 43, 32],
        },
        {
            name: 'Skip',
            data: [53, 32, 33, 52, 13, 43, 32],
        },

    ];

    const [seriess, setSeriess] = useState([44, 55, 13]);
    const options1 = {
        chart: {
            width: 300,
            type: 'donut',
            height: 300,
        },
        labels: ['Promoters 60%', 'Passives 30%', 'Detractors 10% '],
        fill: {
            opacity: 1,
            colors: ['#cc051c', '#142553', "#ccc"]
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200,
                    height: 200,
                },
                legend: {
                    show: false
                }
            }
        }],
        legend: {
            position: 'right',
            offsetY: 0,
            // height: 230,
        }
    };
    const optionsnew = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
        },
        title: {
            // text: 'Product Trends by Month',
            align: 'left',
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // alternating colors for grid rows
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        },
    };

    const seriesnew = [
        {
            name: 'Desktops',
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
    ];


    return (
        <>
            <div className='reposnseovralldivflx'>
                <div className='reposnseovralldivright'>
                    <p className='advocntntdivbgpptrtr'>Response</p>

                    <div className=''>
                        <p className='thjhjsvhhjphj'>Total Responses
                            <span>36%</span>
                        </p>
                        <p className='thjhjsvhhjphj'>Rate
                            <span>62%</span>
                        </p>
                        <div className=''>
                            <ReactApexChart
                                options={options}
                                series={series}
                                type="bar"
                                height={350}
                            />
                        </div>
                    </div>
                </div>
                <div className='reposnseovralldivleft'>
                    <p className='advocntntdivbgpptrtr'>Overall Score
                    </p>
                    <div className='reposnseovralldivleftmainflx'>
                        <div className='reposnseovralldivleftmain'>
                            <Chart
                                options={options1}
                                series={seriess}
                                type="donut"
                                width={300}
                                height={300}
                            />
                        </div>
                        <div className='reposnseovralldivleftmainnew'>
                            <ReactApexChart
                                options={optionsnew}
                                series={seriesnew}
                                type="line"
                                height={350}
                            />
                        </div>
                    </div>


                </div>
            </div>

            <div className='partienospchngdivflx'>
                <div className='partienospchngdifrst'>
                    <p className='advocntntdivbgpptrtr'>Participant</p>
                    <div className='Participattionbg'>
                        <p className='partvhhdgbjtxtyy'>Participattion Rate: 100%</p>

                        <div className='exclnjhgimgmaindlfx'>
                            <div className='exclnjhgimgmain'>
                                <img src={SpeedometerDeepGreen} alt="..." />
                            </div>
                            <p className='fghsbhytsyhgjo'>
                                Excellent
                            </p>
                        </div>
                    </div>
                    <div className='Participattionbgmaihnj'>
                        <p className='partvhhdgbjtxtyy'>Employess Surveyed</p>
                        <p className='fghsbhytsyhgjo'>3 Employess</p>
                    </div>
                </div>
                <div className='partienospchngdifrst'>
                    <p className='advocntntdivbgpptrtr'>eNPS</p>
                    <div className='Participattionbg'>
                        <p className='partvhhdgbjtxtyy'>Score-2</p>
                      
                         <div className='exclnjhgimgmaindlfx'>
                            <div className='exclnjhgimgmain'>
                                <img src={SpeedometerDeepRed} alt="..." />
                            </div>
                            <p className='fghsbhytsyhgjo'>
                            
                            To Improve
                        </p>
                        </div>
                        <div className='partvhhdgbjtxtyybgbrdr'>
                            <p className='partvhhdgbjtxtyy'>Score =
                                % of Promoters - % of Detractors
                            </p>
                        </div>
                    </div>

                    <div className="">
                        <p className='partvhhdgbjtxtyy' style={{ textAlign: "left" }}>Score Breakdown</p>
                        <div className=''>
                            <div className="progressdiv">
                                <div className="" role="progressbar" style={{ width: '39%', backgroundColor: "#cc051c", textAlign: "center", color: "#fff",paddingTop:"10px" }}>
                                    39%
                                </div>
                                <div className="" role="progressbar" style={{ width: '24%', backgroundColor: "#142553", textAlign: "center", color: "#fff",paddingTop:"10px" }}>
                                    24%
                                </div>
                                <div className="" role="progressbar" style={{ width: '37%', backgroundColor: "#ccc", textAlign: "center", color: "#fff",paddingTop:"10px" }}>
                                    37%
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className='partienospchngdifrst'>
                    <p className='advocntntdivbgpptrtr'> Biggest Change </p>
                    <div className='Participattionbg'>

                        <div className='advfhvvimgimghb'>
                            <img src={Loudspeakericon} alt="..." />
                        </div>
                        <p className='fghsbhytsyhgjo'>

                            Learning & Development
                        </p>
                    </div>
                    <div className='previejklcurennjdihv'>
                        <div className='previejklsdiv'>
                            <p className='partvhhdgbjtxtyy'>
                                Previous Score
                            </p>
                            <div className='exclnjhgimgmaindlfx'>
                                <div className='exclnjhgimgmain'>
                                    <img src={SpeedometerDeepGreen} alt="..." />
                                </div>
                                <p className='partvhhdgbjtxtyy'>8</p>
                            </div>
                        </div>
                        <div className='curennjdihbbm'>
                            <p className='partvhhdgbjtxtyy'>
                                Current Score
                            </p>
                            <div className='exclnjhgimgmaindlfx'>
                                <div className='exclnjhgimgmain'>
                                    <img src={SpeedometerLightRed} alt="..." />
                                </div>
                                <p className='partvhhdgbjtxtyy'>8</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Employeevoiceanalytics
