import React, { useState, useEffect } from 'react'
import { useAuthCtx } from '../context/AuthCtx';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-hot-toast';
import { ApiHelperFunction, fileUpload } from '../services/api/apiHelpers';
import { useDispatch } from 'react-redux';
import { getEventData } from '../redux/slices/eventSlice';
import Select from "react-select";
import makeAnimated from "react-select/animated";
// import Event_View from "../../src/Images/Icons/PNG/Event_View.png"
import Addicon from "../../src/Images/headericon/Addicon.png"
const animatedComponents = makeAnimated();

const initialValues = {
  eventName: "",
  hostedBy: "",
  eventDate: "",
  eventstarttime: "",
  eventendtime: "",
  lattitude: "",
  longitude: "",
  addinvites: "",
  name: "",
  notes: "",
  image: "",
  editTrainingName: "",
  editTraininDate: "",
  isHighLighted: "",
  eventTypes: "",
  postType: "",
  affinityGroupId: "",
  eventTime: "",
  addEmploee: []
};

const EventsModal = ({ activity }) => {
  const { setLoading} = useAuthCtx();
  const [imageURL, setImageURL] = useState("");
  const dispatch = useDispatch();
  const [localerror, setlocalerror] = useState("");
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [colourOptions, setColourOptions] = useState([]);
  const [empData, setempData] = useState([]);
  const [eventTypeId, seteventTypeId] = useState([]);
  const [colourOptionseventTypeId, setColourOptionseventTypeId] = useState([]);
  const [selectedOptionseventTypeId, setSelectedOptionseventTypeId] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [colourOptionsGroup, setColourOptionsGroup] = useState([]);
  const [selectedOptionsGroup, setSelectedOptionsGroup] = useState("");
  // const [isPublic, setIsPublic] = useState(false);
  // const [postType, setPostType] = useState("");
  const [selectedOptionsPostType, setSelectedOptionsPostType] = useState("");


  useEffect(() => {
    getEmployeeData();
    getEventTypeData();
    getMyGroupData();
  }, [])

  //options for postType
  const options = [
    { value: 'public', label: 'Public' },
    { value: 'private', label: 'Affinity Group' }
  ]

  //for selecting invitees
  const handleSelectChange = (e) => {
    console.log("Selected Options:", e);

    // Update the colourOptions state with the selected options
    setSelectedOptions(e);
  };

  //for selecting event type
  const handleSelectChangeEventType = (e) => {
    setSelectedOptionseventTypeId(e);
  };

  //for selecting group 
  const handleSelectChangeGroup = (e) => {
    setSelectedOptionsGroup(e);
  };

  //for selecting Post Type
  const handleSelectChangePostType = (e) => {
    // console.log("publicio", e)
    setSelectedOptionsPostType(e);
  };

  //get all invitees
  const getEmployeeData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-all-employees`,
      method: "GET",
    });

    console.log("RESPONSEF", response?.data?.data);

    if (response && response.status) {
      const formattedOptions = response?.data?.data?.map((item) => ({
        value: item?._id,
        label: item?.userName,
      }));

      setColourOptions(formattedOptions);
      setempData(response?.data?.data);
    } else {
      // toast.error(response.message);
    }
  };

  //get event types
  const getEventTypeData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/fetch-event-type`,
      method: "GET",
    });

    console.log("RESPONSEWF", response?.data?.data);

    if (response && response.status) {
      const formattedOptions = response?.data?.data?.map((item) => ({
        value: item?._id,
        label: item?.typeName,
      }));

      setColourOptionseventTypeId(formattedOptions);
      seteventTypeId(response?.data?.data);
    } else {
      // toast.error(response.message);
    }
  };
  // console.log("eventTypeId", eventTypeId)

  //get my affinity groups
  const getMyGroupData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-my-affinity-group`,
      method: "GET",
    });

    // console.log("RESPONSEWTF", response?.data?.data);

    if (response && response.status) {
      const formattedOptions = response?.data?.data?.map((item) => ({
        value: item?._id,
        label: item?.groupName,
      }));

      setColourOptionsGroup(formattedOptions);
      setGroupData(response?.data?.data);
    } else {
      // toast.error(response.message);
    }
  };


  const submitHandler = async (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    setShouldValidateOnBlur(true);

    let data = {};
    setLoading(true);
    if (
      values?.eventName == "" ||
      values?.eventDate == "" ||
      values?.eventTypeId == "" ||
      imageURL == ""
    ) {
      return toast.error("All fields required");

    }

    data = {
      eventName: values?.eventName,
      eventDate: values?.eventDate,
      image: imageURL,
      notes: values?.notes,
      addEmploee: selectedOptions?.map((item) => item?.value),
      isHighLighted: values?.isHighLighted,
      eventTypeId: selectedOptionseventTypeId?.value,
      postType: selectedOptionsPostType?.value,
      affinityGroupId: selectedOptionsGroup?.value,
      eventTime: values?.eventTime
    };

    console.log("qwervbnn", data)
    // return false;
    const res = await ApiHelperFunction({
      urlPath: "/add-event",
      method: "POST",
      data,
    });
    if (res?.status) {
      toast.success("Event is added successfully");
      resetForm();
      dispatch(getEventData());
      // closemodal();
    } else {
      toast.error(res?.response?.data?.message || "Something went wrong");
      console.log("ERROR CREATING USER3", res);
    }

    setLoading(false);
  };

  const schemaCheck = Yup.object(
    activity === "billBoard" ||
      activity === "events" ||
      activity === "kababMenu" ||
      activity === "editEvent"
      ? {
        eventName: Yup.string().required("Event Name is required"),
        hostedBy: Yup.string().required("Host name is required"),
        eventDate: Yup.string().required("Event date is required"),
        eventstarttime: Yup.string().required("start Time is required"),
        eventendtime: Yup.string().required(" End Time is required"),
        notes: Yup.string().required(" Notes is required"),
        addinvites: Yup.string().required(" Addinvites is required"),
        lattitude: Yup.string().required(" lattitude is required"),

        longitude: Yup.string().required(" longitude is required"),
      }
      : activity === "training" || activity === "editTraining"
        ? {
          name: Yup.string().required("Event Name is required"),
          trainingDate: Yup.string().required("Training date is required"),
        }
        : activity === "jobReffered"
          ? {
            name: Yup.string().required("Job Name is required"),
            location: Yup.string().required("Job location is required"),
            salary: Yup.string().required("Salary range is required"),
            description: Yup.string().required("Description range is required"),
            opening: Yup.string().required("Opening is required"),
          }
          : ""
  );


  const handleImageChange = async (e) => {
    let image = e.target.files[0];
    setUploading(true);

    const form = new FormData();
    form.append("image", image);

    let res = await fileUpload("/image-upload", "POST", form);

    if (res.status) {
      toast.success("Image uploaded successfully");
      setImageURL(res?.image);
    } else {
      toast.error("Error uploading image");
    }
    setUploading(false);
  };

  const { values, errors, handleBlur, handleSubmit, resetForm, handleChange } =
    useFormik({
      initialValues,
      validationSchema: schemaCheck,
      validateOnChange: shouldValidateOnChange,
      validateOnBlur: shouldValidateOnBlur,
      onSubmit: (val) => {
        console.log("val", val);
      },
    });

  return (
    <div className="advocacyaddformmain">
      <div className="employeepagecontainer">
        <div className="advocacyfrmdivmain">


          {/* <div className="bulletintextclose">
              <div className="bulletinimageflxdivmodal">
                <div className="bulleBoardFig">
                  <img src={Event_View} alt="..." />
                </div>
                <span className="newBulleCreHead">Add Event</span>
              </div>

             
            </div> */}

          {/* <Formik initialValues={initialValues} onSubmit={onSubmit}> */}

          <form>
            <div className=''>




              {/* <div className=""> */}
              {/* <button className="addCurLocaBtn">
                <i class="fa-solid fa-location-crosshairs"></i>
                Add location
              </button> */}
              {/* <input
                type="text"
                placeholder="Add location"
                className="modAddLocaInp"
              /> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.6rem",
                }}
              >
                <p>lattitude:</p>
                <input
                  style={{ backgroundColor: "white" }}
                  type="text"
                  id="addlocation"
                  value={values.lattitude}
                  name="eventstarttime"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className="homePgMoInps"
                />
                <p>longitude:</p>
                <input
                  style={{ backgroundColor: "white" }}
                  type="text"
                  id="eventDate"
                  value={values.longitude}
                  name="eventendtime"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                />
              </div> */}
              {/* </div> */}

              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt">Event Name <span className='required'>*</span></p>
                <input
                  type="text"
                  id="eventName"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={values?.eventName}
                  name="eventName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="typstrningwhselctnew"
                />
                <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.eventName}
                </small>
              </div>

              {/* <div className="homePgModInnerInpDivsmain">
                <p>Hosted by</p>
                <input
                  type="text"
                  id="hostedBy"
                  placeholder="Hosted by"
                  value={values?.hostedBy}
                  name="hostedBy"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                />
                <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.hostedBy}
                </small>
              </div> */}

              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt">Event Date  <span className='required'>*</span></p>
                <input
                  type="date"
                  id="eventDate"
                  placeholder=""
                  value={values?.eventDate}
                  name="eventDate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="typstrningwhselctnew"
                  min={new Date()?.toISOString()?.split("T")[0]}
                />
                <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.eventDate}
                </small>
                {/* <div
                style={{
                  paddingTop: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label>Pick Time</label>
                <small id="emailHelp" style={{ color: "red" }}>
                  {errors.eventstarttime}
                </small>
                <small id="emailHelp" style={{ color: "red" }}>
                  {localerror}
                  {errors.eventendtime}
                </small>
              </div> */}

                {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.6rem",
                }}
              >
                <input
                  style={{}}
                  type="time"
                  id="eventDate"
                  placeholder="from"
                  value={values.eventstarttime}
                  name="eventstarttime"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFeventstarttime(e.target.value);
                  }}
                  className="homePgMoInps"
                />
                <p>to</p>
                <input
                  style={{}}
                  type="time"
                  id="eventDate"
                  placeholder="upto"
                  value={values.eventendtime}
                  name="eventendtime"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (compareTimes(Feventstarttime, e.target.value)) {
                      handleChange(e);
                      setFeventendtime(handleTimeChange(e));
                      setlocalerror("");
                    } else {
                      setlocalerror("Please select time correctly");
                    }
                  }}
                  className="homePgMoInps"
                />
              </div> */}
              </div>

              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt">Start Time <span className='required'>*</span></p>
                <input
                  type="time"
                  id="eventTime"
                  aria-describedby="emailHelp"
                  placeholder="Enter Time"
                  value={values?.eventTime}
                  name="eventTime"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="typstrningwhselctnew"
                />
                <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.eventTime}
                </small>
              </div>
              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt"> Types Of Event <span className='required'>*</span></p>
                {/* <input
                  type="text"
                  id="hostedBy"
                  placeholder="Add Invites"
                  value={values?.addinvites}
                  name="addinvites"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                /> */}
                <Select
                  placeholder={"Select Event Type"}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  defaultValue={[]}
                  options={colourOptionseventTypeId}
                  onChange={handleSelectChangeEventType}
                  className="typstrningwhselctnew"
                />
                {/* <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.addinvites}
                </small> */}
              </div>

              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt"> Share Your Event <span className='required'>*</span></p>
                {/* <input
                  type="text"
                  id="hostedBy"
                  placeholder="Add Invites"
                  value={values?.addinvites}
                  name="addinvites"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                /> */}


                <Select
                  placeholder={"Select Post Type"}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  defaultValue={[]}
                  options={options}
                  onChange={handleSelectChangePostType}
                  className="typstrningwhselctnew"
                />


                {/* <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.addinvites}
                </small> */}
              </div>

              {selectedOptionsPostType && selectedOptionsPostType?.value === 'private' &&
                (<div className="trainingpageleftdivmain">
                  <p className="rsntxrpnewtxt"> Affinity Groups <span className='required'>*</span></p>
                  {/* <input
                  type="text"
                  id="hostedBy"
                  placeholder="Add Invites"
                  value={values?.addinvites}
                  name="addinvites"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                /> */}


                  <Select
                    placeholder={"Select Invitees"}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={[]}
                    options={colourOptionsGroup}
                    onChange={handleSelectChangeGroup}
                    className="typstrningwhselctnew"
                  />


                  {/* <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.addinvites}
                </small> */}
                </div>)
              }


              {selectedOptionsPostType && selectedOptionsPostType?.value === 'public' && (
                <div className="trainingpageleftdivmain">
                  <p className="rsntxrpnewtxt">Invitation</p>
                  {/* <input
                  type="text"
                  id="hostedBy"
                  placeholder="Add Invites"
                  value={values?.addinvites}
                  name="addinvites"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="homePgMoInps"
                /> */}
                  <Select
                    placeholder={"Select Invitees"}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    options={colourOptions}
                    onChange={handleSelectChange}
                    className="typstrningwhselctnew"
                  />
                  {/* <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.addinvites}
                </small> */}
                </div>)
              }




              <div className="trainingpageleftdivmain">
                <p className="rsntxrpnewtxt">Is this event highlighted?</p>
                <div className='isHighLightedRadiodivnewdiv'>
                  <div className='isHighLightedRadiodivnew'>
                    <input
                      type="radio"
                      id="isHighLightedYes"
                      name="isHighLighted"
                      value="yes"
                      checked={values?.isHighLighted === "yes"}
                      onChange={handleChange}
                      className=""
                    />
                    <label htmlFor="isHighLightedYes" className="radioLabel" >
                      Yes
                    </label>
                  </div>
                  <div className='isHighLightedRadiodivnew'>
                    <input
                      type="radio"
                      id="isHighLightedNo"
                      name="isHighLighted"
                      value="no"
                      checked={values?.isHighLighted === "no"}
                      onChange={handleChange}
                      className=""
                    />
                    <label htmlFor="isHighLightedNo" className="radioLabel" >
                      No
                    </label>
                  </div>
                </div>

                <small id="isHighLightedHelp" style={{ color: "red" }}>
                  {errors?.isHighLighted}
                </small>
              </div>

              <div className="trainingrifgtdivgappp">
                <p className="rsntxrpnewtxt">Event Details</p>
                <textarea
                  value={values?.notes}
                  name="notes"
                  id=""
                  rows={4}
                  cols={50}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="txtdivallartadvcacytfnew"
                ></textarea>
                <small id="emailHelp" style={{ color: "red" }}>
                  {localerror}
                  {errors?.notes}
                </small>
              </div>

              {/* <div className="homePgModInnerInpDivs">
                <input
                  type="file"
                  id="hostedBy"
                  placeholder="Choose a photo"
                  value={image}
                  accept="image/*"
                  name="hostedBy"
                  onBlur={handleBlur}
                  onChange={handleImageChange}
                />
                <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.image}
                </small>
                {uploading ? <p>image uploading......</p> : null}
                {imageURL !== "" && (
                  <div style={{ display: "flex" }}>
                    <img
                      style={{ marginTop: "1rem" }}
                      src={imageURL}
                      alt=""
                      height="100rem"
                      width="100rem"
                    />
                    <div
                      onClick={() => {
                        setImageURL("");
                        setImage("");
                      }}
                    >
                      <i
                        class="fa-solid fa-xmark"
                        style={{
                          color: "red",
                          fontSize: "2rem",
                          cursor: "pointer",
                        }}
                      ></i>
                    </div>
                  </div>
                )}
              </div> */}


              <div className="vdoulddivpbupldgnewmmll">
                <p className="rsntxrpnewtxt"> Image Upload <span className='required'>*</span></p>
                {/* <div className="bgcontainerupldboxsgwgrdiv">
                  <div className="bgcrdbulletin">
                    <div className="bgcontainerupldfiledivnew">
                      <div className="bgcrd">
                        <img src={Addicon} alt="..." />
                        <div className="">
                          <input
                            type="file"
                            id="hostedBy"
                            placeholder="Choose a photo"
                            value={image}
                            accept="image/*"
                            name="hostedBy"
                            onBlur={handleBlur}
                            onChange={handleImageChange}
                            className="upldimagediv"
                          />

                        </div>
                      </div>
                    </div>
                    <div className="bgcontainerupldfiledivabslt">

                      {imageURL !== "" && (
                        <div className="upldimgdivbulletin" >
                          <img
                            src={imageURL}
                            alt="" />
                          <div className="clsoedivmainrd"
                            onClick={() => {
                              setImageURL("");
                              setImage("");
                            }}>
                            <i className="fa-solid fa-xmark"></i>
                          </div>
                        </div>
                      )}
                    </div>

                  </div>
                </div> */}
                {/* {uploading ? <p>image uploading......</p> : null} */}
                <div className="bgcontainerupldboxsgwgrdiv">
                  <div className="bgcrd">
                    <div className="bgcontaineruplddivnew">
                      <div className="logouplddiv">
                        <img
                          src={Addicon}
                          alt="..."
                        />
                        <div className="">
                          <input
                            type="file"
                            id="hostedBy"
                            className="upldimagediv"
                            placeholder="Choose a photo"
                            value={image}
                            accept="image/*"
                            name="hostedBy"
                            onBlur={handleBlur}
                            onChange={handleImageChange}
                          />
                        </div>
                      </div>

                    </div>

                    <p className="upldtxtppdiv">Upload min 5 mb image</p>

                    {uploading ? <p>image uploading......</p> : null}
                    <div className="bgcontainerupldfiledivabslt">
                      {imageURL !== "" && (
                        <div className='imageuplddiv' >
                          <img

                            src={imageURL}
                            alt=""

                          />
                          <div className='imageupldcrs'
                            onClick={() => {
                              setImageURL("");
                              setImage("");
                            }}
                          >
                            <i
                              class="fa-solid fa-xmark"

                            ></i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bulletinsbmtbtndivv">
                <button
                  type="button"
                  className="bulletinsbmtbtn"
                  onClick={submitHandler}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* </Formik> */}

      </div>
    </div>


  )
}

export default EventsModal