import React from 'react'
import { useState } from 'react';
import Advocacyanalytics from './Advocacyanalytics';
import Employeevoiceanalytics from './Employeevoiceanalytics';
const Hranalyticssub = () => {
    const [activeTab, setactiveTab] = useState(1); // Start with Tab 1 activechng

    // Event handler for changing the activechng tab
    const handleTabClick = (tabNumber) => {
        setactiveTab(tabNumber);
    };
    return (
        <>

            <section className="hranalyticssubsectoion">
              
                    <div className='hranylytcicsdivright'>
                        <div className='hranylytcicsdivrightbg'>
                            <ul className="tab-titlesdiv">
                                <li
                                    className={activeTab === 1 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(1)}
                                >
                                    Employee Demography

                                </li>
                                <li
                                    className={activeTab === 2 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(2)}
                                >
                                    Engagement - Employee
                                </li>
                                <li
                                    className={activeTab === 3 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(3)}
                                >
                                    Hiring
                                </li>
                                <li
                                    className={activeTab === 4 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(4)}
                                >
                                    Onboarding
                                </li>
                                <li
                                    className={activeTab === 5 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(5)}
                                >
                                    Advocacy

                                </li>
                                <li
                                    className={activeTab === 6 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(6)}
                                >
                                    Employee Voice

                                </li>
                                <li
                                    className={activeTab === 7 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(7)}
                                >
                                    Training

                                </li>
                                <li
                                    className={activeTab === 8 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(8)}
                                >
                                    Suggestion Received

                                </li>
                                <li
                                    className={activeTab === 9 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(9)}
                                >
                                    Grievance Received
                                </li>
                                <li
                                    className={activeTab === 10 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(10)}
                                >
                                    My team engagement

                                </li>
                                <li
                                    className={activeTab === 11 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(11)}
                                >
                                    Reward Breakup

                                </li>
                                <li
                                    className={activeTab === 12 ? 'activechng' : 'activechngtabnotchng'}
                                    onClick={() => handleTabClick(12)}
                                >
                                    Initiatives
                                  
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='hranylytcicsdivleftdibvmain'>

                 
                    <div className='hranylytcicsdivleft'>
                        <div className="tab-content">
                            {activeTab === 1 && (
                                <div>
                                  
                                </div>
                            )}
                            {activeTab === 2 && (
                                <div>
                                   
                                </div>
                            )}
                            {activeTab === 3 && (
                                <div>
                                 
                                </div>
                            )}
                                 {activeTab === 4 && (
                                <div>
                                   
                                </div>
                            )}
                                 {activeTab === 5 && (
                                <div>
                                   <Advocacyanalytics/>
                                </div>
                            )}
                                 {activeTab === 6 && (
                                <div>
                                 <Employeevoiceanalytics/>
                                </div>
                            )}
                        </div>
                    </div>
                    </div>
               
            </section >
        </>
    )
}

export default Hranalyticssub
