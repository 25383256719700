import React from "react";

const Reward = ({ closemodal, userData }) => {
    console.log(userData, "userDataqwe")
    return (
        <>
            <section className="viedetailsmain">
                <div className="viewdetailscontent">

                    
                    <div className='bulletintextclose'>
                    <p className='newBulleBrodHead'>Rewards Point Details </p>
                    <div className='closedivred' onClick={() => closemodal()}>
                    <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>
                    <div className="rewardtm">
                        <div className="">
                            <p className="biodetailstxtopnop">Coming on hallfame : {userData?.comingonhallfame}</p>
                        </div>
                        <div className="">
                            <p className="biodetailstxtopnop"> Comment : {userData?.comment}</p>
                        </div>
                        <div className="">
                            <p className="biodetailstxtopnop"> Complete Onboarding : {userData?.completeOnboarding}</p>
                        </div>
                        <div className="">
                            <p className="biodetailstxtopnop"> Joining Affinity Groups : {userData?.joiningAffinityGroups}</p>
                        </div>
                        <div className="">
                            <p className="biodetailstxtopnop"> Post : {userData?.post}</p>
                        </div>
                        <div className="">
                            <p className="biodetailstxtopnop"> Publishing Advocacy : {userData?.publishingAdvocacy}</p>
                        </div>
                        <div className="">
                            <p className="biodetailstxtopnop"> React : {userData?.react}</p>
                        </div>
                        <div className="">
                            <p className="biodetailstxtopnop"> Survey Completion  : {userData?.surveyCompletion}</p>
                        </div>
                        <div className="">
                            <p className="biodetailstxtopnop"> Claim Points  : {userData?.claimPoint}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Reward