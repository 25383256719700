import React, { useEffect, useState } from 'react'
import "./hranalytics.css";
import Chart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import Genderanalysis from './Genderanalysis';
import Ageanalysis from './Ageanalysis';
import Educationanalysis from './Educationanalysis';
import Rewardpoints from './Rewardpoints';
import Initiativeanlytics from './Initiativeanlytics';
import ExpandGradient from "../../Images/headericon/ExpandGradient.png"
import { Link } from 'react-router-dom';
import { ApiHelperFunction } from '../../services/api/apiHelpers';

const Hranalyticspage = () => {
    const [category, setCategory] = useState([]);
    const [genderCount, setGenderCount] = useState([]);
    const [categoryAge, setCategoryAge] = useState([]);
    const [AgeCount, setAgeCount] = useState([]);
    const [AdvocacyCount, setAdvocacyCount] = useState([]);

    const [Project, setProject] = useState([]);
    const [ProjectCount, setProjectCount] = useState([]);

    const [Suggestion, setSuggestion] = useState([]);
    const [SuggestionCount, setSuggestionCount] = useState([]);

    const [Grievence, setGrievence] = useState([]);
    const [GrievenceCount, setGrievenceCount] = useState([]);

    const [TrainingCount, setTrainingCount] = useState([]);
    const [HoursCount, setHoursCount] = useState([]);

    const [Empvc, setEmpvc] = useState([]);

    useEffect(() => {
        viewCount();
        viewCompanyTree();
        viewInitiativeCount();
        viewSuggestionCount();
        viewGrievenceCount();
        viewTrainingCount();
        viewEmployeeVoice();
        viewTrainingHoursCount();
    }, [])

    //for viewing count
    const viewCount = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/gender-and-age-calculation`,
            method: "GET",
        });
        // for gender data
        if (response.status) {
            let t = response?.data?.data?.[0]?.genderData?.map((item) => item?.total);
            let array = Object?.values(t);
            setGenderCount(array);
            let i = response?.data?.data?.[0]?.genderData?.map((item) => item?._id);
            let array1 = Object?.values(i);
            setCategory(array1);


            // for age data
            let a = response?.data?.data?.[0]?.ageData?.map((item) => item?.totalUsers);
            let array2 = Object?.values(a);
            setAgeCount(array2);
            let u = response?.data?.data?.[0]?.ageData?.map((item) => item?._id);
            let array3 = Object?.values(u);
            setCategoryAge(array3);
        }
    };


    //for Initiative analysis
    const viewInitiativeCount = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/intiative-analysis`,
            method: "GET",
        });
        // for initiative and count type
        if (response.status) {
            let t = response?.data?.data?.map((item) => item?.total);
            let array = Object?.values(t);
            setProjectCount(array);
            let i = response?.data?.data?.map((item) => item?._id);
            let array1 = Object?.values(i);
            setProject(array1);
        }
    };


    //for Suggestion analysis
    const viewSuggestionCount = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/suggestion-analysis`,
            method: "GET"
        });
        //for initiative and count type
        if (response?.status) {
            let t = response?.data?.data?.map((item) => item?.total);
            let array = Object?.values(t);
            setSuggestionCount(array);
            let i = response?.data?.data?.map((item) => item?._id);
            let array1 = Object?.values(i);
            setSuggestion(array1);
        }
    };


    //for Grievence analysis
    const viewGrievenceCount = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/complaint-analysis`,
            method: "GET"
        });
        //for Grievence type
        if (response?.status) {
            let t = response?.data?.data?.map((item) => item?.total);
            let array = Object?.values(t);
            setGrievenceCount(array);
            let i = response?.data?.data?.map((item) => item?._id);
            let array1 = Object?.values(i);
            setGrievence(array1);
        }
    };


    //for viewing Advocacy count
    const viewCompanyTree = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/advocacy-post-analysis`,
            method: "GET"
        });
        if (response.status) {
            setAdvocacyCount(response?.data?.data?.[0])
        }
    };

    //for Training analysis
    const viewTrainingCount = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/training-participation-analysis`,
            method: "GET",
        });
        if (response.status) {
            let t = response?.data?.data?.map((item) => item?.count);
            let array = Object?.values(t);
            setTrainingCount(array);

        }
    };

     //for Training analysis
     const viewTrainingHoursCount = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/hours-of-training-analysis`,
            method: "GET",
        });
        if (response.status) {
            let t = response?.data?.data?.map((item) => item?.totalWatch);
            let array = Object?.values(t);
            setHoursCount(array);

        }
    };

    //for Employee Voice
    const viewEmployeeVoice = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/employee-voice-analysis`,
            method: "GET",
        });
        if (response.status) {
            // let t = response?.data?.data?.map((item) => item?.count);
            // let array = Object?.values(t);
            setEmpvc(response?.data?.data);

        }
    };


    const options = {
        chart: {
            type: 'bar',
            height: 100,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },

        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '11px',
                            fontWeight: 500,
                        }
                    }
                }
            },
        },
        // xaxis: {

        // },
        yaxis: {
            show: false
        },
        labels: ['<25', '25-34', '35-44', '45-55', '>55'],

        legend: {
            position: 'right',
            offsetY: 40
        },
        fill: {
            opacity: 1,
            colors: ['#142553']
        }
    };

    const spark1 = {
        chart: {
            id: 'sparkline1',
            type: 'line',
            height: 140,
            sparkline: {
                enabled: true
            },
            group: 'sparklines'
        },
        series: [{
            name: 'purple',
            data: [25, 66, 41, 59, 25, 44, 12, 36, 9, 21, 25, 66, 41, 59, 25, 44, 12, 36, 9, 21]
        }],
        stroke: {
            curve: 'smooth'
        },
        markers: {
            size: 0
        },
        tooltip: {
            fixed: {
                enabled: true,
                position: 'right'
            },
            x: {
                show: false
            }
        },
        colors: ['#734CEA']
    };

    const [seriess, setSeriess] = useState([44, 55, 13]);

    const options1 = {
        chart: {
            width: 400,
            type: 'donut',
            height: 600,
        },
        labels: ['Promoters 60%', 'Passives 30%', 'Detractors 10% '],
        fill: {
            opacity: 1,
            colors: ['#cc051c', '#142553', "#ccc"]
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    show: false
                }
            },
            breakpoint: 420,
            options: {
                chart: {
                    width: 200,
                    height: 200,
                },
                legend: {
                    show: false
                }
            }
        }],
        legend: {
            position: 'right',
            offsetY: 0,
            // height: 230,
        }
    };

    const optionsparticipant = {
        series: [{
            name: 'Month',
            data: TrainingCount
            // [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
        }],
        chart: {
            height: 350,
            type: 'bar',
        },
        fill: {
            opacity: 1,
            colors: ['#142553']
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -30,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: true,
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,

            }
        },

    };

    const optionstraining = {
        series: [{
            name: 'Month',
            data: HoursCount
            // [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
        }],
        chart: {
            height: 350,
            type: 'bar',
        },
        fill: {
            opacity: 1,
            colors: ['#cc051c']
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            // formatter: function (val) {
            //     return val + "%";
            // },
            offsetY: -30,
            // offsetX: -30,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            // crosshairs: {
            //     fill: {
            //         type: 'gradient',
            //         gradient: {
            //             colorFrom: '#D8E3F0',
            //             colorTo: '#BED1E6',
            //             stops: [0, 100],
            //             opacityFrom: 0.4,
            //             opacityTo: 0.5,
            //         }
            //     }
            // },
            tooltip: {
                enabled: true,
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                // formatter: function (val) {
                //     return val + "%";
                // }
            }
        },
        // title: {
        //     text: 'Monthly Inflation in Argentina, 2002',
        //     floating: true,
        //     offsetY: 330,
        //     align: 'center',
        //     style: {
        //         color: '#444'
        //     }
        // }
    };

    const [seriessdata, setSeriessdata] = useState([44, 55, 13]);
    const optionsonbord = {
        chart: {
            width: 330,
            type: 'donut',
        },
        labels: ['Linkedin – 60%',
            'Facebook – 30%',
            'Others – 10%'
        ],
        fill: {
            opacity: 1,
            colors: ['#cc051c', '#142553', "#ccc"]
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    show: false
                }
            }
        }],
        legend: {
            position: 'right',
            offsetY: 0,
            height: 230,
        }
    };

    //suggestion amalysis
    const optionspie = {
        seriesdt: SuggestionCount,
        // [20, 46, 34],
        chart: {
            width: 300,
            type: 'pie',
        },
        fill: {
            opacity: 1,
            colors: ['#cc051c', '#142553', "#ccc"]
        },
        labels: Suggestion,
        // ['High', 'Medium', 'Low'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 260
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };


    //Grievence amalysis
    const optionspie1 = {
        seriesdt: GrievenceCount,
        // [20, 46, 34],
        chart: {
            width: 300,
            type: 'pie',
        },
        fill: {
            opacity: 1,
            colors: ['#cc051c', '#142553', "#ccc"]
        },
        labels: Grievence,
        // ['High', 'Medium', 'Low'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 260
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };


    const optionhiringnewfrst = {
        series: [{
            name: 'Inflation',
            data: [15, 12, 10]
        }],
        chart: {
            height: 120,
            minheight: 125,
            type: 'bar',
            width: 130
        },
        fill: {
            opacity: 1,
            colors: ['#142553']
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            // formatter: function (val) {
            //     return val + "%";
            // },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        xaxis: {
            categories: ["<25", "25-34", "35-44", "45-55", ">55"],
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },

            tooltip: {
                enabled: true,
            },
            labels: {
                show: false
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
            }
        },

    };


    const optionhiringnewsnd = {
        series: [{
            name: 'Inflation',
            data: [10, 15, 15]
        }],
        chart: {
            height: 120,
            width: 130,
            type: 'bar',
        },
        fill: {
            opacity: 1,
            colors: ['#cc051c']
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
                gap: 10,
            }
        },
        dataLabels: {
            enabled: true,
            // formatter: function (val) {
            //     return val + "%";
            // },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        xaxis: {
            categories: ["<25", "25-34", "35-44", "45-55", ">55"],
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },

            tooltip: {
                enabled: true,
            },
            labels: {
                show: false
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
            }
        },

    };


    return (
        <>
            <section className="thought_areadivsectiomnmain">
                <div className='thought_areadivflx'>


                    <div className='thought_area_anlytics_left'>
                        <div className='employeedemo'>
                            <div className=''>
                                <p className='emplyeedemotxt'> Employee Demography </p>
                                <div className='genderageflx'>
                                    <Genderanalysis genderCount={genderCount} category={category} />
                                    <Ageanalysis AgeCount={AgeCount} categoryAge={categoryAge} />
                                </div>
                            </div>
                            <div className='educationdivmain'>
                                <p className='emplyeedemotxt'> Education </p>
                                <Educationanalysis />
                            </div>
                        </div>

                        <div className='employeehring'>
                            <div className='analyticsghedrtxticnflx'>
                                <div className=''>
                                    <p className="emplyeedemotxt">Hiring</p>
                                </div>
                                <div className='expndimgdiv'>
                                    <img src={ExpandGradient} alt="..." />
                                </div>
                            </div>
                            <div className='hirerefralfillngnewflx'>
                                <div className='newhiriesdiv'>
                                    <div className='hringdivmann'>
                                        No. of New Hires
                                    </div>
                                    <div className=''>
                                        <p className='hritxtppngf'>90</p>
                                        <p className='hringtxtpptxtd'>Hiries</p>
                                    </div>
                                    <div className='optionshringdivmaindiv'>
                                        <div className='optionghringdivfst'>
                                            <Chart
                                                options={optionhiringnewfrst}
                                                series={optionhiringnewfrst.series}
                                                type="bar"
                                                height={optionhiringnewfrst.chart.height}
                                                width={optionhiringnewfrst.chart.width}
                                            />
                                            <div className='hiringiocnspclhowndiffflas'>
                                                <div className='iconupgrntxt'>
                                                    <div className='inldmghtyn'>
                                                        <i class="fa-solid fa-caret-up"></i>
                                                    </div>
                                                    <div className=''>
                                                        <p className='plujbhgtnpp'>+25</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='optionghringdivsscnd'>
                                            <Chart
                                                options={optionhiringnewsnd}
                                                series={optionhiringnewsnd.series}
                                                type="bar"
                                                height={optionhiringnewsnd.chart.height}
                                                width={optionhiringnewsnd.chart.width}
                                            />

                                        </div>

                                    </div>
                                </div>

                                <div className='newhiriesdiv'>
                                    <div className='hringdivmann'>
                                        No. of Referral Hire

                                    </div>
                                    <div className=''>
                                        <p className='hritxtppngf'>24.0%
                                        </p>
                                        <p className='hringtxtpptxtd'>Referral</p>
                                    </div>
                                    <div className='optionshringdivmaindiv'>
                                        <div className='optionghringdivfst'>
                                            <Chart
                                                options={optionhiringnewfrst}
                                                series={optionhiringnewfrst.series}
                                                type="bar"
                                                height={optionhiringnewfrst.chart.height}
                                                width={optionhiringnewfrst.chart.width}
                                            />


                                        </div>
                                        <div className='optionghringdivsscnd'>
                                            <Chart
                                                options={optionhiringnewsnd}
                                                series={optionhiringnewsnd.series}
                                                type="bar"
                                                height={optionhiringnewsnd.chart.height}
                                                width={optionhiringnewsnd.chart.width}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='newhiriesdiv'>
                                    <div className='hringdivmann'>
                                        New Hire retention

                                    </div>
                                    <div className=''>
                                        <p className='hritxtppngf'>24.0%
                                        </p>
                                        <p className='hringtxtpptxtd'>Retention</p>
                                    </div>
                                    <div className='optionshringdivmaindiv'>
                                        <div className='optionghringdivfst'>
                                            <Chart
                                                options={optionhiringnewfrst}
                                                series={optionhiringnewfrst.series}
                                                type="bar"
                                                height={optionhiringnewfrst.chart.height}
                                                width={optionhiringnewfrst.chart.width}
                                            />
                                        </div>

                                        <div className='optionghringdivsscnd'>
                                            <Chart
                                                options={optionhiringnewsnd}
                                                series={optionhiringnewsnd.series}
                                                type="bar"
                                                height={optionhiringnewsnd.chart.height}
                                                width={optionhiringnewsnd.chart.width}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='newhiriesdiv'>
                                    <div className='hringdivmann'>
                                        No. of Appl /Hire
                                    </div>
                                    <div className=''>
                                        <p className='hritxtppngf'>19
                                        </p>
                                        <p className='hringtxtpptxtd'>Apps</p>
                                    </div>
                                    <div className='optionshringdivmaindiv'>
                                        <div className='optionghringdivfst'>
                                            <Chart
                                                options={optionhiringnewfrst}
                                                series={optionhiringnewfrst.series}
                                                type="bar"
                                                height={optionhiringnewfrst.chart.height}
                                                width={optionhiringnewfrst.chart.width}
                                            />
                                        </div>
                                        <div className='optionghringdivsscnd'>
                                            <Chart
                                                options={optionhiringnewsnd}
                                                series={optionhiringnewsnd.series}
                                                type="bar"
                                                height={optionhiringnewsnd.chart.height}
                                                width={optionhiringnewsnd.chart.width}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='newhiriesdiv'>
                                    <div className='hringdivmann'>
                                        Time to fill
                                    </div>
                                    <div className=''>
                                        <p className='hritxtppngf'>7 </p>
                                        <p className='hringtxtpptxtd'>Days</p>
                                    </div>
                                    <div className='optionshringdivmaindiv'>
                                        <div className='optionghringdivfst'>
                                            <Chart
                                                options={optionhiringnewfrst}
                                                series={optionhiringnewfrst.series}
                                                type="bar"
                                                height={optionhiringnewfrst.chart.height}
                                                width={optionhiringnewfrst.chart.width}
                                            />
                                        </div>
                                        <div className='optionghringdivsscnd'>
                                            <Chart
                                                options={optionhiringnewsnd}
                                                series={optionhiringnewsnd.series}
                                                type="bar"
                                                height={optionhiringnewsnd.chart.height}
                                                width={optionhiringnewsnd.chart.width}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='employeehring'>
                            <div className='analyticsghedrtxticnflx'>
                                <div className=''>
                                    <p className="emplyeedemotxt">Onboarding
                                    </p>
                                </div>
                                <div className='expndimgdiv'>
                                    <img src={ExpandGradient} alt="..." />
                                </div>
                            </div>
                            <div className='hirerefralfillngnewflx'>
                                <div className='newhiriesdiv'>
                                    <div className='hringdivmann'>
                                        Onboarding Time


                                    </div>
                                    <div className=''>
                                        <p className='hritxtppngf'>90</p>
                                        <p className='hringtxtpptxtd'>Hiries</p>
                                    </div>
                                    <div className='optionshringdivmaindiv'>
                                        <div className='optionghringdivfst'>
                                            <Chart
                                                options={optionhiringnewfrst}
                                                series={optionhiringnewfrst.series}
                                                type="bar"
                                                height={optionhiringnewfrst.chart.height}
                                                width={optionhiringnewfrst.chart.width}
                                            />
                                            <div className='hiringiocnspclhowndiffflas'>
                                                <div className='iconupgrntxt'>
                                                    <div className='inldmghtyn'>
                                                        <i class="fa-solid fa-caret-up"></i>
                                                    </div>
                                                    <div className=''>
                                                        <p className='plujbhgtnpp'>+25</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='optionghringdivsscnd'>
                                            <Chart
                                                options={optionhiringnewsnd}
                                                series={optionhiringnewsnd.series}
                                                type="bar"
                                                height={optionhiringnewsnd.chart.height}
                                                width={optionhiringnewsnd.chart.width}
                                            />

                                        </div>

                                    </div>
                                </div>

                                <div className='newhiriesdiv'>
                                    <div className='hringdivmann'>
                                        No. of Open Posi

                                    </div>
                                    <div className=''>
                                        <p className='hritxtppngf'>24.0%
                                        </p>
                                        <p className='hringtxtpptxtd'>Referral</p>
                                    </div>
                                    <div className='optionshringdivmaindiv'>
                                        <div className='optionghringdivfst'>
                                            <Chart
                                                options={optionhiringnewfrst}
                                                series={optionhiringnewfrst.series}
                                                type="bar"
                                                height={optionhiringnewfrst.chart.height}
                                                width={optionhiringnewfrst.chart.width}
                                            />


                                        </div>
                                        <div className='optionghringdivsscnd'>
                                            <Chart
                                                options={optionhiringnewsnd}
                                                series={optionhiringnewsnd.series}
                                                type="bar"
                                                height={optionhiringnewsnd.chart.height}
                                                width={optionhiringnewsnd.chart.width}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='employeehring'>
                            <div className='analyticsghedrtxticnflx'>
                                <div className=''>
                                    <p className="emplyeedemotxt">Advocacy

                                    </p>
                                </div>
                                {/* <div className='expndimgdiv'>
                                <img src={ExpandGradient} alt="..." />
                            </div> */}
                            </div>

                            <div className='bvjhgjhptcafchjnouydiv'>
                                <p className='bvjhgjhptcafchjnouy'>
                                    {AdvocacyCount?.totalAdvocacy}
                                </p>
                                <p className='bvjhgjhptcafchjnouy' style={{ color: "red" }}>
                                    Total Post
                                </p>
                            </div>
                            <div className='bvjhgjhptcafchjnouydiv'>
                                <p className='bvjhgjhptcafchjnouy'>
                                    {AdvocacyCount?.totalViews}
                                </p>
                                <p className='bvjhgjhptcafchjnouy'>
                                    Views
                                </p>
                            </div>
                            <div className='bvjhgjhptcafchjnouydiv'>
                                <p className='bvjhgjhptcafchjnouy'>
                                    {AdvocacyCount?.totalComments}
                                </p>
                                <p className='bvjhgjhptcafchjnouy'>
                                    Comments
                                </p>
                            </div>
                            <div className='bvjhgjhptcafchjnouydiv'>
                                <p className='bvjhgjhptcafchjnouy'>
                                    {AdvocacyCount?.totalSharingCount}
                                </p>
                                <p className='bvjhgjhptcafchjnouy'>
                                    Shares
                                </p>
                            </div>
                            <div className='bvjhgjhptcafchjnouydiv'>
                                <p className='bvjhgjhptcafchjnouy'>
                                    {AdvocacyCount?.tobeReviewed}
                                </p>
                                <p className='bvjhgjhptcafchjnouy'>
                                    To be Reviewed
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className='thought_area_anlytics-mid'>
                        <div className="thoghtmidanalytics">
                            <div className=''>
                                <p className='emplyeedemotxt'> Engagement - Employee
                                </p>
                                <div className='employeeengdivflx'>
                                    <div className='employeeengament'>
                                        <p className='employeengtxtppm'>Engagement index</p>
                                        <h5 className="emplytxtpppercnt">61%</h5>

                                        <div id="spark1" className="sprkchrtdiv">
                                            <Chart options={spark1} series={spark1.series} type="line" height={20} />
                                        </div>
                                        <div className=''>
                                            <p className='rtingttanlytics'>17.3%</p>
                                            <p className='mnthpretxtp'>vs. previous month</p>
                                        </div>

                                    </div>
                                    <div className='employeeengament'>
                                        <p className='employeengtxtppm'>Net Promoter Score</p>
                                        <h5 className="emplytxtpppercnt">10</h5>
                                        <div id="spark1" className="sprkchrtdiv">
                                            <Chart options={spark1} series={spark1.series} type="line" height={20} />
                                        </div>
                                        <div className=''>
                                            <p className='rtingttanlytics'>25.0%</p>
                                            <p className='mnthpretxtp'>vs. previous month</p>
                                        </div>

                                    </div>
                                    <div className='employeeengament'>
                                        <p className='employeengtxtppm'>Satisfaction index</p>
                                        <h5 className="emplytxtpppercnt">73% </h5>
                                        <div id="spark1" className="sprkchrtdiv">
                                            <Chart options={spark1} series={spark1.series} type="line" height={20} />
                                        </div>
                                        <div className=''>
                                            <p className='rtingttanlytics'>-16.1%</p>
                                            <p className='mnthpretxtp'>vs. previous month</p>
                                        </div>

                                    </div>
                                    <div className='employeeengament'>
                                        <p className='employeengtxtppm'>Turnover Rate</p>
                                        <h5 className="emplytxtpppercnt">18%</h5>
                                        <div id="spark1" className="sprkchrtdiv">
                                            <Chart options={spark1} series={spark1.series} type="line" height={20} />
                                        </div>
                                        <div className=''>
                                            <p className='rtingttanlytics'>18%</p>
                                            <p className='mnthpretxtp'>vs. previous month</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='employeeengdivflx'>
                                    <div className='enpstdistridiv'>
                                        <p className='enpsdistxtp'>eNPS Distribution:</p>
                                        <div className=''>
                                            <div>
                                                <Chart
                                                    options={options1}
                                                    series={seriess}
                                                    type="donut"
                                                    width={400}
                                                    height={600}
                                                />
                                                {/* <button onClick={appendData}>Append Data</button> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='engagemntscrtxtntdiv'>
                                        <p className='enpsdistxtp'>Engagement Score</p>
                                        <div className=''>
                                            <h4 className='engmntscrtxtp'>6.4</h4>
                                            <p className='ntinpblttstpp'>Engagement Score based on Engagement on post, Events and projects.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="employeeanalyticsdiv">
                            <div className=''>
                                <p className='emplyeedemotxt'>Employee Voice</p>
                            </div>
                            <div className="ratingspecialonbrflx">
                                <div className="ratingscorediv">
                                    <table class="table-with-gap">

                                        <tr>
                                            <th className="envddivmainfrstscr" >Score</th>
                                            <th className="envddivmainfrstnmbr">1</th>
                                            <th className="envddivmainfrstnmbr">2</th>
                                            <th className="envddivmainfrstnmbr">3</th>
                                            <th className="envddivmainfrstnmbr">4</th>
                                            <th className="envddivmainfrstnmbr">5</th>
                                        </tr>

                                        {
                                            Empvc?.map((item) => {
                                                return (
                                                    <>
                                                        <tr className="bordrdivanlystd">
                                                            <td className="envddivmain">{item?.name}</td>
                                                            {/* {
                                                                item?.ratingData?.map((ele) => {
                                                                    return (
                                                                        <> */}
                                                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }}>{item?.ratingData?.[0]?.count}</td>
                                                                            <td className="envddivmainfrst" style={{ backgroundColor: "#cc051c" }}>{item?.ratingData?.[1]?.count}</td>
                                                                            <td className="envddivmainfrst" style={{ backgroundColor: "#ccc" }}>{item?.ratingData?.[2]?.count}</td>
                                                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }}>{item?.ratingData?.[3]?.count}</td>
                                                                            <td className="envddivmainfrst" style={{ backgroundColor: "#ccc" }}>{item?.ratingData?.[4]?.count}</td>
                                                                        {/* </>
                                                                    )
                                                                })
                                                            } */}

                                                        </tr>
                                                        <tr class="gapp"></tr>
                                                    </>
                                                )
                                            })
                                        }

                                        {/* <tr className="bordrdivanlystd">
                                            <td className="envddivmain">Job Satisfaction</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }}>72</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#cc051c" }}>43</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }}>62</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#ccc" }}>60</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }}>52</td>
                                        </tr>
                                        <tr class="gapp"></tr>
                                        <tr className="bordrdivanlystd">
                                            <td className="envddivmain">Job Involvement</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }} >72</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#cc051c" }}>43</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }}>62</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#ccc" }}>60</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#cc051c" }}>43</td>
                                        </tr>
                                        <tr class="gapp"></tr>
                                        <tr className="bordrdivanlystd">
                                            <td className="envddivmain">Relationship Satisfaction</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#cc051c" }}>72</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }}>43</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#ccc" }} >62</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }}>60</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }}>43</td>
                                        </tr>
                                        <tr class="gapp"></tr>
                                        <tr className="bordrdivanlystd">
                                            <td className="envddivmain">Work life Balance</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#cc051c" }}>72</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }}>43</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#cc051c" }}>62</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#ccc" }}>60</td>
                                            <td className="envddivmainfrst" style={{ backgroundColor: "#142553" }}>43</td>
                                        </tr> */}

                                    </table>
                                </div>
                                <div className="onbordingdiv">
                                    <div className="optionmbtdtrdivnpmain">
                                        <p className="onbrdpptxtm">Onboarding 30-60-90</p>
                                        <div className="optionmbtdtrdivnp">
                                            <Chart
                                                options={optionsonbord}
                                                series={seriessdata}
                                                type="donut"
                                                width={330}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='traininganalyticsdiv'>
                            <div className=''>
                                <p className='emplyeedemotxt'> Training</p>
                            </div>
                            <div className='participanttrainingflxdiv'>
                                <div className='participantdivmain'>
                                    <p className="participanttxtppp">Number of Participants</p>
                                    <div className=''>
                                        <Chart
                                            options={optionsparticipant}
                                            series={optionsparticipant.series}
                                            type="bar"
                                            height={optionsparticipant.chart.height}
                                        />
                                    </div>
                                </div>
                                <div className='trainingpantdivmain'>
                                    <p className="housetringxttp">Hours of Training</p>
                                    <div className=''>
                                        <Chart
                                            options={optionstraining}
                                            series={optionstraining.series}
                                            type="bar"
                                            height={optionstraining.chart.height}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="">
                            <div className="sugstiongrvncedivflx">
                                <div className="suggestionbox">
                                    <div className="">
                                        <p className="emplyeedemotxt">Suggestion Received</p>
                                    </div>
                                    <div className="SuggestionReceiveddiv">
                                        <Chart
                                            options={optionspie}
                                            series={optionspie.seriesdt}
                                            type="pie"
                                            width={optionspie.chart.width}
                                        />
                                    </div>
                                </div>
                                <div className="grivencebox">
                                    <div className="">
                                        <p className="emplyeedemotxt">Grivence Received</p>
                                    </div>
                                    <div className="GrivenceReceiveddiv">
                                        <Chart
                                            options={optionspie1}
                                            series={optionspie1.seriesdt}
                                            type="pie"
                                            width={optionspie1.chart.width}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='thought_area_anlytics_right'>
                        <div className='engagementdivmain'>
                            <div className=''>
                                <p className='emplyeedemotxt'>My team engagement
                                </p>
                                <div className='employeeengdivflxtm'>
                                    <div className='employeeengamentengmntdiv'>
                                        <p className='employeengtxtppm'>Engagement index</p>
                                        <h5 className="emplytxtpppercnt">61%</h5>

                                        <div id="spark1" className="sprkchrtdiv">
                                            <Chart options={spark1} series={spark1.series} type="line" height={20} />
                                        </div>
                                        <div className=''>
                                            <p className='rtingttanlytics'>17.3%</p>
                                            <p className='mnthpretxtp'>vs. previous month</p>
                                        </div>

                                    </div>
                                    <div className='employeeengamentengmntdiv'>
                                        <p className='employeengtxtppm'>Net Promoter Score</p>
                                        <h5 className="emplytxtpppercnt">10</h5>
                                        <div id="spark1" className="sprkchrtdiv">
                                            <Chart options={spark1} series={spark1.series} type="line" height={20} />
                                        </div>
                                        <div className=''>
                                            <p className='rtingttanlytics'>25.0%</p>
                                            <p className='mnthpretxtp'>vs. previous month</p>
                                        </div>

                                    </div>
                                    <div className='employeeengamentengmntdiv'>
                                        <p className='employeengtxtppm'>Satisfaction index</p>
                                        <h5 className="emplytxtpppercnt">73% </h5>
                                        <div id="spark1" className="sprkchrtdiv">
                                            <Chart options={spark1} series={spark1.series} type="line" height={20} />
                                        </div>
                                        <div className=''>
                                            <p className='rtingttanlytics'>-16.1%</p>
                                            <p className='mnthpretxtp'>vs. previous month</p>
                                        </div>

                                    </div>
                                    <div className='employeeengamentengmntdiv'>
                                        <p className='employeengtxtppm'>Turnover Rate</p>
                                        <h5 className="emplytxtpppercnt">18%</h5>
                                        <div id="spark1" className="sprkchrtdiv">
                                            <Chart options={spark1} series={spark1.series} type="line" height={20} />
                                        </div>
                                        <div className=''>
                                            <p className='rtingttanlytics'>18%</p>
                                            <p className='mnthpretxtp'>vs. previous month</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='ViewDetailsbtn'>
                            <Link className="ViewDetailsbtnytx" to="/hranalyticssub">
                                View Details
                            </Link>
                        </div>

                        <div className='rewrddivpnts'>
                            <div className=''>
                                <p className='emplyeedemotxt'>Reward Points</p>
                                <div className='rewatddivmain'>
                                    <Rewardpoints />
                                </div>

                            </div>

                        </div>
                        <div className='rewrddivpnts'>
                            <div className=''>
                                <p className='emplyeedemotxt'>Initiatives</p>
                                <div className="">
                                    <Initiativeanlytics ProjectCount={ProjectCount} Project={Project} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hranalyticspage
