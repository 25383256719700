import React, { useEffect, useState } from 'react'
import Info from "../../Images/headericon/Info.png";
import ApexCharts from 'react-apexcharts';
import Chart from 'react-apexcharts';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import coffe from "../../Images/coffe.png"
import { ApiHelperFunction } from '../../services/api/apiHelpers';

const Advocacyanalytics = () => {
    const [advocacyCount, setAdvocacyCount] = useState([]);
    const [publishCountAdmin, setPublishCountAdmin] = useState([]);
    const [publishCountHr, setPublishCountHr] = useState([]);
    const [publishCountOthers, setPublishCountOthers] = useState([]);


    useEffect(() => {
        viewCount();
        viewPublishContent();
    }, [])


    //for viewing count
    const viewCount = async (val) => {
        const response = await ApiHelperFunction({
            urlPath: `/advocacy-analysis/${val}`,
            method: "GET",
        });
        if (response.status) {
            setAdvocacyCount(response?.data?.data)
        }
    };


    //for publish content
    const viewPublishContent = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/advocay-public-content-analysis`,
            method: "GET",
        });

        if (response.status) {
            let admindata = response?.data?.data?.map((item) => {
                const obj = item?.userDetails?.find(ele => ele?.userType === "Owner")
                return obj?.count
            });
            let hrdata = response?.data?.data?.map((item) => {
                const obj = item?.userDetails?.find(ele => ele?.userType === "Admin")
                return obj?.count
            });
            let otherdata = response?.data?.data?.map((item) => {
                const obj = item?.userDetails?.find(ele => ele?.userType === "otherUser")
                return obj?.count
            });
            setPublishCountAdmin(admindata);
            setPublishCountHr(hrdata);
            setPublishCountOthers(otherdata);
        }
    }


    //for publish chart 
    const optionsnewchrt = {
        series: [
            {
                name: 'Admin',
                data: publishCountAdmin,

            },
            {
                name: 'HR',
                data: publishCountHr,

            },
            {
                name: 'Others',
                data: publishCountOthers,

            }
        ],
        chart: {
            type: 'bar',
            height: 200,
            stacked: true,
            stackType: '100%'
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }
        ],
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: true,
            }
        },
        yaxis: {
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
            }
        },
        fill: {
            opacity: 1,
            colors: ['#cc051c', '#142553', "#ccc"]
        },
        legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 50
        }

    };

    const [seriessdata, setSeriessdata] = useState([44, 55, 13]);
    const optionsonbord = {
        chart: {
            width: 330,
            type: 'donut',
            height: 300,
        },
        labels: ['Linkedin – 60%',
            'Facebook – 30%',
            'Others – 10%'
        ],
        fill: {
            opacity: 1,
            colors: ['#cc051c', '#142553', "#ccc"]
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    show: false
                }
            }
        }],
        legend: {
            position: 'right',
            offsetY: 0,
            height: 350,
        }
    };
    return (
        <>
            <section className='advocacyanalyticssectionall'>
                <div className='advovcaytimeslotdivflx'>
                    {/* <div className='advovcaytimeslotmainflx'>
                        <div className='infodivimg'>
                            <img src={Info} alt="..." />
                        </div>
                        <p className='lstppxtpphj'>Last 6 months
                        </p>
                    </div>
                    <div className='advovcaytimeslotmainflx'>
                        <div className='infodivimg'>
                            <img src={Info} alt="..." />
                        </div>
                        <p className='lstppxtpphj'>Last 3 months
                        </p>
                    </div>
                    <div className='advovcaytimeslotmainflx'>
                        <div className='infodivimg'>
                            <img src={Info} alt="..." />
                        </div>
                        <p className='lstppxtpphj'>Last 1 months
                        </p>
                    </div> */}
                    <Tabs>
                        <TabList>
                        
                            <Tab onClick={()=>viewCount(6)}>
                                <div className='advovcaytimeslotmainflx'>
                                    <div className='infodivimg'>
                                        <img src={Info} alt="..." />
                                    </div>
                                    <p className='lstppxtpphj'>Last 6 months
                                    </p>
                                </div>
                            </Tab>
                            <Tab onClick={()=>viewCount(3)}>
                                <div className='advovcaytimeslotmainflx'>
                                    <div className='infodivimg'>
                                        <img src={Info} alt="..." />
                                    </div>
                                    <p className='lstppxtpphj'>Last 3 months
                                    </p>
                                </div>
                            </Tab>
                            <Tab onClick={()=>viewCount(1)}>
                                <div className='advovcaytimeslotmainflx'>
                                    <div className='infodivimg'>
                                        <img src={Info} alt="..." />
                                    </div>
                                    <p className='lstppxtpphj'>Last 1 months
                                    </p>
                                </div>
                            </Tab>

                        </TabList>

                        <div className='advovcaycontentdivflx'>
                            <TabPanel>
                            <div className='advovcaycontentdivflx'>
                                <div className='advocntntdiv'>
                                    <div className='advocntntdivbgfrst'>
                                        <p className='advocntntdivbgpp'>{advocacyCount?.[0]?.totalData?.totalUsers}</p>
                                        <div className='advocntntdivbtmfrst'>
                                            <p className='advocntntdivbgpptr'>Total Content</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='advocntntdiv'>
                                    <div className='advocntntdivbg'>
                                        <p className='advocntntdivbgpp'>{advocacyCount?.[2]?.adminData?.totalUsers}</p>
                                        <div className='advocntntdivbtm'>
                                            <p className='advocntntdivbgpptr'>Admin Content
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className='advocntntdiv'>
                                    <div className='advocntntdivbg'>
                                        <p className='advocntntdivbgpp'>{advocacyCount?.[1]?.othersData?.totalUsers}</p>
                                        <div className='advocntntdivbtm'>
                                            <p className='advocntntdivbgpptr'>User Content
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                            <div className='advovcaycontentdivflx'>
                                <div className='advocntntdiv'>
                                    <div className='advocntntdivbgfrst'>
                                        <p className='advocntntdivbgpp'>{advocacyCount?.[0]?.totalData?.totalUsers}</p>
                                        <div className='advocntntdivbtmfrst'>
                                            <p className='advocntntdivbgpptr'>Total Content</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='advocntntdiv'>
                                    <div className='advocntntdivbg'>
                                        <p className='advocntntdivbgpp'>{advocacyCount?.[2]?.adminData?.totalUsers}</p>
                                        <div className='advocntntdivbtm'>
                                            <p className='advocntntdivbgpptr'>Admin Content
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className='advocntntdiv'>
                                    <div className='advocntntdivbg'>
                                        <p className='advocntntdivbgpp'>{advocacyCount?.[1]?.othersData?.totalUsers}</p>
                                        <div className='advocntntdivbtm'>
                                            <p className='advocntntdivbgpptr'>User Content
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                            <div className='advovcaycontentdivflx'>
                                <div className='advocntntdiv'>
                                    <div className='advocntntdivbgfrst'>
                                        <p className='advocntntdivbgpp'>{advocacyCount?.[0]?.totalData?.totalUsers}</p>
                                        <div className='advocntntdivbtmfrst'>
                                            <p className='advocntntdivbgpptr'>Total Content</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='advocntntdiv'>
                                    <div className='advocntntdivbg'>
                                        <p className='advocntntdivbgpp'>{advocacyCount?.[2]?.adminData?.totalUsers}</p>
                                        <div className='advocntntdivbtm'>
                                            <p className='advocntntdivbgpptr'>Admin Content
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className='advocntntdiv'>
                                    <div className='advocntntdivbg'>
                                        <p className='advocntntdivbgpp'>{advocacyCount?.[1]?.othersData?.totalUsers}</p>
                                        <div className='advocntntdivbtm'>
                                            <p className='advocntntdivbgpptr'>User Content
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                </div>
                            </TabPanel>
                        </div>
                    </Tabs>

                </div>
                {/* <div className='advovcaycontentdivflx'>

                    <div className='advocntntdiv'>
                        <div className='advocntntdivbgfrst'>
                            <p className='advocntntdivbgpp'>{advocacyCount?.[0]?.totalData?.totalUsers}</p>
                            <div className='advocntntdivbtmfrst'>
                                <p className='advocntntdivbgpptr'>Total Content</p>
                            </div>
                        </div>
                    </div>

                    <div className='advocntntdiv'>
                        <div className='advocntntdivbg'>
                            <p className='advocntntdivbgpp'>{advocacyCount?.[2]?.adminData?.totalUsers}</p>
                            <div className='advocntntdivbtm'>
                                <p className='advocntntdivbgpptr'>Admin Content
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className='advocntntdiv'>
                        <div className='advocntntdivbg'>
                            <p className='advocntntdivbgpp'>{advocacyCount?.[1]?.othersData?.totalUsers}</p>
                            <div className='advocntntdivbtm'>
                                <p className='advocntntdivbgpptr'>User Content
                                </p>
                            </div>
                        </div>

                    </div>
                </div> */}

                <div className='advocacypblxhednetwrkdidivflx'>
                    <div className='advcydivcntntrleft'>
                        <div className='advcydivcntntrleftbng'>
                            <p className='advocntntdivbgpptrtr'>Number of published Content
                            </p>
                            <div id="chart">
                                <ApexCharts options={optionsnewchrt} series={optionsnewchrt.series} type="bar" height={optionsnewchrt.chart.height} />
                            </div>
                        </div>
                    </div>
                    <div className='advcydivcntntrright'>
                        <div className='advcydivcntntrrightbg'>
                            <p className='advocntntdivbgpptrtr'>NetWork Activity</p>

                            <div className=''>
                                <div className="optionmbtdtrdivnpadvocay">
                                    <Chart
                                        options={optionsonbord}
                                        series={seriessdata}
                                        type="donut"
                                        width={330}
                                        height={300}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='tabsadvcyalldiv'>
                    <Tabs>
                        <TabList>
                            <Tab>All Post</Tab>
                            <Tab>All Published</Tab>
                            <Tab>Scheduled</Tab>
                            <Tab>Drafts</Tab>
                            <Tab>Expired</Tab>
                            <Tab>Expiring</Tab>
                        </TabList>

                        <TabPanel>

                        </TabPanel>
                        <TabPanel>
                            <div className='tabsaconstadvcdivbg'>
                                <table className='table'>
                                    <tr>
                                        <th>Post</th>
                                        <th>CTR</th>
                                        <th>Engage Ration</th>
                                        <th>Impression</th>
                                        <th>Likes &
                                            Follows
                                        </th>
                                        <th>Demographics
                                        </th>
                                        <th>Time spent
                                        </th>
                                    </tr>
                                    <tr class="gappvcdivbg"></tr>
                                    <tr>
                                        <td>
                                            <div className='imgdivtxtdivadvghhflx'>
                                                <div className='cdefidhimg'>
                                                    <img src={coffe} alt="..." />
                                                </div>
                                                <div className=''>
                                                    <p className='cfreedsproutghbp'> I love Coffee! #Sprout</p>
                                                    <p className='sprtrtsxgpsdspn'>
                                                        <span>

                                                        </span>
                                                        Sprout coffee co
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td>16</td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                    </tr>
                                    <tr class="gappvcdivbg"></tr>
                                    <tr>
                                        <td>
                                            <div className='imgdivtxtdivadvghhflx'>
                                                <div className='cdefidhimg'>
                                                    <img src={coffe} alt="..." />
                                                </div>
                                                <div className=''>
                                                    <p className='cfreedsproutghbp'> I love Coffee! #Sprout</p>
                                                    <p className='sprtrtsxgpsdspn'>
                                                        <span>

                                                        </span>
                                                        Sprout coffee co
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td>16</td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                    </tr>
                                    <tr class="gappvcdivbg"></tr>

                                    <tr>
                                        <td>
                                            <div className='imgdivtxtdivadvghhflx'>
                                                <div className='cdefidhimg'>
                                                    <img src={coffe} alt="..." />
                                                </div>
                                                <div className=''>
                                                    <p className='cfreedsproutghbp'> I love Coffee! #Sprout</p>
                                                    <p className='sprtrtsxgpsdspn'>
                                                        <span>

                                                        </span>
                                                        Sprout coffee co
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td>16</td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                    </tr>
                                    <tr class="gappvcdivbg"></tr>
                                    <tr>
                                        <td>
                                            <div className='imgdivtxtdivadvghhflx'>
                                                <div className='cdefidhimg'>
                                                    <img src={coffe} alt="..." />
                                                </div>
                                                <div className=''>
                                                    <p className='cfreedsproutghbp'> I love Coffee! #Sprout</p>
                                                    <p className='sprtrtsxgpsdspn'>
                                                        <span>

                                                        </span>
                                                        Sprout coffee co
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                        <td>16</td>
                                        <td><div className=''>
                                            <p className='poimhvhhtxtippadvc'>16</p>
                                        </div></td>
                                    </tr>
                                    <tr class="gappvcdivbg"></tr>
                                </table>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>

            </section >
        </>
    )
}

export default Advocacyanalytics
