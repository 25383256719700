import React, { useEffect, useState } from 'react'
import DocumentRepository from "../../Images/Icons/PNG/DocumentRepository.png";
import certificate from "../../Images/certificate.png"
import Recentdoc from './Recentdoc';
import { Navigate } from 'react-big-calendar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthCtx } from '../../context/AuthCtx';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';
import moment from 'moment';


const Articlepage = () => {
    const { setLoading } = useAuthCtx();
    const navigate = useNavigate();
    const location = useLocation();
    const [catData, setCatData] = useState([]);
    // const [singleData, setSingleData] = useState({});
    // console.log("fghercv", catData)

    //for category wise department data
    const categorywiseDepartmentData = async () => {
        const data = {
            docCatId: location?.state
        };
        setLoading(true);
        const response = await ApiHelperFunction({
            urlPath: `/get-document-category-wise`,
            method: "POST",
            data,
        });

        if (response.status === 200) {
            // toast.success(response?.data?.message);
            setCatData(response?.data?.data)
        } else {
            // toast.error(response?.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        categorywiseDepartmentData();
    }, [])


    const handlearticlefullClick = (item) => {
        // const data = {
        //     item: item
        // }
        // console.log("uiopgh",item)
        // setSingleData(item)
        navigate(`/fullarticleview/${item?._id}`, { state: item })
    }
    

    return (
        <>
            <section className='articlepagemain'>
                <div className='employeepagecontainer'>
                    <div className='articlepagemainflx'>
                        <div className='articlepagemainleft'>
                            <div className='articlepagemainbgmain'>
                                <div className="top">
                                    <div className='documntrepotxtimgflx'>
                                        <div className='documentRepositoryimgicon'>
                                            <img src={DocumentRepository} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='prchsetsxppp'>Document Repository &gt;&gt; Purchase
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='buttom'>
                                    <div className='dcmntdwnldflx'>
                                        {catData?.map((item, index) => {
                                            return (
                                                <div className='documentRepositorycategorybg' key={index}>
                                                    <div className=''>
                                                        <div className='certificateimgdivbg' onClick={() => handlearticlefullClick(item)}>
                                                            <img src={item?.docImage} alt="..." />
                                                            <div className='itemcategppp'>
                                                                <p className='itemcategppptext'>{item?.title}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='topicviweflx'>
                                                        <div className=''>
                                                            <p className='datetxtpp'>{moment(item?.createdOn)?.format('DD-MM-YYYY')}
                                                            </p>
                                                        </div>
                                                        <div className=''>
                                                            <p className='datetxtpp'>{item?.totalUserViews} Views</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='articlepagemainright'>
                            <div className=''>
                                <Recentdoc />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Articlepage
