import React, { useEffect, useState } from 'react'
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import { toast } from "react-hot-toast";
// import Post from "../../Images/Icons/PNG/Post.png"

const SurveySuggestionmodal = ({ closeModal }) => {
    const initial = {
        suggestion: "",
        tag: "",
        managerId: "",
        hrId: "",
        commiteeId: "",
        priority: ""
    }
    const [suggestionBox, setSuggestionBox] = useState(initial);
    const [managerData, setManagerData] = useState([]);
    const [hrData, setHrData] = useState([]);


    //onChange functionality
    const handleChange = (e) => {
        const { name, value } = e.target;
        setSuggestionBox(prev => ({ ...prev, [name]: value }))

        if (name === "managerId") {
            setSuggestionBox((prev) => ({
                ...prev,
                hrId: "",
                commiteeId: "",
            }))

        }
        if (name === "hrId") {
            setSuggestionBox((prev) => ({
                ...prev, managerId: "",
                commiteeId: ""
            }))

        }
    }


    //for fetching all hr data
    const getHrData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-hr`,
            method: "GET",
        });
        if (response && response.status) {
            setHrData(response?.data?.data);
        }
    };

    //for fetching all manager
    const getManagerData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-manager`,
            method: "GET",
        });
        if (response && response.status) {
            setManagerData(response?.data?.data);
        }
    };


    //onChange functionality for radio button
    const handleRadioChange = (e) => {
        const { value } = e.target;
        setSuggestionBox((prev) => ({
            ...prev,
            tag: value,
            managerId: "",
            hrId: "",
            commiteeId: ""
        }));
    };


    //Submit functionality for suggestion
    const handleSuggestion = async () => {
        if (suggestionBox?.suggestion == "") {
            toast?.error("All field is required")
            return false;
        }
        let data = {
            suggestion: suggestionBox?.suggestion,
            tag: suggestionBox?.tag,
            tagId: suggestionBox.managerId ? suggestionBox.managerId : suggestionBox.hrId ? suggestionBox?.hrId : suggestionBox.commiteeId ? suggestionBox?.commiteeId : "",
            priority: suggestionBox?.priority
        };
        // console.log("suggestion", data)
        // return false;
        let response = await ApiHelperFunction({
            urlPath: `/give-suggestion`,
            method: "POST",
            data,
        });
        if (response && response.status) {
            toast.success(response?.data?.message);
            setSuggestionBox(initial)
        } else {
            toast.error(response?.response?.data?.message);
        }
    };

    useEffect(() => {
        getManagerData();
        getHrData();
    }, []);
    return (
        <>
            <div className='mainsurveysuggestion'>
                <div className='surveysuggestioncontent'>
                    <div className='crsiconopentxdivflx'>
                        <div className='crsicon' onClick={() => closeModal()}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className='opentxtdivmainflxncm'>
                            <h4 className='opentxtdiv'>
                                Open Door
                            </h4>
                            <p className='sgstyytxt'>
                                (Suggestion Box)
                            </p>
                        </div>
                    </div>
                    <div className='Specifydivmaindibscvnbp'>


                        <div className='Specifydivmain'>
                            <p className='Specifydivmaintxtgfhpp'>Please Specify Your Suggestion</p>
                            <textarea rows="2" cols="30" placeholder='Write Maximum 200 Words'
                                name="suggestion"
                                value={suggestionBox.suggestion}
                                onChange={(e) => handleChange(e)}></textarea>
                        </div>
                        <div className="prthngdiv">
                            <p className="prttxtdiv">Priority</p>
                            <div className="pstyflx">
                                <button className={suggestionBox?.priority === "high" ?"hlbutton" : "hgbtn"} onClick={() => setSuggestionBox(prev => ({ ...prev, priority: "high" }))}>High</button>
                                <button className={suggestionBox?.priority === "medium" ?"hlbutton" : "hgbtn"}onClick={() => setSuggestionBox(prev => ({ ...prev, priority: "medium" }))}>Medium</button>
                                <button className={suggestionBox?.priority === "low" ?"hlbutton" : "hgbtn"} onClick={() => setSuggestionBox(prev => ({ ...prev, priority: "low" }))}>Low</button>
                            </div>
                        </div>
                        <div className="adresdiv">
                            <p className="prttxtdiv">Address to</p>
                            <>

                                <div className='radiobtntxtpp'>
                                    <input
                                        type="radio"
                                        name="tag"
                                        value="Manager"
                                        checked={suggestionBox.tag === "Manager"}
                                        onChange={handleRadioChange}
                                    />
                                    <label>Manager</label>
                                </div>

                                <div className='radiobtntxtpp'>
                                    <input
                                        type="radio"
                                        name="tag"
                                        value="Hr"
                                        checked={suggestionBox.tag === "Hr"}
                                        onChange={handleRadioChange}

                                    />
                                    <label>HR</label>
                                </div>

                                <div className='radiobtntxtpp'>
                                    <input
                                        type="radio"
                                        name="tag"
                                        value="SuggestionCommite"
                                        checked={suggestionBox.tag === "SuggestionCommite"}
                                        onChange={handleRadioChange}
                                    />
                                    <label>Suggestion Commitee</label>
                                </div>

                            </>


                            <div className="pstyflx">
                                {
                                    suggestionBox?.tag === "Manager"
                                    &&
                                    <div className="emplListDiv">
                                        <div>
                                            <select
                                                class="empListSelect"
                                                aria-label="Select Manager name"
                                                name="managerId"
                                                value={suggestionBox?.managerId}
                                                onChange={(e) => handleChange(e)}
                                            >
                                                <option value={""} disabled>
                                                    Manager
                                                </option>
                                                {managerData?.map((item, i) => (
                                                    <option value={item?._id}>
                                                        {item?.firstName} {item?.lastName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                }

                                {
                                    suggestionBox?.tag === "Hr"
                                    &&
                                    <div className="emplListDiv">
                                        <div>
                                            <select
                                                class="empListSelect"
                                                aria-label="Select HR name"
                                                name="hrId"
                                                value={suggestionBox?.hrId}
                                                onChange={(e) => handleChange(e)}
                                            >
                                                <option value={""} disabled>
                                                    HR
                                                </option>
                                                {hrData?.map((item, i) => (
                                                    <option value={item?._id}>
                                                        {item?.firstName} {item?.lastName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                }

                                {
                                    suggestionBox?.tag === "SuggestionCommite" && <button className="hgbtnnmgnsuggenstdiv"
                                        name="commiteeId"
                                        value={suggestionBox?.commiteeId}
                                        onClick={() => {
                                            setSuggestionBox((prev) => ({
                                                ...prev,
                                                commiteeId: "",
                                                hrId: "",
                                                managerId: "",

                                            }))
                                        }}
                                    >
                                        Suggestion Committee
                                    </button>
                                }
                            </div>
                        </div>

                        <div className='grvncdivmain' onClick={handleSuggestion}>
                            Send
                        </div>

                    </div>
                    {/* <div className="diijfkxkldiv">
                        <div className="postbgimg" onClick={handleSuggestion}>
                            <img src={Post} alt="..." />
                        </div>
                    </div> */}


                </div>
            </div>
        </>
    )
}

export default SurveySuggestionmodal
