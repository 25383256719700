import React, { useEffect, useState } from 'react'
import search from "../../Images/headericon/search.png"
import { ApiHelperFunction } from '../../services/api/apiHelpers';


const Addprojectrightcomp = () => {
const [ProjectName,setProjectName]=useState("");
const [ProjectData,setProjectData]=useState([]);
    
    const searchProject = async () => {
        // setSearching(true);
        let response = await ApiHelperFunction({
          urlPath: "/search-my-project", method: 'POST', data: {
            searchName: ProjectName
          }
        })
        if (response?.status) {
          setProjectData(response?.data?.data?.reverse());
        //   if (employee?.length === 0) {
        //     setMessage('No employee found!');
        //   }
        } 
        // else {
        //   toast?.error('Something went wrong')
        // }
        // setSearching(false);
      };

      useEffect(() => {
        let debounce = setTimeout(() => {
          searchProject();
        }, 400)
    
        return (() => clearTimeout(debounce))
      }, [ProjectName])
    return (
        <>
            <div className='projectaddrihtdivbg'>
                <div className=''>
                    <p className='addattendencetxtpp'>Search Project</p>
                </div>
                <div className='inptsrcchdivmain'>
                    <input 
                    type='text' 
                    placeholder='Select Project' 
                    value={ProjectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    />
                    <div className='srchimhgdiv'>
                        <img src={search} alt="..." />
                    </div>
                </div>
                <div className=''>
                    <ul className='attendencedivrightul'>
                        {
                            ProjectData?.map((item,i)=>{
                                return (
                                    <li className='attendencedivrightul' key={i}>{item?.nameOfInitaitive}</li>
                                )
                            })
                        }

                    </ul>
                </div>
                {/* <div className='sbmtdivbtnaslmain'>
                    <button className='sbmtdivbtnasl'>Submit</button>
                </div> */}
            </div>
        </>
    )
}

export default Addprojectrightcomp
