import React, { useState } from 'react'
import Edit from "../../Images/headericon/Edit.png"
const Earningaddition = () => {

    // const [activebtm, setActivebtm] = useState(Array(10).fill(true));
    // const activebtnclick = (index) => {
    //     const newActiveStates = [...activebtm];
    //     newActiveStates[index] = !newActiveStates[index];
    //     setActivebtm(newActiveStates);

    // }
    const earningdtls = [
        {
            name: "Basic",
            calculation: "Fixed; 50% of CTC",
            code: "Basic"
        },
        {
            name: "House Rent Allowance",
            calculation: "Fixed; 25% of CTC",
            code: "HRA"
        },
        {
            name: "Conveyance Allowance",
            calculation: "Fixed; 10% of CTC",
            code: "CA"
        },
        {
            name: "Fixed Allowance",
            calculation: "Fixed; Flat Amount",
            code: "FA"
        },
        {
            name: "Bonus",
            calculation: "Variable; Flat Amount",
            code: "Bonus"
        },
        {
            name: "Commission",
            calculation: "Variable; Flat Amount",
            code: "Commission"
        },
        {
            name: "Children Education Allowance",
            calculation: "Fixed; Flat amount of 700",
            code: "CEA"
        },
        {
            name: "Transport Allowance",
            calculation: "Fixed; 10% of Basic",
            code: "TA"
        },
        {
            name: "Travelling Allowance",
            calculation: "Fixed; Flat Amount",
            code: "TA"
        },
        {
            name: "Leave Allowance",
            calculation: "Fixed; Flat Amount",
            code: "LA"
        },

    ]

    return (
        <>
            <div className=''>
                <table className='thanledytteslartcuiketanle'>
                    <thead className='enarrsadditinntablethead'>
                        <tr className='enarrsadditinntabletheadtr'>
                            <th className='enarrsadditinntabletheadthone'>
                                <p className='enarrsadditinntabletheadthpp'> NAME </p>
                            </th>

                            <th className='enarrsadditinntabletheadththree'>
                                <p className='enarrsadditinntabletheadthppnew'> Code</p>
                            </th>

                            <th className='enarrsadditinntabletheadthtwo'>
                                <p className='enarrsadditinntabletheadthpp'> CALCULATION TYPE</p>
                            </th>

                            <th className='enarrsadditinntabletheadthfour'>
                                <p className='enarrsadditinntabletheadthrightppnew'> EDIT</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {earningdtls.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th className='enarrsadditinntabletheadthone'>
                                        <p className='enarrsaddwritetetxtetheadthppblue'> {item.name} </p>
                                    </th>
                                    <th className='enarrsadditinntabletheadthtwo'>
                                        <p className='enarrsaddwritetetxtetheadthpp'> {item.code}</p>
                                    </th>
                                    <th className='enarrsadditinntabletheadththree'>

                                        <p className='enarrsaddwritetetxtetheadthpp'> {item.calculation}</p>
                                        {/* <div onClick={() => activebtnclick(index)} className='enarrsaddwritetetxtetheadivinln'>
                                            {activebtm[index] ? (
                                                <p className='enarrsaddwritetetxtetheadthppnewttxp'>Active</p>
                                            ) : (
                                                <p className='enarrsaddwritetetxtetheadthppnewttxp'>Inactive</p>
                                            )}
                                        </div> */}
                                    </th>
                                    <th className='enarrsadditinntabletheadthfour'>
                                        <div className='eitteebeyhummomg'>
                                            <img src={Edit} alt='...' />
                                        </div>
                                    </th>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Earningaddition
