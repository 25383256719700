import React from "react";
import { Oval } from "react-loader-spinner";

const Loader = () => {
  return (
    // <Dna
    //   visible={true}
    //   height="80"
    //   width="80"
    //   ariaLabel="dna-loading"
    //   wrapperStyle={{}}
    //   wrapperClass="dna-wrapper"
    // />
    <Oval
      visible={true}
      height="80"
      width="80"
      color="#4fa94d"
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass=""
      />
    
  );
};

export default Loader;
