import React from 'react'
import Ongoing from './Ongoing'
import StandardOperatingProsedure from "./StandardOperatingProsedure";
import Backarrow from "../../Images/Icons/PNG/Backarrow.png";
import video from "../../Images/Icons/PNG/video.png";
import Singleeducateongoing from './Singleeducateongoing';
import Statuscard from '../../Component/Statuscard';
import { useNavigate } from 'react-router-dom';

const Singleongoing = () => {
    const navigate=useNavigate();
    return (
        <>
            <section className='singleongoingmain'>
                <div className='employeepagecontainer'>
                    <div className='educatemainflx'>
                        <div className='singlemandatoryleft'>
                            {/* <div className=''>
                            <EducateStatusCard />
                        </div> */}
                            <div className="satuscarddivpading">
                                <Statuscard />
                            </div>
                            <div className=''>
                                <Ongoing />
                            </div>
                            <div className=''>
                                <StandardOperatingProsedure />
                            </div>
                        </div>
                        <div className='singlemandatoryright'>
                            <div className='ongoingbgdiv'>
                                <div className="top">
                                    <div className="head bulleBoardHead">
                                        {/* <figure className="bulleBoardFig" >
                                            <img
                                                src={video}
                                                alt="..."

                                            />
                                        </figure> */}
                                        <h4>On Going</h4>
                                    </div>
                                    <div className="add_seeallflx" onClick={()=>navigate(-1)}>
                                        <div className="tyEvMainDiv">
                                            <figure className="bulleBoardFig" >
                                                <img src={Backarrow} alt="..." />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className='bottom '>
                                    {/* <EducateOngoing /> */}
                                    <Singleeducateongoing />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Singleongoing
