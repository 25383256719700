import React from "react";

const Reward = ({ closemodal, userData }) => {
    console.log(userData, "userDataqwe")
    return (
        <>
            <section className="rewarddivmain">
                <div className="rewarddivcontent">
                    <div className="btndivcl" onClick={() => closemodal()}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    <div className="rwddiovmain">
                        <div className="rwddiov">
                            <p className="rwdtxtpp">Reward Breaking Points</p>
                        </div>
                    </div>
                    <div className="rewardtm">
                        <div className="">
                            <p className="txtrwddibpp">Coming on hallfame : {userData?.comingonhallfame}</p>
                        </div>
                        <div className="">
                            <p className="txtrwddibpp"> Comment : {userData?.comment}</p>
                        </div>
                        <div className="">
                            <p className="txtrwddibpp"> Complete Onboarding : {userData?.completeOnboarding}</p>
                        </div>
                        <div className="">
                            <p className="txtrwddibpp"> Joining Affinity Groups : {userData?.joiningAffinityGroups}</p>
                        </div>
                        <div className="">
                            <p className="txtrwddibpp"> Post : {userData?.post}</p>
                        </div>
                        <div className="">
                            <p className="txtrwddibpp"> Publishing Advocacy : {userData?.publishingAdvocacy}</p>
                        </div>
                        <div className="">
                            <p className="txtrwddibpp"> React : {userData?.react}</p>
                        </div>
                        <div className="">
                            <p className="txtrwddibpp"> Survey Completion  : {userData?.surveyCompletion}</p>
                        </div>

                        <div className="">
                            <p className="txtrwddibpp"> Claim Points  : {userData?.claimPoint}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Reward