import React, { useEffect, useState } from 'react';
import download from "../../Images/Icons/PNG/download.png"
// import ElitecertificateTraining from "../../src/Images/ElitecertificateTraining.png";
// import employeeexperiencehub from "../../src/Images/employeeexperiencehub.png";
// import download from "../../src/Images/download.jpg"
import { useAuthCtx } from '../../context/AuthCtx';


const Certificate = () => {
    const { userData, getUserDetails } = useAuthCtx();

    useEffect(() => {
        getUserDetails()
    }, [userData])

    const downloadCertificate = (certificateUrl) => {
        const link = document.createElement('a');
        link.href = certificateUrl;
        link.download = 'certificate.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            {/* <section
                className='certificate' style={{ width: "100%", maxWidth: "80%", margin: "auto" }}
            > */}
            {/* <div className='' style={{
                    backgroundImage: `url(${ElitecertificateTraining})`, backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: "100%",
                    height: "100vh",
                }}>
                    <div className='' style={{ padding: "140px 140px" }}>
                        <div className=''>
                            <h5 className='' style={{ textAlign: "center", color: "#3054a4", fontSize: "60px", fontWeight: "300", letterSpacing: "1px" }}>CERTIFICATE</h5>
                            <p className='' style={{ textAlign: "center", color: "#3054a4", fontSize: "30px", fontWeight: "500", letterSpacing: "1px", fontStyle: "italic", fontFamily: "Dancing Script" }}>Of Achievment</p>
                        </div>
                        <div className='' style={{ padding: "20px 0px" }}>
                            <p className='' style={{ textAlign: "center", color: "rgb(0 0 0 / 85%)", fontSize: "18px", fontWeight: "600", letterSpacing: "1px" }}>This is to Certify that</p>
                            <div className='' style={{ borderBottom: "1px dotted #000", width: "100%", maxWidth: "60%", margin: "auto" }}>
                                <p style={{ textAlign: "center", color: "#ff0000b5", fontSize: "50px", fontWeight: "500", lineHeight: "52px", paddingTop: "10px", }}>SUJOY NAG</p>
                            </div>
                            <div className=''>
                                <p style={{ textAlign: "center", color: "rgb(0 0 0 / 87%)", fontSize: "18px", fontWeight: "600", letterSpacing: "1px", paddingTop: "8px" }}>Successfully Completed the Online Course</p>
                            </div>
                            <div className='' style={{ borderBottom: "1px dotted #000", width: "100%", maxWidth: "40%", margin: "auto" }}>
                                <p style={{ textAlign: "center", color: "#ff0000b5", fontSize: "18px", fontWeight: "500", lineHeight: "22px", paddingTop: "10px", marginBottom: "2px" }}>Thinkful digital marketing bootcamp</p>
                            </div>

                            <div className=''>
                                <p className='' style={{ fontSize: "18px", color: "rgb(0 0 0 / 91 %)", fontWeight: "600", lineHeight: "20px", textAlign: "center", paddingTop: "5px" }}>on</p>
                                <p style={{ fontSize: "24px", color: "rgb(0 0 0 / 91%)", fontWeight: "600", lineHeight: "28px", textAlign: "center", paddingTop: "2px" }}>Elite Employee</p>
                                <p style={{ fontSize: "24px", color: "rgb(0 0 0 / 91%)", fontWeight: "600", lineHeight: "28px", textAlign: "center", paddingTop: "2px" }}>Experience Hub</p>
                            </div>
                            <div className='' style={{ width: "100%", maxWidth: "60%", margin: "auto", paddingTop: "30px" }}>

                                <div style={{ float: "left", paddingTop: "20px" }}>
                                    <div style={{ color: "#000", fontSize: "16px", fontWeight: "600", lineHeight: "19px", width: "100%", maxWidth: "100%", textAlign: "center", paddingBottom: "5px" }}>Certificate No.</div>
                                    <div style={{ color: "#000", fontSize: "16px", fontWeight: "600", lineHeight: "19px", width: "100%", maxWidth: "100%" }}>eeeh-01-02.03.2023</div>
                                </div>
                                <div style={{ width: "100%", maxWidth: "28%", margin: "auto", float: "left" }}>
                                    <div className='' style={{ width: "100%", maxWidth: "55%", minWidth: "55%", height: "70px", margin: "auto" }}>
                                        <img src={download} alt=".." style={{ width: "100%", height: "100%" }} />
                                    </div>
                                </div>
                                <div style={{ width: "100%", maxWidth: "28%", margin: "auto", float: "left" }}>
                                    <div className='' style={{ width: "100%", maxWidth: "55%", minWidth: "55%", height: "70px" }}>
                                        <img src={employeeexperiencehub} alt=".." style={{ width: "100%", height: "100%" }} />
                                    </div>
                                </div>
                                <div className='' style={{ float: "left", paddingTop: "20px" }}>
                                    <div style={{ color: "#000", fontSize: "16px", fontWeight: "600", lineHeight: "19px", width: "100%", maxWidth: "100%", textAlign: "center", paddingBottom: "5px" }}>Signature</div>
                                    <div style={{ color: "#000", fontSize: "16px", fontWeight: "600", lineHeight: "19px", width: "100%", maxWidth: "100%" }}>Anirban Roy</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div > */}


            {
                userData?.certificateData?.map((item) => {
                    return (
                        <section className='cerificatemaonsectioneduct'>
                            <div className='downloaddivimgainknamdivflx'>
                                <p className='traimsignbvjshnamamsnbtxtuicerdr'
                                    style={{ marginRight: "30px" }}
                                >
                                    {item?.trainingId?.title}
                                </p>
                                <div className='downloaddivimgainknam' style={{ cursor: "pointer" }}>
                                    <img src={download} alt="..." onClick={() => downloadCertificate(item?.certificateUrl)} />
                                </div>

                              
                            </div>



                            <div className='certificateimagebg'
                                style=

                                {{ backgroundImage: `url(${"https://companyadmin.fractalselite.com/images/EducationBackground.png"})` }}

                            >
                                <div className="cerfytvatedallin" >
                                    <div className="cerfytvatedalmainkft">
                                        <div classname="">
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <div
                                                                className="comaopnayslogoedu"
                                                     
                                                                style={{ backgroundImage: `url(${"https://companyadmin.fractalselite.com/images/logcompany.png"})` }}
                                                            ></div>
                                                            <h4 className='certificatenametxtpp'
                                                                style={{

                                                                }}
                                                            >
                                                                Certificate
                                                            </h4>
                                                            <p className='certificatenametxtppnich'
                                                                style={{

                                                                }}
                                                            >
                                                                OF Education
                                                            </p>
                                                        </th>
                                                        <th>
                                                            <div
                                                                className="CertificateGalaSealimg"
                                                                // style={{
                                                                //     backgroundImage:
                                                                //         "url(https://companyadmin.fractalselite.com/images/CertificateGalaSeal.png)",

                                                                // }}
                                                                style={{ backgroundImage: `url(${"https://companyadmin.fractalselite.com/images/CertificateGalaSeal.png"})` }}
                                                            ></div>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p className='delightedtxtt'
                                            style={{ color: "black" }}
                                        >
                                            We are delighted to present
                                        </p>
                                        <h4 className='traineeNametxtpp'

                                        >
                                            {item?.userId?.firstName + " " + item?.userId?.lastName}
                                        </h4>
                                        <p className='educationttxppcrtt'
                                            style={{ color: "black" }}
                                        >
                                            with this certificate of education for
                                        </p>
                                        <p className='completingtxtppjocertxded'

                                        >
                                            Successfully completing the learning course
                                        </p>
                                        <p className='traimsignbvjshnamamsnbtxtuicerdr'

                                        >
                                            {item?.trainingId?.title}
                                        </p>
                                        <div className="certficateeduftrui">
                                            <table className='certficateeduftruitbls' >
                                                <tbody>
                                                    <tr className="certficateeduftruitr" >
                                                        <th style={{ textAlign: "left" }}>
                                                            <div className="elitelogoeducrtdfdivimg"
                                                                style={{ backgroundImage: `url(${"https://companyadmin.fractalselite.com/images/elitelogo.png"})` }}
                                                            ></div>
                                                        </th>
                                                        <th style={{ textAlign: "left" }}>
                                                            <div>
                                                                <p className="Certificatenotxttpopp" style={{ color: "black" }}>
                                                                    Unique Certificate No.
                                                                </p>
                                                                <p className='certificateNoppplctrxrtp' style={{ color: "black" }}>
                                                                    {item?.certificateNo}
                                                                </p>
                                                            </div>
                                                        </th>
                                                        <th style={{ textAlign: "left" }}>
                                                            <div classname="">
                                                                <p className="Certificatenotxttpopp"
                                                                    style={{ color: "black" }}
                                                                >
                                                                    Date of Issue.
                                                                </p>
                                                                <p className='certificateNoppplctrxrtp'
                                                                    style={{ color: "black" }}
                                                                >
                                                                    {item?.certificateIssueDate}
                                                                </p>
                                                            </div>
                                                        </th>
                                                        <th style={{ textAlign: "left" }}>
                                                            <div classname="">
                                                                <p className="Certificatenotxttpopp"
                                                                    style={{ color: "black" }}
                                                                >
                                                                    Signature
                                                                </p>
                                                                <p className='certificateNoppplctrxrtp'
                                                                    style={{ color: "black", fontSize: "25px" }}
                                                                >
                                                                    {item?.trainingId?.trainerName}
                                                                </p>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                })
            }


            {/* {
                    userData?.certificateData?.map((item) => {
                        return (
                            <iframe src={item?.certificateUrl} style={{height:"700px",width:"700px"}}></iframe>
                        )
                    })
                } */}
            {/* </section > */}
        </>
    )
}

export default Certificate
