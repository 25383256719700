import React, { useState } from 'react'
import empUserImg from "../Images/Icons/PNG/Profile (2).png"
import SilverIcon from "../Images/Icons/PNG/Reward - Silver.png"
import { addReaction, getAllPosts, toggleDescription } from "../redux/slices/postSlice";
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Fancybox from "./FAncyBox";
import ReactPlayer from "react-player";
import Edit from "../Images/headericon/Edit.png";
import Delete from "../Images/headericon/Delete.png";
import EditRepostModal from './Modal/EditRepostModal';
import { ApiHelperFunction } from '../services/api/apiHelpers';
import toast from 'react-hot-toast';
import { useAuthCtx } from '../context/AuthCtx';
import moment from 'moment';


const SaraTylorRepost = ({ imagesArray, AnotherProfile, createdOn, showDescription, postId, description }) => {
    const { userData } = useAuthCtx();
    const dispatch = useDispatch();
    // console.log("opvbdf", imagesArray)
    const [optionShow, setoptionShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalA, setModalA] = useState(false);
    const [modalId, setModalId] = useState("");
    const [modalB, setModalB] = useState(false);
    const [modalC, setModalC] = useState(false);
    const [repostData, setRepostData] = useState({})
    // const [changeShow, setChangeShow] = useState(false);
    const repostId = imagesArray?._id;

    const handleDelete = async () => {
        const res = await ApiHelperFunction({
            urlPath: `/delete-repost/${repostId}`,
            method: "POST",
        });
        if (res?.status) {
            toast.success("Repost is deleted successfully");
            dispatch(getAllPosts());
        } else {
            // toast.error(res?.message || "Something went wrong");
            // console.log("ERROR CREATING USER3", res);
        }
    }

    {
        // console.log("showModal", imagesArray)
    }

    return (
        <>
            <section
                id="sara_tylor"
                onClick={() => setShowModal(false)}
            >
                <div >
                    <div className="top_area" style={{ position: "relative" }}>
                        <div className="left">
                            <div className="profile_img">
                                {imagesArray?.repostedBy?.[0]?.image ? (
                                    <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                        src={imagesArray?.repostedBy?.[0]?.image}
                                        className="img-fluid"
                                        alt="profile"
                                    />
                                ) : (
                                    <img style={{ cursor: "pointer" }} onClick={AnotherProfile}
                                        src={empUserImg}
                                        className="img-fluid"
                                        alt="profile"
                                    />
                                )}
                            </div>

                            <div
                                className="details"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="name">
                                    <h2 style={{ cursor: "pointer" }} onClick={AnotherProfile}>{imagesArray?.repostedBy?.[0]?.firstName} {imagesArray?.repostedBy?.[0]?.lastName}</h2>
                                    <span>
                                        {/* {
                                            imagesArray?.userType !== "Owner" ? (<>  <img src={SilverIcon} className="img-fluid" alt="icon" />
                                                {imagesArray?.repostedBy?.[0]?.rewardCard?.[0]?.cardImage} Club</>) : (<></>)
                                        } */}
                                        {
                                            imagesArray?.userType !== "Owner"
                                                ?
                                                (<>
                                                    <img src={imagesArray?.repostedBy?.[0]?.rewardCard?.[0]?.cardImage} className="img-fluid" alt="icon" />
                                                    {/* {imagesArray?.postId?.[0]?.userData?.[0]?.rewardCard?.[0]?.cardName} Club */}

                                                </>)
                                                :
                                                (<></>)
                                        }

                                    </span>
                                </div>
                                <div className="text">
                                    <p>
                                        <span>{imagesArray?.repostedBy?.[0]?.userType + " "}, </span>
                                        <span>Posted on {moment(imagesArray?.[0]?.createdOn).format('YYYY - MM - DD')}</span>
                                    </p>
                                </div>
                            </div>
                            <div>
                                {userData?._id === imagesArray?.repostedBy?.[0]?._id && (
                                    <div
                                        className="dot_btn"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowModal(!showModal);
                                            setModalId(repostId);
                                        }}

                                    >
                                        <a to="/" className="btn">
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </a>
                                        {showModal && (
                                            <div
                                                style={{
                                                    position: "relative",
                                                    // overflow: "hidden",
                                                    backgroundColor: "#fff",
                                                    padding: "3px",
                                                    border: "none",
                                                    // top: "123px",
                                                    padding: "3px 8px",
                                                    marginLeft: "-27px",
                                                    borderRadius: "4px",
                                                    cursor: "pointer",
                                                    boxShadow: "0 0 0.1rem 0",
                                                    display: "flex"
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            >


                                                <div className="editimagemain" onClick={(e) => {
                                                    e.stopPropagation();
                                                    setModalA(true);
                                                    setModalB(true);
                                                    setModalC(true);
                                                    setRepostData(imagesArray);
                                                }}>
                                                    <img src={Edit} alt="..." />
                                                </div>
                                                <div className="editimagemain" onClick={handleDelete}>
                                                    <img src={Delete} alt="..." />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                            </div>
                        </div>

                    </div>
                    <div className="">
                        <div className="content"
                            style={{ cursor: "pointer" }
                            }
                        >

                            {imagesArray?.text && (
                                <>
                                    {showDescription || imagesArray?.text?.length <= 850 ? (
                                        <p>{imagesArray?.text}</p>
                                    ) : (
                                        <>{imagesArray?.text.slice(0, 850) + '...'}</>
                                    )}
                                    {imagesArray?.text?.length > 850 && (
                                        <Link onClick={() => dispatch(toggleDescription(postId))}>
                                            {showDescription ? '...Show Less' : 'Show More...'}
                                        </Link>
                                    )}
                                </>
                            )}

                        </div>

                    </div>
                </div>

            </section >
            {
                modalA && (<EditRepostModal
                    closemodal={() => setModalA(false)}
                    repostId={repostId}
                    repostData={repostData}
                    imagesArray={imagesArray}
                    showDescription={showDescription}
                    postId={postId}
                />)
            }
        </>
    )
}

export default SaraTylorRepost