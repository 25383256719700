import React, { useEffect, useState } from 'react'
// import trainingmain1 from "../../Images/trainingmain1.png";
import trainingmain from "../../Images/trainingmain.png"
import star from "../../Images/Icons/PNG/star.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward points.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import { getOnboardingNontechnicalTraining, getOnboardingPhysicalTraining } from '../../redux/slices/jobRecruitmentSlice';
import MicroCousremodal from '../../Component/Modal/MicroCousemodal';

const SkillPhysicaltraining = () => {
    const microTraining = useSelector((state) => state?.jobRecruitmentSlice?.phyTraining)
    const [singleMandatory, setSingleMandatory] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOnboardingPhysicalTraining())
    }, [])

    const [videotraing, setVideotraing] = useState(false);
    const handlevideoClick = (item) => {
        setSingleMandatory(item)
        setVideotraing(!videotraing)
    }
    var settings2 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <>
            <Slider {...settings2}>
                {
                    microTraining?.map((item, i) => {
                        return (
                            <div className='mandatorydivbg' onClick={() => handlevideoClick(item)}>
                                <div className='traingmaindivmain'>
                                    {/* <img src={trainingmain1} alt="..." /> */}

                                    {
                                        (item?.image === "images" || item?.image === "" || item?.image === "image") ? (<div className='traingmaindivmain'>
                                            <img src={trainingmain} alt="..." />
                                        </div>) : (<div className='traingmaindivmain'>
                                            <img src={item?.image} alt="..." />
                                        </div>)
                                    }

                                </div>
                                <div className=''>
                                    <p className='mandttxtpp'>
                                        {item?.title}
                                    </p>
                                    <p className='writertxtpp'>{item?.userName}</p>
                                </div>
                                <div className="imgtxtdivmaindddflx">
                                    {
                                        item?.avgRatings !== null
                                            ?
                                            <div className="imgtxtdivmainddd">
                                                <div className="strimgdiv">
                                                    <img
                                                        src={star}
                                                        alt="..."
                                                    />
                                                </div>
                                                <p className="tsgyutppp">{item?.avgRatings}</p>
                                            </div>
                                            :
                                            <div className="imgtxtdivmainddd">
                                                <div className="strimgdiv">
                                                    <img
                                                        src={star}
                                                        alt="..."
                                                    />
                                                </div>
                                                <p className="tsgyutppp">0</p>
                                            </div>
                                    }
                                    <div className="imgtxtdivmainddd">
                                        <div className="strimgdiv">
                                            <img
                                                src={clock}
                                                alt="..."
                                            />
                                        </div>
                                        <p className="tsgyutppp">
                                            {/* {item?.videos?.[0]?.videoDuration ? (<p className="tsgyutppp">{item?.videos?.[0]?.videoDuration?.hour ? (item?.videos?.[0]?.videoDuration?.hour) : ("00")} h {item?.videos?.[0]?.videoDuration?.min ? (item?.videos?.[0]?.videoDuration?.min) : ("00")}min</p>) : (<p className="tsgyutppp">{item?.placeTime?.[0]?.duration?.hour ? (item?.placeTime?.[0]?.duration?.hour) : ("00")} h {item?.placeTime?.[0]?.duration?.min ? (item?.placeTime?.[0]?.duration?.min) : ("00")}min</p>)} */}
                                            {item?.totalDurationInMinute} min
                                        </p>
                                    </div>
                                    <div className="imgtxtdivmainddd">
                                        <div className="strimgfrmt">
                                            <img
                                                src={reward}
                                                alt="..."
                                            />
                                        </div>
                                        <p className="tsgyutppp">{item.trainingReward}</p>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }


            </Slider>
            {videotraing && <MicroCousremodal closemodal={setVideotraing} singleMandatory={singleMandatory} />}
        </>
    )
}

export default SkillPhysicaltraining
