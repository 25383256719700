import React from 'react'
import View from "../../Images/headericon/View.png"
const Leavetempcomp = () => {
    const earningdtls = [
        {
            name: "Paternity Leave",
            calculation: "Fixed; 50% of CTC",
            gender: "Female",
            code: "PL"
        },
        {
            name: "Earned Leave",
            calculation: "Fixed; 25% of CTC",
            gender: "Male",
            code: "EL"
        },
        {
            name: "Compensatory Off",
            calculation: "Fixed; 10% of CTC",
            gender: "Male",
            code: "CO"
        },
        {
            name: "Sick Leave",
            calculation: "Fixed; 25% of CTC",
            gender: "Male",
            code: "SL"
        },
        {
            name: "Casual Leave",
            calculation: "Fixed; 10% of CTC",
            gender: "Male",
            code: "CL"
        },


    ]
    return (
        <div className=''>
            <table className='thanledytteslartcuiketanle'>
                <thead className='enarrsadditinntablethead'>
                    <tr className='enarrsadditinntabletheadtr'>
                        <th className='enarrsadditinntabletheadthone'>
                            <p className='enarrsadditinntabletheadthpp'> NAME </p>
                        </th>

                        <th className='enarrsadditinntabletheadththree'>
                            <p className='enarrsadditinntabletheadthppnew'> Code</p>
                        </th>

                        <th className='enarrsadditinntabletheadthtwo'>
                            <p className='enarrsadditinntabletheadthpp'> Gender </p>
                        </th>

                        <th className='enarrsadditinntabletheadthfour'>
                            <p className='enarrsadditinntabletheadthrightppnew'> EDIT</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {earningdtls.map((item, index) => {
                        return (
                            <tr key={index}>
                                <th className='enarrsadditinntabletheadthone'>
                                    <p className='enarrsaddwritetetxtetheadthppblue'> {item.name} </p>
                                </th>
                                <th className='enarrsadditinntabletheadthtwo'>
                                    <p className='enarrsaddwritetetxtetheadthpp'> {item.code}</p>
                                </th>
                                <th className='enarrsadditinntabletheadththree'>

                                    <p className='enarrsaddwritetetxtetheadthpp'> {item.gender}</p>

                                </th>
                                <th className='enarrsadditinntabletheadthfour'>
                                    <div className='eitteebeyhummomg'>
                                        <img src={View} alt='...' />
                                    </div>
                                </th>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Leavetempcomp
