import React, { useState } from 'react'
import Addicon from "../../Images/headericon/Addicon.png"
const SalaryTemplatecomptwo = () => {
    const [activebtm, setActivebtm] = useState(true);
    const [addnewtemplate, setaddnewtemplate] = useState(false);
    const [activebtnmaodlonno, setActivebtnmaodlonno] = useState(false);
    const [editeaninapge, setEditeaninapge] = useState(false)
    const activebtnclick = () => {
        setActivebtm(!activebtm);
    }

    const addnetempltebtnclick = () => {
        setaddnewtemplate(true)
    }

    const actibebtnmsdlclick = () => {
        setActivebtnmaodlonno(!activebtnmaodlonno)
    }

    const editeeraningclick = () => {
        setEditeaninapge(true)
    }
    return (
        <>

            {addnewtemplate ?
                <>
                    <section className='salarytemplacomdibvmain'>
                        <div className='slasrtytempdetaolsfdinbg'>
                            <div className='inpttstsalryttemplate'>
                                <input type='text' />
                                <label>Salary Templates</label>
                            </div>
                            <div className='inpttstsalryttemplate'>
                                <input type='text' />
                                <label>Details</label>
                            </div>
                        </div>

                        <div className='slasrtytempdetaolsfdinbg'>
                            <div className='inpttstsalryttemplate'>
                                <input type='text' />
                                <label>Annual CTC</label>
                            </div>


                            <div className=''>
                                <table className='salarttemplatespectdivtable'>
                                    <thead className='salarttemplatespectdivtablethead'>
                                        <tr className='salarttemplatespectdivtabletr'>
                                            <th className='salarttemplatespectdivtablethone'>
                                                <p className='templatenametetxpppcomptwotxtpp'>
                                                    Salary Component
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtablethtwo'>
                                                <p className='templatenametetxpppcomptwotxtpp' style={{paddingLeft:"27px"}}>
                                                    Type of Calculation
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtableththree'>
                                                <p className='templatenametetxpppcomptwotxtpp'>
                                                    Monthly
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtablethfour'>
                                                <p className='templatenametetxpppcomptwotxtpp'>
                                                    Annual
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtablethfive'>
                                                <p className='templatenametetxpppcomptwotxtpp' style={{ textAlign: "right" }}>
                                                    Status

                                                </p>

                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className='salarytemplatedivtablemaintbody'>
                                        <tr>
                                            <th colSpan="12">
                                                <p className='erningtxtpptwteytyw'>Earnings</p>
                                            </th>
                                        </tr>
                                        <tr className='salarytemplatedivtablemaintr'>
                                            <th className='salarttemplatespectdivtablethone ' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr'>
                                                    Basic
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtablethtwo slarttetxtpoisactstdivflx' style={{ verticalAlign: "top" }} onClick={editeeraningclick}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdrone'>
                                                    50
                                                </p>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdrtwo'>
                                                    % CTC
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtableththree' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr 'style={{textAlign:"right"}}>
                                                    10000
                                                </p>
                                            </th>

                                            <th className='salarttemplatespectdivtablethfour' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr' style={{textAlign:"right"}}>
                                                    120000
                                                </p>
                                            </th>

                                            <th className='salarttemplatespectdivtablethfive' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp habsttuul' style={{ textAlign: "right" }} onClick={actibebtnmsdlclick}>
                                                    Active
                                                    {activebtnmaodlonno && <div className='mayasactibeinactvmdl'>
                                                        <p className='mayasactibvvestxtpp'>
                                                            Mark as Active
                                                        </p>
                                                        <p className='mayasactibvvestxtpp'>
                                                            Mark as Inactive
                                                        </p>
                                                    </div>
                                                    }
                                                </p>
                                            </th>
                                        </tr>

                                        <tr className='salarytemplatedivtablemaintr'>
                                            <th className='salarttemplatespectdivtablethone ' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr'>
                                                    House Rent Allowance
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtablethtwo slarttetxtpoisactstdivflx' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdrone'>
                                                    50
                                                </p>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdrtwo'>
                                                    % Basic
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtableththree' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr' style={{textAlign:"right"}}>
                                                    10000
                                                </p>
                                            </th>

                                            <th className='salarttemplatespectdivtablethfour' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr' style={{textAlign:"right"}}>
                                                    120000
                                                </p>
                                            </th>

                                            <th className='salarttemplatespectdivtablethfive' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp habsttuul' style={{ textAlign: "right" }} onClick={actibebtnmsdlclick}>
                                                    Active
                                                    {/* {activebtnmaodlonno && <div className='mayasactibeinactvmdl'>
                                                        <p className='mayasactibvvestxtpp'>
                                                        Mark as Active
                                                        </p>
                                                        <p className='mayasactibvvestxtpp'>
                                                        Mark as Inactive
                                                        </p>
                                                    </div>
                                                    } */}
                                                </p>
                                            </th>
                                        </tr>

                                        <tr className='salarytemplatedivtablemaintr'>
                                            <th className='salarttemplatespectdivtablethone ' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr'>
                                                    Conveyance Allowance
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtablethtwo slarttetxtpoisactstdivflx' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdrone'>
                                                    800
                                                </p>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdrtwo'>
                                                    % Flat Amout
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtableththree' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr' style={{textAlign:"right"}}>
                                                    10000
                                                </p>
                                            </th>

                                            <th className='salarttemplatespectdivtablethfour' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr' style={{textAlign:"right"}}>
                                                    120000
                                                </p>
                                            </th>

                                            <th className='salarttemplatespectdivtablethfive' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp habsttuul' style={{ textAlign: "right" }} onClick={actibebtnmsdlclick}>
                                                    Active
                                                    {/* {activebtnmaodlonno && <div className='mayasactibeinactvmdl'>
                                                        <p className='mayasactibvvestxtpp'>
                                                        Mark  May as Active
                                                        </p>
                                                        <p className='mayasactibvvestxtpp'>
                                                        Mark as Inactive
                                                        </p>
                                                    </div>
                                                    } */}
                                                </p>
                                            </th>
                                        </tr>

                                        <tr className='salarytemplatedivtablemaintr'>
                                            <th className='salarttemplatespectdivtablethone ' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr'>
                                                    Fixed Medical Allowance
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtablethtwo slarttetxtpoisactstdivflx' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdrone'>
                                                    800
                                                </p>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdrtwo'>
                                                    % Flat Amout
                                                </p>
                                            </th>
                                            <th className='salarttemplatespectdivtableththree' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr' style={{textAlign:"right"}}>
                                                    10000
                                                </p>
                                            </th>

                                            <th className='salarttemplatespectdivtablethfour' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp templatenametetbodytbtrdr' style={{textAlign:"right"}}>
                                                    120000
                                                </p>
                                            </th>

                                            <th className='salarttemplatespectdivtablethfive' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp habsttuul' style={{ textAlign: "right" }} onClick={actibebtnmsdlclick}>
                                                    Active
                                                    {/* {activebtnmaodlonno && <div className='mayasactibeinactvmdl'>
                                                        <p className='mayasactibvvestxtpp'>
                                                        Mark as Active
                                                        </p>
                                                        <p className='mayasactibvvestxtpp'>
                                                        Mark as Inactive
                                                        </p>
                                                    </div>
                                                    } */}
                                                </p>
                                            </th>
                                        </tr>

                                    </tbody>

                                </table>
                            </div>

                        </div>


                    </section>

                    {editeaninapge ?
                        <>
                            <section className='salarytemplacomdibvmain'>
                                <div className=''>
                                    <p className='crtnetmpletxtppp'>
                                        Configer
                                    </p>
                                </div>
                                <div className='salarytemplatedivbgmain'>
                                    <table className='salarttemplatespectdivtable'>
                                        <thead className=''>
                                            <tr className='salarttemplatespectdivtabletr'>
                                                <th className='salarttemplatespectdivtabletrthone'>
                                                    <p className='templatenametetxpppcomptwotxtpp '> Name</p>
                                                </th>
                                                <th className='salarttemplatespectdivtabletrthtwo'>
                                                    <p className='templatenametetxpppcomptwotxtpp '> code</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='spakokakghbtdrtrssalarttemplatbody'>
                                            <tr>
                                                <th>
                                                    <p className='templatenametetbodytxtppotxtpp namedibbsftxtstinputbox'>
                                                        Basic
                                                    </p>
                                                </th>
                                                <th>
                                                    <p className='templatenametetbodytxtppotxtpp namedibbsftxtstinputbox' >
                                                        Basic
                                                    </p>
                                                </th>
                                            </tr>

                                        </tbody>
                                    </table>

                                    <div className='falataoiaipercnbasicdivpading'>
                                        <div className="Calculationdivbvdksffdlx">
                                            <p className='ceesllsttypammkittxp'>Select Type of Calculation</p>
                                            <div className='falataoiaipercnbasicdivflx'>
                                                <div className='falataoiaipercnbasic'>
                                                    <input type='radio' />
                                                    <p className='falataoiaipercnbasicttxpp'> Amount</p>
                                                </div>
                                                <div className='falataoiaipercnbasic'>
                                                    <input type='radio' />
                                                    <p className='falataoiaipercnbasicttxpp'> Percentage of Basic </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='ajhjsjamamijatpexttrdiv'>
                                            <p className='sgttstxttaminndtrxpp'>Set Amount</p>
                                            <div className='amaiontgtsuvvdsllinmpyut'>
                                                <span className='amaiontgtsuvvdsllinmpyutspan'>
                                                    ₹
                                                </span>
                                                <input type='text' />
                                            </div>
                                        </div>

                                        <div className='ajhjsjamamijatpexttrdiv'>
                                            <p className='sgttstxttaminndtrxpp'>Percentage</p>
                                            <div className='amaiontgtsuvvdsllinmpyut'>
                                                
                                                <input type='text' />
                                                <span className='amaiontgtsuvvdsllinmpyutspanrifht'>
                                                    %
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                        :
                        <>

                        </>
                    }
                </>

                :
                <>
                    <section className='salarytemplacomdibvmain'>
                        <div className='addnewbtnsalatyytemnpbtndivflx'>
                            <p className='crtnetmpletxtppp'>
                                Salary Templates
                            </p>

                            <button className='addennewtemplatebtn' onClick={addnetempltebtnclick}>
                                <span className='addnewtxtttmplbtn'>Add new Template</span>
                                <div className='addennewtemplatebtnaddimg'>
                                    <img src={Addicon} alt="..." />
                                </div>
                            </button>
                        </div>

                        <div className='salarytemplatedivbgmain'>
                            <div className=''>
                                <table className='salarytemplatedivtablemain'>
                                    <thead className='salarytemplatedivtablemainthead'>
                                        <tr className='salarytemplatedivtablemaintr'>
                                            <th className='salarytemplatedivtablemainthone'>
                                                <p className='templatenametetxpppcomptwotxtpp'>
                                                    Template Name
                                                </p>
                                            </th>
                                            <th className='salarytemplatedivtablemainthtwo'>
                                                <p className='templatenametetxpppcomptwotxtpp' style={{ textAlign: "center" }}>
                                                    Details
                                                </p>
                                            </th>
                                            <th className='salarytemplatedivtablemainththree'>
                                                <p className='templatenametetxpppcomptwotxtpp' style={{ textAlign: "right" }}>
                                                    Status
                                                </p>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className='salarytemplatedivtablemaintbody'>
                                        <tr className='salarytemplatedivtablemaintr'>
                                            <th className='salarytemplatedivtablemainthone' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp' style={{ color: "#1faee6de" }}>
                                                    Basic Salary
                                                </p>
                                            </th>
                                            <th className='salarytemplatedivtablemainthtwo' style={{ verticalAlign: "top" }}>
                                                <p className='templatenametetbodytxtppotxtpp' style={{ textAlign: "left" }}>
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque eligendi laudantium veritatis provident exercitationem, molestiae vitae accusantium placeat ex cumque!
                                                </p>
                                            </th>
                                            <th className='salarytemplatedivtablemainththree' style={{ verticalAlign: "top" }}>
                                                <div onClick={activebtnclick} className=''>
                                                    {activebtm ?
                                                        <>
                                                            <p className='enarrsaddwritetetxtetheadthppnewttxp' style={{ textAlign: "right" }}>Active</p>
                                                        </>

                                                        :

                                                        <>
                                                            <p className='enarrsaddwritetetxtetheadthppnewttxp' style={{ textAlign: "right" }}>Inactive</p>
                                                        </>

                                                    }
                                                </div>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </>
                
                }


        </>
    )
}

export default SalaryTemplatecomptwo
