import React, { useEffect, useState } from 'react'
import listofevent1 from "../../Images/listofevent1.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { getEventData } from '../../redux/slices/eventSlice';
import moment from 'moment';
import Modal from "react-modal";
import EventsBigCalenderModal from '../../SeprateModal/EventsBigCalenderModal';

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

const ListofEngagement = () => {
    const eventData = useSelector((state) => state?.eventSlice?.event);
    const [eventsForSelectedDate, setEventsForSelectedDate] = useState([]);
    const [eventsForSelectedDateMore, setEventsForSelectedDateMore] = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEventData());
    }, [])

    const upcomingEvents = eventData?.filter(event => new Date(event?.eventDate) > new Date());
    // console.log("eventData",eventData)


    const handleDateChange = (e) => {
        setIsOpen(true);
        setEventsForSelectedDate(e);
        // console.log("eventsForSelectedDate", e)
    };

    return (
        <>
            <div className='listofeventsdiv' style={{ marginTop: "20px" }}>
                <div className='luistofeventsiocntxtfxlx'>
                    <div className=''>
                        <p className='listtxytebntyuipp'>Upcoming Events</p>
                    </div>
                </div>
                <div className='listofeventbottom'>
                    {
                        upcomingEvents?.reverse()?.slice(0, 3)?.map((item, i) => {
                            return (
                                <>
                                    <div className='listofimagebottmflx' key={i} onClick={() => handleDateChange(item)}>
                                        <div className='listofeventdivmain'>
                                            {
                                                (item?.image == "" || item?.image == "image") ? (<img src={listofevent1} alt="..." />) : (<img src={item?.image} alt="..." />)
                                            }

                                        </div>
                                        <div className=''>
                                            <p className='evnttxtpplstof'>{item?.eventName}</p>
                                            <p className='evnttxtpplstofdate'>Date:{moment(item?.eventDate)?.format("YYYY-MM-DD")}</p>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }

                    {/* <div className='listofimagebottmflx'>
                        <div className='listofeventdivmain'>
                            <img src={listofevent1} alt="..." />
                        </div>
                        <div className=''>
                            <p className='evnttxtpplstof'>New Event</p>
                            <p className='evnttxtpplstofdate'>Date: 2024-01-30</p>
                        </div>
                    </div>
                    <div className='listofimagebottmflx'>
                        <div className='listofeventdivmain'>
                            <img src={listofevent1} alt="..." />
                        </div>
                        <div className=''>
                            <p className='evnttxtpplstof'>New Event</p>
                            <p className='evnttxtpplstofdate'>Date: 2024-01-30</p>
                        </div>
                    </div> */}
                </div>


            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button
                    className="btn btn-danger"
                    style={{ marginLeft: "80%", height: "30px", width: "30px" }}
                    onClick={() => setIsOpen(false)}
                >
                    X
                </button>
                <EventsBigCalenderModal
                    eventsForSelectedDate={eventsForSelectedDate}
                />
            </Modal>

        </>
    )
}

export default ListofEngagement
