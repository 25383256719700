import React from 'react'
// import advytimg from "../../Images/advytimg.jpg";
import Articleicon from "../../Images/Icons/PNG/Article icon.png"
import { useNavigate } from 'react-router-dom';
import { useAuthCtx } from '../../context/AuthCtx';
const Createdocument = () => {
    const { userData } = useAuthCtx();
    const navigate = useNavigate();
    const handleCreateClick = () => {
        navigate('/createdocumentform')
    }
    return (
        <>
        {
            userData?.userType==="Admin" || userData?.userType==="Manager"
            ?
            (
                <div className="createdcocumentdiv" style={{ cursor: "pointer" }} onClick={handleCreateClick}>
                <div className='createdcocumentop'>
                    <div className='createdcocumentflx'>
                        <div className='documentbrdrprfl'>
                            <div className='documentbrdrprflimg'>
                                <img src={userData?.image} alt="..." />
                            </div>
                        </div>

                        <div className=''>
                            <p className='textthghtpp'>Share Your Thoughts</p>
                        </div>
                    </div>
                </div>
                <div className='createdcocumenbottom'>
                    <div className='crtdcmntflx'>
                        <div className='articleiconimgdiv'>
                            <img src={Articleicon} alt="..." />
                        </div>
                        <div className=''>
                            <p className='dcmnttxtpppdf'>Create Document</p>
                        </div>
                    </div>
                </div>
            </div>
            )
            :
            (<></>)
        }
            
        </>
    )
}

export default Createdocument
