import React, { useEffect, useState } from "react";
import Eventimg2 from "../Images/No-Image-Placeholder.png";
import moment from "moment";
// import table_icon from "../Images/Icons/PNG/share-icon-elite-web.svg"
import toast from "react-hot-toast";
import { ApiHelperFunction } from "../services/api/apiHelpers";
import { useDispatch } from "react-redux";
import { getSubscriptionEventData } from "../redux/slices/subscriptionEventSlice";
import repost from "../Images/Repost Icon.png";
import { getAllPosts } from "../redux/slices/postSlice";
import { useAuthCtx } from "../context/AuthCtx";
import UserPlus from "../../src/Images/headericon/UserPlus.png";
import share from "../../src/Images/headericon/share.png"
// import { getEventData } from "../redux/slices/eventSlice";


export default function EventsBigCalenderModal({ eventsForSelectedDate, closemodal, fetchcalenderdatafunc }) {
  const dispatch = useDispatch();
  const { userData } = useAuthCtx();
  const [reposrsharemodal, setReposrsharemodal] = useState(false);
  const [reposrsharemodalmore, setReposrsharemodalmore] = useState(false);



  //for joinning event
  const handleJoinClick = async (id) => {
    const data = {
      eventId: id,
    };
    const response = await ApiHelperFunction({
      urlPath: `/join-event`,
      method: "POST",
      data,
    });

    if (response.status) {
      toast?.success(response?.data?.message);
      dispatch(getSubscriptionEventData());
    } else {
      toast.error(response?.response?.data?.message);
    }
  }

  useEffect(() => {
    dispatch(getSubscriptionEventData());
  }, [])


  //for Repost event in home page
  const handlePost = async (e, item) => {
    let data = {
      description: `${item?.title} at ${moment(item?.start)?.format("YYYY-MM-DD")}`,
      image: [item?.image],
      postType: "public",
    };
    let response = await ApiHelperFunction({
      urlPath: "/user-add-post",
      method: "POST",
      data: data,
    });

    if (response?.status === 200) {
      closemodal();
      toast?.success(response?.data?.message);
      dispatch(getAllPosts());
    } else {
      toast?.error(response?.response?.data?.message);
    }

  };

  const repostshareclick = () => {
    setReposrsharemodal(!reposrsharemodal)
  }

  const repostsharemoreclick = () => {
    setReposrsharemodalmore(!reposrsharemodalmore)
  }

  const handleDelete = async (id) => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-event/${id}`,
      method: "DELETE",
    });
    if (res?.status) {
      toast.success("Event is deleted successfully");
      fetchcalenderdatafunc();
    }
  }

  return (
    <>
      <section className="bgeventscaleder">
        <div className="bgeventscaledermainbgwht">
          <div className="evnttxtppmddivflx">
            <h5 className="evnttxtppmdlpp"> Event Modal</h5>
            <div className="closedibiocn" onClick={() => closemodal()}>
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>


          <div className="event_imgmodalsivdivmainbfg">
            <div className="event_imgmodalsdivnblflxnnm">
              <div className="event_imgmodalsivdivflx">
                <div className="event_imgmodalsiv">
                  {eventsForSelectedDate?.image ? (
                    <img
                      src={eventsForSelectedDate?.image}
                      className="img-fluid"
                      alt="event"
                    />
                  ) : (
                    <img src={Eventimg2} className="img-fluid" alt="event" />
                  )}
                </div>
                <div className="event_details">

                  <div className="head">
                    <h2 className="evtnttitletxtpphjtxp"> Event Title :
                      <span> {eventsForSelectedDate?.title}  </span>
                    </h2>
                  </div>


                  <div className="hosted">
                    <p className="startdatetxtppp">
                      Start :{" "}
                      <span>  {moment(eventsForSelectedDate?.start)?.format("YYYY-MM-DD")} </span>
                    </p>
                  </div>

                  <div className="hosted">
                    <p className="startdatetxtppp">
                      End :{" "}
                      <span> {moment(eventsForSelectedDate?.end)?.format("YYYY-MM-DD")}  </span>
                    </p>
                  </div>
                  <div className="head">
                    <p className="notextstxtiippshi">
                      Note :
                      <span> {eventsForSelectedDate?.notes}  </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="threedoteasisedivebentjoindivflx">

                <div className="">

                </div>
                <div className="threedotsfrrepostsharedivbg" onClick={repostshareclick}>
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                  {reposrsharemodal && <div className="shrerepostweventnodaldivbg">
                    {
                      userData?.userType !== "Owner" &&
                      <div onClick={() => handleJoinClick(eventsForSelectedDate?.id)} className="usemodaleventprflnewchnageimg">

                        <img src={UserPlus} alt="..." />
                      </div>
                    }
                    {/* <div className="sreposrevnstdjhkknewchnf">
                      <img
                        src={share}
                        alt="..." className=""
                      />
                    </div> */}
                    <div className="sreposrevnstdjhkknewchnf nndjduporethhshrxt"
                      onClick={(e) => handlePost(e, eventsForSelectedDate)}
                    >
                      <img
                        src={repost}
                        alt="..." className=""
                      />
                    </div>
                    {
                      eventsForSelectedDate?.addedBy === userData?._id &&
                      <div className="sreposrevnstdjhkknewchnf">
                        <i class="fa-solid fa-trash" onClick={() => handleDelete(eventsForSelectedDate?.id)}></i>
                      </div>
                    }

                  </div>}
                </div>

              </div>
              {/* <div className="shareusereventflx">
                <div className="sreposrevnstdjhkk"
                  onClick={(e) => handlePost(e, eventsForSelectedDate)}
                >
                  <img
                    src={repost}
                    alt="..." className=""
                  />
                </div>
                {
                  userData?.userType !== "Owner" &&
                  <div onClick={() => handleJoinClick(eventsForSelectedDate?.id)} className="sreposrevnstdjhkk">
               
                    <img src={UserPlus} alt="..." />
                  </div>
                }

              </div> */}
            </div>

            {
              eventsForSelectedDate?.additionalEvents.length > 0 && eventsForSelectedDate?.additionalEvents?.map((item, ind) => {
                return (
                  <>
                    <div className="event_imgmodalsdivnblflxnnm" key={ind}>
                      <div
                        className="event_imgmodalsivdivflx">
                        <div className="event_imgmodalsiv">
                          {item?.image ? (
                            <img
                              src={item?.image}
                              className="img-fluid"
                              alt="event"
                            />
                          ) : (
                            <img src={Eventimg2} className="img-fluid" alt="event" />
                          )}
                        </div>
                        <div className="event_details">

                          <div className="head">
                            <h2 className="evtnttitletxtpphjtxp">Event Title :
                              <span>{item?.title} </span></h2>
                          </div>


                          <div className="hosted">
                            <p className="startdatetxtppp">
                              Start : {" "}
                              {moment(item?.start)?.format("YYYY-MM-DD")}
                            </p>
                          </div>
                          <div className="hosted">
                            <p className="startdatetxtppp">
                              End : {" "}
                              {moment(item?.end)?.format("YYYY-MM-DD")}
                            </p>
                          </div>

                          <div className="head">
                            <p className="notextstxtiippshi"> Note :
                              {item?.notes}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="shareusereventflx">
                        {/* <div onClick={() => handleJoinClick(item?.id)} className="sreposrevnstdjhkk">
                          <img src={UserPlus} alt="..." />
                        </div>
                        <div className="sreposrevnstdjhkk"
                          onClick={(e) => handlePost(e, item)}
                        >
                          <img
                            src={repost}
                            alt="..." className="shareIconImg"
                          />
                        </div>

                        {
                          item?.addedBy === userData?._id &&
                          <div className="sreposrevnstdjhkknewchnf">
                            <i class="fa-solid fa-trash" onClick={() => handleDelete(item?.id)}></i>
                          </div>
                        } */}

                        <div className="threedotsfrrepostsharedivbg" onClick={repostsharemoreclick}>
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                          {reposrsharemodalmore && <div className="shrerepostweventnodaldivbg">
                            <div onClick={() => handleJoinClick(item?.id)} className="sreposrevnstdjhkk">
                              <img src={UserPlus} alt="..." />
                            </div>
                            <div className="sreposrevnstdjhkk"
                              onClick={(e) => handlePost(e, item)}
                            >
                              <img
                                src={repost}
                                alt="..." className="shareIconImg"
                              />
                            </div>
                            {
                              item?.addedBy === userData?._id &&
                              <div className="sreposrevnstdjhkknewchnf">
                                <i class="fa-solid fa-trash" onClick={() => handleDelete(item?.id)}></i>
                              </div>
                            }

                          </div>}
                        </div>

                      </div>


                    </div>
                  </>
                )
              })
            }
          </div>
        </div>



      </section>
    </>
  );
}
