import React, { useEffect, useState } from 'react'
// import trainingmain1 from "../../Images/trainingmain1.png";
// import video from "../../Images/Icons/PNG/video.png";
import star from "../../Images/Icons/PNG/star.png";
import time from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward points.png";
import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { getOnboardingTraining } from '../../redux/slices/jobRecruitmentSlice';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
// import { useAuthCtx } from '../../context/AuthCtx';
import trainingmain from "../../Images/trainingmain.png"

const MicroCousremodal = ({ closemodal, singleMandatory }) => {
    // const { userData } = useAuthCtx();
    // console.log("singleMandatory", singleMandatory)
    const [notEnroll, setNotEnroll] = useState("")
    const navigate = useNavigate()
    // const [descriptionshow, setDescription] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const [empData, setempData] = useState([]);

    //for getting internal trainer name
    const getEmployeeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-company-employees`,
            method: "GET",
        });
        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
                image: item?.image
            }));
            setColourOptions(formattedOptions);
            setempData(response?.data?.data);

        } else {

        }
    };

    useEffect(() => {
        const data = singleMandatory?.mentor
        const mentorData = data?.map((item) => {
            const optData = colourOptions?.find(ele => ele?.value === item)
            return optData ? optData : {}
        })
        setSelectedOptions(mentorData)
        // console.log("mentorDatabb", mentorData)
    }, [colourOptions])

    const handlehowClick = () => {
        const data = {
            selectedOptions: selectedOptions,
            singleMandatory: singleMandatory
        }
        // navigate('/enlargedNewEnrollment', { state: data })
        navigate(`/enlargedNewEnrollment/${singleMandatory?.title?.replace(/\s+/g, '-')}/${singleMandatory?._id}`, { state: data })

    }
    const handlesubitstpClick = (e) => {
        e.stopPropagation(e)
    }

    //for Enroll functionality
    const handleEnroll = async () => {
        let data = {
            trainingId: singleMandatory?._id
        }
        const res = await ApiHelperFunction({
            urlPath: `/join-training`,
            method: "POST",
            data: data
        })
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, enroll it!",
        }).then((res) => {
            if (res.isConfirmed) {
                setNotEnroll(true)
                toast.success("Enrolled Successfully");
            }
            else if (res.isDismissed) {
                setNotEnroll(false)
            }
        });
    }

    //for getting status is joined in training
    const handleIsJoined = async () => {
        let res = await ApiHelperFunction({
            urlPath: `/is-joined/${singleMandatory?._id}`,
            method: "GET",
        });

        // console.log(res?.data?.isJoined, "isJoinedqwe")
        if (res && res?.status) {
            setNotEnroll(res?.data?.isJoined);
        }
    }

    useEffect(() => {
        handleIsJoined();
        getEmployeeData();
    }, [])

    const cousrevideofirstonClick = () => {
        // console.log("playyyy", singleMandatory)
        const data = {
            selectedOptions: selectedOptions,
            singleMandatory: singleMandatory
        }
        // console.log("playyyy", singleMandatory)
        // navigate("/coursefirstVideoPlayer", { state: data })
        navigate(`/coursefirstVideoPlayer/${singleMandatory?.title?.replace(/\s+/g, '-')}/${singleMandatory?._id}/${singleMandatory?.videos?.[0]?._id}`, { state: data })
        // navigate("/coursefirstVideoPlayer", { state: singleMandatory })
    }

    // console.log("notEnroll", notEnroll)

    return (
        <>
            <div className='mandatorymodalmain' onClick={() => closemodal()}>
                <div className='mandatorymodalmaincontent' onClick={(e) => handlesubitstpClick(e)}>
                    <div className='vdeoimgmain'>
                        {/* <img src={trainingmain1} alt="..." /> */}
                        {
                            (singleMandatory?.image === "images" || singleMandatory?.image === "" || singleMandatory?.image === "image") ? (<div>
                                <img src={trainingmain} alt="..." />
                            </div>) : (<div >
                                <img style={{ height: "300px", width: "500px" }} src={singleMandatory?.image} alt="..." />
                            </div>)
                        }
                        <div className='videoimgdiv'>
                            {/* <img src={video} alt='...' /> */}

                        </div>
                    </div>
                    <div className=''>
                        <p className='elitedevloptxt'>{singleMandatory?.title}</p>
                    </div>
                    <div className='divenrolflx'>
                        <div className='divenallbootcmp'>
                            {
                                singleMandatory?.avgRatings !== null
                                    ?
                                    <div className='divstarimgmain'>
                                        <div className='divstarimgmainimg'>
                                            <img src={star} alt="..." />
                                        </div>
                                        <p>{singleMandatory?.avgRatings}</p>
                                    </div>
                                    :
                                    <div className="imgtxtdivmainddd">
                                        <div className="strimgdiv">
                                            <img
                                                src={star}
                                                alt="..."
                                            />
                                        </div>
                                        <p className="tsgyutppp">0</p>
                                    </div>
                            }
                            <div className='divstarimgmain'>
                                <div className='divstarimgmainimg'>
                                    <img src={time} alt="..." />
                                </div>
                                <p>{singleMandatory?.totalDurationInMinute} min</p>
                            </div>
                            <div className='divstarimgmain'>
                                <div className='divstarimgmainimg'>
                                    <img src={reward} alt="..." />
                                </div>
                                <p>{singleMandatory?.trainingReward}</p>
                            </div>
                        </div>
                        {
                            singleMandatory?.placeTime?.length !== 0 ? (<></>) :
                                (
                                    <div className='enrolllnowdiv' style={{ cursor: "pointer" }}>
                                        {
                                            notEnroll === false ? (<div onClick={handleEnroll}>Enroll Now</div>) : (<div onClick={cousrevideofirstonClick}>Play Now</div>)
                                        }

                                    </div>
                                )
                        }

                    </div>
                    <div className=''>
                        <div className="decrtxtppdiv">
                            <p className='decrtxtpp'>Description</p>
                            <p className='descriptiondetails'>
                                {singleMandatory?.description}
                            </p>
                            <div className='showdescripttext' onClick={handlehowClick}>Show More</div>
                        </div>

                    </div>


                </div>
            </div>
        </>
    )
}

export default MicroCousremodal
