import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';

const Initiativeanlytics = ({ProjectCount,Project}) => {
    const [seriessdatainit, setSeriessdatainit] = useState([]);
    // console.log("ProjectCount",seriessdatainit)

    useEffect(()=>{
        setSeriessdatainit(ProjectCount);
    },[ProjectCount])

    const optioninitiative = {
        chart: {
            width: 300,
            type: 'donut',
        },
        labels: Project,
        // ['In Process',
        //     'Completed',],
        fill: {
            opacity: 1,
            colors: ['#cc051c', '#142553']
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    show: false
                }
            }
        }],
        legend: {
            position: 'right',
            offsetY: 0,
            height: 230,
        }
    };

    return (
        <>

            <div className="initiativediv">
                <Chart
                    options={optioninitiative}
                    series={seriessdatainit}
                    type="donut"
                    width={300}
                />
            </div>
        </>
    )
}

export default Initiativeanlytics