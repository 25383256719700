import React, { useEffect, useState } from 'react'
import "./Orgchart.css";
// import bokk_img from "../../Images/bokk_img.png"
import { Link, useNavigate } from 'react-router-dom';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { useAuthCtx } from '../../context/AuthCtx';

const Orgchart = () => {
    const {userData}=useAuthCtx();
    const [companyTree, setCompanyTree] = useState([]);
    const navigate = useNavigate();

    //for viewing count
    const viewCompanyTree = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/get-company-tree`,
            method: "GET"
        });
        if (response.status) {
            console.log("responseyou", response?.data?.data);
            setCompanyTree(response?.data?.data?.[0])
        }
    };

    const AnotherProfile = (id) => {
        if (id === userData?._id) {
            navigate(`/profile`);
        }
        else {
            navigate(`/Profile_rating/${id}`);
        }
    }

    useEffect(() => {
        viewCompanyTree();
    }, [])
    return (
        <>
            <div className="tree">
                <div className='treecontainer'>
                    <ul>
                        <li>
                            <div className='' onClick={() => AnotherProfile(companyTree?._id)}>
                                <div className='ordgfdtlsdivmain'>
                                    <div className='pimfprlimg'>
                                        {/* <img src={bokk_img} alt="..." /> */}
                                        <img src={companyTree?.image} alt="..." />
                                    </div>
                                    <div className=''>
                                        <p className='ordgdtklstxtp'>{companyTree?.firstName + " " + companyTree?.lastName}</p>
                                        <p className='ordgdtklstxtphhdr'>Admin</p>

                                    </div>
                                </div>
                            </div>

                            <ul className="inn_line">
                                {
                                    companyTree?.HRs?.slice(0, 3)?.map((hrs, i) => {
                                        return (
                                            <li key={i}>
                                                <div className='ordgfdtlsdivmain'>
                                                    <div className='ordgfdtlsdivmainflx' onClick={() => AnotherProfile(hrs?._id)}>
                                                        <div className='pimfprlimg'>
                                                            <img src={hrs?.image} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>{hrs?.firstName + " " + hrs?.lastName}</p>
                                                            <p className='ordgdtklstxtphhdr'>Hr</p>
                                                        </div>
                                                    </div>

                                                    <Link className='' to={`/orgchartdetails/${hrs._id}`}>
                                                        {
                                                            hrs?.totalManagerAndEmployee > 0 && (
                                                                <>
                                                                    <p className='ordgdtklstxtphhdrmrdr'> {hrs?.totalManagerAndEmployee}+</p>
                                                                    {/* <p className='mredivmaintxtpp'>More</p> */}
                                                                </>
                                                            )
                                                        }

                                                    </Link>

                                                </div>

                                                <ul className="inn_line">
                                                    {
                                                        hrs?.Managers?.slice(0, 3)?.map((managers, ind) => {
                                                            return (
                                                                <li key={ind}>
                                                                    <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                                        <div className='ordgfdtlsdivmainflx' onClick={() =>AnotherProfile(managers?._id)}>
                                                                            <div className='pimfprlimg'>
                                                                                <img src={managers?.image} alt="..." />
                                                                            </div>
                                                                            <div className=''>
                                                                                <p className='ordgdtklstxtp'>{managers?.firstName + " " + managers?.lastName}</p>
                                                                                <p className='ordgdtklstxtphhdr'>Manager</p>

                                                                            </div>
                                                                        </div>

                                                                        <Link className='' to={`/orgmanagerchart/${hrs?._id}/${managers?._id}`}>
                                                                            {
                                                                                managers?.totalEmployees > 0 && (
                                                                                    <>
                                                                                        <p className='ordgdtklstxtphhdrmrdr'> {managers?.totalEmployees}+</p>
                                                                                    </>
                                                                                )
                                                                            }

                                                                        </Link>
                                                                    </div>

                                                                    <ul className='speccialdiv'>
                                                                        {
                                                                            managers?.Employees?.map((employees, index) => {
                                                                                return (
                                                                                    <li className='speccialdivli' key={index}>
                                                                                        <div className='ordgfdtlsdivmaindiff' onClick={() => AnotherProfile(employees?._id)}>
                                                                                            <div className='pimfprlimg'>
                                                                                                <img src={employees?.image} alt="..." />
                                                                                            </div>
                                                                                            <div className=''>
                                                                                                <p className='ordgdtklstxtp'>{employees?.firstName + " " + employees?.lastName}</p>

                                                                                                <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }


                                                                        {/* <li className='speccialdivli'>
                                                                <div className='ordgfdtlsdivmaindiff'>
                                                                    <div className='pimfprlimg'>
                                                                        <img src={bokk_img} alt="..." />
                                                                    </div>
                                                                    <div className=''>
                                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
            
                                                                        <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li className='speccialdivli'>
                                                                <div className='ordgfdtlsdivmaindiff'>
                                                                    <div className='pimfprlimg'>
                                                                        <img src={bokk_img} alt="..." />
                                                                    </div>
                                                                    <div className=''>
                                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
            
                                                                        <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li className='speccialdivli'>
                                                                <div className='ordgfdtlsdivmaindiff'>
                                                                    <div className='pimfprlimg'>
                                                                        <img src={bokk_img} alt="..." />
                                                                    </div>
                                                                    <div className=''>
                                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
            
                                                                        <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                    </div>
                                                                </div>
                                                            </li> */}

                                                                    </ul>
                                                                </li>
                                                            )
                                                        })
                                                    }



                                                    {/* <li>
                                                    <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                        <div className='ordgfdtlsdivmainflx'>
                                                            <div className='pimfprlimg'>
                                                                <img src={bokk_img} alt="..." />
                                                            </div>
                                                            <div className=''>
                                                                <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                                <p className='ordgdtklstxtphhdr'>Manager</p>
        
                                                            </div>
                                                        </div>
                                                        <Link className='' to="/orgmanagerchart">
                                                            <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                        </Link>
                                                    </div>
        
                                                    <ul className='speccialdiv'>
                                                        <li className='speccialdivli'>
                                                            <div className='ordgfdtlsdivmaindiff'>
                                                                <div className='pimfprlimg'>
                                                                    <img src={bokk_img} alt="..." />
                                                                </div>
                                                                <div className=''>
                                                                    <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
        
                                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className='speccialdivli'>
                                                            <div className='ordgfdtlsdivmaindiff'>
                                                                <div className='pimfprlimg'>
                                                                    <img src={bokk_img} alt="..." />
                                                                </div>
                                                                <div className=''>
                                                                    <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
        
                                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className='speccialdivli'>
                                                            <div className='ordgfdtlsdivmaindiff'>
                                                                <div className='pimfprlimg'>
                                                                    <img src={bokk_img} alt="..." />
                                                                </div>
                                                                <div className=''>
                                                                    <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
        
                                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className='speccialdivli'>
                                                            <div className='ordgfdtlsdivmaindiff'>
                                                                <div className='pimfprlimg'>
                                                                    <img src={bokk_img} alt="..." />
                                                                </div>
                                                                <div className=''>
                                                                    <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
        
                                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                </div>
                                                            </div>
                                                        </li>
        
        
        
                                                    </ul>
                                                </li>
        
                                                <li>
                                                    <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                        <div className='ordgfdtlsdivmainflx'>
                                                            <div className='pimfprlimg'>
                                                                <img src={bokk_img} alt="..." />
                                                            </div>
                                                            <div className=''>
                                                                <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                                <p className='ordgdtklstxtpbifh'>Mr. Lorem Ipsum</p>
                                                                <p className='ordgdtklstxtphhdr'>Manager</p>
        
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                        </div>
                                                    </div>
        
                                                    <ul className='speccialdiv'>
                                                        <li className='speccialdivli'>
                                                            <div className='ordgfdtlsdivmaindiff'>
                                                                <div className='pimfprlimg'>
                                                                    <img src={bokk_img} alt="..." />
                                                                </div>
                                                                <div className=''>
                                                                    <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
        
                                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className='speccialdivli'>
                                                            <div className='ordgfdtlsdivmaindiff'>
                                                                <div className='pimfprlimg'>
                                                                    <img src={bokk_img} alt="..." />
                                                                </div>
                                                                <div className=''>
                                                                    <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
        
                                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className='speccialdivli'>
                                                            <div className='ordgfdtlsdivmaindiff'>
                                                                <div className='pimfprlimg'>
                                                                    <img src={bokk_img} alt="..." />
                                                                </div>
                                                                <div className=''>
                                                                    <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
        
                                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className='speccialdivli'>
                                                            <div className='ordgfdtlsdivmaindiff'>
                                                                <div className='pimfprlimg'>
                                                                    <img src={bokk_img} alt="..." />
                                                                </div>
                                                                <div className=''>
                                                                    <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
        
                                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                </div>
                                                            </div>
                                                        </li>
        
        
        
                                                    </ul>
                                                </li> */}


                                                </ul>

                                            </li>

                                        )
                                    })
                                }


                                {/* <li>
                                    <div className='ordgfdtlsdivmain'>
                                        <div className='ordgfdtlsdivmainflx'>
                                            <div className='pimfprlimg'>
                                                <img src={bokk_img} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                <p className='ordgdtklstxtphhdr'>Hr</p>

                                            </div>
                                        </div>

                                        <Link className='' to="/orgchartdetails" >
                                            <p className='ordgdtklstxtphhdrmrdr'> 16+</p>
                                            <p className='mredivmaintxtpp'>More</p>
                                        </Link>

                                    </div>
                                    <ul className="inn_line">
                                        <li>
                                            <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                <div className='ordgfdtlsdivmainflx'>
                                                    <div className='pimfprlimg'>
                                                        <img src={bokk_img} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                        <p className='ordgdtklstxtphhdr'>Manager</p>

                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                </div>
                                            </div>

                                            <ul className='speccialdiv'>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>



                                            </ul>
                                        </li>

                                        <li>
                                            <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                <div className='ordgfdtlsdivmainflx'>
                                                    <div className='pimfprlimg'>
                                                        <img src={bokk_img} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                        <p className='ordgdtklstxtphhdr'>Manager</p>

                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                </div>
                                            </div>

                                            <ul className='speccialdiv'>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>



                                            </ul>
                                        </li>

                                        <li>
                                            <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                <div className='ordgfdtlsdivmainflx'>
                                                    <div className='pimfprlimg'>
                                                        <img src={bokk_img} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                        <p className='ordgdtklstxtphhdr'>Manager</p>

                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                </div>
                                            </div>

                                            <ul className='speccialdiv'>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>



                                            </ul>
                                        </li>


                                    </ul>

                                </li>

                                <li>
                                    <div className='ordgfdtlsdivmain'>
                                        <div className='ordgfdtlsdivmainflx'>
                                            <div className='pimfprlimg'>
                                                <img src={bokk_img} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                <p className='ordgdtklstxtphhdr'>Hr</p>

                                            </div>
                                        </div>

                                        <Link className='' to="/orgchartdetails" >
                                            <p className='ordgdtklstxtphhdrmrdr'> 16+</p>
                                            <p className='mredivmaintxtpp'>More</p>
                                        </Link>

                                    </div>
                                    <ul className="inn_line">
                                        <li>
                                            <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                <div className='ordgfdtlsdivmainflx'>
                                                    <div className='pimfprlimg'>
                                                        <img src={bokk_img} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                        <p className='ordgdtklstxtphhdr'>Manager</p>

                                                    </div>
                                                </div>
                                                <Link className='' to="/orgmanagerchart">
                                                    <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                </Link>
                                            </div>

                                            <ul className='speccialdiv'>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>



                                            </ul>
                                        </li>

                                        <li>
                                            <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                <div className='ordgfdtlsdivmainflx'>
                                                    <div className='pimfprlimg'>
                                                        <img src={bokk_img} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                        <p className='ordgdtklstxtphhdr'>Manager</p>

                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                </div>
                                            </div>

                                            <ul className='speccialdiv'>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>



                                            </ul>
                                        </li>


                                    </ul>

                                </li>

                                <li>
                                    <div className='ordgfdtlsdivmain'>
                                        <div className='ordgfdtlsdivmainflx'>
                                            <div className='pimfprlimg'>
                                                <img src={bokk_img} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                <p className='ordgdtklstxtphhdr'>Hr</p>

                                            </div>
                                        </div>

                                        <Link className='' to="/orgchartdetails" >
                                            <p className='ordgdtklstxtphhdrmrdr'> 16+</p>
                                            <p className='mredivmaintxtpp'>More</p>
                                        </Link>

                                    </div>
                                    <ul className="inn_line">
                                        <li>
                                            <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                <div className='ordgfdtlsdivmainflx'>
                                                    <div className='pimfprlimg'>
                                                        <img src={bokk_img} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                        <p className='ordgdtklstxtphhdr'>Manager</p>

                                                    </div>
                                                </div>
                                                <Link className='' to="/orgmanagerchart">
                                                    <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                </Link>
                                            </div>

                                            <ul className='speccialdiv'>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>



                                            </ul>
                                        </li>

                                        <li>
                                            <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                <div className='ordgfdtlsdivmainflx'>
                                                    <div className='pimfprlimg'>
                                                        <img src={bokk_img} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                        <p className='ordgdtklstxtphhdr'>Manager</p>

                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                </div>
                                            </div>

                                            <ul className='speccialdiv'>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>



                                            </ul>
                                        </li>


                                    </ul>

                                </li> */}
                            </ul>

                        </li>
                    </ul>
                </div>
            </div>

        </>
    )
}

export default Orgchart

