import React from 'react'
import Ongoing from './Ongoing'
import EducateStatusCard from './EducateStatusCard'
// import StandardOperatingProsedure from "./StandardOperatingProsedure";
import Backarrow from "../../Images/Icons/PNG/Backarrow.png";
// import RequestTraining from "../../Images/Icons/PNG/RequestTraining.png";
import RequestedTrainingcard from "./RequestedTrainingcard"
import DocumentRepositorymain from './DocumentRepositorymain';
import { useNavigate } from 'react-router-dom';

const Singlerequestedtraining = () => {
const navigate=useNavigate();

    return (
        <section className='singleongoingmain'>
            <div className='employeepagecontainer'>
                <div className='educatemainflx'>
                    <div className='singlemandatoryleft'>
                        <div className=''>
                            <EducateStatusCard />
                        </div>
                        <div className=''>
                            <Ongoing />
                        </div>
                        <div className=''>
                            {/* <StandardOperatingProsedure /> */}
                            <DocumentRepositorymain/>
                        </div>
                    </div>

                   
                    <div className='singlemandatoryright'>
                        <div className='ongoingbgdiv'>
                            <div className="top">
                                <div className="head bulleBoardHead">
                                    {/* <figure className="bulleBoardFig" >
                                        <img
                                            src={RequestTraining}
                                            alt="..."

                                        />
                                    </figure> */}
                                    <h4>Requested Training</h4>
                                </div>
                                <div className="add_seeallflx">
                                    <div className="tyEvMainDiv">
                                        <figure className="bulleBoardFig" onClick={()=>navigate(-1)}>
                                            <img src={Backarrow} alt="..." />
                                        </figure>
                                    </div>
                                </div>
                            </div>

                            <div className='bottom '>
                                {/* <EducateOngoing /> */}
                                <RequestedTrainingcard />
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Singlerequestedtraining
