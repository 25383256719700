import React from 'react'
import "./Certificate.css";
import educationcertificate from "../../Images/educationcertificate.png";
import certificateofexcellence from "../../Images/certificateofexcellence.png";
import certificateofengagement from "../../Images/certificateofengagement.png";
import certificateofeloquence from "../../Images/certificateofeloquence.png"
import { useNavigate } from 'react-router-dom';

const Certificatesmain = () => {
    const navigate=useNavigate()
    const certificateofeducationclick=()=>{
        navigate("/certificateofeducation")
    }
    return (
        <>
            <setion className="allcerifivatesmainsection">
                <div className='employeepagecontainer'>
                    <div className='allcerificatebgmain'>

                        <div className="cerificatemainbhbrdr" onClick={()=>certificateofeducationclick()}>
                            <div className='cerificatemainbhbrdrdivflx'>
                                <div className='certificateimage'>
                                    <img src={educationcertificate} alt="..." />
                                </div>
                                <div className=''>
                                    <h4 className='certificatetyoetxtpp'>Certificate of Education</h4>
                                    {/* <p className='coursttiltlecertifacttxtp'>  Title :
                                        <span> BASICS OF PHOTOSHOP AND DESIGN </span>
                                    </p> */}

                                    <p className='coursttiltlecertifacttxtp'> Company Name :
                                        <span>Fractals Elite </span>
                                    </p>
                                    {/* <p className='coursttiltlecertifacttxtp'> Duration :
                                        <span> 6hh 0mm </span>
                                    </p> */}
                                </div>
                            </div>

                        </div>

                        <div className="cerificatemainbhbrdr">
                            <div className='cerificatemainbhbrdrdivflx'>
                                <div className='certificateimage'>
                                    <img src={certificateofexcellence} alt="..." />
                                </div>
                                <div className=''>
                                    <h4 className='certificatetyoetxtpp'>Certificate of Excellence</h4>
                                    {/* <p className='coursttiltlecertifacttxtp'>  Title :
                                        <span>EMPLOYEE OF THE MONTH  </span>
                                    </p> */}

                                    <p className='coursttiltlecertifacttxtp'> Company Name :
                                        <span>Fractals Elite </span>
                                    </p>

                                </div>
                            </div>

                        </div>

                        <div className="cerificatemainbhbrdr">
                            <div className='cerificatemainbhbrdrdivflx'>
                                <div className='certificateimage'>
                                    <img src={certificateofengagement} alt="..." />
                                </div>
                                <div className=''>
                                    <h4 className='certificatetyoetxtpp'>Certificate of Engagement</h4>
                                    {/* <p className='coursttiltlecertifacttxtp'>  Title :
                                        <span> THE PARTICIPATION IN THE EVENT ESG TECHNOLOGIES AND ITS FUTURE  </span>
                                    </p> */}

                                    <p className='coursttiltlecertifacttxtp'> Company Name :
                                        <span>Fractals Elite </span>
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="cerificatemainbhbrdr">
                            <div className='cerificatemainbhbrdrdivflx'>
                                <div className='certificateimage'>
                                    <img src={certificateofeloquence} alt="..." />
                                </div>
                                <div className=''>
                                    <h4 className='certificatetyoetxtpp'>Certificate of Eloquence</h4>
                                    {/* <p className='coursttiltlecertifacttxtp'>  Title :
                                        <span>RECOGNIZING THE EXCEPTIONAL SPRIT OF INNOVATION ON
                                            NEXT GEN HR SOLUTION </span>
                                    </p> */}
                                    
                                    <p className='coursttiltlecertifacttxtp'> Company Name :
                                        <span>Fractals Elite </span>
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </setion>
        </>
    )
}

export default Certificatesmain
