import React from 'react'
import EducateStatusCard from './EducateStatusCard'
import CoursePreviewRecomended from './CoursePreviewRecomended'
import trainingmain1 from "../../Images/trainingmain1.png";
import video from "../../Images/Icons/PNG/video.png";
import advytimg from "../../Images/advytimg.jpg";
import download from "../../Images/Icons/PNG/download.png";
import Share from "../../Images/Icons/PNG/Share.png"
import Coursepreviewupnext from './Coursepreviewupnext';
import { useNavigate } from 'react-router-dom';
const CoursePreview = () => {
    const navigate = useNavigate();

    const handlepageClick = () => {
        navigate("/courseVideoPlayer")
    }
    return (
        <>
            <section className='corsepreviewmain'>
                <div className='employeepagecontainer'>
                    <div className='corsepreviewmainleft'>
                        <div className='educatestatusdiv'>
                            <EducateStatusCard />
                        </div>
                        <div className=''>
                            <CoursePreviewRecomended />
                        </div>
                    </div>
                    <div className='corsepreviewmainmiddle'>
                        <div className='corsepreviewmainmiddlebg'>
                            <div className='trainingdivmainedt' onClick={handlepageClick}>
                                <img src={trainingmain1} alt="..." />
                                <div className='videodivimgicon'>
                                    <img src={video} alt="..." />
                                </div>
                                <div className='textimeevntvdodiv'>
                                    <p className='textimeevntvdo'>15:46</p>
                                </div>
                            </div>
                            <div className=''>
                                <div className='dotdivbgvdo'>
                                    <p className='vdodescrptntxtppdiv'> Part -1: Improve work culture in your workplace</p>
                                </div>
                                <div className=''>
                                    <p className='emplyetextppcrse'>Employee engagement is a measure of employees’ enthusiasm and dedication to work. Engaged employees care about their work and workplace
                                    </p>
                                </div>
                                <div className='trainerdetailsdownloadshreflx'>
                                    <div className='trainerprfltrainerdtls'>
                                        <div className='prfledtslbrdrtrainer'>
                                            <div className='prfledtslbrdrtrainerimg'>
                                                <img src={advytimg} alt="..." />
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div className=''>
                                                <p className='trainermanetxt'>Anirban Roy</p>
                                            </div>
                                            <p className='trinrdtlstxt'>Trainer</p>
                                        </div>
                                    </div>
                                    <div className='dwnldtrainerflx'>
                                        <div className='dwnldpdfflx'>
                                            <div className='downloadimgdivtrnr'>
                                                <img src={download} alt="..." />
                                            </div>
                                            <p className='dwldtxtppodf'>Download Pdf</p>
                                        </div>
                                        <div className='shredivimgmain'>
                                            <img src={Share} alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className='trainerrtngtxtbrdr'>
                                    <p className='trainerrtngtxt'>Trainer Rating</p>
                                </div>
                            </div>
                            <div className='trainerdtlsbrdrtop'>
                                <div className='cousrepreviewbuttmdivlast'>
                                    <p className='crtdtxtppspcl'>Created on 14th Jan 2024
                                    </p>
                                    <p className='dsrtptxtpp'>Description</p>
                                    <p className='empytxtppeng'>Employee engagement is a measure of employees’ enthusiasm and dedication to work. Engaged employees care about their work and workplace, and believe their employer cares about them.
                                        <span className='rdtstppmre'>Read More</span>
                                    </p>
                                    <p className='empytxtppeng'>KeyWords</p>
                                    <p>
                                        <span className='keywrdtagpp'> #Employee,</span>
                                        <span className='keywrdtagpp'> #Engagement,</span>
                                        <span className='keywrdtagpp'> #Company,</span>
                                        <span className='keywrdtagpp'> #Together,</span>
                                        <span className='keywrdtagpp'> #Work ,</span>
                                        <span className='keywrdtagpp'> #People</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='corsepreviewmainright'>
                        <div className=''>
                            <Coursepreviewupnext />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CoursePreview
