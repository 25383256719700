import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
const Genderanalysis = ({category,genderCount}) => {
    const [chartState, setChartState] = useState({
        series: [],
        options: {
            // chart: {
            //     type: 'donut',
            // },
            // responsive: [{
            //     breakpoint: 480,
            //     options: {
            //         chart: {
            //             width: 200,
            //         },
            //         legend: {
            //             position: 'bottom',
            //         },
            //     },
            // }],
            chart: {
                width: 115,
                type: 'donut',
            },
            fill: {
                opacity: 1,
                colors: ['#cc051c', '#142553']
            },
            dataLabels: {
                enabled: false,
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: true
                    }
                },
                breakpoint:1199,
                options:{
                    chart: {
                        width: 250
                    },
                    legend: {
                        show: true
                    }
                },
                breakpoint:767,
                options:{
                    chart: {
                        width: 180
                    },
                    legend: {
                        show: true
                    }
                }
            }],
            legend: {
                position: 'right',
                offsetY: 0,
                height: 230,
            }
        },
    });

 
    // Update chart data when  count changes
    useEffect(() => {
        setChartState(prevState => ({
            ...prevState,
            series:genderCount,
            options: {
                ...prevState.options,
                labels: category
              },
        }));
    }, [genderCount]);
    return (
        <>
            <div className='genderdiv'>
                <h4 className='gendragetxtpp'>
                    GENDER
                </h4>
                <div className='maledivwdthflx'>
                    {/* <p className='malefmltxtpp'>Male</p> */}

                    <div className='apexchartlfnddiv'>
                        <Chart
                            options={chartState.options}
                            series={chartState?.series}
                            type="donut"
                            width={115}
                        />
                        {/* <button onClick={appendData}>Append Data</button> */}
                    </div>

                </div>
                {/* <div className='maledivwdthflx'>
                    <p className='malefmltxtpp'>Female</p>
                    <div className='apexchartlfnddiv'>
                        <Chart
                            options={optionsdata}
                            series={seriesdata}
                            type="donut"
                            width={115}
                        />
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default Genderanalysis