import React, { useEffect } from "react";
// import { useAuthCtx } from "../context/AuthCtx";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "../Foldable Login & Registration form/Login";
import { loginSuccess } from "../redux/slices/loginSlice";
// import Landingpage from "../View/Landingpage/Landingpage";
// import Landingpage from "../View/Landingpage/Index";

const CheckRoutes = () => {
  const loginStatus = useSelector(
    (state) => state.loginSliceReducer.isAuthenticated
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loginSuccess());
  }, []);

  return loginStatus ? <Outlet /> : <Login />;
};

export default CheckRoutes;


// import React from 'react'
// import { Outlet } from 'react-router-dom';
// import Landingpage from "../View/Landingpage/Landingpage";

// const CheckRoutes = () => {
//     const userToken = localStorage.getItem("Authorization");

//     if (userToken) {
//         return <Outlet />
//     } else {
//         return <Landingpage  />
//     }
// }

// export default CheckRoutes
