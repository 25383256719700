import React from 'react'
import sharethght from "../../Images/sharethght.jpg"
import { useNavigate } from 'react-router-dom';
import { useAuthCtx } from '../../context/AuthCtx';
import Addicon from "../../Images/headericon/Addicon.png"
import Statuscard from '../../Component/Statuscard';

const EducateStatusCard = () => {
  const { userData } = useAuthCtx();
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (userData?.userType === "Admin") {
      navigate('/trainingCreate')
    } else if (userData?.userType === "Manager") {
      navigate('/trainingCreateManager')
    } else {
      navigate('/trainingCreateEmployee')
    }

  }


  return (
    <>
      <div className="statusdivmain">
        <div className=''>
          <Statuscard/>
          {/* <div
            className="bg-userdtwht"
            style={{ backgroundColor: "rgb(208, 145, 124)" }}
          >
            <div className="plthclbtx">
              <p className="bghhkpp">BRONZE</p>
              <p className="clbpp">Club</p>
            </div>
            <div
              className=""
              style={{ backgroundColor: "#fff", height: "70%", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px", borderTopRightRadius: "92px" }}
            
            >
              <div className="divpaddingghh">
                <div className="prflimgdiv">
                  <div className="prflimgbrdr">
                    <div className="prflimgfh">
                      <img
                        alt="..."
                        src={sharethght}
                      />
                    </div>
                  </div>
                  <div className="divpatyn">
                    <p className="thounsabpp" style={{ color: "rgb(208, 145, 124)" }}>
                      402
                    </p>
                    <p className="pointshttx">POINTS BALANCE</p>
                  </div>
                </div>
                <div className="tstdfghmrl">
                  <p className="txtppght">Niladri Roy</p>
                  
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className='advocacycreatemaindivpadongnew'>
          {
            (userData?.userType === "Admin" || userData?.userType === "Manager")
              ?
              (<button className='advocacycreatemaindiv  ' onClick={handleDetailsClick}>
                <div className='addimgdiv'>
                  <img src={Addicon} alt="..." />
                </div>
                <p className='crtadvocacytxtypp'>Create New Training</p>
              </button>)
              :
              (<button className='trainingnewdiv' onClick={handleDetailsClick}>Request New Training</button>)
          }

        </div>
      </div>

    </>
  )
}

export default EducateStatusCard
