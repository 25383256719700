import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Eventimg1 from "../Images/eventimg1.png";
import Eventimg2 from "../Images/No-Image-Placeholder.png";
import CreateModal from "./Modal/CreateModal";
import { useDispatch, useSelector } from "react-redux";
import { getBillBoardData } from "../redux/slices/billBoardSlice";
import moment from "moment";
import { useAuthCtx } from "../context/AuthCtx";
// Constants
import { BASE_URL, API_ENDPOINT_PATH } from "../constants/config";
import { toast } from "react-hot-toast";
import { ApiHelperFunction } from "../services/api/apiHelpers";
import { getEventData } from "../redux/slices/eventSlice";
import EventsModal from "../SeprateModal/EventsModal";
import EditEventModal from "../SeprateModal/EditEventModal";
import eventsPic from "../Images/Icons/PNG/Event_View.png"
import profilesqrimg from "../../src/Images/profilesqrimg.jpeg"
import add_icon from "../../src/Images/Icons/PNG/Add icon.png"
import see_all from "../../src/Images/Icons/PNG/See all icon.png"
import { getSubscriptionEventData } from "../redux/slices/subscriptionEventSlice";
import ExpandGradient from "../../src/Images/headericon/ExpandGradient.png";
import Edit from "../../src/Images/headericon/Edit.png";
import Delete from "../../src/Images/headericon/Delete.png";
const initialValues = {
  eventName: "",
  hostedBy: "",
  eventDate: "",
  name: "",
  trainingDate: "",
  editTrainingName: "",
  editTraininDate: "",
};

export default function EventCard() {
  // const [modala, setModala] = useState(false);
  const [modalA, setModalA] = useState(false);
  // const [modalB, setModalB] = useState(false);
  const [modalId, setModalId] = useState("");

  const [top, setTop] = useState(null);
  const [left, setLeft] = useState(null);
  const dispatch = useDispatch();
  const { userData, modalF, setModalF, BulletinBoardSingleData1,
    setBulletinBoardSingleData1, modalG, setModalG, } = useAuthCtx();

  const billBoardData = useSelector((state) => state?.eventSlice?.event);
  const createModal = () => {
    setModalF(true);
  };

  const handleClick = (e, ele, index) => {
    setModalId(ele._id);
    // console.log("EVENT", e, index);
    setBulletinBoardSingleData1(ele);
    // console.log("s", e, ele);
    setTop(e.clientY);
    setLeft(e.clientX);
    setModalA(!modalA);
  };

  useEffect(() => {
    dispatch(getEventData());
    dispatch(getSubscriptionEventData());
    // console.log("bill", billBoardData);
  }, []);

  const stopModal = (e) => {
    modalA && setModalA(false);
    // setModalId("");
    e.stopPropagation();
  };


  //for delete events
  const handleDelete = async () => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-event/${modalId}`,
      method: "DELETE",
    });
    if (res && res?.status) {
      toast.success("Event deleted successfully");

      dispatch(getEventData());
      setModalA(false);
    } else {
      toast.error(res?.message || "Something went wrong");
      console.log("ERROR CREATING USER3", res);
    }
  };

  

  return (
    <>
      <div
        className="bulletin_board"
        // style={{ overflow: modalA ? "hidden" : "" }}
        onClick={(e) => stopModal(e)}
        id="eventsBulle"
      >
        <div className="top">
          <div className="head bulleBoardHead">
            {/* <figure className="bulleBoardFig">
              <img src={eventsPic} alt="..." />
            </figure> */}
            <h4>Events</h4>
          </div>
          <div className="add_seeallflx">
            {(userData?.userType === "Admin" || userData?.userType === "Manager" || userData?.userType === "Owner") ? (
              <div className="tyEvMainDiv" onClick={createModal}>
                {/* <span className="tyEvIcon"> */}
                <figure className="bulleBoardFig">
                  {/* <img src={add_icon} alt="..." /> */}
                </figure>
                {/* </span> */}
                {/* <p className="tyEvText">Add Event</p> */}
              </div>
            ) : (<></>)}
            <div>
              <Link to="/eventsDeails" className="seeAllAn">
                <figure className="bulleBoardFig">
                  <img src={ExpandGradient} alt="..." />
                </figure>
              </Link>
            </div>
          </div>


          {/* {userData?.userType === "Admin" && (
            <div className="crtu" onClick={createModal}>
              create
            </div>
          )} */}
        </div>
        <div className="bottom" style={{ height: "205px", overflowY: "auto" }}>
          {/* {(userData?.userType === "Admin" || userData?.userType === "Manager") ? (
            <div className="tyEvMainDiv" onClick={createModal}>
              <span className="tyEvIcon">
                <i class="fa-solid fa-plus"></i>
              </span>
              <p className="tyEvText">Add Event</p>
            </div>
          ) : (<></>)} */}
          {billBoardData?.length > 0 ? (
            billBoardData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="card_area"
                  style={{
                    marginBottom: "0px",
                    // background: "rgb(242, 233, 220)",
                    position: "relative",
                    marginTop: "0px",
                  }}
                >
                  <div className="event_img">
                    {item?.image ? (
                      <img
                        src={item?.image}
                        className="img-fluid"
                        alt="event"
                      />
                    ) : (
                      // <img src={Eventimg2} className="img-fluid" alt="event" />
                      <img src={profilesqrimg} className="divprflsqr" alt="prflsqr" />
                    )}
                  </div>

                  <div className="event_details">
                    {/* <div style={{cursor:"pointer"}} onClick={() => handleJoinClick(item?._id)}>
                      <i class="fa fa-user-plus" aria-hidden="true"></i>
                    </div> */}

                    <div
                      className="eventdivbulletinfix"
                    // style={{ marginBottom: "9px" }}
                    >
                      <div className="head">
                        <h2>{item.eventName}</h2>
                      </div>
                      <div className="bulletinplus">
                        <div style={{ fontSize: "12px", position: "relative", top: "-3px", left: "-3px" }}>
                          {item?.isHighLighted === "yes" ? <i class="fa-solid fa-plus"></i> : ""}
                        </div>
                        {userData?._id === item?.addedBy || userData?.userType==="Owner" ? (
                          <div
                            className=""
                            onClick={(e) => handleClick(e, item, index)}
                            style={{
                              position: "relative",
                              zIndex: "2",
                              left: "3px",
                              top: "-3px",
                            }}
                          >
                            <a to="/" className="btnmainthredot">
                              <i className="fa-solid fa-ellipsis-vertical"></i>
                            </a>
                            {modalId === item?._id && modalA && (
                              <div
                                style={{
                                  position: "absolute",
                                  // overflow: "hidden",
                                  backgroundColor: "#fff",
                                  padding: "3px",
                                  border: "none",
                                  // top: "123px",
                                  padding: "3px 3px",
                                  right: "16px",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                  boxShadow: "0 0 0.1rem 0",
                                  top: "2px",
                                  // width: "60px",
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                {/* <i
                                  style={{ marginRight: "7px", fontSize: "14px" }}
                                  class="fas fa-edit"
                                  onClick={() => setModalG(true)}
                                ></i>
                                <i
                                  style={{
                                    margin: "3px",
                                    fontSize: "14px",
                                    color: "red",
                                  }}
                                  class="fa fa-trash"
                                  aria-hidden="true"
                                  onClick={handleDelete}
                                ></i> */}
                                <div className="editdltdivmainflx">
                              <div className="editimagemain"     onClick={() => setModalG(true)}   >
                                <img src={Edit} alt="..." />
                              </div>
                              <div className="editimagemain"      onClick={handleDelete}>
                                <img src={Delete} alt="..." />
                              </div>
                              </div>
                              </div>
                            )}
                          </div>
                        )
                        :
                        <></>
                      }
                      </div>
                    </div>
                    {/* <div className="hosted">
                      <p>Hosted by {item.hostedBy}</p>
                    </div> */}

                    <div className="d-flex justify-content-between">
                      <div className="date">
                        <p>
                          Date: {moment(item?.eventDate).format("YYYY-MM-DD")}
                        </p>
                      </div>
                      {/* <div className="time">
                      <p>Time: 5:30 PM </p>
                    </div> */}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No data to show!</p>
          )}
        </div>
      </div>

    </>
  );
}
