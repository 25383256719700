import React, { useState, useEffect } from "react";
import "./Modal2.css";
import { useDispatch } from "react-redux";
import { ApiHelperFunction, fileUpload } from "../../services/api/apiHelpers";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useAuthCtx } from "../../context/AuthCtx";
import { getBadgeData } from "../../redux/slices/badgeSlice";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();


function ProfilePageModal({ closemodal, activity, initialValues }) {
  const { setLoading, userData } = useAuthCtx();
  const [imageURL, setImageURL] = useState("");
  const dispatch = useDispatch();
  // const [localerror, setlocalerror] = useState("");
  // const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
  const [badgeName, setBadgeName] = useState([]);
  // const [empData, setempData] = useState([]);
  const [options, setOptions] = useState([]);
  const [email, setemail] = useState([]);


  const handleSelectChangeemail = selectedemail => {
    setemail(selectedemail ? selectedemail?.map(option => option.value) : []);
  }


  const getBadgeNameData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-all-badge`,
      method: "GET",
    });
    console.log("RESPONSEFgb", response?.data?.data);
    if (response && response.status) {
      setBadgeName(response?.data?.data);
    } else {
      // toast.error(response.error);
    }
  };

  // console.log("njkvnfjn", badgeName)

  // const getEmployeeData = async () => {
  //   const response = await ApiHelperFunction({
  //     urlPath: `/view-all-employees`,
  //     method: "GET",
  //   });
  //   // console.log("RESPONSEF", response?.data?.data);
  //   if (response && response.status) {
  //     setempData(response?.data?.data);
  //   } else {
  //     // toast.error(response.error);
  //   }
  // };
// console.log("userData",userData)

  const getEmployeeData = async () => {
    if (userData?.userType == "Owner") {
      const res = await ApiHelperFunction({
        urlPath: `/view-all-employees`,
        method: "GET",
      });
      let apiData = []
      if (res && res?.status) {

        apiData = res?.data?.data?.map((item) => ({
          label: `${item.email}`,
          value: `${item.email}`,

        }));
      }
      setOptions(apiData);
    }

    if (userData?.userType == "Admin") {
      const res = await ApiHelperFunction({
        urlPath: `/view-all-user-under-hr/${userData?._id}`,
        method: "GET",
      });
      let apiData = []
      if (res && res?.status) {
        console.log("uiphvbdf",res)

        apiData = res?.data?.data?.map((item) => ({
          label: `${item.email}`,
          value: `${item.email}`,

        }));
      }
      setOptions(apiData);
    }

  }



  useEffect(() => {
    getBadgeNameData();
    getEmployeeData();
  }, [])




  // const getNameFromUserId = (selectedUserId) => {
  //   const selectedEmployee = empData.find(
  //     (employee) => employee._id === selectedUserId
  //   );
  //   return selectedEmployee ? selectedEmployee.userName : "";
  // };

  // console.log("pppmmmkkk", badgeName);

  const submitHandler = async (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    setShouldValidateOnBlur(true);

    let data = {};
    setLoading(true);
    if (values.badgeID == "" || values.desc == "" || email == "") {
      return toast.error("All fields required");
    }

    data = {
      badgeID: values?.badgeID,
      desc: values?.desc,
      emails: email,
      // image: imageURL,
    };
    console.log(data, "responser");
    // return false;
    const res = await ApiHelperFunction({
      urlPath: "/send-badge",
      method: "POST",
      data,
    });

    if (res?.status) {
      toast.success(res?.data?.message);
      resetForm();
      dispatch(getBadgeData());
      closemodal();
    } else {
      toast.error(res?.message || "Something went wrong");
      // console.log("ERROR CREATING USER3", res);
    }

    setLoading(false);
  };

  const schemaCheck = Yup.object(
    activity === "billBoard" ||
      activity === "events" ||
      activity === "kababMenu" ||
      activity === "editEvent"
      ? {
        eventName: Yup.string().required("Event Name is required"),
        hostedBy: Yup.string().required("Host name is required"),
        eventDate: Yup.string().required("Event date is required"),
        eventstarttime: Yup.string().required("start Time is required"),
        eventendtime: Yup.string().required(" End Time is required"),
        notes: Yup.string().required(" Notes is required"),
        addinvites: Yup.string().required(" Addinvites is required"),
        lattitude: Yup.string().required(" lattitude is required"),

        longitude: Yup.string().required(" longitude is required"),
      }
      : activity === "training" || activity === "editTraining"
        ? {
          name: Yup.string().required("Event Name is required"),
          trainingDate: Yup.string().required("Training date is required"),
        }
        : activity === "badge" || activity === "editBadge"
          ? {
            name: Yup.string().required("Event Name is required"),
            desc: Yup.string().required("Description is required"),
          }
          : activity === "jobReffered"
            ? {
              name: Yup.string().required("Job Name is required"),
              location: Yup.string().required("Job location is required"),
              salary: Yup.string().required("Salary range is required"),
              description: Yup.string().required("Description range is required"),
              opening: Yup.string().required("Opening is required"),
            }
            : ""
  );

  // const handleImageChange = async (e) => {
  //   let image = e.target.files[0];
  //   setUploading(true);

  //   const form = new FormData();
  //   form.append("image", image);

  //   let res = await fileUpload("/image-upload", "POST", form);

  //   if (res.status) {
  //     toast.success("Image uploaded successfully");
  //     setImageURL(res?.image);
  //   } else {
  //     toast.error("Error uploading image");
  //   }
  //   setUploading(false);
  // };

  const { values,  handleBlur, resetForm, handleChange } =
    useFormik({
      initialValues,
      validationSchema: schemaCheck,
      validateOnChange: shouldValidateOnChange,
      validateOnBlur: shouldValidateOnBlur,
      onSubmit: (val) => {
        console.log("val", val);
      },
    });

    const handleBadgeChange = (e) => {
      const selectedBadgeID = e.target.value;
      const selectedBadge = badgeName?.find(badge => badge._id === selectedBadgeID);
    
      setImageURL(selectedBadge?.image || ""); 
      handleChange(e); 
    };

  return (
    <section className="hallOfFrmeModal">
      <div className="hallOfFrameInner">
        <p className="newBulleBrodHead">Send Badge</p>
        <button className="bulleBrodCloseBtn" onClick={() => closemodal()}>
          <i class="fa-solid fa-xmark"></i>
        </button>
        {/* <div className="mb-3 mt-3">
          <p className="empListSubHead">Badge Name</p>
          <input
            type="text"
            className="empListSelect"
            placeholder="Badge Name"
            value={values?.name}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div> */}

        <div className="mb-3">
          <p className="empListSubHead">Badge Name</p>
          <select
            class="empListSelect"
            aria-label="Select Badge Name"
            name="badgeID"
            value={values?.badgeID}
            onBlur={handleBlur}
            onChange={handleBadgeChange}
          >
            <option value={""} disabled>
              Select Badge Name
            </option>
            {badgeName?.map((item, i) => (
              <option key={i} value={item?._id}>
                {item?.badgename}
              </option>
            ))}
          </select>
        </div>


        {/* Employee Email_id  */}
        <div className='mb-3'>
          <p className="empListSubHead">
            Employee Email_id:
          </p>
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultValue={[]}
            isMulti
            options={options}
            onChange={handleSelectChangeemail}
            value={options?.filter(option => email?.includes(option.value))}
          />
        </div>


        <div className="mb-3">
          <p className="empListSubHead">Reason of achievement</p>
          <input
            type="text"
            className="empListTextArea"
            placeholder="Reason"
            value={values?.desc}
            name="desc"
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>

        <div>
        {imageURL !== "" && (
              <div style={{ display: "flex" }}>
                <img
                  style={{ marginTop: "1rem" }}
                  src={imageURL}
                  alt=""
                  height="100rem"
                  width="100rem"
                />
               
              </div>
            )}
          </div>

        {/* <div className="emplListDiv">
          <p className="empListSubHead">Employee Email_id :</p>
          <select
            class="empListSelect"
            aria-label="Select E-mail"
            name="email"
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
          >
            <option value={""} disabled>
              Select Employee
            </option>
            {empData?.map((item, i) => (
              <option key={i} value={item?._id}>
                {`${item?.email}`}
              </option>
            ))}
          </select>
        </div> */}

        {/* <div className="mb-3">
          <p className="empListSubHead">Badge Image</p>

          <div className="homePgModInnerInpDivs">
            <input
              type="file"
              id="hostedBy"
              placeholder="Choose a photo"
              value={image}
              accept="image/*"
              name="hostedBy"
              onBlur={handleBlur}
              onChange={handleImageChange}
            />
            <small id="emailHelp" style={{ color: "red" }}>
              {errors?.image}
            </small>
            {uploading ? <p>image uploading......</p> : null}
            {imageURL !== "" && (
              <div style={{ display: "flex" }}>
                <img
                  style={{ marginTop: "1rem" }}
                  src={imageURL}
                  alt=""
                  height="100rem"
                  width="100rem"
                />
                <div
                  onClick={() => {
                    setImageURL("");
                    setImage("");
                  }}
                >
                  <i
                    class="fa-solid fa-xmark"
                    style={{
                      color: "red",
                      fontSize: "2rem",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </div>
            )}
          </div>
        </div> */}

        <div className="homePgCreModSubmitDiv">
          <button
            className="homePgCreModSubmitBtn"
            type="button"
            onClick={submitHandler}
          >
            Send
          </button>
        </div>
      </div>
    </section>
  );
}

export default ProfilePageModal;
