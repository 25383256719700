import React, { useEffect, useState } from 'react'
import SearchHandlens from "../../Images/Icons/PNG/Search Hand lens.png";
import imgaelitedvbgmain from "../../Images/imgaelitedvbgmain.png"
import DocumentRepositorycategory from './DocumentRepositorycategory';

import Recentdoc from './Recentdoc';
import Createdocument from './Createdocument';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { useAuthCtx } from '../../context/AuthCtx';
const Documentrepository = () => {
    const [deptData, setDeptData] = useState([]);
    const { userData } = useAuthCtx();

    //for getting all department data
    const getDepartmentCategoryData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/get-documents-category`,
            method: "GET",
        });
        // console.log("RESPONSEF", response?.data?.data);
        if (response && response.status) {
            setDeptData(response?.data?.data);
        } else {
            // toast.error(response.error);
        }
    };

    useEffect(() => {
        getDepartmentCategoryData();
    }, [])

    return (
        <>
            <section className='documentrepositorymain'>
                <div className='employeepagecontainer'>
                    <div className='documentrepositoryflx'>
                        <div className='documentrepositoryright'>

                            <div className="documentrepositorysrchdiv">
                                <div className='documntrepodivmainsrch'>
                                    <input type='text' className='serchdivdctxtsrc' placeholder='Search in Document Repository 
' />
                                    <div className='serchiconimg'>
                                        <img src={SearchHandlens} alt="..." />
                                    </div>

                                </div>
                            </div>
                            {/* <div className='documentbigsldimage'>
                                <div className='imgaelitedvbgmainbgaft'>

                                    <>
                                        {userData?.coverImage && (
                                            <div className='imgaelitedvbgmainbgaft'>
                                                <img src={userData?.coverImage} alt="..." />
                                            </div>
                                        )}
                                    </>
                                    <div className='docuemtslidetextpdiv'>
                                        
                                    </div>
                                </div>
                            </div> */}
                            <div className='documentRepositorycategorydiv'>
                                <div className='documentRepositorycategoryflx'>
                                    <DocumentRepositorycategory deptData={deptData} />
                                </div>
                            </div>

                        </div>
                        <div className='documentrepositoryleftt'>
                            <Createdocument />
                            {/* */}
                            <Recentdoc />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Documentrepository
