import React, { useEffect, useState } from 'react'
import Ongoing from './Ongoing'
import EducateStatusCard from './EducateStatusCard'
import trainingmain from "../../Images/trainingmain.png"
import StandardOperatingProsedure from "./StandardOperatingProsedure"
import star from "../../Images/Icons/PNG/star.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward.png";
import MandatoryCousremodal from '../../Component/Modal/MandatoryCousremodal'
import { getOnboardingNontechnicalTraining, getOnboardingTraining } from '../../redux/slices/jobRecruitmentSlice'
import { useDispatch, useSelector } from 'react-redux'
import NontechnicalCousremodal from '../../Component/Modal/NontechnicalCousremodal'


const SingleNontechnicalCourse = () => {
    const mandatory = useSelector((state) => state?.jobRecruitmentSlice?.nontechnicalTraining)
    const [singleMandatory, setSingleMandatory] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOnboardingNontechnicalTraining())
    }, [])
    const [videotraing, setVideotraing] = useState(false)
    const mandatorycontent = [
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
        {
            text: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
            image: trainingmain,
            writter: "Dr. Angela Yu",
            imgaestar: star,
            imageclock: clock,
            imagereawrd: reward,
            rating: "4.9",
            hrs: "1h 20m",
            reward: 200,

        },
    ]
    const handlevideoClick = (item) => {
        setSingleMandatory(item)
        setVideotraing(!videotraing)
    }
    return (
        <>
            <section className='singlemandatorysection'>
                <div className='employeepagecontainer'>
                    <div className='singleeducatemainflx'>
                    <div className='singlemandatoryright'>
                            <div className='singlemandatorydivbg'>
                                <div className='mandatorydivbgflxdiv'>
                                    {mandatory?.map((item, index) => {
                                        return (
                                            <div className='mandatorydivbg mandatorywidthdiv' key={index} onClick={() => handlevideoClick(item)}>
                                                {
                                                    (item?.image === "images" || item?.image === "" || item?.image === "image") ? (<div className='traingmaindivmain'>
                                                        <img src={trainingmain} alt="..." />
                                                    </div>) : (<div className='traingmaindivmain'>
                                                        <img src={item?.image} alt="..." />
                                                    </div>)
                                                }
                                                <div className=''>
                                                    <p className='mandttxtpp'>
                                                        {item?.description}
                                                    </p>
                                                    <p className='writertxtpp'>{item?.userName}</p>
                                                </div>
                                                <div className="imgtxtdivmaindddflx">
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgdiv">
                                                            <img
                                                                src={star}
                                                                alt="..."
                                                            />
                                                        </div>
                                                        <p className="tsgyutppp">{item.rating ? (<>{item.rating}</>) : ("0")}</p>
                                                    </div>
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgdiv">
                                                            <img
                                                                src={clock}
                                                                alt="..."
                                                            />
                                                        </div>
                                                        {/* {item?.videos?.[0]?.videoDuration ? (<p className="tsgyutppp">{item?.videos?.[0]?.videoDuration?.hour ? (item?.videos?.[0]?.videoDuration?.hour) : ("00")} h {item?.videos?.[0]?.videoDuration?.min ? (item?.videos?.[0]?.videoDuration?.min) : ("00")}min</p>) : (<p className="tsgyutppp">{item?.placeTime?.[0]?.duration?.hour ? (item?.placeTime?.[0]?.duration?.hour) : ("00")} h {item?.placeTime?.[0]?.duration?.min ? (item?.placeTime?.[0]?.duration?.min) : ("00")}min</p>)} */}
                                                        {item?.totalDurationInMinute} min
                                                    </div>
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgfrmt">
                                                            <img
                                                                src={reward}
                                                                alt="..."
                                                            />
                                                        </div>
                                                        <p className="tsgyutppp">{item.trainingReward}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>

                            </div>
                        </div>
                        <div className='singlemandatoryleft'>
                            <div className=''>
                                <EducateStatusCard />
                            </div>
                            <div className=''>
                                <Ongoing />
                            </div>
                            <div className=''>
                                <StandardOperatingProsedure />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            {videotraing && <NontechnicalCousremodal closemodal={setVideotraing} singleMandatory={singleMandatory} />}
        </>
    )
}

export default SingleNontechnicalCourse

