import React, { useEffect, useState } from 'react'
import trainingmain2 from "../../Images/trainingmain2.png";
import Points from "../../Images/Icons/PNG/Points.png"
import { useDispatch, useSelector } from 'react-redux';
import { getOnGoingTraining } from '../../redux/slices/jobRecruitmentSlice';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { useNavigate } from 'react-router-dom';


const EducateOngoing = () => {
    const ongoing = useSelector((state) => state?.jobRecruitmentSlice?.ongoing)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // console.log("clearOnGoing", ongoing)
    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const [empData, setempData] = useState([]);

    //for getting internal trainer name
    const getEmployeeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-company-employees`,
            method: "GET",
        });
        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
            }));
            setColourOptions(formattedOptions);
            setempData(response?.data?.data);
        } else {

        }
    };


    //for play button
    const handlevideoClick = (item) => {
        navigate(`/enlargedNewEnrollment/${item?.trainingId?.title?.replace(/\s+/g, '-')}/${item?.trainingId?._id}`)
        // navigate(`/coursefirstVideoPlayer/${item?.title?.replace(/\s+/g, '-')}/${item?._id}/${item?.videos?.[0]?._id}`)
    }

    // useEffect(() => {
    //     const data = singleMandatory?.mentor
    //     const mentorData = data?.map((item) => {
    //         const optData = colourOptions?.find(ele => ele?.value === item)
    //         return optData ? optData : {}
    //     })
    //     setSelectedOptions(mentorData)
    // }, [colourOptions])


    useEffect(() => {
        getEmployeeData();
        dispatch(getOnGoingTraining());
    }, [])

    const educateongoing = [
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,

        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,


        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,


        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,


        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,


        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,


        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,


        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,


        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,


        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,


        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,


        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "3 New Notifications",
            percentage: "75",
            points: "100",
            imagepoints: Points,

        },
    ];
    const ongoingdata = ongoing?.slice(0, 4)

    // const cousrevideofirstonClick = () => {
    //     const data = {
    //         selectedOptions: selectedOptions,
    //         singleMandatory: singleMandatory
    //     }
    //     // console.log("playyyy", singleMandatory)
    //     navigate("/coursefirstVideoPlayer", { state: data })
    // }


    return (
        <>
            {ongoingdata?.map((item, index) => {
                return (
                    <div className='videoongoinddiv' key={index}>
                        <div className=''>
                            <div className='video_onflx'>
                                <div className='trainingmainimgdiv' >
                                    <img src={item?.trainingId?.image} alt="..." />
                                </div>
                                <div className='playtextbtnvwwh'>
                                    <div className='playtxtflx'>
                                        <p className='lrmtxtppeducate'>{item?.trainingId?.title}</p>
                                        <div className='' onClick={() => handlevideoClick(item)}>
                                            <button className='bg-play'>
                                                Play
                                            </button>
                                        </div>

                                    </div>
                                    {/* <div className=''>
                                        <p className="dotstxtpp"><span className="dotsdiv"></span>{item.notification}</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="prgrsbartxtdivpading">
                            <div className='prgrsbartxtdiv'>
                                <div className=''>
                                    <p className='prgrstextper'>{item?.percentage}%</p>
                                </div>
                                <div className='imgtxtppdivprgrs'>
                                    <div className='prgrsdivimg'>
                                        <img src={item?.imagepoints} alt="..." />
                                    </div>
                                    <p className='prgrstextper'>{item?.points}</p>
                                </div>
                            </div>

                            <div className="borderprgs">
                                <div className="brdrgrey" style={{ height: "6px", width: "40%" }}>
                                </div>
                            </div>
                        </div> */}
                    </div>
                )
            })}

        </>
    )
}

export default EducateOngoing
