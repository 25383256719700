import React, { useEffect, useState } from 'react'
import mandatory from "../../Images/Icons/PNG/mandatory.png";
import certificate from "../../Images/certificate.png";
import user_image from "../../Images/user_image.png";
import Rewardplati from "../../Images/Icons/PNG/Reward - Platinum.png";
import expand from "../../Images/Icons/PNG/Expand.png";
import star from "../../Images/Icons/PNG/star.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward.png";
import trainingmain1 from "../../Images/trainingmain1.png";
import { useParams } from 'react-router-dom';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { toast } from 'react-hot-toast';
import Congratulationmodal from '../../Component/Modal/Congratulationmodal';
import Reytakequizemodal from '../../Component/Modal/Reytakequizemodal';

const Feedbackquize = () => {
    const params = useParams();
    const [trainingQuiz, settrainingQuiz] = useState({});
    const [singleView, setSingleView] = useState([]);
    const [congratulationmodala, setCongratulationmodala] = useState(false);
    const [retakequizemoadl, setRetakequizemoadl] = useState(false);
    const [certificateUrl,setCertificateUrl]=useState("");
    const [percentData,setPercentData]=useState("");

    //for view Single Page
    const viewQuiz = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/get-training-quiz/${params?.id}`,
            method: "GET",
        });
        if (response?.status) {
            setSingleView(response?.data?.data);
        } else {

        }
    }


    // for checkbox and radio button
    const handleChangeTrainingQuiz = (questionId, value) => {
        settrainingQuiz(prevState => ({
            ...prevState,
            [questionId]: value
        }));
    };


    //for view Single Page
    const SubmitQuiz = async () => {

        let data = {
            data: Object.keys(trainingQuiz)?.map(questionId => ({
                trainingId: params?.id,
                questionId: questionId,
                answerGiven: trainingQuiz[questionId]
            }))
        };
        // console.log("trainingId", data);
        // return false;
        const response = await ApiHelperFunction({
            urlPath: `/take-quiz`,
            method: "POST",
            data
        });

        if (response?.status) {
            toast?.success(response?.data?.message)
            settrainingQuiz({});
            // setQuizPercent(response?.data?.percent)
            setPercentData(response?.data?.percent)
            if (response?.data?.percent >= 80) {
                setCongratulationmodala(true);
                setCertificateUrl(response?.data?.certificateUrl)
            }
            if (response?.data?.percent < 80) {
                setRetakequizemoadl(true);
            }

        } else {
            toast?.error(response?.response?.data?.message)
        }
        // setCongratulationmodala(!congratulationmodala)
    }


    useEffect(() => {
        viewQuiz();
    }, [params?.id])


    return (
        <>
            <section className='feedbackquizesection'>
                <div className='employeepagecontainer'>
                    <div className='feedbackquizesectionflx'>
                        <div className='feedbackquizesectionleft'>
                            <div className='cmpltquzwdivbg'>
                                <div className='top'>
                                    <div className='quizeimgtxtflx'>
                                        <div className='mandatorydivimgquz'>
                                            <img src={mandatory} alt="..." />
                                        </div>
                                        <p className='cmpltquzetxt'>Complete Your Quize</p>
                                    </div>
                                </div>
                                <div className='bottom'>
                                    <ul className='feedbnckjhsdivul'>
                                        {
                                            singleView?.map((item, ind) => {
                                                return (
                                                    <div className=''>

                                                        <li className='feedbnckjhsdivli'>
                                                            <div className='quizedivwidthf'>
                                                                <p className='questiontxtquize'>{item?.question}</p>
                                                            </div>
                                                            <div className='quizeanswersdiv'>
                                                                {item?.quizType === "checkBox" ? (
                                                                    <>
                                                                        <div className='cjhekeddivtxt'>
                                                                            <input
                                                                                type='checkbox'
                                                                                value="A"
                                                                                checked={trainingQuiz[item?._id] === "A"}
                                                                                onChange={(e) => handleChangeTrainingQuiz(item?._id, e.target.value)}
                                                                            />
                                                                            <label>A. {item?.A}</label>
                                                                        </div>
                                                                        <div className='cjhekeddivtxt'>
                                                                            <input
                                                                                type='checkbox'
                                                                                value="B"
                                                                                checked={trainingQuiz[item?._id] === "B"}
                                                                                onChange={(e) => handleChangeTrainingQuiz(item?._id, e.target.value)}
                                                                            />
                                                                            <label>B. {item?.B}</label>
                                                                        </div>
                                                                        <div className='cjhekeddivtxt'>
                                                                            <input
                                                                                type='checkbox'
                                                                                value="C"
                                                                                checked={trainingQuiz[item?._id] === "C"}
                                                                                onChange={(e) => handleChangeTrainingQuiz(item?._id, e.target.value)}
                                                                            />
                                                                            <label>C. {item?.C}</label>
                                                                        </div>
                                                                        <div className='cjhekeddivtxt'>
                                                                            <input
                                                                                type='checkbox'
                                                                                value="D"
                                                                                checked={trainingQuiz[item?._id] === "D"}
                                                                                onChange={(e) => handleChangeTrainingQuiz(item?._id, e.target.value)}
                                                                            />
                                                                            <label>D. {item?.D}</label>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className='radiobtntxtpp'>
                                                                            <input
                                                                                type="radio"
                                                                                value="A"
                                                                                checked={trainingQuiz[item?._id] === "A"}
                                                                                onChange={(e) => handleChangeTrainingQuiz(item?._id, e.target.value)}
                                                                            />
                                                                            <label>Yes</label>
                                                                        </div>
                                                                        <div className='radiobtntxtpp'>
                                                                            <input
                                                                                type="radio"
                                                                                value="B"
                                                                                checked={trainingQuiz[item?._id] === "B"}
                                                                                onChange={(e) => handleChangeTrainingQuiz(item?._id, e.target.value)}
                                                                            />
                                                                            <label>No</label>
                                                                        </div>
                                                                    </>
                                                                )}


                                                            </div>
                                                        </li>



                                                    </div>
                                                )
                                            })

                                        }
                                    </ul>
                                    <button type='button' onClick={SubmitQuiz} className='sbmtdbtbhmaiondiv'>Submit</button>

                                    {/* <div className='quizeanswersflx'>
                                        <div className='quizedivwidthf'>
                                            <p className='questiontxtquize'>What you understand by this ?</p>
                                        </div>
                                        <div className='quizeanswersdiv'>
                                            <textarea id="" name="" rows="4" cols="50">

                                            </textarea>
                                        </div>
                                    </div> */}

                                    {/* <div className='inputchckbtndivflx'>
                                        <div className='inpttxtchcktxt'>
                                            <input type="checkbox" name="" value="" />
                                            <label for="vehicle1">I understood the guidelines and the following questions and answered them
                                            </label>
                                        </div>
                                        <div className=''>
                                            <button className='savebtnchngbtn'>Save Changes</button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            {/* <div className='cmpltquzwdivbg'>
                                <div className='top'>
                                    <div className='quizeimgtxtflx'>
                                        <div className='mandatorydivimgquz'>
                                            <img src={mandatory} alt="..." />
                                        </div>
                                        <p className='cmpltquzetxt'>Rate Your Session</p>
                                    </div>
                                </div>
                                <div className='bottom'>
                                    <div className='sessionflxdivstr'>
                                        <div className=''>
                                            <p className='textmuchppstsfy'>How much satisfied are you with your Session?
                                            </p>
                                        </div>
                                        <div className='starpppicon'>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </div>
                                    </div>
                                    <div className='quizebtnmaiondiv'>
                                        <button className='quizebtnmaion'>Finish Your Quize</button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className='feedbackquizesectionright'>
                            <div className='certificatediv'>
                                <div className='top'>
                                    <div className='certificatedivflx'>
                                        <div className='mandatorydivimgquz'>
                                            <img src={mandatory} alt="..." />
                                        </div>
                                        <p className="cmpltquzetxt">Your Certificate</p>
                                    </div>
                                </div>
                                <div className='buttom'>
                                    <div className='certificatedivimage'>
                                        <img src={certificate} alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className='badgedivsectn'>
                                <div className='top'>
                                    <div className='badgedivsectnflx'>
                                        <div className='mandatorydivimgquz'>
                                            <img src={mandatory} alt="..." />
                                        </div>
                                        <p className="cmpltquzetxt">Your Certificate</p>
                                    </div>
                                </div>
                                <div className='buttom'>
                                    <div className='badgedivmaintwflx'>
                                        <div className='badgedivmainbrdr'>
                                            <div className='badgedivmainbrdrdiv'>
                                                <img src={user_image} alt="..." />
                                            </div>
                                        </div>

                                        <div className='Rewardplatiinvimg'>
                                            <img src={Rewardplati} alt="..." />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='upnextdiv'>
                                <div className='top'>
                                    <div className='badgedivsectnflx'>
                                        <div className='mandatorydivimgquz'>
                                            <img src={mandatory} alt="..." />
                                        </div>
                                        <p className="cmpltquzetxt">Up Next</p>
                                    </div>
                                    <div className='expandimgdiv'>
                                        <img src={expand} alt="..." />
                                    </div>
                                </div>
                                <div className='buttom'>
                                    <div className='mandatorydivbg'>
                                        <div className='traingmaindivmain'>
                                            <img src={trainingmain1} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='mandttxtpp'>
                                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!
                                            </p>
                                            <p className='writertxtpp'>Dr. Angela Yu</p>
                                        </div>
                                        <div className="imgtxtdivmaindddflx">
                                            <div className="imgtxtdivmainddd">
                                                <div className="strimgdiv">
                                                    <img
                                                        src={star}
                                                        alt="..."
                                                    />
                                                </div>
                                                <p className="tsgyutppp">4.9</p>
                                            </div>
                                            <div className="imgtxtdivmainddd">
                                                <div className="strimgdiv">
                                                    <img
                                                        src={clock}
                                                        alt="..."
                                                    />
                                                </div>
                                                <p className="tsgyutppp"> 1h 20m</p>
                                            </div>
                                            <div className="imgtxtdivmainddd">
                                                <div className="strimgfrmt">
                                                    <img
                                                        src={reward}
                                                        alt="..."
                                                    />
                                                </div>
                                                <p className="tsgyutppp">200</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* Congratulationmodal design. If candidate successfully submitted the answers then this modal is show */}
            {congratulationmodala && <Congratulationmodal closemodal={setCongratulationmodala} certificateUrl={certificateUrl} percentData={percentData}/>}



            {/* Reytakequizemodal design. If candidate can not successfully submitted the answers then this modal is show */}
            {retakequizemoadl && <Reytakequizemodal closemodal={setRetakequizemoadl} />}
        </>
    )
}

export default Feedbackquize
