import React, { useEffect, useState } from "react";
import "../Foldable Login & Registration form/style.css";
// import frontImage from "./Images/frontImg.jpg";
// import backImage from "./Images/backImg.jpg";
import { useAuthCtx } from "../context/AuthCtx";
import { useFormik } from "formik";
// import companyname from "../../src/Images/companyname.jpeg"
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { clearLogin, loginSuccess } from "../redux/slices/loginSlice";
// import employeeexperiencehub from "../Images/employeeexperiencehub.png";
// import logipageimage from "../Images/logipageimage.png"
import logimg from "../Images/LogNewPic.png"
import logogo from "../Images/logcompany.png"
import btmlogo from "../Images/bottomlogo.png"
import Hide from "../Images/headericon/Hide.png"
import Show from "../Images/headericon/View.png"
import { ApiHelperFunction } from "../services/api/apiHelpers";


const ForgotPassWord = () => {
  const navigate = useNavigate();
  const { forgetPassword, loading, setLoading } = useAuthCtx();
  const [forgetPassw, setForgetPassword] = useState(null);
  // const dispatch = useDispatch();

  const initialValues = {
    email: "",
    // otp: forgetPassw,
    newPassword: ""
  };

  const { values, handleBlur, handleSubmit, handleChange } = useFormik({
    initialValues,
    onSubmit: (e) => {
      handleFormSubmit(e);
    },
  });
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  //for otp send
  const handleForgetPass = async () => {
    let data = {
      email: values?.email
    }
    const response = await ApiHelperFunction({
      urlPath: "/forget-password",
      method: "PUT",
      data
    });

    if (response.status === 200) {
      toast?.success(response?.data?.message)
      setForgetPassword(response?.data?.data?.otp)

      // navigate('/forgotPass')
    } else {

    }
  }
  useEffect(() => {
    handleForgetPass();
  }, [])


  //for Submit Functionality
  const handleFormSubmit = async (e) => {
    if (loading) return;

    let data = values;
    data.otp = forgetPassw;
    setLoading(true);
    const res = await forgetPassword(data);
    if (res?.status) {
      toast?.success(res?.data?.message);
      navigate("/");
    } else {
      toast?.error(res?.data?.message || "Something went wrong");
    }

  };


  return (
    <div className="container-parent">

      {/****shiltu start***/}
      <div className="loginsection_wrap">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="loginimg_wrap">
              <img src={logimg} className="img-fluid" alt="image" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="logcontent_wrap">

              <div className="responsivelogogo_design">
                <div className="logogo_icon">
                  <img src={logogo} className="img-fluid" alt="..." />
                </div>
              </div>

              <div className="rightcontentbox">
                <h4 className="heading">Forget Password</h4>

                <form action="/" onSubmit={handleSubmit}>

                  <div className="form-group">
                    <label htmlFor="InputEmail1">Email Id</label>
                    <input
                      name="email"
                      onBlur={handleBlur}
                      value={values?.email}
                      onChange={handleChange}
                      type="email"
                      className="form-control"
                      id="InputEmail1"
                      aria-describedby="emailHelp"
                      placeholder=""
                      required
                    />

                  </div>

                  <button onClick={handleForgetPass} className="forgot_btn">Send OTP</button>

                  <div className="form-group">
                    <label htmlFor="InputEmail1">OTP</label>
                    <input
                      name="otp"
                      onBlur={handleBlur}
                      value={forgetPassw}
                      // onChange={handleChange}
                      type="number"
                      className="form-control"
                      id="InputEmail1"
                      aria-describedby="emailHelp"
                      placeholder=""
                      required
                    />

                  </div>

                  {/* <div className="form-group"> */}
                  <div className="form-group">
                    <label htmlFor="Inputpassword">New Password</label>
                    <div className="inputcompanydtls">
                      <input
                        type={showPassword ? "text" : "password"}
                        onBlur={handleBlur}
                        value={values?.newPassword}
                        onChange={handleChange}
                        name="newPassword"
                        className="form-control"
                        id="Inputpassword"
                        placeholder=""
                        required
                      />
                      <div className='Hideimgdivicn' onClick={togglePasswordVisibility}>
                        <img src={showPassword ? Show : Hide} alt="..." />
                      </div>
                    </div>

                  </div>

                  {/* <div className="checkbox_area">

                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="termsandcondition"
                        checked={agreeTerms}
                        onChange={() => setAgreeTerms(!agreeTerms)}
                      />
                      <label htmlFor="termsandcondition">I agree with the <Link>terms and conditions</Link></label>
                    </div>

                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="rememberPasswoard"
                        checked={rememberPassword}
                        onChange={() => setRememberPassword(!rememberPassword)}
                      />
                      <label htmlFor="rememberPasswoard">Remember Password</label>
                    </div>

                  </div> */}

                  <div className="submitarea_wrap">
                    <button  className="login_btn" type="submit" > Submit </button>
                    <Link to="/" className="forgot_btn">Go back</Link>
                  </div>

                </form>

                {/* <div className="advicetext">
                  <p>* still can't log in? <br /> <span>Contact Your Company HP.</span></p>
                </div> */}

                <div className="bottomlogo">
                  <div className="imgwrap">
                    <img src={btmlogo} className="img-fluid" alt=".." />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/****shiltu end***/}


    </div >
  );
};

export default ForgotPassWord;
