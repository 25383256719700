import React, { useState } from 'react'
import CalenderAttendence from './CalenderAttendence'
import Addprojectrightcomp from './Addprojectrightcomp';
import Attendenceworktable from './Attendenceworktable';
import { useNavigate } from 'react-router-dom';
import MyLeaves from './MyLeaves';
const MyLeavesmain = () => {
    const [attendencetab, setAttendencetab] = useState(false);
    const navigate = useNavigate()
    const handleaattendenceclick = () => {
        navigate("/attendence")
    }

    const handlemyprojectclick = () => {
        navigate("/myviewproject")
    }

    const handleprojectclick = () => {
        navigate("/managerviewproject")
    }
    const handleamyleaveclick = () => {
        navigate("/myleaves")
    }
    return (
        <section className='attendencemainsection'>
            <div className='employeepagecontainer'>
                <div className='attendencedivflx'>
                    <div className='attendencedivleft'>
                        <div className='projecttimesheetleavedivflx'>
                            <div className='projecttimesheetdivflx'>
                                <button className={attendencetab ? "tabattendiv actv" : "tabattendiv"} onClick={() => handlemyprojectclick()}>
                                    My Projects
                                </button>
                                <button className={attendencetab ? "tabattendiv actv" : "tabattendiv"} onClick={() => handleaattendenceclick()}>
                                    My TimeSheet
                                </button>
                            </div>
                            <div className=''>
                                <button className={attendencetab ? "tabattendiv actv" : "tabattendiv"} onClick={() => handleamyleaveclick()}>
                                    My Leaves
                                </button>
                            </div>
                        </div>
                        <Attendenceworktable />
                    </div>

                    <div className='attendencedivright'>

                        <div className='myleaveduvmain'>
                            <MyLeaves />
                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default MyLeavesmain
