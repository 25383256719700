import React, { useState } from 'react'
import CalenderAttendence from './CalenderAttendence'
import Addprojectrightcomp from './Addprojectrightcomp'
import { useNavigate } from 'react-router-dom';
// import Projectviewemplymanager from './Projectviewemplymanager';
import { useAuthCtx } from '../../context/AuthCtx';
import MyProjectView from './MyProjectView';

const MyViewProject = () => {
    const { userData } = useAuthCtx();
    const [attendencetab, setAttendencetab] = useState(false);
    const navigate = useNavigate()
    const handleaattendenceclick = () => {
        navigate("/attendence")
    }
    const handleprojectclick = () => {
        navigate("/managerviewproject")
    }
    const handlemyprojectclick = () => {
        navigate("/myviewproject")
    }
    const handleamyleaveclick = () => {
        navigate("/myleaves")
    }
    return (
        <section className='mangerviewsectionmain'>
            <div className='employeepagecontainer'>
                <div className='attendencedivflx'>
                    <div className='managercfdivrihght'>
                        <div className='projecttimesheetleavedivflx'>
                            <div className='projecttimesheetdivflx'>
                                <button className={attendencetab ? "tabattendiv actv" : "tabattendiv"} onClick={() => handlemyprojectclick()}>
                                    My Projects
                                </button>
                                <button className={attendencetab ? "tabattendiv actv" : "tabattendiv"} onClick={() => handleaattendenceclick()}>
                                    My TimeSheet
                                </button>
                            </div>
                            <div className='managervewbtnamnomddivflx'>
                                {
                                    userData?.userType === "Manager" &&
                                    <button className="managervewbtnamincnn" onClick={() => handleprojectclick()}>
                                        Manager View
                                    </button>
                                }

                                <button className={attendencetab ? "tabattendiv actv" : "tabattendiv"} onClick={() => handleamyleaveclick()}>
                                    My Leaves
                                </button>
                            </div>
                        </div>


                        <div className='managercardbgdivmaiundivflx'>

                            <MyProjectView />
                        </div>

                    </div>
                    <div className='managercfdivleft'>
                        <div className='calenderdivrightmain'>
                            <CalenderAttendence />
                        </div>
                        <div className='projectaddrihtdiv'>
                            <Addprojectrightcomp />
                        </div>
                    </div>
                </div>


            </div>
        </section>
    )
}

export default MyViewProject
