import React, { useRef, useState } from 'react'
import "./Training.css"
import systm from "../../Images/systm.png";
import lght from "../../Images/lght.png";
// import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import excel from "../../Images/excel.png"
import logosmicrosoftpowerbi from "../../Images/logosmicrosoftpowerbi.png";
import bi_save from "../../Images/bi_save.png"
import video from "../../Images/video/Intro-Short-Video-Edinboro-University.mp4";

const Training = () => {
    const [showmore, setShowmore] = useState(false);
    const [showmoreass, setShowmoreass] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const videoRef = useRef(null);
    const onTabClick = (e, index) => {
        console.log(e);
        setActiveTab(index);
    };
    const handleshowClick = () => {
        setShowmore(!showmore)
    }
    const handleshowassClick = () => {
        setShowmoreass(!showmoreass)
    }
    const [isplaying, setIsplaying] = useState(false);
    // const tabsArray = [...Array(20).keys()];


    const togglePlayPause = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsplaying(!isplaying)
        }
        else {
            videoRef.current.pause();
            setIsplaying(false)

        }
    }

    return (
        <>
            <section className='traingmain'>
                <div className='employeepagecontainer'>
                    <div className='traingsectionmainflx'>
                        <div className='trainingsectionfirst'>
                            <div className='mndlrning'>
                                <div className='mandtxtbg'>
                                    <p className='mndtxtpp'>Mandatory Learning</p>
                                </div>
                                <p className='recomendtxt'>
                                    Recommended Learning
                                </p>
                                <div className='skildivmain'>
                                    <p className='skltxtpp'>Skills</p>
                                    <div className=''>
                                        <div class="skldiv">
                                            <input type="checkbox" id="" name="" class="boxchckdiv" value="" />
                                            <label for="vehicle1">Accounting</label>
                                        </div>
                                        <div class="skldiv">
                                            <input type="checkbox" id="" name="" class="boxchckdiv" value="" />
                                            <label for="vehicle1">Advocacy + </label>
                                        </div>
                                        <div class="skldiv">
                                            <input type="checkbox" id="" name="" class="boxchckdiv" value="" />
                                            <label for="vehicle1">Business Analytics</label>
                                        </div>
                                        <div class="skldiv">
                                            <input type="checkbox" id="" name="" class="boxchckdiv" value="" />
                                            <label for="vehicle1">Economics</label>
                                        </div>
                                        <div className=''>
                                            {showmore && <div className=''>
                                                <div class="skldiv">
                                                    <input type="checkbox" id="" name="" class="boxchckdiv" value="" />
                                                    <label for="vehicle1">Accounting</label>
                                                </div>
                                                <div class="skldiv">
                                                    <input type="checkbox" id="" name="" class="boxchckdiv" value="" />
                                                    <label for="vehicle1">Advocacy + </label>
                                                </div>
                                                <div class="skldiv">
                                                    <input type="checkbox" id="" name="" class="boxchckdiv" value="" />
                                                    <label for="vehicle1">Business Analytics</label>
                                                </div>
                                                <div class="skldiv">
                                                    <input type="checkbox" id="" name="" class="boxchckdiv" value="" />
                                                    <label for="vehicle1">Economics</label>
                                                </div>
                                            </div>}
                                            <div className='shwarw' onClick={handleshowClick}>
                                                {/* <p className='shwtxtpp'>Show More</p> */}
                                                {showmore ? <p className='shwtxtpp'>Show Less</p> : <p className='shwtxtpp'> Show More</p>}
                                                <div className='arrwdiv' >
                                                    <span className={showmore ? "fa-solid fa-sort-up" : "fa-solid fa-sort-down"}></span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="assmntdiv">
                                <div className='assnmgttxtimgarw'>
                                    <div className='assnmgttxtimg'>
                                        <div className="assmntimg">
                                            <img src={systm}
                                                alt="" />
                                        </div>
                                        <div className=''>
                                            <p className='assntxt'>Assignment</p>
                                        </div>
                                    </div>
                                    <div className='arrwdivass' onClick={handleshowassClick}>
                                        <span className={showmoreass ? "fa-solid fa-sort-up" : "fa-solid fa-sort-down"}></span>
                                    </div>

                                </div>
                                {showmoreass &&
                                    <div className={showmoreass ? "showdetails" : "hidedetails"}>
                                        <p className='lrmtxtpp'>Lorem Ipsum</p>
                                        <p className='lrmtxtpp'>Lorem Ipsum</p>
                                        <p className='lrmtxtpp'>Lorem Ipsum</p>
                                        <p className='lrmtxtpp'>Lorem Ipsum</p>
                                    </div>
                                }
                            </div>
                            <div className='assmntdiv'>
                                <div className='assnmgttxtimg'>
                                    <div className="assmntimg">
                                        <img src={lght}
                                            alt="" />
                                    </div>
                                    <div className=''>
                                        <p className='assntxt'>Help Center </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='trainingsectionsecond'>
                            <div className='trainingsectionbothright'>
                                <div className='trainingsectionrightfrst'>
                                    <div className=''>
                                        <p className='upgrdrrtxt'>Upgrade Yourself to be the best in Industry</p>
                                        <div className='bg-whitedivflx'>
                                            <div className='bg-whitediv'>
                                                <div className='videomaindiv'>

                                                    <video poster width="100%" ref={videoRef}>
                                                        <source src={video} type="video/mp4"></source>
                                                    </video>
                                                    <div className="vdo_btn">
                                                        <div className="btn_main" onClick={togglePlayPause}>
                                                            {isplaying ? (
                                                                <i class="fa-solid fa-pause"></i>
                                                            ) : (
                                                                <i class="fa-solid fa-play"></i>
                                                            )}
                                                        </div>
                                                    </div>


                                                    <div className='timeperiodvdo'>
                                                        <p className='twhhrs'>2:00 Hrs</p>
                                                    </div>
                                                </div>
                                                <div className='taxtiondivmain'>
                                                    <div className='taxtionimgflx'>
                                                        <p className='txattxt'>Taxation</p>
                                                        <div className='taxtionimg'>
                                                            <img src={bi_save} alt="..." />
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <p className='ovrtxt'>Over the course you wil learn about taxation policies, entities, puposes.</p>
                                                    </div>
                                                    <div className='rtngstrflx'>
                                                        <p className='rtngtxstp'>Rating</p>
                                                        <div className='rngdivgh'>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='bg-whitediv'>
                                                <div className='videomaindiv'>

                                                    <video poster width="100%" ref={videoRef}>
                                                        <source src={video} type="video/mp4"></source>
                                                    </video>
                                                    <div className="vdo_btn">
                                                        <div className="btn_main" onClick={togglePlayPause}>
                                                            {isplaying ? (
                                                                <i class="fa-solid fa-pause"></i>
                                                            ) : (
                                                                <i class="fa-solid fa-play"></i>
                                                            )}
                                                        </div>
                                                    </div>


                                                    <div className='timeperiodvdo'>
                                                        <p className='twhhrs'>2:00 Hrs</p>
                                                    </div>
                                                </div>
                                                <div className='taxtiondivmain'>
                                                    <div className='taxtionimgflx'>
                                                        <p className='txattxt'>Taxation</p>
                                                        <div className='taxtionimg'>
                                                            <img src={bi_save} alt="..." />
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <p className='ovrtxt'>Over the course you wil learn about taxation policies, entities, puposes.</p>
                                                    </div>
                                                    <div className='rtngstrflx'>
                                                        <p className='rtngtxstp'>Rating</p>
                                                        <div className='rngdivgh'>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='bg-whitediv'>
                                                <div className='videomaindiv'>

                                                    <video poster width="100%" ref={videoRef}>
                                                        <source src={video} type="video/mp4"></source>
                                                    </video>
                                                    <div className="vdo_btn">
                                                        <div className="btn_main" onClick={togglePlayPause}>
                                                            {isplaying ? (
                                                                <i class="fa-solid fa-pause"></i>
                                                            ) : (
                                                                <i class="fa-solid fa-play"></i>
                                                            )}
                                                        </div>
                                                    </div>


                                                    <div className='timeperiodvdo'>
                                                        <p className='twhhrs'>2:00 Hrs</p>
                                                    </div>
                                                </div>
                                                <div className='taxtiondivmain'>
                                                    <div className='taxtionimgflx'>
                                                        <p className='txattxt'>Taxation</p>
                                                        <div className='taxtionimg'>
                                                            <img src={bi_save} alt="..." />
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <p className='ovrtxt'>Over the course you wil learn about taxation policies, entities, puposes.</p>
                                                    </div>
                                                    <div className='rtngstrflx'>
                                                        <p className='rtngtxstp'>Rating</p>
                                                        <div className='rngdivgh'>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='bg-whitediv'>
                                                <div className='videomaindiv'>

                                                    <video poster width="100%" ref={videoRef}>
                                                        <source src={video} type="video/mp4"></source>
                                                    </video>
                                                    <div className="vdo_btndiv">
                                                        <div className="btn_maindiv" onClick={togglePlayPause}>
                                                            {isplaying ? (
                                                                <i class="fa-solid fa-pause"></i>
                                                            ) : (
                                                                <i class="fa-solid fa-play"></i>
                                                            )}
                                                        </div>
                                                    </div>


                                                    <div className='timeperiodvdo'>
                                                        <p className='twhhrs'>2:00 Hrs</p>
                                                    </div>
                                                </div>
                                                <div className='taxtiondivmain'>
                                                    <div className='taxtionimgflx'>
                                                        <p className='txattxt'>Taxation</p>
                                                        <div className='taxtionimg'>
                                                            <img src={bi_save} alt="..." />
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <p className='ovrtxt'>Over the course you wil learn about taxation policies, entities, puposes.</p>
                                                    </div>
                                                    <div className='rtngstrflx'>
                                                        <p className='rtngtxstp'>Rating</p>
                                                        <div className='rngdivgh'>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className='trainingsectionrightscnd'>
                                    <div className='recomendeddiv'>
                                        <h4 className='rcttxtpp'>Recomandations</h4>

                                        <div className='bg-coloradvnc' style={{ backgroundColor: "#E0FFF2" }}>
                                            <div className='advnctxtimgflx'>
                                                <p className='advnctxt'>Advance Excel</p>
                                                <div className='exclimgdivmain'>
                                                    <div className='exclimgdiv'>
                                                        <img src={excel} alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='prgsrdivmain'>

                                                <div className='prgssxtyflx'>
                                                    <p className='prgstxtpp'>progress</p>
                                                    <p className='prgstxtpp'>60%</p>
                                                </div>
                                                <div className='prgsbar'>
                                                    <div className="w-border">
                                                        <div className="w3-grey" style={{ backgroundColor: "#02975A", width: "32%", height: "9px", overflow: "hidden", borderRadius: "10px" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='bgclorssntxtflx'>
                                                <div className='bg-clorrnd'>
                                                    <i className="fa-solid fa-play"></i>
                                                </div>
                                                <div className=''>
                                                    <p className='ssntxyt'>Session 6: VBA</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='bg-coloradvnc' style={{ backgroundColor: "#FFF9E2" }}>
                                            <div className='advnctxtimgflx'>
                                                <p className='advnctxt'>Power Bi</p>
                                                <div className='exclimgdivmain'>
                                                    <div className='exclimgdiv'>
                                                        <img src={logosmicrosoftpowerbi} alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='crstxtppdiv'>
                                                <p className='crstxtpp'>
                                                    Course give insights about data sets, tranform and data modelling
                                                </p>
                                            </div>
                                            <div className=''>
                                                <button className='btnstart'>Start Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Training
