import React, { useEffect, useState } from 'react'
import Addicon from "../../Images/headericon/Addicon.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EditWorkmodal from './EditWorkmodal';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { useAuthCtx } from '../../context/AuthCtx';


const Attendenceworktable = ({ selectedDate }) => {
    const { userData } = useAuthCtx();
    const [timeaddcomplete, serTimeaddcomplete] = useState();
    const [workeditmodal, setWorkeditmodal] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [attendanceShow, setAttendanceShow] = useState([]);
    const [projectId, setProjectId] = useState("");
    const [AbsentShow, setAbsentShow] = useState([]);

    //Get all assigned project
    const getProjectData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/fetch-project-user-wise`,
            method: "GET",
        });
        if (response && response.status) {
            setProjectList(response?.data?.data);
        }
    };

    //Get all attendance project
    const getAttendanceShowData = async () => {
        let data = {
            date: moment(selectedDate)?.format("YYYY-MM-DD")
        }
        const response = await ApiHelperFunction({
            urlPath: `/fetch-weekly-attendence-userwise`,
            method: "POST",
            data
        });
        if (response && response.status) {
            setAttendanceShow(response?.data?.data);
        }
    };

    //Get all absent project
    const getCheckAbsentData = async () => {
        const today = moment()?.format("YYYY-MM-DD");
        let data = {
            date: moment(selectedDate)?.format("YYYY-MM-DD") || today,
            // projectId:projectId
        }
        const response = await ApiHelperFunction({
            urlPath: `/check-my-absent`,
            method: "POST",
            data
        });
        if (response && response.status) {
            setAbsentShow(response?.data?.data);
        }
    };

    const editworkclick = (id) => {
        setWorkeditmodal(!workeditmodal);
        setProjectId(id);
    }
    const navigate = useNavigate()
    const managerviewclick = () => {
        navigate("/managerviewproject")
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    //for getting whole week's date
    const getWeekDates = (date) => {
        const startOfWeek = moment(date)?.startOf('isoWeek'); // Start of the week is Monday
        return Array.from({ length: 7 }, (_, i) => startOfWeek?.clone()?.add(i, 'days'));
    };
    const weekDates = moment(selectedDate).isValid() ? getWeekDates(selectedDate) : [];

    // Calculate total work hours for each day across all projects
    const totalHoursPerDay = weekDates.map(date => {
        return projectList.reduce((total, project) => {
            const attendanceForDate = attendanceShow?.find(attendance =>
                attendance?._id === project?.nameOfInitaitive &&
                attendance?.weeklyAttendances?.some(item => moment(item?.createdOn)?.isSame(date, 'day'))
            );

            const totalWorkHour = attendanceForDate
                ? attendanceForDate?.weeklyAttendances?.find(item => moment(item?.createdOn)?.isSame(date, 'day'))?.totalWorkHour
                : 0;

            return total + (totalWorkHour ? parseFloat(totalWorkHour) : 0);
        }, 0);
    });

    const totalWeeklyHours = totalHoursPerDay.reduce((total, hours) => total + hours, 0);


    useEffect(() => {
        getProjectData();
        getAttendanceShowData();
        getCheckAbsentData();
    }, [selectedDate])

    // console.log("projectList", attendanceShow)
    return (

        <>
            <div className=''>
                <div className='attendencedatewismasnfdviewdivflc'>
                    <div className='attrendencedivdatediv'>
                        <Slider {...settings}>
                            {/* <div>
                                <h3 className='txtppdatenmkk'>Saturday,21 nov</h3>
                            </div>
                            <div>
                                <h3 className='txtppdatenmkk'>Saturday,21 nov</h3>
                            </div> */}

                            {weekDates?.map((date, index) => (
                                <div key={index}>
                                    <h3 className='txtppdatenmkk'>{date?.format("dddd, DD MMM")}</h3>
                                </div>
                            ))}

                        </Slider>
                    </div>


                    <div className='lastweekmangereview'>
                        {
                            userData?.userType === "Manager" &&
                            <div className='' onClick={() => managerviewclick()}>
                                <button className='lastdwekdivbtn'>Manager View</button>
                            </div>
                        }


                        {/* <div className=''>
                            <button className='lastdwekdivbtn'>Copy last week </button>
                        </div> */}
                    </div>
                </div>

                <div className='tabledivattendivtablediv'>
                    <table className='tabledivattendivmain'>
                        <tr>
                            <th>
                                <p className='prthsttxttapppggjyuu8qokk'>
                                    Project Name
                                </p>
                            </th>
                            <th>
                                <div className='addimgspiroject'>
                                    <img src={Addicon} alt="..." />
                                </div>
                            </th>
                            {weekDates?.map((date, index) => (
                                <th className='attendencedaydiv' key={index}>
                                    <p className="elutitxtppsmop">{date?.format("dddd")}</p>
                                    <p className="elutitxtppsmop">{date?.format("DD/MM")}</p>
                                </th>
                            ))}

                            <th className='attendencedaydivnewalada'>Total</th>
                        </tr>


                        {
                            projectList?.map((project, i) => {
                                let projectTotalWork = 0;
                                return (
                                    <tr key={i}>
                                        <td className='inputattendencetdm'>
                                            <p className='elutitxtppsmop'>{project?.nameOfInitaitive}</p>
                                        </td>
                                        <td></td>

                                        {weekDates?.map((date, dateIndex) => {
                                            const attendanceForDate = attendanceShow?.find(attendance =>
                                                attendance?._id === project?.nameOfInitaitive &&
                                                attendance?.weeklyAttendances?.some(item => moment(item?.createdOn)?.isSame(date, 'day'))
                                            );


                                            const totalWorkHour = attendanceForDate
                                                ? attendanceForDate?.weeklyAttendances?.find(item => moment(item?.createdOn)?.isSame(date, 'day'))?.totalWorkHour
                                                : null;

                                            const attendenceType = attendanceForDate
                                                ? attendanceForDate?.weeklyAttendances?.find(item => moment(item?.createdOn)?.isSame(date, 'day'))?.attendenceType
                                                : null;

                                            if (totalWorkHour) {
                                                projectTotalWork += parseFloat(totalWorkHour);
                                            }
                                            const isToday = moment()?.isSame(date, 'day');

                                            return (
                                                <td className='inputattendencetdm' key={dateIndex}>
                                                    <div className={timeaddcomplete ? "inputattendencediv actv" : 'inputattendencediv'}
                                                        style={{ cursor: (isToday) ? "pointer" : "not-allowed" }}
                                                        onClick={(isToday) ? () => editworkclick(project?._id) : null}
                                                    >
                                                        <p className="timeworktxtpp">{
                                                            attendenceType === "absent" ? "absent" :
                                                                attendenceType === "leaveday" ? "leave" :
                                                                    attendenceType === "workday" ? totalWorkHour : "-"}
                                                        </p>
                                                    </div>
                                                </td>
                                            );
                                        })}

                                        <td className='inputattendencetdmnewalada'>
                                            <div className="totalrightyaxisattendencediv">
                                                <p className="timeworktxtpp">{Math.round(projectTotalWork)}</p>
                                            </div>
                                        </td>
                                    </tr>


                                )
                            })
                        }


                        <tr style={{ borderBottom: "1px solid #000" }}></tr>

                        <tr>
                            <td className='inputattendencetdm'>
                                <p className='elutitxtppsmoptptal'>Total</p>
                            </td>
                            <td></td>
                            {totalHoursPerDay?.map((totalHours, index) => (
                                <td className='inputattendencetdm' key={index}>
                                    <div className={timeaddcomplete ? "totalattendencediv actv" : 'totalattendencediv'}>
                                        <p className='timeworktxtpp'>{Math.round(totalHours)}</p>
                                    </div>
                                </td>
                            ))}
                            <td className='inputattendencetdmnewalada'>
                                <div className='totalrightyaxisattendencediv'>
                                    <p className='timeworktxtpp'>{Math.round(totalWeeklyHours)}</p>
                                </div>
                            </td>

                        </tr>

                    </table>
                </div>
            </div>
            {workeditmodal && <EditWorkmodal closemodal={setWorkeditmodal} projectId={projectId} getAttendanceShowData={getAttendanceShowData} selectedDate={selectedDate} />}
        </>
    )
}

export default Attendenceworktable
