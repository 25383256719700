import React, { useEffect, useState } from "react";
import GrowWel from "./GrowWel";
import markImg from "../../Images/markImg.png";
import ShareThoughts from "../../Component/ShareThoughts";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Tabs, Tab, TabScreen, TabPanel } from "react-tabs-scrollable";
import "react-tabs/style/react-tabs.css";
import OnboardingExp from "./OnboardingExp";
import "react-accessible-accordion/dist/fancy-example.css";
import battery from "../../Images/battery.png";
import trophy from "../../Images/trophy.png";
import mrkInPic1 from "../../Images/mrkInPic1.png";
import ImportantLinks from "./ImportantLinks";
import MyMentor from "./MyMentor";
import MentConnection from "./MentConnection";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import { toast } from "react-hot-toast";
import { useAuthCtx } from "../../context/AuthCtx";
import OnsurveysExp from "./onsurveysExp";
import suveyIcon from "../../Images/Icons/PNG/Survey.png";
import { Rating } from "react-simple-star-rating";
import goldIcon from "../../Images/Icons/PNG/Reward - Gold.png";
import eliteExp from "../../Images/Icons/PNG/Elite employee experience logo.png";
import bgprfldiv from "../../Images/bgprfldiv.png";
import Advocayboarddetails from "../../Component/Advocayboarddetails";
import { Select } from "@mui/material";
import Statuscard from "../../Component/Statuscard";
import Post from "../../Images/Icons/PNG/Post.png"
import SurveySuggestionmodal from "../../Component/Modal/SurveySuggestionmodal";
import GrivanceModal from "../../Component/Modal/GrivanceModal";
import GrivancecellIcon from "../../Images/Icons/PNG/GrivancecellIcon.png";
import SuggestionBox from "../../Images/Icons/PNG/SuggestionBox.png"
import EventsModal from "../../SeprateModal/EventsModal";
import "react-tabs-scrollable/dist/rts.css";
import "../Hub/Hub.css"
import message from "../../Images/Icons/PNG/Messages.png"
import DocumentRepositorymain from "../TrainingPage/DocumentRepositorymain";
import post from "../../Images/headericon/post_new.png";
import Comment from "../../Images/headericon/Comment.png"
import { useNavigate } from "react-router-dom";
import Chatbot from "../../Component/Chatbot/Chatbot";


function Surveys() {
  const { setLoading, modalF, setModalF,userData } = useAuthCtx();
  const percentage = 68;
  const [checkListDay, setCheckListDay] = useState([]);
  const [tabId, setTabId] = useState("");
  const [checkList, setCheckList] = useState([]);
  const [task, setTask] = useState([]);
  const [newChecklistData, setNewChecklistData] = useState([]);
  const [linkId, setLinkId] = useState(0);
  const [viewProgressBarData, setProgressBarData] = useState({});
  const [status, setStatus] = useState("");
  const [questionInputs, setQuestionInputs] = useState({});
  const [questionModal, setQuestionModal] = useState({});
  const [EmployeeData, setEmployeeData] = useState([]);
  const [managerData, setManagerData] = useState([]);
  const [isManagerDropdownOpen, setManagerDropdownOpen] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const [hrData, setHrData] = useState([]);
  const [goldCard, setGoldCard] = useState([]);
  const [suggestionmodal, setSuggestionmodal] = useState(false);
  const [grivancemodal, setGrivancemodal] = useState(false)
  const initial = {
    suggestion: "",
    tag: "",
    managerId: "",
    hrId: "",
    commiteeId: "",
    priority: ""
  }

  const initialValues = {
    eventName: "",
    hostedBy: "",
    eventDate: "",
    eventstarttime: "",
    eventendtime: "",
    lattitude: "",
    longitude: "",
    addinvites: "",
    name: "",
    notes: "",
    image: "",
    editTrainingName: "",
    editTraininDate: "",
    isHighLighted: ""
  };

  const initial1 = {
    complaint: "",
    tag: "",
    managerId: "",
    hrId: "",
    commiteeId: "",
    priority: ""
  }
  const [suggestionBox, setSuggestionBox] = useState(initial);
  const [GrivanceData, setGrivanceData] = useState(initial1);
  const [activeTab, setActiveTab] = useState(0);
  // const [tagId, setTagId] = useState("");

  //for scrolling functionality
  // const onTabClick = (index) => {
  //   console.log(index, "indexqwe")
  //   setActiveTab(index);
  // }

  // console.log("suggestionBox", suggestionBox)
  const getGoldCard = async () => {
    setLoading(true);

    const response = await ApiHelperFunction({
      urlPath: "/get-profile",
      method: "GET",
    });

    // console.log("responseeeee", response?.data);

    if (response.status === 200) {
      setGoldCard(response?.data?.data);
      // console.log("data get successfully");
    } else {
      // toast.error(response.message);
    }
    setLoading(false);
  };


  useEffect(() => {
    ViewProgressBar();
    getGoldCard();
    getEmployeeData();
    getManagerData();
    getHrData();
  }, []);


  const getEmployeeData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-all-employees`,
      method: "GET",
    });
    // console.log("RESPONSEF", response?.data?.data);
    if (response && response.status) {
      setEmployeeData(response?.data?.data);
    } else {
      // toast.error(response.error);
    }
  };

  //get manager data
  const getManagerData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-all-manager`,
      method: "GET",
    });
    // console.log("RESPONSEF", response?.data?.data);
    if (response && response.status) {
      setManagerData(response?.data?.data);
    } else {
      // toast.error(response.error);
    }
  };

 

  const getHrData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-all-hr`,
      method: "GET",
    });
    // console.log("RESPONSEF", response?.data?.data);
    if (response && response.status) {
      setHrData(response?.data?.data);
    } else {
      // toast.error(response.error);
    }
  };



  //for viewing progessbar 
  const ViewProgressBar = async () => {
    const res = await ApiHelperFunction({
      urlPath: `/view-progress-bar-status`,
      method: "GET",
    });
    if (res && res?.status) {
      setProgressBarData(res?.data?.data[0]);
    } else {
      // toast.error(res?.message || "Something went wrong");
    }
  };



  //for rating
  // const handleRating = async (rate, index, id) => {
  //   // setRating(rate);
  //   let data = {
  //     surveyID: id,
  //     rating: rate,
  //   };
  //   // console.log("rate",data)
  //   // return false
  //   let response = await ApiHelperFunction({
  //     urlPath: `/add-survey-ratings`,
  //     method: "POST",
  //     data,
  //   });
  //   // console.log("SERVEY RATING");
  //   // console.log("click", response);
  //   if (response && response.status === 200) {
  //     toast.success(response?.data?.message);
  //   } else {
  //     toast.error(response?.response?.data?.message);
  //   }
  //   // let newArr = [...survey];
  //   // newArr[index].rating = rate;
  //   // setSurvey(newArr);
  // };

  //for submit question
  // const handleSubmitQuestion = async (id, index) => {
  //   if (!questionInputs[index]) {
  //     return toast.error("All fields are required");
  //   }

  //   // console.log("ooooop", checklistDayID)
  //   let data = {
  //     surveyId: id,
  //     // question: question,
  //     question: questionInputs[index],
  //   };
  //   console.log("questiondata", data)
  //   // return false
  //   let response = await ApiHelperFunction({
  //     urlPath: `/add-question`,
  //     method: "POST",
  //     data,
  //   });
  //   console.log("click", response);
  //   if (response && response.status === 200) {
  //     toast.success(response?.data?.message);
  //     // setQuestion("");
  //     setQuestionInputs({});
  //   } else {
  //     // console.log(response,"ioprty")
  //     // toast.error(response?.response?.data?.message);
  //   }
  // }

  //for toggle question
  // const handleToggleQuestion = (index) => {
  //   setQuestionModal(prevState => ({
  //     ...prevState,
  //     [index]: !prevState[index]
  //   }));
  // };


  // fetch checklist days
  const fetchCheckDay = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/user-wise-survey-view",
      method: "GET",
    });
    // console.log("ressdd", response);
    if (response && response.status) {
      // console.log("response?.data?.data?.[0]?._id", response?.data?.data);
      setCheckListDay(response?.data?.data);
      let senddata = response?.data?.data;
      fetchCheckList(response?.data?.data?.[0]?._id, senddata);
    } else {
      // toast.error(response?.data?.message);
    }
  };

  const fetchCheckList = async (tabId, senddata) => {
    let data = {
      surveyNameId: tabId,
    };

    let response = await ApiHelperFunction({
      urlPath: `/get-survey`,
      method: "POST",
      data,
    });

    if (response && response.status === 200) {
      setCheckList(response?.data?.data1);
      setStatus(response?.data?.data2);
      // console.log(response?.data.data1, "datawe");
      let Arr = [];
      senddata.forEach((element) => {
        let myIndex = response?.data?.data1?.findIndex(
          (it) => it.surveyNameId == element?._id
        );
        // console.log("myIndex", myIndex);
        if (response?.data?.data1[myIndex]?.surveyNameId === element?._id) {
          if (response.data.data2.surveyStatus === "completed") {
            element.status = "Completed";
          } else if (response?.data.data2.surveyStatus === "notstarted") {
            element.status = "Not Started";
          } else {
            if (response.data.data2.surveyStatus === "ongoing" || response.data.data2 === "")
              element.status = "Ongoing";
            else {
              element.status = "Process";
            }
          }
          Arr.push(element);
          // console.log("arr push", Arr);
          setCheckListDay(Arr);
        } else {
          element.status = element.status;

          Arr.push(element);
          setCheckListDay(Arr);
        }
      });

      setCheckListDay(Arr);
    } 
    // else {
    //   toast.error(response?.response?.data?.message);
    // }
  };

  console.log(checkList, "qwsdcv")

  //task
  const fetchTask = async () => {
    let response = await ApiHelperFunction({
      urlPath: `/view-section-wise-task/6516810fcc090d283efa331b`,
      // urlPath: `/view-section-wise-task/${id}`,
      method: "GET",
    });

    if (response && response.status === 200) {
      // console.log(response?.data, "responseeeeeeeeeee");
      setTask(response?.data?.data);
    } else {
      // toast.error(response?.data?.message);
    }
  };

  useEffect(() => {
    fetchCheckDay();
    fetchTask();
  }, []);

  //suggestion box  onchange
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSuggestionBox(prev => ({ ...prev, [name]: value }))

    if (name === "managerId") {
      setSuggestionBox((prev) => ({
        ...prev,
        hrId: "",
        commiteeId: "",
      }))

    }
    if (name === "hrId") {
      setSuggestionBox((prev) => ({
        ...prev, managerId: "",
        commiteeId: ""
      }))

    }

  }


  //grivance box  onchange
  const handleChangeGrivance = (e) => {
    const { name, value } = e.target;
    setGrivanceData(prev => ({ ...prev, [name]: value }))

    if (name === "managerId") {
      setGrivanceData((prev) => ({
        ...prev,
        hrId: "",
        commiteeId: "",
      }))

    }
    if (name === "hrId") {
      setGrivanceData((prev) => ({
        ...prev, managerId: "",
        commiteeId: ""
      }))

    }

  }
  //  console.log(managerData, "statttt");

  const handlesuggestionClick = () => {
    setSuggestionmodal(!suggestionmodal)
  }

  const grivanceClick = () => {
    setGrivancemodal(!grivancemodal)
  }

  useEffect(() => {
    const selectedTabId = checkListDay[activeTab]?._id;
    // console.log("selectedTabId", selectedTabId, checkListDay)
    if (selectedTabId) {
      fetchCheckList(selectedTabId, checkListDay);
    }
  }, [activeTab])
  const [surveybox, setSueveybox] = useState(false);
  const navigate = useNavigate();

  const handlesurveyclick = (id) => {
    navigate(`/surveydetails/${id}`);
    setSueveybox(true)
  }
  return (
    <>

      <section className="hubNetWorkingSection">
        <div className="headFirstPart">
          {/* <div className="custContain"> */}
          {/* <GrowWel /> */}
          {/* </div> */}
        </div>
        <div className="networkSecPart">
          <div className="employeepagecontainer">
            <div className="surveydivmainblkl">
              <div className="suirveybpokjxbhsmain">
                {/* <div className="tabkksowmn">
                  <Tabs activeTab={activeTab} onTabClick={(e, ind) => onTabClick(ind)} rightBtnIcon={">"}
                    leftBtnIcon={"<"}>
                   
                    {checkListDay &&
                      checkListDay?.map((item, index) => {
                        
                        return (
                          <Tab
                            onClick={() => {
                              fetchCheckList(item?._id, checkListDay);
                            }}
                          >
                            {item?.status && (
                              <div
                                className="comActDiv"
                                style={{ backgroundColor: "#02975A" }}
                              >
                                {item?.status}
                              </div>
                            )}

                            {item?.name}
                          </Tab>
                        );
                      })}
                  
                  </Tabs>


                  <div className="chlTabpanelDiv">
                  
                    <TabScreen >
                      <div className="checkListTableDiv">
                        <table className="checkListTable">
                          
                          <tbody>
                            {checkList &&
                              checkList?.map((item, index) => {
                                

                                return (
                                  <tr key={index}>
                                    <td className="chckLeftTd">
                                      <div
                                        class="accordion myOwnChckListAccr"
                                        id={`myCheckListAccordion_${index}`} 
                                      >
                                        <div class="card">
                                          <div
                                            class="card-header"
                                            id="headingOne"
                                          >
                                           
                                            <div className="questionbrdr">
                                              <div className="questionflx">
                                                <span className="spanOne">
                                                  {item?.survey}
                                                </span>
                                                <div className="messagemaindivratingflx">
                                                  <div className="" >
                                                    <Rating
                                                      
                                                      onClick={(rate) => {
                                                        handleRating(rate, index, item._id);
                                                      }}
                                                      initialValue={item?.ratings}
                                                      size={20}
                                                    />
                                                  </div>
                                                  <div className="messagemaindiv" onClick={() => handleToggleQuestion(index)}>
                                                    <img src={Comment} alt="..." />
                                                  </div>
                                                </div>
                                              </div>

                                              {questionModal[index] ? (<div className="textareasubmitflx">
                                                <div className="textarediv">
                                                  <textarea placeholder="Text in this box"
                                                    rows="1"
                                                    cols="50"
                                                    name={`question_${index}`}
                                                    value={questionInputs[index] || ""}
                                                    onChange={(e) =>
                                                      setQuestionInputs({
                                                        ...questionInputs,
                                                        [index]: e.target.value,
                                                      })
                                                    }
                                                  ></textarea>
                                                </div>
                                                <button className="sbmtbtn" onClick={() => handleSubmitQuestion(item?._id, index)}>
                                                  <div className="postbgimg">
                                                    <img src={post} alt="..." />
                                                  </div>
                                                </button>

                                              </div>) : (<></>)}
                                            </div>
                                          </div>

                                          <div
                                            id={`collapseCheck1_${index}`} 
                                            class="collapse"
                                            aria-labelledby="headingOne"
                                            data-parent={`#myCheckListAccordion_${index}`} 
                                          >
                                          
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                   
                                  </tr>
                                );
                              })}
                          </tbody>
                          
                        </table>
                      </div>
                    </TabScreen>
                  
                  </div>

                </div> */}
                <div className="surveyleftpanendiv">
                  <h4 className="surveyowntxtpsomel">Hello {userData?.firstName + " " + userData?.lastName}!!</h4>
                  <div className="surveycheckdivflx">
                    {
                      checkListDay && checkListDay?.map((item, i) => {
                        return (
                          <div className={item?.surveyStatus==="onGoing" ? "surveycheckdivbox actv" : item?.surveyStatus==="completed" ? "surveycheckdivboxcom" : "surveycheckdivbox"}
                            onClick={() => handlesurveyclick(item?._id)}
                            key={i}
                            style={{ cursor: "pointer" }}
                          >
                            <h4 className="surveytsxtphghsfiuh">
                              {item?.name}
                            </h4>
                            <p className="chekcljnstckpml">
                              End Date : {item?.surveyEndDate}
                            </p>
                            <div className={item?.surveyStatus==="onGoing" ? "locksurveyunclokd actv" : "locksurveyunclokd"}>
                              {item?.surveyStatus==="onGoing" ? (
                                <i className="fa-solid fa-lock-open"></i>
                              ) 
                              : 
                              item?.surveyStatus==="completed" ? (
                                <i className="fa-solid fa-lock-open" style={{visibility:"hidden"}}></i>
                             
                              )
                              :
                              (
                                <i className="fa-solid fa-lock"></i>
                              )}
                            </div>


                          </div>
                        )
                      })
                    }
                    
                  </div>

                </div>
              </div>

              <div className="survetdivrightbdbk">


                <div className="eliteFigDiv" style={{ marginBottom: "10px", }}>

                  <div className="bgsghdiv" onClick={handlesuggestionClick} style={{cursor:"pointer"}}>
                    <div className="grvnctxtflx">

                      <h4 className="drtopen">Open Door</h4>
                    </div>

                    <p className="sghdntxt">(Suggestion Box)</p>
                    <div className="slpdhjdiv">
                      <input type=""
                        placeholder="Please write maximum 200 words"
                        className="tstsugrstdiv"
                        name="suggestion"
                        value={suggestionBox.suggestion}

                        onChange={(e) => handleChange(e)}
                        
                      />
                      <div className="diijfkxkldivpxi">
                        <div className="postbgimg">
                          <img src={post} alt="..." />
                        </div>
                      </div>
                    </div>



                  </div>

                  <div className="bgsghdiv" onClick={grivanceClick} style={{cursor:"pointer"}}>
                    <div className="grvnctxtflx">

                      <h4 className="drtopen">
                        Whistle Blower
                      </h4>

                    </div>
                    <p className="sghdntxt">(Grievance Cell)</p>
                    <div className="slpdhjdiv"  >
                      <input type=""
                        placeholder="Please write maximum 200 words"
                        className="tstsugrstdiv"
                        name="complaint"
                        value={GrivanceData?.complaint}
                        // onChange={(e) => setSuggestion(e.target.value)} 
                        onChange={(e) => handleChangeGrivance(e)}
                       
                      />
                      <div className="diijfkxkldivpxi">
                        <div className="postbgimg">
                          <img src={post} alt="..." />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


                <DocumentRepositorymain />

                <MyMentor />
                {/* <MentConnection /> */}
              </div>

            </div>
          </div>
        </div>
      </section>
      <Chatbot />
      
      {modalF && (
        <EventsModal
          closemodal={setModalF}
          activity={"events"}
          initialValues={initialValues}
        />
      )}
      {suggestionmodal && <SurveySuggestionmodal closeModal={setSuggestionmodal} />}
      {grivancemodal && <GrivanceModal closeModal={setGrivancemodal} />}
    </>
  );
}

export default Surveys;
