import React, { useEffect, useState } from "react";
import "./Profile.css";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import { toast } from "react-hot-toast";
import { useAuthCtx } from "../../context/AuthCtx";
import HobbyModal from "../../Component/Modal/HobbyModal";
import Swal from "sweetalert2";
import add_icon from "../../Images/Icons/PNG/Add icon.png"

function AspiringHobby(hobbies) {

  const { modalN, setModalN, getUserDetails } = useAuthCtx();
  const [hobbyData, setHobbyData] = useState([]);
  // console.log("hobbyData", hobbyData);
  // console.log("hobbies", hobbies);
  useEffect(() => {
    setHobbyData(hobbies.hobbyDatas);
  }, [hobbies]);

  const initialValues = {
    hobbies: "",
    hobbyBackColor: ""
  };


  // const handleDelete = async (id) => {
  //   const res = await ApiHelperFunction({
  //     urlPath: `/delete-hobbies/${id}`,
  //     method: "PUT",
  //   });
  //   if (res?.status) {
  //     toast.success("Deleted Successfully");
  //     getUserDetails()
  //   } else {
  //     // toast.error(res?.message || "Something went wrong");
  //     console.log("ERROR CREATING USER3", res);
  //   }
  // };

  const handleDelete = async (id) => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-hobbies/${id}`,
      method: "PUT",
    })
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {

      if (result.isConfirmed) {
        toast.success("Deleted Successfully");
        getUserDetails()

      }
    });
  }

  // const ViewAllHobby = async () => {
  //   let response = await ApiHelperFunction({
  //     urlPath: "/view-hobbies",
  //     method: "GET",
  //   });
  //   if (response?.status) {
  //     setHobbyData(response?.data?.data);
  //   } else {
  //     toast.error("Error fetching hobby data");
  //   }
  // };

  // useEffect(() => {
  //   ViewAllHobby();
  // }, []);
  const loop = [
    {
      data: "WORKING IN FRACTALS PROJECT",
      bgClr: "#D7DBFF",
    },
    {
      data: "DEVELOPING MY UX SKILL",
      bgClr: "#FFDBDB",
    },
    {
      data: "VOLUNTEERING AT ANOLD AGE HOME",
      bgClr: "#B2FFDF",
    },
  ];
  return (
    <>
      <div className="hobbyAspiMainDiv">
        <div className="flscghujndiv">
          <p className="hobbyAspHead">Hobbies</p>
          {/* <span className="tyEvIcon">
            <i class="fa-solid fa-plus" onClick={() => setModalN(true)}></i>
          </span> */}
          <figure className="bulleBoardFig">
            <img src={add_icon} onClick={() => setModalN(true)} alt="..." />
          </figure>
        </div>

        {/* <div className="tyEvMainDiv" onClick={() => setModalN(true)}> */}

        {/* <p className="tyEvText">Add Hobbies and Personal Information</p> */}
        {/* </div> */}

        <div>
          {hobbyData &&
            hobbyData?.map((item, indx) => {
              return (
                <div className="aspiringdivmainbackg">
                  <div
                    className="aspHobClrDivs"
                    style={{ backgroundColor: `${item?.hobbyBackColor}`, display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="wrkHobbyText">{item?.hobbies}</p>
                    <div className="deleticondivbg">
                      <i class="fa-solid fa-trash" style={{ cursor: "pointer" }} onClick={() => handleDelete(item?._id)}></i>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {modalN && (
        <HobbyModal
          closemodal={setModalN}
          activity={"hobby"}
          initialValues={initialValues}
          hobbies={hobbies}
          hobbyData={hobbyData}
          setHobbyData={setHobbyData}
        />
      )}
    </>
  );
}

export default AspiringHobby;
