import React, { useEffect, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
// import TrainingCreateEmployeeVirtual from './TrainingCreateEmployeeVirtual';
import { ApiHelperFunction, fileUpload } from '../../services/api/apiHelpers';
// import toast from 'react-hot-toast';
// import TrainingCreateEmployeePhysical from './TrainingCreateEmployeePhysical';
import SingleReqTrainingPhysicalMH from './SingleReqTrainingPhysicalMH';
import SingleReqTrainingVirtualMH from './SingleReqTrainingVirtualMH';
import { useLocation, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const SingleReqTrainingMH = () => {
    const params = useParams();
    const location = useLocation();
    const [singleView, setSingleView] = useState({});
    const [trainingType, settrainingType] = useState("");
    const [trainingCategory, settrainingCategory] = useState("");
    const [title, settitle] = useState("");
    const [language, setlanguage] = useState("");
    const [image, setImage] = useState("");
    const [uploading, setUploading] = useState(false);
    const [imageURL, setImageURL] = useState("");
    const [trainingCategoryData, settrainingCategoryData] = useState([]);

    //for view Single Page
    const viewSinglepost = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-single-trainings/${params?.id}`,
            method: "GET",
        });
        if (response.status === 200) {
            setSingleView(response?.data?.data?.[0]);
        } else {
        }
    }

    useEffect(() => {
        viewSinglepost()
        viewTrainingCategory();
    }, [])



    useEffect(() => {
        window.scroll(0, 0);
        const data = singleView
        if (location?.pathname == `/singleReqTrainingmh/${singleView?._id}`) {
            console.log("firstqqww", data?.image)

            settrainingType(data?.trainingType);
            settrainingCategory(data?.trainingCategory);
            settitle(data?.title);
            setlanguage(data?.language);
            setImageURL(data?.image);
        }
    }, [singleView?._id, location])

    // console.log("location", location?.pathname,singleView?._id)


    //for thumbnail image uploading
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await fileUpload("/image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploading(false);
    };

    //fetching all training category
    const viewTrainingCategory = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/training-categorylist-byUser`,
            method: "GET",
        });
        if (response && response.status) {
            settrainingCategoryData(response?.data?.data);
        }
    }

    // console.log("nmj",title)
    return (
        <>
            <section className='trainingcreatepagemain'>
                <div className='employeepagecontainer'>
                    <div className='trainingcreatepagemainflx'>
                        {/* for left part of create training */}
                        <div className='trainingcreatepageleft'>
                            <div className='trainingcreatepageleftbrdr'>
                                <div className=''>
                                    <form>
                                        <div className='trainingpageleftdivmain'>
                                            <p className='typstringtxtppcrt'>Types of Traning</p>
                                            <select
                                                id=""
                                                className='typstrningwhselct'
                                                name="trainingType"
                                                value={trainingType}
                                                onChange={(e) => settrainingType(e.target.value)}
                                            >
                                                <option value="" disabled>Select option</option>
                                                <option value="Virtual">Virtual</option>
                                                <option value="physically">Physically</option>
                                            </select>
                                        </div>
                                        <div className='trainingpageleftdivmain'>
                                            <p className='typstringtxtppcrt'>Types of Category</p>
                                            <select
                                                id=""
                                                className='typstrningwhselct'
                                                name="trainingCategory"
                                                value={trainingCategory}
                                                onChange={(e) => settrainingCategory(e.target.value)}
                                            >
                                                {/* <option value="" disabled>Select option</option> */}
                                                {/* <option value="SkillDevelopment">Skill development training</option> */}
                                                <option value={""} disabled>
                                                    Select Option
                                                </option>
                                                {trainingCategoryData?.map((item, i) => (
                                                    <option key={i} value={item?._id}>
                                                        {item?.name}
                                                    </option>
                                                ))}

                                            </select>
                                        </div>
                                        <div className='trainingpageleftdivmain'>
                                            <p className='typstringtxtppcrt'> Trainning Title</p>
                                            <input
                                                type="text"
                                                className='typstrningwhselct'
                                                name="title"
                                                value={title}
                                                onChange={(e) => settitle(e.target.value)}
                                            />
                                        </div>
                                        <div className='trainingpageleftdivmain'>
                                            <p className='typstringtxtppcrt'>Language</p>
                                            <input
                                                type="text"
                                                className='typstrningwhselct'
                                                name="language"
                                                value={language}
                                                onChange={(e) => setlanguage(e.target.value)}
                                            />
                                        </div>
                                        {/* <div className='trainingpageleftdivmain'>
                                            <p className='typstringtxtppcrt'>Thumbnail Image</p>

                                            <div className="bgcontainerupldbrdr">
                                                <div className="bgcrd">
                                                    <div className="bgcontainerupldchmageredif">
                                                        <div className="logoupld">
                                                            <img src={Addicon} alt="..." />
                                                            <div className="">
                                                                <input
                                                                    type="file"
                                                                    class="upldimagediv"
                                                                    value={image}
                                                                    accept="image/*"
                                                                    name="image"
                                                                    onChange={handleImageChange}
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="upldtxtppdiv">Upload Your Thumbnail</p>
                                                    {uploading ? <p>image uploading......</p> : null}
                                                    {imageURL !== "" && (
                                                        <div className='imageuplddivredifald' >
                                                            <img

                                                                src={imageURL}
                                                                alt=""

                                                            />
                                                            <div className='imageupldcrs'
                                                                onClick={() => {
                                                                    setImageURL("");
                                                                    setImage("");
                                                                }}
                                                            >
                                                                <i
                                                                    class="fa-solid fa-xmark"

                                                                ></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                        </div> */}

                                        <div className='trainingpageleftdivmain'>
                                            <p className='typstringtxtppcrt'>Thumbnail Image</p>

                                            <div className="bgcontainerupldbrdr">
                                                <div className="bgcrd">
                                                    <div className="bgcontainerupld">
                                                        <div className="logoupld">
                                                            <img src={Addicon} alt="..." />
                                                            <div className="">
                                                                <input
                                                                    type="file"
                                                                    class="upldimagediv"
                                                                    value={image}
                                                                    accept="image/*"
                                                                    name="image"
                                                                    onChange={handleImageChange}
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="upldtxtppdiv">Upload Your Thumbnail</p>
                                                    {uploading ? <p>image uploading......</p> : null}
                                                    {imageURL !== "" && (
                                                        <div className='imageuplddiv' >
                                                            <img

                                                                src={imageURL}
                                                                alt=""

                                                            />
                                                            <div className='imageupldcrs'
                                                                onClick={() => {
                                                                    setImageURL("");
                                                                    setImage("");
                                                                }}
                                                            >
                                                                <i
                                                                    class="fa-solid fa-xmark"

                                                                ></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* training type wise component change */}
                        {/* {
                            singleView?.trainingType === "Virtual"
                                ?
                                (<SingleReqTrainingVirtualMH
                                    trainingType={singleView?.trainingType}
                                    trainingCategory={singleView?.trainingCategory}
                                    title={singleView?.title}
                                    language={singleView?.language}
                                    image={singleView?.image}
                                    singleView={singleView}
                                />)
                                :
                                (<SingleReqTrainingPhysicalMH
                                    trainingType={singleView?.trainingType}
                                    trainingCategory={singleView?.trainingCategory}
                                    title={singleView?.title}
                                    language={singleView?.language}
                                    image={singleView?.image}
                                    singleView={singleView}
                                />)
                        } */}


                        {/* training type wise component change */}
                        {
                            trainingType === "Virtual"
                                ?
                                (<SingleReqTrainingVirtualMH
                                    singleView={singleView}
                                    trainingType={trainingType}
                                    trainingCategory={trainingCategory}
                                    title={title}
                                    language={language}
                                    image={image}
                                    uploading={uploading}
                                    imageURL={imageURL}
                                    settrainingType={settrainingType}
                                    settrainingCategory={settrainingCategory}
                                    settitle={settitle}
                                    setlanguage={setlanguage}
                                    setImage={setImage}
                                    setImageURL={setImageURL}
                                    locationData={location?.state?.data?.values}
                                    id={params?.id}
                                />)
                                :
                                (<SingleReqTrainingPhysicalMH
                                    singleView={singleView}
                                    trainingType={trainingType}
                                    trainingCategory={trainingCategory}
                                    title={title}
                                    language={language}
                                    image={image}
                                    uploading={uploading}
                                    imageURL={imageURL}
                                    settrainingType={settrainingType}
                                    settrainingCategory={settrainingCategory}
                                    settitle={settitle}
                                    setlanguage={setlanguage}
                                    setImage={setImage}
                                    setImageURL={setImageURL}
                                    locationData={location?.state?.data?.values}
                                    id={params?.id}
                                />)
                        }


                    </div>

                </div>

            </section>
        </>

    )
}

export default SingleReqTrainingMH