import React, { useEffect, useState } from 'react';
import ProfileImg1 from "../../Images/No-Image-Placeholder.png";
import "./Message.css";
import search from "../../Images/headericon/search.png";
import Info from "../../Images/headericon/Info.png";
import Attach from "../../Images/headericon/Attach.png";
import Camera from "../../Images/headericon/Camera.png";
import Mic from "../../Images/headericon/Mic.png"
import sendicon from "../../Images/headericon/sendicon.png"
import { useLocation } from 'react-router-dom';
import { SocketConnect, SocketDisconnect, socket } from '../../Socket/Socket';
import { ApiHelperFunction, fileUpload } from '../../services/api/apiHelpers';
import { useAuthCtx } from '../../context/AuthCtx';
import moment from 'moment';
import Chatbot from '../../Component/Chatbot/Chatbot';
import toast from 'react-hot-toast';
import Loader from '../../Component/loader/Loader';
import ReactPlayer from 'react-player';
import { ReactMediaRecorder } from 'react-media-recorder';

var selectedChat = {}
const Message = () => {
    const location = useLocation();
    const fId = location?.state?.fId;
    const fName = location?.state?.name;
    const remoteImage = location?.state?.image;
    const ftype = location?.state?.ftype;
    const fdept = location?.state?.fdept;
    const { userData } = useAuthCtx();
    const [chatlist, setChatlist] = useState([])
    const [allChat, setAllChat] = useState({});
    const [chatData, setChatData] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');
    const [Uploading, setUploading] = useState(false);
    const [images, setImages] = useState([]);
    const [UploadingVideo, setUploadingVideo] = useState(false);
    const [VideoURL, setVideoURL] = useState("");
    const [UploadingAudio, setUploadingAudio] = useState(false);
    const [AudioURL, setAudioURL] = useState("");


    useEffect(() => {
        if (fId) {
            let chatData = {
                chatwithId: fId,
                chatwith: fName,
                userName: userData?.firstName,
                userNameId: userData?._id,
                remoteUserImage: remoteImage,
                ftype: ftype,
                fdept: fdept
            }

            setAllChat(chatData)
            selectedChat = chatData
            ViewChatData(chatData.chatwithId)
        }
    }, [fId])

    const handleChatClick = (item) => {
        setAllChat(item)
        selectedChat = item
        ViewChatData(item.chatwithId)
    }

    //for images upload
    const handleImageChange = async (e) => {
        let files = Array.from(e.target.files);
        let imageArray = [];
        setUploading(true);

        for (let i = 0; i < files.length; i++) {
            const form = new FormData();
            form.append("image", files[i]);
            let res = await fileUpload("/image-upload", "POST", form);
            console.log("resnm", res)
            if (res?.status) {
                toast?.success("Image uploaded successfully");
                imageArray?.push(res?.image);
            } else {
                toast.error("Error uploading image");
            }

        }

        setImages(imageArray);
        setUploading(false);
    };


    //for video upload
    const handleVideoChange = async (e) => {
        setUploadingVideo(true)

        let image = e.target.files[0];

        const form = new FormData();
        form.append("image", image);
        // console.log("imagekkk",form)

        let res = await fileUpload("/image-upload", "POST", form);

        if (res.status) {
            setUploadingVideo(false)

            toast.success("Video uploaded successfully");
            setVideoURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploadingVideo(false)

    }


    // const startRecording = (startRecording) => {
    //     startRecording();
    // }

    // const stopRecording = (stopRecording, mediaBlobUrl, sendAudioMessage) => {
    //     stopRecording();
    //     if (mediaBlobUrl) {
    //         sendAudioMessage(mediaBlobUrl);
    //     }
    // }


    const sendAudioMessage = async (mediaBlobUrl) => {
        setUploadingAudio(true);

        const audioBlob = await fetch(mediaBlobUrl).then(r => r.blob());
        const form = new FormData();
        form.append("image", audioBlob);
        console.log("audioBlob",mediaBlobUrl)

        let res = await fileUpload("/image-upload", "POST", form);

        if (res.status) {
            toast.success("Audio uploaded successfully");
            setAudioURL(res?.image);
        } else {
            toast.error("Error uploading audio");
        }

        setUploadingAudio(false);
        sendMessage();
    }


    //for remove image or video
    const removeImage = (index) => {
        let imageremove = [...images];
        imageremove?.splice(index, 1);
        setImages(imageremove);
    };

    //for viewing chatList
    const ViewChatList = async () => {
        let response = await ApiHelperFunction({
            urlPath: "/fetch-chat-list",
            method: "GET",
        });
        if (response?.status) {
            setChatlist(response?.data?.data);
        } else {

        }
    };


    //for viewing chatData
    const ViewChatData = async (chatId) => {
        let response = await ApiHelperFunction({
            urlPath: `/fetch-chat/${chatId}`,
            method: "GET",
        });
        if (response?.status) {
            setChatData(response?.data?.data?.reverse());
        } else {

        }
    };

    useEffect(() => {
        ViewChatList();
    }, [])

    useEffect(() => {
        // Connect to the Socket.IO server
        const userValid = localStorage?.getItem("Authorization")
        if (userValid) SocketConnect(userValid);

        // Cleanup function to disconnect socket on component unmount
        return () => {
            SocketDisconnect();
            selectedChat = {}
        };
    }, []);

    useEffect(() => {
        if (socket) {
            // Listen for incoming messages
            socket.on('receivedMessage', (message) => {
                setChatData(state => {
                    if (selectedChat?.chatwithId === message.receiverId) {
                        let index = state.findIndex(it => it._id === message._id)
                        if (index >= 0) return state;
                        ViewChatData(selectedChat?.chatwithId)
                        return [...state, message]

                    } else {
                        return state
                    }

                });
            });

            socket.on('updateChatlist', (data) => {
                setChatlist(state => {
                    let index = state.findIndex(it => it.chatwithId === data.chatwithId)

                    if (index >= 0) {
                        state[index] = data
                        ViewChatData(data?.chatwithId)
                        return JSON.parse(JSON?.stringify(state))
                    } else {
                        return [...state, data]
                    }
                })
            })
        }
    }, [socket]);

    const sendMessage = () => {
        if (socket && currentMessage.trim() !== '' || socket && images?.length > 0 || socket && VideoURL !== '' || socket && AudioURL !== '') {
            // Emit a message to the server

            let chatData = {
                receiverName: allChat?.chatwith,
                receiverId: allChat?.chatwithId,
                messageType: 'text',
                message: currentMessage,
                images: images,
                video: VideoURL,
                audio: AudioURL,
                senderImage: userData?.image,
                receiverImage: allChat?.remoteUserImage,
                fdept: allChat?.fdept,
                ftype: allChat?.ftype
            }
            socket.emit('sendMessage', chatData);
            chatData.createdOn = new Date()
            setChatData(state => [...state, chatData])
            setVideoURL('');
            setCurrentMessage('');
            setImages([]);
        }
    };
    // console.log("nmhj", allChat?.remoteUserImage)

    return (
        <>
            <section className='messagesectiondiv'>
                <div className='msgcontainer'>
                    <div className='messagedivflx'>
                        <div className='messagedivleft'>
                            <div className='messagedivbgg'>
                                <div className='messagedivlfttop'>
                                    <div className='inptdivtxtdippplcd'>
                                        <input type='text' placeholder='search' />
                                        <div className='srchdivimgicn'>
                                            <img src={search} alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className='messagedivlftbottom'>
                                    {
                                        chatlist?.length !== 0 ?
                                            (<>
                                                {
                                                    chatlist?.map((item) => {
                                                        // console.log("vbfg",item?.remoteUserImage)
                                                        return (
                                                            <div className='msgprofiledsgtmflc' style={{ cursor: "pointer" }} onClick={() => handleChatClick(item)}>
                                                                <div className='msgprofjdsgfxl'>
                                                                    <div className=''>

                                                                        {/* Profel Image Show */}
                                                                        {
                                                                            item?.remoteUserImage ?
                                                                                (
                                                                                    <div className='prfldivimhbg'>
                                                                                        <img src={item?.remoteUserImage} alt="..." />
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <div className='prfldivimhbg'>
                                                                                        <img src={ProfileImg1} alt="..." />
                                                                                    </div>
                                                                                    // <h2>Message</h2>
                                                                                    // <></>
                                                                                )
                                                                        }

                                                                        {/* No Profile Image  */}


                                                                    </div>
                                                                    <div className=''>
                                                                        <p className='profilenamemsgtxt'>{item?.chatwith}</p>
                                                                        <p className='profilenamemsgdsgtxtp'>{item?.message}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='timedivhpp'>
                                                                    <p className='timemsgppp'>{moment(item.updatedOn).format('hh:mm A')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>

                                            ) : (<p>No ChatList here</p>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='messagedivright'>
                            <div className='messagedivrightbg'>
                                <div className='messagedivrighttop'>
                                    <div className='messagerighttopflxdiv'>
                                        <div className='messagedivprfldsgrightflx'>
                                            <div className=''>

                                                {/* Profel Image Show */}
                                                {
                                                    allChat?.remoteUserImage
                                                        ?
                                                        (<div className='prfldivimhbg'>
                                                            <img src={allChat?.remoteUserImage} alt="..." />
                                                        </div>)
                                                        :
                                                        (<h3>Messages</h3>)
                                                }

                                                {/* No Profile Image  */}
                                                {/* <div className='prfldivimhbg'>
                                                   <img src={prflcircle} alt="..." />
                                              </div> */}
                                            </div>
                                            <div className=''>
                                                <p className='profilenamemsgtxtrighttop'>{allChat?.chatwith}</p>
                                                <p className='profilenamemsgdsgtxtprightdsgtop'> {allChat?.fdept}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='infoimgsicvimg'>
                                            <img src={Info} alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className='messagedivrightbottom'>
                                    <div className=''>
                                        {
                                            chatData?.length !== 0 ? (
                                                <>
                                                    {
                                                        chatData?.map((item) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        (item?.receiverId === userData?._id)
                                                                            ?
                                                                            (<div className='profileotherdiv'>
                                                                                <div className='prfldivimhbg'>
                                                                                    <img src={allChat?.remoteUserImage} alt="..." />
                                                                                </div>
                                                                                <div className='profileotherdivbg'>
                                                                                    <p className='profileotherdivtxt'>{item?.message} </p>
                                                                                    <p className='profileotherdivtxttime'>{moment(item?.createdOn)?.format('hh:mm A')}</p>
                                                                                </div>
                                                                            </div>)
                                                                            :
                                                                            (
                                                                                <div className='profileowndiv'>
                                                                                    {/* <div className='prfldivimhbg'>
                                                <img src={advytimg} alt="..." />
                                            </div> */}

                                                                                    <div className='profileotherdivbgsntimg'>
                                                                                        <div className='profileowndivbg'>
                                                                                            {item?.images?.length > 0 &&
                                                                                                <>{
                                                                                                    item?.images?.map((ele) => {
                                                                                                        return (
                                                                                                            <img src={ele} style={{ width: "200px", height: "200px" }} />
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                                </>
                                                                                            }

                                                                                            {item?.video &&
                                                                                                <ReactPlayer
                                                                                                    className='reactdivimgmainplrt'
                                                                                                    controls
                                                                                                    width='20rem'
                                                                                                    height='10rem'
                                                                                                    playing={false}
                                                                                                    url={item?.video}
                                                                                                    config={{
                                                                                                        youtube: {
                                                                                                            playerVars: { showinfo: 1 }
                                                                                                        },
                                                                                                        facebook: {
                                                                                                            appId: '12345'
                                                                                                        },
                                                                                                    }}
                                                                                                />
                                                                                                // <video src={item?.video} style={{ width: "200px", height: "200px" }} />
                                                                                            }

                                                                                            {/* {item?.audio && (
                                                                                                <ReactPlayer
                                                                                                    controls
                                                                                                    url={item?.audio}
                                                                                                    width="250px"
                                                                                                    height="30px"
                                                                                                />
                                                                                            )} */}

                                                                                            <p className='profileotherdivtxt'>{item?.message}</p>
                                                                                            <p className='profileotherdivtxttime'>{moment(item.createdOn).format('hh:mm A')}</p>
                                                                                            <div className='profilearrowdiv'>

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className=''>
                                                                                            <div className=''>
                                                                                                <p className='snttxtpppcbh'>Sent</p>
                                                                                            </div>
                                                                                            <div className='senncheckdivimg'>
                                                                                                <img src={userData.image} alt="..." />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                    }

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                                : (<p>Tap to conversation or No Conversation till now</p>)
                                        }

                                        {Uploading ? (
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <Loader />
                                            </div>
                                        ) : (
                                            <div className="prt_img_flx">
                                                {images &&
                                                    images?.map((item, index) => {
                                                        return (
                                                            <div className="prt_img" key={index}>
                                                                <img src={item} alt="" />

                                                                <div className="close_icon_img">
                                                                    <i
                                                                        class="fa-solid fa-xmark"
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "24px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => removeImage(index)}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        )}

                                        <div className="prt_img_flx">
                                            {VideoURL && <div className="prt_img">
                                                <video src={VideoURL} alt="" />

                                                <div className="close_icon_img">
                                                    <i
                                                        class="fa-solid fa-xmark"
                                                        style={{
                                                            color: "red",
                                                            fontSize: "24px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => setVideoURL("")}
                                                    ></i>
                                                </div>
                                            </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className='messagedivrightbottomlastbtm'>
                                    <div className='messagedivsrchdivflx'>
                                        {/* <div className='msgicondivallsivtext'> */}
                                        <div className='msgicondivallsivtextflx'>
                                            <div className='texticonimgsaa'>
                                                <img src={Attach} alt="..." />
                                                <div className='inputtypeprfls'>
                                                    <input
                                                        type="file"
                                                        id=""
                                                        name=""
                                                        multiple
                                                        onChange={handleImageChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='cameraspclarea'>
                                                <img
                                                    src={Camera}
                                                    alt="..."
                                                />
                                                <div >
                                                    <input
                                                        type="file"
                                                        id=""
                                                        name=""
                                                        accept='video/*'
                                                        onChange={handleVideoChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='micaaspclarea'>
                                            <img src={Mic} alt="mic" />

                                                {/* <ReactMediaRecorder
                                                    audio
                                                    render={({ startRecording, stopRecording, mediaBlobUrl }) => (
                                                        <>
                                                            <div
                                                                onMouseDown={() => startRecording()}
                                                                onMouseUp={() => stopRecording(startRecording, mediaBlobUrl, sendAudioMessage)}
                                                            >
                                                                <img src={Mic} alt="mic" />
                                                            </div>
                                                        </>
                                                    )}
                                                /> */}
                                            </div>
                                        </div>
                                        {/* </div> */}
                                        <div className='textmasgdiv'>
                                            <textarea
                                                id=""
                                                name="currentMessage"
                                                rows="1"
                                                cols="1"
                                                value={currentMessage}
                                                onChange={(e) => setCurrentMessage(e.target.value)}
                                            >
                                            </textarea>
                                            <div className='sendicondivimgbg' onClick={sendMessage}>
                                                <div className='sendicondivimg'>
                                                    <img src={sendicon} alt="..." />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Chatbot />
        </>
    );
}
export default Message;

