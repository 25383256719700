import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ThreeDot from "../../Images/dots.png";
import ProfileImg1 from "../../Images/profile1.png";
import ProfileImg2 from "../../Images/profile2.png";
import ProfileImg3 from "../../Images/profile3.png";
import ProfileImg4 from "../../Images/profile4.png";
// import AttachedImg from "../../Images/attached.png";
import AttachedImg from "../../Images/Icons/PNG/Attach.png";
// import CommentsImg from "../../Images/chaticon.png";
import CommentsImg from "../../Images/Icons/PNG/Comment.png";
import { useAuthCtx } from "../../context/AuthCtx";
import { useDispatch, useSelector } from "react-redux";
import { getInitiative } from "../../redux/slices/initiativeSlice";
import share from "../../Images/headericon/share.png"
// import Share from "../../Images/Icons/PNG/share-icon-elite-web.svg"
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import toast from "react-hot-toast";
import { getBillBoardData } from "../../redux/slices/billBoardSlice";
import EditInitiativeModal from "../../SeprateModal/EditInitiativeModal"
import InitiativeDetailsModal from "../../SeprateModal/InitiativeDetailsModal";
import { RWebShare } from "react-web-share";
import add_icon from "../../Images/Icons/PNG/Add icon.png"
import InitiativeModal from "../../Component/Modal/InitiativeModal";
import Edit from "../../Images/headericon/Edit.png"
import moment from "moment";

export default function InitiativeDetail() {
  const { setLoading, userData } = useAuthCtx();
  const dispatch = useDispatch();
  const initiative = useSelector((state) => state?.initiativeSlice?.data);
  // const { setModalM } = useAuthCtx();
  const [modalB, setModalB] = useState(false);
  const [modalId, setModalId] = useState("");
  // const [BulletinBoardSingleData, setBulletinBoardSingleData] = useState({});
  const [top, setTop] = useState(null);
  const [left, setLeft] = useState(null);
  const [modalA, setModalA] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [editbtn, setEdibtn] = useState(false)
  const [selectedDescription, setSelectedDescription] = useState("");
  const [indx, setindx] = useState('')
  const [initiativeData, setInitiativeData] = useState({});
  const navigate = useNavigate();


  // console.log("initiativeData", initiativeData)
  useEffect(() => {
    dispatch(getInitiative());
  }, []);

  const handleInitiativeClick = (ele) => {
    navigate(`/${ele?.nameOfInitaitive?.replace(/\s+/g, '-')}/${ele?._id}`)
    // setInitiativeData(ele);
    // setViewDetails(true);
  };

  const handleClick = (e, ele, index) => {
    setModalId(ele._id);
    // console.log("EVENT", e, index);
    setInitiativeData(ele);
    // console.log("s", e, ele);
    setTop(e.clientY);
    setLeft(e.clientX);
    setModalA(!modalA);
  };


  //for delete functionality
  const handleDelete = async () => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-initiative/${modalId}`,
      method: "DELETE",
    });
    if (res?.status) {
      toast.success("Deleted Successfully");

      dispatch(getInitiative());
      setModalA(false);
    } else {
      toast.error(res?.response?.data?.message || "Something went wrong");
      console.log("ERROR CREATING USER3", res);
    }
  };

  const openDetails = (item, id, index) => {
    console.log("index", index);
    setInitiativeData(item);
    setindx(index)
    setViewDetails(!viewDetails);
    //  alert("working")
    setSelectedDescription(item?.description || "");
  };
  const handleeditClick = () => {
    setEdibtn(!editbtn);
    // alert("working");
  }
  const handlestopClick = (e) => {
    e.stopPropagation();
  }


  //for join initiative
  const handleJoinClick = async (id) => {
    const data = {
      // name: groupName,
      initiativesID: id
    };
    const response = await ApiHelperFunction({
      urlPath: `/join-initiative`,
      method: "POST",
      data,
    });

    if (response.status) {
      toast.success(response?.data?.message);
      // dispatch(getAffinityGroups());
      // setGroupName("");
    } else {
      toast.error(response?.response?.data?.message);
    }
  };
  const initialValues6 = {
    initiativesID: "",
    nameOfInitaitive: "",
    description: "",
    startDate: "",
    endDate: "",
    rewardPoints: "",
    assignedUsers:[],
    departmentId:[],
    initiativeView:""
  };
  const { modalM, setModalM, } = useAuthCtx();
  return (
    <section className="bulleDetail initiativeDetail">
      <div className="container">
        <div className="row">
          <div className="col">
            <section id="initiative_sec">
              <div className="top_area">
                <div className="head" style={{ display: "flex", alignItems: "center" }}>
                  <h4>Project</h4>
                  <div className="" style={{ marginLeft: "15px" }}>
                    {userData?.userType === "Admin" || userData?.userType === "Owner" || userData?.userType === "Manager"? (
                      <div className="tyEvMainDiv"
                      //  onClick={() => setModalM(true)}
                      onClick={()=>navigate('/project')}
                       >
                        <figure className="bulleBoardFig">
                          <img src={add_icon} alt="..." />
                        </figure>
                        {/* <p className="tyEvText">Add Initiative</p> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div>
                  <Link to="/" className="backHomeBtn">
                    <i class="fa-solid fa-arrow-left" style={{ color: "#000000" }}></i>
                    {/* Back */}
                  </Link>
                </div>
              </div>

              <div className="bottom_ara bottomdiv_ara">


                {initiative?.length > 0 ? (
                  initiative?.map((item, ind, index) => {
                    return (
                      <div
                        className="card_area card_area_indtls"
                        style={{ background: "rgb(242, 233, 220)" }}
                      >
                        <div className="top">
                          <div className="name" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <p><span style={{ fontSize: "16px" }}>Name : </span>  {item?.nameOfInitaitive} </p>


                            <div className="shrejnbtn">
                              {/* <div className="imgshred">
                                <img src={Share} alt="..." />
                              </div> */}



                              {
                                userData?._id === item?.addedBy || userData?.userType === "Owner" ? (<></>) : (<div className="">
                                  <button className="jnbtndiv" onClick={() => handleJoinClick(item?._id)}>Join</button>
                                </div>)
                              }


                              {/* <div className="btnactgdivll" onClick={handleeditClick}>

                                <Link to="/initiativeDetail" className="btn" style={{ color: "#000" }}>
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                </Link>
                                {
                                  editbtn &&
                                  <div className="btnaghedtdlt" onClick={handlestopClick}>

                                    <i className="fas fa-edit" style={{ marginRight: "7px", fontSize: "14px" }}></i>
                                    <i class="fa fa-trash" aria-hidden="true" style={{ margin: "3px", fontSize: "14px", color: "red" }}></i>
                                  </div>
                                }
                              </div> */}
                              {userData?._id === item?.addedBy || userData?.userType === "Owner"
                                ?
                                <div
                                  className=""
                                  onClick={(e) => handleClick(e, item, index)}
                                  style={{
                                    position: "relative",
                                    zIndex: "2",
                                    // left: "8px",
                                    // bottom: "-5px",
                                  }}
                                >
                                  <a className="jnbtnfbgchng" style={{ cursor: "pointer" }}>
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                  </a>
                                  {modalId === item?._id && modalA && (
                                    <div
                                      style={{
                                        // position: "absolute",
                                        overflow: "hidden",
                                        backgroundColor: "#fff",
                                        // padding: "3px 8px",
                                        border: "none",
                                        top: "auto",
                                        padding: "3px 8px",
                                        // marginLeft: "-27px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                        boxShadow: "0 0 0.1rem 0",
                                        position: "absolute",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        columnGap: "10px",
                                        right: "10px",
                                      }}
                                    >

                                      {/* <i
                                        style={{
                                          fontSize: "14px",
                                        }}
                                        class="fas fa-edit"
                                        onClick={() => setModalB(true)}
                                      ></i> */}
                                      <div className="shareimgmn" 
                                      // onClick={() => setModalB(true)}
                                      onClick={()=>navigate("/editProject",{state:initiativeData})}
                                      >
                                        <img src={Edit} alt="/" />
                                      </div>
                                      <i
                                        style={{
                                          // margin: "3px",
                                          fontSize: "14px",
                                          color: "red",
                                        }}
                                        class="fa fa-trash"
                                        aria-hidden="true"
                                        onClick={handleDelete}
                                      ></i>
                                      <div className="">
                                        <RWebShare disableNative={true}
                                          data={{
                                            text: `${item?.nameOfInitaitive}`,
                                            url: "http://52.66.120.170:3004/initiativeDetail",
                                            title: "Share initiative",
                                          }}
                                          sites={[
                                            "linkedin",
                                            "facebook",
                                            "twitter",
                                            "whatsapp",
                                            "mail",
                                            "copy",
                                          ]}
                                          onClick={(platform) => {
                                            if (platform === "copy") {
                                              // Copy to clipboard logic
                                              const textToCopy = `${item?.nameOfInitaitive} - http://52.66.120.170:3004/initiativeDetail`;
                                              navigator.clipboard.writeText(textToCopy).then(() => {
                                                console.log("Text copied to clipboard:", textToCopy);
                                                // You can also show a notification or toast here
                                                toast.success("Link copied to clipboard!");
                                              });
                                            } else {
                                              // Handle other platforms
                                              console.log("Shared successfully on", platform);
                                            }
                                          }}
                                        >
                                          <div className="shareimgmn">
                                            <img src={share} alt="Share Icon" className="shareIconImg" />
                                            <span></span>
                                          </div>
                                        </RWebShare>
                                      </div>

                                      {/* <RWebShare
                                        data={{
                                          text: `${item?.nameOfInitaitive}`,
                                          url: "http://52.66.120.170:3004/initiativeDetail",
                                          title: "Share initiative",
                                        }}
                                        sites={[
                                          "linkedin",
                                          "facebook",
                                          "twitter",
                                          "whatsapp",
                                          "mail",
                                          "copy",
                                        ]}
                                        onClick={(platform) => {
                                          if (platform === "copy") {
                                            // Copy to clipboard logic
                                            const textToCopy = `${item?.nameOfInitaitive} - http://52.66.120.170:3004/initiativeDetail`;
                                            navigator.clipboard.writeText(textToCopy).then(() => {
                                              console.log("Text copied to clipboard:", textToCopy);
                                              // You can also show a notification or toast here
                                              toast.success("Link copied to clipboard!");
                                            });
                                          } else {
                                            // Handle other platforms
                                            console.log("Shared successfully on", platform);
                                          }
                                        }}
                                      >
                                        <div className="share">
                                          <img src={Share} alt="Share Icon" className="shareIconImg" />
                                          <span></span>
                                        </div>
                                      </RWebShare> */}
                                    </div>

                                  )}
                                </div>
                                :
                                <></>
                              }
                              {/* {userData?._id === item?.addedBy && (
                                <div
                                  className="dot_btn"
                                  onClick={(e) => handleClick(e, item, index)}
                                  style={{
                                    position: "absolute",
                                    zIndex: "2",
                                    right: "4px",
                                    top: "5px",

                                  }}
                                >
                                  <Link to="/bulletinDetails" className="btn" style={{ color: "#fff" }}>
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                  </Link>
                                  {modalId === item?._id && modalA && (
                                    <div
                                      style={{
                                        position: "relative",
                                        // overflow: "hidden",
                                        backgroundColor: "#fff",
                                        padding: "3px",
                                        border: "none",
                                        // top: "123px",
                                        padding: "3px 8px",
                                        marginLeft: "-27px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                        boxShadow: "0 0 0.1rem 0",
                                      }}
                                    >
                                      <i
                                        style={{ marginRight: "7px", fontSize: "14px" }}
                                        class="fas fa-edit"
                                        onClick={() => setModalB(true)}
                                      ></i>
                                      <i
                                        style={{
                                          margin: "3px",
                                          fontSize: "14px",
                                          color: "red",
                                        }}
                                        class="fa fa-trash"
                                        aria-hidden="true"
                                        onClick={handleDelete}
                                      ></i>
                                    </div>
                                  )}
                                </div>
                              )} */}

                              {/* <div className="vwdnbtn">
                                <button
                                  className="viewbtn" style={{ cursor: "pointer" }}
                                  key={index}
                                  onClick={() => openDetails(item, item._id, index)}
                                >
                                  View */}
                              {/* {viewDetails && index === indx && (
                                  <div key={index} className="modaldescript">
                                    {item.addNotes}
                                  </div>
                                )} */}

                              {/* {viewDetails && (
                    <div className="modaldescript">
                      {selectedDescription}
                    </div>
                  )} */}
                              {/* </button> */}
                              {/* <div className="vsmodalcntnt"></div> */}
                              {/* </div> */}

                            </div>
                          </div>

                        </div>
                        <div className="content_area">
                          {/* <p>Contact-{item?.contact}</p> */}
                          <p>Reward Points-{item?.rewardPoints}</p>
                          <p>Project Type-{item?.initiativeTypeName}</p>

                          <div className="">
                            <div>
                              <p style={{ marginBottom: "5px" }}>Start Date - {moment(item?.startDate)?.format('YYYY-MM-DD')}</p>
                            </div>

                            <div className="startviewflx">
                              <div>
                                <p style={{ marginBottom: "0px" }}>End Date -  {moment(item?.endDate)?.format('YYYY-MM-DD')}</p>
                              </div>
                              <div className="">
                                <button className="viewbtn" onClick={() => handleInitiativeClick(item)}>View</button>
                              </div>
                            </div>

                          </div>
                        </div>

                        {/* <div
                          className="all_profile d-flex align-items-center"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="social_profile">
                            <img
                              src={ProfileImg1}
                              className="img-fluid"
                              alt="profile"
                            />
                          </div>
                          <div
                            className="social_profile"
                            style={{ marginLeft: "-8px" }}
                          >
                            <img
                              src={ProfileImg2}
                              className="img-fluid"
                              alt="profile"
                            />
                          </div>
                          <div
                            className="social_profile"
                            style={{ marginLeft: "-8px" }}
                          >
                            <img
                              src={ProfileImg3}
                              className="img-fluid"
                              alt="profile"
                            />
                          </div>
                          <div
                            className="social_profile"
                            style={{ marginLeft: "-8px" }}
                          >
                            <img
                              src={ProfileImg4}
                              className="img-fluid"
                              alt="profile"
                            />
                          </div>
                        </div> */}
                        {/* <div className="bottom">
                          <div className="attached">
                            <div className="d-flex align-items-center"> */}
                        {/* <Link to="/" className="attached_icon">
                                <img
                                  src={AttachedImg}
                                  className="img-fluid"
                                  alt="attached"
                                />
                              </Link> */}
                        {/* <div className="text">
                                <p>
                                  <span>2</span> file Attached
                                </p>
                              </div> */}
                        {/* </div>
                          </div> */}
                        {/* <div className="comments">
                            <div className="d-flex align-items-center">
                              <Link to="/" className="comments_icon">
                                <img
                                  src={CommentsImg}
                                  className="img-fluid"
                                  alt="comments"
                                />
                              </Link>
                              <div className="text">
                                <p>
                                  <span>10</span> comments
                                </p>
                              </div>
                            </div>
                          </div> */}
                      </div>

                    );
                  })
                ) : (
                  <p>No Project to show!</p>
                )}
                {/* <div className="card_area" style={{ background: "#E9FDFD" }}>
                  <div className="top">
                    <div className="name">
                      <h4>Fashion Store E -commerce </h4>
                    </div>
                    <div className="dot_btn">
                      <Link to="/" className="btn">
                        <img src={ThreeDot} className="img-fluid" alt="dot" />
                      </Link>
                    </div>
                  </div>
                  <div className="content_area">
                    <p>Application Concept</p>
                    <p>Website Concept</p>
                    <p>Project Duration - 2 Months</p>
                  </div>
                  <div
                    className="all_profile d-flex align-items-center"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="social_profile">
                      <img
                        src={ProfileImg1}
                        className="img-fluid"
                        alt="profile"
                      />
                    </div>
                    <div
                      className="social_profile"
                      style={{ marginLeft: "-8px" }}
                    >
                      <img
                        src={ProfileImg2}
                        className="img-fluid"
                        alt="profile"
                      />
                    </div>
                    <div
                      className="social_profile"
                      style={{ marginLeft: "-8px" }}
                    >
                      <img
                        src={ProfileImg3}
                        className="img-fluid"
                        alt="profile"
                      />
                    </div>
                    <div
                      className="social_profile"
                      style={{ marginLeft: "-8px" }}
                    >
                      <img
                        src={ProfileImg4}
                        className="img-fluid"
                        alt="profile"
                      />
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="attached">
                      <div className="d-flex align-items-center">
                        <Link to="/" className="attached_icon">
                          <img
                            src={AttachedImg}
                            className="img-fluid"
                            alt="attached"
                          />
                        </Link>
                        <div className="text">
                          <p>
                            <span>2</span> file Attached
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="comments">
                      <div className="d-flex align-items-center">
                        <Link to="/" className="comments_icon">
                          <img
                            src={CommentsImg}
                            className="img-fluid"
                            alt="comments"
                          />
                        </Link>
                        <div className="text">
                          <p>
                            <span>10</span> comments
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card_area" style={{ background: "#F3E9FD" }}>
                  <div className="top">
                    <div className="name">
                      <h4>Social Media App</h4>
                    </div>
                    <div className="dot_btn">
                      <Link to="/" className="btn">
                        <img src={ThreeDot} className="img-fluid" alt="dot" />
                      </Link>
                    </div>
                  </div>
                  <div className="content_area">
                    <p>Application Design</p>
                    <p>Application Development</p>
                    <p>Project Members - 6 Months</p>
                  </div>
                  <div
                    className="all_profile d-flex align-items-center"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="social_profile">
                      <img
                        src={ProfileImg1}
                        className="img-fluid"
                        alt="profile"
                      />
                    </div>
                    <div
                      className="social_profile"
                      style={{ marginLeft: "-8px" }}
                    >
                      <img
                        src={ProfileImg2}
                        className="img-fluid"
                        alt="profile"
                      />
                    </div>
                    <div
                      className="social_profile"
                      style={{ marginLeft: "-8px" }}
                    >
                      <img
                        src={ProfileImg3}
                        className="img-fluid"
                        alt="profile"
                      />
                    </div>
                    <div
                      className="social_profile"
                      style={{ marginLeft: "-8px" }}
                    >
                      <img
                        src={ProfileImg4}
                        className="img-fluid"
                        alt="profile"
                      />
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="attached">
                      <div className="d-flex align-items-center">
                        <Link to="/" className="attached_icon">
                          <img
                            src={AttachedImg}
                            className="img-fluid"
                            alt="attached"
                          />
                        </Link>
                        <div className="text">
                          <p>
                            <span>2</span> file Attached
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="comments">
                      <div className="d-flex align-items-center">
                        <Link to="/" className="comments_icon">
                          <img
                            src={CommentsImg}
                            className="img-fluid"
                            alt="comments"
                          />
                        </Link>
                        <div className="text">
                          <p>
                            <span>10</span> comments
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </section>
          </div>
        </div>
      </div >
      {/* {
        modalB && (<EditInitiativeModal closemodal={setModalB}
          activity={"editinitiative"}
          initialValues={initiativeData} />)
      } */}

      {viewDetails && <InitiativeDetailsModal closemodal={setViewDetails} initiativeData={initiativeData} />}

      {modalM && (
        <InitiativeModal
          closemodal={setModalM}
          activity={"initiative"}
          initialValues={initialValues6}
        />
      )}
    </section >


  );
}
