import React, { useEffect, useState } from 'react'
// import bokk_img from "../../Images/bokk_img.png";
import {  useNavigate, useParams } from 'react-router-dom';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { useAuthCtx } from '../../context/AuthCtx';

const Orgchartdetails = () => {
    const {userData}=useAuthCtx();
    const navigate=useNavigate();
    const params = useParams();
    const [hrData, setHrData] = useState([]);

    //for view Single Manager tree
    const viewSingleHr = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/get-hr-company-tree/${params?.id}`,
            method: "GET",
        });
        if (response.status) {
            console.log("respovbsdiop", response?.data?.data)
            setHrData(response?.data?.data?.[0])
        }
    }

    useEffect(() => {
        viewSingleHr();
    }, [])

    const AnotherProfile = (id) => {
        if (id === userData?._id) {
            navigate(`/profile`);
        }
        else {
            navigate(`/Profile_rating/${id}`);
        }
    }

    return (
        <>
            <div className="tree">
                <div className='treecontainer'>
                    <ul>
                        <li>
                            <div className=''>
                                <div className='ordgfdtlsdivmain' onClick={()=>AnotherProfile(hrData?._id)}>
                                    <div className='pimfprlimg'>
                                        <img src={hrData?.image} alt="..." />
                                    </div>
                                    <div className=''>
                                        <p className='ordgdtklstxtp'>{hrData?.firstName + " " + hrData?.lastName}</p>
                                        <p className='ordgdtklstxtphhdr'>Admin</p>

                                    </div>
                                </div>
                            </div>
                            <ul class="inn_linemaindiv">
                                <li>

                                    <div className='ordgfdtlsdivmain'>
                                        <div className='ordgfdtlsdivmainflx' onClick={()=>AnotherProfile(hrData?.HRs?.[0]?._id)}>
                                            <div className='pimfprlimg'>
                                                <img src={hrData?.HRs?.[0]?.image} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='ordgdtklstxtp'>{hrData?.HRs?.[0]?.firstName + " " + hrData?.HRs?.[0]?.lastName}</p>
                                                <p className='ordgdtklstxtphhdr'>Hr</p>

                                            </div>
                                        </div>

                                        {/* <Link className='' to="/orgchartdetails">
                                            <p className='ordgdtklstxtphhdrmrdr'> 20+</p>
                                        </Link> */}

                                    </div>

                                    <ul class="inn_line">
                                        {
                                            hrData?.HRs?.[0]?.Managers?.map((managers, i) => {
                                                return (
                                                    <li key={i}>

                                                        <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                            <div className='ordgfdtlsdivmainflx' onClick={()=>AnotherProfile(managers?._id)}>
                                                                <div className='pimfprlimg'>
                                                                    <img src={managers?.image} alt="..." />
                                                                </div>
                                                                <div className=''>
                                                                    <p className='ordgdtklstxtp'>{managers?.firstName + " " + managers?.lastName}</p>
                                                                    <p className='ordgdtklstxtphhdr'>Manager</p>

                                                                </div>
                                                            </div>
                                                            {/* <div className=''>
                                                                <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                            </div> */}
                                                        </div>
                                                        <ul className='speccialdiv'>
                                                            {
                                                                managers?.Employees?.map((employees, ind) => {
                                                                    return (
                                                                        <li className='speccialdivli' key={ind}>
                                                                            <div className='ordgfdtlsdivmaindiff' onClick={()=>AnotherProfile(employees?._id)}>
                                                                                <div className='pimfprlimg'>
                                                                                    <img src={employees?.image} alt="..." />
                                                                                </div>
                                                                                <div className=''>
                                                                                    <p className='ordgdtklstxtp'>{employees?.firstName + " " + employees?.lastName}</p>

                                                                                    <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }

                                                            {/* <li className='speccialdivli'>
                                                                <div className='ordgfdtlsdivmaindiff'>
                                                                    <div className='pimfprlimg'>
                                                                        <img src={bokk_img} alt="..." />
                                                                    </div>
                                                                    <div className=''>
                                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                                        <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className='speccialdivli'>
                                                                <div className='ordgfdtlsdivmaindiff'>
                                                                    <div className='pimfprlimg'>
                                                                        <img src={bokk_img} alt="..." />
                                                                    </div>
                                                                    <div className=''>
                                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                                        <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className='speccialdivli'>
                                                                <div className='ordgfdtlsdivmaindiff'>
                                                                    <div className='pimfprlimg'>
                                                                        <img src={bokk_img} alt="..." />
                                                                    </div>
                                                                    <div className=''>
                                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                                        <p className='ordgdtklstxtphhdr'>Employee</p>
                                                                    </div>
                                                                </div>
                                                            </li> */}

                                                        </ul>

                                                    </li>
                                                )
                                            })
                                        }


                                        {/* <li>

                                            <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                <div className='ordgfdtlsdivmainflx'>
                                                    <div className='pimfprlimg'>
                                                        <img src={bokk_img} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                        <p className='ordgdtklstxtphhdr'>Manager</p>

                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                </div>
                                            </div>
                                            <ul className='speccialdiv'>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>

                                        </li>

                                        <li>

                                            <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                <div className='ordgfdtlsdivmainflx'>
                                                    <div className='pimfprlimg'>
                                                        <img src={bokk_img} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                        <p className='ordgdtklstxtphhdr'>Manager</p>

                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                </div>
                                            </div>
                                            <ul className='speccialdiv'>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>

                                        </li>

                                        <li>

                                            <div className='ordgfdtlsdivmainnewdrtlsdiv'>
                                                <div className='ordgfdtlsdivmainflx'>
                                                    <div className='pimfprlimg'>
                                                        <img src={bokk_img} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>
                                                        <p className='ordgdtklstxtphhdr'>Manager</p>

                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='ordgdtklstxtphhdrmrdr'> 4+</p>
                                                </div>
                                            </div>
                                            <ul className='speccialdiv'>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className='speccialdivli'>
                                                    <div className='ordgfdtlsdivmaindiff'>
                                                        <div className='pimfprlimg'>
                                                            <img src={bokk_img} alt="..." />
                                                        </div>
                                                        <div className=''>
                                                            <p className='ordgdtklstxtp'>Mr. Lorem Ipsum</p>

                                                            <p className='ordgdtklstxtphhdr'>Employee</p>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>

                                        </li> */}

                                    </ul>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Orgchartdetails
