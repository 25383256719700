import React from 'react'

const BioDetailsModal = ({ closemodal, initiativeData }) => {
    return (
        <div className='viedetailsmain' >
            <div className='viewdetailscontent'>
                <div className='bulletintextclose'>
                    <p className='newBulleBrodHead'>Biodetails</p>
                    <div className='closedivred' onClick={() => closemodal()}>
                    <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>
                {/* <div className='closemarkdst'><i class="fa-solid fa-xmark"></i></div> */}
                <div className=''>
                    <div className="details"> 
                        <p className="biodetailstxtopnop">
                            Introduction : <span>{initiativeData?.introduction}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            Education : <span>{initiativeData?.education}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            City : <span>{initiativeData?.city}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            State : <span>{initiativeData?.state}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            Industry : <span>{initiativeData?.industry}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            About : <span>{initiativeData?.about}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            Certificate : <span>{initiativeData?.certificate}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            Phone No.: <span>{initiativeData?.phone}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            E-mail: <span>{initiativeData?.mail}</span>
                        </p>
                        <p className="biodetailstxtopnop">
                            CV :  <a href={initiativeData?.resume?.startsWith("http") ? initiativeData?.resume : `http://${initiativeData?.resume}`} target="_blank" rel="noopener noreferrer">
                                {initiativeData?.resume?.slice(0, 30)}
                            </a>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BioDetailsModal