import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-hot-toast';
// import { getBio } from "../../redux/slices/bioSlice";
import { useDispatch } from "react-redux";
import { ApiHelperFunction } from "../services/api/apiHelpers";
// import Addicon from "../../Images/headericon/Addicon.png"
import add_icon from "../Images/Icons/PNG/Add icon.png"




const EditComModal = ({ closemodal, initialValues }) => {
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
    const [phoneFields, setPhoneFields] = useState(1);
    const [emailFields, setEmailFields] = useState(1);
    const dispatch = useDispatch();
    const [cdetails, setCdetails] = useState([]);


    // const initialValues = {
    //     about: "",
    //     typeOfIndustry: "",
    //     nameOfCeo: "",
    //     city: "",
    //     state: "",
    //     phoneNoOne: cdetails?.registeredPhoneNo,
    //     phoneNoTwo: "",
    //     phoneNoThree: "",
    //     emailIdOne: cdetails?.email,
    //     emailIdTwo: "",
    //     emailIdThree: ""
    // }

    const viewCompanyDetails = async () => {
        const res = await ApiHelperFunction({
            urlPath: "/view-register-company-details",
            method: "GET"
        });
        if (res?.status) {
            setCdetails(res?.data?.data?.[0])
        }
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        setShouldValidateOnChange(true);
        setShouldValidateOnBlur(true);

        let data = {};
        if (
            values.about === "" ||
            values.typeOfIndustry === "" ||
            values.city === "" ||
            values.state === "" ||
            values.nameOfCeo === "" ||
            values.phoneNoOne === "" ||
            values.emailIdOne === ""

        ) {
            return toast.error("All fields required");

        }

        data = {
            about: values?.about,
            typeOfIndustry: values?.typeOfIndustry,
            city: values?.city,
            state: values?.state,
            nameOfCeo: values?.nameOfCeo,
            phoneNoOne: cdetails?.registeredPhoneNo,
            phoneNoTwo: values?.phoneNoTwo,
            phoneNoThree: values?.phoneNoThree,
            emailIdOne: cdetails?.email,
            emailIdTwo: values?.emailIdTwo,
            emailIdThree: values?.emailIdThree
        };

        const res = await ApiHelperFunction({
            urlPath: `/edit-company-details/${initialValues?._id}`,
            method: "PUT",
            data,
        });

        if (res?.status) {
            toast.success("Updated Successfully");
            resetForm();
            // dispatch(getBio());
            closemodal();
        } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
            console.log("ERROR CREATING USER3", res);
        }

        // setLoading(false);
    };


    //for add more functionality for phone no. 
    const addPhoneField = () => {
        if (phoneFields < 3) {
            setPhoneFields(phoneFields + 1);
        }
    };


    //for remove functionality for phone no.
    const removePhoneField = () => {
        if (phoneFields > 1) {
            setPhoneFields(phoneFields - 1);
        }
    };


    //for add more functionality for email 
    const addEmailField = () => {
        if (emailFields < 3) {
            setEmailFields(emailFields + 1);
        }
    };


    //for remove functionality for email
    const removeEmailField = () => {
        if (emailFields > 1) {
            setEmailFields(emailFields - 1);
        }
    };


    const { values, handleBlur, resetForm, handleChange } =
        useFormik({
            initialValues,
            // validationSchema: schemaCheck,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

    useEffect(() => {
        // dispatch(getBio());
        viewCompanyDetails()
    }, [])

    console.log("cdtails", cdetails)
    return (
        <>
            <section className="createmodalnewdiv">
                <div className="createmodalnewdivcontent">

                    <div className="bulletintextclose">
                        <p className="newBulleBrodHead">Company Details</p>
                        <div className="closedivred" onClick={() => closemodal()}>
                            <i className="fa-solid fa-xmark" />
                        </div>
                    </div>

                    <div className="biopdivbmmaibn">
                        <form>
                            <div className="homePgModInnerInpDivsmain">
                                <p> Company Name </p>
                                <input
                                    type="text"
                                    id="education"
                                    aria-describedby="emailHelp"
                                    placeholder=" Name of the Company "
                                    value={cdetails?.companyName}
                                />
                            </div>

                            <div className="homePgModInnerInpDivsmain">
                                <p>Employees Strength </p>
                                <input
                                    type="text"
                                    id="education"
                                    aria-describedby="emailHelp"
                                    placeholder=" Numbers of employees  "
                                    value={cdetails?.employeeStrenght}
                                />
                            </div>

                            <div className="homePgModInnerInpDivsmain">
                                <p>About </p>
                                <textarea
                                    id="about"
                                    placeholder="About your company"
                                    value={values?.about}
                                    name="about"
                                    onBlur={handleBlur}
                                    cols=""
                                    rows="1"
                                    onChange={handleChange}></textarea>

                            </div>
                            <div className="homePgModInnerInpDivsmain">
                                <p> Industry Type </p>
                                <input
                                    type="text"
                                    id="typeOfIndustry"
                                    aria-describedby="emailHelp"
                                    placeholder="Types of Industry"
                                    value={values?.typeOfIndustry}
                                    name="typeOfIndustry"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="homePgModInnerInpDivsmain">
                                <p > Name of the CEO  </p>
                                <input
                                    type="text"
                                    id="nameOfCeo"
                                    aria-describedby="emailHelp"
                                    placeholder="Name of the CEO"
                                    value={values?.nameOfCeo}
                                    name="nameOfCeo"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>


                            <h5 className="boiosdkmcubnjkpkk">Address</h5>

                            <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                <p> Local address  </p>
                                <input
                                    type="text"
                                    id="address"
                                    aria-describedby="emailHelp"
                                    placeholder="Local Address"
                                    value={cdetails?.address}
                                />
                            </div>

                            <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                <p> City  </p>
                                <input
                                    type="text"
                                    id="city"
                                    aria-describedby="emailHelp"
                                    placeholder="City"
                                    value={values?.city}
                                    name="city"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="homePgModInnerInpDivsmain">
                                <p> State  </p>
                                <input
                                    type="text"
                                    id="state"
                                    aria-describedby="emailHelp"
                                    placeholder="State"
                                    value={values?.state}
                                    name="state"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="homePgModInnerInpDivsmain">
                                <p> Country  </p>
                                <input
                                    type="text"
                                    id="Country"
                                    aria-describedby="emailHelp"
                                    placeholder="Country"
                                    value={cdetails?.country}
                                />
                            </div>

                            <h5 className="boiosdkmcubnjkpkk">Contact Information</h5>
                            {/* <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                <p> Phone </p>
                                <input
                                    type="number"
                                    id="phone"
                                    aria-describedby="emailHelp"
                                    placeholder="Phone"
                                    value={values?.phone}
                                    name="phone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div> */}

                            <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                <p> Phone 1 </p>
                                <input
                                    type="number"
                                    id="phoneNoOne"
                                    aria-describedby="emailHelp"
                                    placeholder="Phone"
                                    value={cdetails?.registeredPhoneNo}
                                // name="phoneNoOne"
                                // onBlur={handleBlur}
                                // onChange={handleChange}
                                />
                            </div>
                            {phoneFields >= 2 && (
                                <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                    <p> Phone 2 </p>
                                    <input
                                        type="number"
                                        id="phoneNoTwo"
                                        aria-describedby="emailHelp"
                                        placeholder="Phone"
                                        value={values?.phoneNoTwo}
                                        name="phoneNoTwo"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </div>
                            )}
                            {phoneFields >= 3 && (
                                <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                    <p> Phone 3 </p>
                                    <input
                                        type="number"
                                        id="phoneNoThree"
                                        aria-describedby="emailHelp"
                                        placeholder="Phone"
                                        value={values?.phoneNoThree}
                                        name="phoneNoThree"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </div>
                            )}
                            {phoneFields < 3 && (
                                <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                    <figure className="bulleBoardFig" onClick={addPhoneField}>
                                        <img src={add_icon} alt="..." />
                                    </figure>
                                </div>
                            )}
                            {phoneFields > 1 && (
                                <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                    <div type="button">
                                        <figure className="bulleBoardFig" onClick={removePhoneField}>
                                            <i class="fa-solid fa-trash"></i>
                                        </figure>
                                    </div>
                                </div>
                            )}


                            <div className="homePgModInnerInpDivsmain">
                                {/* <p className="txtrwddibpp"> E-mail </p>
                                <input
                                    type="text"
                                    id="mail"
                                    aria-describedby="emailHelp"
                                    placeholder="E-mail"
                                    value={values?.mail}
                                    name="mail"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                /> */}

                                <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                    <p> Email 1 </p>
                                    <input
                                        type="email"
                                        id="emailIdOne"
                                        aria-describedby="emailHelp"
                                        placeholder="E-mail"
                                        value={cdetails?.email}
                                        name="emailIdOne"
                                    // onBlur={handleBlur}
                                    // onChange={handleChange}
                                    />
                                </div>
                                {emailFields >= 2 && (
                                    <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                        <p> Email 2 </p>
                                        <input
                                            type="email"
                                            id="emailIdTwo"
                                            aria-describedby="emailHelp"
                                            placeholder="E-mail"
                                            value={values?.emailIdTwo}
                                            name="emailIdTwo"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                )}
                                {emailFields >= 3 && (
                                    <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                        <p> Email 3 </p>
                                        <input
                                            type="email"
                                            id="emailIdThree"
                                            aria-describedby="emailHelp"
                                            placeholder="E-mail"
                                            value={values?.emailIdThree}
                                            name="emailIdThree"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </div>
                                )}
                                {emailFields < 3 && (
                                    <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                        <div type="button">
                                            <figure className="bulleBoardFig" onClick={addEmailField}>
                                                <img src={add_icon} alt="..." />
                                            </figure>
                                        </div>
                                    </div>
                                )}
                                {emailFields > 1 && (
                                    <div className="homePgModInnerInpDivsmain" style={{ borderBottom: "0px solid #fff" }}>
                                        <div type="button">
                                            <figure className="bulleBoardFig" onClick={removeEmailField}>
                                                <i class="fa-solid fa-trash"></i>
                                            </figure>
                                        </div>
                                    </div>
                                )}
                            </div>


                            <div className="homePgCreModSubmitDiv" style={{ marginTop: "10px" }}>
                                <button
                                    type="button"
                                    className="homePgCreModSubmitBtn"
                                    onClick={submitHandler}
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default EditComModal