import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-hot-toast';

import { useDispatch } from 'react-redux';
import { getEventData } from '../../redux/slices/eventSlice';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Event_View from "../../../src/Images/Icons/PNG/Event_View.png"
import { useAuthCtx } from '../../context/AuthCtx';
import { ApiHelperFunction, fileUpload } from '../../services/api/apiHelpers';
import './ClaimPointModal.css';
import rewardpoints from "../../../src/Images/headericon/rewardpoints.png";

const animatedComponents = makeAnimated();

const ApproveClaimPointModal = ({ id, closemodal, activity, initialValues, isManagerApproved }) => {
    // console.log("initialValuesty", initialValues)
    const [isApprove, setApprove] = useState("pending");
    // const { setLoading, userData } = useAuthCtx();
    // const [imageURL, setImageURL] = useState("");
    // const dispatch = useDispatch();
    const [localerror, setlocalerror] = useState("");
    // const [image, setImage] = useState("");
    // const [uploading, setUploading] = useState(false);
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const [empData, setempData] = useState([]);
    const [claimPoints, setClaimPoint] = useState("");
    const [eventTypeId, seteventTypeId] = useState([]);
    const [colourOptionseventTypeId, setColourOptionseventTypeId] = useState([]);
    const [selectedOptionseventTypeId, setSelectedOptionseventTypeId] = useState(initialValues?.activityId);
    const [groupData, setGroupData] = useState([]);
    const [colourOptionsGroup, setColourOptionsGroup] = useState([]);
    const [selectedOptionsGroup, setSelectedOptionsGroup] = useState(initialValues?.behaviourId);
    // const [isPublic, setIsPublic] = useState(false);
    // const [postType, setPostType] = useState("");
    // const [selectedOptionsPostType, setSelectedOptionsPostType] = useState("");


    useEffect(() => {
        getEmployeeData();
        getEventTypeData();
        getMyGroupData();
    }, [])

    useEffect(() => {
        const data = initialValues?.receiverMail
        const mentorData = data?.map((item) => {
            const optData = colourOptions?.find(ele => ele?.value === item)
            return optData ? optData : {}
        })
        setSelectedOptions(mentorData)
    }, [colourOptions])

    useEffect(() => {
        setApprove(isManagerApproved);
    }, [isManagerApproved]);


    // console.log("ColourOptionsGroup", initialValues?.claimPoints)

    //for selecting beneficiaries
    // const handleSelectChange = (e) => {
    //     setSelectedOptions(e);
    // };

    // //for selecting activities
    // const handleSelectChangeEventType = (e) => {
    //     // console.log("rtv", e)
    //     setSelectedOptionseventTypeId(e);
    //     setClaimPoint(e.point)
    // };

    // //for selecting behaviuoral shperes
    // const handleSelectChangeGroup = (e) => {
    //     setSelectedOptionsGroup(e);
    // };


    //get all beneficiaries
    const getEmployeeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-company-employees`,
            method: "GET",
        });

        console.log("RESPONSEF", response?.data?.data);

        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
            }));

            setColourOptions(formattedOptions);
            setSelectedOptions(formattedOptions?.filter(e => e.value === initialValues.receiverMail))
            setempData(response?.data?.data);
        } else {
            // toast.error(response.message);
        }
    };

    //get activities
    const getEventTypeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/get-points`,
            method: "GET",
        });

        console.log("RESPONSEWF", response?.data?.data);

        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.activity,
                point: item?.point
            }));

            setColourOptionseventTypeId(formattedOptions);
            setSelectedOptionseventTypeId(formattedOptions?.find(e => e.value === initialValues?.activityId))
            seteventTypeId(response?.data?.data);
            setClaimPoint(response?.data?.data?.point)
        } else {
            // toast.error(response.message);
        }
    };

    // console.log("setSelectedOptionseventTypeId", setSelectedOptionsGroup)

    //get behaviuoral shperes
    const getMyGroupData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/get-sphare`,
            method: "GET",
        });

        console.log("RESPONSEWTF", response?.data?.data);

        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.name,
            }));

            setColourOptionsGroup(formattedOptions);
            setSelectedOptionsGroup(formattedOptions?.find(e => e.value === initialValues?.behaviourId))
            setGroupData(response?.data?.data);
        } else {
            // toast.error(response.message);
        }
    };



    //for approve functionality
    const handleClaimPointApprove = async (approvalStatus) => {
        let data = {
            isManagerApproved: approvalStatus,
        };

        let response = await ApiHelperFunction({
            urlPath: `/approval-claim-points/${id}`,
            method: "POST",
            data: data,
        });

        if (response.status === 200) {
            // console.log("responseadmin", response?.data);
            if (response?.data?.status) {
                // toast.success(response?.data?.message);


                // if (approvalStatus == "true") {
                //     setApprove(true);
                // } else if (approvalStatus == "false") {
                //     setApprove(false);
                // } else if (approvalStatus == "defer") {
                //     // toast?.success(response?.data?.message)
                //     setApprove(false);

                // } else {
                //     setApprove(false);
                // }

                if (approvalStatus) {
                    setApprove(approvalStatus);
                }
                toast.success(response?.data?.message);

            } else {
                // toast.error(response?.data?.message);
                // toast.success("Server Error");
                // console.log("ypoiu",response?.data?.message)

            }
        } else {
            // toast.success("Server Error");

        }
        closemodal();
    };

    //for schemaCheck
    const schemaCheck = Yup.object(
        activity === "billBoard" ||
            activity === "events" ||
            activity === "kababMenu" ||
            activity === "editEvent"
            ? {
                eventName: Yup.string().required("Event Name is required"),
                hostedBy: Yup.string().required("Host name is required"),
                eventDate: Yup.string().required("Event date is required"),
                eventstarttime: Yup.string().required("start Time is required"),
                eventendtime: Yup.string().required(" End Time is required"),
                notes: Yup.string().required(" Notes is required"),
                addinvites: Yup.string().required(" Addinvites is required"),
                lattitude: Yup.string().required(" lattitude is required"),

                longitude: Yup.string().required(" longitude is required"),
            }
            : activity === "training" || activity === "editTraining"
                ? {
                    name: Yup.string().required("Event Name is required"),
                    trainingDate: Yup.string().required("Training date is required"),
                }
                : activity === "jobReffered"
                    ? {
                        name: Yup.string().required("Job Name is required"),
                        location: Yup.string().required("Job location is required"),
                        salary: Yup.string().required("Salary range is required"),
                        description: Yup.string().required("Description range is required"),
                        opening: Yup.string().required("Opening is required"),
                    }
                    : ""
    );

    const { values, errors, handleBlur, handleChange } =
        useFormik({
            initialValues,
            validationSchema: schemaCheck,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });
    // console.log("claimPoints", claimPoints)
    return (
        <div className="createmodal_main">
            <div className="createmodalnewdiv">
                <div className="createmodalnewdivcontent">
                    <div
                        className=""
                    >
                        <div className="bulletintextclose">
                            <div className="bulletinimageflxdivmodal">
                                <div className="bulleBoardFig">
                                    <img src={rewardpoints} alt="..." />
                                </div>
                                <span className="newBulleCreHead">Claim Point</span>
                            </div>

                            <div onClick={() => closemodal()}>
                                <i
                                    class="fa-solid fa-xmark"
                                    style={{
                                        color: "red",
                                        fontSize: "22px",
                                        cursor: "pointer",
                                    }}
                                ></i>
                            </div>
                        </div>

                        <div className='formhgfthdivmain'>
                            <form

                                className=""
                            >

                                <div className="homePgModInnerInpDivsmain">
                                    <p>Activity</p>

                                    {/* <Select
                                        placeholder={"Select Activity"}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={selectedOptionseventTypeId?.label}
                                        options={colourOptionseventTypeId}
                                    // options={selectedOptionseventTypeId?.label}
                                    // onChange={handleSelectChangeEventType}
                                    /> */}
                                    <input
                                        type='text'
                                        value={selectedOptionseventTypeId?.label}
                                        // name="collaboratorMail"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="addNoteCreModTextArea"
                                    ></input>

                                </div>

                                <div className="homePgModInnerInpDivsmain">
                                    <p>Claim Points</p>
                                    <input
                                        type='number'
                                        value={initialValues?.claimPoints}
                                        name="claimPoints"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="addNoteCreModTextArea"
                                    ></input>
                                    <small id="emailHelp_point" style={{ color: "red" }}>
                                        {localerror}
                                        {errors?.claimPoints}
                                    </small>
                                </div>

                                <div className="homePgModInnerInpDivsmain">
                                    <p>Contribution Behaviour Shpreres</p>

                                    {/* <Select
                                        placeholder={"Select Contribution Behaviour Shpreres"}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={[]}
                                        options={colourOptionsGroup}
                                        onChange={handleSelectChangeGroup}
                                    /> */}
                                    <input
                                        type='text'
                                        value={selectedOptionsGroup?.label}
                                        // name="collaboratorMail"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="addNoteCreModTextArea"
                                    ></input>

                                </div>


                                <div className="homePgModInnerInpDivsmain">
                                    <p>Describe the contribution Details</p>
                                    <textarea
                                        value={values?.desc}
                                        name="desc"
                                        id=""
                                        cols=""
                                        rows="4"
                                        onBlur={handleBlur}
                                        // onChange={handleChange}
                                        className="addNoteCreModTextArea"
                                    ></textarea>
                                    <small id="emailHelp_point" style={{ color: "red" }}>
                                        {localerror}
                                        {errors?.desc}
                                    </small>
                                </div>

                                <div className="homePgModInnerInpDivsmain">
                                    <p>Add Collaborators (email ids)</p>
                                    <input
                                        type='email'
                                        value={initialValues?.collaboratorMail}
                                        name="collaboratorMail"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="addNoteCreModTextArea"
                                    ></input>
                                    <small id="emailHelp_point" style={{ color: "red" }}>
                                        {localerror}
                                        {errors?.notes}
                                    </small>
                                </div>


                                <div className="homePgModInnerInpDivsmain">
                                    <p>Add Recipients or Beneficiaries</p>
                                    {/* <Select
                                        placeholder={"Select Beneficiaries"}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={[]}
                                        isMulti
                                        options={colourOptions}
                                        //selectedOptions
                                        onChange={handleSelectChange}
                                    /> */}

                                    <Select
                                        placeholder={"Select Beneficiaries"}
                                        closeMenuOnSelect={false}
                                        // components={animatedComponents}
                                        value={selectedOptions}
                                        // defaultValue={[]}
                                        isMulti
                                        options={colourOptions}
                                    // onChange={handleSelectChange}
                                    />

                                    {/* <input
                                        type='text'
                                        value={selectedOptions?.label}
                                        // name="collaboratorMail"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="addNoteCreModTextArea"
                                    ></input> */}

                                </div>


                                <div className="bulletinsbmtbtndivv">

                                    {/* <button
                                        type="button"
                                        className="bulletinsbmtbtn"
                                        onClick={submitHandler}
                                    >
                                        Submit
                                    </button> */}


                                    {isManagerApproved === "pending" ? (
                                        <>
                                            <div className="btnacpt btndivmainflx">
                                                <button
                                                    type='button'
                                                    className="btndivaprrcj"
                                                    // style={{ marginLeft: "4rem" }}
                                                    onClick={() => handleClaimPointApprove("true")}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    type='button'
                                                    className="btndivaprrcj"
                                                    // style={{ marginRight: "4rem" }}
                                                    onClick={() => handleClaimPointApprove("false")}
                                                >
                                                    Reject
                                                </button>

                                            </div>
                                        </>
                                    ) : isManagerApproved === "true" ? (
                                        <div className="apprdiv">
                                            <button type="button" className="btnapprovd">Approved</button>
                                        </div>
                                    ) : (
                                        <button type="button" className="btnapprovde">Rejected</button>
                                    )}


                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div >
        </div >

    )
}

export default ApproveClaimPointModal