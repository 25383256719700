import React from 'react'
import Fancybox from '../../Component/FAncyBox'

const SeeMoreModal = ({ closemodal,singleAffGroup }) => {
    const handlesubmitstpClick=(event)=>{
        event.stopPropagation()
    }
    // console.log(initiativeData, "ppppp")
    return (
        <div className='seemoredivmain' onClick={() => closemodal()}>
            <div className='seemoredivmaincontent' onClick={(event)=>handlesubmitstpClick(event)}>
                <div className='closemarkdst' onClick={() => closemodal()}><i class="fa-solid fa-xmark"></i></div>
                <div className=''>
                    <div className="details">
                        <p className='purpsetextpp'>{singleAffGroup?.purpose}</p>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default SeeMoreModal