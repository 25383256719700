import React from 'react'

const EmployeeView = ({ selectedDescription, closemodal }) => {
    return (
        <>
            <div className='viedetailsmain' onClick={() => closemodal()}>
                <div className='viewdetailscontent'>
                    <div className='closemarkdst'><i class="fa-solid fa-xmark"></i></div>
                    <div className=''>
                        <h5>Reward breaking Points</h5>
                        <div className="">
                            <p>
                                Coming on hallfame : {selectedDescription?.comingonhallfame}
                            </p>
                            <p>
                                Comment : {selectedDescription?.comment}
                            </p>
                            <p>
                                Complete Onboarding : {selectedDescription?.completeOnboarding}
                            </p>
                            <p>
                                Joining Affinity Groups : {selectedDescription?.joiningAffinityGroups}
                            </p>

                            <p>
                                Post : {selectedDescription?.post}
                            </p>

                            <p>
                                Publishing Advocacy : {selectedDescription?.publishingAdvocacy}
                            </p>

                            <p>
                                React : {selectedDescription?.react}
                            </p>

                            <p>
                                Survey Completion : {selectedDescription?.surveyCompletion}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeView