import React, { useEffect, useState } from 'react'
import Hiringicon from "../../Images/Icons/PNG/Hiringicon.png"
import { useDispatch, useSelector } from 'react-redux';
import { RWebShare } from 'react-web-share';
import toast from "react-hot-toast";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import { getRefferedJobs } from "../../redux/slices/jobRefferedSlice";
import { useAuthCtx } from '../../context/AuthCtx';
import table_icon from "../../Images/Icons/PNG/share-icon-elite-web.svg"
import HiringDetailsModal from '../../SeprateModal/HiringDetailsModal';
import { useLocation, useNavigate } from 'react-router-dom';
import ReferModal from '../../Component/Modal/ReferModal';
import JobApplyModal from '../../Component/Modal/JobApplyModal';

const Jobssingledetails = () => {
    const domainName = window.location.hostname;
    const splitedDName = domainName?.split('.')[0];
    const jobs = useSelector((state) => state?.jobSlice?.jobs);
    const [modalId, setModalId] = useState("");
    const [modalA, setModalA] = useState(false);
    const dispatch = useDispatch();
    const { userData, getUserDetails } = useAuthCtx();
    const [jobrefferedData, setJobrefferedData] = useState({});
    const [top, setTop] = useState(null);
    const [left, setLeft] = useState(null);
    const [modalB, setModalB] = useState(false);
    const [hiringviewmodal, setHirringviewmodal] = useState(false);
    const [refer, setRefer] = useState(false);
    const [applyModal, setApplyModal] = useState(false);
    const navigate = useNavigate();

    //for delete functionality
    const handleDelete = async () => {
        const res = await ApiHelperFunction({
            urlPath: `/delete-job-reffered/${modalId}`,
            method: "DELETE",
        });
        if (res?.status) {
            toast.success("Job is deleted successfully");

            dispatch(getRefferedJobs());
            setModalA(false);
        } else {
            toast.error(res?.message || "Something went wrong");
            console.log("ERROR CREATING USER3", res);
        }
    };


    useEffect(() => {
        dispatch(getRefferedJobs());
        getUserDetails();
    }, []);

    //for hiring click
    const handlehiringClick = (ele) => {
        setJobrefferedData(ele);
        setHirringviewmodal(true);
        setRefer(false);
        setApplyModal(false);
        navigate(`/jobssingledetails/${ele?.jobTitle?.replace(/\s+/g, '-')}/${ele?._id}`);
    };


    const handleClick = (e, ele, index) => {
        setModalId(ele._id);
        setJobrefferedData(ele);
        setTop(e.clientY);
        setLeft(e.clientX);
        setModalA(!modalA);
    };
    return (
        <>
            <section className='jobssingledetailsmain'>
                <div className='employeepagecontainer'>
                    <div className='jobssingledetailsflx'>
                        <div className='jobsreferredmainbgdiv'>
                            <div className="jobsreferredmainbgsingle">
                                <section id="jobs_referreddetilas">
                                    <div className="top_area">
                                        <div className="head jntxtimgflx">
                                            <div className="initaimg">
                                                <img src={Hiringicon} alt="..." />
                                            </div>
                                            <h4>Hiring</h4>
                                        </div>
                                        <div>
                                        </div>
                                    </div>

                                    <div className="bottom_area_details bottomareadibsingle">

                                        {jobs?.length > 0 ? (
                                            jobs?.map((item, index) => {
                                                return (
                                                    <div className="bottom_area_detailsdiv bottomdtlsdivare">
                                                        <div className='bottom_area_detailsdivdivflx'
                                                        >

                                                            <div className="jobsredferdrttgdibsingldivbmah" style={{cursor:"pointer"}} onClick={() => handlehiringClick(item)}>
                                                                <div className="head">
                                                                    <h4 className='jobtitleredrdtlsffpprdtxtpp'>{item?.jobTitle}</h4>
                                                                </div>


                                                                {/* <div className="shredotflxdiv">
                                                                    <div className="">
                                                                        <RWebShare
                                                                            data={{
                                                                                text: `${item?.jobTitle}`,
                                                                                url: `http://${splitedDName}.fractalselite.com/jobssingledetails/${item?.jobTitle?.replace(/\s+/g, '-')}/${item?._id}`,
                                                                                title: "Share job",
                                                                            }}
                                                                            sites={[
                                                                                "linkedin",
                                                                                "facebook",
                                                                                "twitter",
                                                                                "whatsapp",
                                                                                "mail",
                                                                                "copy",
                                                                            ]}


                                                                            onClick={(platform) => {
                                                                                if (platform === "copy") {

                                                                                    const textToCopy = `http://${splitedDName}.fractalselite.com/jobssingledetails/${item?.jobTitle?.replace(/\s+/g, '-')}/${item?._id}`;
                                                                                    navigator.clipboard.writeText(textToCopy).then(() => {
                                                                                        console.log("Text copied to clipboard:", textToCopy);

                                                                                        toast.success("Link copied to clipboard!");
                                                                                    });
                                                                                } else {

                                                                                    console.log("Shared successfully on", platform);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className="share">
                                                                                <img src={table_icon} alt="..." className="shareIconImg" />
                                                                                <span></span>
                                                                            </div>
                                                                        </RWebShare>
                                                                    </div>



                                                                    {userData?._id === item?.jobRefferedBy && (
                                                                        <div
                                                                            className="dot_btn"
                                                                            onClick={(e) => handleClick(e, item, index)}
                                                                            style={{
                                                                                position: "relative",
                                                                                zIndex: "2",
                                                                                left: "8px",
                                                                                bottom: "-5px",
                                                                            }}
                                                                        >
                                                                            <a className="btn">
                                                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                                                            </a>
                                                                            {modalId === item?._id && modalA && (
                                                                                <div
                                                                                    style={{
                                                                                        overflow: "hidden",
                                                                                        backgroundColor: "#fff",
                                                                                        padding: "3px",
                                                                                        border: "none",
                                                                                        top: "32px",
                                                                                        padding: "3px 8px",
                                                                                        marginLeft: "-27px",
                                                                                        borderRadius: "4px",
                                                                                        cursor: "pointer",
                                                                                        boxShadow: "0 0 0.1rem 0",
                                                                                        position: "absolute"
                                                                                    }}
                                                                                >

                                                                                    <i
                                                                                        style={{
                                                                                            marginRight: "7px",
                                                                                            fontSize: "14px",
                                                                                        }}
                                                                                        class="fas fa-edit"
                                                                                        onClick={() => setModalB(true)}
                                                                                    ></i>
                                                                                    <i
                                                                                        style={{
                                                                                            margin: "3px",
                                                                                            fontSize: "14px",
                                                                                            color: "red",
                                                                                        }}
                                                                                        class="fa fa-trash"
                                                                                        aria-hidden="true"
                                                                                        onClick={handleDelete}
                                                                                    ></i>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div> */}

                                                                <div className="details">
                                                                    <p className='lbndapplicdtgsiucittxygjhgjh'>
                                                                        Location: {item?.jobLocation} 
                                                                    </p>
                                                                    <p className='lbndapplicdtgsiucittxygjhgjh'>
                                                                        Skill: {item?.keySkills}
                                                                    </p>
                                                                    <p className='lbndapplicdtgsiucittxygjhgjh'>
                                                                        Opening:  {item?.openings} 
                                                                    </p>
                                                                    <div className="dscrptviewflxdiv">
                                                                        {/* <p>
                                                                        Description:
                                                                        <span>{item?.description}</span>
                                                                    </p> */}
                                                                        {/* <div className="" onClick={() => handlehiringClick(item)}>
                                                                            <button className="viewbtnnn" >View</button>
                                                                        </div> */}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="cmpltdrbtnkbhagnmain">
                                                                <button className="cmpltdrbtnkbhagn">Active</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <p>No jobs to show! </p>
                                        )}
                                    </div>
                                </section>
                            </div>
                        </div>
                        {/* <div className=''>
                            <HiringDetailsModal jobrefferedData={jobrefferedData} closemodal={setHirringviewmodal} setRefer={setRefer}
                                setApplyModal={setApplyModal} />
                        </div> */}
                        {/* {hiringviewmodal &&  */}

                        <HiringDetailsModal
                            closemodal={setHirringviewmodal}
                            jobrefferedData={jobrefferedData}
                            setRefer={setRefer}
                            setApplyModal={setApplyModal}
                            id={jobrefferedData?._id}
                        />
                        {/* } */}

                        {/* {
                            console.log("bnmk", jobrefferedData?._id)
                        } */}

                        {
                            refer && (<ReferModal closemodal1={setRefer} id={jobrefferedData?._id} />)
                        }

                        {
                            applyModal && (<JobApplyModal closemodal1={setApplyModal} id={jobrefferedData?._id} />)
                        }
                    </div>
                </div>
            </section>

        </>
    )
}

export default Jobssingledetails
