import React from "react";
import "./Hub.css";
import HubFirst from "./HubFirst";
import HubSecond from "./HubSecond"

function HubPage() {
  return (
    <section>
      <HubFirst />
      <HubSecond />
    </section>
  );
}

export default HubPage;
