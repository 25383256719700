import React, { useEffect, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png"
import Select from "react-select";
import { ApiHelperFunction, fileUpload } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';

const Advocacyaddform = () => {
    const iniData = {
        AdvocacyName: "",
        advocacyCatId: "",
        desc: "",
        language: ""
    }
    const [formData, setFormData] = useState(iniData);
    const [uploading, setUploading] = useState(false);
    const [image, setImage] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [uploadingFile, setUploadingFile] = useState(false);
    const [fileURL, setFileURL] = useState("");
    const [fileData, setFile] = useState("");
    const [catdata, setCatData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    //for onChange functionality
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }))
    }

    //for thumbnail image uploading
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await fileUpload("/image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploading(false);
    };

    //for file uploading
    const handleFileChange = async (e) => {
        let image = e.target.files[0];
        setUploadingFile(true);

        const form = new FormData();
        form.append("image", image);

        let res = await fileUpload("/image-upload", "POST", form);

        if (res.status) {
            toast.success("File is uploaded successfully");
            setFileURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploadingFile(false);
    };

    //for getting all Category data
    const getCategoryData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-advocacy-category`,
            method: "GET",
        });
        // console.log("RESPONSEF", response?.data?.data);
        if (response && response.status) {
            setCatData(response?.data?.data);
        } else {
            // toast.error(response.error);
        }
    };


    //for grivance section
    const handleSubmit = async () => {
        if (formData?.AdvocacyName === "") {
            toast.error("Document Type is required");
            return false;
        }

        if (formData?.language === "") {
            toast.error("Language is required");
            return false;
        }

        if (formData?.advocacyCatId === "") {
            toast.error("Advocacy catagory is required");
            return false;
        }

        if (imageURL === "") {
            toast.error("Advocacy image is required");
            return false;
        }

        if (formData?.desc === "") {
            toast.error("Description is required");
            return false;
        }

        if (fileURL === "") {
            toast.error("Material is required");
            return false;
        }

        let data = {
            AdvocacyName: formData?.AdvocacyName,
            advocacyCatId: formData?.advocacyCatId,
            language: formData?.language,
            image: imageURL,
            desc: formData?.desc,
            material: fileURL
        };
        // console.log("documenteradvocacydata", data)
        // return false
        let response = await ApiHelperFunction({
            urlPath: `/add-advocacy`,
            method: "POST",
            data,
        });
        // console.log("clickqqqq", response);
        if (response && response.status === 200) {
            toast.success(response?.data?.message);
            // setFormData([iniData])
            setFormData(iniData)
            setImageURL("");
            setFileURL("");

        } else {
            toast.error(response?.response?.data?.message);
        }
    };


    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    useEffect(() => {
        getCategoryData();
    }, [])

    return (
        <>
            <section className='advocacyaddformmain'>
                <div className='employeepagecontainer'>

                    <div className="advocacyfrmdivmain">
                        <form>
                            <div className=''>
                                <div className='videotitledesrtpupldflx'>
                                    <div className="vdoulddivpbupldg" >
                                        <p className="rsntxrpnewtxt">Upload Image  <span class="required">*</span></p>
                                        <div className="bgcontainerupldboxsgwgrdiv">
                                            <div className="bgcrd">
                                                <div className="bgcontaineruplddivnew">
                                                    <div className="logouplddiv">
                                                        <img
                                                            src={Addicon}
                                                            alt="..."
                                                        />
                                                        <div className="">
                                                            <input
                                                                type="file"
                                                                className="upldimagediv"
                                                                name="image"
                                                                // defaultValue=""
                                                                value={image}
                                                                onChange={handleImageChange}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>

                                                <p className="upldtxtppdiv">Upload min 5 mb image</p>
                                            
                                                {uploading ? <p>image uploading......</p> : null}
                                                <div className="bgcontainerupldfiledivabslt">
                                                    {imageURL !== "" && (
                                                        <div className='imageuplddiv' >
                                                            <img

                                                                src={imageURL}
                                                                alt=""

                                                            />
                                                            <div className='imageupldcrs'
                                                                onClick={() => {
                                                                    setImageURL("");
                                                                    setImage("");
                                                                }}
                                                            >
                                                                <i
                                                                    class="fa-solid fa-xmark"

                                                                ></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vdoulddivpbflxwh">
                                        <p className="rsntxrpnewtxt">Upload Documents <span class="required">*</span> </p>
                                        <div className="uploaddocumentbrdraddbtnflx">
                                            <div className="uploaddocumentbrdraddbtn">
                                                <div className="bgcontainerupldboxsgwgrdiv">
                                                    <div className="bgcrd">
                                                        <div className="bgcontaineruplddivnew">
                                                            <div className="logouplddiv">
                                                                <img
                                                                    src={Addicon}
                                                                    alt="..."
                                                                />
                                                                <div className="">
                                                                    <input
                                                                        type="file"
                                                                        className="upldimagediv"
                                                                        name="fileData"
                                                                        defaultValue=""
                                                                        value={fileData}
                                                                        onChange={handleFileChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="upldtxtppdiv">Upload Document</p>

                                                        {uploading ? <p>image uploading......</p> : null}

                                                        <div className="bgcontainerupldfiledivabslt">
                                                            {fileURL !== "" && (
                                                                <div className='imageuplddiv' >
                                                                    <img

                                                                        src={fileURL}
                                                                        alt=""

                                                                    />
                                                                    <div className='imageupldcrs'
                                                                        onClick={() => {
                                                                            setFileURL("");
                                                                            setFile("");
                                                                        }}
                                                                    >
                                                                        <i
                                                                            class="fa-solid fa-xmark"

                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="typstringtxtppcrt">Select Category
                                        <span class="required">*</span></p>
                                    <select id=""
                                        className="typstrningwhselctnew"
                                        name="advocacyCatId"
                                        value={formData?.advocacyCatId}
                                        onChange={(e) => handleChange(e)}
                                    >

                                        <option value={""} disabled="">
                                            Select option
                                        </option>
                                        {catdata?.map((item, i) => (
                                            <option key={i} value={item?._id}>
                                                {item?.categoryName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="typstringtxtppcrt">Advocacy Language</p>
                                    <input
                                        type="text"
                                        className="typstrningwhselctnew"
                                        name="language"
                                        value={formData?.language}
                                        defaultValue=""
                                        placeholder='Advocacy Language'
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="typstringtxtppcrt">Advocacy Title

                                        <span class="required">*</span></p>
                                    <input
                                        type="text"
                                        className="typstrningwhselctnew"
                                        // defaultValue=""
                                        name="AdvocacyName"
                                        value={formData?.AdvocacyName}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt"> Description <span class="required">*</span></p>
                                    <textarea
                                        className="txtdivallartadvcacytfnew"
                                        id="w3review"
                                        rows={4}
                                        cols={50}
                                        defaultValue={""}
                                        name="desc"
                                        value={formData?.desc}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>

                                {/* <div className="kywrdscrdtfredvdivflx">
                                    <div className="trainingpageleftdivmainnew">
                                        <p className="typstringtxtppcrt">Keywords
                                            <span class="required">*</span>
                                        </p>
                                        <input
                                            type="text"
                                            className="typstrningwhselctnew"

                                        />
                                    </div>
                                    <div className="trainingpageleftdivmainnew">
                                        <p className="typstringtxtppcrt">Credit/Reference

                                            <span class="required">*</span></p>
                                        <input
                                            type="text"
                                            className="typstrningwhselctnew"

                                        />
                                    </div>
                                </div> */}




                                {/* <div className="trainernameselectflx">
                                            <div className="trainernametextinputdiv">
                                                <p className="rsntxrpnewtxt">Advocacy Name  <span class="required">*</span></p>
                                                <input
                                                    type="text"
                                                    className="texttraingrightdiv"
                                                    name="trainerName"
                                                    defaultValue=""
                                                />
                                            </div>

                                            <div className='trainernametextinputdiv'>
                                                <p className="rsntxrpnewtxt">
                                                    Advocacy Category(if any) <span class="required">*</span>
                                                </p>

                                                <select id="" className="typstrningwhselct" name="trainingCategory">
                                                    <option value="" disabled="">
                                                        Select option
                                                    </option>
                                                    <option value="011">011</option>
                                                    <option value="001">001</option>
                                                    <option value="abc">abc</option>
                                                </select>

                                            </div>
                                        </div> */}




                                {/* Upload Documents Sectionc */}





                            </div>
                            <div className="homePgCreModSubmitDivbtnkl">
                                <button className="homePgCreModSubmitBtn" type="button" onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>


                    {/* <div className='trainingcreatepageleft'>
                            <div className='trainingcreatepageleftbrdr' style={{ border: "none" }}>
                                <div className="">
                                    <form> */}



                    {/* For advocacy Languagae */}
                    {/* <div className="trainingpageleftdivmain">
                                            <p className="typstringtxtppcrt">Advocacy Language</p>
                                            <input
                                                type="text"
                                                className="typstrningwhselct"
                                                name="language"
                                                value={formData?.language}
                                                defaultValue=""
                                                placeholder='Advocacy Language'
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div> */}


                    {/* <div className="trainingpageleftdivmain">
                                            <p className="typstringtxtppcrt">Language
                                                <span class="required">*</span>
                                            </p>
                                            <input
                                                type="text"
                                                className="typstrningwhselct"
                                                name="language"
                                                defaultValue=""
                                            />
                                        </div> */}
                    {/* <div className="trainingpageleftdivmain">
                                            <p className="typstringtxtppcrt">Thumbnail Image</p>
                                            <div className="bgcontainerupldbrdr">
                                                <div className="bgcrd">
                                                    <div className="bgcontainerupld">
                                                        <div className="logoupld">
                                                            <img
                                                                src={Addicon}
                                                                alt="..."
                                                            />
                                                            <div className="">
                                                                <input
                                                                    type="file"
                                                                    className="upldimagediv"
                                                                    accept="image/*"
                                                                    name="image"
                                                                    defaultValue=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="upldtxtppdiv">Upload min 5 mb image</p>
                                                </div>
                                            </div>
                                        </div> */}
                    {/* </form>
                                </div>

                            </div>
                        </div> */}

                </div>


            </section>
        </>
    )
}

export default Advocacyaddform
