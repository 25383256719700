import React from 'react'
import sorry from "../../Images/sorry.png"
import { useNavigate } from 'react-router-dom'
const Reytakequizemodal = ({closemodal}) => {
    const navigate=useNavigate();
  return (
    <div className='allcardmodalbgmain ' 
    // onClick={() => closemodal()}
    >
            <div className='congratsdivcard'>
             

                <div className='trphyimhgmaindivmnain'>
                    <div className='trphyimhgmain'>
                        <img src={sorry} alt="..." />
                    </div>
                    <div className='congarttscyyppdiv'>
                        <h4 className='congarttscyypp'>Sorry!</h4>
                        <p className='ablernytxtpp'>Do you want to retake your quize ?
                        </p>
                    </div>

                    <div className='sbmtdbtbhmaiondivdivflx'>
                    <button className="sbmtdbtbhmaiondivno" onClick={()=>navigate(-1)}>No</button>
                        <button className="sbmtdbtbhmaiondivyes">Yes</button>
                     
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Reytakequizemodal
