import React, { useState } from 'react'
import "./Attendence.css";
import CalenderAttendence from './CalenderAttendence'
import Addprojectrightcomp from './Addprojectrightcomp';
import Attendenceworktable from './Attendenceworktable';
// import MyLeaves from './MyLeaves';
// import ManagerviewProject from './ManagerviewProject';
// import Projectviewemplymanager from './Projectviewemplymanager';
import { useNavigate } from 'react-router-dom';


const Attendence = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
    const [attendencetab, setAttendencetab] = useState(false);
    // const handleattendtabclick = (tabNumber) => {
    //     setAttendencetab(tabNumber);
    // };
    const navigate = useNavigate()
    const handleaattendenceclick = () => {
        navigate("/attendence")
    }
    const handleprojectclick = () => {
        navigate("/myviewproject")
    }
    const handleamyleaveclick = () => {
        navigate("/myleaves")
    }
    return (
        <>
            <section className='attendencemainsection'>
                <div className='employeepagecontainer'>
                    <div className='attendencedivflx'>
                        <div className='attendencedivleft'>
                            <div className='projecttimesheetleavedivflx'>
                                <div className='projecttimesheetdivflx'>
                                    <button className={attendencetab ? "tabattendiv actv" : "tabattendiv"} onClick={() => handleprojectclick()}>
                                        My Projects
                                    </button>
                                    <button className={attendencetab ? "tabattendiv actv" : "tabattendiv"} onClick={() => handleaattendenceclick()}>
                                        My TimeSheet
                                    </button>
                                </div>
                                <div className=''>
                                    <button className={attendencetab ? "tabattendiv actv" : "tabattendiv"} onClick={() => handleamyleaveclick()}>
                                        My Leaves
                                    </button>
                                </div>
                            </div>
                            <Attendenceworktable selectedDate={selectedDate}/>
                        </div>

                        <div className='attendencedivright'>

                                <div className='calenderdivrightmain'>
                                    <CalenderAttendence selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
                                </div>
                                <div className='projectaddrihtdiv'>
                                    <Addprojectrightcomp />
                                </div>
                           
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}

export default Attendence

