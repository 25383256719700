import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSaveTraining } from '../../redux/slices/jobRecruitmentSlice';
import { useAuthCtx } from '../../context/AuthCtx';
import { useNavigate } from 'react-router-dom';

const SaveTrainingCard = () => {
    const { userData } = useAuthCtx();
    const savetraining = useSelector((state) => state?.jobRecruitmentSlice?.savetraining);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log("getSaveTraining", savetraining);
    useEffect(() => {
        dispatch(getSaveTraining())
    }, [])

    const handleTrainingClick = (item) => {
        const data = {
            // selectedOptions: selectedOptions,
            singleMandatory: item?.savedData?.[0]
        }
        navigate(`/enlargedNewEnrollment/${item?.savedData?.[0]?.title?.replace(/\s+/g, '-')}/${item?.savedData?.[0]?._id}`, { state: data })
    }

    // const requestedtraining=[
    //     {
    //         image:trainingmain2,
    //         text:"Lorem Ipsum",
    //         notification:"text"
    //     },
    //     {
    //         image:trainingmain2,
    //         text:"Lorem Ipsum",
    //         notification:"text"
    //     },

    //     {
    //         image:trainingmain2,
    //         text:"Lorem Ipsum",
    //         notification:"text"
    //     },
    //     {
    //         image:trainingmain2,
    //         text:"Lorem Ipsum",
    //         notification:"text"
    //     },

    // ]

    return (
        <>

            {savetraining?.map((item, index) => {
                return (
                    <>
                        {
                            userData?._id === item?.addedBy && (
                                <div className='videoongoinddivnejbhj' key={index} onClick={() => handleTrainingClick(item)} style={{ cursor: "pointer" }}>

                                    <div className=''>
                                        <div className='trainingmainimgdivnesdsgn'>
                                            <img src={item?.savedData?.[0]?.image} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='lrmtxtppfgvffggdg'>{item?.savedData?.[0]?.title || item?.savedData?.[0]?.name}</p>
                                        </div>
                                    </div>

                                </div>)

                            // : (<p>No Saved Items</p>)
                        }
                    </>

                )
            })}

        </>
    )
}

export default SaveTrainingCard
