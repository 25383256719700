import React, { useEffect, useRef, useState } from 'react'
import trainingmain1 from "../../Images/trainingmain1.png";
import video from "../../Images/Icons/PNG/video.png";
import advytimg from "../../Images/advytimg.jpg";
import download from "../../Images/Icons/PNG/download.png";
import Share from "../../Images/Icons/PNG/Share.png"
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Rating } from "react-simple-star-rating";
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';
import moment from 'moment';

const CoursefirstVideoPlayer = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const selectedOptions = location?.state?.selectedOptions;
    // console.log(item, "itemtyuioui")
    const [dropdownintro, setDropdownintro] = useState(false)
    const [singleView, setSingleView] = useState([]);
    const [watchedPercentage, setWatchedPercentage] = useState(0);
    const [playedDuration, setPlayedDuration] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const previousPercentageRef = useRef(0);
    const [singleViewList, setSingleViewList] = useState([])



    //for view Single Page training
    const viewSinglepostList = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-single-trainings/${params?.tId}`,
            method: "GET",
        });
        if (response?.status === 200) {
            setSingleViewList(response?.data?.data?.[0]);
        } else {

        }
    }



    //for view Single Page Video
    const viewSinglepost = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-video-trainings/${params?.tId}/${params?.id}`,
            method: "GET",
        });
        if (response?.status === 200) {
            setSingleView(response?.data?.data);
        } else {

        }
    }

    const handleowndropdwnClick = () => {
        setDropdownintro(!dropdownintro)
    }

    //for rating functionality
    const handleRating = async (rate, id, vId) => {
        let data = {
            trainingId: id,
            videoId: vId,
            rating: rate,
        };

        let response = await ApiHelperFunction({
            urlPath: `/add-training-video-rating`,
            method: "PUT",
            data,
        });

        if (response && response.status === 200) {
            toast?.success(response?.data?.message);
        } else {
            toast?.error(response?.response?.data?.message);
        }

    };

    //for calculating percentage of video progress
    const handleProgress = (state) => {
        const { playedSeconds, played } = state;
        const playedMinutes = playedSeconds / 60; // Convert played seconds to minutes
        setPlayedDuration(Math.round(playedMinutes))
        const percentage = played * 100;
        setWatchedPercentage(Math.round(percentage)); // Round to nearest integer
        updateVideoPlaytime();
    };

    //for updating video play time
    const updateVideoPlaytime = async () => {
        const data = {
            trainingId: `${params?.tId}`,
            videoId: `${params?.id}`,
            playTimePercent: watchedPercentage,
            playtime: playedDuration
        };
        // console.log("datakl", data)
        // return false;
        const response = await ApiHelperFunction({
            urlPath: `/update-playtime-byuser`,
            method: "POST",
            data,
        });

        if (response.status) {
            viewSinglepostList();
        }
    };

    //for viewing add more videos
    const cousrevideoonClick = (item) => {
        const data = {
            item: item
        }
        navigate(`/courseVideoPlayer/${singleViewList?.title?.replace(/\s+/g, '-')}/${singleViewList?._id}/${item?._id}`, { state: data })
    }

    useEffect(() => {
        const previousPercentage = previousPercentageRef.current;
        if (Math.abs(watchedPercentage - previousPercentage) >= 5) {
            updateVideoPlaytime();
            previousPercentageRef.current = watchedPercentage;
        }
    }, [watchedPercentage]);


    useEffect(() => {
        viewSinglepost();
        viewSinglepostList();
    }, [params?.tId, params?.id]);

    console.log(singleViewList?.quizStatus, "uiodfg")
    return (
        <>
            <section className='coursevideoplayermain'>
                <div className='employeepagecontainer'>
                    <div className='coursevideoplayerflxdiv'>
                        <div className='coursedivplrtdiv'>
                            <div className='corsepreviewmainmiddlebg'>
                                <div className='trainingdivmainedt' >
                                    {/* <img src={trainingmain1} alt="..." />
                                    <div className='videodivimgicon'>
                                        <img src={video} alt="..." />
                                    </div> */}
                                    <ReactPlayer
                                        className='reactdivimgmainplrt'
                                        controls
                                        width='40rem'
                                        height='20rem'
                                        playing={true}
                                        url={singleView?.video?.videoLink}
                                        // onBuffer={cousrevideoonClick}
                                        onProgress={handleProgress}
                                        config={{
                                            youtube: {
                                                playerVars: { showinfo: 1 }
                                            },
                                            facebook: {
                                                appId: '12345'
                                            },
                                        }}

                                    />
                                    {/* <div className='textimeevntvdodiv'>
                                        <p className='textimeevntvdo'>{singleView?.videos?.[0]?.videoDuration?.hour} hr {singleView?.videoDuration?.min} min</p>
                                    </div> */}
                                </div>
                                <div className=''>
                                    <div className='dotdivbgvdo'>
                                        <p className='vdodescrptntxtppdiv'>{singleView?.video?.videoTitle}</p>
                                    </div>
                                    <div className=''>
                                        <p className='emplyetextppcrse'>{singleView?.video?.videoDescription}
                                        </p>
                                    </div>


                                    {/* rating section */}
                                    <div className="" >
                                        <Rating

                                            // key={`${key}${index}`}
                                            onClick={(rate) => {
                                                handleRating(rate, singleView?._id, singleView?.video?._id);
                                            }}
                                            initialValue={singleView?.videoRatings?.rating}
                                            size={19}
                                        />
                                    </div>

                                    <div className='trainerdetailsdownloadshreflx'>
                                        <div className='trainerprfltrainerdtls'>
                                            <div className='prfledtslbrdrtrainer'>
                                                <div className='prfledtslbrdrtrainerimg'>
                                                    <img
                                                        // src={advytimg} 
                                                        src={`${selectedOptions?.map((item) => item?.image)} `}
                                                        alt="..." />
                                                </div>
                                            </div>
                                            <div className=''>
                                                {/* <div className=''>
                                                    <p className='trainermanetxt'>Anirban Roy</p>
                                                </div> */}

                                                {
                                                    singleView?.mentor !== 0 ?
                                                        (<div className=''>
                                                            <p className='trainermanetxt'>{`${selectedOptions?.map((item) => item?.label)} `}</p>
                                                        </div>)
                                                        // : ele?.trainerName ? (<div className=''>
                                                        //     <p className='trainermanetxt'>Training Name : {ele?.trainerName}</p>
                                                        // </div>)
                                                        : (<></>)

                                                }

                                                <p className='trinrdtlstxt'>Trainer</p>
                                            </div>
                                        </div>
                                        <div className='dwnldtrainerflx'>
                                            {/* <div className='dwnldpdfflx'>
                                                <div className='downloadimgdivtrnr'>
                                                    <img src={download} alt="..." />
                                                </div>
                                                <p className='dwldtxtppodf'>Download Pdf</p>
                                            </div> */}

                                            <div className='downladimgmainpl'>
                                                {
                                                    singleView?.video?.videoDocument?.[0]?.document !== "" ? (<div className='dateshowlinkdiv'><img src={download} alt="..." /></div>) : (<div><img src={download} alt="..." /></div>)
                                                }

                                                <div className='downloaddivmaindiv'>

                                                    {
                                                        singleView?.video?.videoDocument?.[0]?.document !== "" && (
                                                            <>
                                                                <div className='downloaddivmaindivsize' >

                                                                    <a href={singleView?.video?.videoDocument?.[0]?.document?.startsWith("http") ? singleView?.video?.videoDocument?.[0]?.document : `http://${singleView?.video?.videoDocument?.[0]?.document}`} target="_blank" rel="noopener noreferrer">
                                                                        {singleView?.video?.videoDocument?.[0]?.document?.slice(0, 20)}
                                                                    </a>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>



                                            {/* <div className='shredivimgmain'>
                                                <img src={Share} alt="..." />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='trainerrtngtxtbrdr'>
                                        {/* <p className='trainerrtngtxt'>Trainer Rating</p> */}
                                    </div>
                                </div>
                                <div className='trainerdtlsbrdrtop'>
                                    <div className=''>
                                        <p className='crtdtxtppspcl'>
                                            Created on {moment(singleView?.createdOn).format("YYYY-MM-DD")}
                                            {/* Created on 14th Jan 2024 */}
                                        </p>
                                        <p className='dsrtptxtpp'>Description</p>
                                        <p className='empytxtppeng'>{singleView?.description}
                                            {/* <span className='rdtstppmre'>Read More</span> */}
                                        </p>
                                        <p className='empytxtppeng'>KeyWords</p>
                                        <p>
                                            <span className='keywrdtagpp'> #Employee,</span>
                                            <span className='keywrdtagpp'> #Engagement,</span>
                                            <span className='keywrdtagpp'> #Company,</span>
                                            <span className='keywrdtagpp'> #Together,</span>
                                            <span className='keywrdtagpp'> #Work ,</span>
                                            <span className='keywrdtagpp'> #People</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='coursedivplrtdivbgintr'>
                            <div className='coursecntntdiv'>
                                <div className=''>
                                    <p className='crstntpptxtp'>Course Content</p>
                                </div>


                                <div className=''>
                                    <div className='introbgpdf'>
                                        <div className='Introductiontxtangle'>
                                            <div className='' onClick={handleowndropdwnClick}>
                                                <span className={dropdownintro ? "fa-solid fa-angle-right" : "fa-solid fa-angle-down"}></span>
                                            </div>
                                            <div className=''>
                                                <p className='intrdivppptxts'>Video Playlist</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={dropdownintro ? "closedropdownactd" : "opendropdownactd"}>

                                        {
                                            singleViewList?.videos?.map((item, i) => {
                                                return (
                                                    <>
                                                        <div className='coursecontentflxalll' key={i}>
                                                            <div className='cousreimgdmainimgnn'>
                                                                <div
                                                                    className='cousreimgdmain'
                                                                    onClick={(e) => cousrevideoonClick(item, e)}
                                                                >

                                                                    <video
                                                                        src={item?.videoLink}
                                                                        className='reactdivimgmainplrt'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='lastmnthdesitxtflx'>
                                                                <div className=''>
                                                                    <p className='crsecntntxt'>{i + 1}</p>
                                                                </div>
                                                                <div className=''>
                                                                    <p className='crsecntntxt'>{item?.videoTitle}</p>
                                                                </div>
                                                            </div>
                                                            <div className='cusrvideominuteflx'>
                                                                <div className='timeimgdiv'>
                                                                    {/* <img src={time} alt="..." /> */}
                                                                </div>
                                                                <div className=''>
                                                                    {/* <p className='hrsmnttxtpp'>{item?.videoDuration?.hour ? (<>{`${item?.videoDuration?.hour} hr`}</>) : ("0")}  {item?.videoDuration?.min ? (<>{`${item?.videoDuration?.min} min`}</>) : ("00")}</p> */}
                                                                    <p className='hrsmnttxtpp'>{item?.videoDuration?.hour ? (<>{item?.videoDuration?.hour}</>) : ("0")}hr  {item?.videoDuration?.min ? (<>{item?.videoDuration?.min}</>) : ("00")}min</p>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </>


                                                )
                                            })
                                        }


                                        {/* {
                                            singleView?.placeTime?.length === 0
                                                ? (<>
                                                    <div className=''>
                                                        <p className='undrstndgppp'>Language : {singleView?.language}</p>
                                                    </div>
                                                    {
                                                        singleView?.placeTime?.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <div className='drpopfdivbhjkl' key={i}>
                                                                        <div className='contetxtxttimeflx'>
                                                                            <div className=''>
                                                                                <p className='undrstndgppp'>{moment(singleView.StartDate).format("YYYY-MM-DD")}</p>
                                                                            </div>
                                                                            <div className=''>
                                                                                <p className='undrstndgppp'>{singleView?.startTime}</p>
                                                                            </div>
                                                                            <div className=''>
                                                                                <p className='undrstndgppp'>{singleView?.videoDuration?.hour ? (<p>{singleView?.videoDuration?.hour}</p>) : ("0")} hr {singleView?.videoDuration?.min ? (<p>{singleView?.videoDuration?.min}</p>) : ("00")} min</p>
                                                                            </div>
                                                                           
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </>)
                                                : (<>
                                                    <div className=''>
                                                        <p className='undrstndgppp'>Language : {singleView?.language}</p>
                                                    </div>
                                                    {
                                                        singleView?.mentor !== 0 ?
                                                            (<div className=''>
                                                                <p className='undrstndgppp'>Mentor : {`${selectedOptions?.map((item) => item?.label)}`}</p>
                                                            </div>)
                                                            : singleView?.trainerName ? (<div className=''>
                                                                <p className='undrstndgppp'>Training Name : {singleView?.trainerName}</p>
                                                            </div>)
                                                                : (<></>)

                                                    }


                                                </>)
                                        } */}


                                    </div>



                                </div>


                            </div>


                            {
                                singleViewList?.quizStatus === "active" ?
                                    (<button className="quizebtnvisble" onClick={() => navigate(`/feedbackquize/${singleViewList?._id}`)}>
                                        Take Quiz
                                    </button>)
                                    :
                                    (
                                        <button className="enrolllnowdiv">
                                            Take Quiz
                                        </button>
                                    )
                            }
                        </div>
                    </div>


                </div>
            </section>
        </>
    )
}

export default CoursefirstVideoPlayer
