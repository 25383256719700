import React, { useState } from 'react'
import "./SalaryTemplate.css";
import imgprlsthgt from "../../Images/imgprlsthgt.jpg"
import Salarytemplatecomp from './Salarytemplatecomp';
import Salarydetails from './Salarydetails';
import SalaryTemplatecompone from './SalaryTemplatecompone';
import SalaryTemplatecomptwo from './SalaryTemplatecomptwo';
import SalaryTemplatecompthree from './SalaryTemplatecompthree';
const Salarytemplatemain = () => {
    const [slaryduvtab, setSlaryduvtab] = useState(0);

    const handlebtntabnewald = (tabNumber) => {
        setSlaryduvtab(tabNumber)
    }
    return (
        <>
            <section className='salarytemplatesmain'>
                <div className='salarytemplatedivleftsidebar'>
                    <div className='salaryimgmainbrdrikkk'>
                        <div className='salaryimgmain'>
                            <img src={imgprlsthgt} alt='...' />
                        </div>
                        <div className=''>
                            <p className='nameofjjdhhhfrhhtxtpp'>Mrs.Lorem Ipsum</p>
                            <p className='nameofhrbtctpp'>Lorem Ipsum</p>
                        </div>
                    </div>

                    <div className='salartydivmaindiv'>
                        <button className={slaryduvtab === 1 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(1)}>
                            Lorem Ipsum 1
                        </button>
                        <button className={slaryduvtab === 2 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(2)}>
                            Lorem Ipsum 2
                        </button>
                        <button className={slaryduvtab === 3 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(3)}>
                            Lorem Ipsum 3
                        </button>
                        <button className={slaryduvtab === 4 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(4)}>
                            Lorem Ipsum 4
                        </button>
                        <button className={slaryduvtab === 5 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(5)}>
                            Lorem Ipsum 5
                        </button>
                        <button className={slaryduvtab === 6 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(6)}>
                            Lorem Ipsum 6
                        </button>
                        <button className={slaryduvtab === 7 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(7)}>
                            Lorem Ipsum 7
                        </button>
                        <button className={slaryduvtab === 8 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(8)}>
                            Lorem Ipsum 8
                        </button>
                        <button className={slaryduvtab === 9 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(9)}>
                            Lorem Ipsum 9
                        </button>
                    </div>

                </div>
                <div className='salarytemplatedivrightmaincomp'>
                    <div className='slarytemplatedivcontainer'>


                        {slaryduvtab === 0 && (
                            <>
                                <Salarydetails />

                            </>
                        )}

                        {slaryduvtab === 1 && (
                            <>
                                <Salarytemplatecomp />
                            </>
                        )}

                        {slaryduvtab === 2 && (
                            <>
                                <SalaryTemplatecompone />
                            </>
                        )}

                        {slaryduvtab === 3 && (
                            <>
                                <SalaryTemplatecomptwo />
                            </>
                        )}


                        {slaryduvtab === 4 && (
                            <>
                                <SalaryTemplatecompthree />
                            </>
                        )}


                    </div>

                </div>
            </section>
        </>
    )
}

export default Salarytemplatemain
