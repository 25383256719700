import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
// import sharethght from "../../Images/sharethght.jpg";
import { BsEmojiSmileFill } from "react-icons/bs";
import EmojiPicker from 'emoji-picker-react';
import post from "../../Images/headericon/post_new.png";
import { useDispatch } from 'react-redux';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { getAllPosts, toggleDescription } from '../../redux/slices/postSlice';
import ReactPlayer from 'react-player';
import Fancybox from '../FAncyBox';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import empUserImg from "../../Images/Icons/PNG/Profile (2).png"
// import SilverIcon from "../../Images/Icons/PNG/Reward - Silver.png"
import { useAuthCtx } from '../../context/AuthCtx';

const RepostModal = ({ closemodal, postId, imagesArray, showDescription, description }) => {
    const { userData } = useAuthCtx();
    const [isEmoji, setIsEmoji] = useState(false);
    const [text, setText] = useState("");
    const dispatch = useDispatch();

    //for  repost in home page
    const handlePost = async () => {
        let data = {
            postId: postId,
            text: text,
            repostType:imagesArray?.docType
        }
        let response = await ApiHelperFunction({
            urlPath: "/repost",
            method: "POST",
            data: data,
        });
        console.log("databn", data);
        // return false;
        if (response?.status === 200) {
            setText("");
            closemodal();
            // console.log("responsenm", response?.data?.msg)
            toast.success(response?.data?.msg);
            dispatch(getAllPosts());
        } else {
            toast.error(response?.response?.data?.msg);
        }
    }

    useEffect(() => {
        dispatch(getAllPosts())
    }, [])

    console.log("ghrtmh", imagesArray)
    return (
        <>
            <section className='repostmodal'>
                <div className='commenteditmodalmainbg'>
                    <div className='coomenteditbgmain'>
                        <div className='commentedittxtdeleteicontop'>
                            <div className=''>
                                <p className='surecommntedit'>Repost</p>
                            </div>
                            <div className='crsicondelte' onClick={() => closemodal()}>
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                        </div>
                        <div className=''>
                            {/* <div className='profile_picimgmain'>
                            <img src={sharethght} alt="..." />
                        </div> */}
                            {/* <div className='wrapboxbg'>
                            <p>Nilardri Roy</p>
                           
                        </div> */}

                        </div>
                        <div className='commentprofiledeletesectionbtm'>
                            <div className='profile_picimgmain'>
                                <img src={userData?.image} alt="..." />
                            </div>
                            <div className="mycomment_push">
                                <form>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id=""
                                            aria-describedby="textHelp"
                                            placeholder="Write a text..."
                                            name='text'
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}

                                        />

                                        <BsEmojiSmileFill className="comment-emoji-icon"
                                            onClick={() => setIsEmoji(prev => !prev)}
                                        // onClick={() => setIsEmoji(true)}
                                        />
                                        {isEmoji &&
                                            // <EmojiPicker onEmojiClick={(emojiObject) => setCommentText(prev => prev + emojiObject.emoji)} />
                                            <EmojiPicker
                                                onEmojiClick={(emojiObject) => setText(prev => prev + emojiObject.emoji)}
                                            />
                                        }
                                        <button
                                            type='button'
                                            className="sendcommentbtn"
                                            onClick={handlePost}
                                        >
                                            <img src={post} alt="..." />
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div id="sara_tylor" >
                            <div className="top_area" style={{ position: "relative" }}>
                                <div className="left">
                                    <div className="profile_img">
                                        {
                                            imagesArray?.userImage && (
                                                <img style={{ cursor: "pointer" }}
                                                src={imagesArray?.userImage}
                                                className="img-fluid"
                                                alt="profile"
                                            />
                                            )
                                            
                                        }
                                        {imagesArray?.userData?.[0]?.image && (
                                            <img style={{ cursor: "pointer" }}
                                                src={imagesArray?.userData?.[0]?.image}
                                                className="img-fluid"
                                                alt="profile"
                                            />
                                        )
                                        //  : 
                                        // (
                                        //     <img style={{ cursor: "pointer" }}
                                        //         src={empUserImg}
                                        //         className="img-fluid"
                                        //         alt="profile"
                                        //     />
                                        // )
                                        }
                                    </div>

                                    <div
                                        className="details"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div className="name">
                                            <h2 style={{ cursor: "pointer" }}>{imagesArray?.userData?.[0]?.firstName} {imagesArray?.userData?.[0]?.lastName}</h2>
                                            <span>
                                                {
                                                    imagesArray?.userData?.[0]?.userType !== "Owner"
                                                        ? (<img src={imagesArray?.userData?.[0]?.rewardCard?.[0]?.cardImage} className="img-fluid" alt="icon" />)
                                                        : (<></>)
                                                }
                                            </span>
                                        </div>
                                        {/* </Link> */}
                                        <div className="text">
                                            <p>
                                                <span>{imagesArray?.userData?.[0]?.userType + " "}, </span>
                                                <span>Posted on {moment(imagesArray?.createdOn)?.format('YYYY - MM - DD')}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="">
                                <div className="content"
                                    style={{ cursor: "pointer" }
                                    }
                                >

                                    {imagesArray?.description && (
                                        <>
                                            {showDescription || imagesArray?.description?.length <= 850 ? (
                                                <p>{imagesArray?.description}</p>
                                            ) : (
                                                <>{imagesArray?.description?.slice(0, 850) + '...'}</>
                                            )}
                                            {description?.length > 850 && (
                                                <Link onClick={() => dispatch(toggleDescription(postId))}>
                                                    {showDescription ? '...Show Less' : 'Show More...'}
                                                </Link>
                                            )}
                                        </>
                                    )}


                                </div>

                                <div className="gallery_area">

                                    <div>
                                        <Fancybox options={{ infinite: false }}>
                                            <p className="all_box">

                                                {
                                                    (imagesArray?.docType === "homePost") ?
                                                        (<>
                                                            {imagesArray?.image?.[0] ? (<img src={imagesArray?.image[0]}
                                                                style={{
                                                                    width: `${imagesArray?.image?.length === 1 ? "50%" : ""
                                                                        }`,
                                                                    height: `${imagesArray?.image?.length === 1 ? "50%" : ""
                                                                        }`,
                                                                }} alt="" />) : (<></>)}
                                                            {
                                                                imagesArray?.video?.[0] ? (<div className='trainingdivmainedt' >
                                                                    <ReactPlayer
                                                                        className='reactdivimgmainplrt'
                                                                        controls
                                                                        width='40rem'
                                                                        height='20rem'
                                                                        playing={false}
                                                                        url={imagesArray?.video}
                                                                        config={{
                                                                            youtube: {
                                                                                playerVars: { showinfo: 1 }
                                                                            },
                                                                            facebook: {
                                                                                appId: '12345'
                                                                            },
                                                                        }}

                                                                    />
                                                                </div>) : (<></>)
                                                            }
                                                        </>) :
                                                        (imagesArray?.docType === "advocacyPost") ?
                                                            (<>
                                                                {imagesArray?.image ? (<img src={imagesArray?.image}
                                                                    style={{
                                                                        width: `${imagesArray?.image?.length === 1 ? "50%" : ""
                                                                            }`,
                                                                        height: `${imagesArray?.image?.length === 1 ? "50%" : ""
                                                                            }`,
                                                                    }} alt="" />) : (<></>)}
                                                            </>) :
                                                            (imagesArray?.docType === "eventData") ?
                                                                (<>
                                                                    {imagesArray?.image ? (<img src={imagesArray?.image}
                                                                        style={{
                                                                            width: `${imagesArray?.image?.length === 1 ? "50%" : ""
                                                                                }`,
                                                                            height: `${imagesArray?.image?.length === 1 ? "50%" : ""
                                                                                }`,
                                                                        }} alt="" />) : (<></>)}
                                                                </>) :
                                                                (<></>)
                                                }


                                            </p>
                                        </Fancybox>
                                    </div>
                                    <div>



                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div >

            </section>

        </>
    )
}

export default RepostModal