import React, { useState } from 'react'
import LogNewPic from "../../src/Images/LogNewPic.png";
import btmlogo from "../Images/bottomlogo.png";
// import Hide from "../Images/headericon/Hide.png"
// import Show from "../Images/headericon/View.png"
import { Link, useNavigate } from 'react-router-dom'
// import { useDispatch } from 'react-redux';
// import { registerCompany } from '../redux/slices/registrationSlice';
import toast from 'react-hot-toast';
import { countries } from '../CountryCodes/countryCode';

const Registrationpage = () => {
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        companyName: "",
        userName:"",
        registrationNumber: "",
        address: "",
        employeeStrenght: "",
        email: "",
        country: "",
        countryCode: "",
        // password: "",
        registeredPhoneNo: "",
        // confirmPassword: ""
    });
    // const [showPassword, setShowPassword] = useState(false);
    // const [showConPassword, setShowConPassword] = useState(false);

    // const togglePasswordVisibility = () => {
    //     setShowPassword(!showPassword);
    // };

    // const toggleConPasswordVisibility = () => {
    //     setShowConPassword(!showConPassword);
    // };

    //for onChange functionality
    const handleChange = (e) => {
        const { name, value } = e.target;
        const numericValue = parseFloat(value);
        // if (!isNaN(numericValue) && numericValue >= 0) {
            if (name === "country") {
                const selectedCountry = countries?.find(country => country.name === value);
                setFormData({
                    ...formData,
                    country: value,
                    countryCode: selectedCountry ? selectedCountry.dial_code : "", 
                });
            } else {
                setFormData({
                    ...formData,
                    [name]: value,
                });
            }
        // } else {
        //     toast?.error("Please enter a valid non-negative number");
        // }
    };
    
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     if (name === "country") {
    //         const selectedCountry = countries?.find(country => country.name === value);
    //         setFormData({
    //             ...formData,
    //             country: value,
    //             countryCode: selectedCountry ? selectedCountry.dial_code : "", 
    //         });
    //     } else {
    //         setFormData({
    //             ...formData,
    //             [name]: value,
    //         });
    //     }
    // };
    

    // const handleChange = (e) => {
    //     // const selectedCountry = countries.find(country => country?.name === e.target.value);
    //     setFormData({
    //         ...formData,
    //         // country_code: selectedCountry?.dial_code,
    //         [e.target.name]: e.target.value
    //     });

    //     // const selectedCountry = countries.find(country => country?.name === e.target.value);
    //     // if (selectedCountry) {
    //     //     setFormData({
    //     //         ...formData,
    //     //         country: e.target.value,
    //     //         country_code: selectedCountry?.dial_code
    //     //     });
    //     // }
    // };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    //for submit form
    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            formData?.companyName === "" ||
            formData?.userName === "" ||
            formData?.address === "" ||
            formData?.registeredPhoneNo === "" ||
            formData?.employeeStrenght === "" ||
            formData?.email === "" ||
            formData?.country === ""
            // formData?.password === "" ||
            // formData?.confirmPassword === ""
        ) {
            return toast?.error("All fields required");
        }

        if (!validateEmail(formData.email)) {
            return toast?.error("Please enter a valid email address");
        }
        navigate('/subscriptionplan',{state:formData})

        // if (formData.password !== formData.confirmPassword) { // Check if passwords match
        //     return toast?.error("Passwords do not match");
        // }

        // dispatch(registerCompany(formData));
        // setFormData({
        //     companyName: "",
        //     registrationNumber: "",
        //     address: "",
        //     employeeStrenght: "",
        //     email: "",
        //     country: "",
        //     countryCode: "",
        //     // password: "",
        //     // confirmPassword: "",
        //     registeredPhoneNo: ""
        // })
    };

    return (
        <>
            <section className='registartionpagebgmain'>
                <div className='registrationwrapmain'>
                    <div className='registratrationdivflx'>
                        <div className='registratrationdivleft'>
                            <div className='LogNewPicdivpmio'>
                                <img src={LogNewPic} alt="..." />
                            </div>
                        </div>
                        <div className='registratrationdivright'>
                            <div className='registratrationdivleftpading'>
                                <div className='cross_icon_reg_flx'>
                                    <h4 className='registartxtpp'>Registration</h4>
                                    <div className='cross_reg' style={{ cursor: "pointer" }} onClick={() => navigate('/')}>
                                        <i className="fa-solid fa-xmark"></i>
                                    </div>
                                </div>
                                <div className="registartoonfrmdivmain">
                                    {/* <form> */}
                                    <div className='inputcompanydtls'>
                                        <p>Company Name :</p>
                                        <input
                                            type="text"
                                            name="companyName"
                                            value={formData?.companyName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className='inputcompanydtls'>
                                        <p>Company Username :</p>
                                        <input
                                            type="text"
                                            name="userName"
                                            value={formData?.userName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className='inputcompanydtls'>
                                        <p>Company CIN Number :</p>
                                        <input
                                            type="number"
                                            name="registrationNumber"
                                            value={formData?.registrationNumber}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className='inputcompanydtls'>
                                        <p>Adress :</p>
                                        <input
                                            type="text"
                                            name="address"
                                            value={formData?.address}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className='inputcompanydtls'>
                                        <p>Country :</p>
                                        <select
                                            name="country"
                                            value={formData?.country}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Country</option>
                                            {countries?.map((country) => (
                                                <option key={country.code} value={country.name}>
                                                    {country.name}
                                                </option>
                                            ))}

                                        </select>
                                    </div>


                                    <div className='inputcompanydtls'>
                                        <p>Registered Phone Number :</p>
                                        <span>{formData?.countryCode}</span>
                                        <input
                                            type="number"
                                            name="registeredPhoneNo"
                                            value={formData?.registeredPhoneNo}
                                            onChange={handleChange}
                                        />
                                    </div>


                                    <div className='inputcompanydtls'>
                                        <p>Employee Strenght</p>
                                        <input
                                            type="number"
                                            name="employeeStrenght"
                                            value={formData?.employeeStrenght}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className='inputcompanydtls'>
                                        <p>Email Id :</p>
                                        <input
                                            type="text"
                                            name="email"
                                            value={formData?.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {/* <div className='inputcompanydtls'>
                                        <p>Password</p>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            value={formData?.password}
                                            onChange={handleChange}
                                        />

                                        <div className='Hideimgdivicn' onClick={togglePasswordVisibility}>
                                            <img src={showPassword ? Show : Hide} alt="..." />
                                        </div>
                                    </div>
                                    <div className='inputcompanydtls'>
                                        <p>Confirm Password</p>
                                        <input
                                            type={showConPassword ? "text" : "password"}
                                            name="confirmPassword"
                                            value={formData?.confirmPassword}
                                            onChange={handleChange}
                                        />
                                        <div className='Hideimgdivicn' onClick={toggleConPasswordVisibility}>
                                            <img src={showConPassword ? Show : Hide} alt="..." />
                                        </div>
                                    </div> */}
                                    <div className="checkbox_area">
                                        <div className="form-group">
                                            <input type="checkbox" id="termsandcondition" />
                                            <label htmlFor="termsandcondition">I agree with the <Link>terms and conditions</Link></label>
                                        </div>
                                    </div>

                                    <div className='asmbmnbtnmaindiv'>
                                        <button
                                            className='asmbmnbtn'
                                            type="button"
                                            onClick={(e) => handleSubmit(e)}
                                            // onClick={()=>navigate('/subscriptionplan')}
                                        >
                                            Continue
                                        </button>
                                    </div>
                                    {/* </form> */}


                                    <div className="bottomlogo">
                                        <div className="companykslogodivmnm">
                                            <img src={btmlogo} className="img-fluid" alt=".." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Registrationpage
