import React, { useEffect, useState } from 'react'
// import DocumentRepository from "../../Images/Icons/PNG/DocumentRepository.png";
// import Purchase from "../../Images/Icons/PNG/Purchase.png";
// import IT from "../../Images/Icons/PNG/IT.png";
// import operations from "../../Images/Icons/PNG/operations.png";
// import management from "../../Images/Icons/PNG/management.png";
// import Expand from "../../Images/Icons/PNG/Expand.png";
// import trainingmain2 from "../../Images/trainingmain2.png";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentsRepo } from '../../redux/slices/jobRecruitmentSlice';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import documentReposiy from "../../Images/headericon/documentReposiy.png";
import ExpandGradient from "../../Images/headericon/ExpandGradient.png";

const DocumentRepositorymain = () => {
    // const documents = useSelector((state) => state?.jobRecruitmentSlice?.documents)
    const dispatch = useDispatch();
    const [deptData, setDeptData] = useState([]);

    useEffect(() => {
        dispatch(getDocumentsRepo());
        getDepartmentCategoryData();
    }, [])



    const navigate = useNavigate()
    const handledocmtrepoClick = () => {
        navigate("/documentrepository")
    }


    //for getting all department data
    const getDepartmentCategoryData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/get-documents-category`,
            method: "GET",
        });
        // console.log("RESPONSEF", response?.data?.data);
        if (response && response.status) {
            setDeptData(response?.data?.data);
        } else {
            // toast.error(response.error);
        }
    };

    // console.log("qwesdgh", deptData)
    return (
        <>
            <div className='videoongoing'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        {/* <figure className="bulleBoardFig">
                            <img
                                src={DocumentRepository}
                                alt="..."
                            />
                        </figure> */}
                        <h4>Document Repository</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv">
                            <figure className="bulleBoardFig" onClick={handledocmtrepoClick}>
                                <img src={ExpandGradient} alt="..." />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    {
                        deptData?.map((item, i) => {
                            return (
                                <>
                                    <div className='bottomrepoflx' key={i}>
                                        <div className='purchaseimgdiv'>
                                            <img src={documentReposiy} alt="..." />
                                        </div>
                                        <div className="">
                                            <p className='purtxt'>{item?.catName}</p>
                                        </div>
                                        <div className=''>
                                            <h5 className='artcl'>{item?.totaDocumentCount}</h5>
                                            <p className='artctext'>Topics</p>
                                        </div>
                                        <div className=''>
                                            <h5 className='artcl'>{item?.totalUserViews}</h5>
                                            <p className='artctext'>View</p>
                                        </div>
                                        {/* <div className='Purchasedivflx'>
                                            <div className='purtxtdiv'>
                                                <p className='purtxt'>{item?.catName}</p>
                                            </div>
                                            <div className='arttopsubtopic'>
                                                <div className=''>
                                                    <h5 className='artcl'>5</h5>
                                                    <p className='artctext'>Topics</p>
                                                </div>

                                                <div className=''>
                                                    <h5 className='artcl'>15</h5>
                                                    <p className='artctext'>View</p>
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>
                                </>
                            )
                        })
                    }

                    {/* <div className='bottomrepoflx'>
                        <div className='purchaseimgdiv'>
                            <img src={IT} alt="..." />
                        </div>
                        <div className='Purchasedivflx'>
                            <div className='purtxtdiv'>
                                <p className='purtxt'>IT</p>
                            </div>
                            <div className='arttopsubtopic'>
                                <div className=''>
                                    <h5 className='artcl'>5</h5>
                                    <p className='artctext'>Topics</p>
                                </div>

                                <div className=''>
                                    <h5 className='artcl'>15</h5>
                                    <p className='artctext'>View</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='bottomrepoflx'>
                        <div className='purchaseimgdiv'>
                            <img src={operations} alt="..." />
                        </div>
                        <div className='Purchasedivflx'>
                            <div className='purtxtdiv'>
                                <p className='purtxt'>Operations</p>
                            </div>
                            <div className='arttopsubtopic'>
                                <div className=''>
                                    <h5 className='artcl'>5</h5>
                                    <p className='artctext'>Topics</p>
                                </div>

                                <div className=''>
                                    <h5 className='artcl'>15</h5>
                                    <p className='artctext'>View</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='bottomrepoflx'>
                        <div className='purchaseimgdiv'>
                            <img src={management} alt="..." />
                        </div>
                        <div className='Purchasedivflx'>
                            <div className='purtxtdiv'>
                                <p className='purtxt'>Management</p>
                            </div>
                            <div className='arttopsubtopic'>
                                <div className=''>
                                    <h5 className='artcl'>5</h5>
                                    <p className='artctext'>Topics</p>
                                </div>

                                <div className=''>
                                    <h5 className='artcl'>15</h5>
                                    <p className='artctext'>View</p>
                                </div>
                            </div>
                        </div>

                    </div> */}
                </div>

            </div>
        </>
    )
}

export default DocumentRepositorymain
