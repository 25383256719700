import React, { useState, useEffect } from 'react'
import DocumentRepositorymain from '../TrainingPage/DocumentRepositorymain'
import GrowWel from "./GrowWel";
import markImg from "../../Images/markImg.png";
import ShareThoughts from "../../Component/ShareThoughts";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Tabs, Tab, TabScreen, TabPanel } from "react-tabs-scrollable";
import "react-tabs/style/react-tabs.css";
import OnboardingExp from "./OnboardingExp";
import "react-accessible-accordion/dist/fancy-example.css";
import battery from "../../Images/battery.png";
import trophy from "../../Images/trophy.png";
import mrkInPic1 from "../../Images/mrkInPic1.png";
import ImportantLinks from "./ImportantLinks";
import MyMentor from "./MyMentor";
import MentConnection from "./MentConnection";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import { toast } from "react-hot-toast";
import { useAuthCtx } from "../../context/AuthCtx";
import OnsurveysExp from "./onsurveysExp";
import suveyIcon from "../../Images/Icons/PNG/Survey.png";
import { Rating } from "react-simple-star-rating";
import goldIcon from "../../Images/Icons/PNG/Reward - Gold.png";
import eliteExp from "../../Images/Icons/PNG/Elite employee experience logo.png";
import bgprfldiv from "../../Images/bgprfldiv.png";
import Advocayboarddetails from "../../Component/Advocayboarddetails";
import { Select } from "@mui/material";
import Statuscard from "../../Component/Statuscard";
import Post from "../../Images/Icons/PNG/Post.png"
import SurveySuggestionmodal from "../../Component/Modal/SurveySuggestionmodal";
import GrivanceModal from "../../Component/Modal/GrivanceModal";
import GrivancecellIcon from "../../Images/Icons/PNG/GrivancecellIcon.png";
import SuggestionBox from "../../Images/Icons/PNG/SuggestionBox.png"
import EventsModal from "../../SeprateModal/EventsModal";
import "react-tabs-scrollable/dist/rts.css";
import "../Hub/Hub.css"
import message from "../../Images/Icons/PNG/Messages.png"
import post from "../../Images/headericon/post_new.png";
import Comment from "../../Images/headericon/Comment.png"
import { useNavigate, useParams } from "react-router-dom";

const Surveydetails = () => {
    const params = useParams();
    const { setLoading, modalF, setModalF, userData } = useAuthCtx();
    const percentage = 68;
    const [checkListDay, setCheckListDay] = useState([]);
    const [tabId, setTabId] = useState("");
    const [checkList, setCheckList] = useState([]);
    const [task, setTask] = useState([]);
    const [newChecklistData, setNewChecklistData] = useState([]);
    const [linkId, setLinkId] = useState(0);
    const [viewProgressBarData, setProgressBarData] = useState({});
    const [status, setStatus] = useState("");
    const [question, setQuestion] = useState("");
    const [questionInputs, setQuestionInputs] = useState({});
    const [questionModal, setQuestionModal] = useState({});
    const [EmployeeData, setEmployeeData] = useState([]);
    const [managerData, setManagerData] = useState([]);
    const [isManagerDropdownOpen, setManagerDropdownOpen] = useState(false);
    const [selectedManager, setSelectedManager] = useState(null);
    const [hrData, setHrData] = useState([]);
    const [goldCard, setGoldCard] = useState([]);
    const [suggestionmodal, setSuggestionmodal] = useState(false);
    const [grivancemodal, setGrivancemodal] = useState(false);
    const initial = {
        suggestion: "",
        tag: "",
        managerId: "",
        hrId: "",
        commiteeId: "",
        priority: ""
    }

    const initialValues = {
        eventName: "",
        hostedBy: "",
        eventDate: "",
        eventstarttime: "",
        eventendtime: "",
        lattitude: "",
        longitude: "",
        addinvites: "",
        name: "",
        notes: "",
        image: "",
        editTrainingName: "",
        editTraininDate: "",
        isHighLighted: ""
    };

    const initial1 = {
        complaint: "",
        tag: "",
        managerId: "",
        hrId: "",
        commiteeId: "",
        priority: ""
    }
    const [suggestionBox, setSuggestionBox] = useState(initial);
    const [GrivanceData, setGrivanceData] = useState(initial1);
    const [ratings, setRatings] = useState({});

    useEffect(() => {
        getEmployeeData();
        getManagerData();
        getHrData();
    }, []);


    const getEmployeeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-employees`,
            method: "GET",
        });
        // console.log("RESPONSEF", response?.data?.data);
        if (response && response.status) {
            setEmployeeData(response?.data?.data);
        } else {
            // toast.error(response.error);
        }
    };

    //get manager data
    const getManagerData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-manager`,
            method: "GET",
        });
        // console.log("RESPONSEF", response?.data?.data);
        if (response && response.status) {
            setManagerData(response?.data?.data);
        } else {
            // toast.error(response.error);
        }
    };

    const getHrData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-hr`,
            method: "GET",
        });
        // console.log("RESPONSEF", response?.data?.data);
        if (response && response.status) {
            setHrData(response?.data?.data);
        } else {
            // toast.error(response.error);
        }
    };


    //for suggestion section
    const handleSuggestion = async () => {
        if (suggestionBox?.suggestion == "") {
            toast?.error("All field is required")
            return false;
        }
        let data = {
            suggestion: suggestionBox?.suggestion,
            tagId: suggestionBox.managerId ? suggestionBox.managerId : suggestionBox.hrId ? suggestionBox?.hrId : suggestionBox.commiteeId ? suggestionBox?.commiteeId : "",
            priority: suggestionBox?.priority
        };
        // console.log("suggestion", data)
        // return false
        let response = await ApiHelperFunction({
            urlPath: `/give-suggestion`,
            method: "POST",
            data,
        });
        // console.log("suggestion");
        // console.log("clickqqqq", response);
        if (response && response.status) {
            toast.success(response?.data?.message);
            setSuggestionBox(initial)
        } else {
            toast.error(response?.response?.data?.message);
        }
    };


    //for grivance section
    const handleGrivance = async () => {
        if (GrivanceData?.complaint == "") {
            toast.error("All field is required")
            return false;
        }
        let data = {
            complaint: GrivanceData?.complaint,
            tagId: GrivanceData.managerId ? GrivanceData.managerId : GrivanceData.hrId ? GrivanceData?.hrId : GrivanceData.commiteeId ? GrivanceData?.commiteeId : "",
            priority: GrivanceData?.priority
        };
        // console.log("suggestion", data)
        // return false
        let response = await ApiHelperFunction({
            urlPath: `/add-complaint`,
            method: "POST",
            data,
        });
        // console.log("suggestion");
        // console.log("clickqqqq", response);
        if (response && response.status === 200) {
            toast.success(response?.data?.message);
            setGrivanceData(initial1)
        } else {
            toast.error(response?.response?.data?.message);
        }
    };

    //for rating
    const handleRating = async (rate, id) => {
        setRatings(prev => ({ ...prev, [id]: rate }));

        let data = {
            surveyID: id,
            rating: rate,
        };
        // console.log("rate",data)
        // return false
        let response = await ApiHelperFunction({
            urlPath: `/add-survey-ratings`,
            method: "POST",
            data,
        });

        if (response && response.status) {
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.response?.data?.message);
        }
        // let newArr = [...survey];
        // newArr[index].rating = rate;
        // setSurvey(newArr);
    };

    //for submit question
    const handleSubmitQuestion = async (id) => {
        // if (!questionInputs[index]) {
        //     return toast.error("All fields are required");
        // }
        if (!question) {
            return toast.error("All fields are required");
        }
        let data = {
            surveyId: id,
            question: question[id],
            // question: questionInputs[index],
        };
        console.log("questiondata", data)
        // return false
        let response = await ApiHelperFunction({
            urlPath: `/add-question`,
            method: "POST",
            data,
        });
        console.log("clickopui", response);
        if (response && response.status) {
            toast.success(response?.data?.message);
            setQuestion("");
            // setQuestionInputs({});
        } else {
            // console.log(response,"ioprty")
            // toast.error(response?.response?.data?.message);
        }
    }

    //for toggle question
    const handleToggleQuestion = (index) => {
        setQuestionModal(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };


    const getSurvey = async () => {
        let data = {
            surveyNameId: params?.id
        }
        const response = await ApiHelperFunction({
            urlPath: `/get-survey`,
            method: "POST",
            data
        });
        // console.log("RESPONSEF", response?.data?.data);
        if (response && response.status) {
            setCheckList(response?.data?.data1);
            setStatus(response?.data?.data2)
        }
    };

    useEffect(() => {
        getSurvey();
    }, []);

    //suggestion box  onchange
    const handleChange = (e) => {
        const { name, value } = e.target;
        setSuggestionBox(prev => ({ ...prev, [name]: value }))

        if (name === "managerId") {
            setSuggestionBox((prev) => ({
                ...prev,
                hrId: "",
                commiteeId: "",
            }))

        }
        if (name === "hrId") {
            setSuggestionBox((prev) => ({
                ...prev, managerId: "",
                commiteeId: ""
            }))

        }

    }


    //grivance box  onchange
    const handleChangeGrivance = (e) => {
        const { name, value } = e.target;
        setGrivanceData(prev => ({ ...prev, [name]: value }))

        if (name === "managerId") {
            setGrivanceData((prev) => ({
                ...prev,
                hrId: "",
                commiteeId: "",
            }))

        }
        if (name === "hrId") {
            setGrivanceData((prev) => ({
                ...prev, managerId: "",
                commiteeId: ""
            }))

        }

    }


    const handlesuggestionClick = () => {
        setSuggestionmodal(!suggestionmodal)
    }

    const grivanceClick = () => {
        setGrivancemodal(!grivancemodal)
    }

    useEffect(() => {
        const initialRatings = {};
        checkList?.forEach(item => {
            if (item?.ratings && item?.ratings?.length > 0) {
                initialRatings[item._id] = item?.ratings[0];
            } else {
                initialRatings[item._id] = 0; 
            }
        });
        setRatings(initialRatings);
        getSurvey();
    }, [checkList]);


    // console.log("rating",ratings)
    return (
        <>
            <section className='surbevstdetaujsectio'>
                <div className='employeepagecontainer'>
                    <div className='survetdetaulsdicvflx'>
                        <div className="surveysctiveboxcdtlsdiv">
                            <h4 className="surveyowntxtpsomel">Hello {userData?.firstName + " " + userData?.lastName} !!</h4>
                            <div className="surveysctiveboxcdtls">
                                <h4 className="sutvetdtlsdivpp">
                                    {status?.surveyName}
                                </h4>
                                <p className="chekcljnstckpml">
                                    {status?.surveyStatus}
                                </p>



                            </div>
                        </div>
                        <div className="surveydtlsquestion">
                            <div className='surveydtlsquestionbg'>
                                <ul className='surveylistul'>
                                    {
                                        checkList?.map((item, i) => {
                                            return (
                                                <div className='surveylistlisdivmain' key={i}>
                                                    <li className="surveylistlis">
                                                        {item?.survey}

                                                    </li>
                                                    <div className="fivedivdldivflxmainflx">
                                                        <div className="fivedivdldivflx">

                                                            {[1, 2, 3, 4, 5]?.map((rate) => (

                                                                <div
                                                                    className={
                                                                        ratings[item?._id] === rate ? 'fivedivdldiv actv' : "fivedivdldiv"
                                                                    }
                                                                    onClick={() => handleRating(rate, item?._id)}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    {rate}
                                                                </div>

                                                            ))}

                                                            {/* <div style={{ cursor: "pointer" }}
                                                                className={rating === 1 ? 'fivedivdldiv actv' : 'fivedivdldiv'}
                                                                onClick={() => {
                                                                    handleRating(1, item?._id);
                                                                }}
                                                            >
                                                                1
                                                            </div>
                                                            <div style={{ cursor: "pointer" }}
                                                              className={rating === 2 ? 'fivedivdldiv actv' : 'fivedivdldiv'}
                                                                onClick={() => {
                                                                    handleRating(2, item?._id);
                                                                }}
                                                            >2

                                                            </div>
                                                            <div style={{ cursor: "pointer" }}
                                                               className={rating === 3 ? 'fivedivdldiv actv' : 'fivedivdldiv'}
                                                                onClick={() => {
                                                                    handleRating(3, item?._id);
                                                                }}
                                                            >3

                                                            </div>
                                                            <div style={{ cursor: "pointer" }}
                                                                className={rating === 4 ? 'fivedivdldiv actv' : 'fivedivdldiv'}
                                                                onClick={() => {
                                                                    handleRating(4, item?._id);
                                                                }}
                                                            >4

                                                            </div>
                                                            <div style={{ cursor: "pointer" }}
                                                               className={rating === 5 ? 'fivedivdldiv actv' : 'fivedivdldiv'}
                                                                onClick={() => {
                                                                    handleRating(5, item?._id);
                                                                }}
                                                            >5

                                                            </div> */}
                                                        </div>

                                                        <div className='messagemaindiv' onClick={() => handleToggleQuestion(i)}>
                                                            <img src={Comment} alt="..." />
                                                        </div>
                                                    </div>

                                                    {
                                                        questionModal[i] &&
                                                        <div className='textarediv'>
                                                            <div className='slpdhjdiv'>
                                                                <textarea placeholder="Text in this box"
                                                                    type="text"
                                                                    rows="1"
                                                                    cols="50"
                                                                    name='question'
                                                                    value={question[i] || ""}
                                                                    onChange={(e) =>
                                                                        setQuestion({
                                                                            ...question,
                                                                            [i]: e.target.value,
                                                                        })
                                                                    }
                                                                ></textarea>
                                                                
                                                                    <div className="postbgimgchndjpoth" onClick={() => handleSubmitQuestion(item?._id)}>
                                                                        <img src={post} alt="..." />
                                                                    </div>
                                                               
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                        </div>

                        <div className="surveydtlsrignthdiv">
                            <div className="eliteFigDiv" style={{ marginBottom: "10px", }}>

                                <div className="bgsghdiv">
                                    <div className="grvnctxtflx">

                                        <h4 className="drtopen">Open Door</h4>
                                    </div>

                                    <p className="sghdntxt">(Suggestion Box)</p>
                                    <div className="slpdhjdiv">
                                        <input type=""
                                            placeholder="Please Specify Your Suggestion"
                                            className="tstsugrstdiv"
                                            name="suggestion"
                                            value={suggestionBox.suggestion}

                                            onChange={(e) => handleChange(e)}
                                            onClick={handlesuggestionClick}
                                        />
                                        <div className="diijfkxkldivpxi">
                                            <div className="postbgimg" onClick={handleSuggestion}>
                                                <img src={post} alt="..." />
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div className="bgsghdiv">
                                    <div className="grvnctxtflx">

                                        <h4 className="drtopen">
                                            Whistle Blower
                                        </h4>

                                    </div>
                                    <p className="sghdntxt">(Grievance Cell)</p>
                                    <div className="slpdhjdiv">
                                        <input type=""
                                            placeholder="Please Specify Your Grievance"
                                            className="tstsugrstdiv"
                                            name="complaint"
                                            value={GrivanceData?.complaint}
                                            // onChange={(e) => setSuggestion(e.target.value)} 
                                            onChange={(e) => handleChangeGrivance(e)}
                                            onClick={grivanceClick}
                                        />
                                        <div className="diijfkxkldivpxi">
                                            <div className="postbgimg" onClick={handleGrivance}>
                                                <img src={post} alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <DocumentRepositorymain />

                            <MyMentor />
                        </div>
                    </div>
                </div>
            </section>
            {suggestionmodal && <SurveySuggestionmodal closeModal={setSuggestionmodal} />}
            {grivancemodal && <GrivanceModal closeModal={setGrivancemodal} />}
        </>
    )
}

export default Surveydetails
