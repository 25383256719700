import React, { useEffect, useState } from 'react'
import { useAuthCtx } from '../../context/AuthCtx';
import { useFormik } from "formik";
import * as Yup from "yup";
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';
import a from "../../Images/Icons/PNG/Add icon.png"
import Calender from "../../Images/Icons/PNG/Calender.png";
import Post from "../../Images/Icons/PNG/Post.png"
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

const JobNotificationSingle = () => {
    const { setLoading } = useAuthCtx();
    const [isApprove, setApprove] = useState("pending");
    const location = useLocation();
    const isAdminApproved = location?.state?.data?.isAdminApproved; // Access isAdminApproved from location state
    // const [isCheck, setCheck] = useState("")


    useEffect(() => {
        // console.log("Locajsdhfjshdf", location?.state?.data);
        // setFieldValue("isAdminApproved", location?.state?.isAdminApproved)
        setFieldValue("_id", location?.state?.data?.values?._id);
        setFieldValue("Department", location?.state?.data?.values?.Department);
        setFieldValue("jobTitle", location?.state?.data?.values?.jobTitle);
        setFieldValue("jobLocation", location?.state?.data?.values?.jobLocation);
        setFieldValue("hiringManager", location?.state?.data?.values?.hiringManager);
        setFieldValue("level", location?.state?.data?.values?.level);
        setFieldValue("isNewPosition", location?.state?.data?.values?.isNewPosition);
        setFieldValue("isTempPosition", location?.state?.data?.values?.isTempPosition);
        setFieldValue("candidateSourcing", location?.state?.data?.values?.candidateSourcing);
        setFieldValue("hiringReason", location?.state?.data?.values?.hiringReason);
        setFieldValue("priority", location?.state?.data?.values?.priority);
        setFieldValue("description", location?.state?.data?.values?.description);
        setFieldValue("interviewerEmail", location?.state?.data?.values?.interviewerEmail);
        setFieldValue("openings", location?.state?.data?.values?.openings);
        setFieldValue("altEmail", location?.state?.data?.values?.altEmail);
        setFieldValue("qualification", location?.state?.data?.values?.qualification);
        setFieldValue("addQualification", location?.state?.data?.values?.addQualification);
        setFieldValue("experience_low", location?.state?.data?.values?.experience_low);
        setFieldValue("experience_high", location?.state?.data?.values?.experience_high);
        setFieldValue("Competencies", location?.state?.data?.values?.Competencies);
        setFieldValue("inclusion", location?.state?.data?.values?.inclusion);
        setFieldValue("interviewProcess", location?.state?.data?.values?.interviewProcess);
        setFieldValue("criteria", location?.state?.data?.values?.criteria);
        setFieldValue("profileLink", location?.state?.data?.values?.profileLink);
        setFieldValue("salary", location?.state?.data?.values?.salary);
        setFieldValue("salaryDate", location?.state?.data?.values?.salaryDate);

        setFieldValue("positionDescription", location?.state?.data?.values?.positionDescription);
        // setFieldValue("positionName", location?.state?.data?.values?.positionName);
        setFieldValue("responsibilities", location?.state?.data?.values?.responsibilities);

        setFieldValue("keySkills", location?.state?.data?.values?.keySkills);
        setFieldValue("period", location?.state?.data?.values?.period);
        setFieldValue("isReffered", location?.state?.data?.values?.isReffered);
        // setFieldValue("qualifications", location?.state?.data?.values?.qualifications);
        // setFieldValue("goodToHave", location?.state?.data?.values?.goodToHave);
        // setFieldValue("keyResponsibilities", location?.state?.data?.values?.keyResponsibilities);
        // setFieldValue("advertising", location?.state?.data?.values?.advertising);
        // setFieldValue("additionalComments", location?.state?.data?.values?.additionalComments);
        // setFieldValue("approvals", location?.state?.data?.values?.approvals);
    }, [])

    // useEffect(() => {
    //     // Update isApprove state based on the initial value of isAdminApproved
    //     setApprove(isAdminApproved);
    // }, [isAdminApproved]);

    // Use try...catch to handle potential JSON parsing errors
    // let values;
    // try {
    //     values = JSON.parse(imagesArrayParam);
    // } catch (error) {
    //     console.error('Error parsing imagesArray JSON:', error);
    //     // Handle the error or provide a default value for imagesArray
    //     values = [];
    // }

    const initial = {
        advertising: "",
        additionalComments: "",
        approvals: "",
        reason: ""
    }

    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
    const [managerData, setManagerData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);
    const jobs = useSelector((state) => state?.jobRecruitmentSlice?.jobsrec);
    const [approvalModal, setApprovalModal] = useState(false);
    const [disapprovalModal, setDisApprovalModal] = useState(false);
    const [formData, setFormData] = useState(initial);

    useEffect(() => {
        getManagerData();
        getDepartmentData();
    }, []);

    useEffect(() => {
        // Update isApprove state based on the initial value of isAdminApproved
        setApprove(isAdminApproved);
    }, [isAdminApproved]);

    //for getting all manager data
    const getManagerData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-manager`,
            method: "GET",
        });
        // console.log("RESPONSEF", response?.data?.data);
        if (response && response.status) {
            setManagerData(response?.data?.data);
        } else {
            // toast.error(response.error);
        }
    };

    //for fetching Department data
    const getDepartmentData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-department`,
            method: "GET",
        });
        // console.log("RESPONSEF", response?.data?.data);
        if (response && response.status) {
            setDepartmentData(response?.data?.data);
        } else {
            // toast.error(response.error);
        }
    };



    //for submitting functionality
    const submitHandler = async (e) => {
        e.preventDefault();
        setShouldValidateOnChange(true);
        setShouldValidateOnBlur(true);
        // console.log("handleSubmit", values);

        let data = {};
        setLoading(true);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the email is empty or not valid
        if (!values.interviewerEmail || !emailPattern.test(values.interviewerEmail)) {
            toast.error("Please enter a valid email address");
            return;

        }

        if (!values.altEmail || !emailPattern.test(values.altEmail)) {
            toast.error("Please enter a valid email address");
            return;
        }


        if (values.jobTitle == "") {
            return toast.error("Job Title is required");
        }
        if (values.Department == "") {
            return toast.error("Department is required");
        }
        if (values.jobLocation == [""]) {
            return toast.error("Location is required");
        }
        if (values.hiringManager == "") {
            return toast.error("HiringManager is required");
        }
        if (values.level == "") {
            return toast.error("level is required");
        }
        if (values.isNewPosition == "") {
            return toast.error("New Position is field required");
        }
        if (values.isTempPosition == "") {
            return toast.error("TempPosition is required");
        }
        if (values.candidateSourcing == "") {
            return toast.error("Candidate Sourcing is required");
        }
        if (values.hiringReason == "") {
            return toast.error("Hiring Reason is required");
        }
        if (values.priority == "") {
            return toast.error("priority is required");
        }
        // if (values.description == "") {
        //     return toast.error("Description is required");
        // }
        if (values.interviewerEmail == [""]) {
            return toast.error("InterviewerEmail is required");
        }
        if (values.openings == "") {
            return toast.error("Opening is required");
        }

        if (values.qualification == "") {
            return toast.error("qualification is required");
        }
        // if (values.addQualification == "") {
        //     return toast.error("AddQualification is required");
        // }
        if (values.experience_low == "") {
            return toast.error("Experience_low is required");
        }
        if (values.experience_high == "") {
            return toast.error("Experience_high is required");
        }
        if (values.Competencies == "") {
            return toast.error("Competencies is required");
        }
        if (values.inclusion == "") {
            return toast.error("Inclusion is required");
        }
        if (values.interviewProcess == "") {
            return toast.error("InterviewProcess is required");
        }
        if (values.criteria == "") {
            return toast.error("Criteria is required");
        }
        if (values.profileLink == "") {
            return toast.error("Profile Link is required");
        }
        if (values.salary == "") {
            return toast.error("Salary is required");
        }
        if (values.period == "") {
            return toast.error("Period is required");
        }
        if (values.keySkills == "") {
            return toast.error("Skill is required");
        }
        if (values.positionDescription == "") {
            return toast.error("Position Description is required");
        }
        if (values.responsibilities == "") {
            return toast.error("Responsibilities is required");
        }
        // if (values.Department == "") {
        //     return toast.error("All fields required");
        // }

        data = {
            jobTitle: values?.jobTitle,
            Department: values?.Department,
            jobLocation: values?.jobLocation,
            hiringManager: values?.hiringManager,
            level: values?.level,
            isNewPosition: values?.isNewPosition,
            isTempPosition: values?.isTempPosition,
            candidateSourcing: values?.candidateSourcing,
            hiringReason: values?.hiringReason,
            priority: values?.priority,
            description: values?.description,
            interviewerEmail: values?.interviewerEmail,
            openings: values?.openings,
            altEmail: values?.altEmail,
            qualification: values?.qualification,
            addQualification: values?.addQualification,
            experience_low: values?.experience_low,
            experience_high: values?.experience_high,
            Competencies: values?.Competencies,
            inclusion: values?.inclusion,
            interviewProcess: values?.interviewProcess,
            criteria: values?.criteria,
            profileLink: values?.profileLink,
            salary: values?.salary,
            salaryDate: moment(values?.salaryDate).format("YYYY-MM-DD"),

            positionDescription: values?.positionDescription,
            // positionName: values?.positionName,
            responsibilities: values?.responsibilities,

            goodToHave: values?.goodToHave,
            keySkills: values?.keySkills,
            period: values?.period,
            isReffered: values?.isReffered
            // qualifications: values?.qualifications,
            // advertising: values?.advertising,
            // additionalComments: values?.additionalComments,
            // approvals: values?.approvals,
            // keyResponsibilities: values?.keyResponsibilities,
        };
        // console.log("click_training", data);
        const res = await ApiHelperFunction({
            urlPath: `/edit-job-requirment/${values?._id}`,
            method: "PUT",
            data,
        });

        if (res?.status) {
            toast.success("Updated Successfully");
            // resetForm();

        } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
        }
        setLoading(false);
    };


    const { values, handleBlur, handleChange, setFieldValue } =
        useFormik({
            initialValues: {
                jobTitle: "",
                Department: "",
                jobLocation: [],
                hiringManager: "",
                level: "",
                isNewPosition: true,
                isTempPosition: false,
                candidateSourcing: "",
                hiringReason: "",
                priority: "",
                description: "",
                interviewerEmail: [],
                openings: "",
                altEmail: "",
                qualification: "",
                addQualification: "",
                experience_low: "",
                experience_high: "",
                Competencies: "",
                inclusion: "",
                interviewProcess: "",
                criteria: "",
                profileLink: "",
                salary: "",
                responsibilities: "",
                positionDescription: "",
                keySkills: [""],
                salaryDate: "",
                isReffered: false,
                // advertising: "",
                // additionalComments: "",
                // approvals: "",
                // keyResponsibilities: "",
            },
            // validationSchema: schemaCheck,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

    console.log("ortyu", values);
    // const handlePriorityClick = (priority) => {
    //     setFieldValue("priority", priority);
    // };

    // Handler to add interviewer email by clicking on 'addimg'
    // const handleAddInterviewerEmail = () => {
    //     const newInterviewerEmails = [...values.interviewerEmail, '']; // Add an empty email field
    //     setFieldValue('interviewerEmail', newInterviewerEmails);
    // };

    // Handler to remove interviewer email
    const handleRemoveInterviewerEmail = (index) => {
        const updatedInterviewerEmails = [...values.interviewerEmail];
        updatedInterviewerEmails.splice(index, 1);
        setFieldValue('interviewerEmail', updatedInterviewerEmails);
    };


    //for handling job approval
    const handleJobApprove = async (approvalStatus) => {
        // if (userData?.userType === "Admin") {
        let data = {
            // id: jobRequirmentId,
            // id: id,
            isAdminApproved: approvalStatus,
        };

        let response = await ApiHelperFunction({
            urlPath: `/giveapproval/${values?._id}`,
            method: "POST",
            data: data,
        });

        if (response.status == 200) {
            // console.log("responseadmin", response?.data);
            if (response?.data?.status) {
                // toast.success(response?.data?.message);
                toast.success(response?.data?.message);

                if (approvalStatus == "true") {
                    setApprove(true);
                    setApprovalModal(true);
                    setDisApprovalModal(false);
                } else if (approvalStatus == "false") {
                    setApprove(false);
                    setApprovalModal(false);
                    setDisApprovalModal(true)
                } else if (approvalStatus == "defer") {
                    // toast?.success(response?.data?.message)
                    setApprove(false);
                    setApprovalModal(false);
                    setDisApprovalModal(false)
                } else {
                    setApprove(false);
                    setApprovalModal(false);
                    setDisApprovalModal(false);
                }

                // if (approvalStatus) {
                //     setApprove(approvalStatus);
                // }
            } else {
                // toast.error(response?.data?.message);
                // toast.success("Server Error");
                // console.log("ypoiu",response?.data?.message)

            }
        } else {
            // toast.success("Server Error");
            // toast.error(response?.response?.data?.message);
            // console.log(response?.response?.data?.message,"poutre")
        }
        // }
    };

    //for submitting hr approval part
    const HandleSubmit = async () => {
        let data = {};
        if (isApprove == true) {
            data = {
                advertising: formData.advertising,
                additionalComments: formData.additionalComments,
                approvals: formData.approvals,
                jobRequirmentId: values?._id
            };
        }
        if (isApprove == false) {
            data = {
                reason: formData?.reason,
                jobRequirmentId: values?._id,
                isAdminApproved: false
            };
        }

        // console.log(data,isAdminApproved,"rtyuiop")
        // data = {
        //     advertising: formData.advertising,
        //     additionalComments: formData.additionalComments,
        //     approvals: formData.approvals,
        //     jobRequirmentId: values?._id
        // };

        const res = await ApiHelperFunction({
            urlPath: `/fill-approval-form`,
            method: "POST",
            data,
        });
        if (res?.status) {
            toast.success("Submit to Admin successfully");
            // resetForm();
            // dispatch(getInitiative());
            // closemodal();
        } else {
            // toast.error(res?.message || "Something went wrong");
            console.log("ERROR CREATING USER3", res);
        }
        // }
    }

    // onchange
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }))
    }

    // const handleClick = () => {
    //     if (document.querySelector('#DarkSwitch').checked) {
    //         document.body.classList.add("drakmode");
    //     }
    //     else {
    //         document.body.classList.remove("drakmode");
    //     }
    // }
    // console.log("isAdminApprovedqwer", values?._id)

    // const handleToggleChange = (e) => {
    //     console.log("togglee", e.target.checked)
    //     setCheck(e.target.checked)
    // }


    return (
        <>
            <section className='jobnotificationmain'>
                <div className='employeepagecontainer'>
                    <div className='jobmaindtxlflx'>
                        <div className='jobbrdrwhdt'>
                            <div className='jobdtksvbrdr'>
                                <div className=''>
                                    <form>
                                        <div className='fulljobdiv'>
                                            <div className='jobdtlsdiv'>
                                                <p className='jibtxt'>Job Title <span class="required">*</span></p>
                                                <input type='text'
                                                    className='jobtxtinpt'
                                                    name="jobTitle"
                                                    value={values?.jobTitle}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {/* <div className='jobdtlsdiv'>
                                                <p className='jibtxt'>Department/Team <span class="required">*</span></p>
                                                <input type='text'
                                                    className='jobtxtinpt'
                                                    name="Department"
                                                    value={values?.Department}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div> */}
                                            <div className='jobdtlsdiv'>
                                                <p className='jibtxt'>Department<span class="required">*</span></p>
                                                <select
                                                    class="jobslctdiv"
                                                    aria-label="Select Department name"
                                                    name="Department"
                                                    value={values?.Department}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    <option value={""} >
                                                        Select Department
                                                    </option>
                                                    {departmentData?.map((item, i) => (
                                                        <option key={i} value={item?._id}>
                                                            {item?.departmentName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className='jobdtlsdiv'>
                                                <p className='jibtxt'>Hiring Manager<span class="required">*</span></p>
                                                {/* <input
                                        type='text'
                                        className='jobtxtinpt'
                                        name="hiringManager"
                                        value={values?.hiringManager}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    /> */}
                                                <select
                                                    class="hirinfsingle"
                                                    aria-label="Select Hiring Manager name"
                                                    name="hiringManager"
                                                    value={values?.hiringManager}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    <option value={""} >
                                                        Select Manager
                                                    </option>
                                                    {managerData?.map((item, i) => (
                                                        <option key={i} value={item?._id}>
                                                            {item?.firstName} {item?.lastName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='jobdtlsdiv'>
                                                <p className='jibtxt'>Location<span class="required">*</span></p>
                                                <input
                                                    type='text'
                                                    className='jobtxtinpt'
                                                    name="jobLocation"
                                                    value={values?.jobLocation}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='jobdtlsdiv'>
                                                <p className='jibtxt'>Level<span class="required">*</span></p>
                                                <input
                                                    type='text'
                                                    className='jobtxtinpt'
                                                    name="level"
                                                    value={values?.level}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='ysnopst'>
                                                <p className='jibtxt'>Is this a new Position?<span class="required">*</span></p>
                                                <div className='yxsbnflx'>
                                                    <div className='mrtrdtys'>
                                                        {/* <input type="radio" id="Yes" name="" defaultValue="yes" /> */}
                                                        <input

                                                            type="radio"
                                                            id="true"
                                                            name="isNewPosition"
                                                            value="true"
                                                            checked={values?.isNewPosition === true}
                                                            // onChange={handleChange}
                                                            onChange={() => setFieldValue('isNewPosition', true)}
                                                        />
                                                        <label htmlFor="html">Yes</label>
                                                    </div>
                                                    <div className='mrtrdtys'>
                                                        {/* <input type="radio" id="No" name="" defaultValue="no" /> */}
                                                        <input

                                                            type="radio"
                                                            id="false"
                                                            name="isNewPosition"
                                                            value="false"
                                                            checked={values?.isNewPosition === false}
                                                            // onChange={handleChange}
                                                            onChange={() => setFieldValue('isNewPosition', false)}
                                                        />
                                                        <label htmlFor="html">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ysnopst'>
                                                <p className='jibtxt'>Is this a Temporary Position?<span class="required">*</span></p>
                                                <div className='yxsbnflx'>
                                                    <div className='mrtrdtys'>
                                                        {/* <input type="radio" id="Yes" name="" defaultValue="yes" /> */}
                                                        <input

                                                            type="radio"
                                                            id="true"
                                                            name="isTempPosition"
                                                            value="true"
                                                            checked={values?.isTempPosition === true}
                                                            // onChange={handleChange}
                                                            onChange={() => setFieldValue('isTempPosition', true)}
                                                        />
                                                        <label htmlFor="html">Yes</label>
                                                    </div>
                                                    <div className='mrtrdtys'>
                                                        {/* <input type="radio" id="No" name="" defaultValue="No" /> */}
                                                        <input
                                                            // defaultValue="no"
                                                            type="radio"
                                                            id="false"
                                                            name="isTempPosition"
                                                            value="false"
                                                            checked={values?.isTempPosition === false}
                                                            // onChange={handleChange}
                                                            onChange={() => setFieldValue('isTempPosition', false)}
                                                        />
                                                        <label htmlFor="html">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ysnopst'>
                                                <p className='jibtxt'>Level Of Priority<span class="required">*</span></p>
                                                <div className='hgmdlwflx'>


                                                    <button
                                                        className={values?.priority === "High" ? "btnscactb" : "plnbtnact"}
                                                        type='button'
                                                        onClick={() => setFieldValue('priority', 'High')}
                                                    >
                                                        High
                                                    </button>
                                                    <button
                                                        className={values?.priority === "Mid" ? "btnscactb" : "plnbtnact"}
                                                        type='button'
                                                        onClick={() => setFieldValue('priority', 'Mid')}
                                                    >
                                                        Medium
                                                    </button>
                                                    <button
                                                        className={values?.priority === "Low" ? "btnscactb" : "plnbtnact"}
                                                        type='button'
                                                        onClick={() => setFieldValue('priority', 'Low')}
                                                    >
                                                        Low
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='ysnopst'>
                                                <p className='jibtxt'>Candidate Sourcing Preference<span class="required">*</span></p>
                                                <div className='hgmdlwflx'>
                                                    {/* <button
                                                type='button'
                                                className='gnjhbtn'
                                                onClick={() => setFieldValue(prev => ({ ...prev, candidateSourcing: "External" }))}
                                            >
                                                External
                                            </button>
                                            <button
                                                type='button'
                                                className='gnjhbtn'
                                                onClick={() => setFieldValue(prev => ({ ...prev, candidateSourcing: "Internal" }))}
                                            >
                                                Internal
                                            </button>
                                            <button type='button'
                                                className='gnjhbtn'
                                                onClick={() => setFieldValue(prev => ({ ...prev, candidateSourcing: "Both" }))}
                                            >
                                                Both
                                            </button> */}

                                                    <button
                                                        className={values?.candidateSourcing === "External" ? "btnscactb" : "plnbtnact"}
                                                        type='button'
                                                        onClick={() => setFieldValue('candidateSourcing', 'External')}
                                                    >
                                                        External
                                                    </button>
                                                    <button
                                                        className={values?.candidateSourcing === "Internal" ? "btnscactb" : "plnbtnact"}
                                                        type='button'
                                                        onClick={() => setFieldValue('candidateSourcing', 'Internal')}
                                                    >
                                                        Internal
                                                    </button>
                                                    <button
                                                        className={values?.candidateSourcing === "Both" ? "btnscactb" : "plnbtnact"}
                                                        type='button'
                                                        onClick={() => setFieldValue('candidateSourcing', 'Both')}
                                                    >
                                                        Both
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                        <div className='jobdtlswh'>
                            <div className='dtlskmflx'>
                                <h4 className='dtlstxtpphh'>Details</h4>
                                {/* <p className='jibtxt'><span class="required">*</span>Mandatory</p> */}
                                <div className='rfrlinpflx'>
                                    <h6
                                    //  className='opnttxt'
                                    ><span class="required"></span>Open to Refferal Hiring</h6>
                                    <div className="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="DarkSwitch"
                                            // onClick={handleClick} 
                                            // checked={isCheck}
                                            // onChange={(e) => handleToggleChange(e)}
                                            checked={values?.isReffered}
                                            onChange={(e) => setFieldValue('isReffered', e.target.checked)}
                                        />
                                        <label className="custom-control-label" htmlFor="DarkSwitch"></label>
                                    </div>
                                </div>
                                <div className='sbmtdivmainnn'>
                                    <button className='sbmtdiv'
                                        type="button"
                                        onClick={submitHandler}>
                                        {/* <div className='pstimg'>
                                            <img src={Post} alt="..." />
                                        </div> */}
                                        <i class="fas fa-edit"></i>
                                        <div className='hrtxty' style={{ marginLeft: "4px" }}>
                                            Edit
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className=''>
                                <form>
                                    <div className='alldtlsfrmdiv'>
                                        <div className='senddivrxr'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Reason for Hire<span class="required">*</span></p>
                                            </div>
                                            <div className='rsntxrptxtdiv'>
                                                <textarea
                                                    cols={50}
                                                    rows={2}
                                                    type="text"
                                                    className='rsjobtxtdiv'
                                                    name="hiringReason"
                                                    value={values?.hiringReason}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='senddivrxr'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Position Description<span class="required">*</span></p>
                                            </div>
                                            <div className='rsntxrptxtdiv'>
                                                <textarea
                                                    cols={50}
                                                    rows={2}
                                                    type="text"
                                                    className='rsjobtxtdiv'
                                                    name="positionDescription"
                                                    value={values?.positionDescription}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Interviewer Email Id<span class="required">*</span></p>
                                    </div>
                                    <div className='addimginpt'>
                                        <div className='addimg'>
                                            <img src={a} alt="..." />
                                        </div>

                                       

                                        <div className='interdivmain'>
                                            <input type='text' className='interdiv' />

                                        </div>
                                    </div>
                                </div> */}

                                        {/* <div className='senddivrxr'>
                                    <div className='rsntxrpdiv'>
                                        <p className='rsntxrp'>Interviewer Email Id<span class="required">*</span></p>
                                    </div>
                                    <div className='addimginpt'>
                                        <div className='addimg' onClick={handleAddInterviewerEmail}> 
                                            <img src={a} alt="..." />
                                        </div>
                                       
                                        {values?.interviewerEmail?.map((email, index) => (
                                            <div key={index} className="added-email">
                                                <input
                                                    type='text'
                                                    className='interdiv'
                                                    value={email}
                                                    onChange={(e) => {
                                                        const updatedInterviewerEmails = [...values.interviewerEmail];
                                                        updatedInterviewerEmails[index] = e.target.value;
                                                        setFieldValue('interviewerEmail', updatedInterviewerEmails);
                                                    }}
                                                />
                                                <button type="button" onClick={() => handleRemoveInterviewerEmail(index)}>Remove</button>
                                            </div>
                                        ))}
                                    </div>
                                </div> */}

                                        <div className='senddivrxrdiv'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Interviewer Email Id<span class="required">*</span></p>
                                            </div>
                                            <div className='addimginptdivmm'>

                                                {/* Display added interviewer emails */}
                                                {values?.interviewerEmail?.map((email, index) => (
                                                    <div key={index} className="added-emaildivmain">
                                                        <input
                                                            type='text'
                                                            className='interdivmaintxt'
                                                            value={email}
                                                            onChange={(e) => {
                                                                const updatedInterviewerEmails = [...values.interviewerEmail];
                                                                updatedInterviewerEmails[index] = e.target.value;
                                                                setFieldValue('interviewerEmail', updatedInterviewerEmails);
                                                            }}
                                                        />
                                                        {/* Display remove button only for additional email fields */}
                                                        {index > 0 && <button type="button" className="btnrmvnbmain" onClick={() => handleRemoveInterviewerEmail(index)}>Remove</button>}
                                                    </div>
                                                ))}
                                                {/* <div className='addimg' onClick={handleAddInterviewerEmail}> 
                                                    <img src={a} alt="..." />
                                                </div> */}
                                            </div>
                                        </div>


                                        <div className='senddivrxr'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Alternative Email
                                                    <span class="required"></span>
                                                </p>
                                            </div>
                                            <div className='hrdivtxtmain'>
                                                <input
                                                    type="email"
                                                    className='hrdivtxt'
                                                    name="altEmail"
                                                    value={values?.altEmail}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className='senddivrxr'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>No. of Hire<span class="required">*</span></p>
                                            </div>
                                            <div className='hrdivtxtmain'>
                                                <input
                                                    type="number"
                                                    className='hrdivtxt'
                                                    name="openings"
                                                    value={values?.openings}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='senddivrxr'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Responsibilities<span class="required">*</span></p>
                                            </div>
                                            <div className='rsntxrptxtdiv'>
                                                <textarea
                                                    cols={50}
                                                    rows={2}
                                                    type="text"
                                                    className='rsjobtxtdiv'
                                                    name="responsibilities"
                                                    value={values?.responsibilities}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='qualiaddi'>
                                            <div className='minimdiv'>
                                                <div className="addqtmntxt">
                                                    <p className="rsntxrp">Minimum Qualification<span className="required">*</span></p>
                                                </div>
                                                <div className="qulitxtar">
                                                    <input
                                                        type="text"
                                                        className="qulitxtardiv"
                                                        name="qualification"
                                                        value={values?.qualification}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='addnidiv'>
                                                <div className="addqtmntxt">
                                                    <p className="rsntxrp">Additional Qualification<span className="required">*</span></p>
                                                </div>
                                                <div className="qulitxtar">
                                                    <input
                                                        type="text"
                                                        className="qulitxtardiv"
                                                        name="addQualification"
                                                        value={values?.addQualification}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='senddivrxr'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Experience(No. of Years)<span class="required">*</span></p>
                                            </div>
                                            <div className='frmtodivflx'>
                                                <div className='frmstrt'>
                                                    <>
                                                        <label htmlFor="">From</label>
                                                        {/* <select name="" id="">
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select> */}
                                                        <select
                                                            name="experience_low"
                                                            value={values?.experience_low}
                                                            onChange={handleChange}
                                                            className='experience-select'
                                                        >
                                                            <option>Select</option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                            <option value="16">16</option>
                                                            <option value="17">17</option>
                                                            <option value="18">18</option>
                                                            <option value="19">19</option>
                                                            <option value="20">20</option>
                                                            <option value="21">21</option>
                                                            <option value="22">22</option>
                                                            <option value="23">23</option>
                                                            <option value="24">24</option>
                                                            <option value="25">25</option>
                                                            <option value="26">26</option>
                                                            <option value="27">27</option>
                                                            <option value="28">28</option>
                                                            <option value="29">29</option>
                                                            <option value="30">30</option>
                                                        </select>
                                                    </>

                                                </div>
                                                <div className='frmend'>
                                                    <>
                                                        <label htmlFor="">To</label>
                                                        {/* <select name="" id="">
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select> */}
                                                        <select
                                                            name="experience_high"
                                                            value={values?.experience_high}
                                                            onChange={handleChange}
                                                            className='experience-select'
                                                        >
                                                            <option>Select</option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                            <option value="16">16</option>
                                                            <option value="17">17</option>
                                                            <option value="18">18</option>
                                                            <option value="19">19</option>
                                                            <option value="20">20</option>
                                                            <option value="21">21</option>
                                                            <option value="22">22</option>
                                                            <option value="23">23</option>
                                                            <option value="24">24</option>
                                                            <option value="25">25</option>
                                                            <option value="26">26</option>
                                                            <option value="27">27</option>
                                                            <option value="28">28</option>
                                                            <option value="29">29</option>
                                                            <option value="30">30</option>
                                                        </select>
                                                    </>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='senddivrxr'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Competencies and Traits<span class="required">*</span></p>
                                            </div>
                                            <div className='rsntxrptxtdiv'>
                                                <textarea
                                                    cols={50}
                                                    rows={2}
                                                    type="text"
                                                    className='rsjobtxtdiv'
                                                    name="Competencies"
                                                    value={values?.Competencies}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='senddivrxr'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Inclusion Considerations<span class="required">*</span></p>
                                            </div>
                                            <div className='rsntxrptxtdiv'>
                                                <textarea
                                                    cols={50}
                                                    rows={2}
                                                    type="text"
                                                    className='rsjobtxtdiv'
                                                    name="inclusion"
                                                    value={values?.inclusion}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='positionwhnflx'>
                                            <div className='avgdixflx'>
                                                <div className='avgsldiv'>
                                                    <p className='rsntxrp'>Avg. Salary for the Position<span class="required">*</span></p>
                                                </div>
                                                <div className='avgdivmain'>
                                                    <input
                                                        type="number"
                                                        className='avgdiv'
                                                        name="salary"
                                                        value={values?.salary}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='whendixflx'>
                                                <div className='addldiv'>
                                                    <p className='rsntxrp'>By When<span class="required">*</span></p>
                                                </div>
                                                <div className='caledricontxtflsx'>
                                                    <div className='clndrimg'>
                                                        <img src={Calender} alt="..." />
                                                    </div>
                                                    <div className='avgdivmain'>
                                                        <input
                                                            type="date"
                                                            className='avgdiv'
                                                            name="salaryDate"
                                                            value={values?.salaryDate}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='senddivrxr'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Interview Process<span class="required">*</span></p>
                                            </div>
                                            <div className='rsntxrptxtdiv'>
                                                <input
                                                    type="text"
                                                    className='rsjobtxtdiv'
                                                    name="interviewProcess"
                                                    value={values?.interviewProcess}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='senddivrxr'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Evaluation Criteria<span class="required">*</span></p>
                                            </div>
                                            <div className='rsntxrptxtdiv'>
                                                <textarea
                                                    cols={50}
                                                    rows={2}
                                                    type="text"
                                                    className='rsjobtxtdiv'
                                                    name="criteria"
                                                    value={values?.criteria}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='senddivrxr'>
                                            <div className='prvdlinkdiv'>
                                                <p className='rsntxrp'>Sample Profile From Job Board</p>
                                                <p className='rsntxrp'>Provided Link <span class="required">*</span></p>
                                            </div>
                                            <div className='rsntxrptxtdiv'>
                                                <input
                                                    type="text"
                                                    className='rsjobtxtdiv'
                                                    name="profileLink"
                                                    value={values?.profileLink}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className='senddivrxr'>
                                            {/* <div className=''> */}
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Skills<span class="required">*</span></p>
                                            </div>
                                            <div className='rsntxrptxtdiv'>
                                                <textarea
                                                    cols={50}
                                                    rows={2}
                                                    type="text"
                                                    className='rsjobtxtdiv'
                                                    name="keySkills"
                                                    value={values?.keySkills}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />

                                            </div>
                                        </div>
                                        <div className='senddivrxr'>
                                            <div className='rsntxrpdiv'>
                                                <p className='rsntxrp'>Nature of Engagement<span class="required">*</span></p>
                                            </div>
                                            <div className='btnflxmain'>
                                                <div className={values?.period === "FullTime" ? "btnscactb" : "plnbtnact"}

                                                    onClick={() => setFieldValue('period', 'FullTime')}
                                                >Full Time</div>
                                                <div className={values?.period === "PartTime" ? "btnscactb" : "plnbtnact"}

                                                    onClick={() => setFieldValue('period', 'PartTime')}
                                                >Part Time</div>
                                                <div className={values?.period === "Internship" ? "btnscactb" : "plnbtnact"}

                                                    onClick={() => setFieldValue('period', 'Internship')}
                                                >Internship</div>
                                                <div className={values?.period === "Contractual" ? "btnscactb" : "plnbtnact"}

                                                    onClick={() => setFieldValue('period', 'Contractual')}
                                                >Contractual</div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div >




                            {isAdminApproved === "pending" ? (
                                <>
                                    <div className="btnacpt btndivmainflx">
                                        <button
                                            className="btndivaprrcj"
                                            // style={{ marginLeft: "4rem" }}
                                            onClick={() => handleJobApprove("true")}
                                        >
                                            Approve
                                        </button>
                                        <button
                                            className="btndivaprrcj"
                                            // style={{ marginRight: "4rem" }}
                                            onClick={() => handleJobApprove("false")}
                                        >
                                            Reject
                                        </button>
                                        <button
                                            className="btndivaprrcj"
                                            // style={{ marginRight: "4rem" }}
                                            onClick={() => handleJobApprove("defer")}
                                        >
                                            Defer
                                        </button>
                                    </div>
                                </>
                            ) : isAdminApproved === "true" ? (
                                <div className="apprdiv">
                                    <button className="btnapprovd">Approved</button>
                                </div>
                            ) : (
                                <button className="btnapprovde">Rejected</button>
                            )}


                            {approvalModal ? (<><div className='desareadivmaindiv'>
                                <div className='desareadiv'>

                                    <div className='rsntxrpdivpding'>
                                        <p className='rsntxrp'>Advertising and Sourcing<span class="required">*</span></p>
                                    </div>
                                    <div className=''>
                                        <textarea
                                            type="text"
                                            cols={100}
                                            rows={3}
                                            className=''
                                            name="advertising"
                                            value={formData?.advertising}
                                            onBlur={handleBlur}
                                            onChange={handleFormChange}
                                        >
                                        </textarea>
                                    </div>
                                </div>

                                <div className='desareadiv'>

                                    <div className='rsntxrpdivpding'>
                                        <p className='rsntxrp'>Additional Comments/Notes<span class="required">*</span></p>
                                    </div>
                                    <div className=''>
                                        <textarea
                                            type="text"
                                            cols={100}
                                            rows={3}
                                            className=''
                                            name="additionalComments"
                                            value={formData?.additionalComments}
                                            onBlur={handleBlur}
                                            onChange={handleFormChange}
                                        >
                                        </textarea>
                                    </div>
                                </div>

                                <div className='desareadiv'>

                                    <div className='rsntxrpdivpding'>
                                        <p className='rsntxrp'>Approvals<span class="required">*</span></p>
                                    </div>
                                    <div className=''>
                                        <textarea
                                            type="text"
                                            cols={100}
                                            rows={3}
                                            className=''
                                            name="approvals"
                                            value={formData?.approvals}
                                            onBlur={handleBlur}
                                            onChange={handleFormChange}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                                {/* <div className='sbmtdivmainnndiv'>
                                    <button className='sbmtdiv' style={{ marginTop: "10px" }}
                                        type="button"
                                        onClick={HandleSubmit}>
                                        <div className='pstimg'>
                                            <img src={Post} alt="..." />
                                        </div>
                                        <div className='hrtxty'>
                                            Submit to Admin
                                        </div>
                                    </button>
                                </div> */}

                            </>
                            ) : (<></>)}


                            {disapprovalModal ? (<><div className='desareadivmaindiv'>

                                <div className='desareadiv'>

                                    <div className='rsntxrpdivpding'>
                                        <p className='rsntxrp'>Reason<span class="required">*</span></p>
                                    </div>
                                    <div className=''>
                                        <textarea
                                            type="text"
                                            cols={100}
                                            rows={3}
                                            className=''
                                            name="reason"
                                            value={formData?.reason}
                                            onBlur={handleBlur}
                                            onChange={handleFormChange}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>


                            </>
                            ) : (<></>)}

                            <div className='sbmtdivmainnndiv'>
                                <button className='sbmtdiv' style={{ marginTop: "10px" }}
                                    type="button"
                                    onClick={HandleSubmit}>
                                    <div className='pstimg'>
                                        <img src={Post} alt="..." />
                                    </div>
                                    <div className='hrtxty'>
                                        Submit to Admin
                                    </div>
                                </button>
                            </div>
                        </div >
                    </div>
                </div>
            </section>

        </>
    )
}


export default JobNotificationSingle