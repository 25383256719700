import React, { useEffect, useState } from "react";
import "./Notifications.css";
import notiImg from "../../Images/notiImg.png";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import { useAuthCtx } from "../../context/AuthCtx";
import { toast } from "react-hot-toast";
import moment from "moment";

function NotificationSingle({
  advocacyId,
  closeSingleModal,
  title,
  description,
  image,
  userFirstName,
  userLastName,
  senderName,
  isAdminApproved,
  groupId,
  requestId,
  createdOn,
  onboardingTrainingId,
  friendRequestId,
  isFriendReqAccept,
  senderImage,
  viewAllNotification
}) {
  const [isApprove, setApprove] = useState("pending");
  const { userData } = useAuthCtx();
  const [isApproveAdvocacy, setApproveAdvocacy] = useState("pending");
  const [isApproveTraining, setApproveTraining] = useState("pending");
  const [isFriendRequest, setIsFriendRequest] = useState("pending");
  

  //for handling joining group
  const handleAffinityApprove = async (requestId, approvalStatus) => {
    if (userData?.userType === "Admin" || userData?.userType === "Manager" || userData?.userType === "Owner") {
      let data = {
        id: requestId,
        // id: id,
        isAdminApproved: approvalStatus,
      };

      let response = await ApiHelperFunction({
        urlPath: "/give-join-approval",
        method: "POST",
        data: data,
      });

      if (response.status == 200) {
        // console.log("responseadmin", response?.data);
        if (response?.data?.status) {
          toast.success(response?.data?.message);
          // if (approvalStatus === true) {
          //   setApprove(true);
          // } else {
          //   setApprove(false);
          // }

          if (approvalStatus) {
            setApprove(approvalStatus);
          }
          viewAllNotification();
        } else {
          toast.error(response?.data?.message);
        }
      } else {
        toast.error(response?.response?.data?.message);
      }
    }
  };


  //for handling advocacy post
  const handleApprove = async (advocacyId, approvalStatus) => {
    if (userData?.userType === "Manager") {
      let data = {
        id: advocacyId,
        isAdminApproved: approvalStatus,
      };

      let response = await ApiHelperFunction({
        urlPath: "/advocacy-content-update",
        method: "POST",
        data: data,
      });

      if (response.status == 200) {
        console.log("response", response?.data);
        if (response?.data?.status) {
          toast.success(response?.data?.message);
          if (approvalStatus) {
            setApproveAdvocacy(approvalStatus);
          }
          viewAllNotification();
        } else {
          toast.error(response?.message);
        }
      }
    } else if (userData?.userType === "Admin") {
      let data = {
        id: advocacyId,
        isAdminApproved: approvalStatus,
      };

      let response = await ApiHelperFunction({
        urlPath: "/advocacy-content-update",
        method: "POST",
        data: data,
      });

      if (response.status == 200) {
        if (response?.data?.status) {
          toast.success(response?.data?.message);
          if (approvalStatus) {
            setApproveAdvocacy(approvalStatus);
          }
        } else {
          toast?.error(response?.data?.message);
        }
      }
    } else {
      return;
    }
    closeSingleModal();
    // dispatch(getAdvocacyPosts());
    //  else {
    //   toast.error("Can't get data. Something went wrong");
    // }
  };


  //for handling approve training
  const handleApproveTraining = async (onboardingTrainingId, approvalStatus) => {
    if (userData?.userType === "Manager") {
      let data = {
        // id: onboardingTrainingId,
        isAdminApproved: approvalStatus,
      };

      let response = await ApiHelperFunction({
        urlPath: `/give-approval-training/${onboardingTrainingId}`,
        method: "POST",
        data: data,
      });

      if (response.status == 200) {
        console.log("response", response?.data);
        if (response?.data?.status) {
          toast.success(response?.data?.message);
          if (approvalStatus) {
            setApproveTraining(approvalStatus);
          }
          viewAllNotification();
        } else {
          toast?.error(response?.message);
        }
      }
    } else if (userData?.userType === "Admin") {
      let data = {
        // id: onboardingTrainingId,
        isAdminApproved: approvalStatus,
      };

      let response = await ApiHelperFunction({
        urlPath: `/give-approval-training/${onboardingTrainingId}`,
        method: "POST",
        data: data,
      });

      if (response.status == 200) {
        if (response?.data?.status) {
          toast.success(response?.data?.message);
          if (approvalStatus) {
            setApproveTraining(approvalStatus);
          }
          viewAllNotification();
        } else {
          toast.error(response?.data?.message);
        }
      }
    } else {
      return;
    }
    closeSingleModal();
    // dispatch(getAdvocacyPosts());
    //  else {
    //   toast.error("Can't get data. Something went wrong");
    // }
  };


  //for handling friend request
  const handleFriendRequestApprove = async (friendRequestId, approvalStatus) => {
    let data = {
      isFriendReqAccept: approvalStatus,
    };

    let response = await ApiHelperFunction({
      urlPath: `/accept-reject-request/${friendRequestId}`,
      method: "POST",
      data: data,
    });

    if (response.status === 200) {
      if (response?.data?.status) {
        toast?.success(response?.data?.message);
        if (approvalStatus) {
          setIsFriendRequest(approvalStatus);
        }
        closeSingleModal();
        viewAllNotification();
      } else {
        toast.error(response?.data?.message);
      }
    } else {
      toast.error(response?.response?.data?.message);
    }
  };


  useEffect(() => {
    setApprove(isAdminApproved);
    setApproveAdvocacy(isAdminApproved);
    setApproveTraining(isAdminApproved);
  }, [isAdminApproved]);

  useEffect(() => {
    setIsFriendRequest(isFriendReqAccept)
  }, [isFriendReqAccept])

  return (
    <>
      <section className="notiSingleSection">
        {
          groupId
            ?
            (<div className="notisignalInnerDiv">
              <div className="notSingMainDiv">
                <figure className="notImgFig">
                  <img src={notiImg} alt="..." />
                </figure>
                <div>
                  <p className="abcNotName">
                    {userFirstName} {userLastName}
                  </p>
                  <p className="timeNotSinText">
                    {moment(new Date(createdOn)).fromNow()}
                  </p>
                </div>
              </div>
              <p className="loremSingleText">{title}</p>


              {isAdminApproved === "pending" ? (
                <>
                  <div className="btnacpt">
                    <button
                      className="btn btn-success"
                      style={{ marginLeft: "4rem" }}
                      onClick={() => handleAffinityApprove(requestId, "true")}
                    >
                      Approve
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{ marginRight: "4rem" }}
                      onClick={() => handleAffinityApprove(requestId, "false")}
                    >
                      Reject
                    </button>
                  </div>
                </>
              ) : isAdminApproved === "true" ? (
                <button className="btn btn-success">Approved</button>
              ) : (
                <button className="btn btn-danger">Rejected</button>
              )}

              <button
                className="cancelSingleBtn"
                onClick={() => closeSingleModal()}
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>)
            :
            advocacyId
              ?
              (<div className="notisignalInnerDiv">
                <div className="notSingMainDiv">
                  <figure className="notImgFig">
                    <img src={notiImg} alt="..." />
                  </figure>
                  <div>
                    <p className="abcNotName">
                      {senderName}
                    </p>
                    <p className="timeNotSinText">
                      {" "}
                      {moment(new Date(createdOn))?.fromNow()}
                    </p>
                  </div>
                </div>
                <p className="loremSingleText">{description}</p>
                <figure className="singlePopFig">
                  <img src={image} alt="..." />
                </figure>
                {isAdminApproved === "pending" ? (
                  <>
                    <div className="btnacpt">
                      <button
                        className="btn btn-success"
                        style={{ marginTop: "8px", marginLeft: "8rem" }}
                        onClick={() => handleApprove(advocacyId, "true")}
                      >
                        Approve
                      </button>
                      <button
                        className="btn btn-danger"
                        style={{ marginTop: "8px", marginLeft: "5px" }}
                        onClick={() => handleApprove(advocacyId, "false")}
                      >
                        Reject
                      </button>
                    </div>
                  </>
                ) : isAdminApproved === "true" ? (
                  <button className="btn btn-success">Approved</button>
                ) : (
                  <button className="btn btn-danger">Rejected</button>
                )}

                <button
                  className="cancelSingleBtn"
                  onClick={() => closeSingleModal()}
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>)
              :
              friendRequestId
                ?
                (<div className="notisignalInnerDiv">
                  <div className="notSingMainDiv">
                    <figure className="notImgFig">
                      <img src={senderImage} alt="..." />
                    </figure>
                    <div>
                      <p className="abcNotName">
                        {senderName}
                      </p>
                      <p className="timeNotSinText">
                        {moment(new Date(createdOn))?.fromNow()}
                      </p>
                    </div>
                  </div>
                  <p className="loremSingleText">{title}</p>


                  {isFriendReqAccept === "pending" ? (
                    <>
                      <div className="btnacpt">
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "4rem" }}
                          onClick={() => handleFriendRequestApprove(friendRequestId, "accept")}
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-danger"
                          style={{ marginRight: "4rem" }}
                          onClick={() => handleFriendRequestApprove(friendRequestId, "reject")}
                        >
                          Reject
                        </button>
                      </div>
                    </>
                  ) : isFriendReqAccept === "accept" ? (
                    <button className="btn btn-success">Approved</button>
                  ) : (
                    <button className="btn btn-danger">Rejected</button>
                  )}

                  <button
                    className="cancelSingleBtn"
                    onClick={() => closeSingleModal()}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </div>)
                :

                (<div className="notisignalInnerDiv">
                  <div className="notSingMainDiv">
                    <figure className="notImgFig">
                      <img src={notiImg} alt="..." />
                    </figure>
                    <div>
                      <p className="abcNotName">
                        {userFirstName} {userLastName}
                      </p>
                      <p className="timeNotSinText">
                        {" "}
                        {moment(createdOn)?.startOf("hour")?.fromNow()}
                      </p>
                    </div>
                  </div>
                  <p className="loremSingleText">{description}</p>
                  <figure className="singlePopFig">
                    <img src={image} alt="..." />
                  </figure>
                  {isAdminApproved === "pending" ? (
                    <>
                      <div className="btnacpt">
                        <button
                          className="btn btn-success"
                          style={{ marginTop: "8px", marginLeft: "8rem" }}
                          onClick={() => handleApproveTraining(onboardingTrainingId, "true")}
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-danger"
                          style={{ marginTop: "8px", marginLeft: "5px" }}
                          onClick={() => handleApproveTraining(onboardingTrainingId, "false")}
                        >
                          Reject
                        </button>
                      </div>
                    </>
                  ) : isAdminApproved === "true" ? (
                    <button className="btn btn-success">Approved</button>
                  ) : (
                    <button className="btn btn-danger">Rejected</button>
                  )}

                  <button
                    className="cancelSingleBtn"
                    onClick={() => closeSingleModal()}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </div>)

        }

      </section>
    </>
  );
}

export default NotificationSingle;
