import React, { useEffect, useState } from 'react'
// import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useFormik } from 'formik';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useAuthCtx } from '../../context/AuthCtx';


const MyLeaves = () => {
    const initial = {
        leaveTypeId: "",
        unit: "hour",
        startDate: "",
        EndDate: "",
        totalDays: "",
        reason: "",
        startDate: "",
        startTime: "",
        EndTime: "",
        totalDuration: ""
    }
    // const [value, onChange] = useState('10:00');
    const { userData } = useAuthCtx();
    const [leaveData, setleaveData] = useState(initial);
    // const [selectLeave, setSelectLeave] = useState("");
    const [leaveType, setLeaveType] = useState([]);
    const [leaveCount, setLeaveCount] = useState([]);
    const [leaveCountManager, setLeaveCountManager] = useState([]);
    const [leaveCountEmployee, setLeaveCountEmployee] = useState([]);

    // const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);


    //for fetching Leave Types
    const getLeaveType = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-leave-type`,
            method: "GET",
        });
        if (response && response.status) {
            setLeaveType(response?.data?.data);
        }
    };

    //for fetching Leave Count
    const getLeaveCount = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-gender-wise-leave-by-users`,
            method: "GET"
        });
        if (response && response?.status) {
            setLeaveCount(response?.data?.data);
        }
    };

    //onChange functionality for radio button
    const handleRadioChange = (e) => {
        const { value } = e.target;
        setleaveData((prev) => ({
            ...prev,
            unit: value
        }));
    };


    //for submitting functionality
    const submitHandler = async () => {
        // e.preventDefault();
        // setShouldValidateOnChange(true);
        let data = {};

        if (leaveData.leaveTypeId === "") {
            return toast?.error("Leave Type is required");
        }
        if (leaveData.unit === "") {
            return toast?.error("Day/hour is required");
        }
        if (leaveData.reason === "") {
            return toast?.error("Reason is required");
        }

        data = {
            leaveTypeId: leaveData?.leaveTypeId,
            unit: leaveData?.unit,
            reason: leaveData?.reason,
            startDate: moment(leaveData?.startDate)?.format('YYYY/MM/DD'),
            EndDate: leaveData?.EndDate ? moment(leaveData?.EndDate)?.format('YYYY/MM/DD') : "",
            startTime: leaveData?.startTime,
            EndTime: leaveData?.EndTime,
            totalDays: leaveData?.totalDays
        };
        // console.log("leaveData",data);
        // return false;
        const res = await ApiHelperFunction({
            urlPath: "/apply-for-leave",
            method: "POST",
            data,
        });
        if (res?.status) {
            toast.success("Leave Application is Submitted successfully");
            setleaveData(initial);
        } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
        }
    };

    // const { values, resetForm, handleChange, setFieldValue } =
    //     useFormik({
    //         leaveData,
    //         validateOnChange: shouldValidateOnChange

    //     });


    //onChange functionality
    const handleChange = (e) => {
        const { name, value } = e.target;

        setleaveData(prev => {
            if (name === 'EndDate' && new Date(value) < new Date(prev.startDate)) {
                toast.error("End date cannot be earlier than start date");
                return prev;
            }
            return { ...prev, [name]: value }
        });
    }

    useEffect(() => {
        getLeaveType();
        getLeaveCount();
    }, [])


    // Calculate total days based on startDate and EndDate
    useEffect(() => {
        if (leaveData?.startDate && leaveData?.EndDate) {
            const start = new Date(leaveData?.startDate);
            const end = new Date(leaveData?.EndDate);
            const timeDiff = end - start;
            const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
            setleaveData(prev => ({
                ...prev,
                totalDays: daysDiff + 1
            }));
        }
    }, [leaveData?.startDate, leaveData?.EndDate]);
    return (
        <>
            <div className='myleaverightsecdiv'>
                <div className=''>
                    <h4 className='mylvetxtpp'>My Leaves</h4>
                </div>

                <div className='leacvebalncediv'>
                    <div className='leaveblancpcrntdaydivflx'>
                        <p className='leaveblancetxtp'>Your Leave Balance</p>
                        <p className='crntdaystxtp'><span>*</span> Count in Days</p>
                    </div>

                    <div className=''>
                        <table className='tableleavebalance'>
                            <tr className='tableleavebalancetr'>
                                <th>

                                </th>
                                <th className='tableleavebalanceth'>
                                    <p className='tableleavebalancethtxt'>Total</p>
                                </th>
                                <th className='tableleavebalanceth'>
                                    <p className='tableleavebalancethtxt'>Used</p>
                                </th>
                                <th className='tableleavebalanceth'>
                                    <p className='tableleavebalancethtxt'>Renewing</p>
                                </th>
                            </tr>

                            {/* {
                                userData?.userType === "Admin"
                                    ?
                                    (<> */}
                            {
                                leaveCount?.map((item) => {
                                    return (
                                        <tr className='tableleavebalancetr'>
                                            <td className='tableleavebalancetd'>
                                                <p className='tableleavebalancetdtxt'>{item?.leaveName}</p>
                                            </td>
                                            <td className='tableleavebalancetd'>
                                                <p className='tableleavebalancetdtxt'>{item?.totalDays}</p>
                                            </td>
                                            <td className='tableleavebalancetd'>
                                                <p className='tableleavebalancetdtxt'>{item?.totalUsedLeave}</p>
                                            </td>
                                            <td className='tableleavebalancetd'>
                                                <p className='tableleavebalancetdtxt'>{item?.totalRemaining}</p>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            {/* </>)
                                    :
                                    userData?.userType === "Manager"
                                        ?
                                        (
                                            <>
                                                {
                                                    leaveCount?.map((item) => {
                                                        return (
                                                            <tr className='tableleavebalancetr'>
                                                                <td className='tableleavebalancetd'>
                                                                    <p className='tableleavebalancetdtxt'>{item?.name}</p>
                                                                </td>
                                                                <td className='tableleavebalancetd'>
                                                                    <p className='tableleavebalancetdtxt'>{item?.leaveForManager}</p>
                                                                </td>
                                                                <td className='tableleavebalancetd'>
                                                                    <p className='tableleavebalancetdtxt'>00</p>
                                                                </td>
                                                                <td className='tableleavebalancetd'>
                                                                    <p className='tableleavebalancetdtxt'>00</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                        :
                                        userData?.userType === "Employee"
                                            ?
                                            (
                                                <>
                                                    {
                                                        leaveCount?.map((item) => {
                                                            return (
                                                                <tr className='tableleavebalancetr'>
                                                                    <td className='tableleavebalancetd'>
                                                                        <p className='tableleavebalancetdtxt'>{item?.name}</p>
                                                                    </td>
                                                                    <td className='tableleavebalancetd'>
                                                                        <p className='tableleavebalancetdtxt'>{item?.leaveForEmployee}</p>
                                                                    </td>
                                                                    <td className='tableleavebalancetd'>
                                                                        <p className='tableleavebalancetdtxt'>00</p>
                                                                    </td>
                                                                    <td className='tableleavebalancetd'>
                                                                        <p className='tableleavebalancetdtxt'>00</p>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                            :
                                            (<></>)
                            } */}
                            {/* <tr className='tableleavebalancetr'>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>Casual</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>10</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>20</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>5</p>
                                </td>
                            </tr>
                            <tr className='tableleavebalancetr'>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>Medical</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>10</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>20</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>5</p>
                                </td>
                            </tr>
                            <tr className='tableleavebalancetr'>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>Maternity</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>10</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>20</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>5</p>
                                </td>
                            </tr>
                            <tr className='tableleavebalancetr'>
                                <td>
                                    <p className='tableleavebalancetdtxt'>Casual</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>10</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>20</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>5</p>
                                </td>
                            </tr>
                            <tr className='tableleavebalancetr'>
                                <td>
                                    <p className='tableleavebalancetdtxt'>Medical</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>10</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>20</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>5</p>
                                </td>
                            </tr> */}

                            <tr className='tableleavebalancetrbrdr'></tr>
                            <tr className='tableleavebalancetr'>
                                <td>
                                    <p className='tableleavebalancetdtxt'>Total</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>50</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>00</p>
                                </td>
                                <td className='tableleavebalancetd'>
                                    <p className='tableleavebalancetdtxt'>00</p>
                                </td>
                            </tr>


                        </table>
                    </div>
                </div>

                <div className=''>
                    <div className='mylvetxtppdivmain'>
                        <h4 className='mylvetxtpp'>Leave Apply</h4>
                    </div>

                    <div className=''>
                        <form>
                            <div className=''>
                                <p className='leavealltyeheadingtxtpp'>Leave Unit</p>

                                <div className='leavealltyeheadivflxflxmain'>
                                    <div className='leavealltyeheadivflx'>
                                        <input
                                            type="radio"
                                            id=""
                                            name="unit"
                                            value="hour"
                                            checked={leaveData?.unit === "hour"}
                                            onChange={handleRadioChange}
                                        />
                                        <label for="age1">Hour</label>
                                    </div>
                                    <div className='leavealltyeheadivflx'>
                                        <input
                                            type="radio"
                                            id=""
                                            name="unit"
                                            value="day"
                                            checked={leaveData?.unit === "day"}
                                            onChange={handleRadioChange}
                                        />
                                        <label for="age1">Days</label>
                                    </div>
                                </div>
                            </div>


                            {
                                leaveData?.unit === "day"
                                &&
                                <>
                                    <div className='timedatadivmain'>
                                        <p className='leavealltyeheadingtxtpp'>Date</p>
                                        <div className='leavealltyeheadingttimedivflx'>
                                            <div className='leavealltyeheadingttimedivright'>
                                                <p className='leavealltyeheadingttimepp'>From</p>
                                                <div className='dateinpuytdivmain'>
                                                    <input
                                                        type="date"
                                                        id=""
                                                        name="startDate"
                                                        // value={leaveData?.startDate}
                                                        value={leaveData?.startDate}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </div>

                                            </div>
                                            <div className='leavealltyeheadingttimedivleft'>
                                                <p className='leavealltyeheadingttimepp'>To</p>
                                                <div className='dateinpuytdivmain'>
                                                    <input
                                                        type="date"
                                                        id=""
                                                        name="EndDate"
                                                        value={leaveData?.EndDate}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="dateslevetstxtppdivflx">
                                        <p className='dateslevetstxtpp'>No. of Dates</p>
                                        <p className='dateslevetstxtpp'>{leaveData?.totalDays}</p>
                                    </div>
                                </>
                            }

                            {
                                leaveData?.unit === "hour"
                                &&
                                <>
                                    <div className='leavealltyeheadingttimedivright'>
                                        <p className='leavealltyeheadingttimepp'>Date</p>
                                        <div className='dateinpuytdivmain'>
                                            <input
                                                type="date"
                                                id=""
                                                name="startDate"
                                                value={leaveData?.startDate}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>

                                    </div>


                                    <div className='timedatadivmain'>
                                        <p className='leavealltyeheadingtxtpp'>Time</p>

                                        <div className='leavealltyeheadingttimedivflx'>
                                            <div className='leavealltyeheadingttimediv'>
                                                <p className='leavealltyeheadingttimepp'>From</p>
                                                <div className='dateinpuytdivmaintime'>
                                                    <input
                                                        type="time"
                                                        id=""
                                                        name="startTime"
                                                        value={leaveData?.startTime}
                                                        onChange={(e) => handleChange(e)}
                                                        placeholder=''

                                                    />
                                                </div>
                                            </div>
                                            <div className='leavealltyeheadingttimediv'>
                                                <p className='leavealltyeheadingttimepp'>To</p>
                                                <div className='dateinpuytdivmaintime'>
                                                    <input
                                                        type="time"
                                                        id=""
                                                        placeholder=''
                                                        name="EndTime"
                                                        value={leaveData?.EndTime}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </>

                            }



                            <div className='leavereqtxtppdivflx'>
                                <p className='leavereqtxtpp'>Leave Request Type</p>
                                <div className="leavereqtxtppdislct">
                                    <select
                                        name="leaveTypeId"
                                        value={leaveData?.leaveTypeId}
                                        onChange={(e) => handleChange(e)}

                                    >
                                        <option value={""} >
                                            Select Leave Type
                                        </option>
                                        {leaveType?.map((item, i) => (
                                            <option key={i} value={item?._id}>
                                                {item?.name}
                                            </option>
                                        ))}

                                        {/* <option value="">Casual</option>
                                        <option value="">Medical</option>
                                        <option value="">Maternity</option>
                                        <option value="">Casual</option> */}
                                    </select>
                                </div>
                            </div>

                            <div className="leavereqtxtppdiv">
                                <p className='leavereqtxtpp'>Reason</p>
                                <div className='reasontxtarediv'>

                                    <textarea
                                        id=""
                                        rows="4"
                                        cols="50"
                                        name="reason"
                                        value={leaveData?.reason}
                                        onChange={(e) => handleChange(e)}
                                    >

                                    </textarea>
                                </div>
                            </div>

                            <div className='sbmtdivbtnaslmain'>
                                <button
                                    className='sbmtdivbtnasl'
                                    type='button'
                                    onClick={submitHandler}
                                >
                                    Submit</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyLeaves
