import React, { useEffect, useState } from 'react'
import ApexCharts from 'react-apexcharts';
import Chart from 'react-apexcharts';
import CalenderAttendence from './CalenderAttendence';
import ProjectWorktable from './ProjectWorktable';
import Worklocationslide from './Worklocationslide';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';


const ProjectViewdetails = () => {
    const params = useParams();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [managercard, setManagercard] = useState(false);
    const [projectdetailmodal, setProjectdetailmodal] = useState(false);
    const [assignedEmp, setassignedEmp] = useState([]);
    const [SingleProject, setSingleProject] = useState({});
    const [chartOptions, setChartOptions] = useState({
        series: [],
        chart: {
            width: 410,
            type: 'donut',
        },
        title: {
            text: 'Project Breakdown by Employees',
            align: 'center',
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#000',
            },
        },
        dataLabels: {
            enabled: false,
        },
        labels: [],
        fill: {
            opacity: 1,
            colors: [],
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        show: true,
                    },
                },
            },
        ],
        legend: {
            position: 'right',
            horizontalAlign: 'center',
            itemMargin: {
                horizontal: 10,
                vertical: 5,
            },
            offsetY: 20,
            offsetX: 0,
            height: 250,
            markers: {
                width: 25,
                height: 7,
                radius: 5,
                fillColors: [],
            },
            labels: {
                colors: [],
            },
        },
    });
    const [managerProjectData, setManagerProjectData] = useState([]);
    const navigate = useNavigate();

    
    //get manager projects
    const getManagerProjectData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/fetch-manager-view`,
            method: "GET",
        });
        if (response && response.status) {
            setManagerProjectData(response?.data?.data);
        }
    }


    const handleprojectview = (id) => {
        navigate(`/projectviewdetails/${id}`)
    }

    // Generate color palette
    const generateColors = (count) => {
        const baseColors = [
            // '#cae5fc', 
            '#72b7ee',
            '#3ca8f9',
            '#1463a5',
            '#12436e'
        ];
        const colors = [];
        for (let i = 0; i < count; i++) {
            colors?.push(baseColors[i % baseColors?.length]);
        }
        return colors;
    };

    // Get all assigned Employee
    const getProjectData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/fetch-users-in-project/${params?.id}`,
            method: 'GET',
        });
        if (response && response?.status) {
            const data = response?.data?.data?.[0]?.data;
            setSingleProject(response?.data?.data?.[0])
            setassignedEmp(data);

            const series = data?.map((item) => item?.totalworkHour);
            const labels = data?.map((item) => item?.userName);
            const colors = generateColors(data?.length);

            setChartOptions((prevOptions) => ({
                ...prevOptions,
                series: series,
                labels: labels,
                fill: {
                    ...prevOptions.fill,
                    colors: colors,
                },
                legend: {
                    ...prevOptions.legend,
                    markers: {
                        ...prevOptions.legend.markers,
                        fillColors: colors,
                    },
                    labels: {
                        ...prevOptions.legend.labels,
                        colors: colors,
                    },
                },
            }));
        }
    };
    // const options = {
    //     series: [44, 55, 13, 33, 50,],
    //     chart: {
    //         width: 410,
    //         type: 'donut',
    //         // offsetX:50,
    //         // offsetY:50,
    //     },
    //     title: {
    //         text: 'Project Breakdown by Employess', // Add your chart title here
    //         align: 'center', // Align the title
    //         style: {
    //             fontSize: '20px', // Set the font size
    //             fontWeight: 'bold', // Set the font weight
    //             color: '#000', // Set the font color
    //         },
    //     },
    //     dataLabels: {
    //         enabled: false,
    //     },
    //     labels: ['Nilardri Roy', 'Samil Islam', 'Divya Thakur', 'Sayani Chakraborty', 'Suman Das'],
    //     fill: {
    //         opacity: 1,
    //         colors: ['#cae5fc', '#72b7ee', '#3ca8f9', '#1463a5', '#12436e']
    //     },
    //     responsive: [
    //         {
    //             breakpoint: 480,
    //             options: {
    //                 chart: {
    //                     width: 200,
    //                 },
    //                 legend: {
    //                     show: true,
    //                 },
    //             },
    //         },
    //     ],
    //     legend: {
    //         position: 'right',
    //         horizontalAlign: 'center', 
    //         itemMargin: {
    //             horizontal: 10, // Horizontal gap between legend items
    //             vertical: 5 // Vertical gap between legend items
    //         },
    //         offsetY: 20,
    //         offsetX: 0,
    //         height: 250,
    //         markers: {
    //             width: 25,
    //             height: 7,
    //             radius: 5,
    //             fillColors: ['#cae5fc', '#72b7ee', '#3ca8f9', '#1463a5', '#12436e'],
    //         },
    //         labels: {
    //             colors: ['#cae5fc', '#72b7ee', '#3ca8f9', '#1463a5', '#12436e']
    //         },
    //     }
    // };

    const mangeroptionchart = {
        series: [{
            name: 'Inflation',
            data: [30, 60, 100, 150, 40, 7]
        }],
        chart: {
            height: 180,

            type: 'bar',
        },
        fill: {
            opacity: 1,
            colors: ['#268ACC']
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },

            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -20, // Adjusted to make sure labels are placed correctly
            style: {
                fontSize: '12px',
                colors: ['#268ACC'] // Adjusted to be readable on the bar color
            }
        },
        xaxis: {
            categories: ["Day "], // Added categories for each data point
            position: 'bottom',
            offsetY: -4,
            offsetX: 50, // Adjusted position to 'bottom'
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true
            },
            labels: {
                style: {
                    fontSize: '12px',
                    colors: ['#268ACC'],
                    textAlign: 'center'// Adjusted to be readable
                }
            },
            tooltip: {
                enabled: true,
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                style: {
                    fontSize: '12px',
                    colors: ['#268ACC'] // Adjusted to be readable
                }
            }
        }
    };

    const projectclickall = () => {
        setProjectdetailmodal(!projectdetailmodal)
    }

    useEffect(() => {
        getProjectData();
    }, [params?.id])

    useEffect(() => {
        getManagerProjectData()
    }, [])
    return (
        <>
            <section className='projectviewdetailamainsection'>
                <div className='employeepagecontainer'>
                    <div className='attendencedivflx'>
                        <div className='projectviewdetailsdivright'>
                            <div className='projectviewdivprjjectchartdivflx'>
                                <div className='projectviewbtnmiaonvd'>
                                    <button className='managerviwebtnmain' onClick={projectclickall}>Project View

                                        {projectdetailmodal && <div className='managerviwebtnmaindtlsdivmainkkkp'>
                                            <ul className='prokekctviewtxtppul'>
                                                {
                                                    managerProjectData?.map((item, i) => {
                                                        return (
                                                            <li className='prokekctviewtxtpppli' key={i} onClick={() => handleprojectview(item?._id)}>
                                                                <p className='prokekctviewtxtpp'>{item?.nameOfInitaitive}</p>
                                                            </li>
                                                        )
                                                    })
                                                }

                                                {/* <li className='prokekctviewtxtpppli'>
                                                    <p className='prokekctviewtxtpp'>Elite Learn</p>
                                                </li>
                                                <li className='prokekctviewtxtpppli'>
                                                    <p className='prokekctviewtxtpp'>Esg</p>
                                                </li> */}
                                            </ul>
                                        </div>
                                        }
                                    </button>

                                </div>
                                <div className='projectviewbtnmiaonvd'>
                                    <button className='managerviwebtnmain' >My Timesheet</button>
                                </div>
                                <div className='projectchartdivmain'>
                                    <div className='projectchartdivmainbg'>
                                        <ApexCharts options={chartOptions} series={chartOptions?.series} type="donut" width={chartOptions?.chart?.width} />
                                    </div>
                                </div>
                            </div>

                            <div className='ProjectWorktabletadbmainsectdiv'>
                                <ProjectWorktable selectedDate={selectedDate} assignedEmp={assignedEmp} id={params?.id} />
                            </div>

                        </div>
                        <div className='projectviewdetailsdivleft'>
                            <div className='managercardbgdiprojectdivmaonnj'>
                                <div className='managercardbgdiprojectdiv'>
                                    <div className={managercard ? "managercardbgdiv actv" : "managercardbgdiv"}>
                                        <div className='mangercarddivflxmain'>
                                            <div className="mangercarddivrightdata">
                                                <div className='projectnamecprojecttitldiv'>
                                                    <p className='projectnamecardtxt'>Project</p>
                                                    <h4 className='projecttitletxtxcdrtxt'>{SingleProject?.data?.[0]?.projectName}</h4>
                                                </div>
                                                <div className='projectnamecprojecttitldiv'>
                                                    <p className='projectstarttxtppcrdt'>Start Date</p>
                                                    <h4 className='projectstartdatetxtcarddatetyr'>{moment(SingleProject?.data?.[0]?.startDate)?.format("YYYY-MM-DD")}</h4>
                                                </div>
                                                <div className='projectnamecprojecttitldiv'>
                                                    <p className='projectstarttxtppcrdt'>End Date</p>
                                                    <h4 className='projectstartdatetxtcarddatetyr'>{moment(SingleProject?.data?.[0]?.endDate)?.format("YYYY-MM-DD")}</h4>
                                                </div>
                                                <div className='projectnamecprojecttitldiv'>
                                                    <p className='projectstarttxtppcrdt'>Total Hours</p>
                                                    <h4 className='projectstartdatetxtcarddatetyr'>{SingleProject?.totalworkHourSum}</h4>
                                                </div>
                                                {/* <div className='breakupchviewbrancktcrsivflx'>
                                                    <div className='breakupcheckdib'>
                                                        <i class="fa-solid fa-chevron-right"></i>
                                                    </div>
                                                    <div className=''>
                                                        <p className='viewbrancktcrpp'>View Breakup</p>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className='mangercarddivleftdata'>
                                                <div className=''>
                                                    <Chart
                                                        options={mangeroptionchart}
                                                        series={mangeroptionchart.series}
                                                        type="bar"
                                                        height={mangeroptionchart.chart.height}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='Worklocationtabledivmaion'>
                                <Worklocationslide assignedEmp={assignedEmp} />
                            </div>
                            <div className='calenderdivrightmain'>
                                <CalenderAttendence selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProjectViewdetails
