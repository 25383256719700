import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-hot-toast';
import sharethght from "../../Images/sharethght.jpg";
import Attach from "../../Images/headericon/Attach.png";
import { BsEmojiSmileFill } from "react-icons/bs";
import EmojiPicker from 'emoji-picker-react';
import post from "../../Images/headericon/post_new.png";
import { useAuthCtx } from '../../context/AuthCtx';
import { useDispatch } from 'react-redux';
import { ApiHelperFunction, fileUpload } from '../../services/api/apiHelpers';
import { getAllPosts } from '../../redux/slices/postSlice';

const Replyeditmodal = ({ closemodal, activity, getComment, initialValues }) => {
    const [isEmoji, setIsEmoji] = useState(false);
    const [commentText, setCommentText] = useState("");
    const [selectedEmoji, setSelectedEmoji] = useState("")
    const { setLoading, loading, getUserDetails, userData } = useAuthCtx();
    const [imageURL, setImageURL] = useState(initialValues?.image);
    const dispatch = useDispatch();
    const [localerror, setlocalerror] = useState("");
    const [image, setImage] = useState("");
    const [uploading, setUploading] = useState(false);
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);

    // console.log("initialValuescvwe", initialValues)

    const schemaCheck = Yup.object(
        activity === "billBoard" ||
            activity === "events" ||
            activity === "kababMenu" ||
            activity === "editEvent"
            ? {
                eventName: Yup.string().required("Event Name is required"),
                hostedBy: Yup.string().required("Host name is required"),
                eventDate: Yup.string().required("Event date is required"),
                eventstarttime: Yup.string().required("start Time is required"),
                eventendtime: Yup.string().required(" End Time is required"),
                notes: Yup.string().required(" Notes is required"),
                addinvites: Yup.string().required(" Addinvites is required"),
                lattitude: Yup.string().required(" lattitude is required"),

                longitude: Yup.string().required(" longitude is required"),
            }
            : activity === "training" || activity === "editTraining"
                ? {
                    name: Yup.string().required("Event Name is required"),
                    trainingDate: Yup.string().required("Training date is required"),
                }
                : activity === "jobReffered"
                    ? {
                        name: Yup.string().required("Job Name is required"),
                        location: Yup.string().required("Job location is required"),
                        salary: Yup.string().required("Salary range is required"),
                        description: Yup.string().required("Description range is required"),
                        opening: Yup.string().required("Opening is required"),
                    }
                    : ""
    );

    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await fileUpload("/image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploading(false);
    };

    //for doing comment
    const handleEditComment = async (e) => {
        e.preventDefault();
        // if (values?.comment === "" && imageURL === "") {
        //     return;
        // }
        let urlPath = `/edit-reply/${initialValues?._id}`;
        let data = {
            // postID: postId,
            reply: `${values?.reply} ${selectedEmoji}`,
            image: imageURL
        };

        // console.log("imffggg", data);
        // return false;
        if (values?.reply || values?.image) {
            let res = await ApiHelperFunction({ urlPath, method: "PUT", data });
            if (res?.status) {
                toast.success("Reply is edited successfully");
                getComment();
                dispatch(getAllPosts());
                setCommentText("");
                setImageURL("");
                closemodal();
            } else {
                toast.error(res?.data?.message || "Error Fetching User Details");
            }
        } else {
            toast.error("No Reply is Added");
        }
    };

    useEffect(() => {
        dispatch(getAllPosts())
    }, [])

    const { values, handleChange, setFieldValue } =
        useFormik({
            initialValues,
            validationSchema: schemaCheck,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });
    return (
        <>
            <div className='commenteditmodalmainbg'>
                <div className='coomenteditbgmain'>
                    <div className='commentedittxtdeleteicontop'>
                        <div className=''>
                            <p className='surecommntedit'>Edit your Reply</p>
                        </div>
                        <div className='crsicondelte' onClick={() => closemodal()}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div className=''>
                        {/* <div className='profile_picimgmain'>
                            <img src={sharethght} alt="..." />
                        </div> */}
                        {/* <div className='wrapboxbg'>
                            <p>Nilardri Roy</p>
                           
                        </div> */}

                    </div>
                    <div className='commentprofiledeletesectionbtm'>
                        <div className='profile_picimgmain'>
                            <img src={sharethght} alt="..." />
                        </div>
                        <div className="mycomment_push">
                            <form>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby="textHelp"
                                        placeholder="Write a reply..."
                                        name='reply'
                                        value={`${values?.reply}`}
                                        onChange={handleChange}

                                    />

                                    <div className="inpCmntAttach">
                                        <img src={Attach} alt="..." />
                                        <input
                                            type="file"
                                            class="form-control"
                                            id="hostedBy"
                                            placeholder="Choose a photo"

                                            name="image"
                                            value={image}
                                            onChange={handleImageChange}

                                        />


                                        {imageURL !== "" && (

                                            <div style={{
                                                display: "flex", position: "relative",
                                                bottom: "0", left: '0', paddingTop: "10px"
                                            }}>
                                                <img
                                                    style={{ marginTop: "1rem" }}
                                                    src={imageURL}
                                                    alt=""
                                                    height="100rem"
                                                    width="100rem"
                                                />
                                                <div
                                                    onClick={() => {
                                                        setImageURL("");
                                                        setImage('')
                                                    }}
                                                >
                                                    <i
                                                        class="fa-solid fa-xmark"
                                                        style={{
                                                            color: "red",
                                                            fontSize: "2rem",
                                                            cursor: "pointer",
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>

                                        )}
                                    </div>

                                    {uploading ? <p style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '-84%'
                                    }}>image uploading......</p> : null}


                                    <BsEmojiSmileFill className="comment-emoji-icon"
                                        onClick={() => setIsEmoji(prev => !prev)}
                                    // onClick={() => setIsEmoji(true)}
                                    />
                                    {isEmoji &&
                                        // <EmojiPicker onEmojiClick={(emojiObject) => setCommentText(prev => prev + emojiObject.emoji)} />
                                        <EmojiPicker
                                            onEmojiClick={(emojiObject) => setFieldValue("reply", values?.reply + emojiObject?.emoji)}
                                        />
                                    }
                                    <button
                                        type='button'
                                        className="sendcommentbtn"
                                        onClick={handleEditComment}
                                    >
                                        <img src={post} alt="..." />
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}

export default Replyeditmodal