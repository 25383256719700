import React, { useState } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'


const CalenderAttendence = ({selectedDate,setSelectedDate}) => {
  // const ValuePiece = Date | null;
  // const Value = ValuePiece | [ValuePiece, ValuePiece];
  // const [value, onChange] = useState(false);
  return (
    <>
      <div className='calenderdivmainattendence'>
        <Calendar
          // onChange={onChange}
          //  value={value} 
          onChange={setSelectedDate} value={selectedDate}
        />
      </div>
    </>
  )
}

export default CalenderAttendence
