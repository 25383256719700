import { upload } from "@testing-library/user-event/dist/upload";
import React, { useEffect, useState } from "react";
import ThoughtsImg from "../Images/Icons/PNG/Profile (2).png";
import InputImg from "../Images/Icons/PNG/Attach.png";
import CreditsIcon from "../Images/Icons/PNG/Event Create.png";
import EliteCardicon from "../Images/Icons/PNG/Post card.png";
import { useDispatch, useSelector } from "react-redux";
import { useAuthCtx } from "../context/AuthCtx";
import { addPosts, getAllPosts } from "../redux/slices/postSlice";
import { ToastBar, toast } from "react-hot-toast";
import { ApiHelperFunction, fileUpload } from "../services/api/apiHelpers";
import ThoughtModalHome from "../../src/Component/Modal/ThoughtModalHome";
import Loader from "./loader/Loader";
import Hiring from "../../src/Images/headericon/Hiring.png";
import Ask_HR from "../Images/Icons/PNG/Ask_HR.png";
import connection8 from "../Images/connection8.png";
// Constants
import { BASE_URL } from "../constants/config";
import Askhrmodal from "../SeprateModal/Askhrmodal";
// import Thankyou from "../../src/Images/Icons/PNG/Thankyou.png";
// import CreateNewPost from "../../src/Images/Icons/PNG/CreateNewPost.png";
import { Link, useNavigate } from "react-router-dom";
import Allcard from "./Modal/Allcard";
import OneCard from "./Modal/OneCard";
import Secondcard from "./Modal/Secondcard";
import Thirdcard from "./Modal/Thirdcard";
import ForthCard from "./Modal/ForthCard";
import Create from "../../src/Images/headericon/Create New Post.png";
import events from "../../src/Images/headericon/events.png";
import ThankyouCard from "../../src/Images/headericon/ThankyouCard.png";
import rewardpoints from "../../src/Images/headericon/rewardpoints.png";
import advocacy from "../../src/Images/headericon/advocacy.png";
import ClaimPointModal from "./Modal/ClaimPointModal";
// import events from "../../src/Images/headericon/events.png";
export default function ShareThoughts({ getAffinityPosts }) {
  const { setLoading, userData, setModalF, modalF } = useAuthCtx();
  const postLoading = useSelector((state) => state.postGetSlice.isLoading);
  const postData = useSelector((state) => state.postGetSlice.addPost);
  const [uploading, setUploading] = useState(false);
  const [imageURL, setImageURL] = useState([]);
  const [image, setImage] = useState("");
  const [modalthght, setModalthght] = useState(false);
  const dispatch = useDispatch();
  const [sharePublicly, setSharePublicly] = useState(false);
  const [shareAffinityGroup, setshareAffinityGroup] = useState(false);
  const [askmodal, setAskmodal] = useState(false);
  const [handlecardfisrt, setHandlecardfirst] = useState(false);
  const [handleonecard, setHandleonecard] = useState(false);
  const [handlesecondcard, setHandlesecondcard] = useState(false);
  const [handlethirdcard, setHandlethirdcard] = useState(false);
  const [handlefourthcard, sethandleFourthcard] = useState(false);
  const [claimModal, setClaimModal] = useState(false);

  const navigate = useNavigate();
  // const initialValues = {
  //   cardName: "",
  //   cardDesc: "",
  //   receiverMail: ""
  // }

  const initialValuesPoints = {
    activityId: "",
    behaviourId: "",
    desc: "",
    collaboratorMail: "",
    receiverMail: [],
    claimPoints: ""
  }

  const [initialValues, setinitialValues] = useState({
    cardName: "",
    cardDesc: "",
    receiverMail: "",
    receiverImage: ""
  });

  const handleonecardClick = () => {
    setHandlecardfirst(false);
    setHandleonecard(true);
    setinitialValues({ ...initialValues, cardName: "ThankYou" });

    // setHandlecardfirst(false)
  }

  const handlesecondcardClick = () => {
    setHandlecardfirst(false);
    setHandlesecondcard(true);
    setinitialValues({ ...initialValues, cardName: "BirthDay" });
  }
  const handlethirdcardClick = () => {
    setHandlecardfirst(false);
    setHandlethirdcard(true);
    setinitialValues({ ...initialValues, cardName: "Onboarding" });
  }
  const handlfourthcardClick = () => {
    setHandlecardfirst(false);
    sethandleFourthcard(true);
    setinitialValues({ ...initialValues, cardName: "Anniversary" });
  }

  const handleClaimModal = () => {
    setClaimModal(!claimModal);
  }


  const [post, setPost] = useState({
    description: "",
    image: [],
  });

  const initialvaluesaskhr = {
    question: "",
  };

  const handlethoughtModal = () => {
    setModalthght(true);
  };
  const handleaskClick = () => {
    setAskmodal(!askmodal);
  };

  const closeEventModal = () => {
    // setModalF(true);
    navigate('/createevent');
    setModalthght(false);
  };

  const handleImageChange = async (e) => {
    let images = Array.from(e.target.files);
    let imageArray = [];
    setUploading(true);
    for (let i = 0; i < images.length; i++) {
      const form = new FormData();
      form.append("image", images[i]);

      let res = await fileUpload("/image-upload", "POST", form);

      if (res.status) {
        // toast.success("Images uploaded successfully");
        imageArray.push(res?.image);
      } else {
        toast.error("Error uploading image");
      }
    }
    setPost({ ...post, image: imageArray });
    setUploading(false);
  };

  const removeImage = (index) => {
    let images = [...post.image];
    images.splice(index, 1);
    setPost({ ...post, image: images });
  };

  const handleCheckboxChange = (e) => {
    setSharePublicly(e.target.checked);
  };
  const handlecardClick = () => {
    setHandlecardfirst(true);
    setHandleonecard(false);
  }
  // const handleAffinityCheckboxChange = (e) => {
  //   setshareAffinityGroup(e.target.checked);
  // }

  return (
    <>
      <section id="share_thoughts">
        <div className="pstnrmlheadertop">
          <div className="pstnrmlheadertopflx">
            {/* <div className="pstdivshrediv" style={{ border: "2px solid #c8db9f" }}>
              <div className="crtpostdivmaingtuy">
                <img src={Hiring} alt="..." />
              </div>
              <Link className="psttxtppmn" to="/jobsReferDetail">
                Hiring
              </Link>
            </div> */}

            <div className="pstdivshrediv" style={{ border: "2px solid #f9efc9" }} onClick={handlecardClick} >
              <div className="crtpostdivmaingtuy">
                <img src={ThankyouCard} alt="..." />
              </div>
              <p className="psttxtppmn" >
                Thankyou Card
              </p>
            </div>

            <div className="pstdivshrediv" style={{ border: "2px solid #a4bdff" }}
              // to="/advocacy"
              onClick={() => navigate("/advocacyaddform")}
            >
              <div className="crtpostdivmaingtuy">
                <img src={advocacy} alt="..." />
              </div>
              <div className="psttxtppmn" >
                Advocacy
              </div>
            </div>

            <div className="pstdivshrediv" style={{ border: "2px solid #ffa0b7" }} onClick={handleClaimModal}>
              <div className="crtpostdivmaingtuy">
                <img src={rewardpoints} alt="..." />
              </div>
              <div className="psttxtppmn">
                Claims Point
              </div>
            </div>


            {
              userData?.userType === "Admin" || userData?.userType === "Owner" ? (<div className="pstdivshrediv" style={{ border: "2px solid #c8db9f" }}
                onClick={() => navigate("/hrJobPost")}
              >
                <div className="crtpostdivmaingtuy">
                  <img src={Hiring} alt="..." />
                </div>
                <div className="psttxtppmn" >
                  Hiring
                </div>
              </div>) :
                userData?.userType === "Manager" ?
                  (<div className="pstdivshrediv" style={{ border: "2px solid #c8db9f" }}
                    onClick={() => navigate("/managerJobPost")}
                  >
                    <div className="crtpostdivmaingtuy">
                      <img src={Hiring} alt="..." />
                    </div>
                    <div className="psttxtppmn" >
                      Hiring
                    </div>
                  </div>) :
                  userData?.userType === "Employee" ? (
                    <div className="pstdivshrediv" style={{ border: "2px solid #c8db9f" }}
                      onClick={() => navigate("/netWorking")}
                    >
                      <div className="crtpostdivmaingtuy">
                        <img src={Hiring} alt="..." />
                      </div>
                      <div className="psttxtppmn" >
                        Onboarding
                      </div>
                    </div>
                  ) :
                    (<></>)
            }

            {
              userData?.userType === "Admin" || userData?.userType === "Manager" || userData?.userType === "Owner"
              ? (<div className="pstdivshrediv" style={{ border: "2px solid #ffa1a1" }}
                // to="/eventsDeails"
                onClick={closeEventModal}
              >
                <div className="crtpostdivmaingtuy">
                  <img src={events} alt="..." />
                </div>
                <div className="psttxtppmn"

                // onClick={closeEventModal}
                >
                  Event
                </div>
              </div>) : (<div className="pstdivshrediv" style={{ border: "2px solid #ffa1a1" }}
                // to="/eventsDeails"
                onClick={() => navigate("/trainingCreateEmployee")}
              >
                <div className="crtpostdivmaingtuy">
                  <img src={events} alt="..." />
                </div>
                <div className="psttxtppmn"

                // onClick={closeEventModal}
                >
                  Learning
                </div>
              </div>)
            }


          </div>
        </div>
        <div className="top">
          <div className="profilebrdr">
            <div
              className="profile_img"
              style={{ borderRadius: "50%", overflow: "hidden" }}
            >
              {/* <img src={connection8} className="img-fluid" alt="profile" /> */}
              {userData?.image ? (
                <img
                  src={userData?.image}
                  className="img-fluid"
                  alt="profile"
                />
              ) : (
                <img
                  src={
                    BASE_URL + "/" + "uploads/imageUploads/530060.com-4811117"
                  }
                  className="img-fluid"
                  alt="profile"
                />
              )}
            </div>
          </div>

          {/* <input
                type="text"
                className="form-control"
                id="description"
                name="description"
                aria-describedby="textHelp"
                placeholder="Share your thoughts..."
                value={post?.description}
                onChange={(e) =>
                  setPost({ ...post, description: e.target.value })
                }
              /> */}
          <div className="thght_txt" onClick={handlethoughtModal}>
            <p>Share Moments That Matters...</p>
          </div>

          {/* {uploading ? (
            <Loader />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {post?.image?.length > 0 &&
                post?.image?.map((item, index) => {
                  return (
                    <div key={index} className="thoughtsimg_up">
                      <img src={item} className="img-fluid" alt="" />
                      <div
                        className="close"
                        onClick={() => {
                          removeImage(index);
                        }}
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </div>
                    </div>
                  );
                })}
            </div>
          )} */}
        </div>

        {/* <div className="share_public_checkbox"> */}

        {/* <input
          type="checkbox"
          id="sharePublicly"
          checked={sharePublicly}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="sharePublicly">
          Share publicly
        </label> */}

        {/* <input
          type="checkbox"
          id="sharePublicly"
          checked={shareAffinityGroup}
          onChange={handleAffinityCheckboxChange}
        />
        <label htmlFor="sharePublicly">
          Share Affinity Group
        </label> */}

        {/* </div> */}
        <div className="">
          {/* <div className="attach_btn" style={{ marginRight: "20px" }} onClick={handlethoughtModal}>
            <div className="file_upload" >
              <div className="input_img" style={{ cursor: "pointer" }}>
                <img src={InputImg} className="img-fluid" alt="icon" />
              </div> */}{" "}
          {/* today */}
          {/* <input
                id=""
                type="file"
                value={image}
                // onChange={handleImageChange}
                accept="image/*"
                multiple
                style={{ cursor: "pointer" }}
              /> */}
          {/* </div>
            <div className="attach">
              <p style={{ cursor: "pointer" }}>Attach</p>
            </div>
          </div> */}
          {/* event  */}
          <div className="crdrtaskthank">
            {/* <div className="request_credits" style={{ marginRight: "20px" }}>
              {userData?.userType === "Admin" ||
              userData?.userType === "Manager" ? (
                <>
                  {" "}
                  <button className="btn">
                    <img
                      src={CreditsIcon}
                      className="img-fluid"
                      alt="icon"
                      onClick={closeEventModal}
                    />
                  </button>
                  <p
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={closeEventModal}
                  >
                    Create Event
                  </p>
                </>
              ) : (
                <>
                  <div
                    className=""
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={handleaskClick}
                  >
                    <button className="btn">
                      <img src={Ask_HR} className="img-fluid" alt="icon" />
                    </button>
                    <p style={{ cursor: "pointer", whiteSpace: "nowrap" }}>
                      Ask Hr
                    </p>
                  </div>
                </>
              )}
              <button className="btn">
                {" "}
                <img src={CreditsIcon} className="img-fluid" alt="icon" />{" "}
              </button>{" "}
              <p style={{ cursor: "pointer" }}>Event</p>

            </div> */}

            {/* <div
              className="request_credits"
              style={{ display: "flex", alignItems: "center" }}
            >
              <button className="btn">
                <img src={Thankyou} lassName="img-fluid" alt="icon" />
              </button>
              <p style={{ cursor: "pointer", whiteSpace: "nowrap" }}>
                Thank You
              </p>
            </div> */}
          </div>
          {/* 
          <div className="">
            <div
              className="request_credits"
              style={{ display: "flex", alignItems: "center" }}
            >
              <button className="btn" onClick={handlethoughtModal}>
                <img src={CreateNewPost} lassName="img-fluid" alt="icon" />
              </button>
              <p
                style={{
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  color: "black",
                }}
                onClick={handlethoughtModal}
              >
                Create New Post
              </p>
            </div>
          </div> */}
          {/* post card  */}
          {/* <div style={{ cursor: "pointer" }} className="Post_Card">
            <button className="btn">
              <img src={EliteCardicon} className="img-fluid" alt="icon" />
            </button>
            <p>
              <span >élite</span>Post Card
            </p>
          </div> */}
          {/* <div className="post_btn">
            <button className="btn" onClick={handleClick}>
              Post
            </button>
          </div> */}
        </div>
      </section>
      {modalthght && (
        <ThoughtModalHome
          getAffinityPosts={getAffinityPosts}
          closemodal={setModalthght}
          text={"Share Moments That Matters..."}
          attach={"Attach"}
          event={"Event"}
        // elite={"élite Post Card"}
        />
      )}
      {askmodal && (
        <Askhrmodal
          Closemodal={setAskmodal}
          initialValues={initialvaluesaskhr}
          activity={"askHr"}
        />
      )}

      {
        claimModal && (<ClaimPointModal closemodal={setClaimModal} activity={"claimpoint"} initialValues={initialValuesPoints} />)
      }

      {
        handlecardfisrt && <Allcard
          closemodala={setHandlecardfirst}
          handleonecardClick={handleonecardClick}
          handleonecard={handleonecard}
          handlesecondcardClick={handlesecondcardClick}
          handlethirdcardClick={handlethirdcardClick}
          handlfourthcardClick={handlfourthcardClick}
        />
      }

      {handleonecard && <OneCard closemodal={setHandleonecard} initialValues={initialValues} />}
      {handlesecondcard && <Secondcard closemodal={setHandlesecondcard} initialValues={initialValues} />}

      {handlethirdcard && <Thirdcard closemodal={setHandlethirdcard} initialValues={initialValues} />}

      {handlefourthcard && <ForthCard closemodal={sethandleFourthcard} initialValues={initialValues} />}
    </>
  );
}
