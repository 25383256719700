import React, { useEffect, useState } from 'react'
// import Chart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import moment from 'moment';


const MyProjectView = () => {
    const [managercard, setManagercard] = useState(false);
    const [myProjectData, setMyProjectData] = useState([]);
    const navigate = useNavigate();

    const getMyProjectData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/fetch-my-project`,
            method: "GET",
        });
        if (response && response.status) {
            setMyProjectData(response?.data?.data);
        }
    }

    useEffect(() => {
        getMyProjectData();
    }, [])

    return (
        <>

            {
                myProjectData?.map((item, i) => {
                    return (
                        <div className='managercardbgdivmaiun' key={i}
                        >
                            <div className={managercard ? "managercardbgdiv actv" : "managercardbgdiv"}>
                                <div className='mangercarddivflxmain'>
                                    <div className="mangercarddivrightdata">
                                        <div className='projectnamecprojecttitldiv'>
                                            <p className='projectnamecardtxt'>Project</p>
                                            <h4 className='projecttitletxtxcdrtxt'>{item?.nameOfInitaitive}</h4>
                                        </div>
                                        <div className='projectnamecprojecttitldiv'>
                                            <p className='projectstarttxtppcrdt'>Start Date</p>
                                            <h4 className='projectstartdatetxtcarddatetyr'>{moment(item?.startDate)?.format("YYYY-MM-DD")}</h4>
                                        </div>
                                        <div className='projectnamecprojecttitldiv'>
                                            <p className='projectstarttxtppcrdt'>Last Update</p>
                                            <h4 className='projectstartdatetxtcarddatetyr'>
                                              {
                                                item?.lastUpdatedDate !== null
                                                ?
                                                <>
                                                 {moment(item?.lastUpdatedDate)?.format("YYYY-MM-DD")}
                                                </>
                                                :
                                                <>Not yet Started</>
                                              } 
                                                </h4>
                                        </div>
                                        <div className='projectnamecprojecttitldiv'>
                                            <p className='projectstarttxtppcrdt'>Total Hours</p>
                                            <h4 className='projectstartdatetxtcarddatetyr'>{item?.totalHour}h</h4>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                })
            }

            {/* <div className='managercardbgdivmaiun'>
                        <div className={managercard ? "managercardbgdiv actv" : "managercardbgdiv"}>
                            <div className='mangercarddivflxmain'>
                                <div className="mangercarddivrightdata">
                                    <div className='projectnamecprojecttitldiv'>
                                        <p className='projectnamecardtxt'>Project</p>
                                        <h4 className='projecttitletxtxcdrtxt'>Innoverse</h4>
                                    </div>
                                    <div className='projectnamecprojecttitldiv'>
                                        <p className='projectstarttxtppcrdt'>Start Date</p>
                                        <h4 className='projectstartdatetxtcarddatetyr'>10th January,2024</h4>
                                    </div>
                                    <div className='projectnamecprojecttitldiv'>
                                        <p className='projectstarttxtppcrdt'>Last Update</p>
                                        <h4 className='projectstartdatetxtcarddatetyr'>16th June,2-24</h4>
                                    </div>
                                    <div className='projectnamecprojecttitldiv'>
                                        <p className='projectstarttxtppcrdt'>Total Hours</p>
                                        <h4 className='projectstartdatetxtcarddatetyr'>14h</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='managercardbgdivmaiun'>
                        <div className={managercard ? "managercardbgdiv actv" : "managercardbgdiv"}>
                            <div className='mangercarddivflxmain'>
                                <div className="mangercarddivrightdata">
                                    <div className='projectnamecprojecttitldiv'>
                                        <p className='projectnamecardtxt'>Project</p>
                                        <h4 className='projecttitletxtxcdrtxt'>Bookpeckers</h4>
                                    </div>
                                    <div className='projectnamecprojecttitldiv'>
                                        <p className='projectstarttxtppcrdt'>Start Date</p>
                                        <h4 className='projectstartdatetxtcarddatetyr'>15th January,2024</h4>
                                    </div>
                                    <div className='projectnamecprojecttitldiv'>
                                        <p className='projectstarttxtppcrdt'>Last Update</p>
                                        <h4 className='projectstartdatetxtcarddatetyr'>9th June,2-24</h4>
                                    </div>
                                    <div className='projectnamecprojecttitldiv'>
                                        <p className='projectstarttxtppcrdt'>Total Hours</p>
                                        <h4 className='projectstartdatetxtcarddatetyr'>18h</h4>
                                    </div>
                                   
                                </div>
                                
                            </div>
                        </div>
                    </div> */}

        </>
    )
}

export default MyProjectView
