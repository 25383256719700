import React, { useEffect, useState } from 'react'
// import advoWhite from "../../Images/advoWhite.png";
// import halloof1 from "../../Images/halloof1.png"
import { RWebShare } from 'react-web-share';
import share from "../../Images/Icons/PNG/Share.png"
import { useLocation, useParams } from 'react-router-dom';
import download from "../../Images/Icons/PNG/download.png"
import moment from 'moment';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';
import { useAuthCtx } from '../../context/AuthCtx';

const Secondadvocacy = () => {
    const domainName = window.location.hostname;
    const splitedDName = domainName?.split('.')[0];
    const { userData } = useAuthCtx();
    const params = useParams();
    const [singleView, setSingleView] = useState({});

    //for view Single Page
    const viewSinglepost = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-advocacy-single-page/${params?._id}/${userData?._id}`,
            method: "GET",
        });
        if (response.status === 200) {
            setSingleView(response?.data?.data);
        }
    }

    //for view Single Advocacy share
    const viewShareAdvocacy = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/update-advocacy-sharing-count/${params?._id}`,
            method: "PUT",
        });
        if (response.status) {
            console.log("respovbsdiop", response)
        }
    }

    useEffect(() => {
        viewSinglepost();
    }, [])
    return (
        <>
            <section className='singleadvocacypagemain'>
                <div className='employeepagecontainer'>

                    <div className="singleadvocacyimage">
                        <img src={singleView?.image} alt="..." />

                    </div>
                    <div className='singleadvocacydivbg'>
                        <div className='row'>
                            <div className=''>
                                <h4 className='advocacytextdivhhdr'>{singleView?.AdvocacyName}</h4>
                            </div>

                            <div className='rwebsharedivimgsk'>
                                <div>
                                    {/* <RWebShare disableNative={true}
                                        data={{
                                            text: `${item?.AdvocacyName}`,
                                            url: `https://fractalselite.com/singleadvocacy/${item?.AdvocacyName?.replace(/\s+/g, '-')}/${item?._id}`,
                                            title: `${item?.AdvocacyName}`,
                                        }}
                                        onClick={() => console.log("shared successfully!")}
                                    >
                                        <button className='shaeredibimgh'>
                                            <img src={Share} alt="..." />
                                        </button>
                                    </RWebShare> */}
                                    <RWebShare
                                        disableNative={true}
                                        data={{
                                            text: `${singleView?.desc}`,
                                            url: `http://${splitedDName}.fractalselite.com/singleadvocacy/${singleView?.AdvocacyName?.replace(/\s+/g, '-')}/${singleView?._id}`,
                                            title: "Elite",
                                        }}
                                        sites={[
                                            "linkedin",
                                            "facebook",
                                            "twitter",
                                            "whatsapp",
                                            "mail",
                                            "copy",
                                        ]}
                                        // onClick={() => console.log("shared successfully!")}
                                        onClick={(platform) => {
                                            viewShareAdvocacy();
                                            if (platform === "copy") {
                                                const textToCopy = `http://${splitedDName}.fractalselite.com/singleadvocacy/${singleView?.AdvocacyName?.replace(/\s+/g, '-')}/${singleView?._id}`;
                                                navigator.clipboard.writeText(textToCopy).then(() => {
                                                    toast.success("Link copied to clipboard!");
                                                });
                                            } else {
                                                // Handle other platforms
                                                // console.log("Shared successfully on", platform);
                                            }
                                        }}

                                    >
                                        <button className="shaeredibimgh">

                                            <img src={share} alt="..." />
                                            {/* <span style={{ paddingLeft: "5px" }}>Share</span> */}
                                        </button>

                                    </RWebShare>
                                </div>
                            </div>
                            {/* <div>

                                <div className='downladimgmainpl'>
                                    {
                                        item?.material !== "" ? (<div className='dateshowlinkdiv'><img src={download} alt="..." /></div>) : (<div><img src={download} alt="..." /></div>)
                                    }

                                    <div className='downloaddivmaindiv'>

                                        {
                                            item?.material !== "" && (
                                                <>
                                                    <div className='downloaddivmaindivsize' >

                                                        <a href={item?.material?.startsWith("http") ? item?.material : `http://${item?.material}`} target="_blank" rel="noopener noreferrer">
                                                            {item?.material?.slice(0, 20)}
                                                        </a>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="prflimgtxtflx">
                            <div className="imgnmnwhdivimg">
                                <div className="prflsadvctimgmain">
                                    <img src={singleView?.userImage} alt="..." />
                                </div>
                            </div>
                            <div className="prfdtlsadvc">
                                <p className="prfladtxt">{singleView?.firstName + " " + singleView?.lastName}</p>
                                <p className="prfldttxt">{moment(singleView?.createdOn).format("MMM-DD-YY")}</p>
                            </div>



                        </div>

                        <div className=''>
                            {
                                singleView?.language ? (<p className='loremsingleadvocaytext'>Language : {singleView?.language}</p>) : (<></>)
                            }

                            <p className='loremsingleadvocaytext'>{singleView?.desc}</p>

                        </div>

                        <div className=''>
                            <div className='row' style={{ paddingTop: "40px", paddingLeft: "32px" }}>
                                <div>
                                    <p >Download File : </p>
                                </div>
                                <div className='downladimgmainpl'>

                                    {
                                        singleView?.material !== "" ? (<div className='dateshowlinkdiv'><img src={download} alt="..." /></div>) : (<div><img src={download} alt="..." /></div>)
                                    }

                                    <div className='downloaddivmaindiv'>

                                        {
                                            singleView?.material !== "" && (
                                                <>
                                                    <div className='downloaddivmaindivsize' >

                                                        <a href={singleView?.material?.startsWith("http") ? singleView?.material : `http://${singleView?.material}`} target="_blank" rel="noopener noreferrer">
                                                            {singleView?.material?.slice(0, 20)}
                                                        </a>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>


                </div>
            </section>
        </>
    )
}

export default Secondadvocacy
