import React, { useEffect, useState } from 'react';
import HttpClient from '../../utils/HttpClient';
import { useNavigate } from 'react-router-dom';
import AIRegenerateIcon from "../../Images/headericon/AI Regenerate Icon.png";
import "./Cahtbot.css";
import Chatbotmain from './Chatbotmain';

// import { toast } from 'react-toastify';
function Chatbot() {
  const [chatbotclick, setChatbotclick] = useState(false);
  const [FirstTopic, setFirstTopic] = useState([]);
  const [mainTopic, setMainTopic] = useState([]);
  const [TopicsTitle, setTopicsTitle] = useState('');
  // const [Subtopic, setSubTopic] = useState([]);
  const [Answer, setAnswer] = useState('');
  const [chatType, setChatType] = useState("")

  const [viewedTopics, setViewedTopiocs] = useState([]);
  const [dropTopics, setDropTopics] = useState([{ value: '', label: '' }]);
  const [endMessage, setEndMessage] = useState("");
  const [isExistData, setIsExistData] = useState(false);
  const [histryData, setHistryData] = useState([])


  // console.log(histryData, "histryData")
  // console.log(Subtopic, "Subtopic")


  const handlechatbotClick = () => {
    setChatbotclick(!chatbotclick)
  }
  const handlestopbotClick = (e) => {
    e.stopPropagation();
  }

  // change dropdown topics
  const changeDropDownTopics = (data) => {

    console.log("datasddd", data)
    const mapData = data?.map(ele => ({
      value: ele,
      label: ele?.topicName,
    }))
    setDropTopics(mapData)
  }

  // main topic get
  const getFirstTopics = async () => {
    const res = await HttpClient.requestData('view-chat-bot', {}, "POST");
    // console.log("chatbot", res?.data)
    if (res && res?.status) {
      setFirstTopic(res?.data)
      changeDropDownTopics(res?.data)
      setMainTopic(res?.data)
    }
  }

  // sub topic get
  const GetSubtopicData = async (id) => {
    const newViewTopics = viewedTopics

    const data = {
      "topicID": id
    }
    const res = await HttpClient.requestData('view-chat-bot', data, "POST");
    console.log("chatbot", res)
    if (res && res?.status) {
      if (res?.data1) {
        setChatType(res?.data1)
      } else {
        setChatType("")
      }

      if (res?.data1 === "Topic") {
        setFirstTopic(res?.data);
        setTopicsTitle(res?.data?.title)
        changeDropDownTopics(res?.data)
        setIsExistData(false)


        const fltData = FirstTopic?.find(ele => ele?._id === id)
        if (fltData) {
          setViewedTopiocs(prev => [...prev, fltData?.topicName]);
        }
        console.log("fltidffttt", fltData)

      }
      else if (res?.data1 === "Answer") {
        // setAnswer(res?.data)
        setFirstTopic([]);

        changeDropDownTopics(mainTopic)
        setIsExistData(false)

        const fltData = FirstTopic?.find(ele => ele?._id === id)
        setHistryData(prev => [...prev, { topic: [...newViewTopics, fltData?.topicName], Answer: res?.data }])
        setViewedTopiocs([])

        // if (fltData) {
        //   setViewedTopiocs(prev => [...prev, fltData?.topicName]);
        // }
        // console.log("fltidff", fltData)

      } else {
        // if data1===null
        // setEndMessage("No data Found").
        setIsExistData(true)
      }
    }
  }

  // onchange of select dropdown
  const handleSelectChange = (e) => {
    // console.log("elkfff", e)
    GetSubtopicData(e?.value?._id)
  }

  useEffect(() => {
    getFirstTopics()
  }, []);

  // const [ scrollBtn, setScrollBtn]=useState(false)

  // useEffect(() => {
  //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  // }, []);

  // function scrollBy () {
  //     if(window.scrollY > 500){
  //         setScrollBtn (true);
  //     }
  //     else {
  //         setScrollBtn (false);
  //     }
  //   }
  // window.addEventListener("scroll" ,scrollBy);


  // chatbox search
  const navigate = useNavigate()
  const [searchName, setSearchName] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [searchMixData, setSearchMixData] = useState([]);
  const [chatSearchLoad, setCharchLoad] = useState(false);
  const[aigeneratemodala,setAigeneratemodala]=useState(false)

  console.log("searchMixData", searchMixData)

  const handleChatBoxearch = async () => {
    const data = {
      "searchName": searchName
    }
    setCharchLoad(true)
    const res = await HttpClient.requestData("chatboat-search", data, "POST");
    setCharchLoad(false)
    // console.log("resdfff", res)
    if (res && res?.status) {
      setSearchedData(res?.data)
      setSearchMixData([
        ...res?.data?.advCatSearch,
        ...res?.data?.advocacySearch,
        ...res?.data?.documentCatSearch,
        ...res?.data?.documentSearch
      ])
    } else {

    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchName) {
        setSearchMixData([])
        setSearchedData([])
        handleChatBoxearch()
      }
      else {
        setSearchMixData([])
        setSearchedData([])
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [searchName])

  const chatbotaiclick=()=>{
    setAigeneratemodala(!aigeneratemodala)
  }

  return (
    <>
      <div className='scrolltop_btn'>

        <div className='chatbtimg' onClick={chatbotaiclick} >
          <img src={AIRegenerateIcon} alt="..." />
          </div>


          {/* {
            chatbotclick
            &&
            <div className='chatboxdesign' 
           
            >

              <div className='chatbottop'>

                <div className='chatbotimg'>
                  <img src={charbot} alt="..." />
                  <div className='chatdot'></div>
                </div>


                <div className='chatonlnflx'>
                  <p className='ChatBottxt'>Ask Hr</p>
                  <p className='chatonln'>Online</p>
                </div>
                <div className='clsusediv' 
                onClick={handlechatbotClick}
                >
                  <i className="fa-solid fa-xmark"></i>
                </div>
              </div>

              <div className='chatbotttom'>
                {
                  searchName &&
                  <div className='chatbotSearchDrop'>
                    {
                      chatSearchLoad &&
                      <p>Chatboat AI is Searching...</p>
                    }

                    {
                      searchMixData?.map((item, i) =>
                        <div key={i}>

                          {item?.categoryName &&
                            <p onClick={() => navigate(`/advocacy`, { state: { item: item } })}>
                              <span><img src={advocasyImg} style={{ height: "20px", width: "20px" }} /></span>{" "}
                              {item?.categoryName}
                            </p>
                          }

                          {item?.AdvocacyName &&
                            <p onClick={() => navigate(`/singleadvocacy/${item?.AdvocacyName?.replace(/\s+/g, '-')}/${item?._id}`, { state: { item: item } })}>
                              <span><img src={item?.image} style={{ height: "20px", width: "20px" }} /></span>{" "}
                              {item?.AdvocacyName}
                            </p>
                          }

                          {item?.catName &&
                            <p onClick={() => navigate(`/articlepage`, { state: item?._id })}>
                              <span><img src={docRep} style={{ height: "20px", width: "20px" }} /></span>{" "}
                              {item?.catName}
                            </p>
                          }

                         
                          {item?.title &&
                            <p onClick={() => navigate(`/fullarticleview`, { state: item })}>
                              <span><img src={item?.docImage} style={{ height: "20px", width: "20px" }} /></span>{" "}
                              {item?.title}
                            </p>
                          }


                        </div>
                      )
                    }
                  </div>
                }

                <div className='inpittdivcchtbot'>

                  <input
                   
                    type='text'
                    onChange={e => setSearchName(e.target.value)}
                  />

                  <div className='postdivomnchatbot'>
                    <img src={post} alt='...' />
                  </div>

                </div>
              </div>

            </div>
           } */}
        

      </div>

      {aigeneratemodala && <Chatbotmain closemodal={()=>setAigeneratemodala(false)}/>}


    </>

  )
}

export default Chatbot


