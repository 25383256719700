import React, { useEffect, useState } from 'react'
import Article from "../../Images/Icons/PNG/Article.png";
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { useNavigate, useParams } from 'react-router-dom';
import { getJobReq, getJobpost } from '../../redux/slices/jobRecruitmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthCtx } from '../../context/AuthCtx';

const Hiringdetails = () => {
    const { userData } = useAuthCtx();
    const jobreq = useSelector((state) => state?.jobRecruitmentSlice?.jobreq);
    const jobpost = useSelector((state) => state?.jobRecruitmentSlice?.jobpost);
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [singleView, setSingleView] = useState({});
    const [singleHrView, setSingleHrView] = useState({});
    const [departmentData, setDepartmentData] = useState([]);
    const [whyData, setWhyData] = useState([]);

    //for view Single Page
    const viewSinglepost = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/get-job-requirment-byid/${params?.id}`,
            method: "POST",
        });
        if (response.status === 200) {
            setSingleView(response?.data?.data);
        } else {
        }
    }

    //for view Hr Single Page
    const viewSingleHrpost = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-job-addedby/${params?.id}`,
            method: "GET",
        });
        if (response.status === 200) {
            setSingleHrView(response?.data?.data);
        } else {
        }
    }

    //for fetching Department data
    const getDepartmentData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-department`,
            method: "GET",
        });
        // console.log("RESPONSEF", response?.data?.data);
        if (response && response.status) {
            setDepartmentData(response?.data?.data);
        } else {
            // toast.error(response.error);
        }
    };

    //for fetching data of why join us
    const getWhyJoinData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-about-company`,
            method: "GET",
        });
        if (response && response.status) {
            setWhyData(response?.data?.data);
        } else {
        }
    };

    const getDepartmentName = (departmentId) => {
        const department = departmentData.find(department => department._id === departmentId);
        return department ? department.departmentName : '';
    };


    useEffect(() => {
        const findData = jobreq?.find(ele => ele?._id === params?.id)
        setSingleView(findData)
    }, [jobreq, params])

    useEffect(() => {
        const findData = jobpost?.find(ele => ele?._id === params?.id)
        setSingleHrView(findData)
    }, [jobpost, params])


    const handleJobReqClick = (id) => {
        navigate(`/hiringdetials/${id}`)
    }

    useEffect(() => {
        viewSinglepost();
        getDepartmentData();
        getWhyJoinData();
        viewSingleHrpost();
        dispatch(getJobReq());
        dispatch(getJobpost());
    }, [])


    return (
        <>
            <section className='hiringdetialsmainsection'>

                {/* For Manager */}
                {
                    userData?.userType === "Manager" && (
                        <div className='employeepagecontainer'>

                            <div className='hiringdetialsdivflx'>

                                <div className='hririnhjjhdibnmon'>
                                    <div className="">
                                        <div className="rewarddiv">
                                            <div className="rewarddivflx">
                                                <p className="hobbyAspHead">Requested Hiring</p>

                                            </div>
                                            <div className="bottomareadibvmainhiring">
                                                {
                                                    jobreq?.map((item) => {
                                                        return (
                                                            <div className="bottomareadibvmainbg" style={{ cursor: "pointer" }} onClick={() => handleJobReqClick(item?._id)}>
                                                                <p className="bhsbvbbshhhtghpppn">
                                                                    Job Title :
                                                                    <span>
                                                                        {item?.jobTitle}
                                                                    </span>
                                                                </p>
                                                                <p className="bhsbvbbshhhtghpppn">
                                                                    Location :
                                                                    <span>
                                                                        {item.jobLocation.slice(0, -1).join(", ")
                                                                            + (item.jobLocation.length > 1 ? "," : "")
                                                                            + (item.jobLocation.length > 1 ? " and " : "")
                                                                            + item.jobLocation.slice(-1)}
                                                                    </span>
                                                                </p>
                                                                <p className="bhsbvbbshhhtghpppn">
                                                                    Skill :
                                                                    <span>
                                                                        {item.keySkills.slice(0, -1).join(", ")
                                                                            + (item.keySkills.length > 1 ? "," : "")
                                                                            + (item.keySkills.length > 1 ? " and " : "")
                                                                            + item.keySkills.slice(-1)}
                                                                    </span>
                                                                </p>
                                                                <p className="bhsbvbbshhhtghpppn">
                                                                    No. of Opening :
                                                                    <span>
                                                                        {item?.openings}
                                                                    </span>
                                                                </p>

                                                            </div>
                                                        )
                                                    })
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className=''>
                                    <div className='bg-brdrdiv'>

                                        <div className='bg-grydivmainhj'>
                                            <p className='hjkshhvvsuhikdojhjokklo'> Status :
                                                <span>
                                                    {singleView?.isApproved}
                                                </span>

                                            </p>
                                        </div>
                                        <div className="bg-grydivmainhj">
                                            <h2 className="psttxtppdiv">
                                                {singleView?.jobTitle}</h2>
                                        </div>


                                        <div className="dshimgtxt">
                                            <div className="imhtnch"><img src={Article} alt="..." /></div>
                                            {
                                                singleView?.departmentName ? (<div className='bg-grydiv'>
                                                    <p className='dstgytxy'>{singleView?.departmentName}</p>
                                                </div>) : (<div className='bg-grydiv'>
                                                    <p className='dstgytxy'>{getDepartmentName(singleView?.Department)}</p>
                                                </div>)
                                            }

                                        </div>
                                        <div className="dshimgtxt">
                                            <div className="imhtnch"><img src={Article} alt="..." /></div>
                                            <div className="bg-grydiv">
                                                <p className="dstgytxy">

                                                    {singleView?.period}</p>
                                            </div>
                                        </div>
                                        <div className="dshimgtxt">
                                            <div className="imhtnch"><img src={Article} alt="..." /></div>
                                            <div className="bg-grydiv">
                                                <p className="dstgytxy">
                                                    {singleView?.keySkills?.slice(0, -1).join(", ")
                                                        + (singleView?.keySkills?.length > 1 ? "," : "")
                                                        + (singleView?.keySkills?.length > 1 ? " and " : "")
                                                        + singleView?.keySkills?.slice(-1)}</p>
                                            </div>
                                        </div>
                                        <div className="dshimgtxt">
                                            <div className="imhtnch"><img src={Article} alt="..." /></div>
                                            <div className="bg-grydiv">
                                                <p className="dstgytxy">
                                                    {singleView?.jobLocation?.slice(0, -1).join(", ")
                                                        + (singleView?.jobLocation?.length > 1 ? "," : "")
                                                        + (singleView?.jobLocation?.length > 1 ? " and " : "")
                                                        + singleView?.jobLocation?.slice(-1)}</p>
                                            </div>
                                        </div>
                                        <div className="inpuytboxdyflx">
                                            <p className="optxtvn">Open to Referral Hiring</p>
                                            <input
                                                type="checkbox"
                                                checked={singleView?.isReffered}
                                            />
                                        </div>

                                    </div>

                                    {
                                        whyData?.map((item, ind) => {
                                            return (
                                                <>
                                                    <div className='jndivtxtwhy' key={ind} >
                                                        <p className="jntxtdivhrin">Why Join US?</p>
                                                        <div className='bg-grydiv'>
                                                            <p className='frcttxy'>
                                                                {item?.whyJoinUs}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }


                                    <div className="jndivtxtwhy">
                                        <p className="jntxtdivhrin">Position Description</p>
                                        <div className="bg-grydiv">
                                            <p className="frcttxy">
                                                {singleView?.positionDescription}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="jndivtxtwhy">
                                        <p className="jntxtdivhrin">Responsibilities:</p>
                                        <div className="bg-grydiv">
                                            <ul className="txtillsltall">
                                                <li className="txtlixtli">
                                                    {singleView?.responsibilities}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="jndivtxtwhy">
                                        <p className="jntxtdivhrin">Qualifications:</p>
                                        <div className="bg-grydiv">
                                            <ul className="txtillsltall">
                                                <li className="txtlixtli">{singleView?.qualification}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="jndivtxtwhy">
                                        <p className="jntxtdivhrin">
                                            Good To Have</p>
                                        <div className="bg-grydiv">
                                            <ul className="txtillsltall">
                                                <li className="txtlixtli">{singleView?.addQualification}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }


                {/* For Hr & Admin */}
                {
                    userData?.userType === "Admin" || userData?.userType === "Owner" ? (
                        <div className='employeepagecontainer'>

                            <div className='hiringdetialsdivflx'>

                                <div className='hririnhjjhdibnmon'>
                                    <div className="">
                                        <div className="rewarddiv">
                                            <div className="rewarddivflx">
                                                <p className="hobbyAspHead">Requested Hiring</p>

                                            </div>
                                            <div className="bottomareadibvmainhiring">
                                                {
                                                    jobpost?.map((item) => {
                                                        return (
                                                            <div className="bottomareadibvmainbg" style={{ cursor: "pointer" }} onClick={() => handleJobReqClick(item?._id)}>
                                                                <p className="bhsbvbbshhhtghpppn">
                                                                    Job Title :
                                                                    <span>
                                                                        {item?.jobTitle}
                                                                    </span>
                                                                </p>
                                                                <p className="bhsbvbbshhhtghpppn">
                                                                    Location :
                                                                    <span>
                                                                        {item.jobLocation.slice(0, -1).join(", ")
                                                                            + (item.jobLocation.length > 1 ? "," : "")
                                                                            + (item.jobLocation.length > 1 ? " and " : "")
                                                                            + item.jobLocation.slice(-1)}
                                                                    </span>
                                                                </p>
                                                                <p className="bhsbvbbshhhtghpppn">
                                                                    Skill :
                                                                    <span>
                                                                        {item.keySkills.slice(0, -1).join(", ")
                                                                            + (item.keySkills.length > 1 ? "," : "")
                                                                            + (item.keySkills.length > 1 ? " and " : "")
                                                                            + item.keySkills.slice(-1)}
                                                                    </span>
                                                                </p>
                                                                <p className="bhsbvbbshhhtghpppn">
                                                                    No. of Opening :
                                                                    <span>
                                                                        {item?.openings}
                                                                    </span>
                                                                </p>

                                                            </div>
                                                        )
                                                    })
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className=''>
                                    <div className='bg-brdrdiv'>

                                        <div className='bg-grydivmainhj'>
                                            <p className='hjkshhvvsuhikdojhjokklo'> Status :
                                                <span>
                                                    {singleHrView?.isApproved}
                                                </span>

                                            </p>
                                        </div>
                                        <div className="bg-grydivmainhj">
                                            <h2 className="psttxtppdiv">
                                                {singleHrView?.jobTitle}</h2>
                                        </div>


                                        <div className="dshimgtxt">
                                            <div className="imhtnch"><img src={Article} alt="..." /></div>
                                            {
                                                singleHrView?.departmentName ? (<div className='bg-grydiv'>
                                                    <p className='dstgytxy'>{singleHrView?.departmentName}</p>
                                                </div>) : (<div className='bg-grydiv'>
                                                    <p className='dstgytxy'>{getDepartmentName(singleHrView?.Department)}</p>
                                                </div>)
                                            }

                                        </div>
                                        <div className="dshimgtxt">
                                            <div className="imhtnch"><img src={Article} alt="..." /></div>
                                            <div className="bg-grydiv">
                                                <p className="dstgytxy">

                                                    {singleHrView?.period}</p>
                                            </div>
                                        </div>
                                        <div className="dshimgtxt">
                                            <div className="imhtnch"><img src={Article} alt="..." /></div>
                                            <div className="bg-grydiv">
                                                <p className="dstgytxy">
                                                    {singleHrView?.keySkills?.slice(0, -1).join(", ")
                                                        + (singleHrView?.keySkills?.length > 1 ? "," : "")
                                                        + (singleHrView?.keySkills?.length > 1 ? " and " : "")
                                                        + singleHrView?.keySkills?.slice(-1)}</p>
                                            </div>
                                        </div>
                                        <div className="dshimgtxt">
                                            <div className="imhtnch"><img src={Article} alt="..." /></div>
                                            <div className="bg-grydiv">
                                                <p className="dstgytxy">
                                                    {singleHrView?.jobLocation?.slice(0, -1).join(", ")
                                                        + (singleHrView?.jobLocation?.length > 1 ? "," : "")
                                                        + (singleHrView?.jobLocation?.length > 1 ? " and " : "")
                                                        + singleHrView?.jobLocation?.slice(-1)}</p>
                                            </div>
                                        </div>
                                        <div className="inpuytboxdyflx">
                                            <p className="optxtvn">Open to Referral Hiring</p>
                                            <input
                                                type="checkbox"
                                                checked={singleHrView?.isReffered}
                                            />
                                        </div>

                                    </div>

                                    {
                                        whyData?.map((item, ind) => {
                                            return (
                                                <>
                                                    <div className='jndivtxtwhy' key={ind} >
                                                        <p className="jntxtdivhrin">Why Join US?</p>
                                                        <div className='bg-grydiv'>
                                                            <p className='frcttxy'>
                                                                {item?.whyJoinUs}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }


                                    <div className="jndivtxtwhy">
                                        <p className="jntxtdivhrin">Position Description</p>
                                        <div className="bg-grydiv">
                                            <p className="frcttxy">
                                                {singleHrView?.positionDescription}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="jndivtxtwhy">
                                        <p className="jntxtdivhrin">Responsibilities:</p>
                                        <div className="bg-grydiv">
                                            <ul className="txtillsltall">
                                                <li className="txtlixtli">
                                                    {singleHrView?.responsibilities}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="jndivtxtwhy">
                                        <p className="jntxtdivhrin">Qualifications:</p>
                                        <div className="bg-grydiv">
                                            <ul className="txtillsltall">
                                                <li className="txtlixtli">{singleHrView?.qualification}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="jndivtxtwhy">
                                        <p className="jntxtdivhrin">
                                            Good To Have</p>
                                        <div className="bg-grydiv">
                                            <ul className="txtillsltall">
                                                <li className="txtlixtli">{singleHrView?.addQualification}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ):(<></>)
                }

            </section>
        </>
    )
}

export default Hiringdetails
