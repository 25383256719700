import React, { useEffect, useState } from "react";
// import "./Modal2.css";
// import "./Modal.css";
import "../Layout/Modal/Modal.css";

import { useDispatch, useSelector } from "react-redux";


import AffinityGroupIcon from "../../src/Images/Icons/PNG/AffinityGroupIcon.png"
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useAuthCtx } from "../context/AuthCtx";
import { getMyAffinityGroups } from "../redux/slices/affinitySlice";
import { ApiHelperFunction, fileUpload } from "../services/api/apiHelpers";
// import { colourOptions } from "../data";
const animatedComponents = makeAnimated();


const EditAffinityModal = ({ closemodal, activity, initialValues }) => {
    const { setLoading, loading, getUserDetails, userData } = useAuthCtx();
    const [imageURL, setImageURL] = useState(initialValues?.image);
    const dispatch = useDispatch();
    const [localerror, setlocalerror] = useState("");
    const [image, setImage] = useState("");
    const [uploading, setUploading] = useState(false);
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
    const [empData, setempData] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const myGroup = useSelector((state) => state?.affinitySlice?.myGroups);
    // const [myGroup, setMyGroup] = useState([]);

    // console.log("initialValueskl", initialValues)

    useEffect(() => {
        const data = initialValues?.addEmp
        const mentorData = data?.map((item) => {
            const optData = colourOptions?.find(ele => ele?.value === item)
            return optData ? optData : {}
        })
        setSelectedOptions(mentorData)
    }, [colourOptions])

    const submitHandler = async (e, selectedSubscription) => {
        e.preventDefault();
        setShouldValidateOnChange(true);
        setShouldValidateOnBlur(true);
        console.log("handleSubmit", values);

        let data = {};
        setLoading(true);
        if (values.groupName == "") {
            return toast.error("All fields required");
        }

        if (values.grouptype == "") {
            return toast.error("All fields required");
        }

        if (values.purpose == "") {
            return toast.error("All fields required");
        }

        if (imageURL == "") {
            return toast.error("All fields required");
        }

        data = {
            groupName: values?.groupName,
            grouptype: values?.grouptype,
            charter: values?.charter,
            purpose: values?.purpose,
            image: imageURL,
            // addEmp: selectedOptions,
            addEmp: selectedOptions?.map((item) => item?.value),
        };

        console.log("click_affinity_group", data);
        const res = await ApiHelperFunction({
            urlPath: `/update-affinity-group/${initialValues?._id}`,
            method: "PUT",
            data,
        });
        if (res?.status) {
            toast.success(res?.data?.message);
            resetForm();
            // dispatch(getAffinityGroups());
            // getAffinityMyGroupData();
            dispatch(getMyAffinityGroups());
            closemodal();
        } else {
            toast.error(res?.message || "Something went wrong");
            console.log("ERROR CREATING USER3", res);
        }
        setLoading(false);
    };

    const schemaCheck = Yup.object(
        activity === "billBoard" ||
            activity === "events" ||
            activity === "kababMenu" ||
            activity === "editEvent"
            ? {
                eventName: Yup.string().required("Event Name is required"),
                hostedBy: Yup.string().required("Host name is required"),
                eventDate: Yup.string().required("Event date is required"),
                eventstarttime: Yup.string().required("start Time is required"),
                eventendtime: Yup.string().required(" End Time is required"),
                notes: Yup.string().required(" Notes is required"),
                addinvites: Yup.string().required(" Addinvites is required"),
                lattitude: Yup.string().required(" lattitude is required"),

                longitude: Yup.string().required(" longitude is required"),
            }
            : activity === "training" || activity === "editTraining"
                ? {
                    name: Yup.string().required("Event Name is required"),
                    trainingDate: Yup.string().required("Training date is required"),
                    link: Yup.string().required("Training link is required"),
                }
                : activity === "affinity" || activity === "editAffinity"
                    ? {
                        groupName: Yup.string().required("Affinity Group Name is required"),
                        grouptype: Yup.string().required("Affinity Group Type is required"),
                        charter: Yup.string().required("Affinity Group Charter is required"),
                        purpose: Yup.string().required("Affinity Group Purpose is required"),
                    }
                    : activity === "jobReffered"
                        ? {
                            name: Yup.string().required("Job Name is required"),
                            location: Yup.string().required("Job location is required"),
                            salary: Yup.string().required("Salary range is required"),
                            description: Yup.string().required("Description range is required"),
                            opening: Yup.string().required("Opening is required"),
                        }
                        : ""
    );

    //view all employees
    const getEmployeeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-employees`,
            method: "GET",
        });

        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
            }));

            setColourOptions(formattedOptions);
            setSelectedOptions(formattedOptions?.filter(e => e.value === initialValues?.addEmp))
            setempData(response?.data?.data);
        } else {
            // toast.error(response.message);
        }
    };


    // const getAffinityMyGroupData = async () => {
    //   const response = await ApiHelperFunction({
    //     urlPath: `/view-my-affinity-group`,
    //     method: "GET",
    //   });
    //   console.log("RESPONSEFSDFu", response?.data?.data);
    //   if (response && response.status) {
    //     setMyGroup(response?.data?.data?.reverse());
    //   } else {
    //     toast.error(response.response?.data?.message);
    //   }
    // };


    //functionality for image upload
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await fileUpload("/image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploading(false);
    };

    // const handleSelectChange = (e) => {
    //   console.log("essdr", e);

    // };

    const handleSelectChange = (e) => {
        // console.log("Selected Options:", e);
        setSelectedOptions(e);
    };

    const { values, errors, handleBlur, handleSubmit, resetForm, handleChange } =
        useFormik({
            initialValues,
            validationSchema: schemaCheck,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

    useEffect(() => {
        getEmployeeData();
        dispatch(getMyAffinityGroups());
        // getAffinityMyGroupData();
    }, []);

    console.log("selectedOptions", selectedOptions)
    return (
        <section className="createmodal_main">
            <div className="createmodalnewdiv">
                <div className="createmodalnewdivcontent">
                    {/* <div className="myafinitydivpading">
            <p className="newBulleBrodHead">My Affinity Groups</p>
          </div>

          <button className="bulleBrodCloseBtn" onClick={() => closemodal()}>
            <i class="fa-solid fa-xmark"></i>
          </button> */}
                    <div className="bulletintextclose">
                        <div className="bulletinimageflxdivmodal">
                            <div className="bulleBoardFig">
                                <img src={AffinityGroupIcon} alt="..." />
                            </div>
                            <span className="newBulleCreHead">My Affinity Groups</span>
                        </div>

                        <div onClick={() => closemodal()}>
                            <i
                                class="fa-solid fa-xmark"
                                style={{
                                    color: "red",
                                    fontSize: "22px",
                                    cursor: "pointer",
                                }}
                            ></i>
                        </div>
                    </div>

                    <div className="">
                        <form
                            onSubmit={(e) => {
                                setShouldValidateOnBlur(true);
                                setShouldValidateOnChange(true);
                                handleSubmit(e);
                            }}
                        >
                            <div className="homePgModInnerInpDivsmainmyafinity">
                                <p>Group Name</p>
                                <input
                                    type="text"
                                    className="empListSelect"
                                    placeholder=""
                                    value={values?.groupName}
                                    name="groupName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="homePgModInnerInpDivsmainmyafinity">
                                <p>Group Type</p>
                                <input
                                    type="text"
                                    className="empListSelect"
                                    placeholder=""
                                    value={values?.grouptype}
                                    name="grouptype"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="homePgModInnerInpDivsmainmyafinity">
                                <p>Group Charter</p>
                                <input
                                    type="text"
                                    className="empListSelect"
                                    placeholder=""
                                    value={values?.charter}
                                    name="charter"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="homePgModInnerInpDivsmainmyafinity">
                                <p>Group Purpose</p>
                                <input
                                    type="text"
                                    className="empListSelect"
                                    placeholder=""
                                    value={values?.purpose}
                                    name="purpose"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>


                            <div className="homePgModInnerInpDivsmainmyafinity">
                                <p>Employees Name</p>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    value={selectedOptions}
                                    isMulti
                                    options={colourOptions}
                                    onChange={handleSelectChange}
                                />

                            </div>

                            <div className="homePgModInnerInpDivsupldflxdiv">
                                <p>Upload Image</p>
                                <div className="bguploadbulletinmdbrdrdiv">
                                    <div className="bgcrdbulletin">
                                        <div className="bgcontainerupldfilediv">
                                            <div className="logoupldbulletin">
                                                <img src="/static/media/Add icon.ed5715eb88ab4afb9efc.png" alt="..." />
                                                <div className="">
                                                    <input
                                                        type="file"
                                                        id="image"
                                                        placeholder="Choose a photo"
                                                        value={image}
                                                        accept="image/*"
                                                        name="image"
                                                        onBlur={handleBlur}
                                                        onChange={handleImageChange}
                                                        className="upldimagediv"
                                                    />

                                                </div>
                                            </div>
                                            <div className="bgcontainerupldfiledivabslt">

                                                {imageURL !== "" && (
                                                    <div className="upldimgdivbulletin" >
                                                        <img
                                                            src={imageURL}
                                                            alt="" />
                                                        <div className="clsoedivmainrd"
                                                            onClick={() => {
                                                                setImageURL("");
                                                                setImage("");
                                                            }}>
                                                            <i className="fa-solid fa-xmark"></i>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {uploading ? <p>image uploading......</p> : null}
                            </div>



                            <div className="bulletinsbmtbtndivv">
                                <button
                                    className="bulletinsbmtbtn"
                                    type="button"
                                    onClick={submitHandler}
                                >
                                    Update
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EditAffinityModal;
