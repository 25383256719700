import React, { useEffect, useState } from 'react'
import trainingmain from "../../Images/trainingmain.png";
import star from "../../Images/Icons/PNG/star.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward points.png";
import { useDispatch, useSelector } from 'react-redux';
import { getOnboardingnewTraining } from '../../redux/slices/jobRecruitmentSlice';
import NewTrainingCousremodal from '../../Component/Modal/NewTrainingCoursemodal';

const Educatetrainingard = () => {
    const newTraining = useSelector((state) => state?.jobRecruitmentSlice?.newTraining);
    const [singleMandatory, setSingleMandatory] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOnboardingnewTraining())
    }, [])

    // console.log("newTraining", newTraining)

    const [videotraing, setVideotraing] = useState(false);
    const handlevideoClick = (item) => {
        setSingleMandatory(item)
        setVideotraing(!videotraing)
    }
    // const navigate = useNavigate();


    return (
        <>      <div className='educatedivjdjbrdrbgmainb'>

          
            {

                newTraining?.map((item, index) => {
                    return (
                        <div className='educatemainbg' onClick={() => handlevideoClick(item)}  >
                            <div className='trainingimg'>
                                {/* <img src={item.image} alt="..." /> */}
                                {
                                    (item?.image === "images" || item?.image === "" || item?.image === "image") ? (<div className='traingmaindivmain'>
                                        <img src={trainingmain} alt="..." />
                                    </div>) : (<div className='traingmaindivmain'>
                                        <img src={item?.image} alt="..." />
                                    </div>)
                                }
                                <div className='trainingimgaddnew'>
                                    <div className='trainingimgdivnew'>new</div>
                                </div>
                            </div>
                            <div className=''>
                                <p className='educatetrainingtext'>{item?.title}</p>
                            </div>
                            <div className="imgtxtdivmaindddflx">
                                {
                                    item?.avgRatings !== null
                                        ?
                                        <div className="imgtxtdivmainddd">
                                            <div className="strimgdiv">
                                                <img
                                                    src={star}
                                                    alt="..."
                                                />
                                            </div>
                                            <p className="tsgyutppp">{item?.avgRatings}</p>
                                        </div>
                                        :
                                        <div className="imgtxtdivmainddd">
                                            <div className="strimgdiv">
                                                <img
                                                    src={star}
                                                    alt="..."
                                                />
                                            </div>
                                            <p className="tsgyutppp">0</p>
                                        </div>
                                }
                                <div className="imgtxtdivmainddd">
                                    <div className="strimgdiv">
                                        <img
                                            src={clock}
                                            alt="..."
                                        />
                                    </div>
                                    {/* <p className="tsgyutppp">{item.hrs}</p> */}
                                    {/* {item?.videos?.[0]?.videoDuration ? (<p className="tsgyutppp">{item?.videos?.[0]?.videoDuration?.hour ? (item?.videos?.[0]?.videoDuration?.hour) : ("00")} h {item?.videos?.[0]?.videoDuration?.min ? (item?.videos?.[0]?.videoDuration?.min) : ("00")}min</p>) : (<p className="tsgyutppp">{item?.placeTime?.[0]?.duration?.hour ? (item?.placeTime?.[0]?.duration?.hour) : ("00")} h {item?.placeTime?.[0]?.duration?.min ? (item?.placeTime?.[0]?.duration?.min) : ("00")}min</p>)} */}
                                    {/* {item?.totalDurationInMinute} min */}


                                    {
                                        item?.placeTime?.length > 0
                                            ?
                                            (
                                                <>
                                                    {item?.physicalTotalDurationInMinute} min
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    {item?.totalDurationInMinute} min
                                                </>
                                            )
                                    }

                                </div>
                                <div className="imgtxtdivmainddd">
                                    <div className="strimgfrmt">
                                        <img
                                            src={reward}
                                            alt="..."
                                        />
                                    </div>
                                    <p className="tsgyutppp">{item?.trainingReward}</p>
                                </div>
                            </div>

                        </div>

                    )
                })

            }
        </div>
            {videotraing && <NewTrainingCousremodal closemodal={setVideotraing} singleMandatory={singleMandatory} />}
        </>
    )
}

export default Educatetrainingard
