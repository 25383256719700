import React, { useEffect, useState } from 'react'
import star from "../../Images/Icons/PNG/star.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward points.png";
import Videosicon from "../../Images/Icons/PNG/Videos icon.png";
import Mobileicon from "../../Images/Icons/PNG/Mobile icon.png"
import Articleicon from "../../Images/Icons/PNG/Article icon.png";
import Certificateicon from "../../Images/Icons/PNG/Certificate icon.png";
import trainingmain from "../../Images/trainingmain.png";
import time from "../../Images/Icons/PNG/time.png"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { useAuthCtx } from '../../context/AuthCtx';
import download from "../../Images/Icons/PNG/download.png"


const EnlargedNewEnrollment = () => {
    const { userData } = useAuthCtx();
    const params = useParams();
    const location = useLocation();
    const singleMandatory = location?.state?.singleMandatory
    const navigate = useNavigate()
    const [dropdownintrocontent, setDropdownintrocontent] = useState(false)
    const [colourOptions, setColourOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [notEnroll, setNotEnroll] = useState("");
    const [singleView, setSingleView] = useState([])
    const [showMaterial, setShowMaterial] = useState(false);

    const toggleMaterialView = () => {
        setShowMaterial(!showMaterial);
    };

    //for view Single Page
    const viewSinglepost = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-single-trainings/${params?.id}`,
            method: "GET",
        });
        if (response?.status === 200) {
            setSingleView(response?.data?.data?.[0]);
        } else {

        }
    }


    const handleowndropdwncontentClick = () => {
        setDropdownintrocontent(!dropdownintrocontent);
    }

    //for viewing add more videos
    const cousrevideoonClick = (item) => {
        const data = {
            selectedOptions: selectedOptions,
            item: item,
            singleMandatory: singleMandatory
        }
        // navigate("/courseVideoPlayer", { state: data })
        navigate(`/courseVideoPlayer/${singleView?.title?.replace(/\s+/g, '-')}/${singleView?._id}/${item?._id}`, { state: data })
    }


    //for viewing first video
    const cousrevideofirstonClick = () => {
        const data = {
            selectedOptions: selectedOptions,
            singleMandatory: singleMandatory
        }
        // navigate("/coursefirstVideoPlayer", { state: data })
        navigate(`/coursefirstVideoPlayer/${singleView?.title?.replace(/\s+/g, '-')}/${singleView?._id}/${singleView?.videos?.[0]?._id}`, { state: data })
    }

    //for Enroll functionality
    const handleEnroll = async () => {
        let data = {
            trainingId: singleMandatory?._id
        }
        const res = await ApiHelperFunction({
            urlPath: `/join-training`,
            method: "POST",
            data: data
        })
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, enroll it!",
        }).then((res) => {
            if (res.isConfirmed) {
                setNotEnroll(true)
                toast?.success("Enrolled Successfully");
            }
            else if (res.isDismissed) {
                setNotEnroll(false)
            }
        });
    }

    //for getting internal trainer name
    const getEmployeeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-company-employees`,
            method: "GET",
        });
        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
                image: item?.image
            }));
            setColourOptions(formattedOptions);

        } else {

        }
    };

    useEffect(() => {
        getEmployeeData();
        viewSinglepost();
    }, [])

    useEffect(() => {
        const data = singleView?.mentor
        const mentorData = data?.map((item) => {
            const optData = colourOptions?.find(ele => ele?.value == item)
            return optData ? optData : {}
        })

        setSelectedOptions(mentorData)
    }, [colourOptions])


    //for getting status is joined in training
    const handleIsJoined = async () => {
        let res = await ApiHelperFunction({
            urlPath: `/is-joined/${singleMandatory?._id}`,
            method: "GET",
        });
        if (res && res?.status) {
            setNotEnroll(res?.data?.isJoined);
        }
    }

    //for save training functionality
    const handleSaveClick = async () => {
        let data = {
            id: singleView?._id,
        };
        let response = await ApiHelperFunction({
            urlPath: "/save-item",
            method: "POST",
            data,
        });
        if (response?.status) {
            toast?.success(response?.data?.message)
        }
    }

    useEffect(() => {
        handleIsJoined();
    }, [])


    console.log("notEnroll", notEnroll)
    return (
        <>
            <section className='EnlargednewEnrollmentsection'>
                <div className='employeepagecontainer'>
                    <div className='webdevloperenrollflx'>
                        <div className='developerbootcampdetails'>
                            <p className='devloperbtcmptxt'>{singleView?.title}</p>
                            <div className='ratingclckrewardflx'>

                                {
                                    singleView?.avgRatings !== null
                                        ?
                                        <div className="imgtxtdivmainddd">
                                            <div className="strimgdiv">
                                                <img src={star} alt="..." />
                                            </div>
                                            <p>{singleView?.avgRatings}</p>
                                        </div>
                                        :
                                        <div className="imgtxtdivmainddd">
                                            <div className="strimgdiv">
                                                <img src={star} alt="..." />
                                            </div>
                                            <p>0</p>
                                        </div>
                                }

                                <div className="imgtxtdivmainddd">
                                    <div className="strimgdiv">
                                        <img src={clock} alt="..." />
                                    </div>
                                    <p className="tsgyutppp">
                                        {/* {singleMandatory?.videos?.[0]?.videoDuration ?
                                            (<p className="tsgyutppp">{singleMandatory?.videos?.[0]?.videoDuration?.hour
                                                ? (singleMandatory?.videos?.[0]?.videoDuration?.hour) : ("00")} h {singleMandatory?.videos?.[0]?.videoDuration?.min ? (singleMandatory?.videos?.[0]?.videoDuration?.min) : ("00")}min</p>)
                                            : (<p className="tsgyutppp">{singleMandatory?.placeTime?.[0]?.duration?.hour ? (singleMandatory?.placeTime?.[0]?.duration?.hour) : ("00")} h {singleMandatory?.placeTime?.[0]?.duration?.min ? (singleMandatory?.placeTime?.[0]?.duration?.min) : ("00")}min</p>)} */}
                                        {
                                            singleView?.placeTime?.length > 0
                                                ?
                                                (
                                                    <>
                                                        {singleView?.physicalTotalDurationInMinute} min
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        {singleView?.totalDurationInMinute} min
                                                    </>
                                                )
                                        }
                                    </p>
                                </div>
                                <div className="imgtxtdivmainddd">
                                    <div className="strimgfrmt">
                                        <img src={reward} alt="..." />
                                    </div>
                                    <p className="tsgyutppp">{singleView?.trainingReward}</p>
                                </div>
                            </div>
                            {/* <div className=''>
                                <p className='engagetxtppenrl'>Last month we designed Testers 10 web platform three Amazon Sellers can get better reviews for their Sellers can cost their products, can order learn for free. test memo and old software had been part of the project time.</p>
                            </div> */}
                            <div className='descriptionanswerdiv'>
                                <h4 className='destxtption'>Description</h4>
                                <p className='lastmonthtxtdestxt'>{singleView?.description}
                                </p>
                                {
                                    userData?.userType !== "Owner" &&
                                    <>
                                        {
                                            singleView?.placeTime?.length !== 0 || singleView?.quizData?.length === 0 ? (<></>) : (
                                                singleView?.quizStatus === "active" ?
                                                    (<button className="quizebtnvisble" onClick={() => navigate(`/feedbackquize/${singleView?._id}`)}>
                                                        Take Quiz
                                                    </button>)
                                                    :
                                                    (
                                                        <button className="enrolllnowdiv">
                                                            Take Quiz
                                                        </button>
                                                    )
                                            )
                                        }
                                    </>
                                }

                            </div>
                        </div>



                        <div>

                        </div>

                        {/* {
                            userData?.userType !== "Owner" && */}
                        <div className='developerbootcampvdo'>
                            <div className='developerbootcampvdodivbh'>
                                <div className='trainingmaindivboot'>
                                    {/* <img src={trainingmain2} alt="..." /> */}
                                    {
                                        (singleView?.image === "images" || singleView?.image === "" || singleView?.image === "image") ? (<div className='traingmaindivmain'>
                                            <img src={trainingmain} alt="..." />
                                        </div>) : (<div className='traingmaindivmain'>
                                            <img src={singleView?.image} alt="..." />
                                        </div>)
                                    }
                                </div>
                                {/* <button className='enrolldivnowbtn'>Enroll Now</button> */}
                                {
                                    userData?.userType !== "Owner" &&
                                    <>
                                        {
                                            singleView?.placeTime?.length !== 0 ? (<></>) : (
                                                <div className='enrolllnowdiv' style={{ cursor: "pointer" }}>
                                                    {
                                                        notEnroll === false ? (<div onClick={handleEnroll}>Enroll Now</div>) : (<div onClick={cousrevideofirstonClick}>Play Now</div>)
                                                    }

                                                </div>
                                            )
                                        }
                                        <button className='enrolldivnowbtn' onClick={handleSaveClick}>Park For Future</button>
                                    </>
                                }
                            </div>
                        </div>
                        {/*  } */}

                    </div>
                    {/* <div className='descriptionanswerdiv'>
                        <h4 className='destxtption'>Description</h4>
                        <p className='lastmonthtxtdestxt'>Last month we designed Testers 10 web platform three Amazon Sellers can get better reviews for their Sellers can cost their products, can order learn for free. test memo and old software had been part of the project time. Last month we designed Testers 10 web platform three Amazon Sellers can get better reviews for their Sellers can cost their products, can order learn for free. test memo and old software had been part of the project time.
                            Last month we designed Testers 10 web platform three Amazon Sellers can get better reviews for their Sellers can cost their products, can order learn for free. test memo and old software had been part of the project time. Last month we designed Testers 10 web platform three Amazon Sellers can get better reviews for their Sellers can cost their products, can order learn for free. test memo and old software had been part of the project time.
                        </p>
                    </div> */}
                    <div className='newenrollmentdivlrninclentnflx'>
                        <div className='newlerndivmainwidth'>
                            <div className='wiillrndiv'>
                                <p className='lrnuniquetxt'>What Will You Learn</p>
                                <div className='wiillrndivflcxdiv'>
                                    {
                                        singleView?.videos?.map((item, i) => {
                                            return (
                                                <>
                                                    {/* {
                                                        item?.videoLearningContent?.map((item, i) => {
                                                            return (
                                                                <>
                                                                 
                                                                </>
                                                            )
                                                        })
                                                    } */}
                                                    <div className='wiillrndivflcx' key={i}>
                                                        {
                                                            item?.videoLearningContent?.map((item, i) => {
                                                                return (

                                                                    <div className='lrntxtqunirppppdiv' key={i}>
                                                                        <p className='lrntxtqunirpppp'>{item?.content}</p>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            )
                                        })

                                    }


                                    {/* <div className='wiillrndivflcx'>
                                        <div className='lrntxtqunirppppdiv'>
                                            <p className='lrntxtqunirpppp'>Lorem ipsum dolor sit amet.</p>
                                        </div>
                                        <div className='lrntxtqunirppppdiv'>
                                            <p className='lrntxtqunirpppp'>Lorem ipsum dolor sit amet.</p>
                                        </div>
                                    </div>
                                    <div className='wiillrndivflcx'>
                                        <div className='lrntxtqunirppppdiv'>
                                            <p className='lrntxtqunirpppp'>Lorem ipsum dolor sit amet.</p>
                                        </div>
                                        <div className='lrntxtqunirppppdiv'>
                                            <p className='lrntxtqunirpppp'>Lorem ipsum dolor sit amet.</p>
                                        </div>
                                    </div> */}
                                </div>


                            </div>

                            <div className='wiillrndiv'>
                                <p className='lrnuniquetxt'>Course Includes</p>
                                <div className='wiillrndivflcxdiv'>

                                    <div className='wiillrndivflcx'>
                                        {
                                            singleView?.videos?.length !== 0 ? (
                                                <div className='coursrincldflx'>
                                                    <div className='cousrsedidicnimg'>
                                                        <img src={Videosicon} alt="..." />
                                                    </div>
                                                    <p className='cousredtlstxtppas'>{singleView?.totalDurationInMinute} minutes on-demand video</p>
                                                </div>
                                            )
                                                :
                                                (
                                                    <div className='coursrincldflx'>
                                                        <div className='cousrsedidicnimg'>
                                                            <img src={Videosicon} alt="..." />
                                                        </div>
                                                        <p className='cousredtlstxtppas'>On-demand Training</p>
                                                    </div>
                                                )
                                        }

                                        <div className='coursrincldflx'>
                                            <div className='cousrsedidicnimg'>
                                                <img src={Certificateicon} alt="..." />
                                            </div>
                                            <p className='cousredtlstxtppas'>Certificate of Completion</p>
                                        </div>
                                        {/* <div className='coursrincldflx'>
                                            <div className='cousrsedidicnimg'>
                                                <img src={download} alt="..." />
                                            </div>
                                            <p className='cousredtlstxtppas'>121 downloadable resources </p>
                                        </div> */}
                                    </div>
                                    <div className='wiillrndivflcx'>
                                        {/* <div className='coursrincldflx'>
                                            <div className='cousrsedidicnimg'>
                                                <img src={CodingIcon} alt="..." />
                                            </div>
                                            <p className='cousredtlstxtppas'>7 Coding Excercise</p>
                                        </div> */}
                                        <div className='coursrincldflx'>
                                            <div className='cousrsedidicnimg'>
                                                <img src={Mobileicon} alt="..." />
                                            </div>
                                            <p className='cousredtlstxtppas'>Accesss on mobile and TV</p>
                                        </div>
                                        <div className='coursrincldflx'>
                                            <div className='cousrsedidicnimg'>
                                                <img src={Articleicon} alt="..." />
                                            </div>
                                            {
                                                singleView?.placeTime?.length !== 0 ? (
                                                    // <div className='enrolllnowdiv' style={{ cursor: "pointer" }}>
                                                    <p className='cousredtlstxtppas'>{singleView?.totalOfflineDocument} Articles</p>

                                                    // </div>
                                                ) : (
                                                    // <div className='enrolllnowdiv' style={{ cursor: "pointer" }}>
                                                    <p className='cousredtlstxtppas'>{singleView?.totalVideoDocument} Articles</p>

                                                    // </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                    <div className='wiillrndivflcx'>


                                    </div>

                                </div>
                            </div>

                            {
                                (singleView?.videos?.length !== 0 && notEnroll === true && userData?.userType !== "Owner") || (notEnroll === "" && userData?.userType !== "Owner")
                                    ?
                                    (<>
                                        <div className='wiillrndiv'>
                                            <p className='lrnuniquetxt'>Course Content</p>

                                            {
                                                singleView?.videos?.map((item, i) => {
                                                    console.log("firstitem", item)
                                                    return (
                                                        <>
                                                            <div className='coursecontentflxalll' key={i}>
                                                                <div className='cousreimgdmainimgnn'>
                                                                    <div
                                                                        className='cousreimgdmain'
                                                                        onClick={(e) => cousrevideoonClick(item, e)}
                                                                    >
                                                                        {/* <img src={trainingmain} alt="..." /> */}
                                                                        {/* <ReactPlayer
                                                                            className='reactdivimgmainplrt'
                                                                            controls
                                                                            // width='42rem'
                                                                            // height='24rem'
                                                                            // playing={false}
                                                                            url={item?.videoLink}
                                                                            onBuffer={cousrevideoonClick}
                                                                        // config={{
                                                                        //     youtube: {
                                                                        //         playerVars: { showinfo: 1 }
                                                                        //     },
                                                                        //     facebook: {
                                                                        //         appId: '12345'
                                                                        //     },
                                                                        // }}

                                                                        /> */}
                                                                        <video
                                                                            src={item?.videoLink}
                                                                            className='reactdivimgmainplrt'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='lastmnthdesitxtflx'>
                                                                    <div className=''>
                                                                        <p className='crsecntntxt'>{i+1}</p>
                                                                    </div>
                                                                    <div className=''>
                                                                        <p className='crsecntntxt'>{item?.videoTitle}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='cusrvideominuteflx'>
                                                                    <div className='timeimgdiv'>
                                                                        <img src={time} alt="..." />
                                                                    </div>
                                                                    <div className=''>
                                                                        {/* <p className='hrsmnttxtpp'>{item?.videoDuration?.hour ? (<>{`${item?.videoDuration?.hour} hr`}</>) : ("0")}  {item?.videoDuration?.min ? (<>{`${item?.videoDuration?.min} min`}</>) : ("00")}</p> */}
                                                                        <p className='hrsmnttxtpp'>{item?.videoDuration?.hour ? (<>{item?.videoDuration?.hour}</>) : ("0")}hr  {item?.videoDuration?.min ? (<>{item?.videoDuration?.min}</>) : ("00")}min</p>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>


                                                    )
                                                })
                                            }

                                            {/* <ReactPlayer
                                            className='showvideo'
                                            // controls
                                            width='10rem'
                                            height='7.5rem'
                                            url={singleMandatory?.videos?.[0]?.videoLink}
                                            playing={false}
                                            onBuffer={() => {
                                                console.log("object")
                                                
                                            }}

                                        />*/}
                                        </div>
                                    </>)
                                    :

                                    singleView?.placeTime?.length !== 0 && userData?.userType !== "Owner"
                                        ?
                                        (<>

                                            <div className='wiillrndiv'>
                                                <p className='lrnuniquetxt'>Course Content</p>

                                                {
                                                    singleView?.placeTime?.map((item, i) => {
                                                        console.log("firstitem", item)
                                                        return (
                                                            <>
                                                                <div className='coursecontentflxalll' key={i}>
                                                                    {/* <div className='cousreimgdmainimgnn'>
                                                                    <div
                                                                        className='cousreimgdmain'
                                                                        onClick={(e) => cousrevideoonClick(item, e)}
                                                                    >
                                                                        
                                                                        <video
                                                                            src={item?.videoLink}
                                                                            className='reactdivimgmainplrt'
                                                                        />
                                                                    </div>
                                                                </div> */}

                                                                    <div className='downladimgmainpl'>
                                                                        <div>
                                                                            <img src={download} onClick={toggleMaterialView} alt="..." />
                                                                        </div>

                                                                        <div className='downloaddivmaindiv'>

                                                                            {
                                                                                showMaterial &&
                                                                                item?.Documents !== "" && (
                                                                                    <>
                                                                                        <div className='downloaddivmaindivsize'>
                                                                                            <iframe src={item?.Documents} title="Material Viewer" width="100%" height="600px"></iframe>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }



                                                                        </div>
                                                                    </div>

                                                                    <div className='lastmnthdesitxtflx'>
                                                                        <div className=''>
                                                                            <p className='crsecntntxt'>1</p>
                                                                        </div>
                                                                        <div className=''>
                                                                            <p className='crsecntntxt'>Building : {item?.Budling}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='cusrvideominuteflx'>
                                                                        <div className='timeimgdiv'>
                                                                            <img src={time} alt="..." />
                                                                        </div>
                                                                        <div className=''>
                                                                            <p className='hrsmnttxtpp'>{item?.duration?.hour ? (<>{item?.duration?.hour}</>) : ("0")}hr  {item?.duration?.min ? (<>{item?.duration?.min}</>) : ("00")}min</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </>


                                                        )
                                                    })
                                                }

                                            </div>

                                        </>)
                                        :
                                        <></>
                            }


                            {/* <div className='coursecontentflxalll'>
                                    <div className='cousreimgdmainimgnn'>
                                        <div className='cousreimgdmain'>
                                            <img src={trainingmain} alt="..." />
                                        </div>
                                    </div>
                                    <div className='lastmnthdesitxtflx'>
                                        <div className=''>
                                            <p className='crsecntntxt'>2</p>
                                        </div>
                                        <div className=''>
                                            <p className='crsecntntxt'>Last month we designed Testers 10 web platform?</p>
                                        </div>
                                    </div>
                                    <div className='cusrvideominuteflx'>
                                        <div className='timeimgdiv'>
                                            <img src={time} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='hrsmnttxtpp'>26m</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='coursecontentflxalll'>
                                    <div className='cousreimgdmainimgnn'>
                                        <div className='cousreimgdmain'>
                                            <img src={trainingmain} alt="..." />
                                        </div>
                                    </div>
                                    <div className='lastmnthdesitxtflx'>
                                        <div className=''>
                                            <p className='crsecntntxt'>3</p>
                                        </div>
                                        <div className=''>
                                            <p className='crsecntntxt'>Last month we designed Testers 10 web platform?</p>
                                        </div>
                                    </div>
                                    <div className='cusrvideominuteflx'>
                                        <div className='timeimgdiv'>
                                            <img src={time} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='hrsmnttxtpp'>26m</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='coursecontentflxalll'>
                                    <div className='cousreimgdmainimgnn'>
                                        <div className='cousreimgdmain'>
                                            <img src={trainingmain} alt="..." />
                                        </div>
                                    </div>
                                    <div className='lastmnthdesitxtflx'>
                                        <div className=''>
                                            <p className='crsecntntxt'>4</p>
                                        </div>
                                        <div className=''>
                                            <p className='crsecntntxt'>Last month we designed Testers 10 web platform?</p>
                                        </div>
                                    </div>
                                    <div className='cusrvideominuteflx'>
                                        <div className='timeimgdiv'>
                                            <img src={time} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='hrsmnttxtpp'>26m</p>
                                        </div>
                                    </div>

                                </div> */}


                        </div>
                        <div className='newlerndivmainleft'>
                            <div className='newlerndivmainleftbrdr'>
                                <div className="crsecntnttxtp">
                                    <p className='crsecntnttxtppp'>Course Information</p>
                                </div>
                                <div className=''>
                                    <div className='Introductiontxtanglebgmain'>
                                        <div className='Introductiontxtangle'>
                                            <div className='' onClick={handleowndropdwncontentClick}>
                                                <span className={dropdownintrocontent ? "fa-solid fa-angle-right" : "fa-solid fa-angle-down"}></span>
                                            </div>
                                            <div className=''>
                                                <p className='intrdivppptxts'>Introduction</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={dropdownintrocontent ? "closedropdownactd" : "opendropdownactd"}>
                                        {
                                            singleView?.placeTime?.length !== 0
                                                ? (<>
                                                    <div className=''>
                                                        <p className='undrstndgppp'>Language : {singleView?.language}</p>
                                                    </div>
                                                    {
                                                        singleView?.placeTime?.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <div className='drpopfdivbhjkl' key={i}>
                                                                        <div className='contetxtxttimeflx'>
                                                                            <div className=''>
                                                                                <p className='undrstndgppp'>{moment(item.StartDate).format("YYYY-MM-DD")}</p>
                                                                            </div>
                                                                            <div className=''>
                                                                                <p className='undrstndgppp'>{item?.startTime}</p>
                                                                            </div>
                                                                            <div className=''>
                                                                                <p className='undrstndgppp'>{item?.videoDuration?.hour ? (<p>{item?.videoDuration?.hour}</p>) : ("0")} hr {item?.videoDuration?.min ? (<p>{item?.videoDuration?.min}</p>) : ("00")} min</p>
                                                                            </div>
                                                                            {/* <div className=''>
                                                                                <p className='undrstndgppp'>{item?.language}</p>
                                                                            </div> */}
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </>)
                                                : (<>
                                                    <div className=''>
                                                        <p className='undrstndgppp'>Language : {singleView?.language}</p>
                                                    </div>
                                                    {
                                                        singleView?.mentor !== 0 ?
                                                            (<div className=''>
                                                                <p className='undrstndgppp'>Mentor : {`${selectedOptions?.map((item) => item?.label)} `}</p>
                                                            </div>)
                                                            : singleView?.trainerName ? (<div className=''>
                                                                <p className='undrstndgppp'>Training Name : {singleView?.trainerName}</p>
                                                            </div>)
                                                                : (<></>)

                                                    }


                                                </>)
                                        }

                                        {/* <div className='drpopfdivbhjkl'>
                                            <div className='contetxtxttimeflx'>
                                                <div className=''>
                                                    <p className='undrstndgppp'>How to understand ?</p>
                                                </div>
                                                <div className=''>
                                                    <p className='timeintrotxt'>00:39
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='drpopfdivbhjkl'>
                                            <div className='contetxtxttimeflx'>
                                                <div className=''>
                                                    <p className='undrstndgppp'>How to understand ?</p>
                                                </div>
                                                <div className=''>
                                                    <p className='timeintrotxt'>00:39
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='drpopfdivbhjkl'>
                                            <div className='contetxtxttimeflx'>
                                                <div className=''>
                                                    <p className='undrstndgppp'>How to understand ?</p>
                                                </div>
                                                <div className=''>
                                                    <p className='timeintrotxt'>00:39
                                                    </p>
                                                </div>
                                            </div>

                                        </div> */}
                                    </div>
                                </div>
                                {/* <div className=''>
                                    <div className='Introductiontxtanglebgmain'>
                                        <div className='Introductiontxtangle'>
                                            <div className='' onClick={handleowndropdwncontentClick}>
                                                <span className={dropdownintrocontent ? "fa-solid fa-angle-right" : "fa-solid fa-angle-down"}></span>
                                            </div>
                                            <div className=''>
                                                <p className='intrdivppptxts'>Introduction</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={dropdownintrocontent ? "closedropdownactd" : "opendropdownactd"}>
                                        <div className='drpopfdivbhjkl'>
                                            <div className='contetxtxttimeflx'>
                                                <div className=''>
                                                    <p className='undrstndgppp'>How to understand ?</p>
                                                </div>
                                                <div className=''>
                                                    <p className='timeintrotxt'>00:39
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='drpopfdivbhjkl'>
                                            <div className='contetxtxttimeflx'>
                                                <div className=''>
                                                    <p className='undrstndgppp'>How to understand ?</p>
                                                </div>
                                                <div className=''>
                                                    <p className='timeintrotxt'>00:39
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='drpopfdivbhjkl'>
                                            <div className='contetxtxttimeflx'>
                                                <div className=''>
                                                    <p className='undrstndgppp'>How to understand ?</p>
                                                </div>
                                                <div className=''>
                                                    <p className='timeintrotxt'>00:39
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='drpopfdivbhjkl'>
                                            <div className='contetxtxttimeflx'>
                                                <div className=''>
                                                    <p className='undrstndgppp'>How to understand ?</p>
                                                </div>
                                                <div className=''>
                                                    <p className='timeintrotxt'>00:39
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='Introductiontxtanglebgmain'>
                                        <div className='Introductiontxtangle'>
                                            <div className='' onClick={handleowndropdwncontentClick}>
                                                <span className={dropdownintrocontent ? "fa-solid fa-angle-right" : "fa-solid fa-angle-down"}></span>
                                            </div>
                                            <div className=''>
                                                <p className='intrdivppptxts'>Introduction</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={dropdownintrocontent ? "closedropdownactd" : "opendropdownactd"}>
                                        <div className='drpopfdivbhjkl'>
                                            <div className='contetxtxttimeflx'>
                                                <div className=''>
                                                    <p className='undrstndgppp'>How to understand ?</p>
                                                </div>
                                                <div className=''>
                                                    <p className='timeintrotxt'>00:39
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='drpopfdivbhjkl'>
                                            <div className='contetxtxttimeflx'>
                                                <div className=''>
                                                    <p className='undrstndgppp'>How to understand ?</p>
                                                </div>
                                                <div className=''>
                                                    <p className='timeintrotxt'>00:39
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='drpopfdivbhjkl'>
                                            <div className='contetxtxttimeflx'>
                                                <div className=''>
                                                    <p className='undrstndgppp'>How to understand ?</p>
                                                </div>
                                                <div className=''>
                                                    <p className='timeintrotxt'>00:39
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='drpopfdivbhjkl'>
                                            <div className='contetxtxttimeflx'>
                                                <div className=''>
                                                    <p className='undrstndgppp'>How to understand ?</p>
                                                </div>
                                                <div className=''>
                                                    <p className='timeintrotxt'>00:39
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default EnlargedNewEnrollment
