
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import { ApiHelperFunction } from '../../services/api/apiHelpers';

const Educationanalysis = () => {
    const [publishCountManager, setPublishCountManager] = useState([]);
    const [publishCountHr, setPublishCountHr] = useState([]);
    const [publishCountOthers, setPublishCountOthers] = useState([]);
    const [education, setEducation] = useState([])

    useEffect(() => {
        viewPublishContent();
    }, [])

    const viewPublishContent = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/education-analysis`,
            method: "GET",
        });

        if (response.status) {
            let edu = response?.data?.data?.map((item) => {
                const obj = item?._id
                return obj
            });
            let managerdata = response?.data?.data?.map((item) => {
                const obj = item?.userTypes?.find(ele => ele?.userType === "Manager")
                return obj?.count
            });
            let hrdata = response?.data?.data?.map((item) => {
                const obj = item?.userTypes?.find(ele => ele?.userType === "Hr")
                return obj?.count
            });
            let otherdata = response?.data?.data?.map((item) => {
                const obj = item?.userTypes?.find(ele => ele?.userType === "Employee")
                return obj?.count
            });
            setPublishCountManager(managerdata);
            setPublishCountHr(hrdata);
            setPublishCountOthers(otherdata);
            setEducation(edu);
        }
    }

    const optioneducational = {
        series: [

            {
                name: 'HR',
                data: publishCountHr,

            },
            {
                name: 'Manager',
                data: publishCountManager,

            },
            {
                name: 'Employee',
                data: publishCountOthers,

            }],
        // [{
        //     data: [99, 150, 125, 99, 90,]
        // }, 
        // {
        //     data: [200, 200, 203, 120, 135,]
        // },
        // {
        //     data: [200, 200, 203, 120, 135,]
        // }],
        chart: {
            height: 300,
            width: 280,
            type: 'bar',
        },
        fill: {
            opacity: 1,
            colors: ['#cc051c', '#142553', '#818589']
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['#fff']
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: true,
                dataLabels: {
                    position: 'top',  // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetX: 30,
            style: {
                fontSize: '12px',
                colors: ['#cc051c', '#142553', '#818589']
            }
        },
        xaxis: {
            categories: education,
            // ['Bachelor’s Degree', 'Bachelor’s Degree', 'Bachelor’s Degree', 'Bachelor’s Degree', 'Bachelor’s Degree'],
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },

            tooltip: {
                enabled: true,
            },
            labels: {
                show: false,
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
            }

        },

    };

    return (
        <>
            <div className='educationanldivmain'>
                <div>
                    <Chart
                        options={optioneducational}
                        series={optioneducational.series}
                        type="bar"
                        height={optioneducational.chart.height}
                        width={optioneducational.chart.width}
                    />
                </div>
            </div>
        </>
    )
}

export default Educationanalysis