import React, { useEffect, useState } from 'react'
import mandatory from "../../Images/Icons/PNG/mandatory.png";
import Expand from "../../Images/Icons/PNG/Expand.png";
import trainingmain from "../../Images/trainingmain.png"
import star from "../../Images/Icons/PNG/star.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward points.png";
// import EducateMandatory from './EducateMandatory';
import { useNavigate } from 'react-router-dom';
import MandatoryCousremodal from '../../Component/Modal/MandatoryCousremodal';
import { useDispatch, useSelector } from 'react-redux';
import { getOnboardingTraining } from '../../redux/slices/jobRecruitmentSlice';
import Slider from 'react-slick';


const Mandatory = () => {
  const navigate = useNavigate();
  const training = useSelector((state) => state?.jobRecruitmentSlice?.training)
  const dispatch = useDispatch();
  const [singleMandatory, setSingleMandatory] = useState({});
  const [videotraing, setVideotraing] = useState(false);
  const [rewardPoint, setRewardPoint] = useState("");

  const handleDetailsClick = (ele) => {
    navigate('/singleMandatoryCourse', { state: ele })
  }

  useEffect(() => {
    dispatch(getOnboardingTraining())
  }, [])

  const handlevideoClick = (item, point) => {
    setRewardPoint(point);
    setSingleMandatory(item);
    setVideotraing(!videotraing);
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  console.log("singleMandatoryl", rewardPoint)
  return (
    <>
      {
        training?.map((ele, ind) => {
          return (
            <div className='mandatorycoursemaindiv' key={ind}>
              <div className='mandatorydiv'>
                <div className='mandatoryicontxtflx'>
                  <div className='icontxtdivmain'>
                    <div className='mandatoryimg'>
                      <img src={mandatory} alt="..." />
                    </div>
                    <div className=''>
                      <p className='mandatorttxt'>{ele?.name}</p>
                    </div>
                  </div>
                  <div className='Expandimg'>
                    <img src={Expand} alt="..." onClick={() => handleDetailsClick(ele)} />
                  </div>
                </div>

                <div className='mandatorydivbtm'>
                  {/* <EducateMandatory /> */}
                  <>
                    <Slider {...settings}>
                      {
                        ele?.trainingDetails?.map((item, i) => {
                          return (<>
                            <div className='mandatorydivbg' onClick={() => handlevideoClick(item, ele?.rewardPoint)} key={i}>
                              {
                                (item?.image === "images" || item?.image === "" || item?.image === "image") ? (<div className='traingmaindivmain'>
                                  <img src={trainingmain} alt="..." />
                                </div>) : (<div className='traingmaindivmain'>
                                  <img src={item?.image} alt="..." />
                                </div>)
                              }

                              <div className=''>
                                <p className='mandttxtpp'>
                                  {item?.title}
                                </p>
                                <p className='writertxtpp'>{item?.userName}</p>
                              </div>
                              <div className="imgtxtdivmaindddflx">
                                {
                                  item?.avgRatings !== null
                                    ?
                                    <div className="imgtxtdivmainddd">
                                      <div className="strimgdiv">
                                        <img
                                          src={star}
                                          alt="..."
                                        />
                                      </div>
                                      <p className="tsgyutppp">{item?.avgRatings}</p>
                                    </div>
                                    :
                                    <div className="imgtxtdivmainddd">
                                      <div className="strimgdiv">
                                        <img
                                          src={star}
                                          alt="..."
                                        />
                                      </div>
                                      <p className="tsgyutppp">0</p>
                                    </div>
                                }

                                <div className="imgtxtdivmainddd">
                                  <div className="strimgdiv">
                                    <img
                                      src={clock}
                                      alt="..."
                                    />
                                  </div>
                                  {/* {item?.videos?.[0]?.videoDuration ? (<p className="tsgyutppp">{item?.videos?.[0]?.videoDuration?.hour ? (item?.videos?.[0]?.videoDuration?.hour) : ("00")} h {item?.videos?.[0]?.videoDuration?.min ? (item?.videos?.[0]?.videoDuration?.min) : ("00")}min</p>) : (<p className="tsgyutppp">{item?.placeTime?.[0]?.duration?.hour ? (item?.placeTime?.[0]?.duration?.hour) : ("00")} h {item?.placeTime?.[0]?.duration?.min ? (item?.placeTime?.[0]?.duration?.min) : ("00")}min</p>)} */}
                                  {/* {item?.totalDurationInMinute} min */}

                                  {
                                    item?.placeTime?.length > 0
                                      ?
                                      (
                                        <>
                                          {item?.physicalTotalDurationInMinute} min
                                        </>
                                      )
                                      :
                                      (
                                        <>
                                          {item?.totalDurationInMinute} min
                                        </>
                                      )
                                  }

                                </div>
                                <div className="imgtxtdivmainddd">
                                  <div className="strimgfrmt">
                                    <img
                                      src={reward}
                                      alt="..."
                                    />
                                  </div>
                                  <p className="tsgyutppp">{ele?.rewardPoint}</p>
                                </div>
                              </div>
                            </div>
                          </>)
                        })
                      }
                    </Slider>
                    {videotraing && <MandatoryCousremodal
                      closemodal={setVideotraing}
                      singleMandatory={singleMandatory}
                      rewardPoint={rewardPoint}
                    />}
                  </>
                </div>

              </div>
            </div>
          )
        })
      }

    </>
  )
}

export default Mandatory