import React, { useEffect, useState } from 'react'
// import notiImg from "../../Images/notiImg.png";
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import SuggestionBox from "../../Images/headericon/SuggestionBox.png";
import search from "../../Images/headericon/search.png";
import filter from "../../Images/headericon/filter.png"

const SuggestionList = () => {
    const [suggestionData, setSuggestionData] = useState([]);
    const [fiktermodl, setfiktermodl] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [keyword, setkeyword] = useState("");


    const filyteronclick = () => {
        setfiktermodl(!fiktermodl)
    }

    //for viewing suggestion list
    const viewSuggestionData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-suggestion`,
            method: "GET"
        });
        if (response.status) {
            setSuggestionData(response?.data?.data)
        }
    };


    //for filtering data priority wise
    const viewFilteredData = async (val) => {
        const response = await ApiHelperFunction({
            urlPath: `/view-suggestion-prioritywise/${val}`,
            method: "GET"
        });
        if (response.status) {
            setSuggestionData(response?.data?.data);
        }
    };


    //onChange functionality for filtering 
    const handleFilterChange = (event) => {
        const value = event.target.value;
        setSelectedFilter(value);
        if (value === "All") {
            viewSuggestionData();
        } else {
            viewFilteredData(value);
        }
    };


    //for action functionality
    const handleAction = async (id) => {
        const res = await ApiHelperFunction({
            urlPath: `/suggestion-action-update/${id}`,
            method: "PUT"
        })

        Swal.fire({
            title: "Do you take any action?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, take it!",
        }).then((result) => {
            if (result.isConfirmed) {
                setSuggestionData(prevState => prevState?.map(item =>
                    item._id === id ? { ...item, action: true } : item
                ));
                toast?.success("Ok");
            } else if (result.isDismissed) {
                setSuggestionData(prevState => prevState?.map(item =>
                    item._id === id ? { ...item, action: false } : item
                ));
            }
        });
    }

    //for search list
    const searchList = async () => {
        let response = await ApiHelperFunction({
            urlPath: "/search-all-suggestion",
            method: 'POST',
            data: {
                searchName: keyword
            }
        })
        if (response?.status) {
            setSuggestionData(response?.data?.data);
            // if (suggestionData?.length) {
            //     // toast('No data found!');
            // }
        } 
    };

    useEffect(() => {
        let debounce = setTimeout(() => {
            searchList();
        }, 400)

        return (() => clearTimeout(debounce))
    }, [keyword])

    useEffect(() => {
        if (selectedFilter === "All") {
            viewSuggestionData();
        } else {
            viewFilteredData(selectedFilter);
        }
    }, [selectedFilter]);
    // console.log("isActionTaken", action)
    return (
        <section className='Grivencesuggestiondivmain'>
            <div className='employeepagecontainer'>

                <div className='Grivencesuggsbdivbghj'>

                    <div className="grovencedivmabdivpading">

                        <div className='GrivenceCelldivimh'>
                            <img
                                src={SuggestionBox}

                                alt="icon"
                            />

                        </div>
                        <p className="newBulleBrodHead">Suggestions</p>

                    </div>

                    <div className='srchggrivenvflrtdivflx'>


                        <div className='srchggrivenvbhdiv'>
                            <input
                                type='text'
                                placeholder='Name,Designation,Date(DD/MM/YYYY)'
                                name='keyword'
                                value={keyword}
                                onChange={(e) => setkeyword(e.target.value)}
                            />
                            <div className='searchdinsgrgvc'>
                                <img src={search} alt="..." />
                            </div>
                        </div>

                        <div className='filterdivimg' onClick={filyteronclick}>
                            <img src={filter} alt="..." />
                            {fiktermodl && <div className='filterdivimghjgblowhj'>
                                <div className='hoighmdijhdbnmfkx'>
                                    <input type='radio' id="" name="priority" value="All" checked={selectedFilter === "All"} onChange={handleFilterChange} />
                                    <p className='hghtxtpopgjhqqw'>All</p>
                                </div>
                                <div className='hoighmdijhdbnmfkx'>
                                    <input type='radio' id="" name="priority" value="high" checked={selectedFilter === "high"} onChange={handleFilterChange} />
                                    <p className='hghtxtpopgjhqqw'>High</p>
                                </div>
                                <div className='hoighmdijhdbnmfkx'>
                                    <input type='radio' id="" name="priority" value="medium" checked={selectedFilter === "medium"} onChange={handleFilterChange} />
                                    <p className='hghtxtpopgjhqqw'>Medium</p>
                                </div>
                                <div className='hoighmdijhdbnmfkx'>
                                    <input type='radio' id="" name="priority" value="low" checked={selectedFilter === "low"} onChange={handleFilterChange} />
                                    <p className='hghtxtpopgjhqqw'>Low</p>
                                </div>
                            </div>}
                        </div>
                    </div>

                    <div className='grovencedivmabdivpadingbtm'>
                        {
                            suggestionData?.map((item, i) => {
                                return (
                                    <div className="notiPersonImggrovendivfmainboc" key={i}>


                                        <div className='notiPersonImggrovendivflx'>

                                            <div className='fggdjjhanotiPersonIm'>
                                                <div className="notiPersonImggroven">
                                                    <img src={item?.suggestionGiverIamge} alt="..." />
                                                </div>

                                                <div className="fggdjjhafcsghlop">

                                                    <div className='grivenceprorotyttxdivmain'>
                                                        <p className="grivenceprorotyttx">
                                                            {item?.suggestionGiverName}
                                                        </p>
                                                        <p className="grivenceprorotyttx">
                                                            {item?.designation}
                                                        </p>
                                                    </div>
                                                    <div className='grivenceprorotyttxdivmain'>
                                                        <p className="grivenceprorotyttx">
                                                            Date
                                                            <span className='grivenceprorotyttxspn' >{item?.suggestionDate}</span>
                                                        </p>
                                                    </div>
                                                    <div className='grivenceprorotyttxdivmain'>
                                                        <p className="grivenceprorotyttx">
                                                            Priority
                                                            <span className='grivenceprorotyttxspn' >{item?.priority} </span>
                                                        </p>
                                                    </div>

                                                    <div className=''>
                                                        <p className="grivenceprorotyttx">
                                                            Suggestion

                                                            <span className='grivenceprorotyttxspn'> {item?.suggestion}</span>
                                                        </p>
                                                    </div>


                                                </div>
                                            </div>

                                            <div className='manndgfacctdibn'>
                                                {
                                                    item?.action
                                                        ?
                                                        <button className='btnacttkndivbtn'>
                                                            Action Taken
                                                        </button>
                                                        :
                                                        <button className='btnactbndivbtn' onClick={() => handleAction(item?._id)}>
                                                            Action
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

            </div>
        </section>
    )
}

export default SuggestionList