import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import eventimg1 from "../../src/Images/eventimg1.png";
import eventimg2 from "../../src/Images/eventimg2.png"
import { useAuthCtx } from '../context/AuthCtx';
import { ApiHelperFunction } from '../services/api/apiHelpers';
import moment from 'moment';
import profilesqrimg from "../../src/Images/profilesqrimg.jpeg"
import Eventimg2 from "../Images/No-Image-Placeholder.png";
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionEventData } from '../redux/slices/subscriptionEventSlice';

function MysubscribedEvents() {
    const subscribed = useSelector((state) => state?.subscriptionEventSlice?.subscription);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSubscriptionEventData())
    }, [])
    // console.log("subscribed", subscribed)

    return (
        <section id='highlighted_events'>
            <div className='top'>
                <div className='head'>
                    <h4>My Subscribed Events</h4>
                </div>
            </div>
            <div className='bottom'>
                {
                    subscribed?.length > 0 ? (<>{
                        subscribed?.map((item, index) => {
                            return (
                                <>

                                    <div
                                        key={index}
                                        className="card_area"
                                        style={{
                                            marginBottom: "7px",
                                            // background: "#fbd8d8",
                                            position: "relative",
                                            marginTop: "2px",
                                        }}
                                    >
                                        <div className="event_img">
                                            {item?.eventImage ? (
                                                <img
                                                    src={item?.eventImage}
                                                    className="img-fluid"
                                                    alt="event"
                                                />
                                            ) : (
                                                <img src={Eventimg2} className="img-fluid" alt="event" />
                                                // <img src={profilesqrimg} className="divprflsqr" alt="prflsqr" />
                                            )}
                                        </div>
                                        <div className="event_details">
                                            <div
                                                className="d-flex justify-content-between"
                                                // style={{ marginBottom: "9px" }}
                                            >
                                                <div className="head">
                                                    <h2>{item?.eventName}</h2>
                                                </div>
                                                {/* {userData?._id === item?.addedBy && (
                        <div
                          className="dot_btn"
                          onClick={(e) => handleClick(e, item, index)}
                          style={{
                            position: "relative",
                            zIndex: "2",
                            left: "31px",
                            top: "50px",
                          }}
                        >
                          <a to="/" className="btn">
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                          </a>
                          {modalId === item?._id && modalA && (
                            <div
                              style={{
                                position: "absolute",
                                // overflow: "hidden",
                                backgroundColor: "#fff",
                                padding: "3px",
                                border: "none",
                                // top: "123px",
                                padding: "3px 8px",
                                right: "27px",
                                borderRadius: "4px",
                                cursor: "pointer",
                                boxShadow: "0 0 0.1rem 0",
                                top: "2px",
                                width: "53px",
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <i
                                style={{ marginRight: "7px", fontSize: "14px" }}
                                class="fas fa-edit"
                                onClick={() => setModalG(true)}
                              ></i>
                              <i
                                style={{
                                  margin: "3px",
                                  fontSize: "14px",
                                  color: "red",
                                }}
                                class="fa fa-trash"
                                aria-hidden="true"
                                onClick={handleDelete}
                              ></i>
                            </div>
                          )}
                        </div>
                      )} */}
                                                {/* <div style={{ fontSize: "20px", position: "relative", left: "7px" }}>
                        {item?.isHighLighted === "yes" ? "+" : ""}
                      </div> */}
                                            </div>
                                            <div className="hosted">
                                                <p>Hosted by {item?.eventAdmin}</p>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                                <div className="date">
                                                    <p>
                                                        Date: {moment(item?.eventDate).format("YYYY-MM-DD")}
                                                    </p>
                                                </div>
                                                {/* <div className="time">
                      <p>Time: 5:30 PM </p>
                    </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </>
                            )
                        })
                    }</>) : (<>No Subscribed Events</>)
                }
                {/* // {
                //     subscribed?.map((item,index)=>{
                //         return(
                //             <>
                //              <div className='card_area' style={{ marginBottom: "17px", background: "#EAEBFB" }}>
                //     <div className='event_img'>
                //         <img src={eventimg1} className="img-fluid" alt='event' />
                //     </div>
                //     <div className='event_details'>
                //         <div className='hrdrplsdot' style={{ marginBottom: "9px" }}>
                //             <div className='head'>
                //                 <h2>Cleaning Drive near Taj Mahal</h2>
                //             </div>
                //             <div className="plus_btn">
                //                 <button className="btn">
                //                     <i className="fa-solid fa-plus"></i>
                //                 </button>
                //             </div>
                //             <div className="dt" style={{ position: "relative" }}>
                //                 <Link to="/" className="">
                //                     <i className="fa-solid fa-ellipsis-vertical"></i>
                //                 </Link>
                //             </div>
                //         </div>
                        

                //         <div className='d-flex justify-content-between'>
                //             <div className='date'>
                //                 <p>Date: 16th Feb, 2022</p>
                //             </div>
                //             <div className='time'>
                //                 <p>Time: 5:30 PM</p>
                //             </div>
                //         </div>

                //     </div>
                // </div>
                //             </>
                //         )
                //     })
                // } */}
                {/* <div className='card_area' style={{ marginBottom: "17px", background: "#EAEBFB" }}>
                    <div className='event_img'>
                        <img src={eventimg1} className="img-fluid" alt='event' />
                    </div>
                    <div className='event_details'>
                        <div className='hrdrplsdot' style={{ marginBottom: "9px" }}>
                            <div className='head'>
                                <h2>Cleaning Drive near Taj Mahal</h2>
                            </div>
                            <div className="plus_btn">
                                <button className="btn">
                                    <i className="fa-solid fa-plus"></i>
                                </button>
                            </div>
                            <div className="dt" style={{ position: "relative" }}>
                                <Link to="/" className="">
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </Link>
                            </div>
                        </div>
                        

                        <div className='d-flex justify-content-between'>
                            <div className='date'>
                                <p>Date: 16th Feb, 2022</p>
                            </div>
                            <div className='time'>
                                <p>Time: 5:30 PM</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='card_area' style={{ marginBottom: "17px", background: "#FDEFE9" }}>
                    <div className='event_img'>
                        <img src={eventimg2} className="img-fluid" alt='event' />
                    </div>
                    <div className='event_details'>
                        <div className='d-flex justify-content-between align-items-start' style={{ marginBottom: "9px" }}>
                            <div className='head'>
                                <h2>Cyber Security Webinar</h2>
                            </div>
                            <div className='d-flex align-items-center mt-2'>
                                <div className='plus_btn'>
                                    <button className='btn'><i className="fa-solid fa-plus"></i></button>
                                </div>
                                <div className='dt' style={{ position: "relative" }}>
                                    <Link to="/" className='btn'><i className="fa-solid fa-ellipsis-vertical"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='hosted'>
                            <p>Hosted by company name</p>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <div className='date'>
                                <p>Date: 16th Feb, 2022</p>
                            </div>
                            <div className='time'>
                                <p>Time: 5:30 PM</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='card_area' style={{ background: "#FDEFE9" }}>
                    <div className='event_img'>
                        <img src={eventimg2} className="img-fluid" alt='event' />
                    </div>
                    <div className='event_details'>
                        <div className='d-flex justify-content-between align-items-start' style={{ marginBottom: "9px" }}>
                            <div className='head'>
                                <h2>Driving Road Safety - online session with Traffic officer</h2>
                            </div>
                            <div className='d-flex align-items-center mt-2'>
                                <div className='plus_btn'>
                                    <button className='btn'><i className="fa-solid fa-plus"></i></button>
                                </div>
                                <div className='dt' style={{ position: "relative" }}>
                                    <Link to="/" className='btn'><i className="fa-solid fa-ellipsis-vertical"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='hosted'>
                            <p>Hosted by company name</p>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <div className='date'>
                                <p>Date: 16th Feb, 2022</p>
                            </div>
                            <div className='time'>
                                <p>Time: 5:30 PM</p>
                            </div>
                        </div>

                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default MysubscribedEvents
