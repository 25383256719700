import React from 'react'
import DocumentRepository from "../../Images/Icons/PNG/DocumentRepository.png";
import Purchase from "../../Images/Icons/PNG/Purchase.png";
import IT from "../../Images/Icons/PNG/IT.png";
import operations from "../../Images/Icons/PNG/operations.png";
import management from "../../Images/Icons/PNG/management.png";
import Expand from "../../Images/Icons/PNG/Expand.png";
// import trainingmain2 from "../../Images/trainingmain2.png";
const DocumentRepositorymain = () => {
    return (
        <>
            <div className='videoongoing'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        {/* <figure className="bulleBoardFig">
                            <img
                                src={DocumentRepository}
                                alt="..."
                            />
                        </figure> */}
                        <h4>Latest Standard <br/>
                            Operating Procedure
                            (SOP)
                        </h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv">
                            <figure className="bulleBoardFig">
                                <img src={Expand} alt="..." />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='bottomrepoflx'>
                        <div className='purchaseimgdiv'>
                            <img src={Purchase} alt="..." />
                        </div>
                        <div className='Purchasedivflx'>
                            <div className='purtxtdiv'>
                                <p className='purtxt'>Purchase</p>
                            </div>
                            <div className='arttopsubtopic'>
                                {/* <div className=''>
                                    <h5 className='artcl'>5</h5>
                                    <p className='artctext'>Articles</p>
                                </div> */}
                                <div className=''>
                                    <h5 className='artcl'>5</h5>
                                    <p className='artctext'>Topics</p>
                                </div>
                                <div className=''>
                                    <h5 className='artcl'>15</h5>
                                    <p className='artctext'>Views</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='bottomrepoflx'>
                        <div className='purchaseimgdiv'>
                            <img src={IT} alt="..." />
                        </div>
                        <div className='Purchasedivflx'>
                            <div className='purtxtdiv'>
                                <p className='purtxt'>IT</p>
                            </div>
                            <div className='arttopsubtopic'>
                                <div className=''>
                                    <h5 className='artcl'>5</h5>
                                    <p className='artctext'>Articles</p>
                                </div>
                                <div className=''>
                                    <h5 className='artcl'>5</h5>
                                    <p className='artctext'>Topics</p>
                                </div>
                                <div className=''>
                                    <h5 className='artcl'>15</h5>
                                    <p className='artctext'>Sub Topics</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='bottomrepoflx'>
                        <div className='purchaseimgdiv'>
                            <img src={operations} alt="..." />
                        </div>
                        <div className='Purchasedivflx'>
                            <div className='purtxtdiv'>
                                <p className='purtxt'>Operations</p>
                            </div>
                            <div className='arttopsubtopic'>
                                <div className=''>
                                    <h5 className='artcl'>5</h5>
                                    <p className='artctext'>Articles</p>
                                </div>
                                <div className=''>
                                    <h5 className='artcl'>5</h5>
                                    <p className='artctext'>Topics</p>
                                </div>
                                <div className=''>
                                    <h5 className='artcl'>15</h5>
                                    <p className='artctext'>Sub Topics</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='bottomrepoflx'>
                        <div className='purchaseimgdiv'>
                            <img src={management} alt="..." />
                        </div>
                        <div className='Purchasedivflx'>
                            <div className='purtxtdiv'>
                                <p className='purtxt'>Management</p>
                            </div>
                            <div className='arttopsubtopic'>
                                <div className=''>
                                    <h5 className='artcl'>5</h5>
                                    <p className='artctext'>Articles</p>
                                </div>
                                <div className=''>
                                    <h5 className='artcl'>5</h5>
                                    <p className='artctext'>Topics</p>
                                </div>
                                <div className=''>
                                    <h5 className='artcl'>15</h5>
                                    <p className='artctext'>Sub Topics</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default DocumentRepositorymain
