import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import moment from 'moment';

const Projectviewemplymanager = () => {
    const [managercard, setManagercard] = useState(false);
    const [managerProjectData, setManagerProjectData] = useState([]);
    const navigate = useNavigate();
    const [mangeroptionchart, setmangeroptionchart] = useState( {
            series:[{
                name: "categories",
                data: []
            }],
            chart: {
                height: 180,
                type: 'bar',
            },
            fill: {
                opacity: 1,
                colors: ['#268ACC']
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
    
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20, // Adjusted to make sure labels are placed correctly
                style: {
                    fontSize: '12px',
                    colors: ['#268ACC'] // Adjusted to be readable on the bar color
                }
            },
            xaxis: {
                categories: ["Day "], // Added categories for each data point
                position: 'bottom',
                offsetY: -4,
                offsetX: 50, // Adjusted position to 'bottom'
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: true
                },
                labels: {
                    style: {
                        fontSize: '12px',
                        colors: ['#268ACC'],
                        textAlign: 'center'// Adjusted to be readable
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    style: {
                        fontSize: '12px',
                        colors: ['#268ACC'] // Adjusted to be readable
                    }
                }
            }
        })

    //get manager projects
    const getManagerProjectData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/fetch-manager-view`,
            method: "GET",
        });
        if (response && response.status) {
            const projectData = response?.data?.data.map(project => {
                const series = [{
                    name: project?.nameOfInitaitive,
                    data: project?.attendenceData?.map(item => item?.totalWorkHour)
                }];
                const categories = project?.attendenceData?.map(item => item?._id);

                return {
                    ...project,
                    chartOptions: {
                        series: series,
                        chart: {
                            height: 180,
                            type: 'bar',
                        },
                        fill: {
                            opacity: 1,
                            colors: ['#268ACC']
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 0,
                                dataLabels: {
                                    position: 'top', // top, center, bottom
                                },
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            offsetY: -20, // Adjusted to make sure labels are placed correctly
                            style: {
                                fontSize: '12px',
                                colors: ['#268ACC'] // Adjusted to be readable on the bar color
                            }
                        },
                        xaxis: {
                            categories: categories,
                            position: 'bottom',
                            offsetY: -4,
                            offsetX: 50, // Adjusted position to 'bottom'
                            axisBorder: {
                                show: true
                            },
                            axisTicks: {
                                show: true
                            },
                            // labels: {
                            //     style: {
                            //         fontSize: '12px',
                            //         colors: ['#268ACC'],
                            //         textAlign: 'center'// Adjusted to be readable
                            //     }
                            // },
                            tooltip: {
                                enabled: true,
                            }
                        },
                        yaxis: {
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false,
                            },
                            labels: {
                                show: false,
                                style: {
                                    fontSize: '12px',
                                    colors: ['#268ACC'] // Adjusted to be readable
                                }
                            }
                        }
                    }
                };
            });

            setManagerProjectData(projectData);
        }
    }


    const handleprojectview = (id) => {
        navigate(`/projectviewdetails/${id}`)
    }
    // const mangeroptionchart = {
    //     series: [{
    //         name: 'Inflation',
    //         data: [30, 60, 100, 150, 40, 7]
    //     }],
    //     chart: {
    //         height: 180,
    //         type: 'bar',
    //     },
    //     fill: {
    //         opacity: 1,
    //         colors: ['#268ACC']
    //     },
    //     plotOptions: {
    //         bar: {
    //             borderRadius: 0,
    //             dataLabels: {
    //                 position: 'top', // top, center, bottom
    //             },

    //         }
    //     },
    //     dataLabels: {
    //         enabled: true,
    //         offsetY: -20, // Adjusted to make sure labels are placed correctly
    //         style: {
    //             fontSize: '12px',
    //             colors: ['#268ACC'] // Adjusted to be readable on the bar color
    //         }
    //     },
    //     xaxis: {
    //         categories: ["Day "], // Added categories for each data point
    //         position: 'bottom',
    //         offsetY: -4,
    //         offsetX: 50, // Adjusted position to 'bottom'
    //         axisBorder: {
    //             show: true
    //         },
    //         axisTicks: {
    //             show: true
    //         },
    //         labels: {
    //             style: {
    //                 fontSize: '12px',
    //                 colors: ['#268ACC'],
    //                 textAlign: 'center'// Adjusted to be readable
    //             }
    //         },
    //         tooltip: {
    //             enabled: true,
    //         }
    //     },
    //     yaxis: {
    //         axisBorder: {
    //             show: false
    //         },
    //         axisTicks: {
    //             show: false,
    //         },
    //         labels: {
    //             show: false,
    //             style: {
    //                 fontSize: '12px',
    //                 colors: ['#268ACC'] // Adjusted to be readable
    //             }
    //         }
    //     }
    // };

    useEffect(() => {
        getManagerProjectData();
    }, [])
    return (
        <>

            {
                managerProjectData?.map((item, i) => {
                    return (
                        <div className='managercardbgdivmaiun' onClick={() => handleprojectview(item?._id)} key={i}>
                            <div className={managercard ? "managercardbgdiv actv" : "managercardbgdiv"}>
                                <div className='mangercarddivflxmain'>
                                    <div className="mangercarddivrightdata">
                                        <div className='projectnamecprojecttitldiv'>
                                            <p className='projectnamecardtxt'>Project</p>
                                            <h4 className='projecttitletxtxcdrtxt'>{item?.nameOfInitaitive}</h4>
                                        </div>
                                        <div className='projectnamecprojecttitldiv'>
                                            <p className='projectstarttxtppcrdt'>Start Date</p>
                                            <h4 className='projectstartdatetxtcarddatetyr'>{moment(item?.startDate)?.format("YYYY-MM-DD")}</h4>
                                        </div>
                                        <div className='projectnamecprojecttitldiv'>
                                            <p className='projectstarttxtppcrdt'>Last Update</p>
                                            <h4 className='projectstartdatetxtcarddatetyr'>
                                                {
                                                    item?.lastUpdatedDate !== null
                                                        ?
                                                        <>
                                                            {moment(item?.lastUpdatedDate)?.format("YYYY-MM-DD")}
                                                        </>
                                                        :
                                                        <>Not yet Started</>
                                                }
                                            </h4>
                                        </div>
                                        <div className='projectnamecprojecttitldiv'>
                                            <p className='projectstarttxtppcrdt'>Total Hours</p>
                                            <h4 className='projectstartdatetxtcarddatetyr'>{item?.attendenceData?.[0]?.totalWorkHour}h</h4>
                                        </div>
                                        <div className='breakupchviewbrancktcrsivflx'>
                                            <div className='breakupcheckdib'>
                                                <i class="fa-solid fa-chevron-right"></i>
                                            </div>
                                            <div className=''>
                                                <p className='viewbrancktcrpp'>View Breakup</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mangercarddivleftdata'>
                                        <div className=''>
                                        <Chart
                                        options={item.chartOptions}
                                        series={item.chartOptions.series}
                                        type="bar"
                                        height={item.chartOptions.chart.height}
                                    />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }


        </>
    )
}

export default Projectviewemplymanager
