import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// import { colourOptions } from "../data";
import Refer from "../../Images/Icons/PNG/Refer.png";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import toast from "react-hot-toast";
import { useAuthCtx } from "../../context/AuthCtx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const animatedComponents = makeAnimated();


const ReferModal = ({ closemodal1, id }) => {
    const params = useParams();
    const { setLoading } = useAuthCtx();
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const [empData, setempData] = useState([]);
    // console.log(params, "uioer")

    //for change selected options
    const handleSelectChange = (e) => {
        console.log("Selected Options:", e);

        // Update the colourOptions state with the selected options
        setSelectedOptions(e);
    };

    const submitHandler = async (e, selectedSubscription) => {
        e.preventDefault();
        setShouldValidateOnChange(true);
        setShouldValidateOnBlur(true);
        console.log("handleSubmit", values);

        let data = {};
        setLoading(true);
        if (values.userId == [""]) {
            return toast.error("All fields required");
        }

        data = {
            jobId: params?._id,
            userId: selectedOptions?.map((item) => item?.value),
        };

        console.log("click_affinity_group", data);
        const res = await ApiHelperFunction({
            urlPath: "/give-refferl",
            method: "POST",
            data,
        });
        if (res?.status) {
            toast.success(res?.data?.message);
            resetForm();
            // dispatch(getAffinityGroups());
            // getAffinityMyGroupData();
            // dispatch(getMyAffinityGroups());
            closemodal1();
        } else {
            toast.error(res?.message || "Something went wrong");
            console.log("ERROR CREATING USER3", res);
        }
        setLoading(false);
    };

    //   const schemaCheck = Yup.object(
    //     activity === "billBoard" ||
    //       activity === "events" ||
    //       activity === "kababMenu" ||
    //       activity === "editEvent"
    //       ? {
    //         eventName: Yup.string().required("Event Name is required"),
    //         hostedBy: Yup.string().required("Host name is required"),
    //         eventDate: Yup.string().required("Event date is required"),
    //         eventstarttime: Yup.string().required("start Time is required"),
    //         eventendtime: Yup.string().required(" End Time is required"),
    //         notes: Yup.string().required(" Notes is required"),
    //         addinvites: Yup.string().required(" Addinvites is required"),
    //         lattitude: Yup.string().required(" lattitude is required"),

    //         longitude: Yup.string().required(" longitude is required"),
    //       }
    //       : activity === "training" || activity === "editTraining"
    //         ? {
    //           name: Yup.string().required("Event Name is required"),
    //           trainingDate: Yup.string().required("Training date is required"),
    //           link: Yup.string().required("Training link is required"),
    //         }
    //         : activity === "affinity" || activity === "editAffinity"
    //           ? {
    //             groupName: Yup.string().required("Affinity Group Name is required"),
    //             grouptype: Yup.string().required("Affinity Group Type is required"),
    //             charter: Yup.string().required("Affinity Group Charter is required"),
    //             purpose: Yup.string().required("Affinity Group Purpose is required"),
    //           }
    //           : activity === "jobReffered"
    //             ? {
    //               name: Yup.string().required("Job Name is required"),
    //               location: Yup.string().required("Job location is required"),
    //               salary: Yup.string().required("Salary range is required"),
    //               description: Yup.string().required("Description range is required"),
    //               opening: Yup.string().required("Opening is required"),
    //             }
    //             : ""
    //   );


    //get all employee data
    const getEmployeeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-all-employees`,
            method: "GET",
        });

        console.log("RESPONSEF", response?.data?.data);

        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
            }));

            setColourOptions(formattedOptions);
            setempData(response?.data?.data);
        } else {
            // toast.error(response.message);
        }
    };


    const { values, errors, handleBlur, handleSubmit, resetForm, handleChange } =
        useFormik({
            initialValues: {
                userId: []
            },
            //   validationSchema: schemaCheck,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

    useEffect(() => {
        getEmployeeData();
        // dispatch(getMyAffinityGroups());
        // getAffinityMyGroupData();
    }, []);

    return (
        <>
            <div className="refermodaldiv">
                <div className="refermodaldivcontent">
                    <div className="refermpodalflx">
                        <h2 className="rfrtxtpp">Refer Modal</h2>
                        <div className="crssdicv" onClick={() => closemodal1()}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div className="selectdivmain">
                        <div className="selectdiv">
                            <p className="emplselect">Employee Name</p>
                            <div className="selectmaindiv">
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    defaultValue={[]}
                                    isMulti
                                    options={colourOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <button className="referbtnflx" type="button"
                                onClick={submitHandler}>
                                <div className="shrimg">
                                    <img src={Refer} alt="..." />
                                </div>
                                <p className="rfshr"> Refer</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReferModal