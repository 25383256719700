import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

const initialState = {
    status: 'idle',
    isLoading: false,
    isSuccess: false,
    isError: false,
    subscription: [],

}

export const getSubscriptionEventData = createAsyncThunk(
    'get_subscription_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-subscribed-event", method: 'GET' })

        if (response?.status) {
            console.log(response?.data, "responsety")
            return response?.data;

        } else {
            // toast.error('Error fetching badge data')
        }
    }
);

export const subscriptionEventSlice = createSlice({
    name: "subscription_actions",
    initialState,
    reducers: {
        clearSubscriptionEventData: (state) => {
            state.status = "idle";
            state.subscription = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSubscriptionEventData.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getSubscriptionEventData.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                // console.log('payload', payload);
                state.subscription = payload?.data?.reverse();
            })
            .addCase(getSubscriptionEventData.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            });
    }

})
export const { clearSubscriptionEventData } = subscriptionEventSlice.actions;
export default subscriptionEventSlice.reducer;