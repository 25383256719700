import React, { useEffect } from 'react'
// import trainingmain2 from "../../Images/trainingmain2.png";
// import Points from "../../Images/Icons/PNG/Points.png"
import { useDispatch, useSelector } from 'react-redux';
import { getOnGoingTraining } from '../../redux/slices/jobRecruitmentSlice';
import { useNavigate } from 'react-router-dom';


const Singleeducateongoing = () => {
    const ongoing = useSelector((state) => state?.jobRecruitmentSlice?.ongoing)
    const dispatch = useDispatch();
    const navigate = useNavigate();


    //for play button
    const handlevideoClick = (item) => {
        navigate(`/enlargedNewEnrollment/${item?.trainingId?.title?.replace(/\s+/g, '-')}/${item?.trainingId?._id}`)
        // navigate(`/coursefirstVideoPlayer/${item?.title?.replace(/\s+/g, '-')}/${item?._id}/${item?.videos?.[0]?._id}`)
    }


    useEffect(() => {
        dispatch(getOnGoingTraining());
    }, [])


    console.log("ongoingklop", ongoing)
    return (
        <>
            {ongoing?.map((item, index) => {
                return (
                    <div className='videoongoinddiv' key={index}>
                        <div className=''>
                            <div className='video_onflx'>
                                <div className='trainingmainimgdiv'>
                                    <img src={item?.trainingId?.image} alt="..." />
                                </div>
                                <div className='playtextbtnvwwh'>
                                    <div className='playtxtflx'>
                                        <p className='lrmtxtppeducate'>{item?.trainingId?.title}</p>
                                        <div className=''>
                                            <button className='bg-play' onClick={() => handlevideoClick(item)}>
                                                Play
                                            </button>
                                        </div>

                                    </div>
                                    {/* <div className=''>
                                        <p className="dotstxtpp"><span className="dotsdiv"></span>{item?.notification}</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="prgrsbartxtdivpading">
                            <div className='prgrsbartxtdiv'>
                                <div className=''>
                                    <p className='prgrstextper'>{item?.playTimePercent}%</p>
                                </div>
                                {/* <div className='imgtxtppdivprgrs'>
                                    <div className='prgrsdivimg'>
                                        <img src={item?.imagepoints} alt="..." />
                                    </div>
                                    <p className='prgrstextper'>{item?.points}</p>
                                </div> */}
                            </div>

                            <div className="borderprgs">
                                <div className="brdrgrey" style={{ height: "6px", width: `${item?.playTimePercent}%` }}>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default Singleeducateongoing
