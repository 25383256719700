import React from 'react'
import Backarrow from "../../Images/Icons/PNG/Backarrow.png";
import video from "../../Images/Icons/PNG/video.png";
import EducateStatusCard from '../TrainingPage/EducateStatusCard';
import Savetrainningjob from './Savetrainningjob';
import SaveTrainingCard from './SaveTrainingCard';
import { useNavigate } from 'react-router-dom';

const SaveAllTraining = () => {
const navigate=useNavigate();
    return (
        <section className='bulleDetail'>
            <div className='container'>
                
                    {/* <div className='singlemandatoryleft'>
                        <div className=''>
                            <EducateStatusCard />
                        </div>
                        <div className=''>
                            <Savetrainningjob />
                        </div>
                    </div> */}

                   
                        <div className='ongoingbgdiv'>
                            <div className="top">
                                <div className="head bulleBoardHead">
                                    <figure className="bulleBoardFig" >
                                        <img
                                            src={video}
                                            alt="..."

                                        />
                                    </figure>
                                    <h4>Saved Training</h4>
                                </div>
                                <div className="add_seeallflx">
                                    <div className="tyEvMainDiv" onClick={()=>navigate(-1)}>
                                        <figure className="bulleBoardFig" >
                                            <img src={Backarrow} alt="..." />
                                        </figure>
                                    </div>
                                </div>
                            </div>

                            <div className='SaveTrainingCardbtbdiv'>
                                <SaveTrainingCard />
                            </div>

                        </div>
                    
                

            </div>
        </section>
    )
}

export default SaveAllTraining
