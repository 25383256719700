import React from 'react'
import trainingmain2 from "../../Images/trainingmain2.png";
import star from "../../Images/Icons/PNG/star.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward.png";
const Singlerecomendedcard = () => {
    return (
        <>
            <div className='rtrainingmainrecomendeddivbg'>
                <div className='rtrainingmainrecomended'>
                    <img src={trainingmain2} alt="..." />
                </div>
                <div className=''>
                    <p className='recomdedtxytppsngl'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi, vitae.</p>
                    <p className=''>Lorem, ipsum dolor.</p>
                </div>
                <div className="imgtxtdivmaindddflx">
                    <div className="imgtxtdivmainddd">
                        <div className="strimgdiv">
                            <img
                                src={star}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">4.9</p>
                    </div>
                    <div className="imgtxtdivmainddd">
                        <div className="strimgdiv">
                            <img
                                src={clock}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">1h 20m</p>
                    </div>
                    <div className="imgtxtdivmainddd">
                        <div className="strimgfrmt">
                            <img
                                src={reward}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">200</p>
                    </div>
                </div>
            </div>
            <div className='rtrainingmainrecomendeddivbg'>
                <div className='rtrainingmainrecomended'>
                    <img src={trainingmain2} alt="..." />
                </div>
                <div className=''>
                    <p className='recomdedtxytppsngl'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi, vitae.</p>
                    <p className=''>Lorem, ipsum dolor.</p>
                </div>
                <div className="imgtxtdivmaindddflx">
                    <div className="imgtxtdivmainddd">
                        <div className="strimgdiv">
                            <img
                                src={star}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">4.9</p>
                    </div>
                    <div className="imgtxtdivmainddd">
                        <div className="strimgdiv">
                            <img
                                src={clock}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">1h 20m</p>
                    </div>
                    <div className="imgtxtdivmainddd">
                        <div className="strimgfrmt">
                            <img
                                src={reward}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">200</p>
                    </div>
                </div>
            </div>
            <div className='rtrainingmainrecomendeddivbg'>
                <div className='rtrainingmainrecomended'>
                    <img src={trainingmain2} alt="..." />
                </div>
                <div className=''>
                    <p className='recomdedtxytppsngl'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi, vitae.</p>
                    <p className=''>Lorem, ipsum dolor.</p>
                </div>
                <div className="imgtxtdivmaindddflx">
                    <div className="imgtxtdivmainddd">
                        <div className="strimgdiv">
                            <img
                                src={star}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">4.9</p>
                    </div>
                    <div className="imgtxtdivmainddd">
                        <div className="strimgdiv">
                            <img
                                src={clock}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">1h 20m</p>
                    </div>
                    <div className="imgtxtdivmainddd">
                        <div className="strimgfrmt">
                            <img
                                src={reward}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">200</p>
                    </div>
                </div>
            </div>
            <div className='rtrainingmainrecomendeddivbg'>
                <div className='rtrainingmainrecomended'>
                    <img src={trainingmain2} alt="..." />
                </div>
                <div className=''>
                    <p className='recomdedtxytppsngl'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi, vitae.</p>
                    <p className=''>Lorem, ipsum dolor.</p>
                </div>
                <div className="imgtxtdivmaindddflx">
                    <div className="imgtxtdivmainddd">
                        <div className="strimgdiv">
                            <img
                                src={star}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">4.9</p>
                    </div>
                    <div className="imgtxtdivmainddd">
                        <div className="strimgdiv">
                            <img
                                src={clock}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">1h 20m</p>
                    </div>
                    <div className="imgtxtdivmainddd">
                        <div className="strimgfrmt">
                            <img
                                src={reward}
                                alt="..."
                            />
                        </div>
                        <p className="tsgyutppp">200</p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Singlerecomendedcard
