import React, { useEffect, useState } from "react"
import EducateStatusCard from "./EducateStatusCard"
import Ongoing from "./Ongoing"
import StandardOperatingProsedure from "./StandardOperatingProsedure";
import trainingmain from "../../Images/trainingmain.png"
import star from "../../Images/Icons/PNG/star.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward.png";
import { getOnboardingTraining } from "../../redux/slices/jobRecruitmentSlice";
import { useDispatch, useSelector } from "react-redux";
import MicroCousremodal from "../../Component/Modal/MicroCousemodal";
const SingleMicroCourse = () => {
    const microTraining = useSelector((state) => state?.jobRecruitmentSlice?.microTraining)
    const [singleMandatory, setSingleMandatory] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOnboardingTraining())
    }, [])


    const [videotraing, setVideotraing] = useState(false);
    const handlevideoClick = (item) => {
        setSingleMandatory(item)
        setVideotraing(!videotraing)
    }
    
    return (
        <>
            <section className='singlemandatorysection'>
                <div className='employeepagecontainer'>
                    <div className='singleeducatemainflx'>
                        <div className='singlemandatoryright'>
                            <div className='singlemandatorydivbg'>
                                <div className='mandatorydivbgflxdiv'>
                                    {microTraining?.map((item, index) => {
                                        return (
                                            <div className='mandatorydivbg mandatorywidthdiv' key={index} onClick={() => handlevideoClick(item)}>
                                                <div className='traingmaindivmain'>
                                                    {/* <img src={item.image} alt="..." /> */}
                                                    {
                                                        (item?.image === "images" || item?.image === "" || item?.image === "image") ? (<div className='traingmaindivmain'>
                                                            <img src={trainingmain} alt="..." />
                                                        </div>) : (<div className='traingmaindivmain'>
                                                            <img src={item?.image} alt="..." />
                                                        </div>)
                                                    }
                                                </div>
                                                <div className=''>
                                                    <p className='mandttxtpp'>
                                                        {item.description}
                                                    </p>
                                                    <p className='writertxtpp'>{item.userName}</p>
                                                </div>
                                                <div className="imgtxtdivmaindddflx">
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgdiv">
                                                            <img
                                                                src={star}
                                                                alt="..."
                                                            />
                                                        </div>
                                                        <p className="tsgyutppp">{item.rating ? (<>{item.rating}</>) : ("0")}</p>
                                                    </div>
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgdiv">
                                                            <img
                                                                src={clock}
                                                                alt="..."
                                                            />
                                                        </div>
                                                        {/* {item?.videos?.[0]?.videoDuration ? (<p className="tsgyutppp">{item?.videos?.[0]?.videoDuration?.hour ? (item?.videos?.[0]?.videoDuration?.hour) : ("00")} h {item?.videos?.[0]?.videoDuration?.min ? (item?.videos?.[0]?.videoDuration?.min) : ("00")}min</p>) : (<p className="tsgyutppp">{item?.placeTime?.[0]?.duration?.hour ? (item?.placeTime?.[0]?.duration?.hour) : ("00")} h {item?.placeTime?.[0]?.duration?.min ? (item?.placeTime?.[0]?.duration?.min) : ("00")}min</p>)} */}
                                                        {item?.totalDurationInMinute} min
                                                    </div>
                                                    <div className="imgtxtdivmainddd">
                                                        <div className="strimgfrmt">
                                                            <img
                                                                src={reward}
                                                                alt="..."
                                                            />
                                                        </div>
                                                        <p className="tsgyutppp">{item.trainingReward}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>

                            </div>
                        </div>
                        <div className='singlemandatoryleft'>
                            <div className=''>
                                <EducateStatusCard />
                            </div>
                            <div className=''>
                                <Ongoing />
                            </div>
                            <div className=''>
                                <StandardOperatingProsedure />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {videotraing && <MicroCousremodal closemodal={setVideotraing} singleMandatory={singleMandatory} />}
        </>
    )
}

export default SingleMicroCourse