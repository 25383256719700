import React, { useRef, useState } from 'react'
import { NavLink } from "react-router-dom";
import employeelerning from "../../Images/employeelerning.png";
import Desktopandphone from "../../Images/Desktopandphone.png";
import employerreputationn from "../../Images/headericon/employerreputationn.png";
import Mentor from "../../Images/headericon/Mentor.png";
import culture from "../../Images/headericon/culture.png";
import SevenEmployeeeperiencenerves from "../../Images/SevenEmployeeeperiencenerves.png";
import bulletinboardimgekljk from "../../Images/bulletinboardimgekljk.png";
import Openintervueimg from "../../Images/Openintervueimg.png";
import aiimagemain from "../../Images/aiimagemain.png";
import Security from "../../Images/Security.png";
import securityimage from "../../Images/securityimage.png";
import Access from "../../Images/headericon/Access.png";
import Authentication from "../../Images/headericon/Authentication.png"
import Securityicon from "../../Images/headericon/Securityicon.png"
import Landingheader from './Landingheader';
import mongo from "../../Images/mongo.png";
import securityloxk from "../../Images/securityloxk.png";
import securitylockprivt from "../../Images/securitylockprivt.png"
import Eliteexperinceheader from './Eliteexperinceheader';
import elitevideo from "../../Images/video/elitevideo.mp4";
import Addicon from "../../Images/headericon/Addicon.png";
import PlayIcon from "../../Images/headericon/PlayIcon.png";
import PauseIcon from "../../Images/headericon/PauseIcon.png";
const EliteExperienceHub = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleButtonClick = () => {

        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };
    return (
        <>
            {/* <section className="topheaderland">
                <p className="rtxtpphgt">Together Possibilities are infinite</p>
            </section> */}

            <Eliteexperinceheader />
            {/* <section className="elitevideosection">
                <div className="elitevideosectiondiv">
                    <video controls autoplay>
                        <source src={elitevideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </section> */}
            <section className="landingexperincehbfrxst">
                <div className="landingpageconatiner">
                    <div className="landingfinalflxfst">
                        <div className="landingexperivhbfrstctiondiv">
                            <h4 className="landingsctionfrstpp">management by experience is the secret to high performance culture
                            </h4>
                            <div className="landinexperindfrstsecparabtnmain">
                                <p className="landingfsrtsctntxtpp">Employee experience encapsulates what people encounter and
                                    observe over the course of their tenure in the organization, it is the
                                    Moments That Matter
                                </p>
                                <div className="">
                                    <div className="nameemldivflx">
                                        <div className="namedibsbjk">
                                            <input type="text" placeholder="Name" />
                                        </div>
                                        <div className="namedibsbjk">
                                            <input type="text" placeholder="Email" />
                                        </div>
                                    </div>
                                    <button className="demobtn" style={{ backgroundColor: "#012060", color: "#fff" }}>Book For A Demo</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section >

            <section className="elitevideosection">
                <div className="landingpageconatiner">
                    <div className="landingfinalflxfst">
                        <div className="elitevideosectiondivlrft">
                        <div className="elitevideosectiondivmain">
                        <div className="elitevideosectiondivmain">
                <div className="elitevideosectiondiv">
                  <video
                    ref={videoRef}
                    controls={false}
                  >
                    <source src={elitevideo} type="video/mp4" />
                  </video>
                  <button onClick={handleButtonClick} className="bhbsbvidpoim">
                    {isPlaying ?

                      <>
                        <div className="addiconimhj">
                          <img src={PauseIcon} alt="..." />
                        </div>
                      </>
                      :
                      <>
                        <div className="addiconimhj">
                          <img src={PlayIcon} alt="..." />
                        </div>
                      </>

                    }
                  </button>
                </div>
              </div>
              </div>
                        </div>

                        <div className="elitevideosectiondivrighty">
                            <h4 className="landingsctionfrstpp" style={{ color: "#000" }}>Lorem ipsum, dolor sit amet consectetur </h4>
                            <p className="landingfsrtsctntxtpp" style={{ color: "#000" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa animi,</p>
                        </div>

                    </div>
                </div>
            </section>

            <section className="landingexperincehubscnd">
                <div className="landingpageconatiner">
                    <div className="landingxeprncscndflx">
                        <div className="lamdingexprcefrscsctnbnth">
                            <div className="landinfgexprncehbbento">
                                <div className=" bntohubexptbjbent landinexptnvbentofrst" style={{ background: "linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%)" }}>
                                    <p className="hubsrchtstxbntosbdppp">Employer
                                        Branding
                                    </p>
                                </div>
                                <div className=" bntohubexptbjbent landinexptnvbentoscnd">
                                    <p className="hubsrchtstxbntosbdppp" style={{ color: "#000" }}>Employee
                                        Learning

                                    </p>

                                </div>
                                <div className=" bntohubexptbjbent landinexptnvbentothrd" style={{ background: "linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%)" }}>
                                    <p className="hubsrchtstxbntosbdppp" >Employee
                                        Value Proposition

                                    </p>
                                </div>
                                <div className=" bntohubexptbjbent landinexptnvbentofrth">
                                    <p className="hubsrchtstxbntosbdppp" style={{ color: "#000" }}>Employee
                                        Hiring

                                    </p>
                                </div>
                                <div className=" bntohubexptbjbent landinexptnvbentoffth">
                                    <p className="hubsrchtstxbntosbdppp" style={{ color: "#000" }}>Employer
                                        Advocacy

                                    </p>
                                </div>
                                <div className=" bntohubexptbjbent landinexptnvbentosxth" style={{
                                    background: "linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%)"
                                }}  >
                                    < p className="hubsrchtstxbntosbdppp" > Employee
                                        Communications
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="lamdingexprcefrscsctnbnthimg">
                            <div className="Desktopandphoneimgmaibn">
                                <img src={Desktopandphone} alt="..." />
                            </div>
                        </div>
                    </div>
                </div >
            </section >

            <div>
                <section className="landingexperincehubthrd">
                    <div className="landingpageconatiner">
                        <div className="landingexprncvgthfty">
                            <h4 className="landingsctionfrstpp" style={{ textAlign: "center" }}>employer branding
                            </h4>
                            <p className="landingfsrtsctntxtpp" style={{ textAlign: "center" }}>Stand out with an authentic, and inspiring employer brand that attracts the talent you need to win.
                            </p>
                        </div>
                        <div className="badgeattrctafnttyflx">
                            <div className="bgbadgettdiv">
                                <div className="bgbadgettdivcrcl">
                                    <div className="bgbadgettdivcrclimg">
                                        <img src={employerreputationn} alt="..." />
                                    </div>
                                </div>
                                <div className="txtpvjlbagtppmindiv">
                                    <p className="txtpvjlbagtpp">perception shapes talent attraction, retention, company culture alignment
                                    </p>
                                </div>
                            </div>
                            <div className="bgbadgettdiv">
                                <div className="bgbadgettdivcrcl">
                                    <div className="bgbadgettdivcrclimg">
                                        <img src={Mentor} alt="..." />
                                    </div>
                                </div>
                                <div className="txtpvjlbagtppmindiv">
                                    <p className="txtpvjlbagtpp">unique benefits, attract, retain talent, enhance engagement
                                    </p>
                                </div>
                            </div>
                            <div className="bgbadgettdiv">
                                <div className="bgbadgettdivcrcl">
                                    <div className="bgbadgettdivcrclimg">
                                        <img src={culture} alt="..." />
                                    </div>
                                </div>
                                <div className="txtpvjlbagtppmindivlst">
                                    <p className="txtpvjlbagtpp">workplace culture, environment, growth opportunities, engagement, retention focus
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="landingexperincehubthrdwth">
                    <div className="landingexperincehubthrpdding">
                        <div className="">
                            <p className="cndct">Conduct Your</p>
                            <h4 className="emplyrtxtppnj">Employer Brand Assessment
                            </h4>
                            <p className="nwuyyxjhp">Now!
                            </p>
                        </div>
                        <div className="">
                            <button className="strderbtn">Get Started</button>
                        </div>
                    </div>
                </section>
            </div>

            <section className="landingexoerincehubfrth">
                <div className="landingpageconatiner">

                    <div className="landingexoerincehubfrthflx">
                        <div className="landingexoerincehubfrthsctnhgi">
                            <h4 className="landingsctionfrstpp">employee value proposition
                            </h4>
                            <p className="landingfsrtsctntxtpp">Increase Employee value proposition
                                resonating in all the across organizational
                                functions
                            </p>
                        </div>
                        <div className="landingexoerincehubfrthsctimg">
                            <div className="SevenEmployeeepehhkimg">
                                <img src={SevenEmployeeeperiencenerves} alt="..." />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="landingexoerincehubffth">
                <div className="landingpageconatiner">
                    <div className="landingexoerincehubffthflx">
                        <div className="landingexoerincehubffthsctntxtu">
                            <h4 className="landingsctionfrstpp">ignite ideas
                                with elite communications
                            </h4>
                            <p className="landingfsrtsctntxtpp">Drive performance, culture, change and foster belonging with EVP focused communications
                            </p>
                            <ul className="lamdimnghubxcginmuol">
                                <li className="lamdimnghubxcginm">
                                    <NavLink to="/" className="lamdimnghubxcginmlnhj">Corporate Social Communication
                                    </NavLink>
                                </li>
                                <li className="lamdimnghubxcginm">
                                    <NavLink to="/" className="lamdimnghubxcginmlnhj">Affinity group

                                    </NavLink>
                                </li>
                                <li className="lamdimnghubxcginm">
                                    <NavLink to="/" className="lamdimnghubxcginmlnhj">Bulletin board

                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="landingexoerincehubffthsctnimg">
                            <div className="bulletinboardimgekljkimhj">
                                <img src={bulletinboardimgekljk} alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="landingexoerincehubsxthmin">
                <div className="landingexoerincehubsxth">
                    <div className="landingpageconatiner">
                        <div className="landingexoerincehubsxthflx">
                            <div className="landingexoerincehubsxmaindivbjhj">
                                <h4 className="landingsctionfrstpp">AI enhanced
                                    hiring with
                                </h4>
                                <h4 className="landingsctionfrstpppop">Openintervue
                                </h4>
                                <p className="landingfsrtsctntxtpp">superior hiring experience builds long term employee association and reduces attrition
                                </p>
                                <p className="cndctjkpbi"> Comming Soon...</p>
                            </div>
                            <div className="landingexoerincehubsxmaindiimgjb">
                                <div className="Openintervueimgdibimh">
                                    <img src={Openintervueimg} alt="..." />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="aiimagemainimgsiv">
                    <img src={aiimagemain} alt="..." />
                </div>
            </section>

            <div>
                <section className="landingexperincehubsnvth">
                    <div className="landingpageconatiner">
                        <div className="">
                            <h4 className="landingsctionfrstpp" style={{ textAlign: "center" }}>influence your advocacy like an elite

                            </h4>
                            <div className="landingfsrtsctntxtppdiv">
                                <p className="landingfsrtsctntxtpp" style={{ textAlign: "center" }}>Drive employee advocacy, design your own customized surveys and move towards Elite workplace and alternative certifications
                                </p>
                            </div>
                        </div>
                        <div className="badgeattrctafnttyflx">
                            <div className="bgbadgettdivrpt" style={{ background: "linear-gradient(167deg, rgb(237, 59, 76) 0%, rgb(113, 48, 160) 81%)", border: "4px solid #ffff" }}>
                                <div className="bgbadgettdivcrcl">
                                    <div className="bgbadgettdivcrclimg">
                                        <img src={employerreputationn} alt="..." />
                                    </div>
                                </div>
                                <div className="txtpvjlbagtppmindivrpt">
                                    <p className="txtpvjlbagtpp" style={{ color: "#fff" }}>
                                        Design the appearance to match your corporate guidelines and set the submission process
                                    </p>
                                </div>
                            </div>
                            <div className="bgbadgettdivrpt" style={{ background: "linear-gradient(167deg, rgb(237, 59, 76) 0%, rgb(113, 48, 160) 81%)", border: "4px solid #ffff" }}>
                                <div className="bgbadgettdivcrcl">
                                    <div className="bgbadgettdivcrclimg">
                                        <img src={Mentor} alt="..." />
                                    </div>
                                </div>
                                <div className="txtpvjlbagtppmindivrpt">
                                    <p className="txtpvjlbagtpp" style={{ color: "#fff" }}>Leverage and drive to a better employee advocacy to build a supreme corporate appearance

                                    </p>
                                </div>
                            </div>
                            <div className="bgbadgettdivrpt" style={{ background: "linear-gradient(167deg, rgb(237, 59, 76) 0%, rgb(113, 48, 160) 81%)", border: "4px solid #ffff" }}>
                                <div className="bgbadgettdivcrcl">
                                    <div className="bgbadgettdivcrclimg">
                                        <img src={culture} alt="..." />
                                    </div>
                                </div>
                                <div className="txtpvjlbagtppmindivrpt">
                                    <p className="txtpvjlbagtpp" style={{ color: "#fff" }}>Design the appearance to match your corporate guidelines and set the submission process

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="landingexperincehubsnvthdwth">
                    <div className="">
                        <div className="">
                            <p className="cndctjkpbi" style={{ textAlign: "center" }}>What to know more about</p>
                            <h4 className="emplyrtxtppnj" style={{ textAlign: "center" }}>elite employee certification?</h4>
                        </div>
                        <div className="" style={{ margin: "20px auto", display: "flex", justifyContent: "center" }}>
                            <button className="strderbtn">Get Started</button>
                        </div>
                    </div>
                </section>
            </div>

            <section className="landingexperincehbegth">
                <div className="landingpageconatiner">
                    <div className="landingexprcnedivflx">
                        <div className="landingexprcnrdivsctmnfrst">
                            <h4 className="landingsctionfrstpp">empower growth, elite through learning
                            </h4>
                            <p className="landingfsrtsctntxtpp">Drive employee advocacy, design, surveys, and move towards elite workplace certification
                            </p>
                        </div>
                        <div className="landingexprncrdivsctmnscnd">
                            <div className="landibimhdmmainemploiying">
                                <img src={employeelerning} alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="landingexperincehbnnth">
                <div className="landingpageconatiner">
                    <div className="landingsxthdivmainflx">
                        <div className="landingdibfsxthfrstsectn">
                            <h4 className="landingsctionfrstpp" style={{ color: "#fff" }}>Elite helps you win
                                in the marketplace </h4>
                            <p className="landingfsrtsctntxtpp" style={{ color: "#fff" }}>Higher Productivity and efficiency at reduced cost, reduce attrition, increasing employee moral, advocacy and loyalty. </p>
                        </div>

                        <div className="landingdibfsxthscndsectn">
                            <div className="landingrfsrttgriddiv">
                                <div className=" grditmmain landinghmsxthgridfrst">
                                    <p className="elthlpsprct" style={{ color: "#000" }}>30%</p>
                                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "16px", paddingBottom: "3px" }}>Improved internal communication
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridscnd" style={{ background: "linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%)" }}>
                                    <p className="elthlpsprct" style={{ color: "#fff" }}>20%</p>
                                    <p className="elcttxthlptxtp" style={{ color: "#fff", paddingTop: "16px", paddingBottom: "3px" }}>Reduction in
                                        Attrition Rate
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridthrd">
                                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "19px" }}>Increased
                                        Employee
                                        Engagement
                                        and
                                        motivation
                                    </p>
                                    <p className="elthlpsprctonnotxt" >
                                        30%
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridfrth" style={{ background: "linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%)" }}>
                                    <p className="elthlpsprct" style={{ color: "#fff" }}>30%</p>
                                    <p className="elcttxthlptxtp" style={{ color: "#fff", paddingTop: "16px" }}>Reduced
                                        Hiring Cost
                                    </p>
                                </div>
                                <div className=" grditmmainonno landinghmsxthgridffth" >
                                    <p className="elcttxthlptxtp" style={{ textAlign: "start", color: "#000" }}>Improvement in Talent Intake
                                        Quality
                                    </p>
                                    <p className="elthlpsprct" style={{ color: "#000" }}>20%
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridsxth">
                                    <p className="elthlpsprctonno" >20%
                                    </p>
                                    <p className="elcttxthlptxtpemnhop" style={{}}>Increase
                                        Employer
                                        Brand Recall

                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridsvnth" style={{ background: "linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%)" }}>
                                    <p className="elthlpsprct" style={{ color: "#fff" }}>
                                        10%
                                    </p>
                                    <p className="elcttxthlptxtp" style={{ color: "#fff", paddingTop: "16px" }}>Increased
                                        Customer
                                        Satisfaction

                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridegth" style={{ flexDirection: "row", columnGap: "32px", background: "linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%)", alignItems: "center" }}>
                                    <p className="elthlpsprct" style={{ color: "#fff" }}>10%</p>
                                    <p className="elcttxthlptxtp" style={{ textAlign: "end", color: "#fff" }}>Hiring at Reduced
                                        Salary (paying non-premium)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="landingexperincehbntntth">
                <div className="landingpageconatiner">
                    <div className="">
                        <h4 className="landingsctionfrstpp" style={{ textAlign: "center" }}>
                            built with cutting-edge tech and robust security features
                        </h4>
                    </div>
                    <div className="Securityimgiaon">
                        <img src={Security} alt="..." />
                    </div>

                    <div className="securitydivmainflx">
                        <div className="securitydivmainnbg">
                            <div className="securityimageimhmaindfkjndiv">
                                <div className="securityimageimhmaindfkjn">
                                    <img src={securityimage} alt="..." />
                                </div>
                            </div>
                            <div className="">
                                <p className="securitybigtxtpp" style={{ color: "#000", margin: "15px 0px", textAlign: "center" }}>Active Directory is to enable administrators to manage permissions</p>

                            </div>
                        </div>
                        <div className="securitydivmainnbg">

                            <div className="accessimghmaintxtflx">
                                <div className="accessimghmain">
                                    <img src={Access} alt="..." />
                                </div>
                                <div className=""></div>
                                <p className="roletxtpbjpp">ROLE BASED ACCESS CONTROLS
                                </p>
                            </div>
                            <div className="">
                                <p className="securitybigtxtpp" style={{ color: "#000", margin: "15px 0px", textAlign: "center" }}>Elite Admin Cockpit enables you to give access only to relevant teams or individuals so that your data stays in the right hands.
                                </p>

                            </div>
                        </div>
                        <div className="securitydivmainnbg">
                            <div className="accessimghmaintxtflx">
                                <div className="accessimghmain">
                                    <img src={Authentication} alt="..." />
                                </div>
                                <div className=""></div>
                                <p className="roletxtpbjpp">TWO-FACTOR AUTHENTICATION

                                </p>
                            </div>
                            <div className="">
                                <p className="securitybigtxtpp" style={{ color: "#000", margin: "15px 0px", textAlign: "center" }}>Two-factor authentication. Apart from your username and password, users have to input an authentication code for login.
                                </p>

                            </div>
                        </div>
                        <div className="securitydivmainnbg">
                            <div className="accessimghmaintxtflx">
                                <div className="accessimghmain">
                                    <img src={Securityicon} alt="..." />
                                </div>
                                <div className=""></div>
                                <p className="roletxtpbjpp">SECURE
                                    PASSWORD
                                    MANAGEMENT
                                </p>
                            </div>
                            <div className="">
                                <p className="securitybigtxtpp" style={{ color: "#000", margin: "15px 0px", textAlign: "center" }}>Secure password sharing Password management plays a vital role in preserving data and employee privacy.
                                    securitybigtxtpp</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <sectipn className="landingexperincehbntfrthn">
                <div className='landingpageconatiner'>
                    <div className=''>
                        <h4 className='landingsctionfrstpp' style={{ textAlign: "center" }}>
                            your data is safe with us

                        </h4>
                    </div>
                    <div className='datasafeflx'>
                        <div className='encryptiondivbng'>
                            <div className='encryptiontfrtyjjhbkpp'>
                                <p className='encryptiontfrtyjjhbkppmk'>ENCRYPTION AT REST
                                </p>
                            </div>
                            <div className='enxcrtoanarstdivbg'>
                                <div className='enxcrtoanarstdivbgtxtimg'>
                                    <p className='encryptiontfrtypp'>Encryption at REST
                                    </p>
                                    <div className='mongoimg'>
                                        <img src={mongo} alt="..." />
                                    </div>
                                </div>
                                <div className='enxcrtoanarstdivbgtxtimg'>
                                    <p className='encryptiontfrtypp'>Auto backup procedure

                                    </p>
                                    <div className='mongoimg'>
                                        <img src={mongo} alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className='leveragebbiokkppdiv'>
                                <p className='leveragebbiokkpp'>We leverage Mongo DB for Data Encryption at rest, and used in conjunction with transport encryption and good security policies that protect relevant accounts, passwords, and encryption keys, help to ensure compliance with security and privacy standards, including HIPAA, PCI-DSS, and FERPA.
                                </p>
                            </div>
                        </div>
                        <div className='encryptiondivbng' style={{ background: "linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%)", border: "4px solid #fff" }}>
                            <div className='encryptiontfrtyjjhbkpp'>
                                <p className='encryptiontfrtyjjhbkppmk' style={{ color: "#fff" }}>ENCRYPTION AT REST
                                </p>
                            </div>
                            <div className='enxcrtoanarstdivbg'>
                                <div className='securityloxkimg'>
                                    <img src={securityloxk} alt="..." />
                                </div>
                            </div>
                            <div className='leveragebbiokkppdiv'>
                                <p className='leveragebbiokkpp' style={{ color: "#fff" }}>Data Obfuscation
                                    We apply the right level of Data Obfuscation, Tokenizations, Hashing, Masking, to keep it persistently protected while at rest, in transit, and in use.

                                </p>
                            </div>
                        </div>
                        <div className='encryptiondivbng'>
                            <div className='encryptiontfrtyjjhbkpp'>
                                <p className='encryptiontfrtyjjhbkppmk'>Advance Encryption AES-256


                                </p>
                            </div>
                            <div className='enxcrtoanarstdivbg'>
                                <div className='enxcrtoanarstdivbgtxtimg'>
                                    <p className='encryptiontfrtypp' style={{ textAlign: "start" }}>Transparent, automated, comprehensive data-centric security
                                        solution

                                    </p>

                                </div>
                                <div className='enxcrtoanarstdivbgtxtimg'>
                                    <div className='securityloxkimg'>
                                        <img src={securitylockprivt} alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className='leveragebbiokkppdiv'>
                                <p className='leveragebbiokkpp'>Privaclave has architectural components for    Centralized Policy Management Engine (Cockpit), and Distributed components (Autopilot) deployed at the microservices and API layer(s)

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </sectipn>

            <section className="landingexperincehbtwlvth">
                <div className="landingpageconatiner">
                    <div className="landingfinalflxfst">
                        <div className="landingfsrtsctiondiv">

                            <h4 className="landingsctionfrstpp" style={{ color: "#fff" }}>a happy workplace
                                results in greater productivity
                            </h4>
                            <p className="tltxyupghlity" style={{ color: "#fff" }}>Tell us a little about your company and someone from our team will be in touch soon.
                            </p>

                        </div>
                        <div className="strtjrnydivdfrmnkklk">
                            <form>
                                <div className="manmembldnjwgj">
                                    <div className="manjekjipnpt">
                                        <input type="text" placeholder="Name" />
                                    </div>
                                    <div className="manjekjipnpt">
                                        <input type="number" placeholder="Mobile No." />
                                    </div>
                                </div>
                                <div className="manjekjipnpt">
                                    <input type="email" placeholder="Email" />
                                </div>
                                <div className="manjekjipnpt">
                                    <textarea id="" name="" rows="4" cols="4">
                                    </textarea>
                                </div>
                                <button className="bkdibfgbnmrjhk" style={{ background: "linear-gradient(167deg, rgb(237, 59, 76) 0%, rgb(113, 48, 160) 81%)" }}>
                                    Book For A Demo
                                </button>
                            </form>
                            <p className="datyrgghtxpgh" style={{ color: "#fff" }}>* Your data is safe with us. We don’t share our client’s data with anyone.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EliteExperienceHub