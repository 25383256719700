import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

const initialState = {
    status: 'idle',
    isLoading: false,
    isSuccess: false,
    isError: false,
    jobsrec: [],
    training: [],
    skillTraining: [],
    microTraining: [],
    allMicroTraining: [],
    newTraining: [],
    nontechnicalTraining: [],
    documents: [],
    ongoing: [],
    notification: [],
    loginSurvey: [],
    cliamPoints: [],
    savetraining:[],
    jobreq:[],
    jobpost:[],
    trainingreq:[],
    phyTraining:[]
    // addedBy:""
}


export const getRecruitmentJobs = createAsyncThunk(
    'get_jobs_recruitment_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-job-requirment", method: 'GET' })
        if (response?.status) {
            // console.log("responseJobs", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);

export const getOnboardingTraining = createAsyncThunk(
    'get_onboarding_training_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/all-training-list-respectOf-user", method: 'GET' })
        if (response?.status) {
            // console.log("responseJobs", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);


export const getOnboardingSkillTraining = createAsyncThunk(
    'get_onboarding_skill_training_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-skill-training", method: 'GET' })
        if (response?.status) {
            // console.log("responseJobs", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);

export const getOnboardingMicroTraining = createAsyncThunk(
    'get_onboarding_micro_training_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-all-trainings", method: 'GET' })
        if (response?.status) {
            // console.log("responsetrainingall", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);

export const getSaveTraining = createAsyncThunk(
    'get_save_training_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/fetch-save-item", method: 'GET' })
        if (response?.status) {
            return response?.data;

        } else {
            
        }
    }
);

export const getOnboardingnewTraining = createAsyncThunk(
    'get_onboarding_new_training_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-approved-training", method: 'GET' })
        if (response?.status) {
            // console.log("responseJobs", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);

export const getOnboardingNontechnicalTraining = createAsyncThunk(
    'get_onboarding_nontechnical_training_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-nontechnical-training", method: 'GET' })
        if (response?.status) {
            // console.log("nontechnical", response?.data?.training)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);


export const getOnboardingPhysicalTraining = createAsyncThunk(
    'get_onboarding_physical_training_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-physical-training", method: 'GET' })
        if (response?.status) {
            return response?.data;
        }
    }
);

export const getDocumentsRepo = createAsyncThunk(
    'get_documents_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/get-documents", method: 'GET' })
        if (response?.status) {
            // console.log("responseJobs", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);


export const getOnGoingTraining = createAsyncThunk(
    'get_ongoing_new_training_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/joined-training", method: 'GET' })
        if (response?.status) {
            // console.log("responseJobs", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);

export const getRequestTraining = createAsyncThunk(
    'get_requested_training_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-trainings-addedby", method: 'GET' })
        if (response?.status) {
            // console.log("responseJobs", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);

export const getNotification = createAsyncThunk(
    'get_notification_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-notification", method: 'GET' })
        if (response?.status) {
            // console.log("responseJobs", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);

export const getClaimPoints = createAsyncThunk(
    'get_claimpoints_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/fetch-claim-points", method: 'GET' })
        if (response?.status) {
            // console.log("responseJobs", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);

export const getLoginSurvey = createAsyncThunk(
    'get_login_survey_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/get-login-survey", method: 'GET' })
        if (response?.status) {
            // console.log("responselogin", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);

export const getJobReq = createAsyncThunk(
    'get_job_req_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-job-requirment-addedby", method: 'GET' })
        if (response?.status) {
            // console.log("responselogin", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);

export const getJobpost = createAsyncThunk(
    'get_job_post_data', async () => {
        let response = await ApiHelperFunction({ urlPath: "/view-job-addedby", method: 'GET' })
        if (response?.status) {
            // console.log("responselogin", response?.data)
            return response?.data;

        } else {
            // toast.error('Error fetching bill board data')
        }
    }
);

export const jobRecruitmentSlice = createSlice({
    name: "jobs_recruitment_actions",
    initialState,
    reducers: {
        clearJobRecruitmentData: (state) => {
            state.status = "idle";
            state.jobsrec = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
        clearOnboardingTrainingData: (state) => {
            state.status = "idle";
            state.training = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
        clearOnboardingSkillTrainingData: (state) => {
            state.status = "idle";
            state.skillTraining = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
        clearOnboardingMicroTrainingData: (state) => {
            state.status = "idle";
            state.allMicroTraining = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
        clearOnboardingNewTrainingData: (state) => {
            state.status = "idle";
            state.newTraining = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
        clearOnboardingNontechnicalTrainingData: (state) => {
            state.status = "idle";
            state.nontechnicalTraining = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
        clearOnboardingdocuments: (state) => {
            state.status = "idle";
            state.documents = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
        clearOnGoing: (state) => {
            state.status = "idle";
            state.ongoing = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
        clearNotification: (state) => {
            state.status = "idle";
            state.notification = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRecruitmentJobs.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getRecruitmentJobs.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                // console.log('payloadreq', payload);
                state.jobsrec = payload?.data?.reverse();
            })
            .addCase(getRecruitmentJobs.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })

            .addCase(getOnboardingTraining.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getOnboardingTraining.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                // console.log('payloadreq', payload);
                state.training = payload?.data?.reverse();
            })
            .addCase(getOnboardingTraining.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })

            .addCase(getOnboardingSkillTraining.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getOnboardingSkillTraining.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                // console.log('payloadreq', payload);
                state.skillTraining = payload?.training;
                state.microTraining = payload?.microTraining
            })
            .addCase(getOnboardingSkillTraining.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })

            .addCase(getOnboardingMicroTraining.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getOnboardingMicroTraining.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.allMicroTraining = payload?.data
            })
            .addCase(getOnboardingMicroTraining.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })


            .addCase(getOnboardingnewTraining.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getOnboardingnewTraining.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.newTraining = payload?.data.slice(0, 3)
            })
            .addCase(getOnboardingnewTraining.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })


            .addCase(getOnboardingNontechnicalTraining.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getOnboardingNontechnicalTraining.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.nontechnicalTraining = payload?.training
            })
            .addCase(getOnboardingNontechnicalTraining.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })


            .addCase(getOnboardingPhysicalTraining.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getOnboardingPhysicalTraining.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.phyTraining = payload?.data
            })
            .addCase(getOnboardingPhysicalTraining.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })

            .addCase(getDocumentsRepo.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getDocumentsRepo.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.documents = payload?.data?.reverse()
            })
            .addCase(getDocumentsRepo.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })

            .addCase(getOnGoingTraining.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getOnGoingTraining.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.ongoing = payload?.data?.[0]?.joinedTraining?.reverse();
            })
            .addCase(getOnGoingTraining.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })


            .addCase(getSaveTraining.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getSaveTraining.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.savetraining = payload?.data?.reverse();
                // state.addedBy=payload?.data?.addedBy;
            })
            .addCase(getSaveTraining.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })

            .addCase(getNotification.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getNotification.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.notification = payload?.data
            })
            .addCase(getNotification.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })


            .addCase(getLoginSurvey.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getLoginSurvey.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.loginSurvey = payload?.data
                // console.log("loginSurvey", payload?.data)
            })
            .addCase(getLoginSurvey.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })

            .addCase(getClaimPoints.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getClaimPoints.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.cliamPoints = payload?.data
                // console.log("loginSurvey", payload?.data)
            })
            .addCase(getClaimPoints.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })

            .addCase(getJobReq.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getJobReq.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.jobreq = payload?.data?.reverse();
              
            })
            .addCase(getJobReq.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })

            .addCase(getJobpost.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getJobpost.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.jobpost = payload?.data?.reverse();
              
            })
            .addCase(getJobpost.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })

            .addCase(getRequestTraining.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getRequestTraining.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.trainingreq = payload?.data?.reverse();
              
            })
            .addCase(getRequestTraining.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })
    }

})
export const { clearJobRecruitmentData, clearOnboardingTrainingData, clearOnGoing, clearOnboardingSkillTrainingData, clearOnboardingMicroTrainingData, clearOnboardingNewTrainingData, clearOnboardingNontechnicalTrainingData, clearOnboardingdocuments, clearNotification } = jobRecruitmentSlice.actions;
export default jobRecruitmentSlice.reducer;