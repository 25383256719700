import React from 'react'
import Physicaltraining from "../../Images/Icons/PNG/Physicaltraining.png";
import Expand from "../../Images/Icons/PNG/Expand.png";
import { useNavigate } from 'react-router-dom';
import SkillPhysicaltraining from './SkillPhysicaltraining';

const Physicaltrainig = () => {
    const navigate = useNavigate();

    const handleDetailsClick = () => {
        navigate('/singlePhysicalCourse')
    }
    return (
        <>
            <div className='mandatorydiv'>
                <div className='mandatoryicontxtflx'>
                    <div className='icontxtdivmain'>
                        <div className='mandatoryimg'>
                            <img src={Physicaltraining} alt="..." />
                        </div>
                        <div className=''>
                            <p className='mandatorttxt'>Physical Training
                            </p>
                        </div>
                    </div>
                    <div className='Expandimg'>
                        <img src={Expand} alt="..." onClick={handleDetailsClick} />
                    </div>
                </div>

                <div className='mandatorydivbtm'>
                    <SkillPhysicaltraining />
                </div>

            </div>
        </>
    )
}

export default Physicaltrainig
