import React, { useEffect, useState } from 'react'
// import Share from "../../src/Images/Icons/PNG/Share.png";
// import Refer from "../../src/Images/Icons/PNG/Refer.png";
import Skills from "../../src/Images/Icons/PNG/Skills.png";
import Designation from "../../src/Images/Icons/PNG/Designation.png";
import time from "../../src/Images/Icons/PNG/time.png";
import LocationIcon from "../../src/Images/Icons/PNG/LocationIcon.png";
import { ApiHelperFunction } from '../services/api/apiHelpers';
// import Share from "../..src/Images/Icons/PNG/share-icon-elite-web.svg"
// import { RWebShare } from 'react-web-share';
// import toast from 'react-hot-toast';

const AppliedJobDetailsModal = ({ closemodal, values }) => {
  // console.log(values, "pppppqw")
  const [whyData, setWhyData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);


  //for fetching data of why join us
  const getWhyJoinData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-about-company`,
      method: "GET",
    });
    if (response && response.status) {
      setWhyData(response?.data?.data);
    } else {
      // toast.error(response.error);
    }
  };

  //for fetching Department data
  const getDepartmentData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/view-department`,
      method: "GET",
    });
    console.log("RESPONSEF", response?.data?.data);
    if (response && response.status) {
      setDepartmentData(response?.data?.data);
    } else {
      // toast.error(response.error);
    }
  };

  const getDepartmentName = (departmentId) => {
    const department = departmentData?.find(department => department._id === departmentId);
    return department ? department?.departmentName : '';
  };

  useEffect(() => {
    getWhyJoinData();
    getDepartmentData();
  }, [])

  console.log("valuesioyu", values)
  return (
    <div className='viedetailsmain' onClick={() => closemodal()}>
      <div className='viewdetailscontenthiring'>
        <div className='closemarkdst' ><i class="fa-solid fa-xmark"></i></div>
        <div className=''>

          <div className='hghtscrldiv'>

            <div className='bg-brdrdiv'>
              <div className='bg-grydiv'>
                <h2 className='psttxtppdiv'>{values?.jobTitle}</h2>
              </div>
              <div className='dshimgtxt'>
                <div className='imhtnch'>
                  <img src={Designation} alt="..." />
                </div>
                {/* <div className='bg-grydiv'>
                  <p className='dstgytxy'>{getDepartmentName(values?.Department)}</p>
                </div> */}
                {
                  values?.departmentName ? (<div className='bg-grydiv'>
                    <p className='dstgytxy'>{values?.departmentName}</p>
                  </div>) : (<div className='bg-grydiv'>
                    <p className='dstgytxy'>{getDepartmentName(values?.Department)}</p>
                  </div>)
                }
              </div>
              <div className='dshimgtxt'>
                <div className='imhtnch'>
                  <img src={time} alt="..." />
                </div>
                <div className='bg-grydiv'>
                  <p className='dstgytxy'>{values?.period}</p>
                </div>
              </div>
              <div className='dshimgtxt'>
                <div className='imhtnch'>
                  <img src={Skills} alt="..." />
                </div>
                <div className='bg-grydiv'>
                  <p className='dstgytxy'>{values?.keySkills}</p>
                </div>
              </div>
              <div className='dshimgtxt'>
                <div className='imhtnch'>
                  <img src={LocationIcon} alt="..." />
                </div>
                <div className='bg-grydiv'>
                  <p className='dstgytxy'>{values?.jobLocation}</p>
                </div>
              </div>

              {/* <div className='inpuytboxdyflx'>
                <p className='optxtvn'>Open to Referral Hiring</p>
                <input type="checkbox" id="" name="" value="" />
              </div> */}
              <div className='inpuytboxdyflx'>
                <p className='optxtvn'>Open to Referral Hiring</p>
                <input type="checkbox"
                  id=""
                  name=""
                  value=""
                  checked={values?.isReffered}
                />
              </div>

            </div>

            {
              whyData?.map((item, ind) => {
                return (
                  <>
                    <div className='jndivtxtwhy' key={ind} >
                      <p className="jntxtdivhrin">Why Join US?</p>
                      <div className='bg-grydiv'>
                        <p className='frcttxy'>
                          {item?.whyJoinUs}
                        </p>
                      </div>
                    </div>
                  </>
                )
              })
            }



            <div className='jndivtxtwhy'>
              <p className="jntxtdivhrin">Position Description</p>
              <div className='bg-grydiv'>
                <p className='frcttxy'>{values?.positionDescription}</p>
                {/* <p className='frcttxy'>Fractals Global’s mission is to ‘empower every person and every organization on the
                    planet to achieve more’. At studio+91 at the India Development Center (MSIDC), we work
                    together across a multitude of disciplines: design, research and design engineering, to
                    empower people all over the planet. Our diverse teams are constantly iterating, solving
                    problems and collaborating with product managers, engineers to craft meaningful and
                    relevant product experiences.</p>
                  */}
              </div>
            </div>
            <div className='jndivtxtwhy'>
              <p className="jntxtdivhrin">Responsibilities:</p>
              <div className='bg-grydiv'>
                <ul className='txtillsltall'>
                  <li className='txtlixtli'>
                    {values?.responsibilities}
                  </li>
                  {/* <li className='txtlixtli'>
                      You will be leading research projects independently, either alone or with a team
                      of researchers, working closely with a team of designers, product managers and
                      engineers in a highly collaborative and agile environment.
                    </li>
                    <li className='txtlixtli'>
                      Projects usually start with a fundamental inquiry, arrived collaboratively with
                      stakeholders across design product & engineering. This also entails identifying &
                      prioritizing research inquiries and defining the research strategy for your product
                      area
                    </li>
                     */}
                </ul>
              </div>
            </div>
            <div className='jndivtxtwhy'>
              <p className="jntxtdivhrin">Qualifications:</p>
              <div className='bg-grydiv'>
                <ul className='txtillsltall'>
                  <li className='txtlixtli'>
                    {values?.qualification}
                  </li>
                  {/* <li className='txtlixtli'>
                      {jobrefferedData?.addQualification}
                    </li> */}
                  {/* <li className='txtlixtli'>
                      You will be leading research projects independently, either alone or with a team
                      of researchers, working closely with a team of designers, product managers and
                      engineers in a highly collaborative and agile environment.
                    </li>
                    <li className='txtlixtli'>
                      Projects usually start with a fundamental inquiry, arrived collaboratively with
                      stakeholders across design product & engineering. This also entails identifying &
                      prioritizing research inquiries and defining the research strategy for your product
                      area
                    </li>
                   */}
                </ul>
              </div>
            </div>
            <div className='jndivtxtwhy'>
              <p className="jntxtdivhrin">Good To Have</p>
              <div className='bg-grydiv'>
                <ul className='txtillsltall'>
                  <li className='txtlixtli'>
                    {values?.addQualification}
                  </li>
                  {/* <li className='txtlixtli'>
                      You will be leading research projects independently, either alone or with a team
                      of researchers, working closely with a team of designers, product managers and
                      engineers in a highly collaborative and agile environment.
                    </li>
                    <li className='txtlixtli'>
                      Projects usually start with a fundamental inquiry, arrived collaboratively with
                      stakeholders across design product & engineering. This also entails identifying &
                      prioritizing research inquiries and defining the research strategy for your product
                      area
                    </li>
                   */}
                </ul>
              </div>
            </div>
            {/* <div className=''>
              <p className='sndstpp'>Sounds you know someone who can fit?</p>
            </div> */}

          </div>
        </div>
      </div>

    </div>
  )
}

export default AppliedJobDetailsModal