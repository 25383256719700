import React, { useEffect, useState } from 'react'
import { ApiHelperFunction } from '../../services/api/apiHelpers';

const About = () => {
    const [AboutCompany,setAboutCompany]=useState([]);

    const getdata = async () => {
        const res = await ApiHelperFunction({
            urlPath: `/view-about-company`,
            method: "GET",
          });
        if (res && res?.status) {
            setAboutCompany(res?.data?.data)
        }
    }

    useEffect(()=>{
        getdata();
    },[])

  return (
    <section className='bulleDetail'>
      <div className="container">

    {
        AboutCompany?.map((item)=>{
            return(
                <>
                <h4>About Your Company :</h4>
                <p>
                    {item?.about}
                    
                </p>
                </>
            )
        })
    }
    </div>
    </section>

  )
}

export default About