import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// import { colourOptions } from "../data";
import Refer from "../../Images/Icons/PNG/Refer.png";
import { ApiHelperFunction, fileUpload } from "../../services/api/apiHelpers";
import toast from "react-hot-toast";
import { useAuthCtx } from "../../context/AuthCtx";
import { useFormik } from "formik";
// import { GiFlowerStar } from "react-icons/gi";
import { useParams } from "react-router-dom";
import Addicon from "../../Images/headericon/Add icon.png"


const JobApplyModal = ({ closemodal1, id }) => {
    const initialValues={
        name: "",
        email: "",
        profileLink: ""
    }
    // console.log("vberopfg", id)
    const params = useParams();
    const { setLoading } = useAuthCtx();
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
    const [image, setImage] = useState("")
    const [uploading, setUploading] = useState(false);
    const [imageURL, setImageURL] = useState("");



    //for pdf file uploading
    const handlePdfChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await fileUpload("/image-upload", "POST", form);

        if (res.status) {
            toast.success("PDF uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploading(false);
    };


    //for handling apply
    const submitHandler = async () => {
        setShouldValidateOnChange(true);
        setShouldValidateOnBlur(true);

        let data = {};
        setLoading(true);
        // if (values.userId == [""]) {
        //     return toast.error("All fields required");
        // }
        if (
            params?._id === "" ||
            imageURL === "" ||
            values.name === "" ||
            values.email === "" 
      
          ) {
            return toast.error("All fields required");
          }

        data = {
            jobId: params?._id,
            resume: imageURL,
            name: values?.name,
            email: values?.email,
            profileLink:values?.profileLink
        };

        console.log("jobId_data", data);
        // return false;
        const res = await ApiHelperFunction({
            urlPath: "/apply-job",
            method: "POST",
            data,
        });
        if (res?.status) {
            toast.success(res?.data?.message);
            resetForm();
            closemodal1();
        } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
        }
        setLoading(false);
    };


    const { values, resetForm, handleChange } =
        useFormik({
            // initialValues: {
            //     resume: ""
            // },
            initialValues,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

    return (
        <>
            <div className="createmodal_main">
                <div className="create_modal">
                    {/* <div className="refermpodalflx">
                        <h2 className="rfrtxtpp">Apply Modal</h2>
                        <div className="crssdicv" onClick={() => closemodal1()}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                    </div> */}
                    <div className="createmodalnewdivcontent">
                        <div
                            className=""
                        // style={{ width: "100%", height: "100%", padding: "20px 0" }}
                        >
                            <div className="bulletintextclose">
                                <div className="bulletinimageflxdivmodal">
                                    <div className="bulleBoardFig">
                                    <img src={Refer} alt="..." />
                                    </div>
                                    <span className="newBulleCreHead">Apply Modal</span>
                                </div>

                                <div onClick={() => closemodal1()}>
                                    <i
                                        class="fa-solid fa-xmark"
                                        style={{
                                            color: "red",
                                            fontSize: "22px",
                                            cursor: "pointer",
                                        }}
                                    ></i>
                                </div>
                            </div>
                            {/* <div className="selectdivmain">
                                <div className="selectdiv">
                                    <p className="emplselect">Upload CV</p>
                                    <div className="selectmaindiv">


                                        <div className="homePgModInnerInpDivs">
                                            <input
                                                type="file"
                                                id="image"
                                                placeholder="Upload CV"
                                                value={image}
                                                accept=".pdf, .doc"
                                                name="image"
                                                onBlur={handleBlur}
                                                onChange={handlePdfChange}
                                            />
                                            <small id="emailHelp" style={{ color: "red" }}>
                                                {errors?.image}
                                            </small>
                                            {uploading ? <p>CV uploading......</p> : null}
                                           
                                        </div>



                                    </div>
                                    <button className="referbtnflx" type="button"
                                        onClick={submitHandler}>
                                        <div className="shrimg">
                                            <img src={Refer} alt="..." />
                                        </div>
                                        <p className="rfshr"> Apply</p>
                                    </button>
                                </div>
                            </div> */}
                            <form className="">
                                <div>
                                    <div className="homePgModInnerInpDivsmain">
                                        <p>Applicant Name <span className="astgsrdijxppp"> * </span></p>
                                        <input
                                            type="text"
                                            value={values?.name}
                                            name="name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="homePgModInnerInpDivsmain">
                                        <p>Email Address <span className="astgsrdijxppp"> * </span></p>
                                        <input
                                            type="email"
                                            value={values?.email}
                                            name="email"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="homePgModInnerInpDivsupldflx">
                                        <p>Upload Cv <span className="astgsrdijxppp"> * </span> </p>
                                        <div className="bguploadbulletinmdbrdr">
                                            <div className="bgcrdbulletin">
                                                <div className="bgcontainerupldfilediv">
                                                    <div className="logoupldbulletin">
                                                        <img src={Addicon} alt="..." />
                                                        <div className="">
                                                            <input
                                                                type="file"
                                                                id="image"
                                                                placeholder="Upload CV"
                                                                value={image}
                                                                accept=".pdf, .doc"
                                                                name="image"
                                                                onChange={handlePdfChange}
                                                                className="upldimagediv"
                                                            />

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="homePgModInnerInpDivsmain">
                                        <p>Profile Link</p>
                                        <input
                                            type="text"
                                            value={values?.profileLink}
                                            name="profileLink"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <button className="referbtnflx" type="button"
                                        onClick={submitHandler}>
                                        <div className="shrimg">
                                            <img src={Refer} alt="..." />
                                        </div>
                                        <p className="rfshr"> Apply</p>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default JobApplyModal