import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-hot-toast';
import { getBio } from "../redux/slices/bioSlice";
import { useDispatch } from "react-redux";
import { ApiHelperFunction, fileUpload } from "../services/api/apiHelpers";


const EditBioModal = ({ closemodal, initialValues, activity }) => {
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
    const dispatch = useDispatch();
    const [image, setImage] = useState("")
    const [uploading, setUploading] = useState(false);
    const [imageURL, setImageURL] = useState(initialValues?.resume);


    //for pdf file uploading
    const handlePdfChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await fileUpload("/image-upload", "POST", form);

        if (res.status) {
            toast.success("PDF uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploading(false);
    };

    const submitHandler = async (e, selectedSubscription) => {
        e.preventDefault();
        setShouldValidateOnChange(true);
        setShouldValidateOnBlur(true);

        let data = {};
        // setLoading(true);
        if (
            values.introduction === "" ||
            values.education === "" ||
            values.city === "" ||
            values.state === "" ||
            values.industry === "" ||
            values.about === "" ||
            values.certificate === "" ||
            values.phone === "" ||
            values.mail === ""

        ) {
            return toast.error("All fields required");

        }

        data = {
            introduction: values?.introduction,
            education: values?.education,
            city: values?.city,
            state: values?.state,
            industry: values?.industry,
            about: values?.about,
            certificate: values?.certificate,
            phone: values?.phone,
            mail: values?.mail,
            image:imageURL
        };

        const res = await ApiHelperFunction({
            urlPath: `/edit-bio/${initialValues?._id}`,
            method: "PUT",
            data,
        });

        if (res?.status) {
            // console.log(res,"datadata")
            toast.success("Updated successfully");
            resetForm();
            dispatch(getBio());
            closemodal();
        } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
            console.log("ERROR CREATING USER3", res);
        }

        // setLoading(false);
    };

    const schemaCheck = Yup.object(
        activity === "billBoard" ||
            activity === "events" ||
            activity === "kababMenu" ||
            activity === "editEvent"
            ? {
                eventName: Yup.string().required("Event Name is required"),
                hostedBy: Yup.string().required("Host name is required"),
                eventDate: Yup.string().required("Event date is required"),
                eventstarttime: Yup.string().required("start Time is required"),
                eventendtime: Yup.string().required(" End Time is required"),
                notes: Yup.string().required(" Notes is required"),
                addinvites: Yup.string().required(" Addinvites is required"),
                lattitude: Yup.string().required(" lattitude is required"),

                longitude: Yup.string().required(" longitude is required"),
            }
            : activity === "training" || activity === "editTraining"
                ? {
                    name: Yup.string().required("Event Name is required"),
                    trainingDate: Yup.string().required("Training date is required"),
                }
                : activity === "bio" || activity === "editBio"
                    ? {
                        introduction: Yup.string().required("Introduction is required"),
                        education: Yup.string().required("Education is required"),
                        city: Yup.string().required("City is required"),
                        state: Yup.string().required("State is required"),
                        industry: Yup.string().required("Industry is required"),
                        about: Yup.string().required("About is required"),
                        certificate: Yup.string().required("Certificate is required"),
                        phone: Yup.string().required("Phone is required"),
                        mail: Yup.string().required("Mail is required")
                    }
                    : activity === "jobReffered"
                        ? {
                            name: Yup.string().required("Job Name is required"),
                            location: Yup.string().required("Job location is required"),
                            salary: Yup.string().required("Salary range is required"),
                            description: Yup.string().required("Description range is required"),
                            opening: Yup.string().required("Opening is required"),
                        }
                        : ""
    );


    const { values, errors, handleBlur, handleSubmit, resetForm, handleChange } =
        useFormik({
            initialValues,
            validationSchema: schemaCheck,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

    useEffect(() => {
        dispatch(getBio());
    }, [])

    return (
        <>
            <section className="rewarddivmain">
                <div className="rewarddivcontent">
                    <div className="btndivcl" onClick={() => closemodal()}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    <div className="rwddiovmain">
                        <div className="rwddiov">
                            <p className="rwdtxtpp">Bio</p>
                        </div>
                    </div>
                    <div className="rewardtm">
                        <form>
                            <div className="brdrbtmdivsup">
                                {/* <p className="txtrwddibpp">Introduction </p> */}
                                <input
                                    type="text"
                                    id="introduction"
                                    aria-describedby="emailHelp"
                                    placeholder="Introduction"
                                    value={values?.introduction}
                                    name="introduction"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="homePgMoInps"
                                />
                                {/* <input type="text"/> */}
                            </div>
                            <div className="brdrbtmdivsup">
                                {/* <p className="txtrwddibpp"> Education  </p> */}
                                <input
                                    type="text"
                                    id="education"
                                    aria-describedby="emailHelp"
                                    placeholder="Education"
                                    value={values?.education}
                                    name="education"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="homePgMoInps"
                                />
                            </div>
                            <div className="brdrbtmdivsup">
                                {/* <p className="txtrwddibpp"> Certificate  </p> */}
                                <input
                                    type="text"
                                    id="certificate"
                                    aria-describedby="emailHelp"
                                    placeholder="Certificate"
                                    value={values?.certificate}
                                    name="certificate"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="homePgMoInps"
                                />
                            </div>
                            {/* <div className="brdrbtmdivsup">
                            <p className="txtrwddibpp"> Address  </p>
                        </div> */}



                            <div className="brdrbtmdivsup">
                                {/* <p className="txtrwddibpp"> Industry  </p> */}
                                <input
                                    type="text"
                                    id="industry"
                                    aria-describedby="emailHelp"
                                    placeholder="Industry"
                                    value={values?.industry}
                                    name="industry"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="homePgMoInps"
                                />
                            </div>
                            {/* <div className="brdrbtmdivsup">
                            <p className="txtrwddibpp"> Contact </p>
                        </div> */}

                            <div className="brdrbtmdivsup">
                                {/* <p className="txtrwddibpp"> About </p> */}
                                <input
                                    type="text"
                                    id="about"
                                    aria-describedby="emailHelp"
                                    placeholder="About"
                                    value={values?.about}
                                    name="about"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="homePgMoInps"
                                />
                            </div>

                            <h5>Address</h5>
                            <div className="brdrbtmdivsup">
                                {/* <p className="txtrwddibpp"> City  </p> */}
                                <input
                                    type="text"
                                    id="city"
                                    aria-describedby="emailHelp"
                                    placeholder="City"
                                    value={values?.city}
                                    name="city"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="homePgMoInps"
                                />
                            </div>
                            <div className="brdrbtmdivsup">
                                {/* <p className="txtrwddibpp"> State  </p> */}
                                <input
                                    type="text"
                                    id="state"
                                    aria-describedby="emailHelp"
                                    placeholder="State"
                                    value={values?.state}
                                    name="state"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="homePgMoInps"
                                />
                            </div>

                            <h5>Contact Information</h5>
                            <div className="brdrbtmdivsup">
                                {/* <p className="txtrwddibpp"> E-mail </p> */}
                                <input
                                    type="number"
                                    id="phone"
                                    aria-describedby="emailHelp"
                                    placeholder="Phone"
                                    value={values?.phone}
                                    name="phone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="homePgMoInps"
                                />
                            </div>
                            <div className="brdrbtmdivsup">
                                {/* <p className="txtrwddibpp"> E-mail </p> */}
                                <input
                                    type="text"
                                    id="mail"
                                    aria-describedby="emailHelp"
                                    placeholder="E-mail"
                                    value={values?.mail}
                                    name="mail"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="homePgMoInps"
                                />
                            </div>

                            <div className="selectmaindiv">
                                <p className="emplselect">Upload CV</p>
                                <div className="homePgModInnerInpDivs">
                                    <input
                                        type="file"
                                        id="image"
                                        placeholder="Upload CV"
                                        value={image}
                                        accept=".pdf, .doc"
                                        name="image"
                                        onBlur={handleBlur}
                                        onChange={handlePdfChange}
                                    />
                                    <small id="emailHelp" style={{ color: "red" }}>
                                        {errors?.image}
                                    </small>
                                    {uploading ? <p>CV uploading......</p> : null}

                                </div>



                            </div>

                            <div className="homePgCreModSubmitDiv" style={{ marginTop: "10px" }}>
                                <button
                                    type="button"
                                    className="homePgCreModSubmitBtn"
                                    onClick={submitHandler}
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default EditBioModal