import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-hot-toast';

import { useDispatch } from 'react-redux';
import { getEventData } from '../../redux/slices/eventSlice';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Event_View from "../../../src/Images/Icons/PNG/Event_View.png"
import { useAuthCtx } from '../../context/AuthCtx';
import { ApiHelperFunction, fileUpload } from '../../services/api/apiHelpers';
import './ClaimPointModal.css';
import rewardpoints from "../../../src/Images/headericon/rewardpoints.png";

const animatedComponents = makeAnimated();

const ClaimPointModal = ({ closemodal, activity, initialValues }) => {
    const { setLoading, userData } = useAuthCtx();
    const [imageURL, setImageURL] = useState("");
    const dispatch = useDispatch();
    const [localerror, setlocalerror] = useState("");
    const [image, setImage] = useState("");
    const [uploading, setUploading] = useState(false);
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const [empData, setempData] = useState([]);
    const [claimPoints, setClaimPoint] = useState("");
    const [eventTypeId, seteventTypeId] = useState([]);
    const [colourOptionseventTypeId, setColourOptionseventTypeId] = useState([]);
    const [selectedOptionseventTypeId, setSelectedOptionseventTypeId] = useState("");
    const [groupData, setGroupData] = useState([]);
    const [colourOptionsGroup, setColourOptionsGroup] = useState([]);
    const [selectedOptionsGroup, setSelectedOptionsGroup] = useState("");
    const [isPublic, setIsPublic] = useState(false);
    const [postType, setPostType] = useState("");
    const [selectedOptionsPostType, setSelectedOptionsPostType] = useState("");


    useEffect(() => {
        getEmployeeData();
        getEventTypeData();
        getMyGroupData();
    }, [])

    //for selecting beneficiaries
    const handleSelectChange = (e) => {
        setSelectedOptions(e);
    };

    //for selecting activities
    const handleSelectChangeEventType = (e) => {
        // console.log("rtv", e)
        setSelectedOptionseventTypeId(e);
        setClaimPoint(e.point)
    };

    //for selecting behaviuoral shperes
    const handleSelectChangeGroup = (e) => {
        setSelectedOptionsGroup(e);
    };


    //get all beneficiaries
    const getEmployeeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-company-employees`,
            method: "GET",
        });

        console.log("RESPONSEF", response?.data?.data);

        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
            }));

            setColourOptions(formattedOptions);
            setempData(response?.data?.data);
        } else {
            // toast.error(response.message);
        }
    };

    //get activities
    const getEventTypeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/get-points`,
            method: "GET",
        });

        console.log("RESPONSEWF", response?.data?.data);

        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.activity,
                point: item?.point
            }));

            setColourOptionseventTypeId(formattedOptions);
            seteventTypeId(response?.data?.data);
            setClaimPoint(response?.data?.data?.point)
        } else {
            // toast.error(response.message);
        }
    };


    //get behaviuoral shperes
    const getMyGroupData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/get-sphare`,
            method: "GET",
        });

        console.log("RESPONSEWTF", response?.data?.data);

        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.name,
            }));

            setColourOptionsGroup(formattedOptions);
            setGroupData(response?.data?.data);
        } else {
            // toast.error(response.message);
        }
    };

    //for submit functionality
    const submitHandler = async (e, selectedSubscription) => {
        e.preventDefault();
        setShouldValidateOnChange(true);
        setShouldValidateOnBlur(true);

        let data = {};
        setLoading(true);
        // if (
        //     values.eventName == "" ||
        //     values.eventDate == "" ||
        //     values.eventTypeId == "" ||
        //     imageURL == ""
        // ) {
        //     return toast.error("All fields required");

        // }

        data = {
            activityId: selectedOptionseventTypeId?.value,
            desc: values?.desc,
            receiverMail: selectedOptions?.map((item) => item?.value),
            behaviourId: selectedOptionsGroup?.value,
            collaboratorMail: userData?.email,
            claimPoints: claimPoints
        };

        console.log("qwerwert", data)
        // return false;
        const res = await ApiHelperFunction({
            urlPath: "/claim-points",
            method: "POST",
            data,
        });
        if (res?.status) {
            toast.success(res?.data?.message);
            resetForm();
            // dispatch(getEventData());
            closemodal();
        } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
            console.log("ERROR CREATING USER3", res);
        }

        setLoading(false);
    };

    //for schemaCheck
    const schemaCheck = Yup.object(
        activity === "billBoard" ||
            activity === "events" ||
            activity === "kababMenu" ||
            activity === "editEvent"
            ? {
                eventName: Yup.string().required("Event Name is required"),
                hostedBy: Yup.string().required("Host name is required"),
                eventDate: Yup.string().required("Event date is required"),
                eventstarttime: Yup.string().required("start Time is required"),
                eventendtime: Yup.string().required(" End Time is required"),
                notes: Yup.string().required(" Notes is required"),
                addinvites: Yup.string().required(" Addinvites is required"),
                lattitude: Yup.string().required(" lattitude is required"),

                longitude: Yup.string().required(" longitude is required"),
            }
            : activity === "training" || activity === "editTraining"
                ? {
                    name: Yup.string().required("Event Name is required"),
                    trainingDate: Yup.string().required("Training date is required"),
                }
                : activity === "jobReffered"
                    ? {
                        name: Yup.string().required("Job Name is required"),
                        location: Yup.string().required("Job location is required"),
                        salary: Yup.string().required("Salary range is required"),
                        description: Yup.string().required("Description range is required"),
                        opening: Yup.string().required("Opening is required"),
                    }
                    : ""
    );

    const { values, errors, handleBlur, handleSubmit, resetForm, handleChange } =
        useFormik({
            initialValues,
            validationSchema: schemaCheck,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });
    // console.log("claimPoints", claimPoints)
    return (
        <div className="createmodal_main">
            <div className="createmodalnewdiv">
                <div className="createmodalnewdivcontent">
                    <div
                        className=""
                    >
                        <div className="bulletintextclose">
                            <div className="bulletinimageflxdivmodal">
                                <div className="bulleBoardFig">
                                    <img src={rewardpoints} alt="..." />
                                </div>
                                <span className="newBulleCreHead">Claim Point</span>
                            </div>

                            <div onClick={() => closemodal()}>
                                <i
                                    class="fa-solid fa-xmark"
                                    style={{
                                        color: "red",
                                        fontSize: "22px",
                                        cursor: "pointer",
                                    }}
                                ></i>
                            </div>
                        </div>

                        <div className='formhgfthdivmain'>
                            <form

                                className=""
                            >

                                <div className="homePgModInnerInpDivsmain">
                                    <p>Activity</p>

                                    <Select
                                        placeholder={"Select Activity"}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={[]}
                                        options={colourOptionseventTypeId}
                                        onChange={handleSelectChangeEventType}
                                    />

                                </div>

                                <div className="homePgModInnerInpDivsmain">
                                    <p>Claim Points</p>
                                    <input
                                        type='email'
                                        value={claimPoints}
                                        name="claimPoints"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="addNoteCreModTextArea"
                                    ></input>
                                    <small id="emailHelp_point" style={{ color: "red" }}>
                                        {localerror}
                                        {errors?.claimPoints}
                                    </small>
                                </div>

                                <div className="homePgModInnerInpDivsmain">
                                    <p>Contribution Behaviour Shpreres</p>

                                    <Select
                                        placeholder={"Select Contribution Behaviour Shpreres"}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={[]}
                                        options={colourOptionsGroup}
                                        onChange={handleSelectChangeGroup}
                                    />

                                </div>


                                <div className="homePgModInnerInpDivsmain">
                                    <p>Describe the contribution Details</p>
                                    <textarea
                                        value={values?.desc}
                                        name="desc"
                                        id=""
                                        cols=""
                                        rows="4"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="addNoteCreModTextArea"
                                    ></textarea>
                                    <small id="emailHelp_point" style={{ color: "red" }}>
                                        {localerror}
                                        {errors?.desc}
                                    </small>
                                </div>

                                <div className="homePgModInnerInpDivsmain">
                                    <p>Add Collaborators (email ids)</p>
                                    <input
                                        type='email'
                                        value={userData?.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="addNoteCreModTextArea"
                                    ></input>
                                    <small id="emailHelp_point" style={{ color: "red" }}>
                                        {localerror}
                                        {errors?.notes}
                                    </small>
                                </div>


                                <div className="homePgModInnerInpDivsmain">
                                    <p>Add Recipients or Beneficiaries</p>
                                    <Select
                                        placeholder={"Select Beneficiaries"}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={[]}
                                        isMulti
                                        options={colourOptions}
                                        onChange={handleSelectChange}
                                    />

                                </div>


                                <div className="bulletinsbmtbtndivv">
                                    <button
                                        type="button"
                                        className="bulletinsbmtbtn"
                                        onClick={submitHandler}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div >
        </div >

    )
}

export default ClaimPointModal