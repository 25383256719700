import React from 'react'
import toast from 'react-hot-toast';
import { ApiHelperFunction } from '../../services/api/apiHelpers';

const Rejectapplicantmoadl = ({ closemodal, rejectModalId,viewJobApplicants }) => {

  const submitHandler = async () => {
    let data = {};
    data = {
      id: rejectModalId,
      applicationStatus: "reject",
    };
    const res = await ApiHelperFunction({
      urlPath: "/job-application-approval",
      method: "POST",
      data,
    });

    if (res?.status) {
      toast.success(res?.data?.message);
      closemodal();
      viewJobApplicants();
    } else {
      toast.error(res?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <>

      <div className="createmodal_main">
        <div className="create_modal">
          <div className="createmodalnewdivcontent">
            <div className=''>
              <div className="bulletintextclose">
                <div className="bulletinimageflxdivmodal">
                  <div className="bulleBoardFig">
                    {/* <img src={Bulletin_Board} alt="..." /> */}
                  </div>
                  <span className="newBulleCreHead">Applicant Reject Modal</span>
                </div>

                <div onClick={() => closemodal()}>
                  <i
                    class="fa-solid fa-xmark"
                    style={{
                      color: "red",
                      fontSize: "22px",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </div>
              <div className=''>
                <div className='tstxguiuhosbjdivmain'>
                  <p className='tstxguiuhosbj'>Do You Want to reject the Application ?</p>
                  <div className='accptrrjctrdivmsindiv'>
                    <button
                      className='accprbdinbbtn'
                      type='button'
                      onClick={submitHandler}
                    >Yes</button>
                    <button
                      className='rjxctgdbdinbbtn'
                      onClick={() => closemodal()}
                    >No</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Rejectapplicantmoadl

