import React, { useEffect, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ApiHelperFunction, fileUpload } from '../../services/api/apiHelpers';
import { useAuthCtx } from '../../context/AuthCtx';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import download from "../../Images/Icons/PNG/download.png"
import Viewicon from "../../Images/Icons/PNG/View icon.png"
const animatedComponents = makeAnimated();

const TrainingCreateEmployeeVirtualNotification = ({ trainingType, trainingCategory, title, language, imageURL}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userData } = useAuthCtx();
    const [isApprove, setApprove] = useState("pending");
    const [isApproveManager, setApproveManager] = useState("pending");
    const [approvalModal, setApprovalModal] = useState(false);
    const isAdminApproved = location?.state?.data?.isAdminApproved;
    const isManagerApproved = location?.state?.data?.isManagerApproved

    const iniVideoDocument = {
        document: ""
    }

    const iniVideoLearningContent = {
        content: ""
    }

    const iniVideoData = {
        videoLink: "",
        videoTitle: "",
        videoDuration: { hour: "", min: "" },
        videoDocument: [iniVideoDocument],
        videoDescription: "",
        videoLearningContent: [iniVideoLearningContent]
    }

    const initrainingquiz = {
        question: "",
        A: "",
        B: "",
        C: "",
        D: "",
        answer: "",
        quizType: "checkBox"
    }

    const [videos, setvideos] = useState([iniVideoData]);
    const [trainingQuiz, settrainingQuiz] = useState([initrainingquiz]);
    const { setLoading } = useAuthCtx();
    const [description, setdescription] = useState("");
    const [link, setlink] = useState("");
    const [mentor, setmentor] = useState([]);
    const [targetAudiance, settargetAudiance] = useState([]);
    const [materials, setmaterials] = useState("");
    const [trainerName, settrainerName] = useState("");
    const [trainingCost, settrainingCost] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const [empData, setempData] = useState([]);
    const [empDataaudience, setempDataaudience] = useState([]);
    const [selectedOptionsaudience, setSelectedOptionsaudience] = useState([]);
    const [colourOptionsaudience, setColourOptionsaudience] = useState([]);
    const [imageLoader4, setImageLoader4] = useState(false);
    const [imageLoader5, setImageLoader5] = useState(false);
    const [DepartmentName, setDepartmentName] = useState("");
    const [options, setOptions] = useState([]);
    const [DepartmentDropdown, setDepartmentDropdown] = useState([]);
    const [selectedOptionsDepartment, setSelectedOptionsDepartment] = useState([]);

    console.log("targetAudianceop", location?.state?.data?.values?.quizData)

    // console.log("locationDatadd", locationData)

    useEffect(() => {
        window.scroll(0, 0);
        const data = location?.state?.data?.values
        if (location?.pathname == "/singleTrainingNotification") {
            // console.log("firstqqopui", data)
            setvideos(data?.videos);
            settrainingQuiz(data?.quizData);
            setdescription(data?.description);
            setlink(data?.link);
            setmaterials(data?.materials);
            settrainingCost(data?.trainingCost);
            settrainerName(data?.trainerName);
            setDepartmentName(data?.department)
            settargetAudiance(data?.targetAudiance)
            setmentor(data?.mentor)
        }
    }, [location])



    //for setting mentor data
    useEffect(() => {
        const data = location?.state?.data?.values?.mentor
        const mentorData = data?.map((item) => {
            const optData = colourOptions?.find(ele => ele?.value === item)
            return optData ? optData : {}
        })
        setSelectedOptions(mentorData)
    }, [colourOptions, location])


    //for setting department data
    useEffect(() => {
        const data = location?.state?.data?.values?.department; 
        if (Array.isArray(DepartmentDropdown)) {
            const departmentData = DepartmentDropdown.find(ele => ele?.value === data) || {};
            setSelectedOptionsDepartment(departmentData);
        }
    }, [DepartmentDropdown,location]);


    //for setting targetAudience data
    useEffect(() => {
        const data = location?.state?.data?.values?.targetAudiance
        const targetData = data?.map((item) => {
            const optData = colourOptionsaudience?.find(ele => ele?.value === item)
            return optData ? optData : {}
        })
        setSelectedOptionsaudience(targetData)
    }, [colourOptionsaudience, location])

    useEffect(() => {
        // Update isApprove state based on the initial value of isAdminApproved
        setApprove(isAdminApproved);
    }, [isAdminApproved]);

    useEffect(() => {
        // Update isApprove state based on the initial value of isAdminApproved
        setApproveManager(isManagerApproved);
    }, [isManagerApproved]);


    //for handling training approval
    const handleTrainingApprove = async (approvalStatus) => {

        if (userData?.userType === "Admin") {

            let data = {
                isAdminApproved: approvalStatus,
            };

            let response = await ApiHelperFunction({
                urlPath: `/give-approval-training/${location?.state?.data?.values?._id}`,
                method: "POST",
                data: data,
            });

            if (response.status == 200) {
                // console.log("responseadmin", response?.data);
                if (response?.data?.status) {
                    // toast.success(response?.data?.message);
                    toast.success(response?.data?.message);

                    if (approvalStatus) {
                        setApprove(approvalStatus);
                        setApprovalModal(true);
                        navigate('/notiHome')

                    } else {
                        // setApprove(false);
                        // setApprovalModal(false);
                        toast?.error(response?.data?.message)

                    }


                } else {
                    // toast.error(response?.data?.message);
                    // toast.success("Server Error");
                    // console.log("ypoiuio", response?.data?.message)

                }
            } else {
                // toast.success("Server Error");
                // toast.error(response?.response?.data?.message);
                // console.log(response, "poutre")
            }
        }

        if (userData?.userType === "Manager") {
            let data = {
                // id: jobRequirmentId,
                // id: id,
                isManagerApproved: approvalStatus,
                department: DepartmentName,
                targetAudiance: targetAudiance,
            };

            let response = await ApiHelperFunction({
                urlPath: `/give-manager-approval-training/${location?.state?.data?.values?._id}`,
                method: "POST",
                data: data,
            });

            if (response.status == 200) {
                // console.log("responseadmin", response?.data);
                if (response?.data?.status) {
                    // toast.success(response?.data?.message);
                    toast.success(response?.data?.message);
                    // console.log(response?.data?.message,"respons")

                    if (approvalStatus) {
                        setApproveManager(approvalStatus);
                        setApprovalModal(true);
                        navigate('/notiHome')

                    } else {
                        // setApprove(false);
                        // setApprovalModal(false);
                        toast?.error(response?.data?.message)

                    }


                } else {
                    // toast.error(response?.data?.message);
                    // toast.success("Server Error");
                    // console.log("ypoiuop", response?.data?.message)

                }
            } else {
                // toast.success("Server Error");
                // toast.error(response?.response?.data?.message);
                // console.log(response, "poutre")
            }
        }

    };


      //pick department name
      const handleSelectDepartName = (e) => {
        // setDepartmentName(setSelectedOptionsaudience ? setSelectedOptionsaudience.value : "");
        setSelectedOptionsDepartment(e)
    }


    // Target Audiance data view department wise
    const handleSelectChangeaudiance = setSelectedOptionsaudience => {
        settargetAudiance(setSelectedOptionsaudience ? setSelectedOptionsaudience?.map(option => option.value) : []);
    }

    // get Designation/Department Data
    const getDepartmentData = async () => {
        // setIsLoading(true);
        const res = await ApiHelperFunction({
            urlPath: "/view-department",
            method: "GET"
        });
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            // setIsLoading(false);
            apiData = res?.data?.data?.map((item, i) => ({
                label: item?.departmentName,
                value: item?._id,

            }));
        } else {
            // setIsLoading(false);
        }
        setDepartmentDropdown(apiData);
    }

    //Fetch Manager Data
    const getManagerData = async () => {
        const res = await ApiHelperFunction({
            urlPath: "/view-company-employees",
            method: "GET"
        });
        // console.log("resGetCatman", res)
        let apiData = []
        if (res && res?.status) {

            apiData = res?.data?.data?.map((item, i) => ({
                label: `${item.firstName} ${item.lastName}`,
                value: item._id,

            }));
        } else {

        }
        setOptions(apiData);
    }


    //handle change for first upload vdo add more functionality
    const handleChange = (i, e) => {
        let newFormValues = [...videos];
        newFormValues[i][e.target.name] = e.target.value;
        setvideos(newFormValues);
    };

    //for onChange functionality vdo duration
    const handleChangeVideoDur = (i, e) => {
        let newFormValues = [...videos];
        newFormValues[i]["videoDuration"][e.target.name] = e.target.value;
        setvideos(newFormValues);
    };

    //handle change for first trainingQuiz add more functionality
    const handleChangeTraingQuiz = (i, e) => {
        let newFormValues = [...trainingQuiz];
        console.log("newFormValues", newFormValues)
        newFormValues[i][e.target.name] = e.target.value;
        settrainingQuiz(newFormValues);
    };


    //for first add more functionality for trainingquiz add more
    const addFormFieldsTrainingQuiz = () => {
        settrainingQuiz([...trainingQuiz, initrainingquiz]);
    };


    //for first add more functionality
    const addFormFields = () => {
        setvideos([...videos, iniVideoData]);
    };

    //for removing add more field
    const removeFormFields = (i) => {
        let newFormValues = [...videos];
        newFormValues.splice(i, 1);
        setvideos(newFormValues);
    };


    //for videoLink functionality(upload video)
    const handleVideoChange = async (ind, e) => {
        setImageLoader5(true)
        let image = e.target.files[0];
        // setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await fileUpload("/image-upload", "POST", form);

        if (res && res.status) {
            toast?.success("Video is uploaded successfully")
            // console.log("UploadImageResww", res);
            setvideos(prevVideos => {
                const newVideos = [...prevVideos];
                newVideos[ind].videoLink = res.image;
                return newVideos;
            });

        } else {
            // toast.error(res?.message);
        }
        setImageLoader5(false);
    };

 

    //function to nested add more document fields
    const addVideosFields = (ind) => {
        let newDocArr = [...videos[ind].videoDocument, {
            document: ""
        }];
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoDocument: newDocArr })
                } else {
                    return item
                }
            })
        })

    };


    //function to nested add more content fields
    const addContentFields = (ind) => {
        let newDocArr = [...videos[ind].videoLearningContent, {
            content: ""
        }];
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoLearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })

    };

    //handle change for nested add more functionality in videoContent
    const handleDocumentChange = (ind, index, e) => {
        let newDocArr = [...videos[ind].videoLearningContent];
        newDocArr[index][e.target.name] = e.target.value;
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoLearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })
    };

    // Function to remove videoContent functionality
    const removeContentDocument = (ind) => {
        let newDocArr = [...videos[ind].videoLearningContent];
        newDocArr.splice(ind, 1);
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoLearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })
    }

    //for handle inner videodocument onChange functionality
    const HandleVideoDoc = async (ind, index, e) => {
        setImageLoader4(true)
        let image = e.target.files[0];
        // setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await fileUpload("/image-upload", "POST", form);

        if (res && res.status) {
            toast?.success("Document is uploaded successfully")
            // console.log("UploadImageRes", res);
            let newDocArr = [...videos[ind].videoDocument];
            newDocArr[index].document = res.image;

            setvideos(prev => {
                return prev?.map((item, i) => {
                    if (i === ind) {
                        return ({ ...item, videoDocument: newDocArr })
                    } else {
                        return item
                    }
                })
            })

        } else {
            // toast.error(res?.message);
        }
        setImageLoader4(false);
    };

    // Function to remove videodocument onChange functionality
    const removeVideoDocument = (ind, index) => {
        const delArr = videos[ind]?.videoDocument?.filter((ele, i) => i !== index)
        const newVideo = [...videos];
        newVideo[ind]["videoDocument"] = delArr
        setvideos(newVideo)
    }

    //for getting internal trainer name
    const getEmployeeData = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-company-employees`,
            method: "GET",
        });
        if (response && response.status) {
            const formattedOptions = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
            }));

            const formattedOptionsaudience = response?.data?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
            }));

            setColourOptions(formattedOptions);
            setempData(response?.data?.data);
            setempDataaudience(response?.data?.data)
            setColourOptionsaudience(formattedOptionsaudience)

        } else {

        }
    };

    //for getting selected internal trainer
    const handleSelectChange = (e) => {
        setSelectedOptions(e);
    };

    // //for getting selected targeted audience 
    // const handleSelectChangeaudience = (e) => {
    //     setSelectedOptionsaudience(e);
    // };

    //for submitting form functionality 
    const submitHandler = async (e, selectedSubscription) => {
        e.preventDefault();
        let data = {};
        setLoading(true);
        if (trainingType == "") {
            return toast.error("Training Type is required");
        }

        if (trainingCategory == "") {
            return toast.error("Training Category is required");
        }

        if (trainingCategory == "") {
            return toast.error("Training Category is required");
        }

        if (title == "") {
            return toast.error("Training title is required");
        }

        if (language == "") {
            return toast.error("Language is required");
        }

        if (imageURL == "") {
            return toast.error("imageURL is required");
        }

        if (description == "") {
            return toast.error("Description is required");
        }

        if (mentor == ['']) {
            return toast.error("Internal Mentor is required");
        }
        if (videos?.[0]?.videoTitle == "") {
            return toast.error("Video Title field is required");
        }
        if (videos?.[0]?.videoDescription == "") {
            return toast.error("Video Description field is required");
        }
        if (videos?.[0]?.videoLink == "") {
            return toast.error("Upload Video field is required");
        }
        if (videos?.[0]?.videoDocument?.[0]?.document == "") {
            return toast.error("Video document field is required");
        }

        if (videos?.[0]?.videoLearningContent?.[0]?.content == "") {
            return toast.error("Video content field is required");
        }
        if (link == "") {
            return toast.error("Link is required");
        }

        // if (trainingQuiz?.[0]?.question == "") {
        //     return toast.error("TrainingQuiz question is required");
        // }

        // if (trainingQuiz?.[0]?.A == "") {
        //     return toast.error("Option A is required");
        // }

        // if (trainingQuiz?.[0]?.B == "") {
        //     return toast.error("Option B is required");
        // }
        // if (trainingQuiz?.[0]?.answer == "") {
        //     return toast.error("Answer is required");
        // }

        if (materials == "") {
            return toast.error("Materials is required");
        }

        data = {
            trainingType: trainingType,
            trainingCategory: trainingCategory,
            title: title,
            language: language,
            image: imageURL,
            description: description,
            trainerName: trainerName,
            trainingCost: trainingCost,
            videos: videos,
            link: link,
            materials: materials,
            trainingQuiz: trainingQuiz,
            // department: DepartmentName,
            department:selectedOptionsDepartment?.value,
            targetAudiance: targetAudiance,
            // mentor: selectedOptions?.map((item) => item?.value),
            mentor: selectedOptions?.value
        };

        console.log("hhhhhhhhhhh", data);

        const res = await ApiHelperFunction({
            urlPath: `/edit-onboarding-training/${location?.state?.data?.values?._id}`,
            method: "PUT",
            data,
        });
        if (res?.status) {
            toast.success("Training(Virtual) is updated successfully");
            // settrainingType("");
            // settrainingCategory("");
            // settitle("");
            // setlanguage("");
            // setImage("");
            // setImageURL("");
            // setdescription("");
            // settrainerName("");
            // settrainingCost("");
            // setvideos([iniVideoData]);
            // setlink("");
            // setmaterials("");
            // settrainingQuiz([initrainingquiz]);
            // settargetAudiance([]);
            // setmentor([]);
        } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
            console.log("ERROR CREATING USER3", res);
        }
        setLoading(false);
    };

    useEffect(() => {
        getEmployeeData();
        getDepartmentData();
        getManagerData()
    }, [])

   

    const handleChangeTraingRadio = (i, e) => {
        const { value } = e.target
        // console.log("dddd", value)
        let newFormValues = [...trainingQuiz];
        // settrainingQuiz(newFormValues);
        // setTrainingQuizRadio
        if (value === "yes") {
            newFormValues[i]["A"] = value;
            newFormValues[i]["B"] = "";
            newFormValues[i]["answer"] = "A";
            settrainingQuiz(newFormValues)
        } else if (value === "no") {
            newFormValues[i]["B"] = value;
            newFormValues[i]["A"] = "";
            newFormValues[i]["answer"] = "B";
            settrainingQuiz(newFormValues)
        }
    };

    return (
        <>
            <div className='trainingcreatepageright'>
                <div className=''>
                    <form>
                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Training Description
                            </p>
                            <textarea className='txtdivallart'
                                id="w3review"
                                rows={4}
                                cols={50}
                                name="description"
                                value={description}
                                onChange={(e) => setdescription(e.target.value)}
                            />

                        </div>
                        <div className='trainernameselectflx'>
                            <div className='trainernametextinputdiv'>
                                <p className="rsntxrpnewtxt">
                                    External Trainer Name(if any)
                                </p>
                                <input
                                    type='text'
                                    className='texttraingrightdiv'
                                    name="trainerName"
                                    value={trainerName}
                                    onChange={(e) => settrainerName(e.target.value)}
                                />
                            </div>
                            <div className='trainernametextinputdiv'>
                                <p className="rsntxrpnewtxt">
                                    Internal Trainer Name(if any)
                                </p>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    defaultValue={[]}
                                    // isMulti
                                    options={colourOptions}
                                    onChange={handleSelectChange}
                                    value={selectedOptions}
                                />


                            </div>
                        </div>

                        {/* Upload Video section */}
                        <div className='trainingrifgtdivgappp'>
                            {
                                videos?.map((element, ind) => {
                                    return (<>
                                        <div className='trainingrifgtdivgapppbrdr' key={ind}>
                                            <p className="rsntxrpnewtxtupld">
                                                Upload video/s
                                            </p>

                                            <div className='videotitledesrtpupldflx'>
                                                <div className='videottldescrtflx'>
                                                    <div className='videottldivwhgt'>
                                                        <p className="rsntxrpnewtxt"> Video Title</p>
                                                        <input
                                                            type='text'
                                                            className='texttraingrightdiv'
                                                            name="videoTitle"
                                                            placeholder={`videoTitle ${ind + 1}`}
                                                            value={element.videoTitle || ''}
                                                            onChange={e => handleChange(ind, e)}
                                                        />
                                                    </div>
                                                    <div className='videottldivwhgt'>
                                                        <p className="rsntxrpnewtxt"> Description </p>
                                                        <textarea className='txtdivallart'
                                                            id="w3review"

                                                            rows={4}
                                                            cols={50}
                                                            name="videoDescription"
                                                            placeholder={`videoDescription ${ind + 1}`}
                                                            value={element.videoDescription || ''}
                                                            onChange={e => handleChange(ind, e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='vdoulddivpbupldg'>
                                                    <p className="rsntxrpnewtxt">Upload Video</p>
                                                    <div className="bgcontainerupldbrdrdiv">
                                                        <div className="bgcrd">
                                                            <div className="bgcontaineruplddiv">
                                                                <div className="logouplddiv">
                                                                    <img src={Addicon} alt="..." />
                                                                    <div className="">
                                                                        <input
                                                                            type="file"
                                                                            className="upldimagediv"
                                                                            onChange={e => handleVideoChange(ind, e)}
                                                                            accept="video/*"
                                                                        ></input>
                                                                    </div>
                                                                </div>
                                                                <div class="bgcontainerupldfiledivabslt">
                                                                    {
                                                                        videos[ind]?.videoLink !== "" && (
                                                                            <div className='imageuplddivvideo' >

                                                                                <video
                                                                                    src={videos[ind]?.videoLink}
                                                                                    alt=""

                                                                                />
                                                                                <div className='clsoedivmainrd'
                                                                                    onClick={() => {

                                                                                        setvideos(prevVideos => {
                                                                                            const newVideos = [...prevVideos];
                                                                                            newVideos[ind].videoLink = "";
                                                                                            return newVideos;
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        class="fa-solid fa-xmark"

                                                                                    ></i>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {imageLoader5 ? (
                                                                        <>
                                                                            <h5>Video uploading....</h5>
                                                                        </>
                                                                    ) : (null)}
                                                                </div>

                                                            </div>
                                                            <p className="upldtxtppdiv">Upload Your Video</p>
                                                        </div>



                                                        <div className='downladimgmainplmaindiv'>
                                                            <div className='downladimgmainpl'>
                                                                {
                                                                    videos[ind]?.videoLink !== "" ? (<div className='dateshowlinkdiv'><img src={download} alt="..." /></div>) : (<div><img src={download} alt="..." /></div>)
                                                                }

                                                                <div className='downloaddivmaindiv'>

                                                                    {
                                                                        videos[ind]?.videoLink !== "" && (
                                                                            <>
                                                                                <div className='downloaddivmaindivsize' >

                                                                                    <a href={videos[ind]?.videoLink?.startsWith("http") ? videos[ind]?.videoLink : `http://${videos[ind]?.videoLink}`} target="_blank" rel="noopener noreferrer">
                                                                                        {videos[ind]?.videoLink?.slice(0, 20)}
                                                                                    </a>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>

                                            </div>
                                            <div className=' isinthetraining'>
                                                <p className="rsntxrpnewtxtupld">
                                                    What is in the training
                                                </p>
                                                <div className='vdoulddivpbflx'>
                                                    <div className='vdoulddivpbflxwh'>
                                                        <div className='viewicontextflxshpwd'>
                                                            <p className="rsntxrpnewtxt">Duration of the training  </p>
                                                            <div className='addimgicondivggview'>
                                                                <img src={Viewicon} alt="..." />
                                                                <span className='hivermespaecial'>Minimum 30 mins Video Upload</span>
                                                            </div>
                                                        </div>
                                                        <div className='divtrainingmainflx'>
                                                            <div className='divtraininhfrst'>

                                                                <input
                                                                    type='number'
                                                                    className='texttraingrightdiv'
                                                                    name="hour"
                                                                    placeholder={`Duration in hour`}
                                                                    value={element?.videoDuration?.hour || ''}
                                                                    onChange={e => handleChangeVideoDur(ind, e)}
                                                                />
                                                            </div>
                                                            <div className='divtraininhfrst'>
                                                                <input
                                                                    type='number'
                                                                    className='texttraingrightdiv'
                                                                    name="min"
                                                                    placeholder={`Duration in min`}
                                                                    value={element?.videoDuration?.min || ''}
                                                                    onChange={e => handleChangeVideoDur(ind, e)}
                                                                /></div>

                                                        </div>

                                                    </div>

                                                    {/* Documents related to the training section */}
                                                    <div className='vdoulddivpbflxwh'>
                                                        <p className="rsntxrpnewtxt">Documents related to the training </p>
                                                        <div className='uploaddocumentbrdraddbtnflx'>
                                                            {
                                                                element?.videoDocument?.map((element, index) => {

                                                                    return (<>
                                                                        <div className='uplddwnlddivbrtnmain'>
                                                                            <div className='uploaddocumentbrdraddbtn'>
                                                                                <div className="uploaddocumentbrdr">
                                                                                    <div className='bgcrdupload'>
                                                                                        <div className='bgcontaineruplddocumnet'>
                                                                                            <div className='addimgicondivgg'>
                                                                                                <img src={Addicon} alt="..." />
                                                                                                <div className="">
                                                                                                    <input
                                                                                                        type="file"
                                                                                                        className="upldimagediv"
                                                                                                        onChange={e => HandleVideoDoc(ind, index, e)}
                                                                                                    />
                                                                                                </div>


                                                                                            </div>

                                                                                            {imageLoader4 ? (
                                                                                                <>
                                                                                                    <h5> Document uploading....</h5>
                                                                                                </>
                                                                                            ) : null}


                                                                                        </div>
                                                                                        {index ? (
                                                                                            <div className='btnalignt'>
                                                                                                <div
                                                                                                    type="button"
                                                                                                    className=""
                                                                                                    onClick={() => removeVideoDocument(ind, index)}
                                                                                                // style={{ marginTop: '9px' }}
                                                                                                >
                                                                                                    <i className="fa-solid fa-trash"></i>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </div>

                                                                                </div>
                                                                                {/* <div className='btnalignt'>
                                                                            <button
                                                                                type="button"
                                                                                className='addmirfgghdivny'
                                                                                onClick={() => addVideosFields(ind)}
                                                                            >Add More</button>
                                                                        </div> */}

                                                                            </div>
                                                                            <div className='downladimgmainplmaindiv'>
                                                                                <div className='downladimgmainpl'>
                                                                                    {
                                                                                        videos[ind]?.videoDocument[index]?.document !== "" ? (<div className='dateshowlinkdiv'><img src={download} alt="..." /></div>) : (<div><img src={download} alt="..." /></div>)
                                                                                    }

                                                                                    <div className='downloaddivmaindiv'>

                                                                                        {
                                                                                            videos[ind]?.videoDocument[index]?.document !== "" && (
                                                                                                <>
                                                                                                    <div className='downloaddivmaindivsize' >

                                                                                                        <a href={videos[ind]?.videoDocument[index]?.document?.startsWith("http") ? videos[ind]?.videoDocument[index]?.document : `http://${videos[ind]?.videoDocument[index]?.document}`} target="_blank" rel="noopener noreferrer">
                                                                                                            {videos[ind]?.videoDocument[index]?.document.slice(0, 20)}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>




                                                                    </>)
                                                                })
                                                            }

                                                            <div className=''>
                                                                <button
                                                                    type="button"
                                                                    className='addmirfgghdivny'
                                                                    onClick={() => addVideosFields(ind)}
                                                                >Add More</button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div >

                                                {/* What Will You Lern section */}
                                                {
                                                    element?.videoLearningContent?.map((element, index) => {
                                                        return (
                                                            <div className=''>
                                                                <div className='trainingrifgtdivgappp'>
                                                                    <div className='trainingqustonbtndltflx'>
                                                                        <p className="rsntxrpnewtxt">What Will You Learn</p>
                                                                        <div className=''>
                                                                            {index ? (
                                                                                <div className='btndltimgbgdivdkt'>
                                                                                    <div
                                                                                        type="button"
                                                                                        className=""
                                                                                        onClick={() => removeContentDocument(ind)}
                                                                                    // style={{ marginTop: '9px' }}
                                                                                    >
                                                                                        <i className="fa-solid fa-trash"></i>
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>

                                                                    <div className=''>
                                                                        <div className='lrnupldinput'>
                                                                            <input
                                                                                type="text"
                                                                                className='texttraingrightdiv'
                                                                                name="content"
                                                                                placeholder={`Content ${index + 1}`}
                                                                                value={element?.content}
                                                                                onChange={e => handleDocumentChange(ind, index, e)}
                                                                            />
                                                                        </div>
                                                                        {/* <div className='addimngrtring'>
                                                                            <img src={Addicon} alt='...' />
                                                                        </div> */}

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }

                                                <div className='addimngrtring'>
                                                    <img src={Addicon}
                                                        onClick={() => addContentFields(ind)}
                                                        alt='...' />
                                                </div>
                                            </div>

                                        </div>


                                        {ind ? (
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-danger ml-1"
                                                onClick={() => removeFormFields(ind)}
                                            >
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        ) : null}
                                    </>)
                                })
                            }

                            <div className='addmirfgghdivnydiv'>
                                <button
                                    type='button'
                                    className='addmirfgghdivny'
                                    onClick={() => addFormFields()}
                                >
                                    Add More
                                </button>
                            </div>
                        </div >


                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Add any URL, where they will learn
                            </p>
                            <input
                                type='text'
                                className='texttraingrightdiv'
                                name="link"
                                value={link}
                                onChange={(e) => setlink(e.target.value)}
                            />

                        </div>

                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Training price (if any)
                            </p>
                            <input
                                type='number'
                                className='texttraingrightdiv'
                                name="trainingCost"
                                value={trainingCost}
                                onChange={(e) => settrainingCost(e.target.value)}
                            />
                        </div>

                        {/* Quiz Section */}
                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Quiz session:
                            </p>
                            <div className='aaddiconinptfkldimgiconaddflx'>
                                {
                                    trainingQuiz?.map((element, ind) => {
                                        return (
                                            <>
                                                <div className='aaddiconinptfklddibwhhj'>
                                                    <div className='aaddiconinptfkld'>
                                                        <div className='quentionnmbinputqust'>
                                                            <span className='questionnmbr'>
                                                                {`Q${ind + 1}`}
                                                                <input
                                                                    type='text'
                                                                    className='inptyflddiccv'
                                                                    name="question"
                                                                    placeholder={`question ${ind + 1}`}
                                                                    value={element?.question || ''}
                                                                    onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                />
                                                            </span>
                                                        </div>

                                                        {/* {
                                                            element
                                                        } */}
                                                        <div className='anwrdivtyp'>
                                                            <p className='anstxtwrttype'>Answer Type :</p>
                                                            <select
                                                                name="quizType"
                                                                value={element?.quizType}
                                                                id=""
                                                                className="answerdrpdwn"
                                                                onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                            >
                                                                <option>Select option</option>
                                                                <option value="Radio">Radio</option>
                                                                <option value="checkBox">Checkbox</option>
                                                            </select>

                                                        </div>


                                                        {/* Cheked button */}
                                                        {
                                                            element?.quizType === "checkBox"
                                                            &&
                                                            (<div className='cjhekeddivtxtdivmain'>
                                                                <>
                                                                    {/* A  */}
                                                                    <div className='cjhekeddivtxt'>
                                                                        <input
                                                                            type='checkbox'
                                                                            name="answer"
                                                                            value="A"
                                                                            checked={element?.answer === "A"}
                                                                            onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        <label htmlFor="vehicle1">A. </label>

                                                                        <input
                                                                            type="text"
                                                                            defaultValue=""
                                                                            name="A"
                                                                            placeholder={`A ${ind + 1}`}
                                                                            value={element?.A || ''}
                                                                            onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                    </div>


                                                                    {/* B */}
                                                                    <div className='cjhekeddivtxt'>
                                                                        <input
                                                                            type='checkbox'
                                                                            name="answer"
                                                                            value="B"
                                                                            checked={element?.answer === "B"}
                                                                            onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        <label htmlFor="vehicle1">B. </label>
                                                                        <input
                                                                            type="text"
                                                                            defaultValue=""
                                                                            name="B"
                                                                            placeholder={`B ${ind + 1}`}
                                                                            value={element?.B || ''}
                                                                            onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                    </div>

                                                                    {/* C */}
                                                                    <div className='cjhekeddivtxt'>
                                                                        <input
                                                                            type='checkbox'
                                                                            name="answer"
                                                                            value="C"
                                                                            checked={element?.answer === "C"}
                                                                            onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        <label htmlFor="vehicle1">C. </label>
                                                                        <input
                                                                            type="text"
                                                                            defaultValue=""
                                                                            name="C"
                                                                            placeholder={`C ${ind + 1}`}
                                                                            value={element?.C || ''}
                                                                            onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                    </div>

                                                                    <div className='cjhekeddivtxt'>
                                                                        <input
                                                                            type='checkbox'
                                                                            name="answer"
                                                                            value="D"
                                                                            checked={element?.answer === "D"}
                                                                            onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        <label htmlFor="vehicle1">D. </label>
                                                                        <input
                                                                            type="text"
                                                                            defaultValue=""
                                                                            name="D"
                                                                            placeholder={`D ${ind + 1}`}
                                                                            value={element?.D || ''}
                                                                            onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                    </div>

                                                                    {/* <div className='cjhekeddivtxt'>
                                                                    <label htmlFor="vehicle1">Answer : </label>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue=""
                                                                        name="answer"
                                                                        placeholder={`answer option ${ind + 1}`}
                                                                        value={element.answer || ''}
                                                                        onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                    />
                                                                    
                                                                </div> */}

                                                                </>

                                                            </div>)
                                                        }

                                                        {
                                                            element.quizType === "Radio"
                                                            &&
                                                            (
                                                                <div className=''>
                                                                    <>
                                                                        {/* <div className='radiobtntxtpp'>
                                                                            <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                                                                            <label htmlFor="html">Yes</label>
                                                                        </div>

                                                                        <div className='radiobtntxtpp'>

                                                                            <input type="radio" id="css" name="fav_language" defaultValue="CSS" />
                                                                            <label htmlFor="css">No</label>
                                                                        </div> */}

                                                                        <div className='radiobtntxtpp'>
                                                                            <input
                                                                                type="radio"
                                                                                id={`radio-${ind}-A`}
                                                                                name="answer"
                                                                                value="yes"
                                                                                checked={element.answer === "A"}
                                                                                onChange={(e) => handleChangeTraingRadio(ind, e)}
                                                                            />
                                                                            <label htmlFor={`radio-${ind}-A`}>Yes</label>
                                                                        </div>

                                                                        <div className='radiobtntxtpp'>
                                                                            <input
                                                                                type="radio"
                                                                                id={`radio-${ind}-B`}
                                                                                name="answer"
                                                                                value="no"
                                                                                checked={element.answer === "B"}
                                                                                onChange={(e) => handleChangeTraingRadio(ind, e)}
                                                                            />
                                                                            <label htmlFor={`radio-${ind}-B`}>No</label>
                                                                        </div>

                                                                    </>

                                                                </div>)
                                                        }

                                                    </div>
                                                    {/* <div className='addimngrtring'>
                                                    <img src={Addicon} type='button' onClick={() => addFormFieldsTrainingQuiz()} alt="..." />
                                                </div> */}
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                <div className='addimngrtring'>
                                    <img src={Addicon} type='button' onClick={() => addFormFieldsTrainingQuiz()} alt="..." />
                                </div>
                            </div>
                        </div>


                        {/* <div className="trainingrifgtdivgappp ">
                            <p className="rsntxrpnewtxt">Select Department Name</p>

                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}

                                options={DepartmentDropdown}
                                onChange={handleSelectDepartName}
                                value={DepartmentDropdown?.find(option => option.value === DepartmentName)}
                            />
                        </div>

                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Target Audience:
                            </p>
                            

                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={[]}
                                isMulti
                                options={options}
                               
                                onChange={handleSelectChangeaudiance}
                                value={options?.filter(option => targetAudiance.includes(option.value))}
                            />
                        </div> */}

                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Materials/Equipment Needed
                            </p>
                            <textarea className='txtdivallart'
                                id="w3review"
                                rows={4}
                                cols={50}
                                name="materials"
                                value={materials}
                                onChange={(e) => setmaterials(e.target.value)}
                            />
                        </div>

                        <div className="trainingrifgtdivgappp ">
                            <p className="rsntxrpnewtxt">Select Department Name</p>

                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}

                                options={DepartmentDropdown}
                                onChange={handleSelectDepartName}
                                value={selectedOptionsDepartment}
                                // value={DepartmentDropdown?.find(option => option.value === DepartmentName)}
                            />
                        </div>

                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Target Audience:
                            </p>


                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={[]}
                                isMulti
                                options={options}

                                onChange={handleSelectChangeaudiance}
                                value={options?.filter(option => targetAudiance?.includes(option.value))}
                            // value={setSelectedOptionsaudience}
                            />
                        </div>

                        <div className="homePgCreModSubmitDiv">
                            <button
                                className="homePgCreModSubmitBtn"
                                type="button"
                                onClick={submitHandler}
                            >
                                Update
                            </button>
                        </div>


                        {
                            (userData?.userType === "Admin") ? (<>
                                {(isAdminApproved === "pending") ? (
                                    <>
                                        <div className="btnacpt btndivmainflx">
                                            <button
                                                className="btndivaprrcj"
                                                type='button'
                                                // style={{ marginLeft: "4rem" }}
                                                onClick={() => handleTrainingApprove("true")}
                                            >
                                                Approve
                                            </button>
                                            <button
                                                className="btndivaprrcj"
                                                type='button'
                                                // style={{ marginRight: "4rem" }}
                                                onClick={() => handleTrainingApprove("false")}
                                            >
                                                Reject
                                            </button>

                                        </div>
                                    </>
                                ) : isAdminApproved === "true" ? (
                                    <div className="apprdiv">
                                        <button type='button' className="btnapprovd">Approved</button>
                                    </div>
                                ) : (
                                    <button type='button' className="btnapprovde">Rejected</button>
                                )}
                            </>)
                                : (userData?.userType === "Manager") ? (
                                    <>   {isManagerApproved === "pending" ? (
                                        <>
                                            <div className="btnacpt btndivmainflx">
                                                <button
                                                    className="btndivaprrcj"
                                                    type='button'
                                                    // style={{ marginLeft: "4rem" }}
                                                    onClick={() => handleTrainingApprove("true")}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    className="btndivaprrcj"
                                                    type='button'
                                                    // style={{ marginRight: "4rem" }}
                                                    onClick={() => handleTrainingApprove("false")}
                                                >
                                                    Reject
                                                </button>

                                            </div>
                                        </>
                                    ) : isManagerApproved === "true" ? (
                                        <div className="apprdiv">
                                            <button type='button' className="btnapprovd">Approved</button>
                                        </div>
                                    ) : (
                                        <button type='button' className="btnapprovde">Rejected</button>
                                    )}

                                    </>) : (<></>)
                        }

                        {/* {
                            approvalModal ? (<>
                                <div className='trainernameselectflx'>
                                    <div className='trainernametextinputdiv'>
                                        <p className="rsntxrpnewtxt">
                                            External Trainer Name(if any)
                                        </p>
                                        <input
                                            type='text'
                                            className='texttraingrightdiv'
                                            name="trainerName"
                                            value={trainerName}
                                            onChange={(e) => settrainerName(e.target.value)}
                                        />
                                    </div>
                                    <div className='trainernametextinputdiv'>
                                        <p className="rsntxrpnewtxt">
                                            Internal Trainer Name(if any)
                                        </p>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            defaultValue={[]}
                                            isMulti
                                            options={colourOptions}
                                            onChange={handleSelectChange}
                                        />


                                    </div>
                                </div>

                                <div className="trainingrifgtdivgappp ">
                                    <p className="rsntxrpnewtxt">Select Department Name</p>

                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}

                                        options={DepartmentDropdown}
                                        onChange={handleSelectDepartName}
                                        value={DepartmentDropdown?.find(option => option.value === DepartmentName)}
                                    />
                                </div>

                                <div className='trainingrifgtdivgappp'>
                                    <p className="rsntxrpnewtxt">
                                        Target Audience:
                                    </p>


                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={[]}
                                        isMulti
                                        options={options}

                                        onChange={handleSelectChangeaudiance}
                                        value={options?.filter(option => targetAudiance.includes(option.value))}
                                    />
                                </div>
                            </>) : (<></>)
                        } */}

                    </form>


                </div>

            </div>
        </>
    )
}

export default TrainingCreateEmployeeVirtualNotification