import React, { useState, useEffect } from 'react'
import { useAuthCtx } from '../context/AuthCtx';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-hot-toast';
import { ApiHelperFunction, fileUpload } from '../services/api/apiHelpers';
import { useDispatch } from 'react-redux';
import { getAllPosts } from '../redux/slices/postSlice';
import Loader from '../Component/loader/Loader';
import Edit from "../Images/headericon/Edit.png"
import Addicon from "../../src/Images/headericon/Addicon.png"
import EmojiPicker from 'emoji-picker-react';
import { BsEmojiSmileFill } from 'react-icons/bs';
const EditPostModal = ({ closemodal, activity, initialValues, imagesArray, getAffinityPosts }) => {
  const { setLoading } = useAuthCtx();
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [image, setImage] = useState([]);
  const [video, setVideo] = useState([]);
  const [uploading, setUploading] = useState(false);
  // const [imagesURL, setImagesURL] = useState("");
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
  const [isEmoji, setIsEmoji] = useState(false);
  // const [postData, setPostData] = useState([]);
  // const params = useParams();

  // console.log("lllop", params)


  // console.log(initialValues, imagesURL, "initialValuesiop")



  // const submitHandler = async (e, selectedSubscription) => {
  //   e.preventDefault();
  //   setShouldValidateOnChange(true);
  //   setShouldValidateOnBlur(true);
  //   console.log("handleSubmit", values);

  //   let data = {};
  //   setLoading(true);
  //   if (
  //     values.eventName == "" ||
  //     values.hostedBy == "" ||
  //     values.eventDate == "" ||
  //     values.eventstarttime == "" ||
  //     values.eventendtime == "" ||
  //     values.notes == ""
  //   ) {
  //     return toast.error("All fields required");
  //   }
  //   data = {
  //     // lattitude: values?.lattitude,
  //     // longitude: values?.longitude,
  //     eventName: values?.eventName,
  //     hostedBy: values?.hostedBy,
  //     eventDate: values?.eventDate,
  //     eventstarttime: values?.eventstarttime,
  //     eventendtime: values?.eventendtime,
  //     addNotes: values?.notes,
  //     image: imageURL,
  //   };

  //   console.log("kkkkkkk", data);
  //   const res = await ApiHelperFunction({
  //     urlPath: "/add-bulletin-board",
  //     method: "POST",
  //     data,
  //   });
  //   if (res?.status) {
  //     toast.success("Event added successfully");
  //     resetForm();
  //     dispatch(getBillBoardData());
  //     closemodal();
  //   } else {
  //     toast.error(res?.message || "Something went wrong");
  //     console.log("ERROR CREATING USER3", res);
  //   }
  //   setLoading(false);
  // };


  // Handle emoji click
  const handleEmojiClick = (emojiObject) => {
    const emoji = emojiObject.emoji;
    setFieldValue((prevPost) => ({
      ...prevPost,
      description: prevPost.description + emoji,
    }));
  };

  const handlestopClose = (e) => {
    e.stopPropagation();
  }


  //for updation functionality
  const submitHandler = async (e) => {
    console.log("imagesArrayimagesArray", imagesArray)
    e.preventDefault();
    setShouldValidateOnChange(true);
    setShouldValidateOnBlur(true);
    console.log("handleSubmit", values);

    let data = {};
    setLoading(true);

    data = {
      description: values?.description,
      image: images,
      video: video
    };

    if (imagesArray?.docType === "homePost") {
      const res = await ApiHelperFunction({
        urlPath: `/edit-post/${initialValues?._id}`,
        method: "PUT",
        data,
      });
      // console.log("Rehjhfd", res);
      // return
      if (res?.status) {
        toast.success("Post is updated successfully");
        resetForm();
        dispatch(getAllPosts());
        closemodal();
      }
    }


    if (imagesArray?.docType === "eventData") {
      data = {
        notes: values?.notes,
        image: image,
        // postType: "public"
      };
      const res = await ApiHelperFunction({
        urlPath: `/update-event/${initialValues?._id}`,
        method: "PUT",
        data,
      });
      if (res?.status) {
        toast.success("Post is updated successfully");
        resetForm();
        dispatch(getAllPosts());

        closemodal();
      }
    }


    if (imagesArray?.docType === "advocacyPost") {

      data = {

        description: values?.description,
        image: image
      };
      const res = await ApiHelperFunction({
        urlPath: `/update-approved-advocacy/${initialValues?._id}`,
        method: "PUT",
        data,
      });
      if (res?.status) {
        toast.success("Post is updated successfully");
        resetForm();
        dispatch(getAllPosts());
        closemodal();
      }
    }

    if (imagesArray === undefined) {
      const res = await ApiHelperFunction({
        urlPath: `/edit-post/${initialValues?._id}`,
        method: "PUT",
        data,
      });

      if (res?.status) {
        toast.success("Post is updated successfully");
        resetForm();
        getAffinityPosts()
        closemodal();
      } else {
        toast.error(res?.message || "Something went wrong");

      }
    }
    // const res = await ApiHelperFunction({
    //   urlPath: `/edit-post/${initialValues?._id}`,
    //   method: "PUT",
    //   data,
    // });

    // if (res?.status) {
    //   toast.success("Post is updated successfully");
    //   resetForm();
    //   getAffinityPosts()
    //   closemodal();
    // } else {
    //   toast.error(res?.message || "Something went wrong");

    // }

    // const res = await ApiHelperFunction({
    //   urlPath: `/edit-post/${initialValues?._id}`,
    //   method: "PUT",
    //   data,
    // });
    // if (res?.status) {
    //   toast.success("Post is updated successfully");
    //   resetForm();
    //   dispatch(getAllPosts());
    //   closemodal();
    // } else {
    //   toast.error(res?.message || "Something went wrong");
    //   console.log("ERROR CREATING USER3", res);
    // }
    setLoading(false);
  };

  // const handleImagesChange = async (e) => {
  //   let image = e.target.files[0];
  //   setUploading(true);

  //   const form = new FormData();
  //   form.append("image", image);

  //   let res = await fileUpload("/image-upload", "POST", form);

  //   if (res.status) {
  //     toast.success("Images uploaded successfully");
  //     setImagesURL(res?.data);
  //   } else {
  //     toast.error("Error uploading image");
  //   }
  //   setUploading(false);
  // };



  // const removeImage = (index) => {
  //   let images = imagesURL?.filter((item, indx) => index !== indx);
  //   console.log("images", images);
  //   setImagesURL([...images]);
  // };


  //for validation functionality
  const schemaCheck = Yup.object(
    activity === "billBoard" ||
      activity === "events" ||
      activity === "kababMenu" ||
      activity === "editEvent"
      ? {
        eventName: Yup.string().required("Event Name is required"),
        hostedBy: Yup.string().required("Host name is required"),
        eventDate: Yup.string().required("Event date is required"),
        eventstarttime: Yup.string().required("start Time is required"),
        eventendtime: Yup.string().required(" End Time is required"),
        notes: Yup.string().required(" Notes is required"),
        addinvites: Yup.string().required(" Addinvites is required"),
        lattitude: Yup.string().required(" lattitude is required"),

        longitude: Yup.string().required(" longitude is required"),
      }
      : activity === "training" || activity === "editTraining"
        ? {
          name: Yup.string().required("Event Name is required"),
          trainingDate: Yup.string().required("Training date is required"),
        }
        : activity === "jobReffered"
          ? {
            name: Yup.string().required("Job Name is required"),
            location: Yup.string().required("Job location is required"),
            salary: Yup.string().required("Salary range is required"),
            description: Yup.string().required("Description range is required"),
            opening: Yup.string().required("Opening is required"),
          }
          : ""
  );


  //for homepost images updation
  const handleImagesChange = async (e) => {
    let files = Array.from(e.target.files);
    console.log("files", files)
    let imageArray = [];
    let videoArray = [];
    setUploading(true);

    for (let i = 0; i < files.length; i++) {
      const form = new FormData();
      form.append("image", files[i]);

      // Check if the file is an image
      if (files[i].type.startsWith('image/')) {
        let res = await fileUpload("/image-upload", "POST", form);
        console.log("resnm", res)
        if (res?.status) {
          toast.success("Image uploaded successfully");
          imageArray?.push(res?.image);
          setImages(imageArray);
        } else {
          toast.error("Error uploading image");
        }
      }
      // Check if the file is a video
      else if (files[i]?.type?.startsWith('video/')) {
        let res = await fileUpload("/image-upload", "POST", form);

        if (res?.status) {
          toast.success("Video uploaded successfully");
          videoArray.push(res?.image);
          setVideo(videoArray)
        } else {
          toast.error("Error uploading video");
        }
      }
      // Handle other file types if needed
      else {
        toast.error("Unsupported file type");
      }
    }

    // Update state with uploaded images and videos
    // setPost({ ...post, image: imageArray, video: videoArray });
    setUploading(false);
  };


  //for advocacy and event post single image updation
  const handleImageChange = async (e) => {
    let image = e.target.files[0];
    setUploading(true);

    const form = new FormData();
    form.append("image", image);

    let res = await fileUpload("/image-upload", "POST", form);

    if (res.status) {
      toast.success("Image uploaded successfully");
      setImage(res?.image);
    } else {
      toast.error("Error uploading image");
    }
    setUploading(false);
  };



  const { values, setFieldValue, errors, handleBlur, handleSubmit, resetForm, handleChange } =
    useFormik({
      initialValues,
      validationSchema: schemaCheck,
      validateOnChange: shouldValidateOnChange,
      validateOnBlur: shouldValidateOnBlur,
      onSubmit: (val) => {
        console.log("val", val);
      },
    });


  useEffect(() => {
    setImages(values?.image)
    setVideo(values?.video)
    setImage(values?.image)
    // getAffinityPosts()
  }, [])


  //for remove images or videos
  const removeImages = (index, type) => {
    if (type === 'image') {
      let images = [...image];
      images.splice(index, 1);
      setImages(images);
    } else if (type === 'video') {
      let videos = [...video];
      videos.splice(index, 1);
      setVideo(videos);
    }
  };


  return (
    <div className="createmodal_main">
      <div className="create_modal">
        <div className="createmodalnewdivcontent">
          <div
            className="">
            <div className="bulletintextclose">
              <div className="bulletinimageflxdivmodal">
                <div className="bulleBoardFig">
                  <img src={Edit} alt="..." />
                </div>
                <span className="newBulleCreHead">Edit Post</span>
              </div>

              <div onClick={() => closemodal()}>
                <i
                  class="fa-solid fa-xmark"
                  style={{
                    color: "red",
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                setShouldValidateOnBlur(true);
                setShouldValidateOnChange(true);
                handleSubmit(e);
              }}
            >
              <div className="homePgModInnerInpDivsmainedit">
                <p>Description</p>
                <textarea
                  type="text"
                  class="form-control"
                  id="description"
                  aria-describedby="emailHelp"
                  placeholder="Description"
                  value={values?.description || values?.notes}
                  name={imagesArray?.docType === "eventData" ? 'notes' : 'description'}
                  // name='description'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <small id="emailHelp" style={{ color: "red" }}>
                  {errors?.description}
                </small>

              </div>

              <div className="bsimgicon" onClick={(e) => handlestopClose(e)} >
                <BsEmojiSmileFill
                  className=""
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsEmoji((prev) => !prev)

                  }}
                />
                {isEmoji && (
                  <EmojiPicker width={20} fontSize={20}
                    onEmojiClick={
                      (emojiObject) => {
                        setFieldValue('description', `${values?.description} ${emojiObject?.emoji}`)
                      }
                    }

                  />

                )}

              </div>

              {
                imagesArray?.docType === "homePost" ? (<>
                  <div className="homePgModInnerInpDivsupldflx">
                    <p>Post Image/video</p>
                    <div className="bguploadbulletinmdbrdr">
                      {/* <div className="file_upload">
                      <div className="input_img" style={{ cursor: "pointer" }}>
                      </div>
                      <input
                        id=""
                        type="file"
                        name="images"
                        onChange={handleImagesChange}
                        onBlur={handleBlur}
                        style={{ cursor: "pointer" }}
                      />
                    </div> */}
                      <div className="bgcrdbulletin">
                        <div className="bgcontainerupldfilediv">
                          <div className="logoupldbulletin">
                            <img src={Addicon} alt="..." />
                            <div className="">
                              <input
                                id=""
                                type="file"
                                name="images"
                                onChange={handleImagesChange}
                                onBlur={handleBlur}
                                className="upldimagediv"
                              />
                              <small id="emailHelp" style={{ color: "red" }}>
                                {errors.image}
                              </small>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>

                </>

                ) : (
                  <>
                    <div className="homePgModInnerInpDivsupldflx">
                      <p>Post Image/video</p>
                      {/* <input
                      type="file"
                      class="form-control"
                      id="imageURL"
                      placeholder="Choose a photo"
                      // value={imageURL}
                      name="image"
                      onBlur={handleBlur}
                      onChange={handleImageChange}

                    /> */}

                      <div className="bguploadbulletinmdbrdr">
                        {/* <div className="file_upload">
                      <div className="input_img" style={{ cursor: "pointer" }}>
                      </div>
                      <input
                        id=""
                        type="file"
                        name="images"
                        onChange={handleImagesChange}
                        onBlur={handleBlur}
                        style={{ cursor: "pointer" }}
                      />
                    </div> */}
                        <div className="bgcrdbulletin">
                          <div className="bgcontainerupldfilediv">
                            <div className="logoupldbulletin">
                              <img src={Addicon} alt="..." />
                              <div className="">
                                <input
                                  id=""
                                  type="file"
                                  name="images"
                                  onChange={handleImagesChange}
                                  onBlur={handleBlur}
                                  className="upldimagediv"
                                />
                                <small id="emailHelp" style={{ color: "red" }}>
                                  {errors.image}
                                </small>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }



              <small id="emailHelp" style={{ color: "red" }}>
                {errors.image}
              </small>

              {
                imagesArray?.docType === "homePost" ? (
                  <>
                    {uploading ? (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Loader />
                      </div>
                    ) : (
                      <div className="prt_img_flxdiv">
                        {images &&
                          images?.map((item, index) => {
                            return (
                              <div className="prt_img" key={index}>
                                <img src={item} alt="" />

                                {/* <div className="close_icon_img">
                                  <i
                                    class="fa-solid fa-xmark"
                                    style={{
                                      color: "red",
                                      fontSize: "24px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeImages(index, 'image')}
                                  ></i>
                                </div> */}
                              </div>
                            );
                          })}
                      </div>
                    )}


                    {uploading ? (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        {/* <Loader /> */}
                        video uploading...
                      </div>
                    ) : (
                      <div className="prt_img_flxdiv">
                        {video &&
                          video?.map((item, index) => {
                            return (
                              <div className="prt_img" key={index}>
                                <video src={item} style={{ width: "90px", height: "90px" }} alt="" />

                                {/* <div className="close_icon_img">
                                  <i
                                    class="fa-solid fa-xmark"
                                    style={{
                                      color: "red",
                                      fontSize: "24px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeImages(index, 'video')}
                                  ></i>
                                </div> */}
                              </div>
                            );
                          })}

                      </div>
                    )}
                  </>

                ) : (
                  <>
                    {uploading ? <p>image uploading......</p> : null}
                    {image !== "" && (
                      <div style={{ display: "flex" }}>
                        <img
                          style={{ marginTop: "1rem" }}
                          src={image}
                          alt=""
                          height="100rem"
                          width="100rem"
                        />
                        <div
                          onClick={() => {
                            setImage("");
                          }}
                        >
                          <i
                            class="fa-solid fa-xmark"
                            style={{
                              color: "red",
                              fontSize: "2rem",
                              cursor: "pointer",
                            }}
                          ></i>
                        </div>
                      </div>
                    )}
                  </>)
              }





              <div className="bulletinsbmtbtndivv">
                <button

                  type="submit"
                  onClick={submitHandler}
                  class="bulletinsbmtbtn"
                >
                  Update
                </button>
              </div>
            </form>

          </div>

        </div>

      </div>
    </div>
  )
}

export default EditPostModal