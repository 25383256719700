import React, { useCallback, useEffect, useState } from "react";
import "./Profile.css";
import AspiringHobby from "./AspiringHobby";
import HubAffinityGrp from "../Hub/HubAffinityGrp";
// import ProSocialCommit from "./ProSocialCommit";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
// import camera from "../../Images/camera.png";
// import battery from "../../Images/battery.png";
// import trophy from "../../Images/trophy.png";
// import Img11 from "../../Images/comment_person_1.png";
// import Img12 from "../../Images/table_icon.png";
// import Img12 from "../../Images/Icons/PNG/Share.png";
// import Img13 from "../../Images/comment_person_2.png";
// import Img14 from "../../Images/table_icon.png";
// import Image4 from "../../Images/companylogo.png";
// import eliteCard from "../../Images/eliteCard.png";
// import chmpMsgPic from "../../Images/chmpMsgPic.png";
// import Post from "../../Images/Icons/PNG/Post.png";
import share from "../../Images/Icons/PNG/share-icon-elite-web.svg";
// import user_image_2 from "../../Images/user_image_2.png";
// import empMnthPic from "../../Images/empMnthPic.png";
// import profileCvrPic from "../../Images/profileCvrPic.png";
// import ProMentConnetion from "./ProMentConnetion";
// import RateSkills from "./RateSkills";
import { useAuthCtx } from "../../context/AuthCtx";
import { ApiHelperFunction } from "../../services/api/apiHelpers";
import { toast } from "react-hot-toast";
import AddSkill from "../../Component/Modal/AddSkill";
import ProfilePageModal from "../../Component/Modal/ProfilePageModal";
import { getAppliedJobData, getBadgeData, getFollowerData, getFollowingData } from "../../redux/slices/badgeSlice";
import { useDispatch, useSelector } from "react-redux";
import { RWebShare } from "react-web-share";
// import table_icon from "../../Images/table_icon.png";
import { getAllPosts } from "../../redux/slices/postSlice";
// import profileImg from "../../Images/Icons/PNG/Profile (2).png";
// import goldIcon from "../../Images/Icons/PNG/Reward - Gold.png";
// import eliteExp from "../../Images/Icons/PNG/Elite employee experience logo.png";
// import Skills from "../../Images/Icons/PNG/Skills.png";
// import bgprfldiv from "../../Images/bgprfldiv.png";
import { Link, useNavigate } from "react-router-dom";
import CreateModal from "../../Layout/Modal/CreateModal";
import add_icon from "../../Images/Icons/PNG/Add icon.png"
// import Event_View from "../../Images/Icons/PNG/Event_View.png"
import Reward from "../../Component/Modal/Reward";
import Bio from "../../Component/Modal/Bio";
import { getBio, getCDetils, getCompanyDetails } from "../../redux/slices/bioSlice";
import EditBioModal from "../../SeprateModal/EditBioModal";
import BioDetailsModal from "../../SeprateModal/BioDetailsModal";
import AppliedJobDetailsModal from "../../SeprateModal/AppliedJobDetailsModal";
import { getRefferedJobs } from "../../redux/slices/jobRefferedSlice";
import Savetrainningjob from "../Profile/Savetrainningjob"
import MyAffinityGroupModal from "../../Component/Modal/MyAffinityGroupModal";
// import Cards from "./Cards";
// import Edit from "../../Images/headericon/Edit.png";
import View from "../../Images/headericon/View.png";
import Delete from "../../Images/headericon/Delete.png";
// import share from "../../Images/headericon/share.png"
import Edit from "../../Images/headericon/Edit.png";
// import { useMemo } from "react";
import moment from "moment";
import SaraTylorProfile from "./SaraTylorProfile";
import WhiteEdit from "../../Images/headericon/WhiteEdit.png";
import ExpandGradient from "../../Images/headericon/ExpandGradient.png"
import { getJobReq, getJobpost } from "../../redux/slices/jobRecruitmentSlice";
import CompanyDetails from "../../Component/Modal/CompanyDetails";
import ComDetailsModal from "../../SeprateModal/ComDetailsModal";
import EditComModal from "../../SeprateModal/EditComModal";
import Chatbot from "../../Component/Chatbot/Chatbot";

function Profile() {
  const { modalL, setModalL, modalK, setModalK } = useAuthCtx();
  const { userData, getUserDetails } = useAuthCtx();
  const [CompanyClick, setCompanyClick] = useState(false);
  const [skillData, setSkillData] = useState([]);
  const [particularSkillData, setParticularSkillData] = useState([]);
  // const [profileImage, setProfileImage] = useState("");
  const [AvgRating, setAvgRating] = useState("");
  const [ratingData, setRatingData] = useState([]);
  // console.log("Particular Skill", particularSkillData);
  const [skillmodal, setSkillmodal] = useState(false);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [goldCard, setGoldCard] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [imgLoading, setimgLoading] = useState(false);
  // const [profileData, setProfileData] = useState({});
  // const location = useLocation();
  const [reward, setReward] = useState(false)
  const [activeTab, setActiveTab] = useState(0);
  const [modalBio, setModalBio] = useState(false);
  const [modalComDet, setModalComdet] = useState(false);
  const [modalB, setModalB] = useState(false);
  const [modalR, setModalR] = useState(false);
  const [modalId, setModalId] = useState("");
  const [modalCom, setModalCom] = useState(false);
  const [CommodalId, setComModalId] = useState("");
  // const [BulletinBoardSingleData, setBulletinBoardSingleData] = useState({});
  const [top, setTop] = useState(null);
  const [left, setLeft] = useState(null);
  const [initiativeData, setInitiativeData] = useState({});
  const [viewJobDetails, setViewJobDetails] = useState(false);
  const [CompanyData, setCompanyData] = useState({});
  const [viewComDetails, setViewComDetails] = useState(false);
  const [bio, setBio] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const appliedJobData = useSelector((state) => state?.badgeSlice?.appliedJob);
  const jobreq = useSelector((state) => state?.jobRecruitmentSlice?.jobreq);
  const jobpost = useSelector((state) => state?.jobRecruitmentSlice?.jobpost);
  const biodata = useSelector((state) => state?.bioSlice?.data)
  const cdetails = useSelector((state) => state?.bioSlice?.cdetails)
  const comDetails = useSelector((state) => state?.bioSlice?.comDetails)
  const jobs = useSelector((state) => state?.jobSlice?.jobs);
  const [appliedJobId, setappliedJobId] = useState("");
  const [ownPost, setOwnPost] = useState([]);
  const [appliedid, setAppliedid] = useState("");

  const [emojimodal, setEmojimodal] = useState(false);
  // const [repostModal, setRepostModal] = useState(false);
  // const [singleView, setSingleView] = useState({});
  // const [showComment, setShowComment] = useState(true);
  // const [commentView, setCommentView] = useState(true);
  // const [showModal, setShowModal] = useState(false);
  const [commentId, setCommentId] = useState("")
  const [commentData, setCommentData] = useState([]);
  const [commentLoading, setCommentLoading] = useState(false);
  const [changeShow, setChangeShow] = useState(false);
  const navigate = useNavigate();



  const initialValues = {
    badgeID: "",
    // image: "",
    desc: "",
    // employeeID: "",
    email:[]
  };

  const initialValues1 = {
    introduction: "",
    education: "",
    city: "",
    state: "",
    industry: "",
    about: "",
    certificate: "",
    phone: "",
    mail: ""
  };

  const initialValues7 = {
    groupName: "",
    grouptype: "",
    charter: "",
    purpose: "",
    // addEmp: []
  };

  useEffect(() => {
    window.scroll(0, 0);
    ViewAllSkill();
    HandleSkill(skillData[0]?._id);
    getGoldCard();

  }, []);

  useEffect(() => {
    HandleSkill(skillData[0]?._id);
  }, [skillData]);


  //for get comment
  const getComment = async () => {
    let data = {
      postID: commentId,
    };
    setCommentLoading(true);
    let urlPath = "/view-comment";
    let res = await ApiHelperFunction({ urlPath, method: "POST", data });
    // console.log("ResponseDATA", res?.data?.data?.replyData);
    if (res?.status) {
      setCommentData(res?.data?.data);
      // setReplyData(res?.data?.data?.replyData);
      setCommentLoading(false);
    } else {
      setCommentLoading(false)
      // toast.error(res?.data?.message || "Error Fetching User Details");
    }
  };


  // console.log("commentId", commentId)
  useEffect(() => {
    if (commentId) {
      getComment();
      // getReply();
    }
  }, [commentId])


  const handleOptionClick = (index) => {
    setChangeShow(index === modalId);
  };


  // to show delete edit icon
  const showEditDeleIconMemo = useCallback((item) => {
    if (userData?.userType === "Admin" && item?.userType !== "Owner") {
      return true
    } else if (userData?._id === item?.userID) {
      return true
    } else {
      return false
    }

  }, [userData])

  //for Clicking Bio
  const handleClickBio = (e, ele, index) => {
    setModalId(ele._id);
    setInitiativeData(ele);
    setTop(e.clientY);
    setLeft(e.clientX);
    setModalR(!modalR);
  };

  //for Clicking Company Id
  const handleClickCompany = (e, ele, index) => {
    setComModalId(ele._id);
    setCompanyData(ele);
    setTop(e.clientY);
    setLeft(e.clientX);
    setModalCom(!modalCom);
  };

  //for clicking  bio
  const handleInitiativeClick = (ele) => {
    setInitiativeData(ele);
    setViewDetails(true);
  };

  //for clicking Company
  const handleCompanyClick = (ele) => {
    setCompanyData(ele);
    setViewComDetails(true);
  };

  //for clicking applied job 
  const handleAppliedJobClick = (ele) => {
    navigate(`/jobssingledetails/${ele?.jobTitle?.replace(/\s+/g, '-')}/${ele?.jobId}`);
    // setViewJobDetails(true);
    // setappliedJobId(ele?.jobId);
  };

  // const getJobDetails = () => {
  //   return jobs?.find(job => job?._id === appliedJobId);
  // };

  // Function to fetch job details based on jobId
  const getJobDetails = () => {
    return jobs?.find(job => job?._id === appliedJobId);
  };

  //for delete functionality
  const handleDelete = async () => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-bio/${modalId}`,
      method: "PUT",
    });
    if (res?.status) {
      toast.success("Deleted Successfully");

      dispatch(getBio());
      setModalR(false);
    } else {
      toast.error(res?.response?.data?.message || "Something went wrong");
      console.log("ERROR CREATING USER3", res);
    }
  };

  //for company delete functionality
  const handleDeleteCom = async () => {
    const res = await ApiHelperFunction({
      urlPath: `/delete-company-details/${CommodalId}`,
      method: "PUT",
    });
    if (res?.status) {
      toast.success("Deleted Successfully");

      dispatch(getCompanyDetails());
      setModalCom(false);
    } else {
      toast.error(res?.response?.data?.message || "Something went wrong");
      // console.log("ERROR CREATING USER3", res);
    }
  };

  //for progess bar
  const rating = Math?.round(AvgRating?.avgRating);
  const renderProgressBars = () => {
    const progressBars = [];

    for (let i = 5; i >= 1; i--) {
      progressBars?.push(
        <div className="progress_wrap" key={i}>
          <span className="number">{i}</span>
          <span className="icon">
            <i className="fa-solid fa-star" />
          </span>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${(i <= rating) ? (i * 20) + '%' : '0%'}` }}
              aria-valuenow={25}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
          <span className="amount">{(i <= rating) ? 1 : 0}</span>
        </div>
      );
    }

    return progressBars;
  };

  //for rating
  // const Rating = (val) => {
  //   console.log("VALA", val);
  //   switch (val) {
  //     case 1:
  //       return <i className="fa-solid fa-star" />;
  //     case 2:
  //       return (
  //         <>
  //           <i className="fa-solid fa-star" />
  //           <i className="fa-solid fa-star" />
  //         </>
  //       );

  //     case 3:
  //       return (
  //         <>
  //           <i className="fa-solid fa-star" />
  //           <i className="fa-solid fa-star" />
  //           <i className="fa-solid fa-star" />
  //         </>
  //       );
  //     case 4:
  //       return (
  //         <>
  //           <i className="fa-solid fa-star" />
  //           <i className="fa-solid fa-star" />
  //           <i className="fa-solid fa-star" />
  //           <i className="fa-solid fa-star" />
  //         </>
  //       );
  //     case 5:
  //       return (
  //         <>
  //           <i className="fa-solid fa-star" />
  //           <i className="fa-solid fa-star" />
  //           <i className="fa-solid fa-star" />
  //           <i className="fa-solid fa-star" />
  //           <i className="fa-solid fa-star" />
  //         </>
  //       );

  //     default:
  //       return null;
  //   }
  //   // for(let i=0 ; i< val ; i++){
  //   // return(
  //   // <i className="fa-solid fa-star" />
  //   // )
  //   // }
  // };

  //gold card
  const getGoldCard = async () => {
    setLoading(true);

    const response = await ApiHelperFunction({
      urlPath: "/get-profile",
      method: "GET",
    });

    console.log("responseeeee", response?.data);

    if (response.status === 200) {
      setGoldCard(response?.data?.data);
      // console.log("data get successfully");
    } else {
      // toast.error(response?.message);
    }
    setLoading(false);
  };


  //for view skill rating
  const HandleSkill = async (id) => {
    let data = {
      id: id,
    };
    let response = await ApiHelperFunction({
      urlPath: "/view-skill-rating",
      method: "POST",
      data,
    });
    console.log("ressdd", response?.data)
    if (response?.status) {
      setParticularSkillData(response?.data?.data[0]?.reviewData);
      setAvgRating(response?.data?.data[0]);
      setRatingData(response?.data?.data[0]?.reviewCountData)
      // console.log("DATADFF", response?.data);
    } else {
      // toast.error("Error fetching skill data");
    }
  };

  // const SkillRating = async(ele) => {
  //   let data =
  //     {
  //       "id":ele?._id
  //     }

  //   let response= await ApiHelperFunction({ urlPath:"view-skill-rating", method:"POST" , data})
  //   if(response && response?.status){
  //     console.log("RESDSDSDfsdfgsdf", response);
  //   }else{
  //       toast.error('Error fetching skill rating data')
  //   }
  // }

  const handleClick = () => {
    setSkillmodal(!skillmodal);
  };

  const rewardClick = () => {
    setReward(!reward)
  }

  const bioClick = () => {
    setBio(!bio)
  }


  //for view skills
  const ViewAllSkill = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/view-skill",
      method: "GET",
    });
    if (response?.status) {
      console.log("RESDSDSD", response);
      setSkillData(response?.data?.data);
    } else {
      // toast.error("Error fetching skill data");
    }
  };

  // const handleChange = async (e) => {
  //   let file = e.target.files[0];
  //   const data = new FormData();
  //   setProfileImage(URL.createObjectURL(file));
  //   data.append("image", file);

  //   let res = await fileUpload("update-cover-image", "PUT", data);

  //   if (res.status) {
  //     // setImageUrl(res?.image);
  //     ProfileUpdate(res?.coverImage, "coverImage");
  //   } else {
  //     toast.error("Error uploading image");
  //   }
  //   // setUploading(false);
  // };

  // const handleChange = async (e) => {
  //   let file = e.target.files[0];
  //   const data = new FormData();
  //   data.append("image", file);

  //   let res = await fileUpload("/image-upload", "POST", data);

  //   if (res.status) {
  //     // setImageUrl(res?.image);
  //     ProfileUpdate(res?.image, "profileImage");
  //   } else {
  //     toast.error("Error uploading image");
  //   }
  //   // setUploading(false);
  // };

  // const handleChange = async (e) => {
  //   let file = e.target.files[0];
  //   const data = new FormData();
  //   data.append("image", file);

  //   let res = await fileUpload("/image-upload", "POST", data);

  //   if (res.status) {
  //     // setImageUrl(res?.image);
  //     updateCoverImage(res?.image);
  //   } else {
  //     toast.error("Error uploading image");
  //   }
  //   // setUploading(false);
  // };

  //update cover image
  // const updateCoverImage = async (img) => {
  //   let data = {
  //     coverImage: img,
  //   };
  //   let response2 = await ApiHelperFunction({
  //     urlPath: "/update-cover-image",
  //     method: "PUT",
  //     data,
  //   });

  //   console.log(data, response2, "responkdhjli");

  //   if (response2?.status) {
  //     toast.success("Cover picture updated successfully");
  //     getUserDetails();
  //   } else {
  //     // toast.error("Error fetching data");
  //   }
  // };

  // const ProfileUpdate = async (coverImage, image, val) => {
  //   let data;
  //   if (val === "coverImage") {
  //     let data2 = {
  //       coverImage: coverImage,
  //     };
  //     let response2 = await ApiHelperFunction({
  //       urlPath: "/update-cover-image",
  //       method: "PUT",
  //       data2,
  //     });

  //     console.log(data2, "responkdhjli");

  //     if (response2?.status) {
  //       toast.success("Cover picture updated successfully");
  //       getUserDetails();
  //     } else {
  //       // toast.error("Error fetching data");
  //     }
  //   } else {
  //     data = {
  //       image: image,
  //     };
  //   }
  //   console.log("DATAGHJDHAJKS", data);
  //   let response = await ApiHelperFunction({
  //     urlPath: "/update-profile",
  //     method: "PUT",
  //     data,
  //   });
  //   if (response?.status) {
  //     toast.success("Profile Picture updated successfully");
  //     getUserDetails();
  //   } else {
  //     // toast.error("Error fetching data");
  //   }
  // };

  const [modalA, setModalA] = useState(false);
  const [modalP, setModalP] = useState(false);

  // function openModalOne() {
  //   setModalA(true);
  // }


  //for badge post in home page
  // const handlePost = async (e, item) => {
  //   let data = {
  //     description: `${item?.employeeFName} has got ${item?.employeeLName} ${item?.badgeName}`,
  //     image: [item.image],
  //     postType: "public",
  //   };

  //   // return;
  //   let response = await ApiHelperFunction({
  //     urlPath: "/user-add-post",
  //     method: "POST",
  //     data: data,
  //   });

  //   if (response?.status === 200) {
  //     // setPost({
  //     //   description: "",
  //     //   image: "",
  //     // });
  //     toast.success(response?.data?.message);
  //     dispatch(getAllPosts());
  //   } else {
  //     toast.error(response?.response?.data?.message);
  //   }

  //   // try {
  //   //   // Dispatch the addPosts action with the data
  //   //   await dispatch(addPosts(data));

  //   //   // Optionally, you can also dispatch getAllPosts to update the posts list
  //   //   await dispatch(getAllPosts());

  //   //   toast.success("Post added successfully");
  //   // } catch (error) {
  //   //   toast.error("Error adding post");
  //   // }
  // };

  //for load more functionality
  const numberOfInitialRecords = 1
  const numberOfRecordsPage = 1
  const [totalRecordsToDisplay, setTotalRecordsToDisplay] = useState(numberOfInitialRecords)
  const loadMoreItems = () => {
    setTotalRecordsToDisplay(totalRecordsToDisplay + numberOfRecordsPage)
  }
  const onTabClick = (e, index) => {
    setActiveTab(index);

  };

  // //for viewing friendList
  // const ViewAllfriendList = async () => {
  //   let response = await ApiHelperFunction({
  //     urlPath: "/get-follower-friend-list",
  //     method: "GET",
  //   });
  //   if (response?.status) {
  //     // console.log("RESDSDSDiop", response);
  //     setfriendList(response?.data?.data);
  //   } else {
  //     // toast.error("Error fetching skill data");
  //   }
  // };

  // //for viewing followingList
  // const ViewAllfollowingList = async () => {
  //   let response = await ApiHelperFunction({
  //     urlPath: "/get-following-friend-list",
  //     method: "GET",
  //   });
  //   if (response?.status) {
  //     console.log("RESDSDSDiop", response);
  //     setfollowingList(response?.data?.data);
  //   } else {
  //     // toast.error("Error fetching skill data");
  //   }
  // };


  //for navigate follower page
  const handleFollowerClick = () => {
    // const data = {
    //   friendList: friendList,
    //   followingList: followingList
    // }
    navigate("/followers")
  }

  const handleBadgeClick=()=>{
    setModalP(!modalP)
  }

  const handlecerrtificate = () => {
    navigate('/certificatemain')
  }

  const handlehiring = (id) => {
    navigate(`/hiringdetials/${id}`)
  }

  const handleJobReqClick = (id) => {
    navigate(`/hiringdetials/${id}`)
  }


  useEffect(() => {
    dispatch(getBadgeData());
    dispatch(getAppliedJobData());
    dispatch(getBio());
    getUserDetails();
    // getJobDetails();
    dispatch(getRefferedJobs());
    // ViewAllfriendList();
    // ViewAllfollowingList();
    ViewAllSinglePost();
    dispatch(getAllPosts());
    dispatch(getFollowingData());
    dispatch(getFollowerData());
    dispatch(getJobReq());
    dispatch(getJobpost());
    dispatch(getCompanyDetails());
    dispatch(getCDetils());
  }, []);

  useEffect(() => {
    getJobDetails();
  }, [appliedJobId])

  useEffect(() => {
    // console.log("dfgghg", skillData);
    HandleSkill(skillData?.[activeTab]?._id)
  }, [activeTab])


  const [activeTabown, setActiveTabown] = useState(0);
  const handleTabClick = (index) => {
    setActiveTabown(index);
  };


  //for viewing own post
  const ViewAllSinglePost = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/view-single-user-post",
      method: "GET",
    });
    if (response?.status) {
      // console.log("RESDSDSDiop", response);
      const allposts = [...response?.data?.data,
      ...response?.data?.advocacyPost,
      ...response?.data?.eventData,
      ...response?.data?.giftCards,
      ...response?.data?.repostData,
      ...response?.data?.receivedBadge
      ]
      const sortedData = allposts?.sort((a, b) => new Date(b?.createdOn) - new Date(a?.createdOn))
      setOwnPost(sortedData);
    } else {

    }
  };


  //for update reaction
  // const handleReactionSelect = async (postId, reactionType) => {
  //   setShowReaction(reactionType)
  //   setEmojimodal(false)

  //   setLikeLoading(true);
  //   setIsHovered(true);
  //   // setSelectedReaction(reactionType);

  //   const response = await ApiHelperFunction({
  //     urlPath: "/add-reaction",
  //     method: "POST",
  //     data: {
  //       postID: postId,
  //       reactionData: reactionType,
  //     },
  //   });

  //   if (response.status === 200) {
  //     dispatch(getAllPosts());
  //     // console.log("Reaction added/updated successfully!");
  //   } else {
  //     // console.log("Failed to add/update reaction");
  //   }
  //   setTimeout(() => {
  //     setLikeLoading(false);
  //     setIsHovered(false);
  //   }, 100);
  // };

  // console.log("cvsdui", ownPost)


  // const handleemojiClick = () => {
  //   setEmojimodal(true)
  //   setIsHovered(true);
  // }
  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // }


  // const reactionIconMemo = useMemo(() => {
  //   return singleView?.[0]?.reactionData?.find(item => item?.userId === userData?._id)
  // }, [singleView, userData]);

  // const handleItemClick=(item)=>{
  //   setOwnPostItem(item)
  // }

  useEffect(() => {

    const closeEmojiModalll = (event) => {
      if (!event.target.closest('.emojimodalbg') && !event.target.closest('.reaction')) {
        setEmojimodal(false);
      }
    };

    document.addEventListener('click', closeEmojiModalll);

    return () => {
      document.removeEventListener('click', closeEmojiModalll);
    };
  }, []);

  const [viewbrndd, setViewbrndd] = useState(false)
  const handleviewclick = (ele) => {
    setViewbrndd(!viewbrndd)
    setAppliedid(ele?._id);
  }

  return (
    <>
      <section className="profilePageSection">
        <div className="profileCvrPicDivprflnew">
          <figure className="profileBackFigure">
            {userData?.coverImage && (
              <img src={userData?.coverImage} alt="..." />
            )}
          </figure>
          <div>

          </div>
        </div>


        <div className="employeepagecontainer">
          <div className="employeecalederflx">
            <div className="engageplacecardlistwhh profilerightscrngh">
              {
                userData?.userType === "Owner"
                  ?
                  (<div className="user_aboutnbet">
                    <div className="bg-userdtwht" style={{ backgroundColor: `${userData?.rewardCard?.[0]?.cardColor}` }}>
                      <div className="plthclbtx">

                      </div>
                      <div className="" style={{ backgroundColor: "#fff", height: "72%", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", borderTopRightRadius: "80px" }}>
                        <div className="divpaddingghh">
                          <div className="prflimgdiv">
                            <div className="prflimgbrdrcompany icondivcmr ">
                              <div className="prflimgfh">
                                <img src={userData?.image} alt="..." />
                              </div>
                              <div className="mrkInpFigDiv">

                                <Link
                                  className="dropdown-item"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setModal(true)}
                                >
                                  <div className="">
                                    <figure className="mrkCamFig">
                                      <img src={Edit} alt="..." />
                                    </figure>
                                  </div>
                                </Link>
                              </div>
                            </div>

                          </div>

                          <div className="">
                            <p className="txtppghtcpmaytxtp">{`${userData?.firstName} ${userData.lastName}`}</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>)
                  :
                  (<div className="user_aboutnbet">
                    <div className="bg-userdtwht" style={{ backgroundColor: `${userData?.rewardCard?.[0]?.cardColor}` }}>
                      <div className="plthclbtx">
                        <p className="bghhkpp">{userData?.rewardCard?.[0]?.cardName}</p>
                        <p className="clbpp">Club</p>
                      </div>
                      <div className="" style={{ backgroundColor: "#fff", height: "72%", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", borderTopRightRadius: "80px" }}>
                        <div className="divpaddingghh">
                          <div className="prflimgdiv">
                            <div className="prflimgbrdr icondivcmr ">
                              <div className="prflimgfh">
                                <img src={userData?.image} alt="..." />
                              </div>
                              <div className="mrkInpFigDiv">

                                <Link
                                  className="dropdown-item"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setModal(true)}
                                >
                                  {/* <i className="fa-solid fa-user mr-2"></i> */}
                                  <div className="">
                                    <figure className="mrkCamFig">
                                      {/* <img src={camera} alt="..." /> */}
                                      {/* <i class="fa-regular fa-pen-to-square"></i> */}
                                      <img src={Edit} alt="..." />
                                    </figure>
                                  </div>
                                </Link>
                                {/* ) : (
                    <></>
                  )} */}
                              </div>
                            </div>
                            <div className="divpatyn">
                              <p className="thounsabpp" style={{ color: `${userData?.rewardCard?.[0]?.cardColor}` }}>{userData?.rewardPoints}</p>
                              <p className="pointshttx">POINTS BALANCE</p>
                            </div>
                          </div>

                          <div className="tstdfghmrl">
                            <p className="txtppght">{`${userData?.firstName} ${userData.lastName}`}</p>
                            {/* {
                          userData?.userType !== "Admin" ? (<p className="dvjknjpp">{userData?.designation}</p>) : (<></>)
                        } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>)
              }
              <div className="hobyagfondiv">


                <div className="followerbtnmainconnetion">

                  <button className="followerbtn" onClick={handleFollowerClick}>
                    Connections
                  </button>

                </div>

                <div className="hobbyPersoDiv">

                  <AspiringHobby hobbyDatas={userData?.hobbies} />
                </div>

                {
                  userData?.userType === "Admin" || userData?.userType === "Manager" || userData?.userType === "Owner"
                    ?
                    (
                      <div className="followerbtnmainconnetion">
                        <button className="followerbtn" onClick={() => navigate('/leaveReqList')}>
                          Leave Management
                        </button>
                      </div>
                    )
                    :
                    (
                      <></>
                    )
                }


                <div className="">
                  <div className="rewarddiv">
                    {
                      userData?.userType === "Owner"
                        ?
                        (
                          <div className="rewarddivflx">
                            <p className="hobbyAspHead">Company Details</p>
                            {
                              comDetails?.length === 0 &&
                              <figure className="bulleBoardFig" onClick={() => setCompanyClick(true)}>
                                <img src={add_icon} alt="..." />
                              </figure>
                            }

                          </div>
                        )
                        :
                        (
                          <div className="rewarddivflx">
                            <p className="hobbyAspHead">Bio</p>
                            {
                              biodata?.length === 0 &&
                              <figure className="bulleBoardFig" onClick={bioClick}>
                                <img src={add_icon} alt="..." />
                              </figure>
                            }
                          </div>
                        )
                    }



                    <div className="bottom_ara ">
                      <>
                        {
                          userData?.userType === "Owner" &&
                          comDetails?.length > 0 && (
                            comDetails?.map((item, index) => {
                              return (<div className="card_area">
                                <div className="top">
                                  {/* <div className="name introdivprfltxt">
                                    <h4>{item?.introduction}</h4>
                                  </div> */}
                                  <p className="cntntxtbioplll"> Company Name :
                                    <span>
                                      {cdetails?.companyName}
                                    </span>
                                  </p>

                                  {userData?._id && (
                                    <div
                                      className=""
                                      onClick={(e) => handleClickCompany(e, item, index)}
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <a className="verticaldotthrf">
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                      </a>
                                      {CommodalId === item?._id && modalCom && (
                                        <div
                                          style={{
                                            overflow: "hidden",
                                            backgroundColor: "#fff",
                                            padding: "3px",
                                            border: "none",
                                            top: "13px",
                                            padding: "3px 8px",
                                            left: "-96px",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                            boxShadow: "0 0 0.1rem 0",
                                            position: "absolute",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            columnGap: "5px"
                                          }}
                                        >

                                          <div className="dltimgdiv" onClick={handleDeleteCom}>
                                            <img src={Delete} alt="..." />
                                          </div>
                                          <div className="dltimgdiv" onClick={() => setModalComdet(true)}>
                                            <img src={Edit} alt="..." />
                                          </div>
                                          <div className="dltimgdiv" onClick={() => handleCompanyClick(item)}>
                                            <img src={View} alt="..." />
                                          </div>

                                        </div>

                                      )}
                                    </div>
                                  )}

                                </div>

                                <div className="content_area">

                                  <p className="cntntxtbioplll"> CEO :
                                    <span>
                                      {item?.nameOfCeo}
                                    </span>
                                  </p>
                                  <p className="cntntxtbioplll"> Address :
                                    <span>
                                      {item?.city},{item?.state}
                                    </span>
                                  </p>

                                  <div className="indstrtviewflxa">
                                    <p className="cntntxtbioplll"> Industry Type:
                                      <span>
                                        {item?.typeOfIndustry}
                                      </span>
                                    </p>

                                  </div>
                                </div>

                              </div>)
                            })
                          )
                        }

                        {
                          biodata?.length > 0 && (
                            biodata?.map((item, index) => {
                              return (<div className="card_area">
                                <div className="top">
                                  <div className="name introdivprfltxt">
                                    <h4>Introduction : {item?.introduction?.slice(0, 20)}</h4>
                                  </div>


                                  {userData?._id && (
                                    <div
                                      className=""
                                      onClick={(e) => handleClickBio(e, item, index)}
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <a className="verticaldotthrf">
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                      </a>
                                      {modalId === item?._id && modalR && (
                                        <div
                                          style={{
                                            // position: "absolute",
                                            overflow: "hidden",
                                            backgroundColor: "#fff",
                                            padding: "3px",
                                            border: "none",
                                            top: "13px",
                                            padding: "3px 8px",
                                            left: "-96px",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                            boxShadow: "0 0 0.1rem 0",
                                            position: "absolute",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            columnGap: "5px"
                                          }}
                                        >

                                          <div className="dltimgdiv" onClick={handleDelete}>
                                            <img src={Delete} alt="..." />
                                          </div>
                                          <div className="dltimgdiv" onClick={() => setModalBio(true)}>
                                            <img src={Edit} alt="..." />
                                          </div>
                                          {/* <div className="dltimgdiv" onClick={() => handleInitiativeClick(item)}>
                                            <img src={View} alt="..." />
                                          </div> */}

                                        </div>

                                      )}
                                    </div>
                                  )}

                                </div>

                                <div className="content_area">

                                  <p className="cntntxtbioplll">  Education :
                                    <span>
                                      {item?.education}
                                    </span>
                                  </p>
                                  <p className="cntntxtbioplll"> Address :
                                    <span>
                                      {item?.city},{item?.state}
                                    </span>
                                  </p>

                                  <div className="indstrtviewflxa">
                                    <p className="cntntxtbioplll"> Industry :
                                      <span>
                                        {item?.industry}
                                      </span>
                                    </p>
                                    <button className="viewbtndivlk" onClick={() => handleInitiativeClick(item)}>View</button>
                                  </div>
                                </div>

                              </div>)
                            })
                          )
                        }
                      </>
                    </div>

                  </div>
                </div>

                {userData?.userType !== "Owner" && userData?.certificateData?.length > 0 ?
                  <div className="followerbtnmainconnetion">
                    <button className="followerbtn" onClick={handlecerrtificate}>
                      Certificate
                    </button>
                  </div>
                  :
                  <></>
                }


                {
                  userData?.userType !== "Owner" &&
                  <div className="">
                    <div className="rewarddiv">
                      <div className="rewarddivflx">
                        <p className="hobbyAspHead">Rewards Point Details</p>
                        <figure className="bulleBoardFig" onClick={rewardClick}>
                          <img src={View} alt="..." />
                        </figure>
                      </div>
                    </div>
                  </div>
                }


                {/* for Manager Requested Hiring job*/}
                {
                  userData?.userType === "Manager" && (
                    <div className="rewardspoint">
                      <div className="rewarddiv">
                        <div className="rewarddivflx">
                          <p className="hobbyAspHead">Requested Hiring</p>
                          {
                            userData?.userType === "Manager" &&
                            (
                              <figure className="bulleBoardFig" onClick={() => handlehiring(jobreq?.[0]?._id)}>
                                <img src={ExpandGradient} alt="..." />
                              </figure>
                            )
                          }

                        </div>
                        <div className="bottomareadibvmain">
                          {
                            jobreq?.map((item) => {
                              return (
                                <div className="bottomareadibvmainbg" style={{ cursor: "pointer" }} onClick={() => handleJobReqClick(item?._id)}>
                                  <p className="bhsbvbbshhhtghpppn">
                                    Job Title :
                                    <span>
                                      {item?.jobTitle}
                                    </span>
                                  </p>
                                  <p className="bhsbvbbshhhtghpppn">
                                    Location :
                                    <span>
                                      {item?.jobLocation?.slice(0, -1).join(", ")
                                        + (item?.jobLocation?.length > 1 ? "," : "")
                                        + (item?.jobLocation?.length > 1 ? " and " : "")
                                        + item?.jobLocation.slice(-1)}
                                    </span>
                                  </p>
                                  <p className="bhsbvbbshhhtghpppn">
                                    Skill :
                                    <span>
                                      {item?.keySkills.slice(0, -1).join(", ")
                                        + (item?.keySkills?.length > 1 ? "," : "")
                                        + (item?.keySkills?.length > 1 ? " and " : "")
                                        + item?.keySkills?.slice(-1)}
                                    </span>
                                  </p>
                                  <p className="bhsbvbbshhhtghpppn">
                                    No. of Opening :
                                    <span>
                                      {item?.openings}
                                    </span>
                                  </p>

                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )
                }


                {/* for Hr Requested Hiring job */}
                {
                  userData?.userType === "Admin" || userData?.userType === "Owner" ? (
                    <div className="rewardspoint">
                      <div className="rewarddiv">
                        <div className="rewarddivflx">
                          <p className="hobbyAspHead">Requested Hiring</p>
                          {
                            userData?.userType === "Admin" && (
                              <figure className="bulleBoardFig" onClick={() => handlehiring(jobpost?.[0]?._id)}>
                                <img src={ExpandGradient} alt="..." />
                              </figure>
                            )
                          }

                        </div>
                        <div className="bottomareadibvmain">
                          {
                            jobpost?.map((item) => {
                              return (
                                <div className="bottomareadibvmainbg" style={{ cursor: "pointer" }} onClick={() => handleJobReqClick(item?._id)}>
                                  <p className="bhsbvbbshhhtghpppn">
                                    Job Title :
                                    <span>
                                      {item?.jobTitle}
                                    </span>
                                  </p>
                                  <p className="bhsbvbbshhhtghpppn">
                                    Location :
                                    <span>
                                      {item?.jobLocation?.slice(0, -1).join(", ")
                                        + (item?.jobLocation?.length > 1 ? "," : "")
                                        + (item?.jobLocation?.length > 1 ? " and " : "")
                                        + item?.jobLocation?.slice(-1)}
                                    </span>
                                  </p>
                                  <p className="bhsbvbbshhhtghpppn">
                                    Skill :
                                    <span>
                                      {item?.keySkills?.slice(0, -1).join(", ")
                                        + (item?.keySkills?.length > 1 ? "," : "")
                                        + (item?.keySkills?.length > 1 ? " and " : "")
                                        + item?.keySkills?.slice(-1)}
                                    </span>
                                  </p>
                                  <p className="bhsbvbbshhhtghpppn">
                                    No. of Opening :
                                    <span>
                                      {item?.openings}
                                    </span>
                                  </p>

                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )
                    :
                    <></>
                }

              </div>


              {/* 
                <div className="rateidivc">
                  <RateSkills />
                </div> */}

            </div>



            {
              userData?.userType === "Owner"
                ?
                (
                  <div className="calenderwhhhperfl ">

                    <div className="profilemidscrngh">
                      <div className="tab-buttons">
                        {['Activitity Log']?.map((tab, index) => (
                          <button
                            key={index}
                            className={index === activeTabown ? 'activeprofilebtnh' : 'notactivebtnprfl'}
                            onClick={() => handleTabClick(index)}
                          >
                            {tab}
                          </button>
                        ))}
                      </div>
                      <div className="tab-content">
                        {activeTabown === 0 &&
                          <div className="ownpostdivhjhkjhkjl">
                            {/* {
                            userData?.giftCards ? (<div className="">
                              <Cards />
                            </div>) : (<></>)
                          } */}

                            {ownPost?.length > 0 ? (
                              ownPost?.map((item, index) => {
                                // console.log("ertioy", item);
                                return (
                                  <SaraTylorProfile
                                    index={index}
                                    userID={item?.userData?.[0]?.adminID}
                                    key={item?._id}
                                    handleOptionClick={handleOptionClick}
                                    setModalA={setModalA}
                                    modalA={modalA}
                                    modalB={modalB}
                                    modalId={modalId}
                                    isUserReacted={item?.isUserReacted}
                                    postId={item?._id}
                                    name={item?.userData?.[0]?.firstName + " " + item?.userData?.[0]?.lastName}
                                    imagesArray={item}
                                    createdOn={moment(item?.createdOn).format("YYYY-MM-DD")}
                                    reaction={item?.totalReaction}
                                    description={item?.description || item?.notes || item?.cardDesc || item?.desc}
                                    showDescription={item?.showDescription}
                                    optionShow={showEditDeleIconMemo(item)}
                                    setModalB={setModalB}
                                    setModalId={setModalId}
                                    modalK={modalK}
                                    setModalK={setModalK}
                                    changeShow={modalId === item?._id}
                                    showComment={modalId === item?._id}
                                    // showComment={handleShowCommentClick}
                                    commentId={commentId}
                                    setCommentId={setCommentId}
                                    commentData={commentData}
                                    getComment={getComment}
                                    commentLoading={commentLoading}
                                    singleData={item}
                                  />
                                );
                              })
                            ) : (
                              <p>No post Found!</p>
                            )}
                          </div>
                        }

                      </div>
                    </div>

                  </div>
                )
                :
                (<div className="calenderwhhhperfl">
                  <div className="profilemidscrngh">
                    <div className="tab-buttons">
                      {['Activitity Log', 'Skill Validation']?.map((tab, index) => (
                        <button
                          key={index}
                          className={index === activeTabown ? 'activeprofilebtnh' : 'notactivebtnprfl'}
                          onClick={() => handleTabClick(index)}
                        >
                          {tab}
                        </button>
                      ))}
                    </div>
                    <div className="tab-content">
                      {activeTabown === 1 &&
                        <div className="testdivbfkd">
                          <div className="myTestRatingDiv">
                            <div className="myVegsDiv">
                              {/* <figure className="myVetFig">
                                  <img src={Skills} alt="..." />
                                </figure> */}
                              <p className="hobbyAspHeadslklvtxt">
                                Validate My Skills
                              </p>
                              <div className="addicondivflkj" onClick={handleClick}>
                                <figure className="bulleBoardFig">
                                  <img src={WhiteEdit} alt="..." />
                                </figure>
                              </div>
                            </div>

                            <div>
                              <div className="scrollbarandaddiconflx">
                                <Tabs activeTab={activeTab} onTabClick={onTabClick}

                                >
                                  {/* <div className="tabListAddBtnDiv"> */}
                                  {/* <div className="tabListPartDiv"> */}


                                  {skillData &&
                                    skillData?.map((ele, index) => {
                                      return (
                                        <Tab
                                          key={index}
                                          onClick={() => {
                                            console.log("resddff")
                                            // HandleSkill(ele?._id)
                                          }
                                          }
                                        >
                                          {ele?.skill}
                                        </Tab>
                                      );
                                    })}
                                </Tabs>
                                {/* </div> */}
                                {/* <div className="" onClick={handleClick}>
                                  <figure className="bulleBoardFig">
                                    <img src={add_icon} alt="..." />
                                  </figure>
                                </div> */}
                              </div>
                              {/* </div> */}


                              {skillData &&
                                skillData?.map((ele, index) => {
                                  return (
                                    <TabScreen key={index}
                                      activeTab={activeTab}
                                      index={index}
                                      className="some-animation-class somdclassdkko">

                                      {particularSkillData && (
                                        <div id="rating">
                                          <div className="rateJavaHeadDiv">
                                            <p className="rateJavaHead">
                                              {AvgRating?.skillname}: Validated Score
                                            </p>
                                            <div className="rateLinkShareDiv">
                                              {/* <button className="rateLinkedinBtn">
                                                      <i class="fa-brands fa-linkedin"></i>
                                                    </button> */}
                                              {/* <figure className="tabRateIconFig">
                                                      <img src={Img12} alt="..." />
                                                    </figure> */}
                                              <RWebShare
                                                data={{
                                                  // text: `${item?.name}`,
                                                  text: "Elite Employee Experience Hub - Powered by Fractals Global",
                                                  url: "http://52.66.120.170:3004/profile",
                                                  title: "Share job",
                                                }}
                                                sites={[
                                                  "linkedin",
                                                  "facebook",
                                                  "twitter",
                                                  "whatsapp",
                                                  "mail",
                                                  "copy",
                                                ]}

                                                // onClick={() => console.log("shared successfully!")}
                                                onClick={(platform) => {
                                                  if (platform === "copy") {
                                                    // Copy to clipboard logic
                                                    const textToCopy = `Elite Employee Experience Hub - Powered by Fractals Global- http://52.66.120.170:3004/profile`;
                                                    navigator.clipboard.writeText(textToCopy).then(() => {
                                                      console.log("Text copied to clipboard:", textToCopy);
                                                      // You can also show a notification or toast here
                                                      toast.success("Link copied to clipboard!");
                                                    });
                                                  } else {
                                                    // Handle other platforms
                                                    console.log("Shared successfully on", platform);
                                                  }
                                                }}
                                              >
                                                <div className="share">
                                                  <img src={share} alt="..." className="shareIconImg" />
                                                  <span></span>
                                                </div>
                                              </RWebShare>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                                              <div className="rating_text">
                                                <h2>
                                                  {AvgRating?.avgRating}
                                                  {/* {Math?.round(AvgRating?.avgRating)} */}
                                                </h2>
                                                <p>out of</p>
                                                <h2>5.00</h2>
                                              </div>
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-8 col-12">
                                              {/* {particularSkillData ? ( */}
                                              <p className="totalFiveText">
                                                Total reviews
                                                {/* {particularSkillData?.length}{" "}
                                                  reviews given */}
                                              </p>
                                              {/* ) : (
                                                <p className="totalFiveText">
                                                  Total 0 reviews given
                                                </p>
                                              )} */}

                                              <div className="progress_wrap">
                                                <span className="number">5</span>
                                                <span className="icon">
                                                  <i className="fa-solid fa-star" />
                                                </span>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                  />
                                                </div>
                                                <span className="amount">
                                                  {Math.round(ratingData?.[0]?._id) == 5
                                                    ? `${ratingData?.[0]?.totalUser}`
                                                    : 0}
                                                </span>
                                              </div>
                                              <div className="progress_wrap">
                                                <span className="number">4</span>
                                                <span className="icon">
                                                  <i className="fa-solid fa-star" />
                                                </span>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "80%" }}
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                  />
                                                </div>
                                                <span className="amount">
                                                  {Math.round(ratingData?.[1]?._id) == 4
                                                    ? `${ratingData?.[1]?.totalUser}`
                                                    : 0}
                                                </span>
                                              </div>
                                              <div className="progress_wrap">
                                                <span className="number">3</span>
                                                <span className="icon">
                                                  <i className="fa-solid fa-star" />
                                                </span>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "75%" }}
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                  />
                                                </div>
                                                <span className="amount">
                                                  {Math.round(ratingData?.[2]?._id) == 3
                                                    ? `${ratingData?.[2]?.totalUser}`
                                                    : 0}
                                                </span>
                                              </div>
                                              <div className="progress_wrap">
                                                <span className="number">2</span>
                                                <span className="icon">
                                                  <i className="fa-solid fa-star" />
                                                </span>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "30%" }}
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                  />
                                                </div>
                                                <span className="amount">
                                                  {Math.round(ratingData?.[3]?._id) == 2
                                                    ? `${ratingData?.[3]?.totalUser}`
                                                    : 0}
                                                </span>
                                              </div>
                                              <div className="progress_wrap">
                                                <span className="number">1</span>
                                                <span className="icon">
                                                  <i className="fa-solid fa-star" />
                                                </span>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "10%" }}
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                  />
                                                </div>
                                                <span className="amount">
                                                  {Math.round(ratingData?.[4]?._id) == 1
                                                    ? `${ratingData?.[4]?.totalUser}`
                                                    : 0}
                                                </span>
                                              </div>

                                              {/* {renderProgressBars()} */}


                                            </div>
                                            {particularSkillData?.slice(0, totalRecordsToDisplay)?.map((ele) => {
                                              return (
                                                <div className="comment_section">
                                                  <div className="comment_left">
                                                    <div className="comment_person">
                                                      {ele?.image ? (
                                                        <img
                                                          className="img-fluid"
                                                          src={ele?.image}
                                                          alt="pic"
                                                        />
                                                      ) : (
                                                        <img
                                                          className="img-fluid"
                                                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                                                          alt="pic"
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="comment_right">
                                                    <div className="comment_top">
                                                      <div className="comment_top_left">
                                                        <h5>{ele?.reviewedBy}</h5>
                                                        <div className="star">
                                                          {/* {Rating(
                                                                  Math.round(ele?.rating)
                                                                )} */}
                                                        </div>
                                                      </div>
                                                      <div className="comment_top_right">
                                                        {/* <p>5 months ago</p> */}
                                                      </div>
                                                      {/* <div className="comment_icon cmnticnk">
                                                          <button className="cmntLinkBtn">
                                                            <i class="fa-brands fa-linkedin"></i>
                                                          </button>
                                                          <img
                                                            className="img-fluid"
                                                            src={Img12}
                                                            alt="icon"
                                                          />
                                                        </div> */}
                                                    </div>
                                                    <div className="person_description">
                                                      <p>{ele?.review}</p>
                                                    </div>
                                                    {/* <div className="comment_icon">
                                                        <button className="cmntLinkBtn">
                                                          <i class="fa-brands fa-linkedin"></i>
                                                        </button>
                                                        <img
                                                          className="img-fluid"
                                                          src={Img12}
                                                          alt="icon"
                                                        />
                                                      </div> */}
                                                  </div>
                                                </div>
                                              );
                                            })}

                                            {/* <div className="rating_btn">
                                                <button className="review_btn">
                                                  Show More Reviews
                                                </button>
                                              </div> */}

                                            {
                                              totalRecordsToDisplay < particularSkillData?.length && (
                                                <>
                                                  {/* <div class="blog-pagination text-center">
                                                      <button class="btn btn-success" onClick={loadMoreItems}>Load more</button>
                                                    </div> */}
                                                  <div className="rating_btn">
                                                    <button className="review_btn" onClick={loadMoreItems}>
                                                      Show More Reviews
                                                    </button>
                                                  </div>
                                                </>
                                              )
                                            }

                                          </div>
                                        </div>
                                      )}
                                    </TabScreen>
                                  );
                                })}



                            </div>
                          </div>
                        </div>
                      }

                      {activeTabown === 0 &&
                        <div className="ownpostdivhjhkjhkjl">
                          {/* {
                          userData?.giftCards ? (<div className="">
                            <Cards />
                          </div>) : (<></>)
                        } */}

                          {ownPost?.length > 0 ? (
                            ownPost?.map((item, index) => {
                              // console.log("ertioy", item);
                              return (
                                <SaraTylorProfile
                                  index={index}
                                  userID={item?.userData?.[0]?.adminID}
                                  key={item?._id}
                                  handleOptionClick={handleOptionClick}
                                  setModalA={setModalA}
                                  modalA={modalA}
                                  modalB={modalB}
                                  modalId={modalId}
                                  isUserReacted={item?.isUserReacted}
                                  postId={item?._id}
                                  name={item?.userData?.[0]?.firstName + " " + item?.userData?.[0]?.lastName}
                                  imagesArray={item}
                                  createdOn={moment(item?.createdOn).format("YYYY-MM-DD")}
                                  reaction={item?.totalReaction}
                                  description={item?.description || item?.notes}
                                  showDescription={item?.showDescription}
                                  optionShow={showEditDeleIconMemo(item)}
                                  setModalB={setModalB}
                                  setModalId={setModalId}
                                  modalK={modalK}
                                  setModalK={setModalK}
                                  changeShow={modalId === item?._id}
                                  showComment={modalId === item?._id}
                                  // showComment={handleShowCommentClick}
                                  commentId={commentId}
                                  setCommentId={setCommentId}
                                  commentData={commentData}
                                  getComment={getComment}
                                  commentLoading={commentLoading}
                                  singleData={item}
                                />
                              );
                            })
                          ) : (
                            <p>No post Found!</p>
                          )}
                        </div>
                      }

                    </div>
                  </div>

                  {/* <Tabs>
                  <div className='followerrighttopbtmden'>
                    <TabList>
                      <Tab></Tab>
                      <Tab></Tab>
                    </TabList>
                  </div>
                  <div className='followerrighttopbtmdwnl'>
                    <TabPanel>
  
                    </TabPanel>
  
                    <TabPanel>
  
  
  
                    </TabPanel>
                  </div>
                </Tabs> */}




                </div>)
            }



            {viewJobDetails && (
              appliedJobData?.map((value) => {
                return (
                  <AppliedJobDetailsModal closemodal={setViewJobDetails} values={getJobDetails(value?.jobId)} />
                )
              }
              )
            )
            }


            {viewDetails && <BioDetailsModal closemodal={setViewDetails} initiativeData={initiativeData} />}

            {viewComDetails && <ComDetailsModal closemodal={setViewComDetails} CompanyData={CompanyData} />}
            {
              modalBio && (<EditBioModal closemodal={setModalBio}
                activity={"editBio"}
                initialValues={initiativeData} />)
            }

            {
              modalComDet &&
              (<EditComModal closemodal={() => setModalComdet(false)}
                // activity={"editCom"}
                initialValues={CompanyData} />)
            }
            {/* <div className="proSocialCmtDiv">
                <ProSocialCommit />
              </div> */}

            <div className="highlightedsubsrwhh profileleftscrngh">
              {/*  */}


              {
                userData?.userType === "Employee" ? (
                  <div className="rewardspoint">
                    <div className="rewarddiv">
                      <div className="rewarddivflx">
                        <p className="hobbyAspHead">Applied Job</p>
                      </div>


                      <div className="bottom_ara ">
                        {
                          appliedJobData?.length > 0 ? (
                            appliedJobData?.map((item, index) => {
                              console.log("indexkl", index)
                              return (<div className="card_area biobrnmkkjarfbrnm" >
                                <div className="top">
                                  <div className="name introdivprfltxt">
                                    <h4>{item?.jobTitle}</h4>
                                  </div>
                                  <div className="thredothjdjfvnk" onClick={() => handleviewclick(item)}>
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                    {appliedid === item?._id && viewbrndd && <div className="viwebdbfnmmdibn">
                                      <div className="dltimgdiv" onClick={() => handleAppliedJobClick(item)}>
                                        <img src={View} alt="..." />
                                      </div>
                                    </div>
                                    }
                                  </div>
                                </div>

                                <div className="content_area">

                                  <p className="cntntxtbioplll"> Location :

                                    <span>
                                      {item?.jobLocation}
                                    </span>
                                  </p>
                                  <p className="cntntxtbioplll">Openings :
                                    <span>

                                      {item?.openings}
                                    </span>
                                  </p>
                                  <p className="cntntxtbioplll">Salary :
                                    <span>
                                      {item?.salary}
                                    </span>
                                  </p>
                                </div>
                                <div
                                  className="all_profile d-flex align-items-center"
                                >

                                </div>
                                {/* <div className="">
                                  <button className="btnvewdiv" onClick={() => handleAppliedJobClick(item)}>View</button>
                                </div> */}
                              </div>)
                            })
                          ) : (
                            <p>No Applied job to show!</p>
                          )
                        }

                      </div>
                    </div>
                  </div>) : (<></>)
              }

              <div className="myAffitiDiv">
                <HubAffinityGrp />
              </div>

              <div className="followerbtnmainconnetion">
                <button className="followerbtn" onClick={handleBadgeClick}>
                  Send Badge
                </button>
              </div>


              <div className="myAffitiDiv">
                <Savetrainningjob />
              </div>




            </div>

          </div>

        </div>


      </section >
      <Chatbot />

      {modal && <CreateModal closemodal={() => setModal(false)} />
      }
      {
        skillmodal && (
          <AddSkill closemodal={setSkillmodal} ViewAllSkill={ViewAllSkill} />
        )
      }

      {
        modalL && (
          <MyAffinityGroupModal
            closemodal={setModalL}
            activity={"affinity"}
            initialValues={initialValues7}
          />
        )
      }

      {
        modalP && (
          <ProfilePageModal
            closemodal={setModalP}
            activity={"badge"}
            initialValues={initialValues}
          />
        )
      }

      {reward && <Reward closemodal={setReward} userData={userData} />}
      {
        bio && <Bio closemodal={setBio} initialValues={initialValues1} activity={"bio"} />
      }
      {CompanyClick && <CompanyDetails closemodal={setCompanyClick} />}
    </>
  );
}

export default Profile;
