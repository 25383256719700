import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import { useFormik } from 'formik';
import moment from 'moment';

const Applicantacceptmodal = ({ closemodal, acceptModalId,viewJobApplicants }) => {
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const initialValues = {
    applicationStatus: "accept",
    interviewPlace: "",
    interviewDate: "",
    interviewTime: "",
    mailContent: ""
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    let data = {};
    if (
      acceptModalId === "" ||
      values?.applicationStatus === "" ||
      values.interviewPlace === "" ||
      values.interviewDate === "" ||
      values.interviewTime === "" ||
      values.mailContent === ""

    ) {
      return toast.error("All fields required");
    }

    data = {
      id: acceptModalId,
      applicationStatus: "accept",
      interviewPlace: values?.interviewPlace,
      interviewDate: values?.interviewDate,
      interviewTime: values?.interviewTime,
      mailContent: values?.mailContent
    };
    console.log("jobapply", data)
    const res = await ApiHelperFunction({
      urlPath: "/job-application-approval",
      method: "POST",
      data,
    });

    if (res?.status) {
      toast.success(res?.data?.message);
      viewJobApplicants()
      resetForm();
      closemodal();
    } else {
      toast.error(res?.response?.data?.message || "Something went wrong");
    }

  };

  const { values, resetForm, handleChange } =
    useFormik({
      initialValues,
      validateOnChange: shouldValidateOnChange,
      onSubmit: (val) => {
        console.log("val", val);
      },
    });

  return (
    <>
      <div className="createmodal_main">
        <div className="create_modal">

          <div className="createmodalnewdivcontent">
            <div
              className=""

            >
              <div className="bulletintextclose">
                <div className="bulletinimageflxdivmodal">
                  <div className="bulleBoardFig">
                    {/* <img src={Bulletin_Board} alt="..." /> */}
                  </div>
                  <span className="newBulleCreHead">Applicant Modal</span>
                </div>

                <div onClick={() => closemodal()}>
                  <i
                    class="fa-solid fa-xmark"
                    style={{
                      color: "red",
                      fontSize: "22px",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </div>
              <form className="">
                <div className="homePgModInnerInpDivsmain">
                  <p>Interview Place  <span className='astgsrdijxppp'> * </span></p>
                  <input
                    type="text"
                    value={values?.interviewPlace}
                    name="interviewPlace"
                    onChange={handleChange}
                  />
                </div>
                <div className="homePgModInnerInpDivsmain">
                  <p>Interview Date <span className='astgsrdijxppp'> * </span></p>
                  <input
                    type="date"
                    placeholder="Enter Start Date"
                    className="form-control"
                    value={moment(values?.interviewDate).format("YYYY-MM-DD")}
                    name="interviewDate"
                    onChange={handleChange}
                  />
                </div>
                <div className="homePgModInnerInpDivsmain">
                  <p> Time   <span className='astgsrdijxppp'> * </span> </p>
                  <input
                    type="time"
                    id="interviewTime"
                    className="form-control"
                    value={values?.interviewTime}
                    name="interviewTime"
                    onChange={handleChange}
                  />

                </div>
                <div className="homePgModInnerInpDivsmain">
                  <p>Others Notes</p>
                  <textarea
                    id=""
                    cols=""
                    rows="2"
                    value={values?.mailContent}
                    name="mailContent"
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="bulletinsbmtbtndivv">
                  <button
                    type="button"
                    className="bulletinsbmtbtn"
                    onClick={submitHandler}
                  >
                    Submit
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Applicantacceptmodal
