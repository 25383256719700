// import { Chart } from 'chart.js';
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
const Ageanalysis = ({ AgeCount, categoryAge }) => {

    // const optionsage = {
    //     series: [{
    //         name: 'Inflation',
    //         data: [59, 442, 454, 239, 39]
    //     }],
    //     chart: {
    //         height: 200,
    //         type: 'bar',
    //     },
    //     fill: {
    //         opacity: 1,
    //         colors: ['#142553']
    //     },
    //     plotOptions: {
    //         bar: {
    //             borderRadius: 0,
    //             dataLabels: {
    //                 position: 'top', // top, center, bottom
    //             },
    //         }
    //     },
    //     dataLabels: {
    //         enabled: true,
    //         // formatter: function (val) {
    //         //     return val + "%";
    //         // },
    //         offsetY: -20,
    //         style: {
    //             fontSize: '12px',
    //             colors: ["#304758"]
    //         }
    //     },
    //     xaxis: {
    //         categories: ["<25", "25-34", "35-44", "45-55", ">55"],
    //         position: 'bottom',
    //         axisBorder: {
    //             show: false
    //         },
    //         axisTicks: {
    //             show: false
    //         },
    //         // crosshairs: {
    //         //     fill: {
    //         //         type: 'gradient',
    //         //         gradient: {
    //         //             colorFrom: '#D8E3F0',
    //         //             colorTo: '#BED1E6',
    //         //             stops: [0, 100],
    //         //             opacityFrom: 0.4,
    //         //             opacityTo: 0.5,
    //         //         }
    //         //     }
    //         // },
    //         tooltip: {
    //             enabled: true,
    //         }
    //     },
    //     yaxis: {
    //         axisBorder: {
    //             show: false
    //         },
    //         axisTicks: {
    //             show: false,
    //         },
    //         labels: {
    //             show: false,
    //         }
    //     },
    //     // title: {
    //     //     text: 'Monthly Inflation in Argentina, 2002',
    //     //     floating: true,
    //     //     offsetY: 330,
    //     //     align: 'center',
    //     //     style: {
    //     //         color: '#444'
    //     //     }
    //     // }
    // };
    const [chartState, setChartState] = useState({
        // series:[],
        series: [{
            name: 'Age',
            data: AgeCount
        }],
        
        options: {
            chart: {
                height: 200,
                type: 'bar',
            },
            fill: {
                opacity: 1,
                colors: ['#142553']
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
               
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            // xaxis: {
            //     position: 'bottom',
            //     axisBorder: {
            //         show: false
            //     },
            //     axisTicks: {
            //         show: false
            //     },
                
            //     tooltip: {
            //         enabled: true,
            //     }
            // },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                }
            },
            // responsive: [{
            //     breakpoint: 480,
            //     options: {
            //         chart: {
            //             width: 200,
            //         },
            //         legend: {
            //             position: 'bottom',
            //         },
            //     },
            // }],
        },
    });


    // Update chart data when  count changes
    useEffect(() => {
        setChartState(prevState => ({
            ...prevState,
            series: [{
                name: 'Age',
                data: AgeCount
            }],
            options: {
                ...prevState.options,
                labels: categoryAge
            },
        }));
    }, [AgeCount]);

    return (
        <>
            <div className='agediv'>
                <h4 className='gendragetxtpp'>
                    AGE GROUP
                </h4>
                {/* <div>
                    <div id="chart">
                        <ReactApexChart options={options} series={series} type="bar" height={200} width={170} />
                    </div>
                    <div id="html-dist"></div>
                </div> */}
                <div className=''>
                    <Chart
                        // options={optionsage}
                        // series={optionsage.series}
                        options={chartState.options}
                        series={chartState?.series}
                        type="bar"
                        height={chartState?.chart?.height}
                    // height={optionsage.chart.height}
                    />
                </div>
            </div>
        </>
    )
}

export default Ageanalysis