import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import employeeexperiencehub from "../Images/employeeexperiencehub.png";
// import resLogo from "../Images/resLogo.png";
// import Homeicon from "../Images/homeicon.png";
// import Homeicon from "../Images/Icons/PNG/Home.png";
// import Employeeicon from "../Images/clarity_employee-line.png";
// import Employeeicon from "../Images/Icons/PNG/Manager.png";
// import Taskicon from "../Images/octicon_project-24.png";
// import Taskicon from "../Images/Icons/PNG/Task.png";
// import Messagingicon from "../Images/ph_chat-teardrop-dots-light.png";
// import Messagingicon from "../Images/Icons/PNG/Messages.png";
// import homeActiveIcon from "../Images/homeActiveIcon.png";
// import homeActiveIcon from "../Images/Icons/PNG/Home.png";
// import notificationActiveIcon from "../Images/notificationActiveIcon.png";
// import notificationActiveIcon from "../Images/Icons/PNG/Notification.png";
// import performanceActiveIcon from "../Images/performanceActiveIcon.png";
// import performanceActiveIcon from "../Images/Icons/PNG/Task.png";
// import chatActiveIcon from "../Images/chatActiveIcon.png";
// import chatActiveIcon from "../Images/Icons/PNG/Messages.png";
// import employeeActiveIcon from "../Images/employeeActiveIcon.png";
// import employeeActiveIcon from "../Images/Icons/PNG/Manager.png";
// import Notificationicon from "../Images/notification.png";
// import Notificationicon from "../Images/Icons/PNG/Notification.png";
// import ProfileImg from "../Images/profile_img.png";
import profileImg from "../Images/Icons/PNG/Profile (2).png";
import { useAuthCtx } from "../context/AuthCtx";
import { useDispatch } from "react-redux";
import { clearLogin } from "../redux/slices/loginSlice";
import CreateModal from "./Modal/CreateModal";
import rewardpoints from "../../src/Images/headericon/rewardpoints.png"
// import HubIcon from "../Images/Icons/PNG/Hub 1.png";
// import hubActiveIcon from "../Images/Icons/PNG/Hub 1.png";
// import onboardIcon from "../Images/Icons/PNG/Onboarding.png";
// import engageMent from "../Images/Icons/PNG/Engagement.png";
// import mrkInPic2 from "../Images/mrkInPic2.png";
// import advocacyIcon from "../Images/Icons/PNG/Advocacy 1.png";
// import surveyIcon from "../Images/Icons/PNG/Survey.png";
// import companyname from "../../src/Images/companyname.jpeg";
// import search from "../../src/Images/Icons/PNG/Search Hand lens.png";
import TopBarLogo from "../../src/Images/TopBarLogo.png";
// import Hiringicon from "../../src/Images/Icons/PNG/Hiringicon.png";
// import Trainingicon from "../../src/Images/Icons/PNG/Trainingicon.png";
import Home from "../../src/Images/headericon/Home.png";
import events from "../../src/Images/headericon/events.png";
// import Onboarding from "../../src/Images/headericon/Onboarding.png";
import advocacy from "../../src/Images/headericon/advocacy.png";
import Survey from "../../src/Images/headericon/Survey.png";
import training from "../../src/Images/headericon/training.png";
// import Hiring from "../../src/Images/headericon/Hiring.png";
// import search from "../../src/Images/headericon/search.png";
import notification from "../../src/Images/headericon/notification.png";
import More from "../../src/Images/headericon/More.png";
// Constants
// import { BASE_URL } from "../constants/config";
import { ApiHelperFunction } from "../services/api/apiHelpers";
// import toast from "react-hot-toast";
// import HttpClient from "../utils/HttpClient";
import WebLogohead from "../../src/Images/WebLogohead.png";
// import Searchoption from "../Component/Modal/Searchoption";
import Hiring from "../../src/Images/headericon/Hiring.png";
import Onboarding from "../../src/Images/headericon/Onboarding.png";
import documentReposiy from "../../src/Images/headericon/documentReposiy.png";
import BulletinBoard from "../../src/Images/headericon/BulletinBoard.png";
import TopPerformers from "../../src/Images/headericon/TopPerformers.png";
import Chatbot from "../../src/Images/headericon/Chatbot.png";
import Live from "../../src/Images/headericon/Live.png";
// import Openintervuecom from "../../src/Images/headericon/Openintervuecom.png";
import recenttrainingicon from "../../src/Images/headericon/recenttrainingicon.png";
import requestedtrainingicon from "../../src/Images/headericon/requestedtrainingicon.png";
import GrivenceCell from "../../src/Images/headericon/GrivenceCell.png";
import SuggestionBox from "../../src/Images/headericon/SuggestionBox.png";
import Dailypoll from "../../src/Images/headericon/Dailypoll.png";
import Exitmanagement from "../../src/Images/headericon/Exitmanagement.png";
import Initiative from "../../src/Images/headericon/Initiative.png";
import Ideation from "../../src/Images/headericon/Ideation.png";
import SkillRating from "../../src/Images/headericon/SkillRating.png";
import TaskManagement from "../../src/Images/headericon/TaskManagement.png";
import Attendance from "../../src/Images/headericon/Attendance.png";
import Leaverequest from "../../src/Images/headericon/Leaverequest.png";
import CompanyOrgChart from "../../src/Images/headericon/CompanyOrgChart.png";
import Affinity from "../../src/Images/headericon/Affinity.png";
import Mentor from "../../src/Images/headericon/Mentor.png";
import Coach from "../../src/Images/headericon/Coach.png";
import BenefitManagement from "../../src/Images/headericon/BenefitManagement.png";
import CompetencyFramework from "../../src/Images/headericon/CompetencyFramework.png";
import CareerFramework from "../../src/Images/headericon/CareerFramework.png";
import GlobalMarketplace from "../../src/Images/headericon/GlobalMarketplace.png";
import ThankyouCard from "../../src/Images/headericon/ThankyouCard.png";
import Redeempoints from "../../src/Images/headericon/Redeempoints.png";
import ClaimPoints from "../../src/Images/headericon/ClaimPoints.png";
import Employeeiconn from "../../src/Images/headericon/Employeeiconn.png";
import Dashboard from "../../src/Images/headericon/Dashboard.png";
import Messages from "../../src/Images/headericon/Messages.png"
// import GrivenceList from "../Component/Modal/GrivenceList";
// import SuggestionList from "../Component/Modal/SuggestionList";


export default function Header() {
  const { getUserDetails, userData } = useAuthCtx();
  const [modal, setModal] = useState(false);
  const [notiData, setNotiData] = useState([]);
  const [notiNumber, setNotiNumber] = useState([]);
  const [moreicon, setMoreicon] = useState(false);
  // console.log("notiNumber", notiNumber, notiData)

  useEffect(() => {
    getUserDetails();
    viewAllNotification();
    viewAllNotificationNumber();
  }, []);
  const { setLogIn, setLoading } = useAuthCtx();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = () => {
    setLoading(true);
    setTimeout(() => {
      localStorage.clear();
      setLogIn(false);
      setLoading(false);
      sessionStorage.clear();
      dispatch(clearLogin());
      navigate("/");
    }, 500);
  };

  //for view all notification
  const viewAllNotification = async () => {
    let res = await ApiHelperFunction({
      urlPath: "/view-notification",
      method: "GET",
    });

    if (res && res?.status) {
      setNotiData(res?.data?.data?.length);
    } else {
      console.log(res?.response?.data?.message);
    }
  };

  //for getting notification number
  const viewAllNotificationNumber = async () => {
    let res = await ApiHelperFunction({
      urlPath: "/get-notification-number",
      method: "GET",
    });
    // console.log("bjbj", res?.data?.data);
    if (res && res?.status) {
      setNotiNumber(res?.data?.data?.[0]?.notificationNumber);
    } else {
      // toast.error(res?.response?.data?.message);
    }
  };

  const addNotiFicationLight = async () => {
    const data = {
      count: notiData,
    };
    let res = await ApiHelperFunction({
      urlPath: "/add-notification-number",
      method: "POST",
      data: data,
    });
    console.log("disttNoti", res?.data);
    if (res && res?.status) {
      viewAllNotificationNumber();
    }
  };

  const resSideOpen = () => {
    document.querySelector(".resNavHeader").style.cssText +=
      "transform: translate(0, 0)";
  };

  const resSideClose = () => {
    document.querySelector(".resNavHeader").style.cssText +=
      "transform: translate(-350px, 0)";
  };

  // const searchInpOpen = () => {
  //   document
  //     .querySelector(".resSearchDiv")
  //     .classList.remove("resSearchDisplayDiv");
  // };

  // const searchInpClose = () => {
  //   document
  //     .querySelector(".resSearchDiv")
  //     .classList.add("resSearchDisplayDiv");
  // };

  // console.log(userData?.image, "proimge")S
  // const handleClick = (event) => {
  //   switch (event.target.name) {
  //     case "bulletinBoard":
  //       document.getElementById("bullettBoad").classList.add("resBulleFixed");
  //       break;
  //     case "hallOfFame":
  //       document.getElementById("halloof_fame").classList.add("resBulleFixed");
  //       break;
  //     case "employees":
  //       document
  //         .getElementById("employees_card")
  //         .classList.add("resBulleFixed");
  //       break;
  //     case "sustainable":
  //       document
  //         .getElementById("initiative_sec")
  //         .classList.add("resBulleFixed");
  //       break;
  //     case "events":
  //         document
  //           .getElementById("eventsBulle")
  //           .classList.add("resBulleFixed");
  //         break;
  //     default:
  //       return null;
  //   }
  // };

  // const [isShow, setIsshow] = useState(false);
  // const [searchclick, setSearchclick] = useState();
  const [stickheader, setStickyheader] = useState(false);
  const [handleseacrhnew, setHandleseacrhnew] = useState(false);

  // const handlesearchClick = () => {
  //   setSearchclick(!searchclick);
  // };
  // const handlesearchdefaultClick = (e) => {
  //   e.stopPropagation(e);
  // };


  const handlemoreClick = (e) => {
    if (!e.target.closest(".moreclickdivmain")) {
      setMoreicon(false);
    }
    // setMoreicon(!moreicon);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handlemoreClick);
    return () => {
      document.removeEventListener("mousedown", handlemoreClick);
    };
  }, []);

  // const handlesearchClick = () => {
  //   setHandleseacrhnew(!handleseacrhnew);
  // };

  // const handleClick = () => {
  //   setIsshow(!isShow)
  // }

  function setFixed() {
    if (window.scrollY > 200) {
      setStickyheader(true);
    } else {
      setStickyheader(false);
    }
  }
  window.addEventListener("scroll", setFixed);

  const dropdownRef = useRef(null);

  const handleLinkClick = () => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.remove('show');
    }
  };

  return (
    <>
      <section className="topheader">
        <div className="container-fluid">
          <div className="logoimgtxttpflx">
            <div className="logoheadertop">
              {/* <img src={employeeexperiencehub} alt="..." /> */}
              <img src={TopBarLogo} alt="..." />
              {/* <img src={userData?.companyLogo} alt="" /> */}
            </div>
            {/* <div className="elttxtpp">
              elite employee experience hub
            </div> */}
          </div>
        </div>
      </section>

      <section className={stickheader ? "headermainsticky" : "headermain"}>
        <div className="mainpagecontainerheader">
          <div className="hedermainflxspcl">

            {/* <div
              // className="logomaindiv"
              className="dropdown-toggle admin_areamainflx"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <figure className="logoimgmainhhedr"
              >
                <img src={userData?.companyLogo} alt="..." />

              </figure>

              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <ul>
                  <li  >
                    <Link to="/about" className="dropdown-item"  onClick={handleLinkClick}>
                      <i className="fa-solid fa-user mr-2"></i> About Your Company
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      <i className="fa-solid fa-right-from-bracket mr-2"></i>{" "}
                      Contact Information
                    </Link>
                  </li>
                </ul>
              </div>

            </div> */}

            <div className="admin_area">
              <div className="">
                <div className="profile_details">
                  <div
                    className="dropdown-toggle admin_areamainflx"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <figure className="logoimgmainhhedr">
                      <img src={userData?.companyLogo} alt="..." />
                    </figure>
                  </div>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >

                    <Link to="/about" className="dropdown-item">
                      <i className="fa-solid fa-user mr-2"></i> About your company
                    </Link>
                    <Link
                        to="/contact"
                      className="dropdown-item"
                    >
                      <i className="fa-solid fa-right-from-bracket mr-2"></i>{" "}
                      Contact Information
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="logomenudivspcl">
              <ul className="navbar-nav-own">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    <div className="iconheader">
                      <img src={Home} className="normalHeadIcon" alt="icon" />
                      <img src={Home} className="activeHeadIcon" alt="icon" />
                    </div>
                    <div className="namelnktxtppl">Home</div>
                  </NavLink>
                </li>

                {/* {
                  userData?.userType === "Admin" ? (<li className="nav-item">
                    <NavLink className="nav-link" to="/employees">
                      <div className="icon">
                        <img
                          src={Employeeicon}
                          className="img-fluid normalHeadIcon"
                          alt="icon"
                        />
                        <img
                          src={employeeActiveIcon}
                          className="img-fluid activeHeadIcon"
                          alt="icon"
                        />
                      </div>
                      <div className="name">Employee</div>
                    </NavLink>
                  </li>) : (<></>)
                } */}

                {/* <li className="nav-item">
                  <NavLink className="nav-link" to="/hubpage">
                    <div className="icon">
                      <img
                        src={HubIcon}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={hubActiveIcon}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="name">Hub</div>
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink className="nav-link" to="/engagementcalender">
                    <div className="iconheader">
                      <img src={events} className="normalHeadIcon" alt="icon" />
                      <img src={events} className="activeHeadIcon" alt="icon" />
                    </div>
                    <div className="namelnktxtppl">Events</div>
                  </NavLink>
                </li>
                {/* 
                <li className="nav-item">
                  <NavLink className="nav-link" to="/netWorking">
                    <div className="icon">
                      <img
                        src={Onboarding}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={Onboarding}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="name">Onboarding</div>
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink className="nav-link" to="/advocacy">
                    <div className="iconheader">
                      <img
                        src={advocacy}
                        className="normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={advocacy}
                        className="activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="namelnktxtppl">Advocacy</div>
                  </NavLink>
                </li>

                {
                  userData?.userType !== "Owner" && <li className="nav-item">
                    <NavLink className="nav-link" to="/surveys">
                      <div className="iconheader">
                        <img src={Survey} className="normalHeadIcon" alt="icon" />
                        <img src={Survey} className="activeHeadIcon" alt="icon" />
                      </div>
                      <div className="namelnktxtppl">Surveys</div>
                    </NavLink>
                  </li>
                }

                <li className="nav-item">
                  <NavLink className="nav-link" to="/trainingmain">
                    <div className="iconheader">
                      <img
                        src={training}
                        className="normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={training}
                        className="activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="namelnktxtppl">Learning</div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/jobsReferDetail">
                    <div className="iconheader">
                      <img src={Hiring} className="normalHeadIcon" alt="icon" />
                      <img src={Hiring} className="activeHeadIcon" alt="icon" />
                    </div>
                    <div className="namelnktxtppl">Hiring</div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/msg">
                    <div className="iconheader">
                      <img
                        src={Messages}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={Messages}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                      <div className="red_dot"></div>
                    </div>
                    <div className="namelnktxtppl">Message</div>
                  </NavLink>
                </li>

                {/* <li className="nav-item">
                  <NavLink className="nav-link" to="/jobsReferDetail">
                    <div className="iconheader">
                      <img src={Hiring} className="normalHeadIcon" alt="icon" />
                      <img src={Hiring} className="activeHeadIcon" alt="icon" />
                    </div>
                    <div className="namelnktxtppl">Message</div>
                  </NavLink>
                </li> */}
                {/* <div className='inputsearchborder'>
                  <input type='search' placeholder="Search" className="serchdiv " />
                  <div className="searchformicondiv" >
                    <figure className="headerSerFig">
                      <img src={search} alt="icon" />
                      <div className="namee" >Search</div>
                    </figure>
                  </div>
                </div> */}
                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/">
                                        <div className='icon'><img src={Taskicon} className="img-fluid" alt='icon' /></div>
                                        <div className='name'>Task Projects</div>
                                    </Link>
                                </li> */}
                {/* <li className="nav-item">
                  <NavLink className="nav-link" to="/engagement">
                    <div className="icon">
                      <img
                        src={Taskicon}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={performanceActiveIcon}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                    </div>
                    <div className="name">Performence</div>
                  </NavLink>
                </li> */}
                {/* <li className="nav-item">
                  <NavLink className="nav-link" to="/msg">
                    <div className="icon">
                      <img
                        src={Messagingicon}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={chatActiveIcon}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                      <div className="red_dot"></div>
                    </div>
                    <div className="name">Messaging</div>
                  </NavLink>
                </li> */}
                {/* <li className="nav-item" onClick={() => addNotiFicationLight()}>
                  <NavLink className="nav-link" to="/notiHome">
                    <div className="icon">
                      <img
                        src={Notificationicon}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={notificationActiveIcon}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                      {notiData > notiNumber &&
                        <div className="red_dot"></div>
                      }
                      {notiData.some(notification => !notification.read) && (
                        <div className="red_dot"></div>
                      )}
                    </div>
                    <div className="name">Notification</div>
                  </NavLink>
                </li> */}
              </ul>
            </div>
            <div className="logomenusrchnotispcl">
              <ul className="navbar-nav-lnkitmown">
                {/* <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    <div className="iconheader" onClick={handlesearchClick}>
                      <img src={search} className="normalHeadIcon" alt="icon" />
                      <img src={search} className="activeHeadIcon" alt="icon" />
                    </div>
                    <div className="namelnktxtppl">Search</div>
                  </NavLink>
                </li> */}

                <div className="notiBtn" onClick={() => addNotiFicationLight()}>
                  <Link to="/notiHome">
                    <div className="icon">
                      <img
                        src={notification}
                        className="img-fluid normalHeadIcon"
                        alt="icon"
                      />
                      <img
                        src={notification}
                        className="img-fluid activeHeadIcon"
                        alt="icon"
                      />
                      {notiData > notiNumber && <div className="red_dot"></div>}
                      {/* {notiData.some(notification => !notification.read) && (
                        <div className="red_dot"></div>
                      )} */}
                    </div>
                    <div className="name">Notification</div>
                  </Link>
                </div>

                <div className="admin_area">
                  <div className="">
                    <div className="profile_details">
                      <div
                        className="dropdown-toggle admin_areamainflx"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="profile_imgmainhedaer">
                          {userData?.image === "image" ? (
                            <img src={profileImg} alt="..." />
                          ) : (
                            // <img
                            //   src={userData?.image}
                            //   className="img-fluid"
                            //   alt="profile"
                            // />
                            <img
                              src={userData?.image}
                              className="img-fluid"
                              alt="profile"
                            />
                            // <img
                            //   src={
                            //     BASE_URL +
                            //     "/" +
                            //     "uploads/imageUploads/530060.com-4811117"
                            //   }
                            //   className="img-fluid"
                            //   alt="profile"
                            // />
                          )}
                          {/* <img src={profileImg} alt="..." /> */}
                        </div>

                        <div className="nameprofile">
                          <h4>
                            {userData?.firstName + " " + userData?.lastName}
                          </h4>
                          <p>
                            {userData?.userType === "Admin" ? (
                              <p>HR</p>
                            ) : userData?.userType === "Employee" ? (
                              <p>Employee</p>
                            ) : userData?.userType === "Manager" ? (
                              <p>Manager</p>
                            ) : userData?.userType === "Owner" ? (
                              <p>Admin</p>
                            ) :
                              (
                                <></>
                              )}
                          </p>
                        </div>
                      </div>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >

                        <Link to="/profile" className="dropdown-item">
                          <i className="fa-solid fa-user mr-2"></i> Profile
                        </Link>
                        <Link
                          to="/"
                          className="dropdown-item"
                          onClick={handleLogOut}
                        >
                          <i className="fa-solid fa-right-from-bracket mr-2"></i>{" "}
                          Log out
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <li className="nav-item" onClick={() => setMoreicon(!moreicon)}>
                  <div className="nav-link">
                    <div
                      className="iconheader handlemoreclickicon"
                    // onClick={handlemoreClick}
                    >
                      <img src={More} className="normalHeadIcon" alt="icon" />
                      <img src={More} className="activeHeadIcon" alt="icon" />
                      {moreicon && (
                        <div className="moreclickdivmain">
                          <div className="">

                            <div className="">
                              <p className="moreicontxtppp">
                                Employee Experience

                              </p>
                              <div className="hiringdivflxmain">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/bulletinDetails">
                                    <div className="iconheader">
                                      <img
                                        src={BulletinBoard}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={BulletinBoard}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Bulletin Board
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/hallOfDetails">
                                    <div className="iconheader">
                                      <img
                                        src={TopPerformers}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={TopPerformers}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Top Performer

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/engagementcalender">
                                    <div className="iconheader">
                                      <img
                                        src={events}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={events}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">Events</div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={Chatbot}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Chatbot}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">AI Chatbot</div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={Live}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Live}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">Live</div>
                                  </NavLink>
                                </li>
                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Hiring Experience
                              </p>
                              <div className="hiringdivflxmain">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/jobsReferDetail">
                                    <div className="iconheader">
                                      <img
                                        src={Hiring}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Hiring}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Hiring
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/netWorking">
                                    <div className="iconheader">
                                      <img
                                        src={Onboarding}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Onboarding}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Onboarding
                                    </div>
                                  </NavLink>
                                </li>
                                {/* <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={Openintervuecom}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Openintervuecom}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">AI Hiring</div>
                                  </NavLink>
                                </li> */}
                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Learning Experience

                              </p>
                              <div className="hiringdivflxmain">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/trainingmain">
                                    <div className="iconheader">
                                      <img
                                        src={training}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={training}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Learning
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={recenttrainingicon}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={recenttrainingicon}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Recent Learnings

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/Singlerequestedtraining">
                                    <div className="iconheader">
                                      <img
                                        src={requestedtrainingicon}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={requestedtrainingicon}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Request
                                      Learnings

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/documentrepository">
                                    <div className="iconheader">
                                      <img
                                        src={documentReposiy}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={documentReposiy}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Document Repository
                                    </div>
                                  </NavLink>
                                </li>
                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Employee Voice

                              </p>
                              <div className="hiringdivflxmainnavflx">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/surveys">
                                    <div className="iconheader">
                                      <img
                                        src={Survey}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Survey}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      survey
                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/grivenceList">
                                    <div className="iconheader">
                                      <img
                                        src={GrivenceCell}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={GrivenceCell}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Grivence Cell
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/suggestionList">
                                    <div className="iconheader">
                                      <img
                                        src={SuggestionBox}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={SuggestionBox}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Suggestion Box
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/advocacy">
                                    <div className="iconheader">
                                      <img
                                        src={advocacy}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Survey}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Advocacy
                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/bulletinDetails">
                                    <div className="iconheader">
                                      <img
                                        src={Dailypoll}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Dailypoll}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Daily poll
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/engagementcalender">
                                    <div className="iconheader">
                                      <img
                                        src={Exitmanagement}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Exitmanagement}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">Exit Management</div>
                                  </NavLink>
                                </li>
                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Performance Management
                              </p>
                              <div className="hiringdivflxmainnavflx">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/managerviewproject">
                                    <div className="iconheader">
                                      <img
                                        src={Initiative}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Initiative}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Projects
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={Ideation}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Ideation}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Ideation

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={SkillRating}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={SkillRating}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Skill
                                      Rating

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/">
                                    <div className="iconheader">
                                      <img
                                        src={TaskManagement}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={TaskManagement}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Task
                                      Management

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/attendence">
                                    <div className="iconheader">
                                      <img
                                        src={Attendance}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Attendance}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Attendance
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/myleaves">
                                    <div className="iconheader">
                                      <img
                                        src={Leaverequest}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Leaverequest}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Leave
                                      Request


                                    </div>
                                  </NavLink>
                                </li>

                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Networking
                              </p>
                              <div className="hiringdivflxmainnavflx">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/orgchart">
                                    <div className="iconheader">
                                      <img
                                        src={CompanyOrgChart}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={CompanyOrgChart}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Org
                                      Chart

                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/myAffinityDetails">
                                    <div className="iconheader">
                                      <img
                                        src={Affinity}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Affinity}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Affinity Groups


                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/netWorking">
                                    <div className="iconheader">
                                      <img
                                        src={Mentor}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Mentor}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Mentor

                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={Coach}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Coach}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Career
                                      Coach


                                    </div>
                                  </NavLink>
                                </li>


                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Career and Growth

                              </p>
                              <div className="hiringdivflxmainnavflx">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/initiativeDetail">
                                    <div className="iconheader">
                                      <img
                                        src={CareerFramework}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={CareerFramework}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Career
                                      Framework
                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={CompetencyFramework}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={CompetencyFramework}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Competency
                                      Framework
                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={BenefitManagement}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={BenefitManagement}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Benefit
                                      Management


                                    </div>
                                  </NavLink>
                                </li>

                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={Coach}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Coach}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Career
                                      Coach


                                    </div>
                                  </NavLink>
                                </li>


                              </div>
                            </div>

                            <div className="">
                              <p className="moreicontxtppp">
                                Rewards
                              </p>
                              <div className="hiringdivflxmainnavflx">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={rewardpoints}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={rewardpoints}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Points
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={GlobalMarketplace}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={GlobalMarketplace}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Global Marketplace

                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={ThankyouCard}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={ThankyouCard}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Gratitude
                                      Card


                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={Redeempoints}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Redeempoints}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Redeem
                                      Points
                                    </div>
                                  </NavLink>
                                </li>
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="">
                                    <div className="iconheader">
                                      <img
                                        src={ClaimPoints}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={ClaimPoints}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Claim
                                      Points

                                    </div>
                                  </NavLink>
                                </li>


                              </div>
                            </div>


                            <div className="">
                              <p className="moreicontxtppp">
                                Analytics
                              </p>
                              <div className="hiringdivflxmain">
                                <li className="nav-item navwidthmaindiv">
                                  <NavLink className="nav-link" to="/employeeDetail">
                                    <div className="iconheader">
                                      <img
                                        src={Employeeiconn}
                                        className="normalHeadIcon"
                                        alt="icon"
                                      />
                                      <img
                                        src={Employeeiconn}
                                        className="activeHeadIcon"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="namelnktxtppl">
                                      Employees
                                    </div>
                                  </NavLink>
                                </li>
                                {
                                  userData?.userType === "Admin" && (<li className="nav-item navwidthmaindiv">
                                    <NavLink className="nav-link" to="/hranalyticspage">
                                      <div className="iconheader">
                                        <img
                                          src={Dashboard}
                                          className="normalHeadIcon"
                                          alt="icon"
                                        />
                                        <img
                                          src={Dashboard}
                                          className="activeHeadIcon"
                                          alt="icon"
                                        />
                                      </div>
                                      <div className="namelnktxtppl">
                                        Analytics
                                        Dashboard

                                      </div>
                                    </NavLink>
                                  </li>)
                                }


                              </div>
                            </div>


                          </div>
                        </div>
                      )}
                    </div>
                    <div className="namelnktxtppl">More</div>
                  </div>
                </li>
              </ul>
              <div className="baricondiv" onClick={resSideOpen}>
                <i className="fa-solid fa-bars"></i>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="resNavHeader">
        <div className="resHeaderDiv">
          <Link to="/">
            <figure className="resLogo">
              <img src={WebLogohead} alt="..." />
            </figure>
          </Link>
          <button className="resBtn" onClick={resSideClose}>
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="resMenuDiv">
          <ul className="resMenuUl">
            <li className="resMenuLi">
              {/* <Link to="/" className="resMenuLiAn">
                <i class="fa-solid fa-house-chimney"></i> Home
              </Link> */}
              <Link
                to="/bulletinDetails"
                name="bulletinBoard"
                className="resMenuLiAn"
                onClick={resSideClose}
              >
                Bulletin
              </Link>
            </li>
            <li className="resMenuLi">
              {/* <Link to="/employees" className="resMenuLiAn">
                <i class="fa-solid fa-chalkboard-user"></i> Employee
              </Link> */}
              <Link
                to="/hallOfDetails"
                name="hallOfFame"
                id="hallOfFame"
                className="resMenuLiAn"
                onClick={resSideClose}
              >
                Top Performer
              </Link>
            </li>
            <li className="resMenuLi">
              {/* <Link to="/hubpage" className="resMenuLiAn">
                <i class="fa-brands fa-hubspot"></i> Hub
              </Link> */}
              <Link
                to="/employeeDetail"
                className="resMenuLiAn"
                name="employees"
                onClick={resSideClose}
              >
                Employees
              </Link>
            </li>

            <li className="resMenuLi">
              {/* <Link to="/"Bulletin board className="resMenuLiAn">
                <i class="fa-regular fa-file-lines"></i> Performence
              </Link> */}
              <Link
                to="/sustInitiativeDetail"
                className="resMenuLiAn"
                name="sustainable"
                onClick={resSideClose}
              >
                Sustainable Initiative
              </Link>
            </li>
            <li className="resMenuLi">
              {/* <Link to="/" className="resMenuLiAn">
                <i class="fa-regular fa-comment-dots"></i> Messaging
              </Link> */}
              <Link to="/eventsDeails" className="resMenuLiAn" name="events" onClick={resSideClose}>
                Events
              </Link>
            </li>
            <li className="resMenuLi">
              {/* <Link to="/" className="resMenuLiAn">
                <i class="fa-regular fa-bell"></i> Notification
              </Link> */}
              <Link
                to="/trainingDetails"
                className="resMenuLiAn"
                name="training"
                onClick={resSideClose}
              >
                Training
              </Link>
            </li>
            <li className="resMenuLi">
              <Link to="/jobsReferDetail" className="resMenuLiAn" name="jobs" onClick={resSideClose}>
                Jobs referred
              </Link>
            </li>
            <li className="resMenuLi">
              <Link
                to="/myAffinityDetails"
                className="resMenuLiAn"
                name="affinity"
                onClick={resSideClose}
              >
                My Affinity Groups
              </Link>
            </li>
          </ul>
        </div>
      </section>

      {modal && <CreateModal closemodal={() => setModal(false)} />}
      {/* {handleseacrhnew && <Searchoption closemodal={setHandleseacrhnew} />} */}
    </>
  );
}
