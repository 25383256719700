import React from 'react';
import Edit from "../../Images/headericon/Edit.png"
const Deduction = () => {
    const earningdtls = [
        {
            name: "Absent",
            calculation: "Fixed; 50% of CTC",
            code: "Absent"
        },
        {
            name: "Medical allowance",
            calculation: "Fixed; 25% of CTC",
            code: "MA"
        },
        {
            name: "PF",
            calculation: "Fixed; 10% of CTC",
            code: "PF"
        },


    ]
    return (
        <>
            <div className=''>
                <table className='thanledytteslartcuiketanle'>
                    <thead className='enarrsadditinntablethead'>
                        <tr className='enarrsadditinntabletheadtr'>
                            <th className='enarrsadditinntabletheadthone'>
                                <p className='enarrsadditinntabletheadthpp'> NAME </p>
                            </th>

                            <th className='enarrsadditinntabletheadththree'>
                                <p className='enarrsadditinntabletheadthppnew'> Code</p>
                            </th>

                            <th className='enarrsadditinntabletheadthtwo'>
                                <p className='enarrsadditinntabletheadthpp'> CALCULATION TYPE</p>
                            </th>

                            <th className='enarrsadditinntabletheadthfour'>
                                <p className='enarrsadditinntabletheadthrightppnew'> EDIT</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {earningdtls.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th className='enarrsadditinntabletheadthone'>
                                        <p className='enarrsaddwritetetxtetheadthppblue'> {item.name} </p>
                                    </th>
                                    <th className='enarrsadditinntabletheadthtwo'>
                                        <p className='enarrsaddwritetetxtetheadthpp'> {item.code}</p>
                                    </th>
                                    <th className='enarrsadditinntabletheadththree'>

                                        <p className='enarrsaddwritetetxtetheadthpp'> {item.calculation}</p>

                                    </th>
                                    <th className='enarrsadditinntabletheadthfour'>
                                        <div className='eitteebeyhummomg'>
                                            <img src={Edit} alt='...' />
                                        </div>
                                    </th>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Deduction
