import React, { useEffect, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
import adamHubPic from "../../Images/adamHubPic.png"
import { ApiHelperFunction, fileUpload } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';

const Createdocumentform = () => {
  const iniData = {
    docCatId: "",
    title: "",
    details: "",
    version: "",
  }
  const [formData, setFormData] = useState([iniData]);
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileURL, setFileURL] = useState("");
  const [fileData, setFile] = useState("");
  const [deptData, setDeptData] = useState([]);

  //for onChange functionality
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  //for thumbnail image uploading
  const handleImageChange = async (e) => {
    let image = e.target.files[0];
    setUploading(true);

    const form = new FormData();
    form.append("image", image);

    let res = await fileUpload("/image-upload", "POST", form);

    if (res.status) {
      toast.success("Image uploaded successfully");
      setImageURL(res?.image);
    } else {
      toast.error("Error uploading image");
    }
    setUploading(false);
  };

  //for file uploading
  const handleFileChange = async (e) => {
    let image = e.target.files[0];
    setUploadingFile(true);

    const form = new FormData();
    form.append("image", image);

    let res = await fileUpload("/image-upload", "POST", form);

    if (res.status) {
      toast.success("File is uploaded successfully");
      setFileURL(res?.image);
    } else {
      toast.error("Error uploading image");
    }
    setUploadingFile(false);
  };

  //for getting all department data
  const getDepartmentCategoryData = async () => {
    const response = await ApiHelperFunction({
      urlPath: `/get-documents-category`,
      method: "GET",
    });
    // console.log("RESPONSEF", response?.data?.data);
    if (response && response.status) {
      setDeptData(response?.data?.data);
    } else {
      // toast.error(response.error);
    }
  };

  //for submit section
  const handleSubmit = async () => {
    if (formData?.docCatId === "") {
      toast.error("Document Type is required");
      return false;
    }

    if (formData?.title === "") {
      toast.error("Document title is required");
      return false;
    }

    if (imageURL === "") {
      toast.error("Document image is required");
      return false;
    }

    if (formData?.details === "") {
      toast.error("Details is required");
      return false;
    }

    if (formData?.version === "") {
      toast.error("Version is required");
      return false;
    }

    if (fileURL === "") {
      toast.error("File is required");
      return false;
    }



    let data = {
      docCatId: formData?.docCatId,
      title: formData?.title,
      docImage: imageURL,
      details: formData?.details,
      version: formData?.version,
      docUrl: fileURL
    };
    // console.log("documenteras", data)
    // return false
    let response = await ApiHelperFunction({
      urlPath: `/add-document`,
      method: "POST",
      data,
    });
    // console.log("clickqqqq", response);
    if (response && response.status === 200) {
      toast.success(response?.data?.message);
      setFormData(iniData)
    } else {
      toast.error(response?.response?.data?.message);
    }
  };

  useEffect(() => {
    getDepartmentCategoryData();
  }, [])


  return (
    <>
      <section className='createdocumntrepomain'>
        <div className='employeepagecontainer'>
          <div className=''>
            <p className='uploadmanagetextpp'>Upload and Manage Documents</p>
          </div>
          <div className='uploaddivmaindocumentoptndivbrdr'>
            <div className='uploaddivmaindocumentoptndiv'>
              <form className='frmdivupload'>
                <div className='uploaddivmainoptn'>
                  <p className='uploaddoctextpp'>Document Type</p>
                  <select
                    id=""
                    name="docCatId"
                    value={setFormData.docCatId}
                    onChange={(e) => handleChange(e)}
                  >
                    {/* <option value="">Select options</option>
                    <option value="IT"> IT</option>
                    <option value="R&D">R&D</option>
                    <option value="FINANCE">FINANCE</option>
                    <option value="MANAGEMENT">Management</option>
                    <option value="SALES">Sales</option>
                    <option value="NOT-TECH">Non technical</option> */}


                    <option value={""}>
                      Select Department
                    </option>
                    {deptData?.map((item, i) => (
                      <option key={i} value={item?._id}>
                        {item?.catName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='uploaddivmainoptn'>
                  <p className='uploaddoctextpp'>Title of The Document</p>
                  <input
                    type="text"
                    name="title"
                    value={setFormData.title}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className='uploaddivmainoptn'>
                  <p className='uploaddoctextpp'>Add any Thumbnail Image </p>
                  <div className="bgcontainerupldbrdr">
                    <div className="bgcrd">
                      <div className="bgcontainerupld">
                        <div className="logoupld">
                          <img src={Addicon} alt="..." />
                          <div className="">
                            <input
                              type="file"
                              className="upldimagediv"
                              accept="image/*"
                              name="image"
                              defaultValue=""
                              value={image}
                              onChange={handleImageChange}
                            />
                          </div>
                        </div>
                      </div>
                      <p className="upldtxtppdiv">Upload Your Thumbnail</p>

                      {uploading ? <p>image uploading......</p> : null}
                      {imageURL !== "" && (
                        <div className='imageuplddiv' >
                          <img

                            src={imageURL}
                            alt=""

                          />
                          <div className='imageupldcrs'
                            onClick={() => {
                              setImageURL("");
                              setImage("");
                            }}
                          >
                            <i
                              class="fa-solid fa-xmark"

                            ></i>
                          </div>
                        </div>
                      )}

                    </div>
                  </div>

                </div>

                <div className='uploaddivmainoptn'>
                  <p className='uploaddoctextpp'>Details of the document.</p>
                  <textarea
                    id=""
                    rows={4}
                    cols={50}
                    name="details"
                    value={setFormData.details}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className='uploaddivmainoptn'>
                  <p className='uploaddoctextpp'>Version of the document.</p>
                  <input
                    id=""
                    type='number'
                    name="version"
                    value={setFormData.version}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className='uploaddivmainoptn'>
                  <p className='uploaddoctextpp'>File upload</p>
                  <div className="bgcontainerupldbrdr">
                    <div className="bgcrd">
                      <div className="bgcontainerupld">
                        <div className="logoupld">
                          <img src={Addicon} alt="..." />
                          <div className="">
                            <input
                              type="file"
                              className="upldimagediv"
                              // accept="image/*"
                              name="fileData"
                              defaultValue=""
                              value={fileData}
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>
                      </div>
                      <p className="upldtxtppdiv">Upload Your File</p>
                      {uploading ? <p>image uploading......</p> : null}
                      {fileURL !== "" && (
                        <div className='imageuplddiv' >
                          <img

                            src={fileURL}
                            alt=""

                          />
                          <div className='imageupldcrs'
                            onClick={() => {
                              setFileURL("");
                              setFile("");
                            }}
                          >
                            <i
                              class="fa-solid fa-xmark"

                            ></i>
                          </div>
                        </div>
                      )}
                      {/* <div className='imageuplddiv' >
                        <img src={adamHubPic} alt=".." />
                        <div className='imageupldcrs'>
                          <i
                            className="fa-solid fa-xmark"

                          ></i>
                        </div>
                      </div> */}
                    </div>
                  </div>

                </div>
                <div className='homePgCreModSubmitDivbtnkl'>
                  <button type='button' className='homePgCreModSubmitBtn' onClick={handleSubmit}>Submit</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default Createdocumentform
