import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let bioState = {
    status: "idle",
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: [],
    comDetails:[],
    cdetails:[]
};

export const getBio = createAsyncThunk(
    "get_All_Bio",
    async () => {
        let response = await ApiHelperFunction({
            urlPath: "/get-bio",
            method: "GET",
        });
        if (response?.status === 200) {
            return response?.data;
        } 
    }
);


export const getCDetils = createAsyncThunk(
    "get_All_Company_Details_auto_fetch",
    async () => {
        let response = await ApiHelperFunction({
            urlPath: "/view-register-company-details",
            method: "GET",
        });
        if (response?.status) {
            return response?.data;
        } 
    }
);


export const getCompanyDetails = createAsyncThunk(
    "get_All_Company_Details",
    async () => {
        let response = await ApiHelperFunction({
            urlPath: "/view-company-details",
            method: "GET",
        });
        if (response?.status) {
            return response?.data;
        } 
    }
);

export const bioSlice = createSlice({
    name: "bio_actions",
    initialState: bioState,
    reducers: {
        clearBio: (state) => {
            state.status = "idle";
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.data = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBio.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getBio.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.data = payload?.data;
            })
            .addCase(getBio.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
            })

            .addCase(getCDetils.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getCDetils.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.cdetails = payload?.data?.[0];
            })
            .addCase(getCDetils.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
            })


            .addCase(getCompanyDetails.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getCompanyDetails.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.comDetails = payload?.data;
            })
            .addCase(getCompanyDetails.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
            });
    },
});
export const { clearbio } = bioSlice.actions;
export default bioSlice.reducer;
