import React, { useEffect, useState } from 'react'
import Hiringicon from "../../Images/Icons/PNG/Hiringicon.png";
import advytimg from "../../Images/advytimg.jpg";
import download from "../../Images/Icons/PNG/download.png"
import { Link, useParams } from 'react-router-dom';
import Applicantacceptmodal from '../../Component/Modal/Applicantacceptmodal';
import Rejectapplicantmoadl from '../../Component/Modal/Rejectapplicantmoadl';
import Jobssingledetails from './Jobssingledetails';
import { RWebShare } from 'react-web-share';
import { useDispatch, useSelector } from 'react-redux';
import { getRefferedJobs } from '../../redux/slices/jobRefferedSlice';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import moment from 'moment';

const Applicantdetails = () => {
    const params = useParams();
    const jobs = useSelector((state) => state?.jobSlice?.jobs);
    const dispatch = useDispatch();
    const [jobApplicant, setJobApplicant] = useState([]);
    const [applicantmodala, setApplicantmodala] = useState(false)
    const [rejectapmodala, setRejectapmodala] = useState(false);
    const [acceptModalId, setacceptModalId] = useState("");
    const [rejectModalId, setrejectModalId] = useState("");

    const acceptoncilckmodal = (id) => {
        setacceptModalId(id);
        setApplicantmodala(!applicantmodala)
    }

    const rejectionclickmodal = (id) => {
        setrejectModalId(id);
        setRejectapmodala(!rejectapmodala)
    }

    //for viewing is applicants
    const viewJobApplicants = async () => {
        const response = await ApiHelperFunction({
            urlPath: `/view-applications/${params?.id}`,
            method: "GET",
        });

        if (response && response.status) {
            setJobApplicant(response?.data?.data?.[0]?.applications);
        } else {

        }
    };

    useEffect(() => {
        dispatch(getRefferedJobs());
        viewJobApplicants();
    }, []);

    return (
        <>
            <section className='jobssingledetailsmain'>
                <div className='employeepagecontainer'>
                    <div className='jobssingledetailsflx'>
                        <div className='jobsreferredmainbgdiv'>
                            <div className="jobsreferredmainbgsingle">
                                <section id="jobs_referreddetilas">
                                    <div className="top_area">
                                        <div className="head jntxtimgflx">
                                            <div className="initaimg">
                                                <img src={Hiringicon} alt="..." />
                                            </div>
                                            <h4>Hiring</h4>
                                        </div>
                                        <div>
                                        </div>
                                    </div>

                                    <div className="bottom_area_details bottomareadibsingle">
                                        {jobs?.length > 0 ? (
                                            jobs?.map((item, index) => {
                                                return (
                                                    <div className="bottom_area_detailsdiv bottomdtlsdivare">
                                                        <div
                                                            style={{
                                                                padding: "6px 10px 9px",

                                                            }}
                                                        >
                                                            <div className="top">
                                                                <div className="head">
                                                                    <h4 style={{ fontSize: "16px", fontWeight: 600, lineHeight: "18px", fontFamily: "Notosans" }}>{item?.jobTitle}</h4>
                                                                </div>

                                                            </div>
                                                            <div className="details">
                                                                <p className='lbndapplicdtgsiuci'>
                                                                    Location: <span>{item?.jobLocation}</span>
                                                                </p>
                                                                <p className='lbndapplicdtgsiuci'>
                                                                    Skill: <span>{item?.keySkills}</span>
                                                                </p>
                                                                <p className='lbndapplicdtgsiuci'>
                                                                    Opening: <span>{item?.openings}</span>
                                                                </p>
                                                                
                                                            </div>
                                                        </div>

                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <p>No jobs to show! </p>
                                        )}
                                       

                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className='jobsappluicantdivright'>
                            <div className='jobsappluicantdivrightdivman'>
                                <div className='rfshr'>
                                    No. Of Applicant : {jobApplicant?.length}
                                </div>
                                {
                                    jobApplicant?.map((item) => {
                                        return (
                                            <>
                                                <div className='applicantdivmain'>
                                                    <div className='aactptdtlbtnndivdlkcfh'>
                                                        <div className='applicantimgdtlsdiflx'>

                                                            <div className="applicantimgdiv">
                                                                <img src={item?.candidateImage} alt="..." />
                                                            </div>

                                                            <div className='applicntdivdtlsmain'>
                                                                <div className=''>
                                                                    <p className='applicatbstdctxdivmm'><span>
                                                                        Name:
                                                                    </span>
                                                                        {item?.candidateName}
                                                                    </p>
                                                                </div>

                                                                <div className=''>
                                                                    <p className='applicatbstdctxdivmm'><span>
                                                                        Email Address :
                                                                    </span>
                                                                        {item?.candidateMail}
                                                                    </p>
                                                                </div>

                                                                <div className=''>
                                                                    <p className='applicatbstdctxdivmm'><span>
                                                                        Cv :
                                                                    </span>
                                                                     
                                                                        <div className='downloadimgbamaib'>
                                                                            {
                                                                                item?.resume !== "" ? (<div className='dateshowlinkdiv'><img src={download} alt="..." /></div>) : (<div><img src={download} alt="..." /></div>)
                                                                            }

                                                                            <div className='downloaddivmaindiv'>

                                                                                {
                                                                                    item?.resume !== "" && (
                                                                                        <>
                                                                                            <div className='downloaddivmaindivsize' >

                                                                                                <a href={item?.resume?.startsWith("http") ? item?.resume : `http://${item?.resume}`} target="_blank" rel="noopener noreferrer">
                                                                                                    {item?.resume?.slice(0, 20)}
                                                                                                </a>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </p>
                                                                </div>

                                                                <div className=''>
                                                                    <p className='applicatbstdctxdivmm'><span>
                                                                        Profile :
                                                                    </span>
                                                                        <Link className="" 
                                                                        
                                                                        to={item?.profileLink}
                                                                        >
                                                                            {item?.profileLink?.slice(0,50)}
                                                                        </Link>
                                                                    </p>
                                                                </div>

                                                                <div className=''>
                                                                    <p className='applicatbstdctxdivmm'><span>
                                                                        Applied Date :
                                                                    </span>
                                                                        {moment(item?.createdOn).format("YYYY-MM-DD")}
                                                                    </p>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        {
                                                            item?.applicationStatus === "pending"
                                                                ?
                                                                (
                                                                    <div className='accptrrjctrdivmsin'>
                                                                        <button className='accprbdinbbtn' onClick={() => acceptoncilckmodal(item?._id)}>
                                                                            Accept
                                                                        </button>
                                                                        <button className='rjxctgdbdinbbtn' onClick={() => rejectionclickmodal(item?._id)}>
                                                                            Reject
                                                                        </button>
                                                                    </div>
                                                                )
                                                                :
                                                                (item?.applicationStatus === "accept")
                                                                    ?
                                                                    (
                                                                        <div className='accptrrjctrdivmsin'>
                                                                            <button className='accprbdinbbtn'>
                                                                                Accepted
                                                                            </button>
                                                                            <button className='rjxctgdbdinbbtn' onClick={() => rejectionclickmodal(item?._id)}>
                                                                                Reject
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        <div className='accptrrjctrdivmsin'>
                                                                            <button className='accprbdinbbtn' onClick={() => acceptoncilckmodal(item?._id)}>
                                                                                Accept
                                                                            </button>
                                                                            <button className='rjxctgdbdinbbtn'>
                                                                                Rejected
                                                                            </button>
                                                                        </div>
                                                                    )
                                                        }


                                                    </div>

                                                </div>
                                            </>
                                        )
                                    })
                                }


                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {applicantmodala && <Applicantacceptmodal closemodal={setApplicantmodala} acceptModalId={acceptModalId} viewJobApplicants={viewJobApplicants}/>}

            {rejectapmodala && <Rejectapplicantmoadl closemodal={setRejectapmodala} rejectModalId={rejectModalId} viewJobApplicants={viewJobApplicants}/>}
        </>
    )
}

export default Applicantdetails
