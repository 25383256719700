import React, { useEffect, useState } from 'react'
// import { initialState } from 'react-doc-viewer/build/state/reducer';
import toast from 'react-hot-toast';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import moment from 'moment';

const EditWorkmodal = ({ closemodal, projectId, getAttendanceShowData, selectedDate }) => {
    const initialState = {
        projectId: projectId,
        workFromHome: null,
        workFromOffice: null,
        clientSiteWork: null
    }
    const [GiveAttendanceData, setGiveAttendanceData] = useState(initialState);


    //Get all attendance project for edit
    const getAttendanceEditData = async () => {
        let data = {
            date: moment(selectedDate)?.format("YYYY-MM-DD")
        }
        const response = await ApiHelperFunction({
            urlPath: `/fetch-attendence-daywise`,
            method: "POST",
            data
        });
        if (response && response.status) {
            const matchedData = response?.data?.data?.find(item => item.projectId === projectId);
            console.log("matchedData",matchedData)
            if (matchedData) {
                setGiveAttendanceData(matchedData);
            } else {
                setGiveAttendanceData(initialState);
            }
        }
    };

    //Submit functionality for give attendance
    const handleGiveAttendanceData = async () => {
        if (projectId == "") {
            toast.error("All field is required")
            return false;
        }
        let data = {
            projectId: projectId,
            workFromHome: +GiveAttendanceData?.workFromHome,
            workFromOffice: +GiveAttendanceData.workFromOffice,
            clientSiteWork: +GiveAttendanceData?.clientSiteWork
        };
        // console.log("GiveAttendanceData", data)
        // return false
        let response = await ApiHelperFunction({
            urlPath: `/give-attendence`,
            method: "POST",
            data,
        });
        if (response && response.status) {
            toast?.success(response?.data?.message);
            getAttendanceShowData();
            closemodal();
            setGiveAttendanceData(initialState)
        } else {
            toast.error(response?.response?.data?.message);
        }
    };

    //for change functionality of give attendance
    const handleChangeGiveAttendance = (e) => {
        const { name, value } = e.target;
        setGiveAttendanceData(prev => ({ ...prev, [name]: value }))
    }

    const submitstopclick = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        getAttendanceEditData();
    }, [selectedDate])
    return (
        <>
            <section className='editworkmodalmainbg' onClick={() => closemodal()}>
                <div className='editworkmodalbg' onClick={(e) => submitstopclick(e)}>
                    <div className=''>
                        <h4 className='editxtpworklctntxp'>Edit Work Location</h4>
                    </div>
                    <div className=''>
                        <table className='tableworklocatiomdedt'>
                            <tr>
                                <th className='tableworklocatiomth'>
                                    <p class="elutitxtppsmop">Office(Default)</p>
                                </th>
                                <th className='tableworklocatiomth'>
                                    <p class="elutitxtppsmop">Client Site</p>
                                </th>
                                <th className='tableworklocatiomth'>
                                    <p class="elutitxtppsmop">Work From Home</p>
                                </th>
                            </tr>
                            <tr>
                                <td className='tableworklocatiomtd'>
                                    <div className='intputworktimetxtdiv'>
                                        <input
                                            type='number'
                                            name="workFromOffice"
                                            value={GiveAttendanceData?.workFromOffice}
                                            onChange={(e) => handleChangeGiveAttendance(e)}
                                        />
                                    </div>
                                </td>
                                <td className='tableworklocatiomtd'>
                                    <div className='intputworktimetxtdiv'>
                                        <input
                                            type='number'
                                            name="clientSiteWork"
                                            value={GiveAttendanceData?.clientSiteWork}
                                            onChange={(e) => handleChangeGiveAttendance(e)}
                                        />
                                    </div>
                                </td>
                                <td className='tableworklocatiomtd'>
                                    <div className='intputworktimetxtdiv'>
                                        <input
                                            type='number'
                                            name="workFromHome"
                                            value={GiveAttendanceData?.workFromHome}
                                            onChange={(e) => handleChangeGiveAttendance(e)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="editwrksbmtbtnmainbtn">
                        <button
                            className='editwrksbmtbtn'
                            type='button'
                            onClick={handleGiveAttendanceData}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditWorkmodal
